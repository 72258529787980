export const countHiddenSliders = (sliders: number, toShift: number, visible: number): number => {
  if (!visible || !toShift || toShift > visible || (sliders && sliders < visible)) {
    throw new Error('Passed wrong param(s).');
  }

  if (!sliders || sliders === visible) {
    return 0;
  }

  return Math.ceil((sliders - visible)/toShift)
};
