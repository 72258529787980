export enum Type {
  FINGERPRINT_SET_UNIQUE_ID_REQUEST = '@fingerprint/FINGERPRINT_SET_UNIQUE_ID_REQUEST',
  FINGERPRINT_SET_UNIQUE_ID_SUCCESS = '@fingerprint/FINGERPRINT_SET_UNIQUE_ID_SUCCESS',
  FINGERPRINT_SET_UNIQUE_ID_ERROR = '@fingerprint/FINGERPRINT_SET_UNIQUE_ID_ERROR',
}

export interface IRequestUniqueId {
  type: Type.FINGERPRINT_SET_UNIQUE_ID_REQUEST;
  payload: { bulkId: object };
}

export interface IRequestUniqueIdSuccess {
  type: Type.FINGERPRINT_SET_UNIQUE_ID_SUCCESS;
  payload: any;
}

export interface IRequestUniqueIdError {
  type: Type.FINGERPRINT_SET_UNIQUE_ID_ERROR;
  payload: Error;
}
