import {
  IBaseInputLikeStyledTheme,
  IInputLikeStyledTheme,
  ITheme,
} from 'merchants/types/IThemeStyles';
import { palette } from './palette';

const defaultGlobalBaseInputLikeStyles: IBaseInputLikeStyledTheme = {
  backgroundColor: palette.c14,
  borderColor: palette.c6,
  fontSize: '15px',
  boxShadow: `0 1px 8px 0 ${palette.c10}`,

  disabled: {
    borderColor: palette.c6,
    backgroundColor: 'transparent',
  },

  valid: {
    borderColor: palette.c12
  }
};

const defaultGlobalInputLikeStyles: IInputLikeStyledTheme = {
  ...defaultGlobalBaseInputLikeStyles,
  borderRadius: '20px',
  borderWidth: '1px',
  color: palette.c1,
  boxShadow: `0 1px 8px 0 ${palette.c10}`,

  focus: {
    backgroundColor: palette.c3,
    borderColor: palette.c4,
  },

  disabled: {
    ...defaultGlobalBaseInputLikeStyles.disabled,
    color: palette.c28,
  },

  error: {
    backgroundColor: '',
    borderColor: palette.c9,
  },
};

export const theme: ITheme = {
  breakpoint: {
    desktop: '968px',
    mobile: '576px',
    tablet: '768px',
  },
  layout: {
    backgroundColor: palette.c21
  },
  buttons: {
    base: {
      minWidth: '119px',
      minHeight: '32px',
      padding: '8px 16px 10px 16px',
      border: `2px solid ${palette.c4}`,
      borderRadius: '20px',
      backgroundColor: palette.c14,
      fontSize: '15px',
      fontWeight: 'bold',
      color: palette.c4,

      disabled: {
        opacity: '.4',
        backgroundColor: '',
        color: '',
        border: ''
      },

      hover: {
        color: '',
        backgroundColor: palette.с2,
      },

      small: {
        minWidth: '64px',
        padding: '5px 12px 8px',
        fontSize: '14px',
      },
    },
    primary: {
      backgroundColor: palette.c4,
      border: '2px solid transparent',
      color: palette.c3,
      borderRadius: '',
      hover: {
        backgroundColor: palette.c16,
        color: palette.c3
      },
    },
    resubmit: {
      triggerColor: palette.c4,
      color: palette.c1,
      borderColor: '',
      itemBorderColor: palette.c6
    },
  },
  links: {
    base: {
      fontWeight: 600,
      textDecoration: 'none',
      color: palette.c4,

      danger: {
        color: palette.c5,
      },
      action: {
        color: palette.c2,
      },
      hover: {
        textDecoration: 'none',
        color: palette.c4,
      },
    },
    exit: {
      backgroundColor: palette.c3,
      color: palette.c4,
      hover: {
        backgroundColor: '',
        color: '',
      }
    },
    nav: {
      borderColor: palette.c4,
      backgroundColor: '',
      borderRadius: '20px',
      borderWidth: '1px',
      color: palette.c4,
      padding: '9px 11px',

      hover: {
        color: palette.c3,
        backgroundColor: palette.c2,
      },
    },
  },
  forms: {
    dropdown: {
      ...defaultGlobalInputLikeStyles,
      placeholderColor: palette.c28,
      hover: {
        backgroundColor: palette.c27
      },
      active: {
        backgroundColor: palette.c26
      }
    },
    input: {
      ...defaultGlobalInputLikeStyles,
      fontSize: '15px',
    },
    radio: {
      ...defaultGlobalBaseInputLikeStyles,
      borderWidth: '1px',

      checked: {
        backgroundColor: palette.c12,
        borderColor: palette.c6,
        backgroundColorAfter: '',
      },
    },
    checkBox: {
      ...defaultGlobalBaseInputLikeStyles,
      borderWidth: '1px',

      checked: {
        backgroundColor: palette.c2,
        borderColor: palette.c2,
        backgroundColorAfter: '',
      },
    },
    textarea: {
      ...defaultGlobalInputLikeStyles,
      resize: 'none',
    },
    tumbler: {
      ...defaultGlobalBaseInputLikeStyles,
      borderRadius: '12px',
      boxShadow: `0 1px 8px 0 ${palette.c10}`,
      borderWidth: '1px',
      borderColor: palette.c8,
      backgroundColor: palette.c8,

      checked: {
        borderColor: palette.c4,
        backgroundColor: palette.c4,
      },
    },
  },
  text: {
    badge: {
      color: palette.c3,
      backgroundColor: palette.c11,
      danger: {
        backgroundColor: palette.c5,
      },
      primary: {
        backgroundColor: palette.c4,
      },
      warning: {
        backgroundColor: palette.c13,
      },
      success: {
        backgroundColor: palette.c12,
      },
      pending: {
        backgroundColor: palette.c29,
      },
      awaiting: {
        backgroundColor: palette.c30,
      },
    },
    description: {
      color: palette.c1
    },
    error: {
      color: palette.c5,
      paddingTop: '0px',
      paddingLeft: '16px',
    },
    h1: {
      color: palette.c1,
    },
    h3: {
      color: palette.c1,
    },
    h4: {
      color: palette.c1,
    },
  },
  components: {
    appendControl: {
      color: palette.c4,
    },
    breadcrumbs: {
      color: palette.c20,
      backgroundColor: 'none',
      darkArrow: true,

      mobile: {
        color: palette.c1,
        backgroundColor: palette.c10,
      },
    },
    correction: {
      accent: palette.c13,
    },
    divider: {
      lineColor: palette.c17,
      backgroundColor: palette.c21,
    },
    feedback: {
      textColor: palette.c20,
    },
    geoHighlightTip: {
      color: palette.c1
    },
    hamburger: {
      backgroundColor: palette.c7,
      borderColor: palette.c7
    },
    modal: {
      colorCloseButton: '',
      backgroundColorCloseButton: palette.c10,
    },
    modalPreview: {
      colorCloseButton: '',
      backgroundColorCloseButton: palette.c10,
    },
    navigation: {
      backgroundColor: palette.c3,
      boxShadow: '0 2px 17px 0 rgba(147, 147, 147, 0.5)'
    },
    previewArea: {
      borderRadius: '16px',
    },
    phoneNumber: {
      buttonColor: palette.c2,
    },
    progress: {
      backgroundColor: palette.c10,
      backgroundColorAfter: palette.c2,
      tagFontWeight: '600',
      tagColor: palette.c20,
    },
    qrcode: {
      width: '128px',
      height: '128px',
    },
    slider: {
      arrow: {
        backgroundColor: palette.c3
      },
      backgroundColorActive: palette.c4,
      backgroundColor: palette.c6,
    },
    stagePreview: {
      borderRadius: '16px',
      textColor: palette.c1,
      titleColor: palette.c3,
      contentBackground: palette.c3,
      full: {
        correction: {
          divider: palette.c13
        }
      }
    },
    statusBox: {
      background: {
        primary: palette.c4,
        danger: palette.c5,
        warning: palette.c13,
        success: palette.c12,
      },
    },
    tooltip: {
      background: palette.c3,
      border: `1px solid ${palette.c6}`,
      borderRadius: '4px',
      fontSize: '14px',
      maxWidth: '312px',
      padding: '20px 16px',
    },
    uploader: {
      width: '374px',
      height: '234px',
      backgroundColor: palette.c14,
      borderColor: palette.c8,
      borderColorLabel: palette.c8,
      borderStyle: 'dashed',
      borderWidth: '1px',
      borderRadius: '16px',
      borderRadiusLabel: '13px',
      boxShadow: `0 1px 8px 0 ${palette.c10}`,

      active: {
        borderColor: palette.c4,
      },

      error: {
        borderColor: palette.c9,
      },
    },
  },
  icons: {
    docs: {
      backSide: {
        fill: palette.c18,
        fill2: palette.c18
      },
      blankStatement: {
        fill: palette.c6
      },
      doc: {
        fill: palette.c6,
        fill2: palette.c20
      },
      electricityBill: {
        fill: palette.c6
      },
      frontSide: {
        fill: palette.c19,
        fill2: palette.c14
      },
      miningActivity: {
        fill: palette.c6
      },
      selfie: {
        fill: palette.c6
      },
      selfieWithNote: {
        fill: palette.c6,
        fill2: palette.c6
      },
      taxes: {
        fill: palette.c6,
        fill2: palette.c20
      },
      tradingActivity: {
        fill: palette.c6
      },
      utilityBill: {
        fill: palette.c6,
        fill2: palette.c10,
        fill3: palette.c20
      },
    },
    markup: {
      angle: {
        fill: palette.c1
      },
      arrow: {
        fill: palette.c23
      },
      check: {
        fill: palette.c3
      },
      clip: {
        fill: palette.c8
      },
      clock: {
        fill: palette.c6
      },
      close: {
        fill: palette.c5,
        fill2: palette.c3
      },
      discuss: {
        fill: palette.c20
      },
      exclamation: {
        fill: palette.c3
      },
      exit: {
        fill: palette.c4
      },
      eye: {
        fill: palette.c2
      },
      info: {
        fill: palette.c4,
        fill2: palette.c3
      },
      lock: {
        fill: palette.c6
      },
      ok: {
        fill: palette.c22
      },
      refresh: {
        fill: palette.c25
      },
      star: {
        def: palette.c8,
        active: palette.c4
      },
      status: {
        draft: palette.c2,
        accepted: palette.c12,
        correction: palette.c13,
        pending: palette.c25,
      },
      stop: {
        fill: palette.c6
      }
    },
    stubs: {
      apiError: {
        fill: palette.c2,
        fill2: palette.c5
      },
      card: {
        fill: palette.c2,
        fill2: palette.c2
      },
      checkEmail: {
        fill: palette.c2,
        fill2: palette.c2
      },
      clock: {
        fill: palette.c2,
        fill2: palette.c13
      },
      corporate: {
        fill: palette.c2,
        fill2: palette.c2
      },
      error: {
        fill: palette.c2,
        fill2: palette.c5
      },
      ok: {
        fill: palette.c2,
        fill2: palette.c12
      },
      notice: {
        fill: palette.c2,
        fill2: palette.c13
      },
      qrConnected: {
        fill: palette.c2,
        fill2: palette.c12
      },
      qrSubmitted: {
        fill: palette.c2,
        fill2: palette.c12
      },
      rejected: {
        fill: palette.c2,
        fill2: palette.c5
      },
      settings: {
        fill: palette.c2,
        fill2: palette.c13
      },
      stop: {
        fill: palette.c2,
        fill2: palette.c5
      }
    }
  }
};
