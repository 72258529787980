import { Map } from 'immutable';
import { Stage, StageStatuses, StageStatusName } from 'system/Stages/types';

import flatMap from 'lodash/flatMap';
import { Stub } from 'system/Routing/types';
import { Routes } from './Routes';
import { IRouteProps } from './interfaces';

export const flattedWithKeys = (collection: any): IRouteProps[] =>
  flatMap(collection, (value, key) => ({ ...value, key }));

const getStagesEntryUrlsForSingleStage = ({
  stageId,
  stepId = 'status',
  status,
}: {
  stageId: Stage;
  stepId: string;
  status: Stub | string;
}) => {
  const stepUrl = Routes.url({ stageId, stepId });
  const stubUrl = Routes.url({ stageId, stepId, stubId: status });

  return status === 'draft' ? stepUrl : stubUrl;
};

const getStagesEntryUrlsForMultiplyStages = ({
  stageId,
  stepId = 'status',
  status,
}: {
  stageId: Stage;
  stepId: string;
  status: Stub | string;
}) => {
  const stepUrl = Routes.url({ stageId, stepId });
  const stubUrl = Routes.url({ stageId, stepId, stubId: status });
  return ['correction', 'notice'].includes(status) ? stubUrl : stepUrl;
};

export const symlinkUrlByStatus = (
  status: StageStatusName,
  retryDossier = false
) => {
  const schema: { [key: string]: string } = {
    [StageStatuses.INPROGRESS]: Stub.PENDING,
    [StageStatuses.NEXT_STAGE_NEEDED]: Stub.NEXT,
    [StageStatuses.TOBE_ASKED]: Stub.NOTICE, // possible, it's better to discuss this flow
    [StageStatuses.REFUSED]: retryDossier ? Stub.RETRY : Stub.REJECTED,
  };
  return schema[status] || status;
};

export const getStagesEntryUrls = ({ schema, stages, features }: any) => {
  const retryDossier = features.get('retryDossier', false);
  const searchParams = window?.location?.search;

  return schema
    .entrySeq()
    .reduce((acc: Map<any, any>, [stageId, stepIds]: any) => {
      const profileStageStatus = stages
        .find(
          (stage: Map<any, any>) => stage.get('id') === stageId,
          null,
          Map()
        )
        .get('status');

      let url = {};

      const firstStep = stageId === Stage.CORPORATE ? '' : stepIds.first()

      if (stages.size < 2) {
        url = getStagesEntryUrlsForSingleStage({
          stageId,
          stepId: firstStep,
          status: symlinkUrlByStatus(profileStageStatus, retryDossier),
        });
      } else {
        url = getStagesEntryUrlsForMultiplyStages({
          stageId,
          stepId: firstStep,
          status: symlinkUrlByStatus(profileStageStatus, retryDossier),
        });
      }

      const stageUrl = typeof url === 'string' && searchParams ? url + searchParams : url;

      return acc.merge(Map({ [stageId]: stageUrl }));
    }, Map());
};
