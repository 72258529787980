import { all, call, put, select } from 'redux-saga/effects';

import {
  baseUploadFileRequest,
  IUploadParams,
  IUploadResult,
  requestWltfsToken,
} from 'system/Dossier/Upload/sagas';
import { makeSelectRouterParams } from 'system/Bootstrap/selectors';
import { joinByChar } from 'utils/helpers';
import { makeErrorMessage } from 'utils/error';
import { navigateToErrorLayout } from 'system/Routing/sagas';
import { qrCodeIsConnected } from 'system/Dossier/Upload/sagas/qr';
import { navigateCorporateNext } from 'system/Routing/actions';
import { addFiles, errorUploadFiles } from '../actions';

export function* handleResult({
  result,
}: {
  result: (IUploadParams & { files: any[] })[];
  source: any;
}) {
  try {
    const isQrCodeConnected = yield qrCodeIsConnected();

    if (isQrCodeConnected) {
      return;
    }

    if (result) {
      const {
        params: { stepId: block, stubId: step },
      } = yield select(makeSelectRouterParams());

      const stepId = joinByChar(step);
      const blockId = joinByChar(block);

      yield all(
        result.map((item) => {
          const source = item?.source;
          const files = item?.files;
          if (source && files.length) {
            return put(
              addFiles({
                files,
                source,
                stepId,
                blockId,
                replace: true,
              })
            );
          }
        })
      );

      yield put(navigateCorporateNext());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* uploadFiles({
  files,
  source,
  replace,
}: {
  files: IUploadResult[];
  source: string;
  replace?: boolean;
}) {
  try {
    const {
      params: { stepId: block, stubId: step },
    } = yield select(makeSelectRouterParams());

    const stepId = joinByChar(step);
    const blockId = joinByChar(block);

    const mappedFiles = files.reduce(
      (
        acc: [] | IUploadResult[],
        { file, id }: { file: File & { uploaded?: boolean }; id: string }
      ) => {
        if (file?.uploaded) {
          return acc;
        }
        return [...acc, { file, fileType: source, id }];
      },
      []
    );

    if (mappedFiles && mappedFiles.length) {
      yield call(requestWltfsToken);
    }

    const result = yield call(baseUploadFileRequest, mappedFiles);

    yield put(
      addFiles({
        files: result,
        source,
        stepId,
        blockId,
        replace,
      })
    );

    return { files: result, source };
  } catch (err) {
    yield put(errorUploadFiles([makeErrorMessage(err)]));
    yield call(navigateToErrorLayout);
  }
}
