import { Stage } from 'system/Stages/types';

import { Stub, SubStep } from 'system/Routing/types';
import { OrderBy } from './types/shared/types';

export const STAGES = Object.values(Stage);
export const STUBS = Object.values(Stub);
export const SUB_STEPS = Object.values(SubStep);

export const ORDER_BY: OrderBy = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const APT_SUITE = 'aptSuite';
export const CITY = 'city';
export const COUNTRY = 'country';
export const DATE_OF_BIRTH = 'dateOfBirth';
export const EXPIRE_DATE = 'expireDate';
export const FIRST_NAME = 'firstName';
export const GENDER = 'gender';
export const HAS_EXPIRE_DATE = 'hasExpireDate';
export const ISSUE_DATE = 'issueDate';
export const ISSUING_COUNTRY = 'issuingCountry';
export const LAST_NAME = 'lastName';
export const MIDDLE_NAME = 'middleName';
export const NATIONALITY = 'nationality';
export const REGION = 'region';
export const SERIAL_NUMBER = 'serialNumber';
export const STREET = 'street';
export const ZIP_CODE = 'zipCode';
export const SUB_TYPE = 'subType';
