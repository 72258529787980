import { END, eventChannel } from 'redux-saga';
import { cancelled, put, take, select, fork, call, cancel } from 'redux-saga/effects';
import { io } from 'socket.io-client';
import { logLocalDev } from 'utils/logger';
import { makeSelectRequestToken } from 'system/Profile/selectors';
import { makeSelectApiUrl } from 'system/Settings/selectors';

import { livenessCheckWebsocketError, livenessCheckWebsocketMessage } from '../actions';

import { Type } from '../actionTypes';
import { socketEvents } from '../socketEvents';

const config: { socket: any; channel: any; connection: any } = {
  socket: null,
  channel: null,
  connection: null,
};

export function* createSocketConnection() {
  const api = yield select(makeSelectApiUrl());

  const token = yield select(makeSelectRequestToken());

  const socket = io(`${api}/livenessCheck`, {
    secure: true,
    reconnection: true,
    reconnectionDelay: 1500,
    rejectUnauthorized: false,
    auth: { token },
    transports: ['websocket'],
  });

  return socket;
}

export function createSocketChannel(socket: any) {
  return eventChannel((emit) => {
    socket.on(socketEvents.connect, () => {
      emit({ socketId: socket.id });

      logLocalDev('connect');
    });

    socket.on(socketEvents.connectError, (error: any) => {
      emit({ error });

      logLocalDev('connect_error');
    });

    socket.on(socketEvents.livenessCheckResult, (args: any) => {
      logLocalDev('liveness_check_result: ', args);

      emit(args);
    });

    socket.on(socketEvents.userCannotStartInstantApp, (args: any) => {
      logLocalDev(socketEvents.userCannotStartInstantApp, args);

      emit(args);
    });

    socket.on(socketEvents.userOpenLivenessCheck, (args: any) => {
      logLocalDev('user_open_liveness_check: ', args);

      emit({ connected: true });
    });

    socket.on(socketEvents.disconnect, (reason: any) => {
      logLocalDev('Disconnect reason:', reason);

      emit(END);
    });

    function unsubscribe() {
      Object.values(socketEvents).forEach((event) => {
        socket.removeAllListeners(event);
      });
    }

    return unsubscribe;
  });
}

export function* readFromChannel(channel: any) {
  try {
    while (true) {
      const payload: any = yield take(channel);

      logLocalDev('LC Payload', payload);

      if (payload?.error) {
        yield put(livenessCheckWebsocketError(payload.error));
      } else {
        yield put(livenessCheckWebsocketMessage(payload));
      }
    }
  } catch (err) {
    logLocalDev('LC error', err);

    yield put(livenessCheckWebsocketError(err));
  } finally {
    if (yield cancelled()) {
      logLocalDev('LC cancelled');

      channel.close();
    } else {
      const message = 'LC disconnected for an unknown reason';

      yield put(livenessCheckWebsocketError({ message }));

      logLocalDev(message);
    }
  }
}

function* connect() {
  logLocalDev('connect.config', config);

  if (!(config.socket && config.socket?.connected)) {
    config.socket = yield call(createSocketConnection);
  }

  config.channel = yield call(createSocketChannel, config.socket);

  config.connection = yield fork(readFromChannel, config.channel);
}

function* disconnect() {
  if (config.connection) yield cancel(config.connection);

  if (config.socket) config.socket.close();

  config.socket = null;

  config.connection = null;

  config.channel = null;
}

export function* watchForSocketConnect() {
  yield call(connect);

  yield take(Type.LIVENESS_CHECK_CLEANUP);

  yield call(disconnect);
}
