import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import pick from 'lodash/pick';
import get from 'lodash/get';
import has from 'lodash/has';
import updateWith from 'lodash/updateWith';
import hasIn from 'lodash/hasIn';

import { Block, Stage } from 'system/Stages/types';
import { Step, SubStep } from 'system/Routing/types';
import { navigateNext } from 'system/Routing/actions';
import { failedProfile } from 'system/Profile/actions';
import { makeSelectRouterParams } from 'system/Bootstrap/selectors';
import { qrCodeInitConnect } from 'system/Dossier/Upload/sagas/qr';
import { makeSelectStageCorrectionMistakes } from 'system/Profile/selectors';

import { FileType } from 'stages/Identity/selectors';
import { setIdentityInitialStep } from 'stages/Identity/actions';
import {
  setEnhancedStep,
  setEnhancedInitialStep,
} from 'stages/Enhanced/actions';
import { setAddressInitialStep, setAddressStep } from 'stages/Address/actions';
import { setAnswer } from 'stages/Questionnaire/actions';

import { makeErrorMessage } from 'utils/error';
import { joinByChar } from 'utils/helpers';
import { Field } from 'utils/types/shared/enums';

import { setCorporateStep, successCorporateBlockSet } from 'stages/Corporate/actions';
import { requestCache } from 'stages/Corporate/sagas/cache';
import { Type as UploadType } from './Upload/actionTypes';
import { ISetDossierStepSubmit, Type } from './actionTypes';
import { fillUserDossierFinished } from './actions';
import { mapTokenToFile } from './Upload/utils';

const omitEmptyAndMistakes = (mistakes: string[]) => (
  value: string | boolean,
  key: string
) => {
  return mistakes.includes(key) || value === '';
};

const omitBooleanAndEmptyAndMistakes = (mistakes: string[]) => (
  value: string | boolean,
  key: string
) => {
  return mistakes.includes(key) || (!isBoolean(value) && isEmpty(value));
};

export function* fillUserDossier(profile: any) {
  try {
    const mistakesIdentity = (yield select(
      makeSelectStageCorrectionMistakes('identity')
    )).toJS();

    const mistakesAddress = (yield select(
      makeSelectStageCorrectionMistakes('address')
    )).toJS();

    const mistakesEnhanced = (yield select(
      makeSelectStageCorrectionMistakes('enhanced')
    )).toJS();

    // identity
    const residenceProfile = get(profile, 'residence');
    const fullResidenceProfile = get(profile, 'fullResidence');
    const identityDocumentProfile = get(profile, 'identityDocument');

    // address
    const addressDocumentProfile = get(profile, 'addressDocument');
    const socialNetworkProfilesProfile = get(profile, 'socialNetworkProfiles');

    // enhanced
    const employmentProfile = get(profile, 'employment');
    const sourceOfFundsProfile = get(profile, 'sourceOfFunds');
    const questionnaireProfile = get(profile, 'questionnaire');

    // corporate
    const companyDocumentsProfile = get(profile, 'companyDocuments');
    const personalInfo = get(profile, 'personalInfo');
    const paymentDetails = get(profile, 'paymentDetails');

    if (!isEmpty(residenceProfile) && has(residenceProfile, Field.COUNTRY)) {
      const countryResidenceFields = omitBy(
        pick(residenceProfile, [
          Field.COUNTRY,
          Field.REGION,
          Field.SUB_REGION,
          Field.SSN,
          Field.ZIP_CODE,
          Field.REGION,
          Field.SUB_REGION,
          Field.CITY,
          Field.STREET,
          Field.APT_SUITE,
          `${Field.PHONE}.${Field.PHONE_CODE}`,
          `${Field.PHONE}.${Field.PHONE_COUNTRY}`,
          `${Field.PHONE}.${Field.PHONE_NUMBER}`,
        ]),
        omitEmptyAndMistakes(mistakesIdentity)
      );

      if (!isEmpty(countryResidenceFields)) {
        yield put(
          setIdentityInitialStep({
            stepId: joinByChar(Step.RESIDENCE),
            fields: countryResidenceFields,
          })
        );
      }
    }

    if (
      !isEmpty(fullResidenceProfile) &&
      has(fullResidenceProfile, Field.COUNTRY)
    ) {
      const countryResidenceFields = omitBy(
        pick(fullResidenceProfile, [
          Field.COUNTRY,
          Field.REGION,
          Field.SUB_REGION,
          Field.SSN,
          Field.ZIP_CODE,
          Field.STREET,
          Field.CITY,
          Field.APT_SUITE,
          `${Field.PHONE}.${Field.PHONE_CODE}`,
          `${Field.PHONE}.${Field.PHONE_COUNTRY}`,
          `${Field.PHONE}.${Field.PHONE_NUMBER}`,
        ]),
        omitEmptyAndMistakes(mistakesIdentity)
      );

      if (!isEmpty(countryResidenceFields)) {
        yield put(
          setIdentityInitialStep({
            stepId: joinByChar(Step.FULL_RESIDENCE),
            fields: countryResidenceFields,
          })
        );
      }
    }

    if (!isEmpty(identityDocumentProfile)) {
      const identityDocumentFields = omitBy(
        pick(identityDocumentProfile, [Field.SUB_TYPE, Field.ISSUING_COUNTRY]),
        omitEmptyAndMistakes(mistakesIdentity)
      );

      const imagesTokens = get(identityDocumentProfile, Field.IMAGES, []);
      const selfieImageToken = get(
        identityDocumentProfile,
        Field.SELFIE_IMAGE,
        ''
      );

      const uploadFields =
        !isEmpty(imagesTokens) || !isEmpty(selfieImageToken)
          ? [
              ...imagesTokens.map((token: string) => ({ fileToken: token })),
              { id: FileType.selfie, fileToken: selfieImageToken },
            ]
          : [];

      const personalInformationFields = omitBy(
        pick(get(identityDocumentProfile, 'personalData'), [
          Field.NATIONALITY,
          Field.FIRST_NAME,
          Field.MIDDLE_NAME,
          Field.LAST_NAME,
          Field.GENDER,
          Field.DATE_OF_BIRTH,
        ]),
        omitEmptyAndMistakes(mistakesIdentity)
      );

      const subTypeInformationFields = omitBy(
        pick(identityDocumentProfile, [
          Field.SERIAL_NUMBER,
          Field.ISSUING_COUNTRY,
          Field.EXPIRE_DATE,
          Field.HAS_EXPIRE_DATE,
        ]),
        omitEmptyAndMistakes(mistakesIdentity)
      );

      if (!isEmpty(identityDocumentFields)) {
        yield put(
          setIdentityInitialStep({
            stepId: joinByChar(Step.IDENTITY_DOCUMENT),
            fields: identityDocumentFields,
          })
        );
      }

      if (!isEmpty(uploadFields)) {
        yield put(
          setIdentityInitialStep({
            stepId: joinByChar(Step.UPLOAD),
            fields: uploadFields,
          })
        );
      }

      if (!isEmpty(personalInformationFields)) {
        yield put(
          setIdentityInitialStep({
            stepId: joinByChar(Step.PERSONAL_INFORMATION),
            fields: personalInformationFields,
          })
        );
      }

      if (
        !isEmpty(subTypeInformationFields) &&
        has(identityDocumentProfile, Field.SUB_TYPE)
      ) {
        const stepIds = {
          internationalPassport: Step.INTERNATIONAL_PASSPORT_INFORMATION,
          nationalIdCard: Step.NATIONAL_ID_CARD_INFORMATION,
          driverLicense: Step.DRIVER_LICENSE_INFORMATION,
        };
        const stepId = joinByChar(
          get(stepIds, get(identityDocumentProfile, Field.SUB_TYPE))
        );

        // hack that's set an empty expireDate
        // and disable it for correction by adding a meta field for expireDate
        // otherwise just set an empty expireDate
        const fields = { expireDate: '', ...subTypeInformationFields };

        yield put(
          setIdentityInitialStep({
            stepId,
            fields,
          })
        );
      }
    }

    if (!isEmpty(addressDocumentProfile)) {
      const addressResidenceFields = omitBy(
        pick(get(addressDocumentProfile, 'address'), [
          Field.COUNTRY,
          Field.REGION,
          Field.SUB_REGION,
          Field.ZIP_CODE,
          Field.STREET,
          Field.CITY,
          Field.APT_SUITE,
        ]),
        omitEmptyAndMistakes(mistakesAddress)
      );

      const addressDocumentsFields = omitBy(
        pick(addressDocumentProfile, [Field.SUB_TYPE, Field.SUB_TYPE_DETAILED]),
        omitEmptyAndMistakes(mistakesAddress)
      );

      const imagesTokens = get(
        omitBy(
          pick(addressDocumentProfile, [Field.IMAGES]),
          omitEmptyAndMistakes(mistakesAddress)
        ),
        'images',
        []
      );
      const uploadFields = !isEmpty(imagesTokens)
        ? [...imagesTokens.map((token: string) => ({ fileToken: token }))]
        : [];

      if (!isEmpty(addressResidenceFields)) {
        yield put(
          setAddressInitialStep({
            stepId: joinByChar(Step.ADDRESS_RESIDENCE),
            fields: addressResidenceFields,
          })
        );
      }

      if (!isEmpty(addressDocumentsFields)) {
        yield put(
          setAddressInitialStep({
            stepId: joinByChar(Step.ADDRESS_DOCUMENTS),
            fields: {
              ...addressDocumentsFields,
              ...(isEmpty(uploadFields) ? {} : { images: uploadFields }),
            },
          })
        );
      }
    }

    if (!isEmpty(socialNetworkProfilesProfile)) {
      yield put(
        setAddressInitialStep({
          stepId: joinByChar(Step.SOCIAL_PROFILES),
          fields: socialNetworkProfilesProfile,
        })
      );
    }

    if (!isEmpty(employmentProfile)) {
      const employmentFields = omitBy(
        pick(employmentProfile, [
          Field.STATUS,
          Field.TAX_RESIDENCE,
          Field.TIN,
          Field.EMPLOYMENT_PERIOD,
          Field.POSITION,
          Field.POSITION_DETAILED,
          Field.SELF_EMPLOYMENT_TYPE,
          Field.SELF_EMPLOYMENT_TYPE_DETAILED,
          Field.SELF_EMPLOYMENT_INDUSTRY,
          Field.SELF_EMPLOYMENT_INDUSTRY_DETAILED,
        ]),
        omitEmptyAndMistakes(mistakesEnhanced)
      );
      const employmentDetailsFields = omitBy(
        pick(get(employmentProfile, 'company'), [
          Field.INDUSTRY,
          Field.INDUSTRY_DETAILED,
          Field.NAME,
          Field.COUNTRY,
        ]),
        omitEmptyAndMistakes(mistakesEnhanced)
      );

      if (!isEmpty(employmentFields)) {
        yield put(
          setEnhancedInitialStep({
            stepId: joinByChar(Step.EMPLOYMENT),
            fields: employmentFields,
          })
        );
      }

      if (!isEmpty(employmentDetailsFields)) {
        yield put(
          setEnhancedInitialStep({
            stepId: joinByChar(Step.EMPLOYMENT_DETAILS),
            fields: employmentDetailsFields,
          })
        );
      }
    }

    if (!isEmpty(sourceOfFundsProfile)) {
      const sourceOfFiatFundsFields = omitBy(
        pick(get(sourceOfFundsProfile, Field.SOURCE_OF_FIAT_FUNDS), [
          Field.MONTHLY_INCOME,
          Field.DETAILED_SOURCES,
        ]),
        (value: any, key: any) => {
          return (
            (mistakesEnhanced.includes(Field.SOURCE_OF_FIAT_FUNDS) &&
              key === Field.DETAILED_SOURCES) ||
            isEmpty(value)
          );
        }
      );

      if (hasIn(sourceOfFiatFundsFields, Field.DETAILED_SOURCES)) {
        updateWith(
          sourceOfFiatFundsFields,
          Field.DETAILED_SOURCES,
          (detailedSource: any[]) => {
            return detailedSource.map((source: any) => {
              const imagesWithFileTokens = get(
                source,
                'images',
                []
              ).map((fileToken: string) => ({ fileToken }));
              return { ...source, images: imagesWithFileTokens };
            });
          }
        );
      }

      if (!isEmpty(sourceOfFiatFundsFields)) {
        yield put(
          setEnhancedInitialStep({
            stepId: joinByChar(Step.SOURCE_OF_FIAT_FUNDS),
            fields: sourceOfFiatFundsFields,
          })
        );
      }

      const sourceOfCryptoFundsFields = omitBy(
        pick(get(sourceOfFundsProfile, Field.SOURCE_OF_CRYPTO_FUNDS), [
          Field.CRYPTO_FUNDS_DEPOSIT,
          Field.DETAILED_SOURCES,
        ]),
        () => mistakesEnhanced.includes(Field.SOURCE_OF_CRYPTO_FUNDS)
      );

      if (!isEmpty(sourceOfCryptoFundsFields)) {
        yield put(
          setEnhancedInitialStep({
            stepId: joinByChar(Step.SOURCE_OF_CRYPTO_FUNDS),
            fields: sourceOfCryptoFundsFields,
          })
        );
      }

      const withdrawalDestinationFields = omitBy(
        pick(get(sourceOfFundsProfile, Field.WITHDRAWAL_DESTINATION), [
          Field.CRYPTO_FUNDS_WITHDRAW,
          Field.DETAILED_DESTINATIONS,
        ]),
        () => mistakesEnhanced.includes(Field.WITHDRAWAL_DESTINATION)
      );

      if (!isEmpty(withdrawalDestinationFields)) {
        yield put(
          setEnhancedInitialStep({
            stepId: joinByChar(Step.WITHDRAWAL_DESTINATION),
            fields: withdrawalDestinationFields,
          })
        );
      }
    }

    if (!isEmpty(questionnaireProfile)) {
      const bankingJurisdictionFields = omitBy(
        pick(questionnaireProfile, [
          Field.PLANNED_TURNOVER,
          Field.PRIMARY_PURPOSE,
          Field.PRIMARY_PURPOSE_DETAILED,
          Field.BANK_JURISDICTION,
        ]),
        omitEmptyAndMistakes(mistakesEnhanced)
      );

      const financialQuestionnaireFields = omitBy(
        pick(questionnaireProfile, [
          Field.PEP_CLOSE_ASSOCIATE,
          Field.PEP_CLOSE_ASSOCIATE_DETAILED,
          Field.HAS_PEP_RELATIVES,
          Field.PEP_RELATIVES_DETAILED,
          Field.PEP_POSITION,
          Field.US_TAXPAYER,
          Field.US_CITIZEN,
          Field.IS_PEP,
          Field.US_RESIDENT_ALIEN,
        ]),
        omitBooleanAndEmptyAndMistakes(mistakesEnhanced)
      );

      if (!isEmpty(bankingJurisdictionFields)) {
        yield put(
          setEnhancedInitialStep({
            stepId: joinByChar(Step.BANKING_JURISDICTION),
            fields: bankingJurisdictionFields,
          })
        );
      }

      if (!isEmpty(financialQuestionnaireFields)) {
        yield put(
          setEnhancedInitialStep({
            stepId: joinByChar(Step.FINANCIAL_QUESTIONNAIRE),
            fields: financialQuestionnaireFields,
          })
        );
      }
    }

    if (!isEmpty(companyDocumentsProfile)) {
      const mappedDocsFields = Object.keys(companyDocumentsProfile).reduce(
        (acc: any, item: any) => {
          acc[item] = companyDocumentsProfile[item].map(mapTokenToFile);
          return acc;
        },
        {}
      );

      yield put(
        setCorporateStep({
          blockId: joinByChar(Step.COMPANY_DOCUMENTS),
          stepId: joinByChar(SubStep.UPLOAD),
          fields: mappedDocsFields,
        })
      );
    }

    if (!isEmpty(personalInfo)) {
      yield put(
        successCorporateBlockSet({
          data: personalInfo,
          blockId: joinByChar(Step.PERSONAL_INFO),
        })
      );
      yield call(requestCache, { blockId: Block.PERSONAL_INFO, defaultData: personalInfo } )
    } else {
      yield call(requestCache, { blockId: Block.PERSONAL_INFO } )
    }
    if (!isEmpty(paymentDetails)) {
      yield put(successCorporateBlockSet({
        data: paymentDetails,
        blockId: joinByChar(Step.PAYMENT_DETAILS),
      }));
      yield call(requestCache, { blockId: Block.PAYMENT_DETAILS, defaultData: paymentDetails } )
    } else {
      yield call(requestCache, { blockId: Block.PAYMENT_DETAILS } )
    }
    yield put(fillUserDossierFinished());
  } catch (err) {
    yield put(failedProfile([makeErrorMessage(err)]));
  }
}

// eslint-disable-next-line consistent-return
function* watchForSubmitDossierStep(action: ISetDossierStepSubmit) {
  try {
    const {
      payload: { fields },
    } = action;

    const {
      params: { stageId, stepId },
    } = yield select(makeSelectRouterParams());

    switch (stageId) {
      case Stage.ADDRESS:
        yield put(setAddressStep({ stepId: joinByChar(stepId), fields }));
        break;
      case Stage.ENHANCED:
        yield put(setEnhancedStep({ stepId: joinByChar(stepId), fields }));
        break;
      case Stage.CFD:
        yield put(setAnswer(fields));
        break;
      default:
        break;
    }

    yield put(navigateNext());
  } catch (err) {
    console.log(err);
  }
}

export function* rootSaga() {
  yield takeLatest(Type.DOSSIER_STEP_SUBMIT, watchForSubmitDossierStep);
  yield takeEvery(UploadType.QR_INIT_CONNECT, qrCodeInitConnect);
}
