import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { List, Map } from 'immutable';

import { Stage, StageStatuses } from 'system/Stages/types';

import { fetchUserProfile } from 'system/Profile/sagas';
import { makeSelectRouterParams } from 'system/Bootstrap/selectors';
import {
  makeSelectIssuerFallbackLink,
  makeSelectWorkingMode,
} from 'system/Settings/selectors';
import { makeSelectStages } from 'system/Profile/selectors';

import { Stub } from '../types';

import { makeSelectEntryUrls } from '../selectors';

import { symlinkUrlByStatus } from '../utils';
import { Routes } from '../Routes';

import { handleStageSubmit, routingInitialize } from './processes';
import { qrCodeIsConnected } from '../../Dossier/Upload/sagas/qr';
import { makeSelectStageProgress } from '../../Dossier/selectors';
import { Type } from '../actionTypes';

function* handleEntryNavigationForSingleStageFlow({
  stages,
  entryUrls,
}: {
  stages: List<any>;
  entryUrls: Map<any, any>;
}) {
  const stage = stages.first() || Map();
  const status = stage.get('status', '');
  const stageId = stage.get('id', '');

  if (![StageStatuses.DRAFT].includes(status)) {
    yield put(push(entryUrls.get(stageId)));
  } else {
    yield put(push(Routes.root()));
  }
}

function* handleEntryNavigationForMultiStageFlow({
  stages,
  entryUrls,
}: {
  stages: List<any>;
  entryUrls: Map<any, any>;
}) {
  const routeParams = yield select(makeSelectRouterParams());

  if (!routeParams) {
    return;
  }
  const {
    params: { stageId },
  } = routeParams;

  const stageStatus = stages
    .find((stage: Map<any, any>) => stage.get('id') === stageId, null, Map())
    .get('status', '');

  if (![StageStatuses.DRAFT, StageStatuses.CORRECTION].includes(stageStatus)) {
    yield put(push(Routes.root()));
  } else {
    yield put(push(entryUrls.get(stageId)));
  }
}

export function* navigateToEntryUrlByStageStatus() {
  const stages = yield select(makeSelectStages());
  const entryUrls = yield select(makeSelectEntryUrls());

  if (stages.size < 2) {
    yield call(handleEntryNavigationForSingleStageFlow, {
      stages,
      entryUrls,
    });
  } else {
    yield call(handleEntryNavigationForMultiStageFlow, {
      stages,
      entryUrls,
    });
  }
}

export function* handleNavigateNext({
  nextStepId,
  stageId,
}: {
  isQrCodeConnected: string;
  nextStepId: string;
  stageId: Stage;
}) {
  if (!nextStepId) {
    yield call(handleStageSubmit, stageId);
    return;
  }

  yield put(push(Routes.url({ stageId, stepId: nextStepId })));
}

export function* handleNavigateBack({
  stageId,
  prevStepId,
}: {
  stageId: Stage;
  prevStepId: string;
}) {
  if (!prevStepId) {
    yield put(push(Routes.root()));
    return;
  }
  yield put(push(Routes.url({ stageId, stepId: prevStepId })));
}

export function* handleStageNavigation(action: { type: Type }) {
  const isQrCodeConnected = yield qrCodeIsConnected();

  if (isQrCodeConnected) {
    return;
  }

  const routerPath = yield select(makeSelectRouterParams());
  const stageProgress = yield select(makeSelectStageProgress());

  const { prevStepId, nextStepId } = stageProgress.toJS();
  const {
    params: { stageId },
  } = routerPath;

  switch (action.type) {
    case Type.ROUTING_NAVIGATE_NEXT:
      yield call(handleNavigateNext, {
        isQrCodeConnected,
        nextStepId,
        stageId,
      });
      break;

    case Type.ROUTING_NAVIGATE_BACK:
      yield call(handleNavigateBack, { stageId, prevStepId });
      break;

    default:
      console.warn(
        `Unexpected action type in processingStageNavigation. Action type: ${action.type}`
      );
      yield put(push(Routes.root()));
      break;
  }
}

// eslint-disable-next-line consistent-return
export function* navigateToIndex(action?: {
  payload: { refetchUserProfile?: boolean; exit?: boolean };
}) {
  const { exit, refetchUserProfile = true } = action?.payload || {};

  if (exit) {
    const fallback = yield select(makeSelectIssuerFallbackLink);
    return window.location.replace(fallback);
  }

  if (refetchUserProfile) {
    yield call(fetchUserProfile);
  }

  yield call(routingInitialize);

  yield put(push(Routes.root()));
}

export function* navigateAfterSubmit(stages: any, { refetchUserProfile }: { refetchUserProfile: boolean }) {
  const workingMode = yield select(makeSelectWorkingMode());
  const isSingleStage = workingMode === 'singleStage';

  const {
    params: { stageId, stepId },
  } = yield select(makeSelectRouterParams());

  const currentStageStatus = isSingleStage
    ? stages[0]?.status
    : stages.find((stage: any) => stage.id === stageId)?.status;

  if (
    isSingleStage ||
    (stageId === Stage.CFD && currentStageStatus === StageStatuses.TOBE_ASKED)
  ) {
    yield put(
      push(
        Routes.url({
          stageId,
          stepId,
          stubId: symlinkUrlByStatus(currentStageStatus),
        })
      )
    );
  } else {
    yield call(navigateToIndex, { payload: { refetchUserProfile } });
  }
}

export function* navigateToSubmittedLayout() {
  const path = yield select(makeSelectRouterParams());
  if (path) {
    const {
      params: { stageId, stepId },
    } = path;
    yield put(push(Routes.url({ stageId, stepId, stubId: Stub.SUBMITTED })));
  }
}

export function* navigateToErrorLayout() {
  const path = yield select(makeSelectRouterParams());

  if (path) {
    const {
      params: { stageId, stepId },
    } = path;
    yield put(push(Routes.url({ stageId, stepId, stubId: Stub.ERROR })));
  }
}
