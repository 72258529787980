import { List, Map } from 'immutable';
import isEmpty from 'lodash/isEmpty'

import { serialize } from 'utils/serialization';

export const create = (...args: string[]) => {
  return new RegExp(`^([${args.join('')}]+)$`, 'gu');
};

export const escape = (chars = '') => {
  return chars
    .split('')
    .map((char: string) => `\\${char}`)
    .join('');
};

export const serializeError = (key: string, ...args: any[]): string => {
  return serialize({ key, args });
};

export const ifEmptyString = (value: string | null | undefined) => {
  if (value) {
    return Boolean(value.trim())
  }
  return false;
};

const isValidCountryDictItem = (countryDict: List<any>, item: any) => {
  const foundedItem = countryDict.find(
    (it: Map<any, any>) => it.get('id') === item,
    null,
    Map()
  );

  return !foundedItem.isEmpty();
};

export const isValidValue = (
  value: string | { [k: string]: any },
  countryDict: List<any>
): boolean => {
  return typeof value === 'string'
    ? isValidCountryDictItem(countryDict, value) // can be prefilled as a string (f.e. "US")
    : !isEmpty(value); // manual selected from dropdown
};

export const validateRegion = (region: any, schema: any) => {
  if (region.isEmpty()) {
    return schema.notRequired();
  }

  return schema.test(
    'isValidRegion',
    'error_field_required',
    (value: any) => isValidValue(value, region)
  );
};
