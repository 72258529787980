export enum Type {
  QR_INIT_CONNECT = '@Dossier/Upload/QR_INIT_CONNECT',
  QR_SET_READY_STATE = '@Dossier/Upload/QR_SET_READY_STATE',
  QR_SET_UPLOAD_ID = '@Dossier/Upload/QR_SET_UPLOAD_ID',
  QR_SET_WLTFS_TOKEN = '@Dossier/Upload/QR_SET_WLTFS_TOKEN',
}

export interface IQrInitConnect {
  type: Type.QR_INIT_CONNECT;
  payload: {
    onComplete: any;
    stageId: string;
    stepId: string;
    blockId?: string;
  };
}

export interface IQrSetReadyState {
  type: Type.QR_SET_READY_STATE;
  payload: number;
}

export interface IQrSetUploadId {
  type: Type.QR_SET_UPLOAD_ID;
  payload: string;
}

export interface IQRSetWltfsToken {
  type: Type.QR_SET_WLTFS_TOKEN;
  payload: string;
}

export type ActionType =
  | IQrInitConnect
  | IQrSetReadyState
  | IQrSetUploadId
  | IQRSetWltfsToken;
