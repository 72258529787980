import styled from 'styled-components';

import { Button, IButtonProps } from '../Button';

export type IButtonPrimary = IButtonProps

export const Primary = styled(Button)`
  background-color: ${({ theme }: IButtonPrimary) => theme.buttons.primary.backgroundColor};
  border: ${({ theme }) => theme.buttons.primary.border};
  color: ${({ theme }) => theme.buttons.primary.color};
  border-radius: ${({ theme }) => theme.buttons.primary.borderRadius};

  :not(:disabled) {
    :hover {
      background-color: ${({ theme }) => theme.buttons.primary.hover.backgroundColor};
      border: ${({ theme }) => theme.buttons.primary.hover.border};
      color: ${({ theme }) => theme.buttons.primary.hover.color};
    }
  }
`;
