import { DependencyList, useEffect, useState } from 'react';

export const useToggle = (defaultValue = false): any[] => {
  const [state, setState] = useState<boolean>(defaultValue);

  const handleToggleState = () => {
    setState((prevState) => !prevState);
  };

  return [state, handleToggleState];
};

interface IUseToggleWithTimeoutProps {
  defaultValue: boolean;
  timeout?: number;
  deps?: DependencyList;
}

export const useToggleWithTimeout = ({ defaultValue, timeout = 3000, deps = [] }: IUseToggleWithTimeoutProps) => {
  const [state, handleToggleState] = useToggle(defaultValue);
  let timeoutID: number | undefined;

  const handleStartToggleTimeout = (): void => {
    timeoutID = window.setTimeout(() => {
      handleToggleState()
    }, timeout);
  };

  useEffect(() => {
    return () => window.clearInterval(timeoutID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [state, handleStartToggleTimeout];
};
