import { put } from 'redux-saga/effects';

import { makeErrorMessage } from 'utils/error';

import { ProfileSource } from 'utils/externalProfiles/ProfileSource';
import { Backend } from 'utils/externalProfiles/sources/Backend';

import {
  failedProfile,
  setRecognizerProfile,
} from '../actions';

export function* setRecognizerUserProfile({ data, fileNames }: any) {
  try {
    const source = new ProfileSource(new Backend(data, fileNames));
    const profile = source.getSourceData();

    yield put(setRecognizerProfile(profile));
  } catch (err) {
    yield put(failedProfile([makeErrorMessage(err)]));
  }
}
