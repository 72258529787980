import styled from 'styled-components';

import { setColorByState } from 'utils/styledComponents/helpers';
import { IThemeProps } from 'merchants/types/IThemeStyles';

interface IInputThemeProps extends IThemeProps {
  disabled?: boolean;
  error?: boolean;
  valid?: boolean;
}

export const Input = styled.input.attrs((props: any) => {
  return props;
})`
  width: 100%;
  padding: 9px 16px;
  background-color: ${({ theme, disabled, error }: IInputThemeProps) =>
    setColorByState(
      {
        default: theme.forms.input.backgroundColor,
        disabled: theme.forms.input.disabled.backgroundColor,
      },
      { disabled, error }
    )};
  border-style: solid;
  border-width: ${({ theme }: IInputThemeProps) =>
    theme.forms.input.borderWidth};
  border-color: ${({ theme, disabled, error, valid }: IInputThemeProps) =>
    setColorByState(
      {
        default: theme.forms.input.borderColor,
        disabled: theme.forms.input.disabled.borderColor,
        error: theme.forms.input.error.borderColor,
        valid: theme.forms.input.valid?.borderColor,
      },
      { disabled, error, valid }
    )};
  border-radius: ${({ theme }: IInputThemeProps) =>
    theme.forms.input.borderRadius};
  box-shadow: ${({ theme }) => theme.forms.input.boxShadow};
  color: ${({ theme, disabled, error }: IInputThemeProps) =>
    setColorByState(
      {
        default: theme.forms.input.color,
        disabled: theme.forms.input.disabled.color,
      },
      { disabled, error }
    )};
  font-size: ${({ theme }: IInputThemeProps) => theme.forms.input.fontSize};
  outline: none;
  ${({ disabled }) => (disabled ? 'opacity: .4;' : '')}

  &:focus {
    background-color: ${({ theme }: IInputThemeProps) =>
      theme.forms.input.focus.backgroundColor};
    border-color: ${({ theme }: IInputThemeProps) =>
      theme.forms.input.focus.borderColor};
  }
`;

Input.displayName = 'Input';
