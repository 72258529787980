import styled from 'styled-components';

import { CountryId } from 'utils/types/shared/types';
import { WithThemeProps } from 'merchants/types/IThemeStyles';
import { joinIfNotEmpty  } from 'utils/helpers';
import { coordinates } from './coordinates';

import flags from './images/flags.png';

interface IFlagPros {
  countryCode: CountryId;
  cdn: string;
}

export const Flag = styled.span.attrs<WithThemeProps<IFlagPros>>(({ countryCode, ...props }) => {
  const index: keyof typeof coordinates | string = countryCode ? countryCode.toLocaleLowerCase() : '';
  const backgroundPosition = index ? coordinates[index] : '';
  return backgroundPosition
    ? {
        ...props,
        style: {
          backgroundPosition,
        },
      }
    : { ...props };
})`
  display: inline-block;
  margin-right: 10px;
  width: 16px;
  height: 11px;
  background: ${({ cdn = '' }: WithThemeProps<IFlagPros>) => `url(${joinIfNotEmpty(cdn, flags)})`} no-repeat;
`;
