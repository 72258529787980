/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import { makeSelectEntryUrls } from 'system/Routing/selectors';
import { makeSelectStages } from 'system/Profile/selectors';
import { makeSelectStageProgress } from 'system/Dossier/selectors';

import { IndexPage } from 'stages/Index';

function MultiplyStageEntryPoint() {
  return <IndexPage />;
}

function SingleStageEntryPoint({ entryUrl }: { entryUrl: string }) {
  return <Redirect to={entryUrl} />;
}

interface RouteStartVerificationContainerProps {
  stages: Map<string, any>;
  entryUrls:  Map<string, any>;
  forceSetSingleStageEntryPoint?: boolean;
}

function RouteStartVerificationContainer({
  stages,
  entryUrls,
  forceSetSingleStageEntryPoint = false,
}: RouteStartVerificationContainerProps) {
  const stage = stages.first() || Map();
  const stageId = stage.get('id', '');
  const entryUrl = entryUrls.get(stageId);

  if (forceSetSingleStageEntryPoint) {
    return <SingleStageEntryPoint entryUrl={entryUrl} />;
  }

  return stages.size > 1 ? (
    <MultiplyStageEntryPoint />
  ) : (
    <SingleStageEntryPoint entryUrl={entryUrl} />
  );
}

const mapStateToProps = createStructuredSelector({
  stages: makeSelectStages(),
  stagesProgress: makeSelectStageProgress(),
  entryUrls: makeSelectEntryUrls(),
});

const mapDispatchToProps = null;

export const RouteStartVerification = connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteStartVerificationContainer);
