import { call, put, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import { makeErrorMessage } from 'utils/error';
import { blockCacheRequest } from 'system/Stages/sagas';
import { joinByChar } from 'utils/helpers';
import { navigateToErrorLayout } from 'system/Routing/sagas';
import { Block } from 'system/Stages/types';
import { failedBlockCache, successCorporateBlockSet } from '../actions';
import { ICorporateBlockCacheRemove } from '../actionTypes';
import { selectCorporateBlock } from '../selectors';

export function* requestCache({ userData, defaultData, blockId }: any = {}) {
  try {
    const response = yield call(blockCacheRequest, {
      blockId: joinByChar(blockId),
      ...(userData ? { userData } : {}),
    });

    const data = isEmpty(response) && userData ? userData : response;

    yield put(
      successCorporateBlockSet({
        data: isEmpty(response) && defaultData ? defaultData : data,
        blockId: joinByChar(blockId),
      })
    );
  } catch (err) {
    yield put(failedBlockCache([makeErrorMessage(err)]));
  }
}

export function* watchBlockCacheRemove(action: ICorporateBlockCacheRemove) {
  try {
    const {
      payload: { blockId, idx, path },
    } = action;
    const userData = yield select(selectCorporateBlock(blockId as Block));
    
    const keyPath = isArray(path) ? path : [path];

    const nextData = userData.hasIn(keyPath)
      ? userData.getIn(keyPath).delete(idx)
      : userData.getIn(keyPath);

    yield call(requestCache, {
      blockId,
      userData: userData.setIn(keyPath, nextData),
    });
  } catch (err) {
    yield put(failedBlockCache([makeErrorMessage(err)]));
    yield call(navigateToErrorLayout);
  }
}
