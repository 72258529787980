export class ProfileSource {
  private strategy: any;

  constructor(strategy: any) {
    this.strategy = strategy;
  }

  setStrategy(strategy: any) {
    this.strategy = strategy;
  }

  getStrategy() {
    return this.strategy;
  }

  getSourceData () {
    return this.strategy.getSourceData();
  }
}

