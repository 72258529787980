import React, { FunctionComponent, MouseEventHandler, MutableRefObject, ReactNode } from 'react';
import styled from 'styled-components';

import { ProductType, StageId } from 'system/Stages/types';
import { ITranslate } from 'system/Localization';

import { Badge } from 'components/UI/Badge';
import { Button } from 'components/UI/Button/Button';

import { WithThemeProps } from 'merchants/types/IThemeStyles';
import {
  STAGE_PREVIEW_SHORT_WIDTH_DESKTOP,
  STAGE_PREVIEW_SHORT_CONTENT_HEIGHT_DESKTOP,
} from './utils/constants';

import { StageStatusBadge, BadgeStatuses } from './StageStatusBadge';
import { StageStatusBox, StatusBox, IStageStatus } from './StageStatusBox';

interface IStagePreviewShort {
  startEndpoint: string;
  days: string | ReactNode;
  email: string;
  forwardRef?: MutableRefObject<HTMLDivElement | null>;
  imgSrc: string;
  notInQueue: boolean;
  onClick: MouseEventHandler;
  queueNumber: number;
  stageMessage: ReactNode;
  status: keyof IStageStatus;
  subtitle: ReactNode;
  title: ReactNode;
  stageId: StageId;
  handleRetryStage: () => void;
  product?: string;
  translate: ITranslate;
}

export const StagePreviewShort: FunctionComponent<IStagePreviewShort> = ({
  startEndpoint,
  days,
  email,
  forwardRef,
  imgSrc,
  notInQueue,
  onClick,
  queueNumber,
  stageMessage,
  status,
  subtitle,
  title,
  stageId,
  translate,
  handleRetryStage,
  product,
}) => {
  const hasBadgeStatus: boolean = [
    'accepted',
    'correction',
    'next_stage_needed',
    'current_stage_needed',
    'pending',
    'refused',
    'inprogress',
  ].includes(status as BadgeStatuses);

  return (
    <StageBoxShort ref={forwardRef} status={status}>
      {product !== ProductType.BROKER ? (
        <ModalOpenButton onClick={onClick} data-aqaid={`${stageId}-info-modal`}>
          {translate('get_info')}
        </ModalOpenButton>
      ) : null}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {title && <Title>{title}</Title>}
      <PreviewImage
        status={status}
        style={{
          backgroundImage: `url(${imgSrc})`,
        }}
      />
      {hasBadgeStatus && (
        <StageStatusBadge
          status={status as BadgeStatuses}
          translate={translate}
        />
      )}
      <Content>
        <StageStatusBox
          startEndpoint={startEndpoint}
          days={days}
          email={email}
          notInQueue={notInQueue}
          queueNumber={queueNumber}
          stageMessage={stageMessage}
          status={status}
          stageId={stageId}
          translate={translate}
          handleRetryStage={handleRetryStage}
        />
      </Content>
    </StageBoxShort>
  );
};

export const PreviewImage = styled.span<
  WithThemeProps<{
    status?: keyof IStageStatus;
    style?: React.CSSProperties;
  }>
>`
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  max-height: ${({ status }) => {
    if (status === 'locked') {
      return '138px';
    }
    return '266px';
  }};

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    max-height: 224px;
  }
`;

export const Content = styled.section`
  height: ${STAGE_PREVIEW_SHORT_CONTENT_HEIGHT_DESKTOP}px;
  flex-grow: 1;
  padding: 12px;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.components.stagePreview.titleColor};
  font-size: 24px;
  font-weight: bold;
  left: 16px;
  position: absolute;
  top: 70px;
  width: 220px;
`;

export const Subtitle = styled.span`
  color: ${({ theme }) => theme.components.stagePreview.titleColor};
  font-size: 13px;
  font-weight: bold;
  left: 16px;
  position: absolute;
  text-transform: uppercase;
  top: 44px;
`;

export const ModalOpenButton = styled(Button)<{ onClick: MouseEventHandler }>`
  background-color: #fff;
  border-radius: 50%;
  border: none;
  color: ${({ theme }) => theme.buttons.base.color};
  font-size: 12px;
  font-weight: bold;
  height: 96px;
  left: 50%;
  padding: 62px 0 0 0;
  position: absolute;
  top: -67px;
  transform: translateX(-50%);
  width: 96px;
`;

export const StageBoxShort = styled.div<
  WithThemeProps<{
    ref?: MutableRefObject<HTMLDivElement | null>;
    status: keyof IStageStatus;
  }>
>`
  border-radius: ${({ theme }) => theme.components.stagePreview.borderRadius};
  box-shadow: 0 2px 17px 0 rgba(147, 147, 147, 0.5);
  overflow: hidden;
  position: relative;
  max-height: 354px;

  @media (min-width: ${({ theme }) => theme.breakpoint.ablet}) {
    max-height: 308px;
    max-width: ${STAGE_PREVIEW_SHORT_WIDTH_DESKTOP}px;
  }

  ${StatusBox} {
    width: 100%;
  }

  ${Badge} {
    position: absolute;
    top: 200px;
    width: 100%;
  }
`;
