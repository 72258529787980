import { createSelector } from 'reselect';
import {
  makeSelectLicensing,
  makeSelectLicenseLink,
  makeSelectPermissionsAndAuthorizations,
  makeSelectPermissionsAndAuthorizationsLink,
  makeSelectAmlReportingEntity,
  makeSelectAmlProgram,
  makeSelectAmlProgramLink,
  makeSelectSharesTradedOnStockExchange,
  makeSelectStockExchangeLink,
  makeSelectAuditedEntity,
  makeSelectAuditorLink,
  makeSelectLicensingMeta,
  makeSelectLicenseLinkMeta,
  makeSelectPermissionsAndAuthorizationsMeta,
  makeSelectPermissionsAndAuthorizationsLinkMeta,
  makeSelectAmlReportingEntityMeta,
  makeSelectAmlProgramMeta,
  makeSelectAmlProgramLinkMeta,
  makeSelectSharesTradedOnStockExchangeMeta,
  makeSelectStockExchangeLinkMeta,
  makeSelectAuditedEntityMeta,
  makeSelectAuditorLinkMeta,
  makeSelectLicenseFilesValues,
  makeSelectPermissionsAndAuthorizationsFilesValues,
  makeSelectPermissionsAndAuthorizationsFilesMeta,
  makeSelectLicenseFilesMeta,
  makeSelectAmlProgramFileValue,
  makeSelectStockExchangeFileValue,
  makeSelectAuditorFileValue,
  makeSelectAmlProgramFilesMeta,
  makeSelectStockExchangeFilesMeta,
  makeSelectAuditorFilesMeta,
} from './licenses';

export const makeSelectLicensesBlockData = () =>
  createSelector(
    [
      makeSelectLicensing(),
      makeSelectLicenseLink(),
      makeSelectPermissionsAndAuthorizations(),
      makeSelectPermissionsAndAuthorizationsLink(),
      makeSelectAmlReportingEntity(),
      makeSelectAmlProgram(),
      makeSelectAmlProgramLink(),
      makeSelectSharesTradedOnStockExchange(),
      makeSelectStockExchangeLink(),
      makeSelectAuditedEntity(),
      makeSelectAuditorLink(),
      makeSelectLicenseFilesValues(),
      makeSelectPermissionsAndAuthorizationsFilesValues(),
      makeSelectAmlProgramFileValue(),
      makeSelectStockExchangeFileValue(),
      makeSelectAuditorFileValue(),
    ],
    (
      licensing,
      licenseLinks,
      permissionsAndAuthorizations,
      permissionsAndAuthorizationsLinks,
      amlReportingEntity,
      amlProgram,
      amlProgramLinks,
      sharesTradedOnStockExchange,
      stockExchangeLinks,
      auditedEntity,
      auditorLinks,
      proofOfLicensing,
      proofOfPermissionsAndAuthorizations,
      proofOfAmlProgram,
      proofOfSharesTradedOnStockExchange,
      proofOfAuditedEntity
    ) => ({
      licenses: {
        licensing,
        ...(licenseLinks && licenseLinks.length ? { licenseLinks } : {}),
        permissionsAndAuthorizations,
        ...(permissionsAndAuthorizationsLinks &&
        permissionsAndAuthorizationsLinks.length
          ? { permissionsAndAuthorizationsLinks }
          : {}),
        amlReportingEntity,
        amlProgram,
        ...(amlProgramLinks && amlProgramLinks.length
          ? { amlProgramLinks }
          : {}),
        sharesTradedOnStockExchange,
        ...(stockExchangeLinks && stockExchangeLinks.length
          ? { stockExchangeLinks }
          : {}),
        auditedEntity,
        ...(auditorLinks && auditorLinks.length ? { auditorLinks } : {}),
        ...(proofOfLicensing && proofOfLicensing.size
          ? {
              proofOfLicensing: proofOfLicensing.map(
                (file: Map<string, any>) => file.get('fileToken')
              ),
            }
          : {}),
        ...(proofOfPermissionsAndAuthorizations &&
        proofOfPermissionsAndAuthorizations.size
          ? {
              proofOfPermissionsAndAuthorizations: proofOfPermissionsAndAuthorizations.map(
                (file: Map<string, any>) => file.get('fileToken')
              ),
            }
          : {}),
        ...(proofOfAmlProgram && proofOfAmlProgram.size
          ? {
              proofOfAmlProgram: proofOfAmlProgram.map(
                (file: Map<string, any>) => file.get('fileToken')
              ),
            }
          : {}),
        ...(proofOfSharesTradedOnStockExchange &&
        proofOfSharesTradedOnStockExchange.size
          ? {
              proofOfSharesTradedOnStockExchange: proofOfSharesTradedOnStockExchange.map(
                (file: Map<string, any>) => file.get('fileToken')
              ),
            }
          : {}),
        ...(proofOfAuditedEntity && proofOfAuditedEntity.size
          ? {
              proofOfAuditedEntity: proofOfAuditedEntity.map(
                (file: Map<string, any>) => file.get('fileToken')
              ),
            }
          : {}),
      },
    })
  );
export const makeSelectLicensesBlockMeta = () =>
  createSelector(
    [
      makeSelectLicensingMeta(),
      makeSelectLicenseLinkMeta(),
      makeSelectPermissionsAndAuthorizationsMeta(),
      makeSelectPermissionsAndAuthorizationsLinkMeta(),
      makeSelectAmlReportingEntityMeta(),
      makeSelectAmlProgramMeta(),
      makeSelectAmlProgramLinkMeta(),
      makeSelectSharesTradedOnStockExchangeMeta(),
      makeSelectStockExchangeLinkMeta(),
      makeSelectAuditedEntityMeta(),
      makeSelectAuditorLinkMeta(),
      makeSelectPermissionsAndAuthorizationsFilesMeta(),
      makeSelectLicenseFilesMeta(),
      makeSelectAmlProgramFilesMeta(),
      makeSelectStockExchangeFilesMeta(),
      makeSelectAuditorFilesMeta(),
    ],
    (
      licensingMeta,
      licenseLinkMeta,
      permissionsAndAuthorizationsMeta,
      permissionsAndAuthorizationsLinkMeta,
      amlReportingEntityMeta,
      amlProgramMeta,
      amlProgramLinkMeta,
      sharesTradedOnStockExchangeMeta,
      stockExchangeLinkMeta,
      auditedEntityMeta,
      auditorLinkMeta,
      proofOfLicensingMeta,
      proofOfPermissionsAndAuthorizationsMeta,
      proofOfAmlProgramMeta,
      proofOfSharesTradedOnStockExchangeMeta,
      proofOfAuditedEntityMeta
    ) => {
      const licensing = licensingMeta.get('source');
      const licenseLink = licenseLinkMeta.get('source');
      const permissionsAndAuthorizations = permissionsAndAuthorizationsMeta.get(
        'source'
      );
      const permissionsAndAuthorizationsLink = permissionsAndAuthorizationsLinkMeta.get(
        'source'
      );
      const amlReportingEntity = amlReportingEntityMeta.get('source');
      const amlProgram = amlProgramMeta.get('source');
      const amlProgramLink = amlProgramLinkMeta.get('source');
      const sharesTradedOnStockExchange = sharesTradedOnStockExchangeMeta.get(
        'source'
      );
      const stockExchangeLink = stockExchangeLinkMeta.get('source');
      const auditedEntity = auditedEntityMeta.get('source');
      const auditorLink = auditorLinkMeta.get('source');
      const proofOfLicensing = proofOfLicensingMeta.get('source');
      const proofOfPermissionsAndAuthorizations = proofOfPermissionsAndAuthorizationsMeta.get(
        'source'
      );
      const proofOfAmlProgram = proofOfAmlProgramMeta.get('source');
      const proofOfSharesTradedOnStockExchange = proofOfSharesTradedOnStockExchangeMeta.get(
        'source'
      );
      const proofOfAuditedEntity = proofOfAuditedEntityMeta.get('source');
      return {
        licenses: {
          licensing,
          licenseLink,
          permissionsAndAuthorizations,
          permissionsAndAuthorizationsLink,
          amlReportingEntity,
          amlProgram,
          amlProgramLink,
          sharesTradedOnStockExchange,
          stockExchangeLink,
          auditedEntity,
          auditorLink,
          proofOfLicensing,
          proofOfPermissionsAndAuthorizations,
          proofOfAmlProgram,
          proofOfSharesTradedOnStockExchange,
          proofOfAuditedEntity,
        },
      };
    }
  );
