import { IValues } from '../../components/common/Feedback';

export enum Type {
  UI_SUBMITTING = '@UI/SUBMITTING',
  UI_SUBMITTED = '@UI/SUBMITTED',
  UI_SUBMITTING_FAILED = '@UI/SUBMITTING_FAILED',
  UI_FEEDBACK_SAVE = '@UI/FEEDBACK_SAVE',
  UI_ZIP_SUGGESTIONS_GET = '@UI/ZIP_SUGGESTIONS_GET',
  UI_ZIP_RETRIEVE_LOCATION = '@UI/ZIP_RETRIEVE_LOCATION',
  UI_RESUBMIT_STAGES = '@UI/UI_RESUBMIT_STAGES',
  UI_RETRY_STAGE = '@UI/UI_RETRY_STAGE',
  UI_RESET_STAGE_DATA = '@UI/UI_RESET_STAGE_DATA',
  UI_RETRY_STAGE_START = '@UI/UI_RETRY_STAGE_START',
  UI_RETRY_STAGE_FINISHED = '@UI/UI_RETRY_STAGE_FINISHED',
  UI_RESET_ERRORS = '@UI/UI_RESET_ERRORS',
}

export interface IResubmit {
  type: Type.UI_RESUBMIT_STAGES;
  payload: { stageId: string };
}

export interface IRetryStage {
  type: Type.UI_RETRY_STAGE;
  payload: { stageId: string };
}

export interface IRetryStageStart {
  type: Type.UI_RETRY_STAGE_START;
}

export interface IRetryStageFinished {
  type: Type.UI_RETRY_STAGE_FINISHED;
}

export interface IResetStageData {
  type: Type.UI_RESET_STAGE_DATA;
  payload: { stageId: string };
}

export interface IResetErrors {
  type: Type.UI_RESET_ERRORS;
}

export interface IFeedbackSave {
  type: Type.UI_FEEDBACK_SAVE;
  payload: IValues;
}

export interface IZipGet {
  type: Type.UI_ZIP_SUGGESTIONS_GET;
  payload: {
    query: string;
    containerId: string;
    resolve: any;
    reject: any;
  };
}

export interface IZipLocation {
  type: Type.UI_ZIP_RETRIEVE_LOCATION;
  payload: {
    containerId: string;
    resolve: any;
    reject: any;
  };
}


export type ActionType =
  | IZipLocation
  | IZipGet
  | IFeedbackSave
  | IResubmit
  | IRetryStage
  | IRetryStageStart
  | IRetryStageFinished
  | IResetStageData
  | IResetErrors;
