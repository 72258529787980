import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Stop: React.FunctionComponent<IconProps> = ({ width, height }) => {
  const {
    icons: {
      markup: {
        stop: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C7.178 0 0 7.178 0 16s7.178 16 16 16 16-7.178 16-16S24.822 0 16 0zm0 28C9.383 28 4 22.617 4 16c0-2.525.766-4.913 2.221-6.95L22.95 25.78A11.846 11.846 0 0 1 16 28zm9.779-5.05L9.05 6.22A11.846 11.846 0 0 1 16 4c6.617 0 12 5.383 12 12 0 2.525-.766 4.913-2.221 6.95z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
};

Stop.displayName = 'StopIcon';

Stop.defaultProps = { height: '32', width: '32' };
