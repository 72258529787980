import { put, select } from 'redux-saga/effects';
import { List } from 'immutable';

import { IGeoLocation } from 'utils/types/IGeo';

import { makeErrorMessage } from 'utils/error';
import { getAppLocale } from 'utils/locales';

import { makeSelectLocale } from 'system/Localization/selectors';
import { makeSelectCountryDict } from 'system/Dicts/selectors';
import { unshiftCountryDictByGeoLocation } from 'system/Dicts/actions';


import { ISettings } from './types';
import { failedSettings, setLocale, setLocales, setSettings, setGeoLocation } from './actions';
import { unshiftByGeoLocation, unshiftByGeoLocationDictProp } from './helpers';


export function* loadAppSettings(settings: ISettings) {
  try {
    yield put(setSettings(settings));
  } catch (err) {
    yield put(failedSettings([makeErrorMessage(err)]));
  }
}

export function* applyLocale({ locale }: ISettings) {
  try {
    yield put(setLocale(getAppLocale(locale)));
  } catch (err) {
    yield put(failedSettings([makeErrorMessage(err)]));
  }
}

export function* loadAppLocales({ translations }: ISettings) {
  try {
    const locale = yield select(makeSelectLocale());

    yield put(setLocales({ [locale]: translations }));
  } catch (err) {
    yield put(failedSettings([makeErrorMessage(err)]));
  }
}

export function* setGeoLocationSettings(location: IGeoLocation) {
  try{
    const countryDict = yield select(makeSelectCountryDict());
    yield put(setGeoLocation(location));

    const shiftedCountries = unshiftByGeoLocation(countryDict, location.country)
      .updateIn([0, 'regions'], (value: unshiftByGeoLocationDictProp = List()) => {
        return unshiftByGeoLocation(value, location.region);
      });

    yield put(unshiftCountryDictByGeoLocation(shiftedCountries))

  } catch (err) {
    yield put(failedSettings([makeErrorMessage(err)]));
  }
}

export function* rootSaga() {
  yield;
}

