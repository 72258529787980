import React, { FunctionComponent, MouseEventHandler, MutableRefObject, ReactNode, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { types, withAnalytic } from 'system/Analytics';
import { ITranslate } from 'system/Localization';

import { Markup, StyledPrimaryLink } from 'components/UI';
import { IButtonProps } from 'components/UI/Button/Button';
import { PreviewImage } from 'components/common/StagePreview/StagePreviewShort';
import { Primary } from 'components/UI/Button';
import { WithThemeProps } from 'merchants/types/IThemeStyles';

import { SAGE_PREVIEW_FULL_WIDTH_DESKTOP } from '../utils/constants';
import { IStageStatus } from '../StageStatusBox';

import { ListIcon } from './InfoMessage';

interface IStagePreviewFull {
  startEndpoint: string;
  children: ReactNode;
  imgSrc: string;
  stageId: string;
  onClick: MouseEventHandler;
  status: keyof IStageStatus;
  style?: React.CSSProperties;
  forwardRef?: MutableRefObject<HTMLDivElement | null>;
  subtitle?: ReactNode;
  title?: ReactNode;
  translate: ITranslate;
}

export const StagePreviewFull: FunctionComponent<IStagePreviewFull> = ({
  startEndpoint,
  stageId,
  forwardRef,
  imgSrc,
  onClick,
  status,
  style,
  children,
  subtitle,
  title,
  translate,
}) => {
  const {
    components: {
      modalPreview: { backgroundColorCloseButton, colorCloseButton },
    },
  } = useContext(ThemeContext);

  const EnhancedStartLink = withAnalytic(Primary, {
    onClick: {
      trackId: types.TRACK.STAGE_BUTTON,
      STAGE: stageId,
    },
  });

  return (
    <StageBoxFull
      style={style}
      ref={forwardRef}
      data-aqaid={`${stageId}-preview`}
    >
      <ModalCloseButton onClick={onClick} data-aqaid="preview-close-btn">
        <Markup.Close
          fill={backgroundColorCloseButton}
          fill2={colorCloseButton}
        />
      </ModalCloseButton>
      {(status === 'draft' || status === 'correction') && (
        <EnhancedStartLink
          as={StyledPrimaryLink}
          size="sm"
          to={startEndpoint}
          data-aqaid={`${stageId}-start-link`}
        >
          {translate('button_start')}
        </EnhancedStartLink>
      )}
      <PreviewImage
        style={{
          backgroundImage: `url(${imgSrc})`,
          backgroundPosition: 'bottom',
        }}
      />
      <Content>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {title && <Title>{title}</Title>}
        {children}
      </Content>
    </StageBoxFull>
  );
};

export const ModalCloseButton = styled.button.attrs(
  (props: IButtonProps) => props
)`
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.15);
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    left: 16px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    right: 16px;
  }
`;

export const Content = styled.section`
  background: ${({ theme }) => theme.components.stagePreview.contentBackground};
  font-size: 14px;
  height: calc(100% - 332px);
  line-height: 1.4;
  overflow-y: auto;
  padding: 16px;
  white-space: pre-wrap;
  word-break: break-word;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    height: 39vmax;
  }

  > p {
    margin: 16px 0 18px;
    line-height: 1.43;
  }
  > ul {
    padding: 0;
    list-style: none;

    > li {
      margin: 16px 0;
      padding-left: 24px;
      position: relative;

      ${ListIcon} {
        height: 22px;
        left: -3px;
        position: absolute;
        top: 1px;
        width: 22px;
      }
    }
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 8px 0 16px;
`;

const Subtitle = styled.h3`
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const StageBoxFull = styled.div<WithThemeProps<{ ref: any }>>`
  box-shadow: 0 2px 17px 0 rgba(147, 147, 147, 0.5);
  color: ${({ theme }) => theme.components.stagePreview.textColor};
  display: inline-block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: ${({ theme }) =>
    theme.components.stagePreview.borderRadius};

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    max-width: ${SAGE_PREVIEW_FULL_WIDTH_DESKTOP}px;
    max-height: 592px;
    border-radius: ${({ theme }) =>
      theme.components.stagePreview.borderRadius};
  }

  ${PreviewImage} {
    width: 100%;
    height: 100%;
  }

  ${ModalCloseButton} {
    //left: 16px;
    position: absolute;
    top: 16px;

    @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
      left: 16px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
      top: 14px;
      right: 14px;
    }
  }

  ${PreviewImage} {
    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
      max-height: 260px;
      max-width: initial;
    }
  }

  ${Primary} {
    position: absolute;
    right: 16px;
    top: 16px;

    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
      display: none;
    }
  }
`;
