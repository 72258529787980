import React, { useContext } from 'react';
import { Image } from 'components/UI/Image';
import { ConfigContext } from 'merchants';
import { joinIfNotEmpty  } from 'utils/helpers';


interface LogoProps {
  iss: string;
}

export const Logo: React.FC<LogoProps> = ({ iss })  => {
  const { images: { logo }, appConfig: { cdn } } = useContext(ConfigContext);
  const src = joinIfNotEmpty (cdn, logo);
  const fallbackSrc = joinIfNotEmpty(cdn, 'logo/identance/logo.svg');

  return (
    <Image
      src={src}
      fallbackSrc={fallbackSrc}
      alt={`${iss}-logo`}
    />
  );
};
