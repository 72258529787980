import { call, put, select, takeLatest } from 'redux-saga/effects';
import { makeErrorMessage } from 'utils/error';
import { jsonRPC } from 'utils/request';

import { makeSelectApiUrl } from 'system/Settings/selectors';
import { failedDicts, setDicts } from './actions';
import { Type } from './actionTypes';

export function* fetchDicts() {
  try {
    const api = yield select(makeSelectApiUrl());

    const { dictionaries } = yield call(jsonRPC, {
      namespace: 'va',
      method: 'getDictionaries',
      api
    });

    yield put(setDicts(dictionaries));
  } catch (err) {
    yield put(failedDicts([makeErrorMessage(err)]));
  }
}

export function* rootSaga() {
  yield takeLatest(Type.DICTS_GET, fetchDicts);
}
