import { createSelector } from 'reselect';
import { List } from 'immutable';

import { MERCHANT } from 'utils/types/shared/enums';

import { StageStatuses, Stage } from 'system/Stages/types';
import {
  makeSelectISS,
  makeSelectStageById,
  makeSelectStages,
} from 'system/Profile/selectors';

import { Status } from './reducer';

const selector = (state: any) => state.get('ui');

export const makeSelectSubmitting = () =>
  createSelector(
    selector,
    (state) => state.get('status') === Status.SUBMITTING
  );

export const makeSelectSubmitted = () =>
  createSelector(selector, (state) => state.get('status') === Status.SUBMITTED);

export const makeSelectZipSuggestions = () =>
  createSelector(selector, (state) => state.get('zip'));

export const makeSelectErrors = () =>
  createSelector(selector, (state) => state.get('errors', List()));

export const makeSelectLoadingUi = () =>
  createSelector(selector, (state) => state.get('loading', false));

export const makeSelectSliderStartPosition = () =>
  createSelector(
    [makeSelectStages(), makeSelectStageById(Stage.CFD), makeSelectISS()],
    (stages, cfdStage, iss) => {
      return stages.size > 4 &&
        cfdStage.has('status') &&
        cfdStage.get('status', '') !== StageStatuses.LOCKED &&
        iss !== MERCHANT.IDENTANCE
        ? 1
        : 0;
    }
  );
