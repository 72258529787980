import { fromJS } from 'immutable';

import { Type, ActionType, IQuestionnaireSetAnswer } from './actionTypes';

export const defaultState = fromJS({
  loading: false,
  errors: [],
  data: {},
});

// todo: move to system
export const reducer = (state = defaultState, { type, payload }: ActionType) => {
  switch (type) {
    case Type.QUESTIONNAIRE_SET_ANSWER: {
      const { name, value, blockId } = payload as IQuestionnaireSetAnswer['payload'];
      return state.setIn(['data', blockId, name], value);
    }
    case Type.QUESTIONNAIRE_STAGE_ANSWER_REQUEST:
    case Type.QUESTIONNAIRE_SUBMIT_REQUEST:
      return state.set('loading', true);
    case Type.QUESTIONNAIRE_SUBMIT_SUCCESS:
    case Type.QUESTIONNAIRE_STAGE_ANSWER_SUCCESS: {
      return state.set('loading', false);
    }
    case Type.QUESTIONNAIRE_STAGE_ANSWER_FAILED:
    case Type.QUESTIONNAIRE_SUBMIT_FAILED: {
      const errors = state.get('errors').push(fromJS(payload));
      return state.set('loading', false).set('errors', fromJS(errors));
    }
    default:
      return state;
  }
};
