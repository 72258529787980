import React from 'react';
import styled from 'styled-components';

import { ITheme, WithThemeProps } from 'merchants/types/IThemeStyles';
import { Form as FormikForm } from 'formik';

interface ILayoutProps {
  theme: ITheme;
}

// Caution!
// `overflow: hidden` is prohibited.
export const Layout = styled.main`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 100%;
  padding: 24px 16px 16px;
  background-color: ${({ theme }: ILayoutProps) =>
    theme.layout.backgroundColor};

  @media (min-width: ${({ theme }: ILayoutProps) => theme.breakpoint.tablet}) {
    padding: 32px 16px 24px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  ${(props: { center?: boolean }) =>
    props.center ? 'align-items: center; flex-direction: column;' : ''};
`;

export const Caption: React.FunctionComponent<any> = styled.div`
  display: flex;
  justify-content: center;
  max-width: 466px;
  margin: 20px auto 32px;
  text-align: center;
  ${(props: any) => {
    return props?.opacity ? `opacity: ${props?.opacity};` : '';
  }}
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  max-width: 1202px;
  margin: auto;
  width: 100%;
`;

export const Center = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Form = styled(FormikForm).attrs(
  (props: WithThemeProps<{}>) => props
)`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
`;

export const Footer: React.FunctionComponent = styled.footer`
  display: flex;
  justify-content: center;

  > {
    :not(:first-child) {
      margin-left: 24px;
    }
  }

  @media (min-width: ${({ theme }: ILayoutProps) => theme.breakpoint.tablet}) {
    margin-top: 24px;
  }
`;
