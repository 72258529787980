import React, { FC, useContext } from 'react';
import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Arrow: FC<IconProps> = ({
  width = 20,
  height = 20,
}) => {
  const {
    icons: {
      markup: {
        arrow: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} version="1.1">
      <g
        id="Arrow"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-2">
          <rect
            id="Rectangle-17"
            fill={fill}
            opacity="0"
            x="0"
            y="0"
            width={width}
            height={height}
          />
          <g
            id="Chevron-Right"
            transform="translate(6.000000, 8.000000)"
            fill="#26374D"
            fillRule="nonzero"
          >
            <path
              d="M6.42828,7.181055 L11.82285,1.7846925 C12.059025,1.5479025 12.059025,1.1642655 11.82285,0.9268785 C11.586675,0.690089475 11.20305,0.690089475 10.966875,0.9268785 L6.0003,5.89521 L1.0337625,0.927477 C0.797565,0.690688125 0.4139325,0.690688125 0.17714325,0.927477 C-0.059047725,1.16426625 -0.059047725,1.5485025 0.17714325,1.7852925 L5.5716675,7.181655 C5.80542,7.4148075 6.1950825,7.4148075 6.42828,7.181055 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
