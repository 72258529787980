import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import { WithThemeProps } from 'merchants/types/IThemeStyles';

interface DividerProps {
  children: ReactElement | ReactNode | null;
  className?: string;
}

const Text = styled.span`
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  background: ${({ theme }) => theme.components.divider.backgroundColor};
`;

export const Divider = styled(({ children, className }: WithThemeProps<DividerProps>) => (
  <div className={className}>
    {children ? <Text>{children}</Text> : null}
  </div>
))`
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.components.divider.lineColor};
  }
`;
