import React, { FC, Children, cloneElement, ReactElement } from 'react';
import styled from 'styled-components';
import { TransitionStatus } from 'react-transition-group/Transition';

import { Overlay } from 'components/UI';
import { useOverlay } from 'Hooks/useOverlay';

export interface IModalProps {
  children: ReactElement;
  state?: TransitionStatus;
}

export const Modal: FC<IModalProps> = ({ children, state }) => {
  useOverlay();

  const childrenNodes = Children.map(children, (child: ReactElement, index) => {
    return cloneElement(child, {
      index,
      state,
    });
  });

  return (
    <Overlay state={state}>
      <ModalDialog data-aqaid='modal-dialog'>{childrenNodes}</ModalDialog>
    </Overlay>
  );
};

const ModalDialog = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
