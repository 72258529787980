import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Radio, Label } from 'components/UI';
import { ILocalizationProps, withLocalization } from 'system/Localization';
import { WithThemeProps } from 'merchants/types/IThemeStyles';

interface QuestionProps extends ILocalizationProps {
  title: string | ReactNode;
  name: string;
  value: boolean;
  disabled?: boolean;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Question = withLocalization<QuestionProps>(
  ({
    title,
    translate,
    name,
    value: answer,
    disabled,
    onChange,
  }: QuestionProps) => {
    const isTruth = answer === true;
    const isFalse = answer === false;

    return (
      <Container disabled={disabled}>
        <Title>{title}</Title>
        <Label data-aqaid='radio-yes'>
          <Answer>{translate('button_yes', 'Yes')}</Answer>
          <Radio
            name={name}
            value
            checked={isTruth}
            disabled={disabled}
            onChange={onChange}
          />
        </Label>
        <Label data-aqaid='radio-no'>
          <Answer>{translate('button_no', 'No')}</Answer>
          <Radio
            name={name}
            value={false}
            checked={isFalse}
            disabled={disabled}
            onChange={onChange}
          />
        </Label>
      </Container>
    );
  }
);

const Container = styled.div.attrs(
  (props: WithThemeProps<{ disabled: boolean }>) => props
)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #e0e6e8;

  ${Label} {
    :not(:last-child) {
      margin-right: 16px;
    }
  }
  ${({ disabled }) => (disabled ? 'opacity: .4;' : '')}
`;

const Title = styled.h4`
  flex: 1 1;
  font-weight: 600;
`;

const Answer = styled.span`
  :not(:last-child) {
    margin-right: 8px;
  }
`;
