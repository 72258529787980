/* eslint-disable no-console */
import { call, put, select, takeLatest } from 'redux-saga/effects';

import isEmpty from 'lodash/isEmpty';

import { setABTesting } from 'system/Settings/actions';
import { ISettings } from 'system/Settings/types';

import { makeErrorMessage } from 'utils/error';
import { jsonRPC } from 'utils/request';

import { setGeoLocationSettings } from 'system/Settings/sagas';
import { navigateToErrorLayout } from 'system/Routing/sagas';
import { fillUserDossier } from 'system/Dossier/sagas';
import { makeSelectApiUrl, makeSelectConfigStages, makeSelectWorkingMode } from 'system/Settings/selectors';


import {
  failedProfile,
  setDossierStatus,
  setMerchantFeatures,
  setProfile,
  setRegistrationData,
  setStages,
  setUserData
} from '../actions';
import { Type } from '../actionTypes';

export function* loadProfileSettings(settings: ISettings) {
  try {
    yield put(setProfile(settings));
  } catch (err) {
    yield put(failedProfile([makeErrorMessage(err)]));
  }
}

export function* storeUserProfile(userDossier: any = {}) {
  const { stages, userData, dossierStatus, abTesting, merchantFeatures, registrationData } = userDossier;

  if(merchantFeatures) {
    yield put(setMerchantFeatures(merchantFeatures))
  }

  if (stages) {
    const configStages = yield select(makeSelectConfigStages());
    const filteredStages = stages.filter((stage: any) => configStages.includes(stage.id));
    yield put(setStages(configStages.size ? filteredStages : stages));
  }

  if (userData) {
    yield put(setUserData(userData));
  }

  if (dossierStatus) {
    yield put(setDossierStatus(dossierStatus));
  }

  if (registrationData) {
    yield put(setRegistrationData(registrationData));
  }

  if (abTesting) {
    yield put(setABTesting(abTesting));
  }
}

export function* fetchUserLocation() {
  try {
    const api = yield select(makeSelectApiUrl());
    const location = yield call(jsonRPC, {
      namespace: 'geo',
      method: 'getUserLocation',
      api
    });

    if (!isEmpty(location)) {
      yield call(setGeoLocationSettings, location);
    }
  } catch (err) {
    console.error(err); // error view should not be shown
  }
}

export function* fetchUserProfile() {
  try {
    const workingMode = yield select(makeSelectWorkingMode());
    const api = yield select(makeSelectApiUrl());

    const profile = yield call(jsonRPC, {
      namespace: 'va',
      method: 'getUserDossier',
      params: { workingMode },
      api
    });

    const { userDossier, error } = profile;

    if (error) {
      yield call(navigateToErrorLayout);
    }

    if (userDossier) {
      const { stages, userData, dossierStatus, abTesting, merchantFeatures, registrationData } = userDossier;

      yield call(storeUserProfile, {
        stages,
        userData,
        dossierStatus,
        abTesting,
        merchantFeatures,
        registrationData
      });

      yield call(fillUserDossier, userData);
    }
  } catch (err) {
    yield put(failedProfile([makeErrorMessage(err)]));
  }
}

export function* rootSaga() {
  yield takeLatest(Type.PROFILE_GET, fetchUserProfile);
}
