import { Map, List } from 'immutable';
import { createSelector } from 'reselect';
import { sortByNameWithGeoExclude } from 'utils/collection';
import { makeSelectUserRoles } from '../../stages/Corporate/selectors';
import { UserRole } from '../../utils/types/IPersonalInfo';

export const selector = (state: any) => state.get('dicts');

export const makeSelectLoading = () =>
  createSelector(selector, (state) => state.get('loading', false));

export const makeSelectErrors = () =>
  createSelector(selector, (state) => state.get('errors', Map()));

export const makeSelectDictionary = (name: string) => {
  return createSelector(selector, (state) => {
    return state.getIn(['data', name]);
  });
};

export const makeSelectCountryDict = () =>
  createSelector(selector, (state) => state.getIn(['data', 'CountryDict'], List()));

export const makeSelectAllCountries = () =>
  createSelector(makeSelectCountryDict(), (state) => state.sortBy(sortByNameWithGeoExclude));

export const makeSelectPhoneCodes = () =>
  createSelector(makeSelectAllCountries(), (state) => {
    return state.reduce((acc: any, country: any) => {
      const phoneCodes = country.get('phoneCode', List());
      const phoneMask = country.get('phoneMask', '');
      const name = country.get('name', '');
      const alpha3 = country.get('alpha3', '');
      const alpha2 = country.get('alpha2', '');
      const id = country.get('id', '');
      const codes = phoneCodes.map((code: string) => {
        return {
          countryName: name,
          countryId: id,
          alpha2,
          alpha3,
          code,
          phoneMask,
        };
      });
      return acc.concat(codes);
    }, List());
  });

export const makeSelectAllZipSearchCountries = () =>
  createSelector(makeSelectAllCountries(), (countries: List<Map<string, any>>) =>
    countries.filter((country: Map<string, any>) => {
      return country.get('zipSearch', false);
    }, List())
  );

export const makeSelectDocuments = (name = 'DocumentIdentitySubtypeDict') =>
  createSelector(selector, (state) => state.getIn(['data', name], List()));

export const makeSelectSourceOfFiatFounds = () =>
  createSelector(selector, (state) => state.getIn(['data', 'SourceOfFiatFundsDict'], List()));

export const makeSelectAllMonthlyIncomes = () =>
  createSelector(selector, (state) => state.getIn(['data', 'MonthlyIncomeDict'], List()));

export const makeSelectAllCryptoFounds = () =>
  createSelector(selector, (state) => state.getIn(['data', 'SourceOfCryptoFundsDict'], List()));

export const makeSelectAllWithdrawalDest = () =>
  createSelector(selector, (state) => state.getIn(['data', 'WithdrawalDestinationDict'], List()));

export const makeSelectAllEmploymentStatuses = () =>
  createSelector(selector, (state) => state.getIn(['data', 'EmploymentStatusDict'], List()));

export const makeSelectAllSelfEmploymentTypes = () =>
  createSelector(selector, (state) => state.getIn(['data', 'SelfEmploymentTypeDict'], List()));

export const makeSelectAllEmploymentPositions = () =>
  createSelector(selector, (state) => state.getIn(['data', 'EmploymentPositionDict'], List()));

export const makeSelectAllEmploymentPeriods = () =>
  createSelector(selector, (state) => state.getIn(['data', 'EmploymentPeriodDict'], List()));

export const makeSelectAllIndustries = () =>
  createSelector(selector, (state) => state.getIn(['data', 'IndustryDict'], List()));

export const makeSelectAllQuestions = () =>
  createSelector(selector, (state: any) => state.getIn(['data', 'cfd.questions'], List()));

export const makeSelectAllQuestionsIds = () =>
  createSelector(makeSelectAllQuestions(), (state: any) =>
    state.map((item: any) => item.get('id'))
  );

export const makeSelectGroupedQuestionsByBlocks = () =>
  createSelector(makeSelectAllQuestions(), (state: any) =>
    state.groupBy((item: any) => item.get('blockId'))
  );

export const makeSelectGroupedQuestionsIdsByBlock = () =>
  createSelector(makeSelectGroupedQuestionsByBlocks(), (state: any) => {
    return state.reduce((reduction: Map<any, any>, value: Map<any, any>, key: string) => {
      const blockSteps = value.map((item: Map<any, any>) => item.get('id'));

      return reduction.set(key, blockSteps);
    }, Map());
  });

export const makeSelectAllAnswers = () =>
  createSelector(selector, (state: any) => state.getIn(['data', 'cfd.answers'], Map()));

export const makeSelectAllSubAnswers = () =>
  createSelector(selector, (state: any) => state.getIn(['data', 'cfd.subAnswers'], List()));

export const makeSelectAllPurposeDict = () =>
  createSelector(selector, (state: any) => state.getIn(['data', 'PurposeDict'], List()));

export const makeSelectAllTurnoverDict = () =>
  createSelector(selector, (state: any) => state.getIn(['data', 'TurnoverDict'], List()));

export const makeSelectCompanyType = () =>
  createSelector(selector, (state) => state.getIn(['data', 'company_type'], List()));

export const makeSelectCompanyPurpose = () =>
  createSelector(selector, (state) => state.getIn(['data', 'company_puprose'], List()));

export const makeSelectPlanedMonthlyDepositVolume = () =>
  createSelector(selector, (state) =>
    state.getIn(['data', 'planned_monthly_deposit_volume'], List())
  );

export const makeSelectNumberOfDepositOperations = () =>
  createSelector(selector, (state) =>
    state.getIn(['data', 'number_of_deposit_operations'], List())
  );

export const makeSelectPlanedMonthlyWithdrawalVolume = () =>
  createSelector(selector, (state) =>
    state.getIn(['data', 'planned_monthly_withdrawal_volume'], List())
  );

export const makeSelectCompanyIndustryDict = () =>
  createSelector(selector, (state) => state.getIn(['data', 'company_industry'], List()));

export const makeSelectCompanyAnnualProfitDict = () =>
  createSelector(selector, (state) => state.getIn(['data', 'company_annual_net_profit'], List()));

export const makeSelectCompanyFiatSourcesDict = () =>
  createSelector(selector, (state) =>
    state.getIn(['data', 'company_source_of_fiat_funds'], List())
  );

export const makeSelectCompanySourceCryptoFundsDict = () =>
  createSelector(selector, (state) =>
    state.getIn(['data', 'company_source_of_crypto_funds'], List())
  );

export const makeSelectCompanyLicensing = () =>
  createSelector(selector, (state) => state.getIn(['data', 'company_licensing'], List()));

export const makeSelectCompanyHasPermissions = () =>
  createSelector(selector, (state) => state.getIn(['data', 'company_has_permissions'], List()));

export const makeSelectCompanyAmlReporting = () =>
  createSelector(selector, (state) => state.getIn(['data', 'company_aml_reporting'], List()));

export const makeSelectCompanyAmlProgram = () =>
  createSelector(selector, (state) => state.getIn(['data', 'company_aml_program'], List()));

export const makeSelectCompanyTradeOnStock = () =>
  createSelector(selector, (state) => state.getIn(['data', 'company_trade_on_stock'], List()));

export const makeSelectCompanyAuditedEntity = () =>
  createSelector(selector, (state) => state.getIn(['data', 'company_audited_entity'], List()));

export const makeSelectCompanyUserRoles = () =>
  createSelector(selector, (state) => state.getIn(['data', 'company_user_role'], List()));

export const makeSelectAuthorizedUser = () =>
  createSelector(
    makeSelectUserRoles(),
    makeSelectCompanyUserRoles(),
    (roles: List<string>, selectRoles: List<string>) => {
      const autorizedUserId = selectRoles.getIn([UserRole.AUTHORIZED_PERSON, 'id']);
      return roles.includes(autorizedUserId);
    }
  );

export const makeSelectCompanyOfficerPosition = () =>
  createSelector(selector, (state) => state.getIn(['data', 'company_officer_position'], List()));

export const makeSelectCompanyFiatCurrenciesDict = () =>
  createSelector(selector, (state) =>
    state.getIn(['data', 'payment_currency'], List()).map((record: Map<string, string>) =>
      Map({
        value: record.get('id'),
        label: record.get('name'),
      })
    )
  );

export const makeSelectCompanyCryptoCurrenciesDict = () =>
  createSelector(selector, (state) =>
    state.getIn(['data', 'crypto_currency'], List()).map((record: Map<string, string>) =>
      Map({
        value: record.get('id'),
        label: record.get('name'),
      })
    )
  );

export const makeSelectCompanyCryptoCurrenciesMapDict = () =>
  createSelector(makeSelectCompanyCryptoCurrenciesDict(), (state) => {
    return state.reduce(
      (reduction: Map<string, Map<string, string>>, current: Map<string, string>) => {
        return reduction.set(current.get('value') as string, current);
      },
      Map()
    );
  });

export const makeSelectCompanyPaymentMethodDict = () =>
  createSelector(selector, (state) =>
    state.getIn(['data', 'payment_method'], List()).map((record: Map<string, string>) =>
      Map({
        value: record.get('id'),
        label: record.get('name'),
      })
    )
  );

export const makeSelectAllExchange = () => {
  return createSelector(selector, (state: any) =>
    state.getIn(['data', 'HowFindOurExchangeDict'], List())
  );
};

export const makeSelectCryptoFundsWithdraw = () => {
  return createSelector(selector, (state: any) =>
    state.getIn(['data', 'CryptoFundsWithdrawDestinationDict'], List())
  );
};

export const makeSelectAccountCreateReasonDict = () => {
  return createSelector(selector, (state: any) =>
    state.getIn(['data', 'AccountCreateReasonDict'], List()).map((record: Map<string, any>) => {
      return Map({
        value: record.get('id'),
        label: record.get('name'),
      });
    })
  );
};

export const makeSelectAccountCreateReasonIdentityDict = () => {
  return createSelector(selector, (state: any) =>
    state
      .getIn(['data', 'AccountCreateReasonIdentityDict'], List())
      .map((record: Map<string, any>) => {
        return Map({
          value: record.get('id'),
          label: record.get('name'),
        });
      })
  );
};

export const makeSelectMonthlyTurnoverIdentityDict = () => {
  return createSelector(selector, (state: any) =>
    state.getIn(['data', 'MonthlyTurnoverIdentityDict'], List()).map((record: Map<string, any>) => {
      return Map({
        value: record.get('id'),
        label: record.get('name'),
      });
    })
  );
};

export const makeSelectSourceOfFundsIdentityDict = () => {
  return createSelector(selector, (state: any) =>
    state.getIn(['data', 'SourceOfFundsIdentityDict'], List()).map((record: Map<string, any>) => {
      return Map({
        value: record.get('id'),
        label: record.get('name'),
      });
    })
  );
};

export const makeSelectAnnualSalaryDict = () => {
  return createSelector(selector, (state: any) =>
    state.getIn(['data', 'AnnualSalaryDict'], List()).map((record: Map<string, any>) => {
      return Map({
        value: record.get('id'),
        label: record.get('name'),
      });
    })
  );
};

export const makeSelectEmploymentStatusIdentityDict = () => {
  return createSelector(selector, (state: any) =>
    state
      .getIn(['data', 'EmploymentStatusIdentityDict'], List())
      .map((record: Map<string, any>) => {
        return Map({
          value: record.get('id'),
          label: record.get('name'),
        });
      })
  );
};
export const makeSelectEmploymentPositionIdentityDict = () => {
  return createSelector(selector, (state: any) =>
    state
      .getIn(['data', 'EmploymentPositionIdentityDict'], List())
      .map((record: Map<string, any>) => {
        return Map({
          value: record.get('id'),
          label: record.get('name'),
        });
      })
  );
};
