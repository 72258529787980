import { Buffer } from 'buffer';
import { createHash } from 'crypto-browserify';

/*
 * @see https://jira.wnb:8083/browse/ZNYCH-6700
 */
export const secureUserId = (id: string) => {
  const hash = createHash('sha256')
    .update(`${id  }\n`, 'utf8')
    .digest('hex');

  const value = Buffer.from(hash)
    .toString('base64')
    .substr(0, 32);

  return value;
};
