import {
  IQuestionnaireSetAnswer,
  Type,
  IQuestionnaireSubmitRequest,
  IQuestionnaireSubmitSuccess,
  IQuestionnaireSubmitFailed,
  IQuestionnaireStageAnswerRequest,
  IQuestionnaireStageAnswerSuccess,
  IQuestionnaireStageAnswerFailed,
} from './actionTypes';

export const setAnswer = ({
  name,
  value,
  blockId,
}: IQuestionnaireSetAnswer['payload']): IQuestionnaireSetAnswer => ({
  type: Type.QUESTIONNAIRE_SET_ANSWER,
  payload: { name, value, blockId },
});

export const requestSubmitQuestionnaire = (
  payload: IQuestionnaireSubmitRequest['payload']
): IQuestionnaireSubmitRequest => ({
  type: Type.QUESTIONNAIRE_SUBMIT_REQUEST,
  payload,
});

export const successSubmitQuestionnaire = (): IQuestionnaireSubmitSuccess => ({
  type: Type.QUESTIONNAIRE_SUBMIT_SUCCESS,
  payload: {},
});

export const failedSubmitQuestionnaire = (
  payload: Error
): IQuestionnaireSubmitFailed => ({
  type: Type.QUESTIONNAIRE_SUBMIT_FAILED,
  payload,
});

export const requestStageAnswer = (
  payload: IQuestionnaireStageAnswerRequest['payload']
): IQuestionnaireStageAnswerRequest => ({
  type: Type.QUESTIONNAIRE_STAGE_ANSWER_REQUEST,
  payload,
});

export const successStageAnswer = (): IQuestionnaireStageAnswerSuccess => ({
  type: Type.QUESTIONNAIRE_STAGE_ANSWER_SUCCESS,
  payload: {},
});

export const failedStageAnswer = (
  payload: Error
): IQuestionnaireStageAnswerFailed => ({
  type: Type.QUESTIONNAIRE_STAGE_ANSWER_FAILED,
  payload,
});
