import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useContext,
} from 'react';
import styled from 'styled-components';

import get from 'lodash/get';

import { withVerificationLifetimeTimingAnalytic } from 'system/Analytics/withVerificationLifetimeTimingAnalytic';

import { Stage, StageId, StageStatusName } from 'system/Stages/types';
import { withAnalytic, types } from 'system/Analytics';
import { ILocalizationProps, ITranslate } from 'system/Localization';

import { setColorByState } from 'utils/styledComponents/helpers';

import { Markup, StyledPrimaryLink } from 'components/UI';
import { IBadgeProps } from 'components/UI/Badge/Badge';
import { Primary } from 'components/UI/Button';
import { Routes } from 'system/Routing/Routes';
import { ConfigContext } from 'merchants';
import { useStartVerification } from 'system/GoogleTagManager';

export type IStageStatus = {
  [k in StageStatusName]: any;
};

interface IStageStatusBoxProps extends ILocalizationProps {
  startEndpoint: string;
  days: string | ReactNode;
  email: string;
  notInQueue: boolean;
  queueNumber: number;
  stageMessage: ReactNode;
  stageId: StageId;
  status: keyof IStageStatus;
  handleRetryStage: () => void;
}

interface PendingStageMessage {
  email: string;
  notInQueue: boolean;
  queueNumber: number;
  translate: ITranslate;
}

function getPendingStageMessage({
  email,
  notInQueue,
  queueNumber,
  translate,
}: PendingStageMessage) {
  if (notInQueue) {
    const defaultMessage = translate('stage_check_email_message');
    return email ? `${defaultMessage}: ${email}` : defaultMessage;
  }

  const defaultMessage = translate('stage_pending_message');
  return queueNumber ? (
    <>
      {defaultMessage}{' '}
      {/* <QueueMessage>
        {translate('stage_queue_message', 'stage_queue_message', queueNumber)}
      </QueueMessage> */}
    </>
  ) : (
    defaultMessage
  );
}

const StageStatusBox: FunctionComponent<IStageStatusBoxProps> = ({
  startEndpoint,
  days,
  email,
  notInQueue,
  queueNumber,
  stageMessage,
  status,
  stageId,
  translate,
  handleRetryStage,
}) => {
  const {
    features: { retryDossier },
  } = useContext(ConfigContext);

  const EnhancedStartLink = withVerificationLifetimeTimingAnalytic(
    withAnalytic(Primary, {
      onClick: {
        trackId: types.TRACK.STAGE_BUTTON,
        STAGE: stageId,
      },
    }),
    { stageId }
  );

  const triggerVerificationStart = useStartVerification();

  const stageInfoByStatus: Omit<IStageStatus, 'awaiting'> = {
    accepted: (
      <StatusBox isSuccess withQuote>
        <Message>{stageMessage}</Message>
        <BoxAction>
          <Markup.Ok />
        </BoxAction>
      </StatusBox>
    ),
    tobe_asked: (
      <StatusBox>
        <Message>{stageMessage}</Message>
        <BoxAction>
          <EnhancedStartLink
            as={StyledPrimaryLink}
            size="sm"
            to={startEndpoint}
            data-aqaid={`${stageId}-start-link`}
          >
            {translate('button_start')}
          </EnhancedStartLink>
        </BoxAction>
      </StatusBox>
    ),
    draft: (
      <StatusBox>
        <Message>{stageMessage}</Message>
        <BoxAction>
          <EnhancedStartLink
            as={StyledPrimaryLink}
            size="sm"
            to={startEndpoint}
            data-aqaid={`${stageId}-start-link`}
            onClick={() => triggerVerificationStart(stageId)}
          >
            {translate('button_start')}
          </EnhancedStartLink>
        </BoxAction>
      </StatusBox>
    ),
    locked: (
      <StatusBox>
        <Message>{stageMessage}</Message>
        <BoxAction>
          <Markup.Lock />
        </BoxAction>
      </StatusBox>
    ),
    inprogress: (
      <StatusBox isPrimary withQuote>
        <Message>
          {getPendingStageMessage({
            queueNumber,
            notInQueue,
            email,
            translate,
          })}
        </Message>
        <BoxAction>
          <Markup.Clock />
        </BoxAction>
      </StatusBox>
    ),
    pending: (
      <StatusBox isPrimary withQuote>
        <Message>
          {getPendingStageMessage({
            queueNumber,
            notInQueue,
            email,
            translate,
          })}
        </Message>
        <BoxAction>
          <Markup.Clock />
        </BoxAction>
      </StatusBox>
    ),
    correction: (
      <StatusBox isWarning withQuote>
        <Message>
          {translate('stage_description_short_need_correction')}
        </Message>
        <BoxAction>
          <EnhancedStartLink
            as={StyledPrimaryLink}
            size="sm"
            to={Routes.root(startEndpoint)}
            data-aqaid={`${stageId}-start-link`}
          >
            {translate('button_start')}
          </EnhancedStartLink>
        </BoxAction>
      </StatusBox>
    ),
    refused: (
      <StatusBox isDanger withQuote>
        <Message>
          {translate(
            stageId !== Stage.CFD && retryDossier
              ? 'stage_refused_message_try_again'
              : 'stage_refused_message',
            '',
            days
          )}
        </Message>
        <BoxAction>
          {stageId !== Stage.CFD && retryDossier ? (
            <Primary
              size="sm"
              data-aqaid={`${stageId}-retry-button`}
              onClick={handleRetryStage}
            >
              {translate('dialog_button_retry')}
            </Primary>
          ) : (
            <Markup.Stop />
          )}
        </BoxAction>
      </StatusBox>
    ),
    next_stage_needed: (
      <StatusBox isPrimary withQuote>
        <Message>{translate('stage_message_next_required')}</Message>
        <BoxAction>
          <Markup.Clock />
        </BoxAction>
      </StatusBox>
    ),
    current_stage_needed: (
      <StatusBox isPrimary withQuote>
        <Message>{translate('stage_message_current_required')}</Message>
        <BoxAction>
          <EnhancedStartLink
            as={StyledPrimaryLink}
            size="sm"
            to={Routes.root(startEndpoint)}
            data-aqaid={`${stageId}-start-link`}
          >
            {translate('button_start')}
          </EnhancedStartLink>
        </BoxAction>
      </StatusBox>
    ),
  };
  return get(stageInfoByStatus, status, null);
};

StageStatusBox.displayName = 'StageStatusInfo';

export const Message = styled.p`
  width: 165px;
  //padding-right: 8px;
  align-self: center;
`;

export const StatusBox = styled.span.attrs(
  (props: IBadgeProps & { withQuote: boolean }) => props
)`
  border-radius: 0;
  color: #263850;
  font-size: 14px;
  line-height: 1.43;
  padding-bottom: 0;
  padding-left: ${({ withQuote }) => (withQuote ? '12px' : '0')};
  padding-top: 0;
  position: relative;
  display: flex;

  &:before {
    background-color: ${({
      theme,
      isDanger,
      isPrimary,
      isWarning,
      isSuccess,
    }) =>
      setColorByState(
        {
          default: 'none',
          primary: theme.components.statusBox.background.primary,
          danger: theme.components.statusBox.background.danger,
          warning: theme.components.statusBox.background.warning,
          success: theme.components.statusBox.background.success,
        },
        {
          primary: isPrimary,
          danger: isDanger,
          warning: isWarning,
          success: isSuccess,
        }
      )};
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 87%;
    background-color: ${({
      theme,
      isDanger,
      isPrimary,
      isWarning,
      isSuccess,
    }) =>
      setColorByState(
        {
          default: 'none',
          primary: theme.components.statusBox.background.primary,
          danger: theme.components.statusBox.background.danger,
          warning: theme.components.statusBox.background.warning,
          success: theme.components.statusBox.background.success,
        },
        {
          primary: isPrimary,
          danger: isDanger,
          warning: isWarning,
          success: isSuccess,
        }
      )};
  }

  > strong {
    display: block;
  }

  > .action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 165px);
    flex-grow: 1;
  }
`;

const QueueMessage = styled.strong`
  display: block;
`;

function BoxAction({ children }: { children: ReactElement }) {
  return <span className="action">{children}</span>;
}

export { StageStatusBox };
