import { IGeneralConfig, IConfig } from 'merchants/types/IConfig';
import merge from 'lodash/merge';
import { getBaseFeatures, getBaseTranslations, getBaseThemeConfig } from 'merchants/helpers';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { schema } from './schemas';

export const validateConfig = async (config: IGeneralConfig) => {
  try {
    return await schema.validate(config, { strict: true });
  } catch (e) {
    return e;
  }
};

export const mergeConfig = (config: IGeneralConfig): IConfig => {
  const { theme, features, appConfig, i18n, images } = config;
  const {
    theme: baseTheme,
    images: baseImages,
  } = getBaseThemeConfig();

  const baseFeatures = getBaseFeatures();
  const baseTranslations = getBaseTranslations(i18n?.locale);

  const mergedThemeConfig = merge({}, baseTheme?.config, theme?.config);
  const mergedTranslations = merge({}, baseTranslations, i18n?.translations);
  const mergedImages = merge({}, baseImages, images);
  const mergedAppConfig = merge({}, window.appConfig, omitBy(appConfig, isEmpty));

  return {
    ...config,
    backUrl: config.backUrl || '',
    i18n: {
      locale: i18n?.locale || 'en',
      translations: mergedTranslations,
    },
    theme: {
      name: theme?.name || '',
      config: mergedThemeConfig,
    },
    images: mergedImages,
    features: {
      ...baseFeatures,
      ...features,
    },
    appConfig: {
      ...mergedAppConfig,
    },
  };
};
