import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { navigateBack } from 'system/Routing/actions';
import { IRouteRoutes } from 'system/Routing/interfaces';

import { RoutesBuilder } from 'containers/Routes/RoutesBuilder';

interface StageComponentProps {
  routes?: IRouteRoutes;
  stepBack?: () => void;
  exitOnSubmit?: () => void;
}

function StageComponent({
  stepBack,
  routes,
  exitOnSubmit,
}: StageComponentProps) {
  return (
    <RoutesBuilder
      routes={routes}
      handlePrevStep={stepBack}
      exitOnSubmit={exitOnSubmit}
    />
  );
}

StageComponent.displayName = 'Stage';

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  stepBack: () => {
    dispatch(navigateBack());
  },
});

export const Stage = connect(null, mapDispatchToProps)(StageComponent);
