import { ISettings } from '../Settings/types';

export enum Type {
  PROFILE_GET = '@Profile/PROFILE_GET',
  PROFILE_SET = '@Profile/PROFILE_SET',
  STAGES_SET = '@Profile/STAGES_SET',
  USER_DATA_SET = '@Profile/USER_DATA_SET',
  DOSSIER_STATUS_SET = '@Profile/DOSSIER_STATUS_SET',
  RECOGNIZER_PROFILE_SET = '@Profile/RECOGNIZER_PROFILE_SET',
  FEEDBACK_HISTORY_SET = '@Profile/FEEDBACK_HISTORY_SET',
  MERCHANT_FEATURES_SET = '@Profile/MERCHANT_FEATURES_SET',
  REGISTRATION_DATA_SET = '@Profile/REGISTRATION_DATA_SET',

  PROFILE_FAILED = '@Profile/PROFILE_FAILED',
}

export interface IProfileGet {
  type: Type.PROFILE_GET;
  payload: null;
}

export interface IProfileSet {
  type: Type.PROFILE_SET;
  payload: ISettings;
}

export interface IStagesSet {
  type: Type.STAGES_SET;
  payload: any[];
}

export interface IMerchantFeaturesSet {
  type: Type.MERCHANT_FEATURES_SET;
  payload: { [key: string]: boolean };
}

export interface IRegistrationDataSet {
  type: Type.REGISTRATION_DATA_SET;
  payload: { [key: string]: boolean };
}

export interface IUserDataSet {
  type: Type.USER_DATA_SET;
  payload: any;
}

export interface IDossierStatusSet {
  type: Type.DOSSIER_STATUS_SET;
  payload: string;
}

export interface IRecognizerProfileSet {
  type: Type.RECOGNIZER_PROFILE_SET;
  payload: any;
}

export interface IFeedbackHistorySet {
  type: Type.FEEDBACK_HISTORY_SET;
  payload: any;
}

export interface IProfileFailed {
  type: Type.PROFILE_FAILED;
  payload: Error[];
}

export type ActionType =
  | IProfileGet
  | IProfileSet
  | IStagesSet
  | IUserDataSet
  | IMerchantFeaturesSet
  | IDossierStatusSet
  | IRecognizerProfileSet
  | IFeedbackHistorySet
  | IProfileFailed;
