import { useState, useEffect } from 'react';
import { useWindowSize } from 'Hooks';
import { Hook } from './constants';

export const useClientRectWithResize: Hook = (
  { current: node },
  activeElement,
  container
) => {
  const { width = 0, height = 0 } = useWindowSize();
  const [rect, updateFoo] = useState({});
  useEffect(
    () => {
      if (node && container) {
        const containerSize = container.getBoundingClientRect();
        const containerTop = containerSize.top;
        const containerLeft = containerSize.left;

        updateFoo({
          rect: node.getBoundingClientRect(),
          windowSize: { width, height },
          container: { containerTop, containerLeft },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeElement, width, height]
  );

  return rect;
};
