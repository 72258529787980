import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import { StagePreview } from 'components/common/StagePreview';
import { Slider } from 'components/common/Slider';

import { StageId, StageStatusName } from 'system/Stages/types';
import {
  makeSelectStages,
  makeSelectProduct,
  makeSelectEmail,
  makeSelectStageCorrection,
} from 'system/Profile/selectors';
import { withLocalization } from 'system/Localization';
import { retryStage } from 'system/UI/actions';
import { makeSelectEntryUrls } from 'system/Routing/selectors';
import { makeSelectSliderStartPosition } from 'system/UI/selectors';

export function BaseStagesPreview({
  stagesList,
  start = 0,
  product,
  email,
  correctionList,
  stagesEntryUrls,
  translate,
  handleRetryStage,
}: any) {
  const stages = stagesList.toJS();

  return (
    <Slider start={start}>
      {stages.map(
        (
          {
            id: stageId,
            status,
            queueNumber = null,
            notInQueue = null,
            dateOfNextVerification = null,
          }: any,
          index: any
        ) => {
          const correction = correctionList.get(stageId, Map()).toJS();

          return (
            <StagePreview
              startEndpoint={stagesEntryUrls.get(stageId)}
              dateOfNextVerification={dateOfNextVerification}
              email={email}
              index={index}
              key={stageId}
              notInQueue={notInQueue}
              product={product}
              queueNumber={queueNumber}
              stageId={stageId as StageId}
              status={status as StageStatusName}
              correction={correction}
              translate={translate}
              handleRetryStage={handleRetryStage(stageId)}
            />
          );
        }
      )}
    </Slider>
  );
}

const mapStateToProps = createStructuredSelector({
  start: makeSelectSliderStartPosition(),
  stagesEntryUrls: makeSelectEntryUrls(),
  stagesList: makeSelectStages(),
  product: makeSelectProduct(),
  email: makeSelectEmail(),
  correctionList: makeSelectStageCorrection(),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleRetryStage: (stageId: string) => () => {
    dispatch(retryStage(stageId));
  },
});

export const StagesPreview = connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalization(BaseStagesPreview));
