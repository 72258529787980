import React from 'react';

import { ILocalizationProps, withLocalization } from 'system/Localization';
import { Stub } from 'components/UI/Icon';

import { CommonStub } from 'containers/Stubs/CommonStub';
import { ICommonStubProps } from '../CommonStub/types';

export const QrSubmittedStub = withLocalization(({ translate }: ILocalizationProps) => {
  const contentProps: ICommonStubProps = {
    stageTitle: '',
    body: {
      Icon: Stub.QrSubmitted,
      title: translate('upload_success_title'),
      message: translate('upload_success_explanation'),
    },
  };

  return <CommonStub {...contentProps} />;
});
