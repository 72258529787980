import React, { ReactNode, useEffect, ClipboardEventHandler } from 'react';
import styled from 'styled-components';
import { isImmutable } from 'immutable';

import { useField, useFormikContext } from 'formik';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

import { withTimeTracking } from 'containers/Field/HOC/withTimeTracking';
import { ToolTip } from 'components/common/ToolTip';
import { Markup, ErrorMessage } from 'components/UI';

import { ITranslate, withLocalization } from 'system/Localization';
import { TriggerBox } from 'components/common/ToolTip/Tip';

interface FieldProps {
  as: any;
  tip?: ReactNode | string;
  translate: ITranslate;
  disabled?: boolean;
  name: string;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  dictionary?: any;
  title?: ReactNode | string;
  type: string;
  value?: string;
  placeholder?: string | ReactNode;
  handleChange?: (...args: any) => void;
  flexBoxPadding?: string;
  mask?: string;
  isMulti?: boolean;
  maskRegExp?: any[];
  guide?: boolean;
  signed?: boolean;
  handleSetByZip?: ({ ...fields }: any) => void;
  handleResetBlock?: () => void;
  onClick?: () => void;
  handleKeyPress?: (...args: any) => void;
  handlePaste?: ClipboardEventHandler<any>;
  disabledTitle?: boolean;
}

export function BaseField({
  as,
  dictionary,
  disabled,
  name,
  onBlur,
  onFocus,
  tip,
  title,
  type = 'text',
  value,
  placeholder: enhancedPlaceholder,
  translate,
  handleChange,
  flexBoxPadding,
  handleSetByZip,
  handleResetBlock,
  onClick,
  mask,
  maskRegExp,
  guide,
  isMulti,
  signed,
  handleKeyPress,
  handlePaste,
  disabledTitle,
  ...props
}: FieldProps) {
  const {
    setFieldValue,
    setFieldTouched,
    handleChange: handleFormikFieldChange,
    handleBlur,
    errors,
  } = useFormikContext<any>();
  const [field, meta] = useField(name);
  const Component = as;

  const onFieldChangeFactory = (...args: any) => {
    if (isFunction(handleChange)) {
      return handleChange(...args);
    }

    switch (type) {
      case 'radio': {
        return setFieldValue(name, value);
      }
      case 'dropdown': {
        const [dropdownValue = null] = args;
        return setFieldValue(name, dropdownValue);
      }
      case 'datepicker': {
        const [evt = {}] = args;
        return setFieldValue(name, evt.date || evt);
      }
      default: {
        const [evt = {}] = args;
        return handleFormikFieldChange(evt);
      }
    }
  };

  const componentBlurHandlers = {
    dropdown: () => setFieldTouched(name, true),
    datepicker: (select: string) => setFieldTouched(`${name}.${select}`, true),
    base: (e: React.SyntheticEvent) => handleBlur(e),
  };

  const blurHandler = get(
    componentBlurHandlers,
    type,
    componentBlurHandlers.base
  );

  const componentPlaceholder = {
    dropdown: translate('select'),
    base: translate('enter'),
  };

  const placeholder =
    enhancedPlaceholder ||
    get(componentPlaceholder, type, componentPlaceholder.base);

  useEffect(() => {
    const { initialValue, touched } = meta;
    const hasInitialValue = isImmutable(initialValue)
      ? !initialValue.isEmpty()
      : value;
    if (hasInitialValue && !touched) {
      setFieldTouched(name, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.initialValue]);

  const { error, touched } = meta;
  const hasError = touched && (error || errors[name]);
  const valid = (touched && !hasError && value) || (!touched && value);

  return (
    <FieldBox
      disabled={disabled}
      padding={flexBoxPadding}
      data-aqaid={`${type}-${name}-box`}
    >
      {title && (
        <Title disabled={disabledTitle}>
          <span>
            {title}
            {tip && <ToolTip trigger={<Markup.Info />}>{tip}</ToolTip>}
          </span>
        </Title>
      )}
      <Component
        {...field}
        {...meta}
        tip={tip}
        type={type}
        data-aqaid={`${type}-${name}`}
        placeholder={placeholder}
        dictionary={dictionary}
        disabled={disabled}
        error={hasError}
        value={value}
        valid={valid}
        isMulti={isMulti}
        signed={signed}
        onBlur={(e: React.SyntheticEvent) => {
          blurHandler(e);

          if (typeof onBlur !== 'undefined') {
            onBlur(e);
          }
        }}
        onChange={onFieldChangeFactory}
        onFocus={onFocus}
        mask={mask}
        maskRegExp={maskRegExp}
        guide={guide}
        handleSetByZip={handleSetByZip}
        handleResetBlock={handleResetBlock}
        onClick={onClick}
        onKeyPress={handleKeyPress}
        onPaste={handlePaste}
        {...props}
      />

      {hasError && (
        <ErrorMessage data-aqaid="error-message">
          {translate(meta.error || '', meta.error)}
        </ErrorMessage>
      )}
    </FieldBox>
  );
}

BaseField.displayName = 'Field';

export const FieldBox = styled.div<{ padding?: string; disabled?: boolean }>`
  position: relative;
  padding: ${({ padding }) => padding || '0 0 26px 0'};
  ${({ disabled }) => (disabled ? 'opacity: .4;' : '')}
`;

export const Title = styled.h4<{ disabled?: boolean }>`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.h4.color};
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  ${({ disabled }) => (disabled ? 'opacity: .4;' : '')}

  ${TriggerBox} {
    position: relative;
    top: 2px;
  }
`;

export const Field = withTimeTracking<FieldProps>(withLocalization(BaseField));
