import { createSelector } from 'reselect';
import { Map, List, fromJS } from 'immutable';
import camelCase from 'lodash/camelCase';
import { v4 as uuidv4 } from 'uuid';

import { Block } from 'system/Stages/types';
import { SubStep } from 'system/Routing/types';
import { makeSelectUserData } from 'system/Profile/selectors';

import { Field } from 'utils/types/shared/enums';

import { selectCorporateBlock } from '../selectors';

const selectPaymentDetails = createSelector(
  selectCorporateBlock(Block.PAYMENT_DETAILS),
  (state) => state
);

const selectPaymentDetailsCached = createSelector(
  selectPaymentDetails,
  (state) => state.get(Field.CACHE_CORP, Map())
);

/* Wallet address */
export const makeSelectCompanyWalletAddresses = () =>
  createSelector(selectPaymentDetails, (state: Map<string, any>) => {
    return state.get(camelCase(SubStep.PAYMENT_DETAILS_WALLET_ADDRESS), Map());
  });

export const makeSelectCompanyWalletEntryId = () =>
  createSelector(
    makeSelectCompanyWalletAddresses(),
    (state: Map<string, string>) => {
      return state.get(Field.ENTRY_ID, uuidv4());
    }
  );

export const makeSelectCompanyCryptoCurrency = () =>
  createSelector(
    makeSelectCompanyWalletAddresses(),
    (state: Map<string, string>) => {
      return state.get(Field.CRYPTO_CURRENCY_CORP, '');
    }
  );

export const makeSelectCompanyCryptoWalletAddress = () =>
  createSelector(
    makeSelectCompanyWalletAddresses(),
    (state: Map<string, string>) => {
      return state.get(Field.CRYPTO_WALLET_ADDRESS_CORP, '');
    }
  );

export const makeSelectPaymentDetailsCryptoInfoRecord = () =>
  createSelector(
    makeSelectCompanyWalletEntryId(),
    makeSelectCompanyCryptoCurrency(),
    makeSelectCompanyCryptoWalletAddress(),
    (entryId, cryptoCurrency, cryptoWalletAddress) => {
      return Map({
        // hack for an empty record
        ...(cryptoCurrency && { entryId }),
        ...(cryptoCurrency && { cryptoCurrency }),
        ...(cryptoWalletAddress && { cryptoWalletAddress }),
      });
    }
  );

/* End Wallet address */
const makeSelectCompanyBankInfo = () =>
  createSelector(selectPaymentDetails, (state: Map<string, any>) => {
    return state.get(camelCase(SubStep.PAYMENT_DETAILS_BANK_INFO), List());
  });

export const makeSelectCompanyFiatCurrency = () =>
  createSelector(makeSelectCompanyBankInfo(), (state: Map<string, string>) => {
    return state.get(Field.FIAT_CURRENCY_CORP, '');
  });

export const makeSelectCompanyPaymentMethod = () =>
  createSelector(makeSelectCompanyBankInfo(), (state: Map<string, string>) => {
    return state.get(Field.PAYMENT_METHOD_CORP, '');
  });

export const makeSelectCompanyBankAccountEntryId = () =>
  createSelector(makeSelectCompanyBankInfo(), (state: Map<string, string>) => {
    return state.get(Field.ENTRY_ID, uuidv4());
  });

export const makeSelectCompanyBankCountry = () =>
  createSelector(makeSelectCompanyBankInfo(), (state: Map<string, string>) => {
    return state.get(Field.BANK_COUNTRY_CORP, '');
  });

export const makeSelectCompanyBankName = () =>
  createSelector(makeSelectCompanyBankInfo(), (state: Map<string, string>) => {
    return state.get(Field.BANK_NAME_CORP, '');
  });

export const makeSelectCompanyBankAddress = () =>
  createSelector(makeSelectCompanyBankInfo(), (state: Map<string, string>) => {
    return state.get(Field.BANK_ADDRESS_CORP, '');
  });

export const makeSelectCompanyBankAccountNumber = () =>
  createSelector(makeSelectCompanyBankInfo(), (state: Map<string, string>) => {
    return state.get(Field.BANK_ACCOUNT_NUMBER_CORP, '');
  });

export const makeSelectCompanySortCode = () =>
  createSelector(makeSelectCompanyBankInfo(), (state: Map<string, string>) => {
    return state.get(Field.SORT_CODE_CORP, '');
  });

export const makeSelectCompanyABARouting = () =>
  createSelector(makeSelectCompanyBankInfo(), (state) =>
    state.get(Field.ABA_ROUTING_CORP, '')
  );

/* Bank international */
const makeSelectCompanyBankInternational = () =>
  createSelector(selectPaymentDetails, (state: Map<string, any>) => {
    return state.get(
      camelCase(SubStep.PAYMENT_DETAILS_BANK_INTERNATIONAL),
      List()
    );
  });

export const makeSelectCompanyBankIBAN = () =>
  createSelector(makeSelectCompanyBankInternational(), (state) =>
    state.get(Field.IBAN_CORP, '')
  );

export const makeSelectCompanyBankSwiftBicCode = () =>
  createSelector(makeSelectCompanyBankInternational(), (state) =>
    state.get(Field.SWIFT_BIC_CODE_CORP, '')
  );

/* Bank statements */
export const makeSelectCompanyBankStatements = () =>
  createSelector(selectPaymentDetails, (state: Map<string, any>) => {
    return state.getIn(
      [camelCase(SubStep.PAYMENT_DETAILS_BANK_STATEMENTS), 'attachments'],
      List()
    );
  });

export const makeSelectCompanyBankStatementsTokens = () =>
  createSelector(
    makeSelectCompanyBankStatements(),
    (statements: List<Map<string, any>>) => {
      return statements.map((statement: Map<string, any>) =>
        statement.get('fileToken')
      );
    }
  );

/* End Bank statements */

/* Bank correspondent */
const selectCompanyBankCorrespondent = createSelector(
  selectPaymentDetails,
  (state: Map<string, any>) => {
    return state.get(
      camelCase(SubStep.PAYMENT_DETAILS_BANK_CORRESPONDENT),
      List()
    );
  }
);

export const makeSelectCompanyCorrespondentBankName = () =>
  createSelector(selectCompanyBankCorrespondent, (state: Map<string, any>) => {
    return state.get(Field.BANK_NAME_CORP, '');
  });

export const makeSelectCompanyCorrespondentBankCountry = () =>
  createSelector(selectCompanyBankCorrespondent, (state: Map<string, any>) => {
    return state.get(Field.BANK_COUNTRY_CORP, '');
  });

export const makeSelectCompanyCorrespondentBankAddress = () =>
  createSelector(selectCompanyBankCorrespondent, (state: Map<string, any>) => {
    return state.get(Field.BANK_ADDRESS_CORP, '');
  });

export const makeSelectCompanyCorrespondentBankSwiftBicCode = () =>
  createSelector(selectCompanyBankCorrespondent, (state: Map<string, any>) => {
    return state.get(Field.SWIFT_BIC_CODE_CORP, '');
  });

export const makeSelectCompanyCorrespondentBankIBAN = () =>
  createSelector(selectCompanyBankCorrespondent, (state: Map<string, any>) => {
    return state.get(Field.IBAN_CORP, '');
  });
/* End Bank correspondent */

export const makeSelectPaymentDetailsFiatInfo = () => {
  return createSelector(
    selectPaymentDetailsCached,
    makeSelectUserData(),
    (cached: Map<string, any>, stored: any) => {
      const data = stored.getIn(['paymentDetails', 'fiatInfo'], List());

      return cached.get(Field.FIAT_INFO_CORP, List()).merge(data);
    }
  );
}

export const makeSelectPaymentDetailsCryptoInfo = () => {
  return createSelector(
    selectPaymentDetailsCached,
    makeSelectUserData(),
    (cached: Map<string, any>, stored: any) => {
      const data = stored.getIn(['paymentDetails', 'cryptoInfo' ], List());
      const result = cached.get(Field.CRYPTO_INFO_CORP, List()).merge(data);
      return result.reduce((reduction: Map<string, any>, current: Map<string, any>) => {
        return reduction.set(current.get('cryptoWalletAddress'), current);
      }, Map()).toList();
    }
  );
}

export const makeSelectPaymentInternational = () => createSelector(
  selectPaymentDetails,
  (state) => state.get(Field.PAYMENT_INTERNATIONAL, Map())
);

export const makeSelectPaymentFiatInfoWithInternational = () => createSelector(
  makeSelectPaymentDetailsFiatInfo(),
  makeSelectPaymentInternational(),
  (fiatInfo: List<Map<string, any>>, international: Map<string, any>) =>
    fiatInfo.push(international).toJS()
);

/* Cache block */
export const makeSelectCompanyCorrespondentBank = () =>
  createSelector(
    [
      makeSelectCompanyCorrespondentBankName(),
      makeSelectCompanyCorrespondentBankCountry(),
      makeSelectCompanyCorrespondentBankAddress(),
      makeSelectCompanyCorrespondentBankSwiftBicCode(),
      makeSelectCompanyCorrespondentBankIBAN(),
    ],
    (name, country, address, swiftBicCode, IBAN) => {
      return Map({
        ...(name && { name }),
        ...(country && { country }),
        ...(address && { address }),
        ...(swiftBicCode && { swiftBicCode }),
        ...(IBAN && { IBAN }),
      });
    }
  );

export const makeSelectPaymentDetailsFiatInfoRecord = () =>
  createSelector(
    [
      makeSelectCompanyBankAccountEntryId(),
      makeSelectCompanyFiatCurrency(),
      makeSelectCompanyPaymentMethod(),
      makeSelectCompanyBankCountry(),
      makeSelectCompanyBankName(),
      makeSelectCompanySortCode(),
      makeSelectCompanyBankAddress(),
      makeSelectCompanyABARouting(),
      makeSelectCompanyBankAccountNumber(),
      makeSelectCompanyBankStatementsTokens(),
      makeSelectCompanyBankIBAN(),
      makeSelectCompanyBankSwiftBicCode(),
      makeSelectCompanyCorrespondentBank(),
    ],
    (
      entryId,
      currency,
      paymentMethod,
      bankCountry,
      bankName,
      sortCode,
      bankAddress,
      ABARouting,
      bankAccountNumber,
      images,
      IBAN,
      swiftBicCode,
      correspondentBank
    ) => {
      return Map({
        // hack for an empty record
        ...(currency && { entryId }),
        ...(currency && { currency }),
        ...(paymentMethod && { paymentMethod }),
        ...(!images.isEmpty() && { images }),
        ...(bankCountry && { bankCountry }),
        ...(bankName && { bankName }),
        ...(sortCode && { sortCode }),
        ...(bankAddress && { bankAddress }),
        ...(ABARouting && { ABARouting }),
        ...(bankAccountNumber && { bankAccountNumber }),
        ...(IBAN && { IBAN }),
        ...(swiftBicCode && { swiftBicCode }),
        ...(!correspondentBank.isEmpty() && { correspondentBank }),
      }) as Map<string, any>;
    }
  );

export const makeSelectPaymentDetailsMergedFiatInfo = () =>
  createSelector(
    makeSelectPaymentDetailsFiatInfo(),
    makeSelectPaymentDetailsFiatInfoRecord(),
    (cache: List<Map<string, any>>, record: Map<string, any>) => {
      return record.isEmpty() ? cache : cache.push(record);
    }
  );

export const makeSelectPaymentDetailsMergedCryptoInfo = () =>
  createSelector(
    makeSelectPaymentDetailsCryptoInfo(),
    makeSelectPaymentDetailsCryptoInfoRecord(),
    (cache: List<Map<string, any>>, record: Map<string, any>) => {
      return record.isEmpty() ? cache : cache.push(record);
    }
  );

export const makeSelectPaymentDetailsBlockCache = () =>
  createSelector(
    makeSelectPaymentDetailsMergedFiatInfo(),
    makeSelectPaymentDetailsMergedCryptoInfo(),
    (fiatInfo: List<Map<any, any>>, cryptoInfo: List<Map<string, any>>) => {
      const hasFiatPayments = !fiatInfo.isEmpty();
      const hasCryptoPayments = !cryptoInfo.isEmpty();

      return fromJS({
        [Field.CACHE_CORP]: {
          ...(hasFiatPayments && { fiatInfo }),
          ...(hasCryptoPayments && { cryptoInfo }),
        },
      });
    }
  );

/* End cache block */

export const makeSelectPaymentDetailsBlockData = () =>
  createSelector(
    makeSelectPaymentDetailsBlockCache(),
    (cache: Map<string, any>) => {
      const fiatInfo = cache.getIn([Field.CACHE_CORP, Field.FIAT_INFO_CORP], List());
      const cryptoInfo = cache.getIn([Field.CACHE_CORP,Field.CRYPTO_INFO_CORP], List());
      const hasFiatPayments = !fiatInfo.isEmpty();
      const hasCryptoPayments = !cryptoInfo.isEmpty();

      return {
        paymentDetails: {
          planToDepositOrWithdrawFiatFunds: hasFiatPayments,
          ...(hasFiatPayments && { fiatInfo }),
          ...(hasCryptoPayments && { cryptoInfo }),
        },
      };
    }
  );
