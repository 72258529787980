import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

import { Stage } from 'system/Stages/types';
import { joinByChar } from 'utils/helpers';
import { makeSelectSchemaOfStageFilteredByDossierBlocks } from './selectors';

export const makeSelectCorporateBlockById = (blockId: string) =>
  createSelector(
    makeSelectSchemaOfStageFilteredByDossierBlocks(Stage.CORPORATE),
    (schema) => {
      const block = joinByChar(blockId);
      return schema.get(block);
    }
  );

export const makeSelectCorporateBlockSteps = (blockId: string) =>
  createSelector(
    makeSelectCorporateBlockById(blockId),
    (schema) => {
      return schema.map((item: Map<string, any>) => item.get('id'));
    }
  );

export const makeSelectCorporateBlocksList = () =>
  createSelector(
    makeSelectSchemaOfStageFilteredByDossierBlocks(Stage.CORPORATE),
    (schema) => {
      return schema.map((blocks: List<Map<string, any>>) => {
        return blocks.map((item: Map<string, any>) => item.get('id'))
      });
    }
  );

export const makeSelectCorporateBlocksFirstSteps = () =>
  createSelector(
    makeSelectCorporateBlocksList(),
    (schema) => {
      return schema.map((blocks: List<Map<string, any>>) => {
        return blocks.first();
      });
    }
  );
