export const schema:  { [key: string]: string } = {
    userAgent: 'USER_AGENT',
    platform: 'PLATFORM',
    cookies: 'COOKIES_ENABLED',
    timezoneOffset: 'TIMEZONE',
    language: 'LOCALE_IDENTIFIER',
    languages: 'PREFERRED_LANGUAGES',
    fonts: 'FONTS',
    doNotTrack: 'DO_NOT_TRACK',
    localStorage: 'LOCAL_STORAGE',
    sessionStorage: 'SESSION_STORAGE',
    screenResolution: 'SCREEN_RESOLUTION',
    colorDepth: 'SCREEN_DEPTH',
    availableScreenResolution: 'AVAILABLE_SCREEN_RESOLUTION',
    plugins: 'PLUGINS',
    buildID: 'BUILD_ID',
    webglVendorAndRenderer: 'WEBGL_VENDOR_RENDERER',
    webgl: 'WEBGL_PARAMETERS',
    adBlock: 'ADBLOCK',
    merchant: 'MERCHANT',
};
