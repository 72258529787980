import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Taxes: React.FunctionComponent<IconProps>= ({ width = 104, height = 126 }) => {
  const {
    icons: {
      docs: {
        taxes: { fill, fill2 },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg height={height} viewBox={`0 0 ${width} ${height}`} width={width} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect height="124" rx="4" stroke={fill} strokeWidth="2" width="102" x="1" y="1" />
        <g fill={fill}>
          <path d="M40 38h48v-2H40zM40 44h48v-2H40zM40 50h23.489v-2H40zM16 36h16v16H16zM16 60h16v16H16z" />
        </g>
        <path d="M40 62h48v-2H40zM40 68h48v-2H40zM40 74h23.489v-2H40z" fill={fill} />
        <g fill={fill}>
          <path d="M40 86h48v-2H40zM40 92h48v-2H40zM40 98h23.489v-2H40zM16 84h16v16H16z" />
        </g>
        <path d="M16 110h72v-2H16z" fill={fill} />
        <path d="M21.178 26.862h-2.27v-8.819H16v-1.89h8.086v1.89h-2.908v8.819zm11.111 0l-.776-2.549h-3.904l-.776 2.549h-2.447l3.78-10.752h2.775l3.794 10.752H32.29zm-1.318-4.453a753.923 753.923 0 0 1-1.212-3.919 11.06 11.06 0 0 1-.195-.718c-.16.625-.622 2.17-1.384 4.637h2.79zm13.77 4.453h-2.594l-2.49-4.05-2.49 4.05h-2.432l3.553-5.523-3.326-5.185h2.505l2.307 3.852 2.264-3.852h2.446l-3.362 5.31 3.618 5.398zm7.514 0h-6.167V16.154h6.167v1.86h-3.897v2.351h3.626v1.86h-3.626v2.762h3.897v1.875zm8.555-2.974c0 .967-.348 1.729-1.044 2.285-.696.557-1.664.835-2.904.835-1.143 0-2.153-.215-3.032-.644v-2.11c.722.323 1.334.55 1.834.682.5.131.959.197 1.374.197.498 0 .88-.095 1.146-.285.266-.19.399-.474.399-.85a.938.938 0 0 0-.176-.56 1.967 1.967 0 0 0-.516-.473c-.227-.151-.69-.393-1.388-.725-.654-.307-1.145-.603-1.472-.886a3.327 3.327 0 0 1-.784-.989c-.195-.376-.293-.815-.293-1.318 0-.947.321-1.692.963-2.234.642-.542 1.53-.813 2.663-.813.556 0 1.087.066 1.593.198a10.95 10.95 0 0 1 1.585.556l-.732 1.766a10.716 10.716 0 0 0-1.417-.491 4.562 4.562 0 0 0-1.103-.14c-.43 0-.759.1-.988.3a.99.99 0 0 0-.345.785c0 .2.047.374.14.523.092.15.24.293.443.432.202.14.682.39 1.439.751 1 .479 1.687.958 2.058 1.44.371.48.557 1.07.557 1.768z" fill={fill2} />
      </g>
    </svg>
  );
};

Taxes.displayName = 'TaxesIcon';
