import { IDictsFailed, IDictsGet, IDictsSet, IUnshiftCountryDictByGeoLocation, Type } from './actionTypes';

export const getDicts = (): IDictsGet => ({
  type: Type.DICTS_GET,
  payload: null,
});

export const setDicts = (payload: any): IDictsSet => ({
  type: Type.DICTS_SET,
  payload,
});

export const failedDicts = (payload: Error[]): IDictsFailed => ({
  type: Type.DICTS_FAILED,
  payload,
});

export const unshiftCountryDictByGeoLocation = (payload: any): IUnshiftCountryDictByGeoLocation => ({
  type: Type.UNSHIFT_COUNTRY_DICT_BY_GEO_LOCATION,
  payload,
});
