import merge from 'lodash/merge';
import { config as identityConfig, defaultFeatures} from 'merchants/identance';
// eslint-disable-next-line import/no-named-default
import { default as ru } from 'merchants/identance/locales/ru.json';
// eslint-disable-next-line import/no-named-default
import { default as en } from 'merchants/identance/locales/en.json';
import { IConfig } from '../types/IConfig';

export const mergeMerchantFeatures = (
  merchantConfig: Omit<IConfig, 'theme'>,
  features: object
) => {
  return merge(merchantConfig, {
    features: { ...merchantConfig.features, ...features },
  });
};

export const getBaseThemeConfig = (iss = 'identity') => {
  const configs: { [key: string]: IConfig }  = {
    identity: identityConfig,
  };
  return configs[iss];
};

export const getBaseTranslations = (locale = 'en') => {
  const locales: { [key: string]: any } = {
    ru,
    en
  };
  return locales[locale || 'en']
};

export const getBaseFeatures = () => defaultFeatures;
