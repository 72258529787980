import styled from 'styled-components';

import { IThemeProps } from 'merchants/types/IThemeStyles';
import { setColorByState } from 'utils/styledComponents/helpers';

export interface ITextareaProps extends IThemeProps {
  error?: boolean;
  disabled?: boolean;
  valid?: boolean;
}

export const Textarea = styled.textarea.attrs((props: ITextareaProps) => props)`
  padding: 8px 16px;
  width: 100%;
  min-height: 7rem;
  background-color: ${({ theme }) => theme.forms.textarea.backgroundColor};
  border-style: solid;
  border-width: ${({ theme }) => theme.forms.textarea.borderWidth};
  box-shadow: ${({ theme }) => theme.forms.textarea.boxShadow};
  border-color: ${({ theme, disabled, error, valid }: ITextareaProps) =>
    setColorByState(
      {
        default: theme.forms.textarea.borderColor,
        disabled: theme.forms.textarea.disabled.borderColor,
        error: theme.forms.textarea.error.borderColor,
        valid: theme.forms.textarea.valid?.borderColor,
      },
      { disabled, error, valid }
    )};

  border-radius: ${({ theme }) => theme.forms.textarea.borderRadius};
  color: ${({ theme }) => theme.forms.textarea.color};
  font-size: ${({ theme }) => theme.forms.textarea.fontSize};
  resize: ${({ theme }) => theme.forms.textarea.resize};
  outline: none;

  ::placeholder {
    color: ${({ theme }) => theme.forms.textarea.disabled.color};
  }

  &:focus {
    background-color: ${({ theme }) =>
      theme.forms.textarea.focus.backgroundColor};
    border-color: ${({ theme }) => theme.forms.textarea.focus.borderColor};
  }

  ${({ error, theme }) => `
    ${error && `border-color:  ${theme.forms.textarea.error.borderColor};`}
  `}
`;
