import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Photo: React.FunctionComponent<IconProps> = ({ width = 32, height = 32 }) => {
    const {
        icons: {
            markup: {
                ok: { fill },
            }
        },
    } = useContext(ThemeContext);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <g fill="none" fillRule="evenodd">
                <path fillRule="evenodd" d="M10.8198 2.16667H12.8198C13.1733 2.16667 13.5126 2.30718 13.7626 2.55718C14.0127 2.80729 14.1531 3.14646 14.1531 3.50003V9.49998C14.1531 9.85355 14.0127 10.1927 13.7626 10.4428C13.5126 10.6928 13.1734 10.8333 12.8199 10.8333H2.15307C1.7995 10.8333 1.46033 10.6928 1.21034 10.4428C0.960226 10.1927 0.819824 9.85355 0.819824 9.49998V3.50003C0.819824 3.14646 0.960226 2.80729 1.21034 2.55718C1.46033 2.30718 1.7995 2.16667 2.15307 2.16667H4.15306V1.50005C4.15306 1.14648 4.29358 0.807307 4.54357 0.557198C4.79369 0.3072 5.13286 0.166687 5.48643 0.166687H9.4864C9.83997 0.166687 10.1791 0.3072 10.4293 0.557198C10.6793 0.80731 10.8198 1.14648 10.8198 1.50005V2.16667ZM5.6006 4.28112C6.10071 3.781 6.77894 3.50009 7.48619 3.50009C8.19344 3.50009 8.87167 3.781 9.37178 4.28112C9.87189 4.78123 10.1528 5.45945 10.1528 6.16671C10.1528 6.87396 9.87189 7.5523 9.37178 8.0523C8.87167 8.55241 8.19344 8.83344 7.48619 8.83344C6.77894 8.83344 6.10071 8.55241 5.6006 8.0523C5.10049 7.5523 4.81958 6.87396 4.81958 6.16671C4.81958 5.45945 5.10049 4.78123 5.6006 4.28112Z" fill={fill} />
            </g>
        </svg>
    );
};

Photo.displayName = 'Photo';
