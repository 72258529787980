/* eslint-disable no-else-return */
import mapValues from 'lodash/mapValues';
import ifEmpty from 'lodash/isEmpty';
import { Map, Set } from 'immutable';

export const isFunction = (val: any): boolean => typeof val === 'function';
export const isArray = (val: any): boolean => Array.isArray(val);
export const isObject = (val: any): boolean =>
  typeof val === 'object' && val !== null;

export const isEmpty = (value: any): boolean => {
  if (isArray(value)) {
    return value.length > 0;
  } else if (isObject(value)) {
    return Object.keys(value).length > 0;
  }
  return !!value;
};

export const isEmptyString = (value: any): boolean => {
  return value !== '';
};

export const filter = (
  items: any,
  fn: (item: any, index: number) => boolean
) => {
  if (isArray(items)) {
    return items.filter(fn);
  } else if (isObject(items)) {
    return Object.keys(items).reduce((prev, curr, index) => {
      return {
        ...prev,
        ...(fn(items[curr], index) ? { [curr]: items[curr] } : {}),
      };
    }, {});
  }
  return items;
};

export const map = (items: any, mapper: (item: any, index: number) => any) => {
  return Array.isArray(items) ? items.map(mapper) : mapValues(items, mapper);
};

export const fill = (items: any, value: any) => {
  return map(items, () => value);
};

const omitGeoLocation = (country: Map<string, string>) =>
  !country.has('geoLocation');

export const sortByName = (country: Map<string, string>) =>
  country.get('name', '');

export const sortByNameWithGeoExclude = (country: Map<string, string>) =>
  omitGeoLocation(country) && sortByName(country);

export const keyIn = (keys: any) => {
  const keySet = Set(keys);
  return (_: any, k: any) => keySet.has(k);
};

export const pickImmutable = (data: any, keys: any) => {
  if (typeof data !== 'object') return Map()

  return data.filter(keyIn(keys))
};

export const omitImmutable = (data: any, keys: any) =>
  data.filterNot(keyIn(keys));

export const filterEmptyFields = (fields: any) => {
  return Object.keys(fields).reduce((acc: any, key: string) => {
    const value = fields[key];
    if (isArray(value)) {
      acc[key] = value.filter((item: any) => !!item);
      return acc;
    }
    if (!ifEmpty(value)) {
      acc[key] = value;
    }
    return acc;
  }, {});
};
