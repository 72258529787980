import { createSelector } from 'reselect';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';

import { Stage } from 'system/Stages/types';

import { MetaSource } from 'utils/types/shared/enums';
import {
  makeSelectAllSocialProfiles,
  makeSelectDocSubType,
  makeSelectDocumentDetails,
  makeSelectStreet,
  makeSelectCity,
  makeSelectCountry,
  makeSelectRegion,
  makeSelectSubRegion,
  makeSelectZipCode,
  makeSelectFilesTokens,
  makeSelectAptSuite,
  makeSelectWithMetaSource,
  makeSelectDocumentDetailsMeta,
  makeSelectDocSubTypeMeta,
  makeSelectStreetMeta,
  makeSelectCityMeta,
  makeSelectCountryMeta,
  makeSelectRegionMeta,
  makeSelectSubRegionMeta,
  makeSelectZipCodeMeta,
  makeSelectAptSuiteMeta,
  makeSelectUploadFilesTokens,
} from './selectors';

const makeSelectAddress = () => createSelector(
  makeSelectStreet(),
  makeSelectAptSuite(),
  makeSelectCity(),
  makeSelectCountry(),
  makeSelectRegion(),
  makeSelectSubRegion(true),
  makeSelectZipCode(),
  (street, aptSuite, city, country, region, subregion, zipCode) => ({
    type: 'residential',
    street,
    city,
    country,
    region,
    zipCode,
    ...(subregion ? { subregion } : {}),
    ...(aptSuite ? { aptSuite } : {}),
  })
);

const makeSelectAddressDocument = () => createSelector(
  makeSelectDocSubType(),
  makeSelectDocumentDetails(),
  makeSelectFilesTokens,
  makeSelectAddress(),
  (subType: any, subTypeDetailed: any, images: any, address: any) => {
    return {
      type: Stage.ADDRESS,
      subType,
      ...(subTypeDetailed ? { subTypeDetailed } : {}),
      images,
      address,
    };
  }
);

const makeSelectAddressDocumentOnlyImages = () =>
  createSelector(makeSelectUploadFilesTokens(), (images) => {
    return { images };
  });

export const makeSelectAllStageData = () =>
  createSelector(
    makeSelectAddressDocument(),
    makeSelectAllSocialProfiles(),
    makeSelectAddressDocumentOnlyImages(),
    (addressDocument: any, socialNetworkProfiles: any, addressDocumentOnlyImage: any) => {
      const { subType, address: { country, city } } = addressDocument;
      const sendAddressDocumentOnlyImage = [subType, country, city].some((value) => value === '');

      return {
        addressDocument,
        socialNetworkProfiles,
        ...(sendAddressDocumentOnlyImage  ? {addressDocumentOnlyImage} : {}),
      }
    }
  );

const makeSelectAddressMeta = () =>
  createSelector(
    makeSelectWithMetaSource(makeSelectStreetMeta),
    makeSelectWithMetaSource(makeSelectCityMeta),
    makeSelectWithMetaSource(makeSelectCountryMeta),
    makeSelectWithMetaSource(makeSelectRegionMeta),
    makeSelectWithMetaSource(makeSelectSubRegionMeta),
    makeSelectWithMetaSource(makeSelectZipCodeMeta),
    makeSelectWithMetaSource(makeSelectAptSuiteMeta),
    (street, city, country, region, subregion, zipCode, aptSuite) =>
      omitBy(
        {
          street,
          city,
          country,
          region,
          subregion,
          zipCode,
          aptSuite,
        },
        isEmpty
      )
  );

const makeSelectAddressDocumentMeta = () => createSelector(
  makeSelectWithMetaSource(makeSelectDocSubTypeMeta),
  makeSelectWithMetaSource(makeSelectDocumentDetailsMeta),
  makeSelectAddressMeta(),
  (subType, subTypeDetailed, address) => {

    return {
      subType,
      subTypeDetailed,
      address,
      images: MetaSource.MANUAL,
    };
  }
);

export const makeSelectAllStageMetaData = () =>
  createSelector(makeSelectAddressDocumentMeta(), (addressDocument) => ({
    addressDocument,
  }));
