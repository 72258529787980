import styled from 'styled-components';

import { WithThemeProps } from 'merchants/types/IThemeStyles';
import { ITipProps } from '../types';


interface ArrowProps {
  placement?: string;
  ref: any;
}

export const Arrow = styled.span<ArrowProps>`
   z-index: 100;
   width: 10px;
   height: 10px;
   background: ${({ theme }: ITipProps) => theme.components.tooltip.background};
   transform: ${({ style }) => {
     return `${style?.transform} rotate(45deg) !important;`;
   }};
  
   ${({ placement, theme }: WithThemeProps<{ placement?: string }>) => {
     if (placement === 'top') {
       return `
          bottom: -6px;
          border-right: ${theme.components.tooltip.border};
          border-bottom: ${theme.components.tooltip.border};
       `;
     }
     if (placement === 'right') {
       return `
          left: -6px;
          border-left: ${theme.components.tooltip.border};
          border-bottom: ${theme.components.tooltip.border};
       `;
     }
     if (placement === 'left') {
       return `
          right: -6px;
          border-right: ${theme.components.tooltip.border};
          border-top: ${theme.components.tooltip.border};
       `;
     }
     if (placement === 'bottom') {
       return `
          top: -6px;
           border-left: ${theme.components.tooltip.border};
           border-top: ${theme.components.tooltip.border};
       `
     }
     return '';
   }}
`;
