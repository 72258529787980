import isEmpty from 'lodash/isEmpty';

import { Stage } from 'system/Stages/types';
import { STAGES, STUBS, SUB_STEPS } from 'utils/constants';

import { Stub, SubStep } from './types';

export const setParam = (name: string, group: any[], singleStage = false) => {
  return isEmpty(group.filter(Boolean))
    ? `/${singleStage ? '' : `:${name}`}`
    : `/${singleStage ? '' : `:${name}(${group.join('|')})`}`;
};

export const getStageParamPattern = (stages: Stage[] = STAGES) => {
  return setParam('stageId', stages);
};

export const getStepParamPattern = (
  steps: string[] = [],
  singleStage = false
) => {
  return setParam('stepId', steps, singleStage);
};

export const getStubParamPattern = (stubs: Stub[] = STUBS) => {
  return setParam('stubId', stubs);
};

export const getSubStepParamPattern = (subStepIds: SubStep[] = SUB_STEPS) => {
  return setParam('subStepId', subStepIds);
};

export const getCombinedPattern = ({
  stages,
  steps,
  stubs,
  subStepIds,
  singleStage = false,
}: {
  stages?: Stage[];
  steps?: string[];
  subStepIds?: SubStep[];
  stubs?: Stub[] | boolean;
  singleStage?: boolean;
} = {}) => {
  const ifStub = !!(typeof stubs === 'boolean' ? stubs : stubs?.length);

  if (typeof stubs === 'boolean' && !stubs) {
    return `${getStageParamPattern(stages as Stage[])}${getStepParamPattern(
      steps as string[],
      singleStage
    )}`;
  }
  if (subStepIds) {
    return `${getStageParamPattern(stages as Stage[])}${getStepParamPattern(
      steps as string[],
      singleStage
    )}${getSubStepParamPattern(subStepIds as SubStep[])}${
      ifStub ? getStubParamPattern(stubs as Stub[]) : ''
    }`;
  }
  return `${getStageParamPattern(stages as Stage[])}${getStepParamPattern(
    steps as string[],
    singleStage
  )}${getStubParamPattern(stubs as Stub[])}`;
};

export const getCombinedBlockPattern = ({
  stages,
  steps,
  subStepIds,
  singleStage = false,
}: {
  stages?: Stage[];
  steps?: string[];
  subStepIds?: SubStep[];
  singleStage?: boolean;
} = {}) => {
  return `${getStageParamPattern(stages as Stage[])}${getStepParamPattern(
    steps as string[],
    singleStage
  )}${getSubStepParamPattern(subStepIds as SubStep[])}`;
};
