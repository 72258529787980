import React, { FunctionComponent, useContext, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import {
  ICorrection,
  Product,
  StageId,
  StageStatusName,
} from 'system/Stages/types';
import { ITranslate } from 'system/Localization';

import { useOnClickOutside } from 'Hooks';
import { DynamicPortal } from 'components/Portal';

import { ConfigContext } from 'merchants';
import { joinIfNotEmpty } from 'utils/helpers';
import { useClientRectWithResize } from './utils/hooks';
import {
  getCountdown,
  getStageSubtitle,
  getStageTitle,
  getTimeCountMessage,
  getStageShortMessage,
  getStartPositionOfStagePreviewFull,
  getStageFullMessage,
} from './utils/helpers';

import { Modal } from './Modal';
import { PreviewAnimation } from './PreviewAnimation';
import { StagePreviewFull } from './StagePreviewFull';
import { StagePreviewShort } from './StagePreviewShort';
import { ENTER_TIMEOUT, EXIT_TIMEOUT } from './utils/constants';
import { InfoMessage } from './StagePreviewFull/InfoMessage';

interface IStagePreviewProps {
  startEndpoint: string;
  index: number;
  product: Product;
  stageId: StageId;
  status: StageStatusName;
  dateOfNextVerification?: number;
  email?: string;
  notInQueue?: boolean;
  previewFullVisible?: boolean;
  queueNumber?: number;
  correction?: ICorrection;
  handleRetryStage: () => void;
  translate: ITranslate;
}

const StagePreview: FunctionComponent<IStagePreviewProps> = ({
  startEndpoint,
  dateOfNextVerification = 0,
  email = '',
  index,
  notInQueue = false,
  previewFullVisible = false,
  product,
  queueNumber = 0,
  stageId,
  status,
  translate,
  handleRetryStage,
}) => {
  const {
    images: { stages: stageImages },
    appConfig: { cdn },
    containerId
  }: any = useContext(ConfigContext);
  const previewShort = useRef<HTMLDivElement | null>(null);
  const previewFull = useRef<HTMLDivElement | null>(null);
  const container = document.getElementById(containerId);

  const [visible, toggleVisible] = useState<boolean>(previewFullVisible);
  const [clickedElementIndex, forceUpdate] = useState<number>();
  const resizeSettings = useClientRectWithResize(
    previewShort,
    clickedElementIndex,
    container
  );
  useOnClickOutside(previewFull, () => toggleVisible(false));

  const onClick = (id: number) => () => {
    forceUpdate(id);
    toggleVisible(!visible);
  };

  const title = translate(getStageTitle(stageId));
  const subtitle = translate(getStageSubtitle(index));
  const stageShortMessage = translate(getStageShortMessage(stageId, product));
  const stageFullMessage = `${translate(getStageFullMessage(stageId))}`;
  const img = stageImages[stageId];
  const imgSrc =joinIfNotEmpty(cdn, img);
  const days = getTimeCountMessage(
    translate,
    getCountdown(dateOfNextVerification)
  );
  const {
    x,
    y,
    shiftX,
    shiftY,
    width,
    top,
    left,
  } = getStartPositionOfStagePreviewFull(resizeSettings);

  return (
    <Transition
      in={visible}
      timeout={{
        enter: ENTER_TIMEOUT,
        exit: EXIT_TIMEOUT,
      }}
    >
      {(state) => {
        return (
          <>
            <PreviewAnimation state={state} data-aqaid={`${stageId}`}>
              <StagePreviewShort
                startEndpoint={startEndpoint}
                days={days}
                email={email}
                forwardRef={previewShort}
                imgSrc={imgSrc}
                notInQueue={notInQueue}
                onClick={onClick(index)}
                queueNumber={queueNumber}
                stageMessage={stageShortMessage}
                status={status}
                subtitle={subtitle}
                title={title}
                stageId={stageId}
                translate={translate}
                handleRetryStage={handleRetryStage}
                product={product}
              />
            </PreviewAnimation>
            { visible && <DynamicPortal id="preview">
              <Modal state={state}>
                <PreviewAnimation
                  directionX={x}
                  directionY={y}
                  shiftX={shiftX}
                  shiftY={shiftY}
                  startWidth={width}
                  startX={left}
                  startY={top}
                  state={state}
                  status={status}
                >
                  <StagePreviewFull
                    startEndpoint={startEndpoint}
                    stageId={stageId}
                    forwardRef={previewFull}
                    imgSrc={imgSrc}
                    onClick={onClick(index)}
                    status={status}
                    style={{ top: `${top}px`, left: `${left}px` }}
                    subtitle={subtitle}
                    title={title}
                    translate={translate}
                  >
                    <InfoMessage message={stageFullMessage} />
                  </StagePreviewFull>
                </PreviewAnimation>
              </Modal>
            </DynamicPortal>}
          </>
        );
      }}
    </Transition>
  );
};

StagePreview.displayName = 'StagePreview';

export { StagePreview };
