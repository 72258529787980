import React, { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';

import { withTimeTracking } from 'containers/Field/HOC/withTimeTracking';
import { ToolTip } from 'components/common/ToolTip';
import { Markup, ErrorMessage } from 'components/UI';
import { ILocalizationProps, withLocalization } from 'system/Localization';
import { WithThemeProps } from 'merchants/types/IThemeStyles';
import { DateSelect as DatePickerSelect } from './DateSelect';
import { IDateValue, IYearsInterval } from './utils/types';

interface DatePickerProps extends ILocalizationProps {
  disabled: boolean;
  name: string;
  tip: string;
  title: string;
  date: IDateValue;
  yearsInterval: IYearsInterval;
  handleChange: (date: IDateValue) => void;
  touched: {
    year: boolean;
    month: boolean;
    day: boolean;
    all: boolean;
  };
}

const DatePickerComponent = ({
  disabled,
  name,
  tip,
  title,
  date,
  yearsInterval,
  locale,
  translate,
  handleChange,
  touched,
}: WithThemeProps<DatePickerProps>) => {
  const { setFieldTouched } = useFormikContext();
  const [, meta] = useField(name);
  const { error }: any = meta;
  const errorAll = error?.all;
  const ifTouched = touched?.day && touched?.month && touched?.year;

  useEffect(() => {
    const { initialValue } = meta;
    Object.keys(initialValue).forEach((select) => {
      return setFieldTouched(`${name}.${select}`, true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.initialValue]);

  const onBlur = (select: string) => () => {
    return setFieldTouched(`${name}.${select}`, true);
  };

  return (
    <FieldBox disabled={disabled}>
      {title && (
        <Title>
          <span>{title}</span>
          {tip && (
            <ToolTip placement="right" trigger={<Markup.Info />}>
              {tip}
            </ToolTip>
          )}
        </Title>
      )}
      <DatePickerSelect
        error={error}
        touched={touched}
        onBlur={onBlur}
        handleChange={handleChange}
        yearsInterval={yearsInterval}
        locale={locale}
        date={date}
        translate={translate}
        disabled={disabled}
      />
      {ifTouched && error && errorAll && (
        <ErrorMessage data-aqaid="error-message">
          {translate(errorAll)}
        </ErrorMessage>
      )}
    </FieldBox>
  );
};

export const FieldBox = styled.div.attrs(
  (props: WithThemeProps<{ disabled: boolean }>) => props
)`
  position: relative;
  padding-bottom: 24px;
  ${({ disabled }) => (disabled ? 'opacity: .4;' : '')}
`;

export const Title = styled.h4`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.h4.color};
  padding-bottom: 6px;
`;

export const DatePicker = withTimeTracking(
  withLocalization(DatePickerComponent)
);
