import { IconProps } from 'components/UI/Icon/types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export const FrontSide: React.FC<IconProps> = ({
  width = 290,
  height = 96,
}) => {
  const {
    icons: {
      docs: {
        frontSide: { fill, fill2, fill3 },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={fill}
          d="M128 21h162v2H128zM128 49h134v-2H128zM128 75h105v-2H128z"
        />
        <rect width="96" height="96" fill={fill3 || fill} rx="10" />
        <path d="M20 20h56v56H20z" />
        <path
          fill={fill2}
          fillRule="nonzero"
          d="M66.18 65.205l-10.092-5.047a3.85 3.85 0 0 1-2.128-3.443v-3.96c.283-.323.609-.738.955-1.228a23.207 23.207 0 0 0 3.137-6.323c1.294-.398 2.248-1.594 2.248-3.015v-4.227c0-.93-.414-1.761-1.057-2.342v-6.11S60.497 20 47.62 20c-12.878 0-11.622 9.51-11.622 9.51v6.11a3.152 3.152 0 0 0-1.057 2.342v4.227a3.17 3.17 0 0 0 1.461 2.66c1.056 4.597 3.822 7.906 3.822 7.906v3.862c0 1.408-.77 2.705-2.006 3.379l-9.426 5.142a8.473 8.473 0 0 0-4.417 7.44V76h46.49v-3.215a8.476 8.476 0 0 0-4.684-7.58z"
        />
      </g>
    </svg>
  );
};
