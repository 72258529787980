import { useSelector } from 'react-redux';
import { makeSelectLocale, makeSelectLocales } from './selectors';
import { translate } from './utils';

export const useLocalization = () => {
  const locale = useSelector(makeSelectLocale());

  const locales = useSelector(makeSelectLocales());

  return {
    locale,
    translate(term: string, ...placeholders: any): any {
      return translate(locales, locale, term, ...placeholders);
    },
  };
};
