import React, { FC } from 'react';
import { IconProps } from 'components/UI/Icon/types';

export const Reload: FC<IconProps> = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8526 0.655163C11.7543 0.555256 11.6377 0.505157 11.5031 0.505157C11.3685 0.505157 11.2521 0.555256 11.1536 0.655163L10.144 1.67371C9.59001 1.1421 8.95571 0.730166 8.24124 0.4381C7.52686 0.146033 6.79142 0 6.03548 0C4.61159 0 3.36902 0.4381 2.30756 1.31449C1.2461 2.19079 0.547168 3.33415 0.210571 4.74477V4.8C0.210571 4.86842 0.235165 4.92763 0.284354 4.97763C0.333542 5.02763 0.391786 5.05263 0.459087 5.05263H2.00461C2.11853 5.05263 2.19612 4.99207 2.23759 4.87104C2.45503 4.34475 2.5922 4.03689 2.64921 3.94735C3.01164 3.34733 3.49313 2.87628 4.09378 2.53412C4.69443 2.19205 5.3416 2.02092 6.03539 2.02092C7.07616 2.02092 7.97966 2.38149 8.74599 3.10255L7.67404 4.19203C7.57586 4.29204 7.52657 4.41055 7.52657 4.54738C7.52657 4.68421 7.57586 4.80262 7.67404 4.90263C7.77252 5.00263 7.8891 5.05263 8.0237 5.05263H11.5031C11.6377 5.05263 11.7542 5.00263 11.8526 4.90263C11.9509 4.80262 12 4.68421 12 4.54738V1.01041C12 0.873583 11.9511 0.755167 11.8526 0.655163V0.655163Z"
        fill="#0C87F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5396 6.94941H10.0414C9.9271 6.94941 9.84905 7.00988 9.80748 7.13101C9.58913 7.65737 9.45123 7.96513 9.39406 8.05467C9.02986 8.65449 8.54614 9.12572 7.94281 9.46778C7.33938 9.80994 6.68922 9.98097 5.99223 9.98097C5.48773 9.98097 5.00133 9.8863 4.53322 9.69676C4.06511 9.50722 3.64632 9.23871 3.27705 8.89132L4.346 7.8098C4.44483 7.70989 4.49424 7.59129 4.49424 7.45456C4.49424 7.31773 4.44483 7.19922 4.346 7.09932C4.24716 6.99922 4.13014 6.94922 3.99491 6.94922H0.499424C0.364106 6.94922 0.247078 6.99922 0.148247 7.09932C0.0494157 7.19922 0 7.31773 0 7.45456V10.9915C0 11.1282 0.0494157 11.2468 0.148247 11.3467C0.247174 11.4466 0.364201 11.4968 0.499424 11.4968C0.634647 11.4968 0.751674 11.4466 0.850505 11.3467L1.85702 10.3282C2.41361 10.865 3.04692 11.2782 3.75693 11.5676C4.46695 11.8572 5.20167 12.0019 5.9611 12.0019C7.3864 12.0019 8.62955 11.5638 9.69065 10.6874C10.7517 9.8111 11.4487 8.66767 11.7817 7.25717C11.787 7.24671 11.7895 7.2282 11.7895 7.20194C11.7895 7.13353 11.7647 7.07442 11.7153 7.02432C11.6657 6.97451 11.6072 6.94941 11.5396 6.94941V6.94941Z"
        fill="#0C87F2"
      />
    </svg>
  );
};

Reload.displayName = 'Reload';

Reload.defaultProps = { height: '13', width: '12' };
