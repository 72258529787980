import { fromJS, List } from 'immutable';
import isArray from 'lodash/isArray';

import { Type as UIType, ActionType as UIActionType } from 'system/UI/actionTypes';
import { Stage } from 'system/Stages/types';
import { Type, ActionType } from './actionTypes';


export const defaultState = fromJS({
  loading: false,
  errors: [],
  data: {}
});

export const reducer = (state = defaultState, { type, payload }: ActionType & UIActionType) => {
  switch (type) {
    case Type.ADDRESS_STEP_INITIAL_SET: {
      const { stepId, fields = {} } = payload;

      const update = state.getIn(['data', stepId], fromJS(isArray(fields) ? [] : {})).merge(fromJS(fields));

      return state.setIn(['data', stepId], fromJS(update));
    }
    case Type.ADDRESS_STEP_SET: {
      const { stepId, fields = {} } = payload;
      return state.setIn(['data', stepId], fromJS(fields));
    }
    case Type.ADDRESS_BLOCK_RESET: {
      const data = fromJS({});

      return state
        .setIn(['data', 'addressDocuments'], data)
        .setIn(['data', 'addressResidence'], data)
    }
    case Type.ADDRESS_DOC_SET: {
      const { source: subType, images } = payload;
      const prevData = state.getIn(['data', 'addressDocuments'], List());
      const newData = prevData.concat(fromJS({ subType, images }));
      return state.setIn(['data', 'addressDocuments'], newData);
    }
    case Type.ADDRESS_META_SET: {
      const { stepId, fields } = payload;

      return state.mergeIn(['meta', stepId], fields);
    }
    case Type.ADDRESS_SUBMIT_REQUEST:
    case Type.ADDRESS_UPLOAD_DOC_REQUEST: {
      return state.set('loading', true);
    }
    case Type.ADDRESS_SUBMIT_SUCCESS:
    case Type.ADDRESS_UPLOAD_DOC_SUCCESS: {
      return state.set('loading', false);
    }
    case Type.ADDRESS_SUBMIT_FAILED:
    case Type.ADDRESS_UPLOAD_DOC_FAILED: {
      return state.set('loading', false).set('errors', fromJS(payload));
    }
    case UIType.UI_RESET_ERRORS:
      return state.set('errors', fromJS([]));
    case UIType.UI_RESET_STAGE_DATA: {
      const { stageId } = payload;
      return (stageId === Stage.ADDRESS || !stageId) ? defaultState : state;
    }
    default:
      return state;
  }
};
