
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectUID } from 'system/Profile/selectors';
import { useMerchantConfig } from 'merchants/hooks';
import { isDevEnv, isLocalEnv, isProdEnv, isUatEnv } from 'utils/env';

import { Context } from './context';

interface IGoogleTagManager {
  uid: string;
}

const GoogleTagManagerProviderComponent: FC<IGoogleTagManager> = (props) => {
  const { children, uid } = props;

  const googleTagManagerId = useMerchantConfig(['features', 'googleTagManagerId'])

  const enable = (isProdEnv() || isUatEnv()|| isDevEnv() || isLocalEnv()) && googleTagManagerId;

  const value = { enable: Boolean(enable), uid, googleTagManagerId };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const mapStateToProps = createStructuredSelector({
  uid: makeSelectUID(),
});

export const GoogleTagManagerProvider = connect(mapStateToProps)(
  GoogleTagManagerProviderComponent
);
