import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Check: React.FunctionComponent<IconProps> = ({
  className,
  width,
  height,
  fill,
}) => {
  const {
    icons: {
      markup: {
        check: { fill: defaultFill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      stroke={fill || defaultFill}
      strokeWidth="2px"
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  );
};

Check.displayName = 'CheckIcon';

Check.defaultProps = { height: '28', width: '24' };
