import { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { ITheme } from 'merchants/types/IThemeStyles';
import { BUTTON_TYPE, IStubButtonMods } from 'containers/Stubs/CommonStub/types';

export interface IButtonProps {
  size?: 'sm';
  theme: ITheme;
  onClick: MouseEventHandler;
  mods?: IStubButtonMods;
  type?: BUTTON_TYPE | string;
  disabled?: boolean;
  as?: any;
}

export const Button = styled.button.attrs((props: IButtonProps) => props)`
  min-width: ${({ theme }: IButtonProps) => theme.buttons.base.minWidth};
  min-height: ${({ theme }: IButtonProps) => theme.buttons.base.minHeight};
  padding: ${({ theme }: IButtonProps) => theme.buttons.base.padding};
  border: ${({ theme }: IButtonProps) => theme.buttons.base.border};
  border-radius: ${({ theme }: IButtonProps) => theme.buttons.base.borderRadius};
  background-color: ${({ theme }: IButtonProps) => theme.buttons.base.backgroundColor};
  color: ${({ theme }: IButtonProps) => theme.buttons.base.color};
  font-size: ${({ theme }: IButtonProps) => theme.buttons.base.fontSize};
  font-weight: ${({ theme }: IButtonProps) => theme.buttons.base.fontWeight};
  outline: none;
  cursor: pointer;
  transition: background-color 0.25s;

  :disabled {
    opacity: ${({ theme }: IButtonProps) => theme.buttons.base.disabled.opacity};
    background-color: ${({ theme }) => theme.buttons.base.disabled.backgroundColor};
    color: ${({ theme }: IButtonProps) => theme.buttons.base.disabled.color};
    border: ${({ theme }: IButtonProps) => theme.buttons.base.disabled.border};
  }

  :not(:disabled) {
    :hover {
      background-color: ${({ theme }: IButtonProps) => theme.buttons.base.hover.backgroundColor};
      color: ${({ theme }: IButtonProps) => theme.buttons.base.hover.color};
    }
  }

  ${({ size, theme }: IButtonProps) => size === 'sm' && `
    min-width: ${theme.buttons.base.small.minWidth};
    padding: ${theme.buttons.base.small.padding};
    font-size: ${theme.buttons.base.small.fontSize};
  `}
`;


export const ButtonMobile = styled(Button)`
  min-width: 300px;
`;
