import Moment from 'moment';
import { extendMoment } from 'moment-range';

import 'moment/locale/ru';
import 'moment/locale/en-gb';
import {
  DatePickerOption,
  ICreateDateState,
  IGetDatePickerOptions,
  IGetDaysInMonth,
  IGetDaysRange,
  IGetFloatingDay,
  IGetMonthRange,
  IGetYearsRange,
} from './types';

// @ts-ignore
const moment = extendMoment(Moment);

export const getDaysInMonth: IGetDaysInMonth = (year = '', month = '') => {
  const rangeYear = Number(year || moment().format('YYYY'));
  const rangeMonth = Number(month || '01');
  return moment([rangeYear, rangeMonth], 'YYYY-MM').daysInMonth();
};

export const getYearsRange: IGetYearsRange = ({
  startYearShift = -10,
  endYearShift = 80,
}) => {
  const ifFuture = endYearShift > 0;
  const startShift = startYearShift
    ? moment().add(startYearShift, 'years')
    : moment();

  const endShift = ifFuture
    ? moment().add(endYearShift, 'years')
    : moment().subtract(-endYearShift, 'years');

  const fromDate = startShift < endShift ? startShift : endShift;
  const toDate = startShift > endShift ? startShift : endShift;
  const range = moment().range(fromDate, toDate);
  const years = Array.from(range.by('year'));
  const yearsArray = years.map((m: Moment.Moment) => m.format('YYYY'));

  return ifFuture ? yearsArray : yearsArray.reverse();
};

export const getMonthRange: IGetMonthRange = () => {
  return moment.months();
};

export const getDaysRange: IGetDaysRange = (year: string, month: string) => {
  const daysInMonth = getDaysInMonth(year, month);
  return Array.from({ length: daysInMonth }, (v, k) => k + 1);
};

export const getFloatingDay: IGetFloatingDay = ({ year, month, day = 0 }) => {
  const daysInMonth = getDaysInMonth(year, month);
  if (day <= 0 || day > daysInMonth) {
    // FYI. day - set last existed day in month for better user experience.
    return daysInMonth;
  }

  return Number(day);
};

export const getStateDate: ICreateDateState = ({ year, month, day }) => {
  const floatingDay = day
    ? getFloatingDay({
        year,
        month,
        day,
      }).toString()
    : '';

  return {
    year: {
      label: year ? year.toString() : '',
      value: year ? year.toString() : '',
    },
    month: {
      label: month ? moment.months(Number(month) - 1) : '',
      value: month ? month.toString() : '',
    },
    day: {
      label: floatingDay,
      value: floatingDay,
    },
  };
};

export const getDatePickerOptions: IGetDatePickerOptions = (params = {}, locale) => {
  const {
    yearsInterval = { startYearShift: 0, endYearShift: 0 },
    year = '',
    month = '',
  } = params;
  moment.locale(locale);

  const yearsOptions: DatePickerOption[] = getYearsRange(yearsInterval).reduce(
    (options: DatePickerOption[], option) => {
      return [
        ...options,
        { label: option.toString(), value: option.toString() },
      ];
    },
    []
  );

  const monthsOptions: DatePickerOption[] = getMonthRange().reduce(
    (options: DatePickerOption[], option, idx) => {
      return [...options, { label: option, value: (idx + 1).toString() }];
    },
    []
  );

  const daysOptions: DatePickerOption[] = getDaysRange(year, month).reduce(
    (options: DatePickerOption[], option: number) => {
      return [
        ...options,
        { label: option.toString(), value: option.toString() },
      ];
    },
    []
  );

  return {
    year: yearsOptions,
    month: monthsOptions,
    day: daysOptions,
  };
};
