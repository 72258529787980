import { List } from 'immutable';

import { DroppedFile } from 'components/common/FilesHandler';

export const convertToBufferArray = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = ({ target: { result } }: any) => {
      resolve(result);
    };
    reader.onerror = (err) => {
      reader.abort();
      reject(err);
    };
  });
};

export const convertAllToBufferArray = (files: File[]): Promise<any[]> => {
  return Promise.all(files.map(convertToBufferArray));
};

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const buttifyFileSize = (size: any) => {
  let l = 0;
  let n = parseInt(size, 10) || 0;

  while (n >= 1024 && ++l) {
    n /= 1024;
  }

  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)  } ${  units[l]}`;
}

export const getFileToken = (files: List<Map<string, DroppedFile>>) =>
  files?.size
    ? files.map((file) => file.get('fileToken'))
    : undefined
