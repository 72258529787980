import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { IThemeProps } from 'merchants/types/IThemeStyles';

export interface INavProps {
  children: ReactNode;
}

export const Nav: FunctionComponent<INavProps> = ({ children }) => (
  <Wrapper>
    <Body>{children}</Body>
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: ${({ theme }: IThemeProps) => theme.components.navigation.backgroundColor};
  box-shadow: ${({ theme }: IThemeProps) => theme.components.navigation.boxShadow};
  z-index: 1;
  position: relative;
  padding: 14px 16px;
`;

const Body = styled.nav`
  max-width: calc(1170px + 32px);
  margin: 0 auto;
`;
