import { createSelector } from 'reselect';

import { makeSelectCompanySignerEmails } from './signatures';

export const makeSelectSignatureBlockData = () =>
  createSelector(makeSelectCompanySignerEmails(), (esignature) => {
    return {
      esignature,
    };
  });
