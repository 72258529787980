import React, { useState } from 'react';

export const withTimeTracking = <BaseProps extends {}>(
  WrappedComponent: React.ComponentType<BaseProps>
)  => {
  const WithTimeTracking: any = (props: any) => {
    const [time, updateTime] = useState({
      start: 0,
      duration: 0,
    } as any);

    return (
      <WrappedComponent
        onBlur={() => {
          const durationDiff = time.duration + (Date.now() - time.start) / 1000;
          updateTime({ duration: durationDiff });
        }}
        onFocus={() =>
          updateTime({ start: Date.now(), duration: time.duration })
        }
        {...props}
      />
    );
  };

  WithTimeTracking.displayName = `WithTimeTracking(${WrappedComponent.displayName})`;

  return WithTimeTracking;
};
