import pick from 'lodash/pick';

import { IStageRouteProps } from 'system/Routing/interfaces';
import { Block, Stage } from 'system/Stages/types';
import { RoutingSchema, Step, SubStep } from 'system/Routing/types';
import { flattedWithKeys } from 'system/Routing/utils';
import { Routes } from 'system/Routing/Routes';

import { Field } from 'utils/types/shared/enums';
import { RouteStartVerification } from 'containers/Routes/RouteStartVerification';
import { Stage as StageComponent } from 'containers/Stage';

export const stagesRouteSchema: RoutingSchema = {
  [Stage.IDENTITY]: {
    [Block.RESIDENCE]: [
      {
        id: Step.RESIDENCE,
        fields: [Field.COUNTRY, Field.REGION, Field.SUB_REGION, Field.SSN],
      },
    ],
    [Block.FULL_RESIDENCE]: [
      {
        id: Step.FULL_RESIDENCE,
        fields: [
          Field.COUNTRY,
          Field.REGION,
          Field.SUB_REGION,
          Field.SSN,
          Field.ZIP_CODE,
          Field.STREET,
          Field.CITY,
          Field.APT_SUITE,
          `${Field.PHONE}.${Field.PHONE_CODE}`,
          `${Field.PHONE}.${Field.PHONE_COUNTRY}`,
          `${Field.PHONE}.${Field.PHONE_NUMBER}`,
        ],
      },
    ],
    [Block.PHONE]: [
      {
        id: Step.PHONE_NUMBER,
        fields: [
          Field.PHONE_CODE,
          `${Field.PHONE}.${Field.PHONE_CODE}`,
          `${Field.PHONE}.${Field.PHONE_COUNTRY}`,
          `${Field.PHONE}.${Field.PHONE_NUMBER}`,
        ],
      },
    ],
    [Block.IDENTITY_DOCUMENT]: [
      // {
      //   id: Step.LIVENESS_CHECK,
      //   fields: [],
      // },
      {
        id: Step.IDENTITY_DOCUMENT,
        fields: [Field.SUB_TYPE], // there is Field.ISSUING_COUNTRY is temp field. is used for recognition
      },
      {
        id: Step.UPLOAD,
        fields: [Field.IMAGES, Field.SELFIE_IMAGE],
      },
      {
        id: Step.PERSONAL_INFORMATION,
        fields: [
          Field.NATIONALITY,
          Field.FIRST_NAME,
          Field.MIDDLE_NAME,
          Field.LAST_NAME,
          Field.GENDER,
          Field.DATE_OF_BIRTH,
        ].map((f: Field) => `personalData.${f}`),
      },
      [
        {
          id: Step.INTERNATIONAL_PASSPORT_INFORMATION,
          required: {
            [`${Block.IDENTITY_DOCUMENT}.${Field.SUB_TYPE}`]: 'internationalPassport',
          },
          fields: [
            Field.SERIAL_NUMBER,
            Field.ISSUING_COUNTRY,
            Field.HAS_EXPIRE_DATE,
            Field.EXPIRE_DATE,
          ],
        },
        {
          id: Step.NATIONAL_ID_CARD_INFORMATION,
          required: {
            [`${Block.IDENTITY_DOCUMENT}.${Field.SUB_TYPE}`]: 'nationalIdCard',
          },
          fields: [
            Field.SERIAL_NUMBER,
            Field.ISSUING_COUNTRY,
            Field.HAS_EXPIRE_DATE,
            Field.EXPIRE_DATE,
          ],
        },
        {
          id: Step.DRIVER_LICENSE_INFORMATION,
          required: {
            [`${Block.IDENTITY_DOCUMENT}.${Field.SUB_TYPE}`]: 'driverLicense',
          },
          fields: [
            Field.SERIAL_NUMBER,
            Field.ISSUING_COUNTRY,
            Field.HAS_EXPIRE_DATE,
            Field.EXPIRE_DATE,
          ],
        },
      ],
    ],
  },

  [Stage.ADDRESS]: {
    [Block.ADDRESS_DOCUMENT]: [
      {
        id: Step.ADDRESS_RESIDENCE,
        fields: [
          Field.COUNTRY,
          Field.REGION,
          Field.SUB_REGION,
          Field.ZIP_CODE,
          Field.STREET,
          Field.CITY,
          Field.APT_SUITE,
        ].map((f: Field) => `address.${f}`),
      },
      {
        id: Step.ADDRESS_DOCUMENTS,
        fields: [Field.SUB_TYPE, Field.SUB_TYPE_DETAILED],
      },
      {
        id: Step.UPLOAD,
        fields: [Field.IMAGES],
      },
    ],
    [Block.SOCIAL_NETWORK_PROFILES]: [
      {
        id: Step.SOCIAL_PROFILES,
        fields: [Field.SOCIAL_NETWORK_PROFILES],
      },
    ],
    [Block.ADDRESS_DOCUMENT_ONLY_IMAGE]: [
      {
        id: Step.UPLOAD,
        fields: [Field.IMAGES],
      },
    ],
  },

  [Stage.ENHANCED]: {
    [Block.EMPLOYMENT]: [
      {
        id: Step.PURPOSE_QUESTIONNAIRE,
        fields: [Field.DEPOSIT_REASON, Field.INCREASE_TRADING_VOLUME_REASON, Field.OTHER_DETAILED],
      },
      {
        id: Step.EMPLOYMENT,
        fields: [
          Field.STATUS,
          Field.TAX_RESIDENCE,
          // tax_residence != US
          Field.TIN,
          // status == employed
          Field.EMPLOYMENT_PERIOD,
          Field.POSITION,
          Field.POSITION_DETAILED,
          // status == self-employed
          Field.SELF_EMPLOYMENT_TYPE,
          Field.SELF_EMPLOYMENT_TYPE_DETAILED,
          Field.SELF_EMPLOYMENT_INDUSTRY,
          Field.SELF_EMPLOYMENT_INDUSTRY_DETAILED,
        ],
      },
      {
        id: Step.EMPLOYMENT_DETAILS,
        required: {
          [`${Block.EMPLOYMENT}.${Field.STATUS}`]: 'employed',
        },
        fields: [Field.INDUSTRY, Field.INDUSTRY_DETAILED, Field.NAME, Field.COUNTRY].map(
          (f: Field) => `company.${f}`
        ),
      },
    ],
    [Block.SOURCE_OF_FUNDS]: [
      {
        id: Step.SOURCE_OF_FIAT_FUNDS,
        fields: [
          `${Field.SOURCE_OF_FIAT_FUNDS}.${Field.DETAILED_SOURCES}`,
          `${Field.SOURCE_OF_FIAT_FUNDS}.${Field.MONTHLY_INCOME}`,
        ],
      },
      {
        id: Step.UPLOAD,
        fields: [Field.IMAGES],
      },
      {
        id: Step.SOURCE_OF_CRYPTO_FUNDS,
        fields: [`${Field.SOURCE_OF_CRYPTO_FUNDS}.${Field.DETAILED_SOURCES}`],
      },
      {
        id: Step.WITHDRAWAL_DESTINATION,
        fields: [`${Field.WITHDRAWAL_DESTINATION}.${Field.DETAILED_DESTINATIONS}`],
      },
    ],
    [Block.QUESTIONNAIRE]: [
      {
        id: Step.BANKING_JURISDICTION,
        fields: [
          Field.PLANNED_TURNOVER,
          Field.PRIMARY_PURPOSE,
          Field.PRIMARY_PURPOSE_DETAILED,
          Field.BANK_JURISDICTION,
        ],
      },
      {
        id: Step.FINANCIAL_QUESTIONNAIRE,
        fields: [
          Field.PEP_CLOSE_ASSOCIATE,
          Field.PEP_CLOSE_ASSOCIATE_DETAILED,
          Field.HAS_PEP_RELATIVES,
          Field.PEP_RELATIVES_DETAILED,
          Field.PEP_POSITION,
          Field.US_TAXPAYER,
          Field.US_CITIZEN,
          Field.IS_PEP,
          Field.US_RESIDENT_ALIEN,
        ],
      },
    ],
  },

  [Stage.CORPORATE]: {
    [Block.GENERAL_INFORMATION]: [
      {
        id: SubStep.GENERAL,
        fields: [
          Field.FULL_LEGAL_NAME,
          Field.TRADE_NAME,
          Field.TYPE_OF_COMPANY,
          Field.COMPANY_REGISTRATION_NUMBER,
          Field.TIN,
        ],
      },
      {
        id: SubStep.REGISTERED_ADDRESS,
        fields: [
          Field.COUNTRY,
          Field.REGION,
          Field.SUB_REGION,
          Field.ZIP_CODE,
          Field.STREET,
          Field.CITY,
          Field.APT_SUITE,
        ].map((f: Field) => `registeredAddress.${f}`),
      },
      {
        id: SubStep.PREMISES_ADDRESS,
        fields: [Field.COUNTRY, Field.ADDRESS].map((f: Field) => `premisesAddress.${f}`),
      },
      {
        id: SubStep.GENERAL_VAT,
        fields: [Field.EMPLOYER_IDENTIFICATION_NUMBER, Field.VAT, Field.DATE_OF_INCORPORATION],
      },
      { id: SubStep.SOCIAL_PROFILES, fields: [Field.SOCIAL_NETWORK_PROFILES] },
    ],
    [Block.BUSINESS_ACTIVITY]: [
      {
        id: SubStep.BUSINESS_ACTIVITY_INDUSTRY,
        fields: [Field.INDUSTRY_CORP, Field.OTHER_INDUSTRY_CORP],
      },
      {
        id: SubStep.BUSINESS_ACTIVITY_FIAT,
        fields: [Field.INDUSTRY_CORP, Field.OTHER_INDUSTRY_CORP],
      },
      {
        id: SubStep.BUSINESS_ACTIVITY_FIAT_UPLOAD,
        fields: [],
      },
      {
        id: SubStep.BUSINESS_ACTIVITY_CRYPTO,
        fields: [Field.PLANNING_DEPOSIT_CRYPTO_CORP, Field.CRYPTO_FUNDS_DETAILED_SOURCES_CORP],
      },
      {
        id: SubStep.BUSINESS_ACTIVITY_CRYPTO_UPLOAD,
        fields: [],
      },
    ],
    [Block.ACCOUNT_ACTIVITY]: [
      {
        id: SubStep.ACTIVITY,
        fields: [
          Field.PRIMARY_PURPOSE_CORPORATE,
          Field.PRIMARY_PURPOSE_DETAILED_CORPORATE,
          Field.PLANNED_MONTHLY_DEPOSIT_VOLUME,
          Field.EXPECTED_MONTHLY_DEPOSIT_OPERATIONS,
          Field.PLANNED_MONTHLY_WITHDRAWAL_VOLUME,
        ],
      },
    ],
    [Block.LICENSES]: [
      {
        id: SubStep.LICENSES_PERMISSIONS,
        fields: [
          Field.LICENSING,
          Field.LICENSE_LINK,
          Field.LICENSE_FILES,
          Field.PERMISSIONS_AND_AUTHORIZATIONS,
          Field.PERMISSIONS_AND_AUTHORIZATIONS_LINK,
          Field.PERMISSIONS_AND_AUTHORIZATIONS_FILE,
          Field.AML_REPORTING_ENTITY,
        ],
      },
      {
        id: SubStep.AML_REGULATION,
        fields: [
          Field.AML_PROGRAM,
          Field.AML_PROGRAM_LINK,
          Field.AML_PROGRAM_FILES,
          Field.SHARES_TRADED_ON_STOCK_EXCHANGE,
          Field.STOCK_EXCHANGE_LINK,
          Field.STOCK_EXCHANGE_FILE,
          Field.AUDITED_ENTITY,
          Field.AUDITOR_LINK,
          Field.AUDITOR_FILE,
        ],
      },
    ],
    [Block.PAYMENT_DETAILS]: [
      { id: SubStep.PAYMENT_DETAILS_MENU, fields: [] },
      { id: SubStep.PAYMENT_DETAILS_WALLET_ADDRESS, fields: [] },
      { id: SubStep.PAYMENT_DETAILS_BANK_INFO, fields: [] },
      { id: SubStep.PAYMENT_DETAILS_BANK_STATEMENTS, fields: [] },
      { id: SubStep.PAYMENT_DETAILS_BANK_INTERNATIONAL, fields: [] },
      { id: SubStep.PAYMENT_DETAILS_BANK_CORRESPONDENT, fields: [] },
    ],
    [Block.PERSONAL_INFO]: [
      { id: SubStep.ROLE_SELECT_PREVIEW, fields: [] },
      { id: SubStep.SELECT_ROLE, fields: [] },
      {
        id: SubStep.UBO_DOCUMENTS,
        fields: [Field.INTEREST_SHARES],
      },
      {
        id: SubStep.UBO_UPLOAD,
        fields: [Field.IMAGES],
      },
      {
        id: SubStep.UBO_SOURCE_OF_FUNDS,
        fields: [],
      },
      {
        id: SubStep.POSITION_DOCUMENTS,
        fields: [Field.SUB_TYPE],
      },
      {
        id: SubStep.POSITION_UPLOAD,
        fields: [Field.IMAGES],
      },
      {
        id: SubStep.IDENTITY_LIVENESS_CHECK,
        fields: [Field.SUB_TYPE],
      },
      {
        id: SubStep.IDENTITY_DOCUMENT,
        fields: [Field.SUB_TYPE],
      },
      {
        id: SubStep.IDENTITY_SCAN,
        fields: [Field.IMAGES, Field.SELFIE_IMAGE],
      },
      {
        id: SubStep.IDENTITY_UPLOAD,
        fields: [Field.IMAGES, Field.SELFIE_IMAGE],
      },
      {
        id: SubStep.PERSONAL_INFORMATION,
        fields: [
          Field.NATIONALITY,
          Field.FIRST_NAME,
          Field.MIDDLE_NAME,
          Field.LAST_NAME,
          Field.GENDER,
          Field.DATE_OF_BIRTH,
        ].map((f: Field) => `personalData.${f}`),
      },
      {
        id: SubStep.IDENTITY_DOC_INFO,
        fields: [
          Field.SERIAL_NUMBER,
          Field.ISSUING_COUNTRY,
          Field.HAS_EXPIRE_DATE,
          Field.EXPIRE_DATE,
        ],
      },
      {
        id: SubStep.PHONE_NUMBER,
        fields: [
          Field.PHONE_CODE,
          `${Field.PHONE}.${Field.PHONE_CODE}`,
          `${Field.PHONE}.${Field.PHONE_COUNTRY}`,
          `${Field.PHONE}.${Field.PHONE_NUMBER}`,
        ],
      },
      {
        id: SubStep.ADDRESS_RESIDENCE,
        fields: [
          Field.COUNTRY,
          Field.REGION,
          Field.SUB_REGION,
          Field.ZIP_CODE,
          Field.STREET,
          Field.CITY,
          Field.APT_SUITE,
        ].map((f: Field) => `address.${f}`),
      },
      {
        id: SubStep.ADDRESS_DOCUMENTS,
        fields: [Field.SUB_TYPE, Field.SUB_TYPE_DETAILED],
      },
      {
        id: SubStep.ADDRESS_UPLOAD,
        fields: [Field.IMAGES],
      },
      {
        id: SubStep.SOCIAL_PROFILES,
        fields: [Field.SOCIAL_NETWORK_PROFILES],
      },
      {
        id: Step.FINANCIAL_QUESTIONNAIRE,
        fields: [
          Field.PEP_CLOSE_ASSOCIATE,
          Field.PEP_CLOSE_ASSOCIATE_DETAILED,
          Field.HAS_PEP_RELATIVES,
          Field.PEP_RELATIVES_DETAILED,
          Field.PEP_POSITION,
          Field.US_TAXPAYER,
          Field.US_CITIZEN,
          Field.IS_PEP,
          Field.US_RESIDENT_ALIEN,
        ],
      },
    ],
    [Block.COMPANY_DOCUMENTS]: [
      {
        id: SubStep.UPLOAD,
        fields: [
          Field.CERTIFICATE_OF_REGISTRATION,
          Field.STATUTORY_DOCUMENTS,
          Field.EXTRACT_FROM_REGISTER,
          Field.CORPORATE_FOUNDS_CONFIRMATION,
          Field.SIGNED_RESOLUTION,
          Field.ALL_DOCUMENTS,
        ],
      },
    ],
    [Block.SIGNATURE]: [
      {
        id: SubStep.SIGNATURE,
        fields: [],
      },
    ],
  },

  [Stage.CFD]: {
    // step getting from Dicts/cdf.questions
    [Block.APPROPRIATENESS_TEST]: [],
    [Block.CLIENT_CATEGORIZATION]: [],
  },
};

const customStageRoutes = (routes: IStageRouteProps, stages: Stage[] = []) => {
  if (!stages.length) {
    return routes;
  }
  return pick(routes, ['stub', ...stages]);
};

export const getStagesRouteSchema = (stages: Stage[] = []) => {
  if (!stages.length) {
    return stagesRouteSchema;
  }
  return pick(stagesRouteSchema, stages);
};

const root = {
  path: Routes.root(),
  key: 'root',
  exact: true,
  component: RouteStartVerification,
};

const all = (routes: IStageRouteProps) => {
  return {
    path: Routes.stage.all(),
    key: 'stage',
    component: StageComponent,
    routes: () => flattedWithKeys(customStageRoutes(routes)),
  };
};

const oneStage = (routes: IStageRouteProps, stage: Stage) => {
  return {
    path: Routes.stage.one(stage, '', true),
    key: 'stage',
    component: StageComponent,
    routes: () => flattedWithKeys(customStageRoutes(routes, [stage])),
  };
};

const oneOfStages = (routes: IStageRouteProps, stages: Stage[]) => {
  return {
    path: Routes.stage.oneOf(stages, [''], true),
    key: 'stage',
    component: StageComponent,
    routes: () => flattedWithKeys(customStageRoutes(routes, stages)),
  };
};

export const getRoutes = (routes: IStageRouteProps, stages: Stage[]) => {
  if (!stages || !stages.length) {
    return () => [root, all(routes)];
  }

  if (stages.length === 1) {
    return () => [
      {
        ...root,
        forceSetSingleStageEntryPoint: true, // todo: can be discussed
      },
      oneStage(routes, stages[0]),
    ];
  }

  if (stages.length > 1) {
    return () => [root, oneOfStages(routes, stages)];
  }

  return () => [root, all(routes)];
};
