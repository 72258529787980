import { fromJS, Map } from 'immutable';
import { ActionType, Type } from './actionTypes';

export const defaultState = fromJS({
  data: {
    redirectUrl: '',
    skip: false,
    submitted: false,
    socketId: '',
    callLimitExceeded: false,
    connected: false,
  },
  error: null,
  loading: true,
});

function mergeDataPayload(state: Map<string, any>, payload: any) {
  return state.get('data').merge(fromJS(payload));
}

export const reducer = (state = defaultState, action: ActionType) => {
  switch (action.type) {
    case Type.LIVENESS_CHECK_TOKEN_REQUEST:
    case Type.LIVENESS_CHECK_MOBILE_TOKEN_REQUEST:
      return defaultState.set('loading', true);
    case Type.LIVENESS_CHECK_TOKEN_ERROR:
    case Type.LIVENESS_CHECK_MOBILE_TOKEN_ERROR:
      return state.set('error', fromJS(action.payload)).set('loading', false);
    case Type.LIVENESS_CHECK_TOKEN_SUCCESS:
    case Type.LIVENESS_CHECK_MOBILE_TOKEN_SUCCESS:
      return state
        .set('data', mergeDataPayload(state, action.payload))
        .set('error', null)
        .set('loading', false);
    case Type.LIVENESS_CHECK_APP_CONNECT:
    case Type.LIVENESS_CHECK_APP_DISCONNECT:
      return state.setIn(['data', 'connected'], false);
    case Type.LIVENESS_CHECK_CLEANUP:
      return defaultState;
    case Type.LIVENESS_CHECK_WEBSOCKET_CONNECT:
      return state;
    case Type.LIVENESS_CHECK_WEBSOCKET_MESSAGE: {
      return state.set('data', mergeDataPayload(state, action.payload));
    }
    case Type.LIVENESS_CHECK_WEBSOCKET_ERROR:
      return state.set('error', fromJS(action.payload)).set('loading', false);
    default:
      return state;
  }
};
