import styled from 'styled-components';

export const Description = styled.p`
  max-width: 493px;
  font-size: 15px;
  line-height: 1.6;
  text-align: center;
  color: ${({ theme }) => theme.text.description.color};
  margin: 0 auto 32px;
  white-space: pre-wrap;
`;
