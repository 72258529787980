import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import { MerchantParam, WorkingMode } from 'utils/types/shared/enums';

export const settingsSelector = (state: any) => state.get('settings');

export const makeSelectLoading = () =>
  createSelector(
    settingsSelector,
    (state) => state.get('loading')
  );

export const makeSelectErrors = () =>
  createSelector(
    settingsSelector,
    (state) => state.get('errors', List())
  );

export const makeSelectLinks = () =>
  createSelector(
    settingsSelector,
    (state) => state.get('links', Map())
  );

export const makeSelectIssuerIndexLink = createSelector(
  makeSelectLinks(),
  (stage: Map<string, string>) => stage.get('index', '/')
);

export const makeSelectIssuerFallbackLink = createSelector(
  makeSelectLinks(),
  (stage: Map<string, string>) => stage.get('fallback', '/')
);


export const makeSelectIssuerBaseUrl = () => createSelector(
  makeSelectLinks(),
  (stage: Map<string, string>) => stage.get('baseUrl', '')
);

export const makeSelectWorkingMode = () => createSelector(
  settingsSelector,
  (state) => state.get(MerchantParam.WORKING_MODE, WorkingMode.ALL_STAGES) || WorkingMode.ALL_STAGES
);

export const makeSelectLinesCheckTestingValue = () =>
  createSelector(
    settingsSelector,
    (state) => state.getIn(['abTesting', 'livenessCheck'], '')
  );

export const makeSelectABLivenessCheckEnabled = () =>
  createSelector(makeSelectLinesCheckTestingValue(), (name) => name === 'livenessCheck');

export const makeSelectABSelfieEnabled = () =>
  createSelector(makeSelectLinesCheckTestingValue(), (name) => name === 'selfie');

export const makeSelectAppConfig = () =>
  createSelector(
    settingsSelector,
    (state) => state.get('appConfig', Map())
  )
;

export const makeSelectApiUrl = () =>
  createSelector(
    makeSelectAppConfig(),
    (config: Map<string, string>) => config.get('api', '')
  )
;

export const makeSelectCdnUrl = () =>
  createSelector(
    makeSelectAppConfig(),
    (config: Map<string, string>) => config.get('cdn', '')
  )
;

export const makeSelectEnv = () =>
  createSelector(
    makeSelectAppConfig(),
    (config: Map<string, string>) => config.get('env', '')
  )
;

export const makeSelectConfigStages = () =>
  createSelector(
    settingsSelector,
    (settings: Map<string, string>) => settings.get('stages', List())
  )
;

export const makeSelectTabIndex = (name: string) =>
  createSelector(
    settingsSelector,
    (settings: Map<string, string>) => settings.getIn(['tabs', name], 0)
  )
;

