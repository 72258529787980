import { fromJS, List, Map } from 'immutable';
import { createSelector } from 'reselect';
import { matchPath } from 'react-router-dom';
import camelCase from 'lodash/camelCase';

import { Stage, StepAlias } from 'system/Stages/types';
import { joinByChar } from 'utils/helpers';
import { Step, SubStep } from 'system/Routing/types';
import {
  makeSelectExternalProfiles,
  makeSelectStageCorrectionMistakes,
} from 'system/Profile/selectors';
import { updateSourceMeta } from 'stages/Corporate/sagas/utils';
import { makeSelectRouterPathname } from 'system/Bootstrap/selectors';
import { Field } from 'utils/types/shared/enums';

export enum FileType {
  selfie = 'selfie',
}
export enum SubStore {
  DATA = 'data',
  META = 'meta',
}
export type subStore = 'data' | 'meta';
export type selectorSubStoreParam = subStore | undefined;

export const selectorCorporate = (state: any) => {
  return state.getIn(['dossier', Stage.CORPORATE], Map());
};

export const selectCorporateBlock = (
  blockId: string,
  subStore: selectorSubStoreParam = SubStore.DATA
) =>
  createSelector(selectorCorporate, (state) => {
    return state.getIn([subStore, blockId], Map());
  });

export const selectCorporateBlockStep = (
  blockId: string,
  subStore: selectorSubStoreParam = SubStore.DATA,
  stepId: string
) =>
  createSelector(selectCorporateBlock(blockId, subStore), (state) => {
    return state.get(stepId, Map());
  });

export const makeSelectStepMeta = (fields: any, blockId: string, stepId: string) =>
  createSelector(
    selectCorporateBlockStep(blockId, SubStore.META, stepId),
    makeSelectExternalProfiles(),
    makeSelectStageCorrectionMistakes('corporate'),
    (stepMeta, externalProfiles, correctionMistakes) => {
      return fromJS(fields).reduce(
        updateSourceMeta({ externalProfiles, correctionMistakes }),
        stepMeta
      );
    }
  );

export const businessActivitySelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(selectorCorporate, (state) => {
    return state.getIn([subStore, joinByChar(Step.BUSINESS_ACTIVITY)], Map());
  });

export const accountActivitySelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(selectorCorporate, (state) => {
    return state.getIn([subStore, joinByChar(Step.ACCOUNT_ACTIVITY)], Map());
  });

export const licensesSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(selectorCorporate, (state) => {
    return state.getIn([subStore, joinByChar(Step.LICENSES)], Map());
  });

export const beneficiaryBankDetailsSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(selectorCorporate, (state) => {
    return state.getIn([subStore, joinByChar(Step.BANKING_JURISDICTION)], Map());
  });

export const personalInfoSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(selectorCorporate, (state) => {
    return state.getIn([subStore, joinByChar('personalInfo')], Map());
  });

export const companyDocumentsSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(selectorCorporate, (state) => {
    return state.getIn([subStore, joinByChar(Step.COMPANY_DOCUMENTS)], Map());
  });

export const makeSelectCorporateRouterParams = () =>
  createSelector(makeSelectRouterPathname(), (pathname) => {
    return matchPath<{ stageId: string; blockId: string; stepId: string }>(pathname, {
      path: '/:stageId/:blockId/:stepId',
    })?.params;
  });

export const makeSelectCurrentBlockId = () =>
  createSelector(makeSelectCorporateRouterParams(), (params) => {
    return camelCase(params?.blockId);
  });

export const makeSelectCurrentStepId = () =>
  createSelector(makeSelectCorporateRouterParams(), (params) => {
    return camelCase(params?.stepId);
  });

export const makeSelectLoading = () =>
  createSelector(selectorCorporate, (state) => {
    return state.get('loading', false);
  });

const livenessCheckSelector = () =>
  createSelector(personalInfoSelector(), (state) => {
    return state.getIn([joinByChar(StepAlias.LIVENESS_CHECK)], Map());
  });

export const makeSelectLivenessCheckStats = () => {
  return createSelector(selectorCorporate, (state) => {
    const statistics = state.getIn(
      [
        SubStore.DATA,
        joinByChar(StepAlias.PERSONAL_INFORMATION),
        joinByChar(StepAlias.LIVENESS_CHECK_STATS),
      ],
      List()
    );
    return statistics.toJS();
  });
};

export const makeSelectUploadMistakes = () =>
  createSelector(makeSelectStageCorrectionMistakes('identity'), (mistakes) => {
    return mistakes.filter((mistake: Field) =>
      [Field.IMAGES, Field.SELFIE_IMAGE].includes(mistake)
    );
  });

export const makeSelectLivenessCheckSelfie = () => {
  return createSelector(livenessCheckSelector(), (state) => {
    const images = state.get('images', List());

    return images.get(0, Map());
  });
};

const filesSelector = createSelector(selectorCorporate, (state) =>
  state.getIn([SubStore.DATA, Step.UPLOAD], List())
);

export const makeSelectSelfieToken = () =>
  createSelector(
    filesSelector,
    makeSelectLivenessCheckSelfie(),
    (state: Map<string, any>, livenessCheckSelfie) => {
      const livenessCheckFileToken = livenessCheckSelfie.get('fileToken', '');

      const selfie = state.find(
        (file: Map<string, any>) => file.get('id') === FileType.selfie,
        null,
        Map()
      );

      const selfieFileToken = selfie.get('fileToken', '');

      return selfieFileToken || livenessCheckFileToken;
    }
  );

export const makeSelectRegisteredCountry = () =>
  createSelector(selectorCorporate, (state) => {
    return state.getIn([SubStore.DATA, SubStep.REGISTERED_ADDRESS]);
  });
