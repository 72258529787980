import { CrumbProps } from '../Navbar';


interface IGetCrumbOption {
  (pathname: string, baseOption: CrumbProps): CrumbProps[];
}

export const getCrumbOption: IGetCrumbOption = (pathname, { to, label }) => {
  if (pathname === '/') {
    return [{ label }];
  }

  return pathname.split('/').reduce(
    (option: CrumbProps[], crumb: string) => {
      if (!crumb) {
        return option;
      }

      return [...option, { to: `/${crumb}`, label: `${crumb}_header_title` }];
    },
    [{ to, label }]
  );
};

export const getAppCrumb = (pathname: string, { to, label }: CrumbProps) => {
  return getCrumbOption(pathname, { to, label})
    .slice(0,2)
    .map((crumb: CrumbProps, index: number, array: CrumbProps[]) => {
      if (index + 1 !== array.length) {
        return crumb;
      }
      if (crumb.to?.includes('corporate')) {
        return crumb
      }
      return {...crumb, to: null};
    });
};
