export enum ExternalSource {
  BACKEND = 'backend',
}

export enum MetaSource {
  BACKEND = 'Backend',
}

export enum ResultField {
  TYPE = 'type',
  TRUSTED = 'trusted'
}
