import React, { ReactElement, Reducer, useReducer } from 'react';
import styled from 'styled-components';

import { Markup } from 'components/UI';
import { StageBoxShort } from 'components/common/StagePreview/StagePreviewShort';

import {
  STAGE_PREVIEW_SHORT_WIDTH_DESKTOP,
  TIMING_FUNCTION,
  DURATION,
} from 'components/common/StagePreview/utils/constants';

import { countHiddenSliders } from './utils/helpers';

interface State  {
  shifted: number;
  sliders: number;
  visible: number;
  toShift: number;
}

interface Action {
  type: ActionTypes;
  payload: number;
}

enum ActionTypes {
  INCREMENT = 'increment',
  DECREMENT = 'decrement',
  SET = 'set',
}

interface SliderProps {
  children: ReactElement[];
  start?: number;
  toShift?: number;
  visible?: number;
}

function init({ shifted, sliders, visible, toShift }: State) {
  return {
    shifted,
    sliders,
    visible,
    toShift,
  };
}

const reducer: Reducer<State, Action> = (state, { type, payload }) => {
  const { shifted, sliders, visible, toShift } = state;
  switch (type) {
    case ActionTypes.INCREMENT:
      if (shifted >= sliders - visible) {
        return state;
      }
      return { ...state, shifted: shifted + toShift };
    case ActionTypes.DECREMENT:
      if (shifted < 1) {
        return state;
      }
      return { ...state, shifted: shifted - toShift };
    case ActionTypes.SET:
      return {
        ...state,
        shifted: payload,
      };
    default:
      throw new Error();
  }
}

export function Slider({
  children,
  start = 0,
  toShift = 1,
  visible = children.length < 4 ? children.length : 4,
}: SliderProps) {
  const sliders = children.length;
  const hiddenSliders = countHiddenSliders(sliders, toShift, visible);
  const isShowSliderNav = !!hiddenSliders;

  const initial: State = {
    shifted: start,
    sliders,
    visible,
    toShift,
  };

  const [state, dispatch] = useReducer(reducer, initial, init);

  const { shifted } = state;
  const translateX = (STAGE_PREVIEW_SHORT_WIDTH_DESKTOP + 12 + 12) * shifted;
  const showLeftArrow = shifted > 0;
  const showRightArrow = shifted !== hiddenSliders;

  const nextHiddenIndex = visible + shifted + 1;
  const prevHiddenIndex = shifted - 1 + 1;

  const _children = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      shiftedX: translateX,
    });
  });

  const handleArrowClick = (action: ActionTypes, payload = 0) => () => {
    switch (action) {
      case ActionTypes.INCREMENT:
        dispatch({ type: ActionTypes.INCREMENT, payload });
        break;
      case ActionTypes.DECREMENT:
        dispatch({ type: ActionTypes.DECREMENT, payload });
        break;
      case ActionTypes.SET:
        dispatch({ type: ActionTypes.SET, payload });
        break;
      default:
        break;
    }
  };

  return (
    <Wrap>
      {isShowSliderNav && (
        <>
          {showLeftArrow && (
            <Arrow
              onClick={handleArrowClick(ActionTypes.DECREMENT)}
              side="left"
            >
              <ArrowIcon />
            </Arrow>
          )}
          {showRightArrow && (
            <Arrow
              onClick={handleArrowClick(ActionTypes.INCREMENT)}
              side="right"
            >
              <ArrowIcon />
            </Arrow>
          )}
        </>
      )}
      <Inner>
        <Content
          nextHiddenIndex={nextHiddenIndex}
          prevHiddenIndex={prevHiddenIndex}
          translateX={translateX}
          justifyContent={sliders < 5 ? 'center' : 'flex-start'}
        >
          {_children}
        </Content>
        {isShowSliderNav && (
          <Dots>
            {[...new Array(hiddenSliders + 1)]
              .map((a, i) => i)
              .map((index) => (
                <Dot
                  isActive={index === shifted}
                  key={`dot-${index}`}
                  onClick={handleArrowClick(ActionTypes.SET, index)}
                  role="button"
                />
              ))}
          </Dots>
        )}
      </Inner>
    </Wrap>
  );
}

const breakpoint = {
  fullSlider: '1280px',
};

export const Wrap = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;

  @media (min-width: ${breakpoint.fullSlider}) {
    overflow: hidden;
  }
`;

const Inner = styled.div`
  padding: 16px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${breakpoint.fullSlider}) {
    width: 1207px;
    height: 350px;
  }
`;

const Content = styled.div<{
  translateX: number;
  nextHiddenIndex: number;
  prevHiddenIndex: number;
  justifyContent: string;
}>`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: row;
  max-width: 580px;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    justify-content: ${({ justifyContent }) => justifyContent};
  }

  @media (min-width: ${breakpoint.fullSlider}) {
    max-width: none;
    flex-wrap: nowrap;
    margin: 0 -12.4px;
    transition: transform ${DURATION + 150}ms ${TIMING_FUNCTION};
    transform: translateX(${({ translateX }) => translateX * -1}px);
  }

  > div {
    display: flex;
    flex: 0 0 274px;
    margin-bottom: 32px;

    &:last-of-type {
      margin-right: 0;
    }

    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
      margin-right: 16px;
    }

    @media (min-width: ${breakpoint.fullSlider}) {
      flex: 0 0 274.2px;
      margin: 0 12.4px;

      :nth-of-type(${({ nextHiddenIndex }) => nextHiddenIndex}),
      :nth-of-type(${({ prevHiddenIndex }) => prevHiddenIndex}) {
        > ${StageBoxShort} {
          box-shadow: none;
          transform: scale(0.85);
        }
      }
    }
  }
`;

const ArrowIcon = styled(Markup.AngleDown)``;
const Arrow = styled.button<{
  side: string;
  onClick: () => void;
}>`
  display: none;
  width: 56px;
  height: 56px;
  opacity: 1;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transition: all ${DURATION}ms ${TIMING_FUNCTION};
  border: none;
  background: ${({
    theme: {
      components: { slider },
    },
  }) => slider.arrow.backgroundColor};
  ${({ side }) => {
    if (side === 'left') {
      return `
      left: 0;
      transform: translate(-56px, -50%);
      box-shadow: 5px -2px 17px 0 rgba(147, 147, 147, 0);
      
      :focus,
       ${Wrap}:hover &{
        box-shadow: 12px -2px 17px 0 rgba(147, 147, 147, 0.23);
        transform: translate(-28px, -50%);
      }
      `;
    }
    if (side === 'right') {
      return `
      right: 0;
      transform: translate(56px, -50%);
      box-shadow: -5px -2px 17px 0 rgba(147, 147, 147, 0);
      
      :focus, ${Wrap}:hover &{
        box-shadow: -12px -2px 17px 0 rgba(147, 147, 147, 0.23);
        transform: translate(28px, -50%);
      }
      `;
    }
    return '';
  }}

  &:focus {
    outline: none;
  }

  &:hover {
    ${ArrowIcon} {
      transform: ${({ side }) => {
        if (side === 'left') {
          return 'translateX(7px) rotate(90deg) scale(1.2);';
        }
        if (side === 'right') {
          return 'translateX(-7px) rotate(-90deg) scale(1.2);';
        }
        return '';
      }};
    }
  }

  @media (min-width: ${breakpoint.fullSlider}) {
    display: block;
  }

  ${ArrowIcon} {
    transition: transform ${DURATION}ms ${TIMING_FUNCTION};
    transform: ${({ side }) => {
      if (side === 'left') {
        return 'translateX(7px) rotate(90deg);';
      }
      if (side === 'right') {
        return 'translateX(-7px) rotate(-90deg);';
      }
      return '';
    }};
  }
`;

const Dot = styled.span<{ isActive: boolean }>`
  background: red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: block;
  background: ${({
    isActive,
    theme: {
      components: {
        slider: { backgroundColor, backgroundColorActive },
      },
    },
  }) => (isActive ? backgroundColorActive : backgroundColor)};
  margin: 0 5px;
  cursor: pointer;
  transition: transform ${DURATION}ms ${TIMING_FUNCTION};

  &:hover {
    transform: scale(1.25);
  }
`;
const Dots = styled.div`
  display: none;
  justify-content: center;
  padding: 16px 0;
  transform: translateY(10px);
  opacity: 1;
  transition: transform ${DURATION}ms ${TIMING_FUNCTION};

  ${Wrap}:hover & {
    transform: translateY(0px);
  }

  @media (min-width: ${breakpoint.fullSlider}) {
    display: flex;
  }
`;
