/* eslint-disable */
import React from 'react';
import TagManager, { TagManagerArgs, DataLayerArgs } from 'react-gtm-module';
import { secureUserId } from './utils';
import { useGoogleTagManagerConfig } from './context';

import { isProdEnv } from 'utils/env';

const emptyFunc = () => undefined;

export const useDataLayer = () => {
  const { enable } = useGoogleTagManagerConfig();

  if (enable) {
    return (dataLayerArgs: DataLayerArgs) => {
      TagManager.dataLayer(dataLayerArgs);
    };
  }

  return emptyFunc;
};

export const useStartVerification = () => {
  const { enable } = useGoogleTagManagerConfig();

  if (enable) {
    return (stage: string) => {
      const payload = {
        dataLayer: {
          event: 'verification_start',
          type_event: 'important',
          platform: 'web',
          event_type: 'verification',
          status: 'start',
          verification_type: stage,
        },
      };

      setTimeout(() => {
        TagManager.dataLayer(payload);

        if (!isProdEnv()) {
          console.log('GTM: verification_start');
          console.log('GTM:', payload);
        }
      }, 2000);
    };
  }

  return emptyFunc;
};

export const useSubmitVerification = () => {
  const { enable } = useGoogleTagManagerConfig();

  if (enable) {
    return (stage: string) => {
      const payload = {
        dataLayer: {
          event: 'verification_submit',
          type_event: 'important',
          verification_type: stage,
        },
      };

      TagManager.dataLayer(payload);

      if (!isProdEnv()) {
        console.log('GTM: verification_submit');
        console.log('GTM:', payload);
      }
    };
  }

  return emptyFunc;
};

export const useGoogleTagManagerInit = () => {
  const { enable } = useGoogleTagManagerConfig();

  if (enable) {
    return (tagManagerArgs: TagManagerArgs) => {
      if (!isProdEnv()) console.log('GTM: initialize');
      TagManager.initialize(tagManagerArgs);
    };
  }

  return emptyFunc;
};

export const userLoginFlow = () => {
  const { enable, uid } = useGoogleTagManagerConfig();
  const user_id = uid ? secureUserId(uid) : '';
  const sign_in = user_id ? 1 : 0;

  if (enable) {
    return () => {
      if (!isProdEnv()) {
        console.log('GTM: userLoginFlow');
      }

      TagManager.dataLayer({
        dataLayer: {
          ...(user_id ? { user_id } : {}),
          ...(sign_in ? { sign_in } : {}),
        },
      });
    };
  }

  return emptyFunc;
};
