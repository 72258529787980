import { call, put, select } from 'redux-saga/effects';

import { Stage } from 'system/Stages/types';
import { saveStage } from 'system/Stages/sagas';
import { storeUserProfile } from 'system/Profile/sagas';
import { navigateAfterSubmit, navigateToErrorLayout } from 'system/Routing/sagas';

import { makeErrorMessage } from 'utils/error';

import { makeSelectVerificationDateByStageStart } from 'system/Analytics/selectors';
import { failedSubmitIdentity, successSubmitIdentity } from '../actions';
import {
  makeSelectAllStageData,
  makeSelectAllStageMetaData,
} from '../selectors/submit';

export function* watchForSubmit() {
  const stageData = yield select(makeSelectAllStageData());
  const fieldsInputSource = yield select(makeSelectAllStageMetaData());
  const verificationDateStart = yield select(makeSelectVerificationDateByStageStart(Stage.IDENTITY));

  try {
    const response = yield call(saveStage, {
      stageId: Stage.IDENTITY,
      stageData,
      metaData: {
        fieldsInputSource,
        verificationDateStart,
        verificationDateEnd: Date.now()
      },
    });

    const { userDossier } = response;

    if (userDossier) {
      const { stages } = userDossier;
      yield call(storeUserProfile, userDossier);
      yield call(navigateAfterSubmit, stages, { refetchUserProfile: false });
      yield put(successSubmitIdentity());
    }
  } catch (err) {
    yield put(failedSubmitIdentity([makeErrorMessage(err)]));

    yield call(navigateToErrorLayout);
  }
}
