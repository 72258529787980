import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const StarActive: React.FunctionComponent<IconProps> = ({ width, height, fill }) => (
  <svg
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.89 0L8.698 9.057H0l6.715 5.653L4.349 24l7.54-5.307L19.376 24l-2.256-9.576L24 9h-8.862z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
);

StarActive.displayName = 'StarDefaultIcon';

StarActive.defaultProps = { height: '24', width: '24' };


export const StarDefault: React.FunctionComponent<IconProps> = ({
  width,
  height,
  fill,
}) => (
  <svg
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.89 0l3.248 9H24l-6.881 5.424L19.375 24l-7.485-5.307L4.349 24l2.366-9.29L0 9.057h8.698L11.89 0zM8.66 14.06l-1.495 5.87 4.765-3.356 4.73 3.355-1.426-6.052 4.347-3.427h-5.6l-2.051-5.685-2.018 5.722H4.418L8.66 14.06z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
);

StarDefault.displayName = 'StarDefaultIcon';

StarDefault.defaultProps = { height: '24', width: '24' };


interface IStarProps extends IconProps {
  mode: 'active' | 'default';
}

export const Star: React.FunctionComponent<IStarProps> = ({
  mode,
  width,
  height,
}) => {
  const {
    icons: {
      markup: {
        star: { active, def },
      }
    },
  } = useContext(ThemeContext);

  return mode === 'active' ? (
    <StarActive height={height} width={width} fill={active} />
  ) : (
    <StarDefault height={height} width={width} fill={def} />
  );
};


Star.displayName = 'StarIcon';

Star.defaultProps = { height: '24', mode: 'default', width: '24' };
