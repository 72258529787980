import React from 'react';
import { Button, ButtonPrimary, Link } from 'components/UI';
import {
  IStubButton,
  IStubLink,
  BUTTON_TYPE,
} from 'containers/Stubs/CommonStub/types';
import { withAnalytic } from 'system/Analytics';

export const renderButtons = (buttons: IStubButton[], links: IStubLink[]) => {
  if (links.length) {
    return links.map((link: IStubLink, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <Link key={`${link.fallback}-${idx}`} to={link.fallback}>
        {link.text}
      </Link>
    ));
  }

  if (buttons.length) {
    const buttonVariants = {
      [BUTTON_TYPE.primary]: ButtonPrimary,
      [BUTTON_TYPE.regular]: Button,
    };

    return buttons.map(
      ({ text, clickHandler, mods, event }: IStubButton, idx) => {
        let Component = buttonVariants[mods.type];
        if (event) {
          Component = withAnalytic(Component, {
            onClick: { trackId: event },
          });
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Component key={`${text}-${idx}`} onClick={clickHandler} {...mods}>
            {text}
          </Component>
        );
      }
    );
  }
  return null;
};
