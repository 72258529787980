import { call, put, takeLatest, select } from 'redux-saga/effects';

import {
  baseUploadFileRequest,
  IUploadParams,
  IUploadResult,
  requestWltfsToken,
} from 'system/Dossier/Upload/sagas';
import {
  qrCodeCompleteFileUpload,
  qrCodeIsConnected,
} from 'system/Dossier/Upload/sagas/qr';
import { makeSelectRouterSearchUploadId } from 'system/Bootstrap/selectors';
import { setDossierStepSubmit } from 'system/Dossier/actions';
import { navigateToErrorLayout } from 'system/Routing/sagas';

import { generateFileId } from 'utils/helpers';
import { makeErrorMessage } from 'utils/error';

import {
  addFiles,
  errorUploadFiles,
  setEnhancedMeta,
  successUploadFiles,
} from '../actions';
import {
  Type,
  IEnhancedUploadFileRequest,
  IEnhancedStepInitialSet,
} from '../actionTypes';
import {
  makeSelectAvailableSourceByType,
  makeSelectStepMeta,
} from '../selectors';

import { watchFormSubmit } from './submit';

export function* handleResult({ result }: { result: IUploadParams[] }) {
  try {
    yield put(addFiles(result[0]?.id, result));
    const isQrCodeConnected = yield qrCodeIsConnected();

    if (isQrCodeConnected) {
      return;
    }

    yield put(setDossierStepSubmit({ fields: result }));
  } catch (error) {
    console.log(error);
  }
}

function* watchForUploadFileRequest(action: IEnhancedUploadFileRequest) {
  try {
    const {
      payload: { files },
    } = action;

    yield call(requestWltfsToken);

    const uploadId = yield select(makeSelectRouterSearchUploadId());
    const source = yield select(makeSelectAvailableSourceByType());
    const sourceId = source.get('id');

    const uploadFiles = files.reduce(
      (acc: [] | IUploadResult[], file: File) => {
        return [...acc, { file, id: sourceId }];
      },
      []
    );

    const result = yield call(baseUploadFileRequest, uploadFiles);

    if (uploadId) {
      const fileTokens = result.map(({ id, ...rest }: IUploadParams) => ({
        id,
        subType: id,
        fileType: 'multi',
        fileId: generateFileId(),
        ...rest,
      }));

      yield call(qrCodeCompleteFileUpload, { uploadId, fileTokens });
    }

    yield call(handleResult, { result });
    yield put(successUploadFiles());
  } catch (err) {
    yield put(errorUploadFiles([makeErrorMessage(err)]));
    yield call(navigateToErrorLayout);
  }
}

function* watchForEnhancedStepSet(action: IEnhancedStepInitialSet) {
  try {
    const {
      payload: { stepId, fields },
    } = action;

    const stepMeta = yield select(makeSelectStepMeta(fields));
    yield put(setEnhancedMeta({ stepId, fields: stepMeta }));
  } catch (err) {
    console.log(err);
  }
}

export function* rootSaga() {
  yield takeLatest(
    Type.ENHANCED_UPLOAD_FILES_REQUEST,
    watchForUploadFileRequest
  );

  yield takeLatest(Type.ENHANCED_SUBMIT_REQUEST, watchFormSubmit);

  yield takeLatest([Type.ENHANCED_STEP_INITIAL_SET], watchForEnhancedStepSet);
}
