export enum ENVIRONMENTS {
  MOCK = 'mock',
  LOCAL = 'local',
  DEV = 'dev',
  UAT = 'uat',
  PROD = 'prod',
}

export enum MERCHANT {
  CEX = 'cex',
  IDENTANCE = 'identance',
}

export enum MetaSource {
  AUTO = 'Auto',
  AUTO_CHANGED = 'AutoChanged',
  MANUAL = 'Manual',
}

export enum Field {
  APT_SUITE = 'aptSuite',
  CITY = 'city',
  COUNTRY = 'country',
  DATE_OF_BIRTH = 'dateOfBirth',
  EXPIRE_DATE = 'expireDate',
  FIRST_NAME = 'firstName',
  GENDER = 'gender',
  HAS_EXPIRE_DATE = 'hasExpireDate',
  ISSUE_DATE = 'issueDate',
  ISSUING_COUNTRY = 'issuingCountry',
  LAST_NAME = 'lastName',
  MIDDLE_NAME = 'middleName',
  NATIONALITY = 'nationality',
  PHONE = 'phone',
  PHONE_COUNTRY = 'country',
  PHONE_CODE = 'code',
  PHONE_NUMBER = 'number',
  PHONE_MASK = 'mask',
  PHONE_IS_VALIDATED = 'isValidated',
  REGION = 'region',
  SERIAL_NUMBER = 'serialNumber',
  PERSONAL_CODE = 'personalCode',
  SCAN = 'scan',
  SSN = 'SSN',
  STATUS = 'status',
  STREET = 'street',
  SUB_REGION = 'subregion',
  SUB_TYPE = 'subType',
  SUB_TYPE_DETAILED = 'subTypeDetailed',
  ZIP_CODE = 'zipCode',
  IMAGES = 'images',
  SELFIE_IMAGE = 'selfieImage',
  TAX_RESIDENCE = 'taxResidence',
  TIN = 'TIN',
  EMPLOYMENT_PERIOD = 'employmentPeriod',
  POSITION = 'position',
  POSITION_DETAILED = 'positionDetailed',
  SELF_EMPLOYMENT_TYPE = 'selfEmploymentType',
  SELF_EMPLOYMENT_TYPE_DETAILED = 'selfEmploymentTypeDetailed',
  SELF_EMPLOYMENT_COMPANY_NAME = 'selfEmploymentCompanyName',
  SELF_EMPLOYMENT_INDUSTRY = 'selfEmploymentIndustry',
  SELF_EMPLOYMENT_INDUSTRY_DETAILED = 'selfEmploymentIndustryDetailed',
  INDUSTRY = 'industry',
  INDUSTRY_DETAILED = 'industryDetailed',
  NAME = 'name',
  MONTHLY_INCOME = 'monthlyIncome',
  DETAILED_SOURCES = 'detailedSources',
  CRYPTO_FUNDS_DEPOSIT = 'cryptoFundsDeposit',
  CRYPTO_FUNDS_WITHDRAW = 'cryptoFundsWithdraw',
  DETAILED_DESTINATIONS = 'detailedDestinations',
  PLANNED_TURNOVER = 'plannedTurnover',
  PRIMARY_PURPOSE = 'primaryPurpose',
  PRIMARY_PURPOSE_DETAILED = 'primaryPurposeDetailed',
  BANK_JURISDICTION = 'bankJurisdiction',
  BANK_NAME = 'bankName',
  DIFFERENT_BANK_FOR_WITHDRAWAL = 'differentBankForWithdrawal',
  WITHDRAWAL_BANK_JURISDICTION = 'withdrawalBankJurisdiction',
  WITHDRAWAL_BANK_NAME = 'withdrawalBankName',
  PEP_CLOSE_ASSOCIATE = 'pepCloseAssociate',
  PEP_CLOSE_ASSOCIATE_DETAILED = 'pepCloseAssociateDetailed',
  HAS_PEP_RELATIVES = 'hasPepRelatives',
  PEP_RELATIVES_DETAILED = 'pepRelativesDetailed',
  PEP_POSITION = 'pepPosition',
  US_TAXPAYER = 'usTaxpayer',
  US_CITIZEN = 'usCitizen',
  IS_PEP = 'isPep',
  US_RESIDENT_ALIEN = 'usResidentAlien',
  JURISDICTION_CODE = 'jurisdictionCode',
  // Used in smart correction only for cleaning values
  SOCIAL_NETWORK_PROFILES = 'socialNetworkProfiles',
  SOURCE_OF_FIAT_FUNDS = 'sourceOfFiatFunds',
  WITHDRAWAL_DESTINATION = 'withdrawalDestination',
  SOURCE_OF_CRYPTO_FUNDS = 'sourceOfCryptoFunds',
  // Purpose Questionnaire
  PURPOSE_QUESTIONNAIRE_REASON = 'purposeQuestionnaireReason',
  DEPOSIT_REASON = 'depositReason',
  INCREASE_TRADING_VOLUME_REASON = 'increaseTradingVolumeReason',
  OTHER_DETAILED = 'otherDetailed',
  // Occupation
  OCCUPATION = 'occupation',
  OCCUPATION_EMPLOYED = 'employed',
  OCCUPATION_SELFEMPLOYED = 'selfemployed',
  OCCUPATION_UNEMPLOYED = 'unemployed',
  OCCUPATION_EMPLOYED_DETAILS = 'employedDetails',
  OCCUPATION_SELFEMPLOYED_DETAILS = 'selfEmployedDetails',
  OCCUPATION_STATUS = 'status',
  OCCUPATION_INDUSTRY = 'industry',
  OCCUPATION_INDUSTRY_DETAILED = 'industryDetailed',
  OCCUPATION_POSITION = 'position',
  OCCUPATION_POSITION_DETAILED = 'positionDetailed',
  OCCUPATION_TYPE = 'type',
  OCCUPATION_TYPE_DETAILED = 'typeDetailed',
  // Questionnaire identity
  QUESTIONNAIRE_PAGE = 'questionnairePage',
  ACCOUNT_CREATE_REASON = 'accountCreateReason',
  ACCOUNT_CREATE_REASON_OTHER = 'accountCreateReasonOther',
  HOW_FIND_OUR_EXCHANGE = 'howFindOurExchange',
  HOW_FIND_OUR_EXCHANGE_OTHER = 'howFindOurExchangeOther',
  PASS_VERIFICATION_BY_SELF = 'passVerificationBySelf',
  CRYPTO_FOUND_WITH_DRAW = 'cryptoFundsWithdraw',
  CRYPTO_FOUND_WITH_DRAW_DESTINATION = 'cryptoFundsWithdrawDestination',
  CRYPTO_FOUND_WITH_DRAW_DESTINATION_OTHER = 'cryptoFundsWithdrawDestinationOther',
  WORK_WITH_TRADERS = 'workWithTraders',
  MONTHLY_TURNOVER = 'monthlyTurnover',
  SOURCE_OF_FUNDS = 'sourceOfFunds',
  SOURCE_OF_FUNDS_OTHER = 'sourceOfFundsOther',
  ANNUAL_FUNDS_AMOUNT = 'annualFundsAmount',
  STATUS_IDENTITY = 'status',
  EMPLOYMENT_POSITION_IDENTITY = 'employmentPosition',
  WHEN_RECEIVE_PROFIT = 'whenReceiveProfitFrom',
  WHEN_RECEIVE_FUNDS = 'whenReceiveFunds',

  // Corporate
  ENTRY_ID = 'entryId',

  // General info
  FULL_LEGAL_NAME = 'fullLegalName',
  TRADE_NAME = 'tradeName',
  TYPE_OF_COMPANY = 'typeOfCompany',
  COMPANY_REGISTRATION_NUMBER = 'companyRegistrationNumber',
  ADDRESS = 'address',
  SAME_AS_REGISTERED = 'sameAsRegistered',
  EMPLOYER_IDENTIFICATION_NUMBER = 'employerIdentificationNumber',
  VAT = 'VAT',
  DATE_OF_INCORPORATION = 'dateOfIncorporation',
  INDUSTRY_CORP = 'industry',
  OTHER_INDUSTRY_CORP = 'otherIndustry',
  REGION_OF_BUSINESS = 'regionOfBusiness',

  // Account activity
  PRIMARY_PURPOSE_CORPORATE = 'primaryPurpose',
  PRIMARY_PURPOSE_DETAILED_CORPORATE = 'primaryPurposeDetailed',
  PLANNED_MONTHLY_DEPOSIT_VOLUME = 'plannedMonthlyDepositVolume',
  EXPECTED_MONTHLY_DEPOSIT_OPERATIONS = 'expectedMonthlyDepositOperations',
  PLANNED_MONTHLY_WITHDRAWAL_VOLUME = 'plannedMonthlyWithdrawalVolume',

  // business activity
  ANNUAL_PROFIT_CORP = 'annualNetProfitLastYear',
  BUSINESS_MODEL_CORP = 'bussinessModel',
  BUSINESS_MODEL_CORP_FILES = 'bussinessModelFiles',

  // fiat
  FIAT_FUNDS_CORP = 'corporateSourceOfFiatFunds',
  FIAT_FUNDS_DETAILED_SOURCES_CORP = 'detailedSources',
  FIAT_FUNDS_SOURCE_CORP = 'source',
  FIAT_FUNDS_DETAILED_CORP = 'detailed',
  FIAT_FUNDS_IMAGES = 'images',

  // crypto
  PLANNING_DEPOSIT_CRYPTO_CORP = 'planToDepositCryptoFunds',

  CRYPTO_FUNDS_CORP = 'corporateSourceOfCryptoFunds',
  CRYPTO_FUNDS_DETAILED_SOURCES_CORP = 'detailedSources',
  CRYPTO_FUNDS_SOURCE_CORP = 'source',
  CRYPTO_FUNDS_DETAILED_CORP = 'detailed',
  CRYPTO_FUNDS_IMAGES = 'images',
  CRYPTO_FUNDS_PURCHASE_DETAILS_CORP = 'purchaseDetails',

  // Licenses, permissions and AML regulation
  LICENSING = 'licensing',
  LICENSE_LINK = 'licenseLink',
  LICENSE_FILES = 'proofOfLicensing',
  PERMISSIONS_AND_AUTHORIZATIONS = 'permissionsAndAuthorizations',
  PERMISSIONS_AND_AUTHORIZATIONS_LINK = 'permissionsAndAuthorizationsLink',
  PERMISSIONS_AND_AUTHORIZATIONS_FILE = 'proofOfPermissionsAndAuthorizations',
  AML_REPORTING_ENTITY = 'amlReportingEntity',
  AML_PROGRAM = 'amlProgram',
  AML_PROGRAM_LINK = 'amlProgramLink',
  AML_PROGRAM_FILES = 'proofOfAmlProgram',
  SHARES_TRADED_ON_STOCK_EXCHANGE = 'sharesTradedOnStockExchange',
  STOCK_EXCHANGE_LINK = 'stockExchangeLink',
  STOCK_EXCHANGE_FILE = 'proofOfSharesTradedOnStockExchange',
  AUDITED_ENTITY = 'auditedEntity',
  AUDITOR_LINK = 'auditorLink',
  AUDITOR_FILE = 'proofOfAuditedEntity',

  // Company Documents
  CERTIFICATE_OF_REGISTRATION = 'certificateOfRegistration',
  STATUTORY_DOCUMENTS = 'statutoryDocuments',
  EXTRACT_FROM_REGISTER = 'extractFromRegister',
  CORPORATE_FOUNDS_CONFIRMATION = 'corporateFundsConfirmation',
  SIGNED_RESOLUTION = 'signedResolution',
  ALL_DOCUMENTS = 'otherDocuments',

  // Payment details
  PAYMENT_INTERNATIONAL = 'international',
  // virtual fields
  CACHE_CORP = 'cache',

  FIAT_INFO_CORP = 'fiatInfo',
  CRYPTO_INFO_CORP = 'cryptoInfo',

  CRYPTO_CURRENCY_CORP = 'cryptoCurrency',
  CRYPTO_WALLET_ADDRESS_CORP = 'cryptoWalletAddress',

  FIAT_CURRENCY_CORP = 'currency',
  PAYMENT_METHOD_CORP = 'paymentMethod',
  BANK_COUNTRY_CORP = 'bankCountry',
  BANK_NAME_CORP = 'bankName',
  SORT_CODE_CORP = 'sortCode',
  BANK_ADDRESS_CORP = 'bankAddress',
  ABA_ROUTING_CORP = 'ABARouting',
  BANK_ACCOUNT_NUMBER_CORP = 'bankAccountNumber',
  BANK_CORRESPONDENT_CORP = 'bankCorrespondent',
  IBAN_CORP = 'IBAN',
  SWIFT_BIC_CODE_CORP = 'swiftBicCode',

  // Personal info
  USER_ROLE = 'userRole',
  PLACE_OF_BIRTH = 'placeOfBirth',
  CITIZENSHIP = 'citizenship',
  INTEREST_SHARES = 'interestShares',
  POSITION_OTHER_DETAILED = 'otherDetailed',

  PROOF_OF_POSITION = 'proofOfPosition',
  PROOF_OF_INTEREST_SHARES = 'proofOfInterestShares',

  // Signature
  COMPANY_SIGNATURES_CORP = 'esignature',
  COMPANY_SIGNER_EMAILS_CORP = 'signerEmails',
  COMPANY_REPRESENTATIVES = 'representatives',
}

export enum MerchantParam {
  BACK_URL = 'backUrl',
  EMAIL = 'email',
  ISS = 'iss',
  LOCALE = 'locale',
  UID = 'uid',
  WORKING_MODE = 'workingMode',
}

export enum WorkingMode {
  ALL_STAGES = 'allStages',
  SINGLE_STAGE = 'singleStage',
}
