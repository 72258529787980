import React from 'react';
import { render } from 'react-dom';

import { ConfigureStore } from 'store';
import * as Bootstrap from 'system/Bootstrap/actions';
import { validateConfig, mergeConfig } from './utils/bootstrap';

import { Root } from './Root';
import { makeErrorMessage } from './utils/error';
import { IGeneralConfig } from './merchants/types/IConfig';
import { IStageRouteProps } from './system/Routing/interfaces';
import { TrackEventsEmitter } from './store/middleware/TrackEventsEmitter';
import { ITrackedEventCB } from './store/middleware/evenTypes';
import { isDevEnv, isLocalEnv, isProdEnv, isUatEnv } from './utils/env';
import { initLogger } from './utils/error/logger';

export const App = async (
  externalConfig: IGeneralConfig,
  routes: IStageRouteProps,
  container: any = document.getElementById('root-websdk'),
  notificationHandler?: ITrackedEventCB
) => {
  const containerId = container.getAttribute('id');
  container.setAttribute('class', 'root-websdk');
  const config = { ...externalConfig, containerId };

  if (!isProdEnv() || !isLocalEnv()) {
    initLogger();
  }

  if(isDevEnv() || isLocalEnv() || isUatEnv()) {
    console.log('External config', config);
  }

  const result = await validateConfig(config);

  await ConfigureStore.createStore(config.baseUrl);

  const store = ConfigureStore.getStore();

  if (notificationHandler) {
    TrackEventsEmitter.getInstance(notificationHandler);
  }

  if (result.errors) {
    console.log('Websdk config error---', result.errors);
    await store.dispatch(Bootstrap.failed([makeErrorMessage(result.errors)]));
  } else {
    const mergedConfig = mergeConfig(config);
    if (isDevEnv() || isLocalEnv() || isUatEnv()) {
      console.log('Merged config', mergedConfig);
    }
    await store.dispatch(Bootstrap.start(mergedConfig));

    render(
      <Root
        history={ConfigureStore.getHistory()}
        store={store}
        config={mergedConfig}
        routes={routes}
      />,
      container
    );
  }
};

