import { array, boolean, object, string } from 'yup';
import { Stage } from 'system/Stages/types';
import { themeSchema } from './theme';
import { objectShape } from './helpers';

export const schema = objectShape(
  {
    requestToken: string(),
    product: string(),
    i18n: objectShape(
      {
        locale: string(),
        translations: object().shape({ key: string() }),
      },
      'i18n'
    ),
    theme: objectShape(
      {
        name: string(),
        config: themeSchema,
      },
      'theme'
    ),
    backUrl: string(),
    baseUrl: string(),
    containerId: string(),
    workingMode: string(),
    features: objectShape(
      {
        breadcrumbs: boolean(),
        breadcrumbsInHeader: boolean(),
        header: boolean(),
        phoneFieldOnResidence: boolean(),
        retryDossier: boolean(),
        selfieWithIdentity: boolean(),
        selfieWithNote: boolean(),
        fingerprint: boolean(),
        exitBtn: boolean(),
        googleTagManagerId: string().default('')
      },
      'features'
    ),
    images: object().shape({}),
    stages: array()
      .nullable()
      .test('stages', 'stages is not valid', (value: any[] | null | undefined) => {
        if (!value) return true;
        return value.every((stage) =>
          [
            Stage.IDENTITY,
            Stage.CORPORATE,
            Stage.ENHANCED,
            Stage.ADDRESS,
          ].includes(stage)
        );
      }),
      appConfig: objectShape({
          api: string(),
          cdn: string(),
          env: string(),
          zendeskId: string(),
      }, 'config.appConfig')
  },
  'config'
);
