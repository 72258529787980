import React from 'react';
import { IconProps } from 'components/UI/Icon/types';

export const Website: React.FunctionComponent<IconProps> = ({
  fill,
  height,
  width,
}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg" >
    <g fill="none">
      <circle cx="14.5" cy="14.5" r="14.5" fill={fill} />
      <path fill="#FFF" d="M14.5 7C10.36 7 7 10.36 7 14.5c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zm-.75 13.448A5.991 5.991 0 0 1 8.5 14.5c0-.465.06-.908.158-1.342l3.592 3.592v.75c0 .825.675 1.5 1.5 1.5v1.448zm5.175-1.905A1.488 1.488 0 0 0 17.5 17.5h-.75v-2.25a.752.752 0 0 0-.75-.75h-4.5V13H13c.412 0 .75-.338.75-.75v-1.5h1.5c.825 0 1.5-.675 1.5-1.5v-.307A6.003 6.003 0 0 1 20.5 14.5c0 1.56-.6 2.977-1.575 4.043z" />
    </g>
  </svg>
);

Website.displayName = 'ShareIcon';

Website.defaultProps = { fill: '#263850', height: 32, width: 32 };
