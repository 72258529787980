import React, { ComponentType } from 'react';
import { components as Reselect, OptionProps } from 'react-select';
import { Flag } from 'components/common/Flag';

import { withLocalization, WithLocalizationProps } from 'system/Localization';
import { withCustomOption } from './HOC/withCustomOption';
import { withCountryFilter } from './HOC/withCountryFilter';
import { Dropdown } from './Dropdown';
import { GeoHighlightTip } from './GeoHighlightTip';

const OptionFlag: ComponentType<OptionProps<any>> = (props) => {
  const {
    value: countryCode,
    label,
    data: {
      data: { geoLocation, cdn },
    },
    translate,
  } = props as any;

  return (
    <Reselect.Option {...props}>
      <Flag countryCode={countryCode} cdn={cdn} />
      {label}{' '}
      {geoLocation && (
        <GeoHighlightTip>
          ({translate('suggest_country_by_ip')}){' '}
        </GeoHighlightTip>
      )}
    </Reselect.Option>
  );
};

OptionFlag.displayName = 'OptionFlag';

export const DropdownWithFlag = withCountryFilter(
  withCustomOption(withLocalization<WithLocalizationProps<any>>(OptionFlag))(
    Dropdown
  )
);
