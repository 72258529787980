import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectLoading } from 'system/Bootstrap/selectors';

import { Loader } from 'components/common';

interface Internal {
  loading?: boolean;
}

export const withLoader = <BaseProps extends object>(
  Component: ComponentType<BaseProps>
) => {
  const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
  });

  const WithLoader = ({ loading, ...props }: BaseProps & Internal) => {
    return loading ? <Loader /> : <Component {...(props as BaseProps)} />;
  };

  return connect(
    mapStateToProps,
    null
  )(WithLoader);
};
