import React, { FC, ChangeEvent } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { Input } from 'components/UI/Input/Input';
import { clearFromSymbolsValue, getMask } from 'components/UI/Input/Input/utils';

const MASKS: { [key: string]: (string | RegExp)[] } = {
  SSN: [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  employerIdentificationNumber: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
};

export interface InputWithMask {
  maskPattern?: string;
  mask?: string;
  maskRegExp: string[];
  guide?: boolean;
  onChange: (value: string) => void;
  error?: boolean;
}

export const InputWithMask: FC<InputWithMask & MaskedInputProps> = (props) => {
  const {
    mask: maskType = '',
    onChange,
    maskPattern = '',
    maxLength = 15,
    maskRegExp = [],
    guide = true,
  } = props;

  const mask = MASKS[maskType] || maskRegExp;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    let { value } = target;

    if (maskType !== 'phone') {
      value = clearFromSymbolsValue(value);
    }
    onChange(value);
  };

  const maskGenerator =
    maskType === 'phone' ? getMask(maskPattern, maxLength) : mask;

  return (
    <MaskedInput
      {...props}
      guide={guide}
      onChange={handleChange}
      mask={maskGenerator}
      render={(ref: (inputElement: HTMLElement) => void, inner: any) => {
        return <Input ref={ref} {...inner} />;
      }}
    />
  );
};
