import queryString from 'query-string';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';

import { Routes } from 'system/Routing/Routes';
import { Step, SubStep } from 'system/Routing/types';

export const parse = (url: string, options = { parseBooleans: true }) => {
  return queryString.parse(url, options);
};

export const stringify = (obj: {}) => queryString.stringify(obj);

export const getUploadUrl = ({ stageId, subType, issuingCountry }: any) => {
  const params = stringify({
    source: subType,
    country: issuingCountry,
  });

  return `/${stageId}/upload?${params}`;
};

type getUploadWithQueryParams = {
  stepId: Step | SubStep;
  source: string;
  country?: string;
  blockId?: string;
};

export const getUploadUrlWithQuery = ({
  stepId,
  source,
  country,
}: getUploadWithQueryParams) => {
  return Routes.url({
    stepId,
    query: omitBy(
      {
        source,
        country,
      },
      isEmpty
    ),
  });
};

export const getUploadCorporateUrlWithQuery = ({
  blockId,
  stepId,
  source,
  country,
}: getUploadWithQueryParams) => {
  return Routes.url({
    blockId,
    stepId,
    query: omitBy(
      {
        source,
        country,
      },
      isEmpty
    ),
  });
};

export const getQRCodeUploadUrlWithQuery = ({
  stageId,
  blockId,
  nextStepId,
  uploadId,
  index,
  amount,
  iss,
  uid,
  selfieWithIdentity,
  selfieWithNote,
  experiment,
  baseUrl = '',
}: any) => {
  const restQuery = stringify({
    uploadId,
    index: index + 1,
    amount,
    iss,
    uid,
    selfieWithIdentity,
    selfieWithNote,
    experiment,
  });
  const {
    location: { origin: domain },
  } = window;

  const path = Routes.url({
    blockId,
    stageId,
    stepId: nextStepId.slice(1),
  });

  return `${domain}${baseUrl}${path}&${restQuery}`;
};

export const getCorporateQRCodeUploadUrlWithQuery = ({
  stageId,
  nextStepId,
  uploadId,
  index,
  amount,
  iss,
  uid,
  selfieWithIdentity,
  selfieWithNote,
  experiment,
  baseUrl = '',
}: any) => {
  const restQuery = stringify({
    uploadId,
    index: index + 1,
    amount,
    iss,
    uid,
    selfieWithIdentity,
    selfieWithNote,
    experiment,
  });
  const {
    location: { origin: domain },
  } = window;

  const path = Routes.url({
    stageId,
    stepId: nextStepId.slice(1),
  });

  return `${domain}${baseUrl}${path}&${restQuery}`;
};

export const urlProtocolRegexp = /^([hH][tT][tT][pP]|[hH][tT][tT][pP][sS])(:\/\/){1,3}/;

export const matchUrlProtocol = (url: string) => url.match(urlProtocolRegexp);

export const urlNormalize = (url: string) => {
  url.trim();

  const matchedProtocol = matchUrlProtocol(url);

  if (!matchedProtocol) {
    // Remove a protocol hack
    if (['https:/', 'http:/'].includes(url)) {
      return '';
    }

    return `https://${url.startsWith('http') ? '' : url}`;
  }

  const { 0: protocol, input }: any = matchedProtocol;

  return `${protocol.toLowerCase()}${input.slice(
    protocol.length,
    input.length
  )}`;
};

export const urlTwitterNormalize = (value: string) => {
  return `https://twitter.com/${value}`;
};

export const url = { parse, stringify };
