import { IUploadParams } from 'system/Dossier/Upload/sagas';

export enum Type {
  ADDRESS_STEP_INITIAL_SET = '@Address/ADDRESS_STEP_INITIAL_SET',
  ADDRESS_STEP_SET = '@Address/ADDRESS_STEP_SET',
  ADDRESS_BLOCK_RESET = '@Address/ADDRESS_BLOCK_RESET',
  ADDRESS_BLOCK_FAILED = '@Address/ADDRESS_BLOCK_FAILED',
  ADDRESS_UPLOAD_DOC_REQUEST = '@Address/ADDRESS_UPLOAD_DOC_REQUEST',
  ADDRESS_UPLOAD_DOC_SUCCESS = '@Address/ADDRESS_UPLOAD_DOC_SUCCESS',
  ADDRESS_UPLOAD_DOC_FAILED = '@Address/ADDRESS_UPLOAD_DOC_FAILED',
  ADDRESS_DOC_SET = '@Address/ADDRESS_DOC_SET',

  ADDRESS_META_SET = '@Address/ADDRESS_META_SET',

  ADDRESS_SUBMIT_REQUEST = '@Address/ADDRESS_SUBMIT_REQUEST',
  ADDRESS_SUBMIT_SUCCESS = '@Address/ADDRESS_SUBMIT_SUCCESS',
  ADDRESS_SUBMIT_FAILED = '@Address/ADDRESS_SUBMIT_FAILED',
}

export interface AddressStepInitialSet {
  type: Type.ADDRESS_STEP_INITIAL_SET;
  payload: {
    stepId: string;
    fields: object;
  };
}

export interface AddressStepSet {
  type: Type.ADDRESS_STEP_SET;
  payload: {
    stepId: string;
    fields: object;
  };
}

export interface AddressBlockFailed {
  type: Type.ADDRESS_BLOCK_FAILED;
  payload: Error;
}

export interface AddressBlockReset {
  type: Type.ADDRESS_BLOCK_RESET;
  payload: null;
}

export interface IRequestUploadAddressDocument {
  type: Type.ADDRESS_UPLOAD_DOC_REQUEST;
  payload: { files: File[] };
}

export interface IUploadAddressDocumentSuccess {
  type: Type.ADDRESS_UPLOAD_DOC_SUCCESS;
  payload: null;
}

export interface IUploadAddressDocumentFailed {
  type: Type.ADDRESS_UPLOAD_DOC_FAILED;
  payload: any;
}

export interface ISetAddressDocuments {
  type: Type.ADDRESS_DOC_SET;
  payload: {
    source?: string;
    images: IUploadParams[];
  };
}

export interface IAddressSetMeta {
  type: Type.ADDRESS_META_SET;
  payload: {
    stepId: string;
    fields: object;
  };
}

export interface IAddressSubmitRequest {
  type: Type.ADDRESS_SUBMIT_REQUEST;
  payload: {};
}

export interface IAddressSubmitSuccess {
  type: Type.ADDRESS_SUBMIT_SUCCESS;
  payload: {};
}

export interface IAddressSubmitFailed {
  type: Type.ADDRESS_SUBMIT_FAILED;
  payload: Error[];
}

export type ActionType =
  | AddressStepInitialSet
  | AddressStepSet
  | AddressBlockFailed
  | AddressBlockReset
  | IRequestUploadAddressDocument
  | IUploadAddressDocumentSuccess
  | IUploadAddressDocumentFailed
  | ISetAddressDocuments
  | IAddressSubmitRequest
  | IAddressSubmitSuccess
  | IAddressSubmitFailed
  | IAddressSetMeta;
