import React from 'react';
import { Map } from 'immutable';

import { RejectedDossier, TryAgainStub } from 'containers/Stubs';
import { ErrorMessage } from '../Stubs';

interface ErrorViewsProps {
  error: Map<string, any>;
  code?: string;
}

export const ErrorView = ({ error }: ErrorViewsProps) => {
  const code = error.get('code', '');
  // TODO check errors on type prop?
  const type = error.get('type', '');
  const retryDossier = error.getIn(
    ['data', 'merchantSettings', 'retryDossier'],
    false
  );
  const softRefuseReason = error.getIn(['data', 'softRefuseReason'], null);
  switch (code) {
    case 2:
    case 6:
    case 1013:
      return retryDossier ? (
        <TryAgainStub softRefuseReason={softRefuseReason} />
      ) : (
        <RejectedDossier
          dateOfNextVerification={error.getIn(
            ['data', 'dateOfNextVerification'],
            ''
          )}
          softRefuseReason={softRefuseReason}
        />
      );

    default:
      return <ErrorMessage type={type} message={error.get('message', '')} />;
  }
};
