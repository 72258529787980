import React from 'react';
import { CommonStub } from 'containers/Stubs/CommonStub';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import { ILocalizationProps, withLocalization } from 'system/Localization';
import { Stub } from 'components/UI/Icon';
import { BUTTON_TYPE, ICommonStubProps } from '../CommonStub/types';

interface IQrConnectedStub extends ILocalizationProps {
  history: History;
}

export const QrConnectedStub = withRouter(withLocalization(({ translate, history }: IQrConnectedStub) => {
  const contentProps: ICommonStubProps = {
    stageTitle: translate('connected_mobile_header'),
    buttons: [
      {
        clickHandler: () => history.goBack(),
        text: translate('back'),
        mods: { type: BUTTON_TYPE.regular },
      },
    ],
    body: {
      Icon: Stub.QrConnected,
      title: translate('mobile_device_connected'),
      message: translate('connected_mobile_explanation'),
    },
  };

  return <CommonStub {...contentProps} />;
}));
