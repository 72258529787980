/* eslint-disable */
import { escape } from './helpers';

export const latinRegular = `a-zA-Z`;
export const latinSupplement = '\\u00A0-\\u00FF';
export const latinExtendedA = '\\u0100-\\u017F';
export const latinExtendedB = '\\u0180-\\u024F';
export const latinExtendedAdditional = '\\u1E00-\\u1EFF';
export const latin = `${latinRegular}${latinSupplement}${latinExtendedA}${latinExtendedB}${latinExtendedAdditional}`;
export const cyrillic = `а-яА-ЯЁё`;
export const letters = `${latin}${cyrillic}`;
export const digits = escape(`\d`);
export const space = escape(`\s`);
export const quotes = "`‘’'";
export const slashes = escape(`/\\`);
export const slash = escape(`/`);
export const verticalSlash = escape(`|`);
export const punctuation = '.,\\-';
export const dot = escape('.');
export const dash = escape('\\-');
export const star = escape('*');
export const brackets = escape('()');
export const plus = '+';
