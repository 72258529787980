import { call, put, select, takeLatest } from 'redux-saga/effects';

import { storeUserProfile } from 'system/Profile/sagas';
import { saveStage } from 'system/Stages/sagas';
import { Stage } from 'system/Stages/types';
import { navigateAfterSubmit, navigateToErrorLayout, navigateToIndex } from 'system/Routing/sagas';
import { makeSelectVerificationDateByStageStart } from 'system/Analytics/selectors';

import { makeErrorMessage } from 'utils/error';
import { jsonRPC } from 'utils/request';

import { makeSelectApiUrl } from 'system/Settings/selectors';
import { IQuestionnaireStageAnswerRequest, Type } from './actionTypes';
import { successStageAnswer, failedStageAnswer, successSubmitQuestionnaire } from './actions';

import { makeSelectData } from './selectors';

function* watchForSubmit() {
  try {
    const stageData = yield select(makeSelectData);
    const verificationDateStart = yield select(makeSelectVerificationDateByStageStart(Stage.CFD));

    const response = yield call(saveStage, {
      stageId: Stage.CFD,
      stageData,
      metaData: {
        verificationDateStart,
        verificationDateEnd: Date.now()
      }
    });

    const { userDossier, error } = response;

    if (error) {
      yield call(navigateToErrorLayout);
    }

    if (userDossier) {
      const { stages } = userDossier;
      yield call(storeUserProfile, userDossier);
      yield call(navigateAfterSubmit, stages, { refetchUserProfile: false });
      yield put(successSubmitQuestionnaire())
    }
  } catch (err) {
    yield call(navigateToErrorLayout);
  }
}

function* submitStageAnswer(action: IQuestionnaireStageAnswerRequest) {
  try {
    const { stageId, answer } = action.payload;
    const api = yield select(makeSelectApiUrl());

    const response = yield call(jsonRPC, {
      namespace: 'va',
      method: 'submitStageAnswer',
      params: { stageId, answer },
      api
    });

    const { userDossier, error } = response;

    if (userDossier) {
      yield call(storeUserProfile, userDossier);
      yield put(successStageAnswer());
      yield call(navigateToIndex);
    } else {
      yield put(failedStageAnswer(makeErrorMessage(error)));
      yield call(navigateToErrorLayout);
    }

  } catch (e) {
    yield put(failedStageAnswer(makeErrorMessage(e)));
    yield call(navigateToErrorLayout);
  }
}

export function* rootSaga() {
  yield takeLatest(Type.QUESTIONNAIRE_SUBMIT_REQUEST, watchForSubmit);
  yield takeLatest(Type.QUESTIONNAIRE_STAGE_ANSWER_REQUEST, submitStageAnswer);
}
