import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Refresh: React.FunctionComponent<IconProps> = ({
  width,
  height,
}) => {
  const {
    icons: {
      markup: {
        refresh: { fill },
      }
    },
  } = useContext(ThemeContext);

  return (
    <svg
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <rect fill="#FFF" height="24" rx="12" width="24" />
        <g fill={fill} fillRule="nonzero">
          <path d="M5.922 12.738A6.33 6.33 0 0 1 5.875 12c0-3.356 2.769-6.125 6.125-6.125 1.566 0 3.031.631 4.11 1.616l-.541.55c-.122.121-.169.3-.122.468a.49.49 0 0 0 .356.32c.094.018 3.194 1.017 2.997.977.251.084.622-.184.553-.553l-.666-3.34a.47.47 0 0 0-.328-.357.468.468 0 0 0-.459.122l-.46.45A7.995 7.995 0 0 0 12 4c-4.397 0-8 3.603-8 8v.019c0 .264.012.468.026.624a.469.469 0 0 0 .375.418l.963.192a.47.47 0 0 0 .558-.515zM19.602 10.938l-.966-.196a.47.47 0 0 0-.56.516c.034.273.049.54.049.742 0 3.356-2.769 6.125-6.125 6.125-1.566 0-3.031-.631-4.11-1.625l.541-.54c.122-.122.169-.3.122-.47a.49.49 0 0 0-.356-.318c-.094-.02-3.194-1.018-2.997-.978a.46.46 0 0 0-.422.131.467.467 0 0 0-.131.422l.666 3.31a.47.47 0 0 0 .328.355.45.45 0 0 0 .459-.121l.45-.45A8.027 8.027 0 0 0 12 20c4.397 0 8-3.603 8-8v-.028c0-.208-.008-.412-.025-.615a.469.469 0 0 0-.373-.419z" />
        </g>
      </g>
    </svg>
  );
}

Refresh.displayName = 'StopIcon';

Refresh.defaultProps = { height: '24', width: '24' };
