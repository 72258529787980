import { createSelector } from 'reselect';
import { Map, List, fromJS } from 'immutable';
import { matchPath } from 'react-router-dom';
import get from 'lodash/get';

import { DocumentAddressSubtypeId } from 'utils/types/IDocument';
import { joinByChar } from 'utils/helpers';
import { pickImmutable } from 'utils/collection';
import { url } from 'utils/url';
import { Field } from 'utils/types/shared/enums';

import { routerSelector } from 'system/Bootstrap/selectors';
import {
  makeSelectExternalProfiles,
  makeSelectStageCorrectionMistakes,
} from 'system/Profile/selectors';
import { makeSelectDocuments } from 'system/Dicts/selectors';

import {
  makeSelectCountry as makeSelectCountryIdentity,
  makeSelectSubRegion as makeSelectSubRegionIdentity,
  makeSelectRegion as makeSelectRegionIdentity,
  makeSelectAptSuite as makeSelectAptSuiteIdentity,
  makeSelectCity as makeSelectCityIdentity,
  makeSelectStreet as makeSelectStreetIdentity,
  makeSelectZipCode as makeSelectZipCodeIdentity,
  selectorSubStoreParam,
  SubStore,
} from 'stages/Identity/selectors';
import { updateSourceMeta } from 'stages/Identity/sagas/utils';


const selector = (state: any) => state.getIn(['dossier', 'address'], Map());

export const selectedStepSelector = (
  subStore: selectorSubStoreParam =  SubStore.DATA
) =>
  createSelector(routerSelector, selector, (router, state) => {
    const matched = matchPath(router.getIn(['location', 'pathname']), {
      path: '/:stageId/:stepId',
    });
    const stepId = get(matched, 'params.stepId', '');
    return state.getIn([subStore, joinByChar(stepId)], Map());
  });

const documentSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(selector, (state) =>
    state.getIn([subStore, 'addressDocuments'], Map())
  );

export const addressDocumentSelector = (
  subStore: selectorSubStoreParam = SubStore.DATA
) =>
  createSelector(selector, (state) =>
    state.getIn([subStore, joinByChar('address-residence')], Map())
  );

export const makeSelectLoading = () =>
  createSelector(selector, (state) => state.get('loading', false));

export const makeSelectErrors = () =>
  createSelector(selector, (state) => state.get('errors', List()));

export const makeSelectDocSubType = (subStore?: selectorSubStoreParam) => {
  return createSelector(
    routerSelector,
    documentSelector(subStore),
    (router, state) => {
      const { source = '' } = url.parse(router.getIn(['location', 'search']));
      return state.get(Field.SUB_TYPE, source);
    }
  );
};

export const makeSelectDocSubTypeMeta = () => {
  return createSelector(documentSelector(SubStore.META), (state) =>
    state.get(Field.SUB_TYPE, Map())
  );
};

export const makeSelectDocumentDetails = () => {
  return createSelector(documentSelector(), (state) =>
    state.get(Field.SUB_TYPE_DETAILED, '')
  );
};

export const makeSelectDocumentDetailsMeta = () => {
  return createSelector(documentSelector(SubStore.META), (state) =>
    state.get(Field.SUB_TYPE_DETAILED, Map())
  );
};

export const makeSelectDocumentImages = () => {
  return createSelector(documentSelector(), (state) =>
    state.get(Field.IMAGES, List())
  );
};

export const makeSelectCountry = () => {
  return createSelector(
    addressDocumentSelector(),
    makeSelectCountryIdentity(),
    makeSelectStageCorrectionMistakes('address'),
    (state, country, mistakes) => {
      return state.get(Field.COUNTRY, mistakes.includes(Field.COUNTRY) ? '' : country);
    }
  );
};

export const makeSelectCountryMeta = () => createSelector(
  addressDocumentSelector(SubStore.META),
  (state) => state.get(Field.COUNTRY, Map())
);

export const makeSelectRegion = () => {
  return createSelector(
    addressDocumentSelector(),
    makeSelectRegionIdentity(),
    (state, region) =>
    state.get(Field.REGION, region)
  );
};

export const makeSelectRegionMeta = () => {
  return createSelector(addressDocumentSelector(SubStore.META), (state) =>
    state.get(Field.REGION, Map())
  );
};

export const makeSelectSubRegion = (ifSubmit?: boolean) => {
  return createSelector(
    addressDocumentSelector(),
    makeSelectSubRegionIdentity(),
    (state, subRegion) =>
    state.get(Field.SUB_REGION, ifSubmit ? '' : subRegion)
  );
};

export const makeSelectSubRegionMeta = () => {
  return createSelector(addressDocumentSelector(SubStore.META), (state) =>
    state.get(Field.SUB_REGION, Map())
  );
};

export const makeSelectZipCode = () => {
  return createSelector([addressDocumentSelector(), makeSelectZipCodeIdentity()], (state, identityData) =>
    state.get(Field.ZIP_CODE, identityData)
  );
};

export const makeSelectZipCodeMeta = () => {
  return createSelector(addressDocumentSelector(SubStore.META), (state) =>
    state.get(Field.ZIP_CODE, Map())
  );
};

export const makeSelectStreet = () => {
  return createSelector([addressDocumentSelector(), makeSelectStreetIdentity()], (state, identityData) => {
    return state.get(Field.STREET, identityData);
  });
};

export const makeSelectStreetMeta = () => {
  return createSelector(addressDocumentSelector(SubStore.META), (state) => {
    return state.get(Field.STREET, Map());
  });
};

export const makeSelectCity = () => {
  return createSelector([addressDocumentSelector(), makeSelectCityIdentity()], (state, identityData) =>
    state.get(Field.CITY, identityData)
  );
};

export const makeSelectCityMeta = () => {
  return createSelector(addressDocumentSelector(SubStore.META), (state) =>
    state.get(Field.CITY, Map())
  );
};

export const makeSelectAptSuite = () => {
  return createSelector([addressDocumentSelector(), makeSelectAptSuiteIdentity()], (state, identityData) =>
    state.get(Field.APT_SUITE, identityData)
  );
};

export const makeSelectAptSuiteMeta = () => {
  return createSelector(addressDocumentSelector(SubStore.META), (state) =>
    state.get(Field.APT_SUITE, Map())
  );
};

export const makeSelectAllSocialProfiles = (
  subStore: selectorSubStoreParam = 'data'
) =>
  createSelector(selector, (state: Map<string, any>) =>
    state.getIn([subStore, 'socialProfiles'], List())
  );

export const makeSelectFilesTokens = createSelector(
  makeSelectDocumentImages(),
  (state: List<Map<string, any>>) =>
    state.map((file: Map<string, any>) => file.get('fileToken', ''))
);

export const makeSelectUploadFilesTokens = () =>
  createSelector(selector, (state: Map<string, any>) => {
    return state
      .getIn(['data', 'upload'], List())
      .map((file: Map<string, any>) => file.get('fileToken', ''));
  });

export const makeSelectWithMetaSource = (select: any) => {
  return createSelector(select(), (state: Map<any, any> = Map()) => {
    return pickImmutable(state, ['source']).get('source', '');
  });
};

export const makeSelectStepMeta = (fields: any) =>
  createSelector(
    selectedStepSelector(SubStore.META),
    makeSelectExternalProfiles(),
    makeSelectStageCorrectionMistakes('address'),
    (stepMeta, externalProfiles, correctionMistakes) => {
      return fromJS(fields).reduce(
        updateSourceMeta({ externalProfiles, correctionMistakes }),
        stepMeta
      );
    }
  );

export const makeSelectDocumentSubtypeName = () =>
  createSelector(
    makeSelectDocuments('DocumentAddressSubtypeDict'),
    (subtypeDict) => (id: DocumentAddressSubtypeId) => {
      return subtypeDict
        .find((document: any) => document.get('id') === id, null, List())
        .get('name', '');
    }
  );
