import React from 'react';
import { withRouter, match } from 'react-router-dom';
import { History, Location } from 'history';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ILocalizationProps, withLocalization } from 'system/Localization';
import { makeSelectEmail } from 'system/Profile/selectors';
import { navigateIndex } from 'system/Routing/actions';

import {
  BUTTON_TYPE,
  ICommonStubProps,
} from 'containers/Stubs/CommonStub/types';
import { CommonStub } from 'containers/Stubs/CommonStub';
import { StageId } from 'system/Stages/types';

import { Stub } from 'components/UI/Icon';

interface ISubmittedStub extends ILocalizationProps {
  location: Location ;
  history: History;
  match: match<{ stageId: StageId }>;
  email: string;
  handleLocation: (exit: boolean) => () => void;
  exitOnSubmit: boolean;
}

const mapStateToProps = createStructuredSelector({
  email: makeSelectEmail(),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleLocation: (exit: boolean) => () => {
    dispatch(navigateIndex({ exit, refetchUserProfile: false }));
  },
});

export const SubmittedStub = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withLocalization(
      ({
        translate,
        match: {
          params: { stageId },
        },
        email,
        handleLocation,
        exitOnSubmit,
      }: ISubmittedStub) => {
        const contentProps: ICommonStubProps = {
          stageTitle: translate(`stage_title_${stageId}`),
          stageSubtitle: translate(`check_email_${stageId}_message_1`),
          buttons: [
            {
              clickHandler: handleLocation(exitOnSubmit),
              text: translate('button_finish'),
              mods: { type: BUTTON_TYPE.primary },
            },
          ],
          body: {
            Icon: Stub.CheckEmail,
            title: translate('screen_title_check_email'),
            message: translate(`check_email_${stageId}_message_2`, '', [email]),
          },
        };

        return <CommonStub {...contentProps} />;
      }
    )
  )
);
