import { createSelector } from 'reselect';
import { Map, List, fromJS } from 'immutable';
import camelCase from 'lodash/camelCase';

import { Block } from 'system/Stages/types';
import { Field } from 'utils/types/shared/enums';
import { SubStep } from 'system/Routing/types';

import { selectCorporateBlock } from '../selectors';

const extractFileToken = (images: List<Map<string, any>>): List<string> => {
  return images.map((image: Map<string, any>) => image.get('fileToken'));
}

const selectCompanyIndustryBlock = () =>
  createSelector(
    selectCorporateBlock(Block.BUSINESS_ACTIVITY),
    (state: Map<string, any>) => {
      return state.get(SubStep.BUSINESS_ACTIVITY_INDUSTRY, Map());
    }
  );

const selectCompanyFiatBlock = () =>
  createSelector(
    selectCorporateBlock(Block.BUSINESS_ACTIVITY),
    (state: Map<string, any>) => {
      return state.get(SubStep.BUSINESS_ACTIVITY_FIAT, Map());
    }
  );

const selectCompanyCryptoBlock = () =>
  createSelector(
    selectCorporateBlock(Block.BUSINESS_ACTIVITY),
    (state: Map<string, any>) => {
      return state.get(SubStep.BUSINESS_ACTIVITY_CRYPTO, Map());
    }
  );

const selectCompanyFiatUpload = () =>
  createSelector(
    selectCorporateBlock(Block.BUSINESS_ACTIVITY),
    (state: Map<string, any>) => {
      return state.get(camelCase(SubStep.BUSINESS_ACTIVITY_FIAT_UPLOAD), Map());
    }
  );

const selectCompanyCryptoUpload = () =>
  createSelector(
    selectCorporateBlock(Block.BUSINESS_ACTIVITY),
    (state: Map<string, any>) => {
      return state.get(
        camelCase(SubStep.BUSINESS_ACTIVITY_CRYPTO_UPLOAD),
        Map()
      );
    }
  );

// industry
export const makeSelectCompanyAnnualProfit = () =>
  createSelector(selectCompanyIndustryBlock(), (state: Map<string, any>) => {
    return state.get(Field.ANNUAL_PROFIT_CORP, '');
  });

export const makeSelectCompanyBusinessModel = () =>
  createSelector(selectCompanyIndustryBlock(), (state: Map<string, any>) => {
    return state.get(Field.BUSINESS_MODEL_CORP);
  });

export const makeSelectCompanyBusinessModelFilesValues = () => {
  return createSelector(selectCompanyIndustryBlock(), (state) =>
    state.get(Field.BUSINESS_MODEL_CORP_FILES, List())
  );
};

export const makeSelectCompanyBusinessModelFiles = () => {
  return createSelector(makeSelectCompanyBusinessModelFilesValues(), (state) =>
    state.map((item: Map<string, any>) => item).toJS()
  );
};

export const makeSelectCompanyFiatDetailedSources = () =>
  createSelector(
    [selectCompanyFiatBlock(), selectCompanyFiatUpload()],
    (state: Map<string, any>, files: Map<string, any>) => {
      const sources = state.get(Field.FIAT_FUNDS_DETAILED_SOURCES_CORP, List());

      return sources.map((source: Map<string, any>) => {
        const images = files.get(
          source.get(Field.FIAT_FUNDS_SOURCE_CORP),
          List()
        );
        return source.set(Field.FIAT_FUNDS_IMAGES, images);
      });
    }
  );

// crypto
export const makeSelectCompanyPlanningDepositCrypto = () =>
  createSelector(selectCompanyCryptoBlock(), (state: Map<string, any>) => {
    return state.has(Field.PLANNING_DEPOSIT_CRYPTO_CORP)
      ? state.get(Field.PLANNING_DEPOSIT_CRYPTO_CORP)
      : false;
  });

export const makeSelectCompanyCryptoDetailedSources = () =>
  createSelector(
    [selectCompanyCryptoBlock(), selectCompanyCryptoUpload()],
    (state: Map<string, any>, files: Map<string, any>) => {
      const sources = state.get(
        Field.CRYPTO_FUNDS_DETAILED_SOURCES_CORP,
        List()
      );

      return sources.map((source: Map<string, any>) => {
        const images = files.get(
          source.get(Field.CRYPTO_FUNDS_SOURCE_CORP),
          List()
        );

        return source.set(Field.CRYPTO_FUNDS_IMAGES, images);
      });
    }
  );


// Submit
export const makeSelectCompanySourceOfFiatFunds = () =>
  createSelector(
    makeSelectCompanyFiatDetailedSources(),
    (sources: List<Map<string, any>>) => {
      const detailedSources = sources.map((src: Map<string, any>) => {
        return src.set('images', extractFileToken(src.get('images', List())));
      });

      return fromJS({ detailedSources });
    }
  );

export const makeSelectCompanySourceOfCryptoFunds = () =>
  createSelector(
    makeSelectCompanyCryptoDetailedSources(),
    (sources: List<Map<string, any>>) => {
      const detailedSources = sources.map((src: Map<string, any>) => {
        return src.set('images', extractFileToken(src.get('images', List())));
      });

      return fromJS({
        detailedSources,
      });
    }
  );

