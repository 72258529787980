import { useContext } from 'react';
import get from 'lodash/get';

import { ConfigContext } from './MerchantConfigProvider';

export const useMerchantConfig = (path: string[]) => {
  const config = useContext(ConfigContext);

  return path ? get(config, path) : config;
}
