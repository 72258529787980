import { createSelector } from 'reselect';
import { fromJS, List, Map, merge } from 'immutable';
import { matchPath } from 'react-router-dom';

import get from 'lodash/get';

import { routerSelector } from 'system/Bootstrap/selectors';

import { url } from 'utils/url';
import { externalSourceProfile } from 'utils/externalProfiles/types';
import { ExternalSource } from 'utils/externalProfiles/enums';
import { BlockId, ProductType, StageId, StageStatuses } from '../Stages/types';
import { extractPayloadFromJWT } from '../../utils/helpers';

export const profileSelector = (state: any) => state.get('profile');

export const makeSelectLoading = () =>
  createSelector(profileSelector, (state) => state.get('loading'));

export const makeSelectRequestToken = () =>
  createSelector(profileSelector, (state) => state.get('requestToken', ''));

export const makeSelectErrors = () =>
  createSelector(profileSelector, (state) => state.get('errors', List()));

export const makeSelectUID = () => createSelector(profileSelector, (state) => state.get('uid', ''));

export const makeSelectDossierStatus = () =>
  createSelector(profileSelector, (state) => state.get('dossierStatus', ''));

export const makeSelectEmail = () =>
  createSelector(profileSelector, (state) => state.get('email', ''));

export const makeSelectExternalProfile = (source: externalSourceProfile) =>
  createSelector(profileSelector, (state) => state.get(source, ''));

export const makeSelectRequestTokenPayload = () =>
  createSelector(makeSelectRequestToken(), (token) => extractPayloadFromJWT(token));

export const makeSelectVerificationType = () =>
  createSelector(makeSelectRequestTokenPayload(), (state) => get(state, 'verificationType', ''));

export const makeSelectExternalProfiles = () =>
  createSelector(makeSelectExternalProfile(ExternalSource.BACKEND), (recognitionProfile) => {
    return merge(fromJS({ recognitionProfile })).reduce((acc: any, profile: any) => {
      if (profile.has('type')) {
        return acc.push(profile);
      }
      return acc.concat(profile.toIndexedSeq());
    }, List());
  });

export const makeSelectISS = () =>
  createSelector(routerSelector, profileSelector, (router, state) => {
    const matched = matchPath(router.getIn(['location', 'pathname']), {
      path: '/:stageId/:stepId',
    });
    const { iss = '' } = url.parse(router.getIn(['location', 'search']));
    const stepId = get(matched, 'params.stepId', '');

    const defaultIss = stepId === 'upload' ? iss : '';

    return state.get('iss', defaultIss);
  });

export const makeSelectProduct = () =>
  createSelector(profileSelector, (state) => state.get('product', ''));

export const makeSelectIfBro = () =>
  createSelector(makeSelectProduct(), (product: string) => product === ProductType.BROKER);

export const makeSelectUserData = () =>
  createSelector(profileSelector, (state) => state.get('userData', Map()));

export const makeSelectGeneralInfoCountry = () =>
  createSelector(makeSelectUserData(), (state) =>
    state.getIn(['generalInformation', 'registeredAddress', 'country'])
  );

export const makeSelectMerchantFeatures = () =>
  createSelector(profileSelector, (state) => state.get('merchantFeatures', Map()));

export const makeSelectRegistrationData = () =>
  createSelector(profileSelector, (state) => state.get('registrationData', Map()));

export const makeSelectRegistrationCountry = () =>
  createSelector(makeSelectRegistrationData(), (state) => state?.country);

export const makeSelectStages = () =>
  createSelector(profileSelector, (state) => state.get('stages', List()));

export const makeSelectStageById = (id: StageId) =>
  createSelector(makeSelectStages(), (stages) =>
    stages.find((stage: Map<string, any>) => stage.get('id') === id, null, Map())
  );

export const makeSelectStageStatus = (id: StageId) =>
  createSelector(
    makeSelectStageById(id),
    (stage) =>
      stage.get('status', '') as Extract<
        StageStatuses,
        StageStatuses.ACCEPTED | StageStatuses.PENDING | StageStatuses.REFUSED | StageStatuses.DRAFT
      >
  );

export const makeSelectStageBlocks = (id: StageId) =>
  createSelector(makeSelectStageById(id), (stage) => {
    return stage.get('blocks', List());
  });

export const makeSelectStageBlockById = (stageId: StageId, blockId: BlockId) =>
  createSelector(makeSelectStageBlocks(stageId), (blocks) => {
    return blocks.find((block: Map<string, any>) => block.get('id') === blockId, null, Map());
  });

export const makeSelectStageCorrectionMistakes = (id: StageId) =>
  createSelector(makeSelectStageById(id), (stage) => {
    return stage.getIn(['smartCorrection', 'mistakes'], List()).map((mistake: any) => {
      const path = mistake.get('name', '').split('.');
      return path[path.length - 1];
    });
  });

export const makeSelectCorrectionMistakes = (id: StageId) =>
  createSelector(makeSelectStageById(id), (stage) => {
    return stage.getIn(['smartCorrection', 'mistakes'], List()).map((mistake: any) => {
      return mistake.get('name', '');
    });
  });

export const makeSelectTouchedStageIds = () =>
  createSelector(profileSelector, (state) => {
    return state
      .get('stages')
      .filter((stage: any) => !['locked', 'draft'].includes(stage.get('status')))
      .map((stage: any) => stage.get('id'));
  });

export const makeSelectFeedbackHistory = () =>
  createSelector(profileSelector, (state) => state.getIn(['feedback', 'received'], List()));

export const makeSelectStagesWithoutFeedback = () => {
  return createSelector(
    makeSelectTouchedStageIds(),
    makeSelectFeedbackHistory(),
    (touchedStages, feedbackHistory) => {
      return touchedStages.reduce((stages: any, stageId: any) => {
        if (feedbackHistory.includes(stageId)) {
          return stages;
        }
        return stages.push(stageId);
      }, List());
    }
  );
};

export const makeSelectResetStages = () =>
  createSelector(makeSelectStages(), (state) => {
    return state.filter((value: any) => value.has('resubmit'));
  });

export const makeSelectResetStageIds = () =>
  createSelector(makeSelectResetStages(), (state) => {
    return state.reduce((reduction: any, stage: any) => {
      return reduction.set(stage.get('id'), stage.get('resubmit'));
    }, Map());
  });

export const makeSelectStageCorrection = () =>
  createSelector(makeSelectStages(), (stages) => {
    return stages
      .filter((value: any) => value.has('correctionJson'))
      .reduce((reduction: any, stage: any) => {
        return reduction.set(stage.get('id'), stage.get('correctionJson'));
      }, Map());
  });

export const makeSelectTicketSystem = () =>
  createSelector(makeSelectMerchantFeatures(), (state) => {
    return state.get('ticketSystem', false);
  });

export const makeSelectListCountriesForDeactivateItems = (item: string) =>
  createSelector(makeSelectMerchantFeatures(), (state) => {
    return state.getIn(['listCountriesForDeactivateItems', item], List());
  });
