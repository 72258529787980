import { fromJS } from 'immutable';

import { MerchantParam, WorkingMode } from 'utils/types/shared/enums';
import { ActionType, ISettingsSet, ITabIndexGet, ITabIndexSet, Type } from './actionTypes';

const defaultState = fromJS({
  loading: false,
  errors: [],
  cdn: '',
  i18n: {
    locale: '',
    locales: {},
  },
  links: { index: '', fallback: '' },
  theme: {},
  abTesting: {},
  location: {},
  features: {},
  images: {},
  stages: [],
  appConfig: {},
  [MerchantParam.WORKING_MODE]: '',
});

export function reducer(
  state: any = defaultState,
  { type, payload }: ActionType
) {
  switch (type) {
    case Type.SETTINGS_SET: {
      const {
        logoUrl = '',
        backUrl = '',
        baseUrl = '',
        workingMode = WorkingMode.ALL_STAGES,
        images = {},
        theme = {},
        features = {},
        appConfig = state.get('appConfig', fromJS({})),
        stages = []
      } = payload as ISettingsSet['payload'];

      return state
        .set('loading', false)
        .setIn(['links', 'index'], logoUrl)
        .setIn(['links', 'fallback'], backUrl)
        .setIn(['links', 'baseUrl'], baseUrl)
        .set('images', fromJS(images))
        .set('theme', fromJS(theme))
        .set('features', fromJS(features))
        .set('appConfig', fromJS(appConfig))
        .set('stages', fromJS(stages))
        .set(MerchantParam.WORKING_MODE, workingMode);
    }
    case Type.LOCALE_SET: {
      return state.setIn(['i18n', 'locale'], payload);
    }
    case Type.LOCALES_SET: {
      return state.setIn(['i18n', 'locales'], fromJS(payload));
    }
    case Type.AB_TESTING_SET: {
      const abTesting = state.get('abTesting').merge(fromJS(payload));

      return state.set('abTesting', abTesting);
    }
    case Type.GEO_LOCATION_SET: {
      return state.set('location', fromJS(payload));
    }
    case Type.SETTINGS_FAILED: {
      const errors = state.get('errors').push(fromJS(payload));

      return state.set('loading', false).set('errors', fromJS(errors));
    }
    case Type.GET_TAB_INDEX: {
      const { name } = payload as ITabIndexGet['payload'];
      return state.getIn(['tabs', name], 0);
    }
    case Type.SET_TAB_INDEX: {
      const { name, value } = payload as ITabIndexSet['payload'];
      return state.setIn(['tabs', name], value);
    }
    default:
      return state;
  }
}
