import { SyntheticEvent, DragEvent } from 'react';
import { FileValidateOptions } from './types';

export const fileListToArray = (fileList: FileList | null | any[]): File[] => {
  return fileList ? Array.prototype.slice.call(fileList) : [];
};

export const stopEvent = (evt: SyntheticEvent | DragEvent) => {
  evt.preventDefault();
  evt.stopPropagation();
};

const fileTypeAllowed = (
  { type, name }: File,
  { accept = [] }: FileValidateOptions
) => {
  return accept.length
    ? accept.some(
        (acceptType: string) => type === acceptType || name?.endsWith(acceptType)
      )
    : true;
};

const fileIsImage = (file: File) => {
  return file && file.type.includes('image/');
};

export const fileIsPdf = (file: File) => {
  return file && file.type.includes('pdf');
};

const isValidImage = async (file: File) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      URL.revokeObjectURL(image.src);
      resolve(true);
      return true;
    };

    image.onerror = async () => {
      resolve(false);
      return false;
    };
  });
};

const isValidPdf = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    let buffer: string;
    reader.onload = (e: any) => {
      buffer = e.target.result;
      if (buffer.slice(0, 2048).includes('%PDF-')) {
        resolve(true);
      } else {
        resolve(false);
      }
    };

    reader.onerror = () => {
      resolve(false);
    };

    reader.readAsBinaryString(file);
  });
};

const fileMaxSizeAllowed = (
  { size }: File,
  { maxFileSize }: FileValidateOptions
) => {
  return size < maxFileSize;
};

const fileMinSizeAllowed = (
  { size }: File,
  { minFileSize }: FileValidateOptions
) => {
  return size >= minFileSize;
};

export const fileValidate = async (
  file: File & { uploaded?: boolean },
  options: FileValidateOptions
) => {
  if (file?.uploaded) {
    return ''
  }
  if (!fileTypeAllowed(file, options)) {
    return 'upload_error_wrong_format';
  }
  if (!fileMaxSizeAllowed(file, options)) {
    return 'upload_error_max_size';
  }
  if (!fileMinSizeAllowed(file, options)) {
    return 'upload_error_min_size';
  }
  const valid = await isValidImage(file);
  if (fileIsImage(file) && !valid) {
    return 'upload_error_broken_image';
  }

  if (fileIsPdf(file)) {
    const validPdf = await isValidPdf(file);
    return !validPdf ? 'upload_error_broken_image' : '';
  }

  return '';
};
