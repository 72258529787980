import * as Yup from 'yup';

import { serializeError } from 'utils/validation';

export const feedbackSchema = Yup.object().shape({
  score: Yup.number().required(),
  feedback: Yup.string().when('score',
    (scoreValue: number, field: any) => {
      const feedbackField = field
          .min(20, serializeError('error_field_min_length', 20))
          .max(512, serializeError('error_field_max_length', 512))
      return scoreValue < 5
        ? feedbackField.required('error_feedback_too_short')
        : feedbackField.notRequired();
    }
  ),
});
