import isNaN from 'lodash/isNaN';

export const getMaskPattern = (pattern = '') => {
  return pattern.split('').reduce((acc: any, item: string) => {
    const whitespaceChars = [' ', '\t', '\n'];
    const digit = /\d/;

    if (whitespaceChars.includes(item) || isNaN(+item)) {
      acc.push(item);
      return acc;
    }

    if (typeof +item === 'number' && !isNaN(+item)) {
      acc.push(digit);
      return acc;
    }

    return acc;
  }, []);
};

export const clearFromSymbolsValue = (value = '') => {
  return value.replace(/\D/g, '');
}

export const getMask = (maskPattern: string, maxLength: number) => {
  const maskValue = clearFromSymbolsValue(maskPattern);
  const maskRegex = getMaskPattern(maskPattern);

  const restSymbolsLength = maxLength - maskValue.length;

  const additional = restSymbolsLength > 0
    ? Array.from(Array(restSymbolsLength), (_) =>  /\d/)
    : [];

  return maskRegex.concat(additional);
};
