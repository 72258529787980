import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Ok: React.FunctionComponent<IconProps> = ({ width = 32, height = 32 }) => {
  const {
    icons: {
      markup: {
        ok: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g fill="none" fillRule="evenodd">
        <circle cx="16" cy="16" r="14.25" stroke={fill} strokeWidth="3.5" />
        <path fill={fill} fillRule="nonzero" d="M21.362 11.158c.596-.596 1.563-.596 2.16 0 .596.597.596 1.564 0 2.16l-7.273 7.274c-.07.07-.148.133-.23.188-.018.021-.038.042-.058.062-.596.596-1.563.596-2.16 0l-5.323-5.323c-.596-.597-.596-1.564 0-2.16.597-.597 1.564-.597 2.16 0L14.9 17.62l6.462-6.462z" />
      </g>
    </svg>

  );
};

Ok.displayName = 'OkIcon';
