import { Type as bootstrapTypes } from 'system/Bootstrap/actionTypes';
import { Type as routingTypes } from 'system/Routing/actionTypes';
import { Type as dossierTypes } from 'system/Dossier/actionTypes';
import { Type as identityTypes } from 'stages/Identity/actionTypes';
import { Type as addressTypes } from 'stages/Address/actionTypes';
import { Type as enhancedTypes } from 'stages/Enhanced/actionTypes';
import { Type as corporateTypes } from 'stages/Corporate/actionTypes';
import { Type as cfdTypes } from 'stages/Questionnaire/actionTypes';
import { Action } from 'redux';

export interface ITrackedEvent {
  category: string;
  action: string;
}

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export type ITrackedEventCB = (actionType: ITrackedEvent, actionPayload?: any) => void

export const events: { [key: string]: ITrackedEvent } = {
  [bootstrapTypes.APPLICATION_LOADING]: {
    category: 'application',
    action: bootstrapTypes.APPLICATION_LOADING,
  },
  [bootstrapTypes.APPLICATION_LOADED]: {
    category: 'application',
    action: bootstrapTypes.APPLICATION_LOADED,
  },
  [bootstrapTypes.APPLICATION_FAILED]: {
    category: 'application',
    action: bootstrapTypes.APPLICATION_FAILED,
  },
  [routingTypes.SET_STAGE_PROGRESS]: {
    category: 'routing',
    action: routingTypes.SET_STAGE_PROGRESS,
  },
  [dossierTypes.DOSSIER_STEP_SUBMIT]: {
    category: 'dossier',
    action: dossierTypes.DOSSIER_STEP_SUBMIT,
  },
  [identityTypes.IDENTITY_SUBMIT_SUCCESS]: {
    category: 'stages',
    action: identityTypes.IDENTITY_SUBMIT_SUCCESS,
  },
  [addressTypes.ADDRESS_SUBMIT_SUCCESS]: {
    category: 'stages',
    action: addressTypes.ADDRESS_SUBMIT_SUCCESS,
  },
  [enhancedTypes.ENHANCED_SUBMIT_SUCCESS]: {
    category: 'stages',
    action: enhancedTypes.ENHANCED_SUBMIT_SUCCESS,
  },
  [corporateTypes.CORPORATE_SUBMIT_SUCCESS]: {
    category: 'stages',
    action: corporateTypes.CORPORATE_SUBMIT_SUCCESS,
  },
  [cfdTypes.QUESTIONNAIRE_SUBMIT_SUCCESS]: {
    category: 'stages',
    action: cfdTypes.QUESTIONNAIRE_SUBMIT_SUCCESS,
  },
  [identityTypes.IDENTITY_SUBMIT_FAILED]: {
    category: 'stages',
    action: identityTypes.IDENTITY_SUBMIT_FAILED,
  },
  [addressTypes.ADDRESS_SUBMIT_FAILED]: {
    category: 'stages',
    action: addressTypes.ADDRESS_SUBMIT_FAILED,
  },
  [enhancedTypes.ENHANCED_SUBMIT_FAILED]: {
    category: 'stages',
    action: enhancedTypes.ENHANCED_SUBMIT_FAILED,
  },
  [corporateTypes.CORPORATE_SUBMIT_FAILED]: {
    category: 'stages',
    action: corporateTypes.CORPORATE_SUBMIT_FAILED,
  },
  [cfdTypes.QUESTIONNAIRE_SUBMIT_FAILED]: {
    category: 'stages',
    action: cfdTypes.QUESTIONNAIRE_SUBMIT_FAILED,
  },
};
