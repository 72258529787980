export enum Type {
  QUESTIONNAIRE_SET_ANSWER = '@Questionnaire/QUESTIONNAIRE_SET_ANSWER',

  QUESTIONNAIRE_SUBMIT_REQUEST = '@Questionnaire/QUESTIONNAIRE_SUBMIT_REQUEST',
  QUESTIONNAIRE_SUBMIT_SUCCESS = '@Questionnaire/QUESTIONNAIRE_SUBMIT_SUCCESS',
  QUESTIONNAIRE_SUBMIT_FAILED = '@Questionnaire/QUESTIONNAIRE_SUBMIT_FAILED',

  QUESTIONNAIRE_STAGE_ANSWER_REQUEST = '@Questionnaire/QUESTIONNAIRE_STAGE_ANSWER_REQUEST',
  QUESTIONNAIRE_STAGE_ANSWER_SUCCESS = '@Questionnaire/QUESTIONNAIRE_STAGE_ANSWER_SUCCESS',
  QUESTIONNAIRE_STAGE_ANSWER_FAILED = '@Questionnaire/QUESTIONNAIRE_STAGE_ANSWER_FAILED',
}

export interface IQuestionnaireSetAnswer {
  type: Type.QUESTIONNAIRE_SET_ANSWER;
  payload: {
    name: string;
    value: string;
    blockId: string;
  };
}

export interface IQuestionnaireSubmitRequest {
  type: Type.QUESTIONNAIRE_SUBMIT_REQUEST;
  payload: {};
}

export interface IQuestionnaireSubmitSuccess {
  type: Type.QUESTIONNAIRE_SUBMIT_SUCCESS;
  payload: {};
}

export interface IQuestionnaireSubmitFailed {
  type: Type.QUESTIONNAIRE_SUBMIT_FAILED;
  payload: Error;
}

export interface IQuestionnaireStageAnswerRequest {
  type: Type.QUESTIONNAIRE_STAGE_ANSWER_REQUEST;
  payload: {
    stageId: string;
    answer: string;
  };
}

export interface IQuestionnaireStageAnswerSuccess {
  type: Type.QUESTIONNAIRE_STAGE_ANSWER_SUCCESS;
  payload: {};
}

export interface IQuestionnaireStageAnswerFailed {
  type: Type.QUESTIONNAIRE_STAGE_ANSWER_FAILED;
  payload: Error;
}

export type ActionType =
  | IQuestionnaireSetAnswer
  | IQuestionnaireSubmitRequest
  | IQuestionnaireSubmitSuccess
  | IQuestionnaireSubmitFailed
  | IQuestionnaireStageAnswerRequest
  | IQuestionnaireStageAnswerSuccess
  | IQuestionnaireStageAnswerFailed;
