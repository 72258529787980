import { RouteStage, RoutingSchema, Step } from './types';
import { StageId } from '../Stages/types';

export enum Type {
  SET_SCHEMA = '@Routing/SET_SCHEMA',

  SET_STAGE_ROUTE_IDS_GROUPED = '@Routing/SET_STAGE_ROUTE_IDS_GROUPED',
  SET_STAGE_ROUTE_IDS = '@Routing/SET_STAGE_ROUTE_IDS',
  SET_STAGES_ENTRY_URLS = '@Routing/SET_STAGES_ENTRY_URLS',

  SET_STAGE_PROGRESS = '@Routing/SET_STAGE_PROGRESS',

  SET_IDENTITY_PROGRESS = '@Routing/SET_IDENTITY_PROGRESS',

  ROUTING_TO_PROCESS_STEP_PROGRESS = '@Routing/ROUTING_TO_PROCESS_STEP_PROGRESS',
  CORPORATE_ROUTING_STEP_PROGRESS = '@Routing/CORPORATE_ROUTING_STEP_PROGRESS',

  ROUTING_NAVIGATE_NEXT = '@Routing/ROUTING_NAVIGATE_NEXT',
  ROUTING_NAVIGATE_BACK = '@Routing/ROUTING_NAVIGATE_BACK',
  ROUTING_NAVIGATE_INDEX = '@Routing/ROUTING_NAVIGATE_INDEX',

  CORPORATE_NAVIGATE_NEXT = '@Routing/CORPORATE_NAVIGATE_NEXT',
  CORPORATE_NAVIGATE_BACK = '@Routing/CORPORATE_NAVIGATE_BACK',
  CORPORATE_NAVIGATE_STEP = '@Routing/CORPORATE_NAVIGATE_STEP',
}

export interface IRoutingSetSchema {
  type: Type.SET_SCHEMA;
  payload: RoutingSchema;
}

export interface IRoutingSetStageRouteIdsGrouped {
  type: Type.SET_STAGE_ROUTE_IDS_GROUPED;
  payload: RouteStage;
}

export interface IRoutingSetStageRouteIds {
  type: Type.SET_STAGE_ROUTE_IDS;
  payload: {
    stageId: StageId;
    routeIds: (Step | string)[];
  };
}

export interface IRoutingSetStagesEntryUrls {
  type: Type.SET_STAGES_ENTRY_URLS;
  payload: string[];
}

export interface IRoutingProcessStepProgress {
  type: Type.ROUTING_TO_PROCESS_STEP_PROGRESS;
  payload?: {
    nextStepId?: string;
    omitPrevSteps?: string[];
    omitNextSteps?: string[];
  };
}

export interface ICorporateRoutingStepProgress {
  type: Type.CORPORATE_ROUTING_STEP_PROGRESS;
  payload?: {
    nextStepId?: string;
    omitPrevSteps?: string[];
    omitNextSteps?: string[];
  };
}

export interface IRoutingSetStepProgress {
  type: Type.SET_STAGE_PROGRESS;
  payload: any;
}

export interface ISetIdentityProgress {
  type: Type.SET_IDENTITY_PROGRESS;
  payload: number;
}

export type ActionType =
  | IRoutingSetSchema
  | IRoutingSetStageRouteIdsGrouped
  | IRoutingSetStageRouteIds
  | IRoutingSetStagesEntryUrls
  | IRoutingProcessStepProgress
  | IRoutingSetStepProgress
  | ICorporateRoutingStepProgress
  | ISetIdentityProgress;
