import {
  Type,
  IVerificationDateStart,
  VerificationLifeTimeParams
} from './actionTypes';

export const setVerificationDateStart = (
  payload: VerificationLifeTimeParams
): IVerificationDateStart => ({
  type: Type.VERIFICATION_DATE_START,
  payload,
});
