import { takeLatest } from 'redux-saga/effects';

import { Type } from '../actionTypes';
import {
  handleStepProgress
} from './processes';

import { navigateToIndex, handleStageNavigation } from './navigate';
import { handleCorporateNavigation, handleCorporateStepProgress, navigateCorporateStep } from './corporate';

export function* rootSaga() {
  yield takeLatest(
    Type.ROUTING_TO_PROCESS_STEP_PROGRESS,
    handleStepProgress
  );
  yield takeLatest(
    Type.CORPORATE_ROUTING_STEP_PROGRESS,
    handleCorporateStepProgress
  );

  yield takeLatest(
    Type.CORPORATE_NAVIGATE_STEP,
    navigateCorporateStep
  );

  yield takeLatest(
    [Type.ROUTING_NAVIGATE_NEXT, Type.ROUTING_NAVIGATE_BACK],
    handleStageNavigation
  );

  yield takeLatest(
    [Type.CORPORATE_NAVIGATE_NEXT, Type.CORPORATE_NAVIGATE_BACK],
    handleCorporateNavigation
  );

  yield takeLatest(Type.ROUTING_NAVIGATE_INDEX, navigateToIndex);
}
