import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Clip: React.FunctionComponent<IconProps> = ({
  width,
  height,
}) => {
  const {
    icons: {
      markup: {
        clip: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.492 7.614a.967.967 0 0 1 1.325 0 .868.868 0 0 1 0 1.266l-8.134 7.776a1.168 1.168 0 0 0 0 1.696 1.302 1.302 0 0 0 1.774 0L20.464 6.874C21.644 5.746 21.556 4 20.27 2.77c-1.286-1.23-3.111-1.313-4.291-.185L3.197 14.805a4.222 4.222 0 0 0 0 6.14c1.764 1.686 4.657 1.686 6.422 0L22.4 8.724a.967.967 0 0 1 1.325 0 .868.868 0 0 1 0 1.266L10.943 22.21c-2.496 2.386-6.575 2.386-9.07 0a5.957 5.957 0 0 1 0-8.672l12.781-12.22c1.942-1.856 4.95-1.717 6.94.186 1.992 1.903 2.137 4.779.195 6.635L9.78 19.62c-1.218 1.164-3.205 1.164-4.423 0a2.903 2.903 0 0 1 0-4.229l8.134-7.776z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
};

Clip.displayName = 'ClipIcon';

Clip.defaultProps = { height: '24', width: '24' };
