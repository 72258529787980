import { ReactNode } from 'react';
import get from 'lodash/get';
import { Product, Stage, StageId } from 'system/Stages/types';
import { ITranslate } from 'system/Localization';
import { ClientRectResize, SAGE_PREVIEW_FULL_WIDTH_DESKTOP } from './constants';

interface ITimeCount {
  days: number;
  hours: number;
  minutes: number;
}

export const getCountdown = (dateOfNextVerification: number): ITimeCount => {
  const difference = (dateOfNextVerification * 1000) - Date.now();

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

  return { days, hours, minutes };
};
// TODO rewrite to return only key
export const getTimeCountMessage = (translate: ITranslate, { days, hours, minutes }: ITimeCount): string | ReactNode => {
  if (days > 0) {
    return translate('days_count', 'days_count', days);
  }
  if (hours > 0) {
    return translate('hours_count', 'hours_count', hours);
  }
  if (minutes > 0) {
    return translate('minutes_count', 'minutes_count', minutes);
  }

  return '';
};

export const getStageTitle = (stageId: StageId) => {
  const stageTitles: { [k in StageId]: string } = {
    [Stage.IDENTITY]: 'stage_title_identity',
    [Stage.ADDRESS]: 'stage_title_address',
    [Stage.ENHANCED]: 'stage_title_enhanced',
    [Stage.CORPORATE]: 'stage_title_corporate',
    [Stage.CFD]: 'stage_title_bro_questionnaire',
  };

  return get(stageTitles, stageId, '');
};

export const getStageSubtitle = (index: number) => get(['stage_index_title_1', 'stage_index_title_2', 'stage_index_title_3', 'stage_index_title_4', 'stage_index_title_5'], index, '');

// TODO remove to config file for merchant
export const getStageShortMessage = (stageId: StageId, product: Product) => {
  const stageMessages: {
    [k in StageId]: { common: string; 'cex-broker'?: string };
  } = {
    identity: {
      common: 'stage_description_short_identity',
      'cex-broker': 'stage_subtitle_identity_cex-bro',
    },
    address: {
      common: 'stage_description_short_address',
      'cex-broker': 'stage_subtitle_address_cex-bro',
    },
    enhanced: {
      common: 'stage_description_short_enhanced',
      'cex-broker': 'stage_subtitle_aml_cex-bro',
    },
    corporate: {
      common: 'stage_description_short_corporate',
      'cex-broker': 'stage_subtitle_corporate_cex-bro',
    },
    cfd: {
      common: 'stage_description_short_bro_questionnaire',
    },
  };

  return get(
    stageMessages,
    [stageId, product],
    get(stageMessages, [stageId, 'common'])
  );
};

export const getStageFullMessage = (stageId: StageId) => {
  const stageMessages: { [k in StageId]: string } = {
    [Stage.IDENTITY]: 'intro_description_identity',
    [Stage.ADDRESS]: 'intro_description_address',
    [Stage.ENHANCED]: 'intro_description_aml',
    [Stage.CORPORATE]: 'intro_description_corporate',
    [Stage.CFD]: 'intro_description_appropriateness',
  };
  return get( stageMessages, stageId, '');
};

export const getStartPositionOfStagePreviewFull = (settings = {}) => {
  const {
    rect: {
      top: nodeTop = 'auto',
      left: nodeLeft = 'auto',
      width = 'auto',
    } = {},
    windowSize,
    container
  } = settings as ClientRectResize;

  const w = windowSize ? windowSize.width : 0;
  const windowIndent = 16;
  const shiftX = 196;
  const shiftY = 184;
  const top = +nodeTop - container?.containerTop;
  const left = +nodeLeft - container?.containerLeft;

  const x = w - (left + SAGE_PREVIEW_FULL_WIDTH_DESKTOP + windowIndent) > 0 ? 'left' : 'right';
  const y = top - shiftY > 0 ? 'up' : 'down';

  return { x, y, shiftX, shiftY, width, top, left }
};
