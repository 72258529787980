import { createSelector } from 'reselect';
import { matchPath } from 'react-router-dom';
import get from 'lodash/get';
import { Map, List } from 'immutable';

import { makeSelectErrors as makeSelectProfileErrors } from 'system/Profile/selectors';
import { makeSelectErrors as makeSelectUIErrors, makeSelectLoadingUi } from 'system/UI/selectors';
import { makeSelectErrors as makeSelectIdentityErrors } from 'stages/Identity/selectors';
import { makeSelectErrors as makeSelectAddressErrors } from 'stages/Address/selectors';
import { makeSelectErrors as makeSelectEnhancedErrors } from 'stages/Enhanced/selectors';
import { url } from 'utils/url';
import { Status } from './reducer';

const bootstrapSelector = (state: any) => state.get('bootstrap');
const dossierSelector = (state: any) => state.get('dossier');
export const routerSelector = (state: any) => state.get('router');

export const makeSelectRouter = () =>
  createSelector(routerSelector, (state) => state);

// todo: replace all occurrences
export const makeSelectRouterSearch = () =>
  createSelector(routerSelector, (router) =>
    router.getIn(['location', 'search'])
  );

export const makeSelectRouterPathname = () =>
  createSelector(routerSelector, (router) => {
    return router.getIn(['location', 'pathname'], '')
  });

export const makeSelectRouterParams = () =>
  createSelector(routerSelector, (router) => {
    return matchPath<{ stageId: string; stepId: string; stubId: string }>(
      router.getIn(['location', 'pathname']),
      { path: '/:stageId/:stepId?/:stubId?' }
    );
  });

export const makeSelectRouterSearchUploadId = () =>
  createSelector(makeSelectRouterSearch(), (search) => {
    const { uploadId = '' } = url.parse(search);
    return uploadId;
  });

export const makeSelectLoading = () =>
  createSelector(
    bootstrapSelector,
    dossierSelector,
    routerSelector,
    makeSelectLoadingUi(),
    (bootstrap, dossier, router, uiLoader) => {
      const matched = matchPath(router.getIn(['location', 'pathname']), { path: '/:stageId'});
      const stageId = get(matched, 'params.stageId');

      const stageIsLoading = dossier.getIn([stageId, 'loading'], false);

      return bootstrap.get('status') === Status.LOADING  || stageIsLoading || uiLoader;
    }
  );

export const makeSelectErrors = () =>
  createSelector(bootstrapSelector, (bootstrap) =>
    bootstrap.get('errors', List())
  );

export const makeSelectPath = createSelector(
  routerSelector,
  (router: Map<string, any>) => router.getIn(['location', 'pathname'], '/')
);

export const makeSelectAllErrors = () =>
  createSelector(
    makeSelectErrors(),
    makeSelectProfileErrors(),
    makeSelectIdentityErrors(),
    makeSelectAddressErrors(),
    makeSelectEnhancedErrors(),
    makeSelectUIErrors(),
    (
      bootstrap: List<Map<string, any>>,
      profile: List<Map<string, any>>,
      identity: List<Map<string, any>>,
      address: List<Map<string, any>>,
      enhanced: List<Map<string, any>>,
      ui: List<Map<string, any>>
    ) => {
      const errors = bootstrap.concat(profile, identity, address, enhanced, ui);
      return errors;
    }
  );
