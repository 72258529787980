import styled from 'styled-components';

export const ErrorMessage = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.text.error.color};
  display: block;
  font-size: 13px;
  font-weight: 600;
  padding-left: ${({ theme }) => theme.text.error.paddingLeft};
  padding-top: ${({ theme }) => theme.text.error.paddingTop};
`;
