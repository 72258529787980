import { boolean, number, object, string } from 'yup';
import { objectShape } from './helpers';

const baseInputSchema = (name: string) => ({
  backgroundColor: string(),
  borderColor: string(),
  fontSize: string(),
  boxShadow: string(),

  disabled: objectShape(
    {
      borderColor: string(),
      backgroundColor: string(),
    },
    `${name}.disabled`
  ),

  valid: objectShape(
    {
      borderColor: string(),
    },
    `${name}.valid`
  ),
});

const extendedInputSchema = (name: string) => ({
  ...baseInputSchema(name),
  borderRadius: string(),
  borderWidth: string(),
  color: string(),

  focus: objectShape(
    {
      backgroundColor: string(),
      borderColor: string(),
    },
    `${name}.focus`
  ),

  disabled: objectShape(
    {
      borderColor: string(),
      backgroundColor: string(),
      color: string(),
    },
    `${name}.disabled`
  ),

  error: objectShape(
    {
      backgroundColor: string(),
      borderColor: string(),
    },
    `${name}.error`
  ),
});

const badgeSchema = objectShape(
  {
    color: string(),
    backgroundColor: string(),
    danger: objectShape(
      {
        backgroundColor: string(),
      },
      'text.badge.danger'
    ),
    primary: objectShape(
      {
        backgroundColor: string(),
      },
      'text.badge.primary'
    ),
    warning: objectShape(
      {
        backgroundColor: string(),
      },
      'text.badge.warning'
    ),
    success: objectShape(
      {
        backgroundColor: string(),
      },
      'text.badge.success'
    ),
    pending: objectShape(
      {
        backgroundColor: string(),
      },
      'text.badge.pending'
    ),
    awaiting: objectShape(
      {
        backgroundColor: string(),
      },
      'text.badge.awaiting'
    ),
  },
  'text.badge'
);

const breakpointSchema = objectShape(
  {
    mobile: string(),
    tablet: string(),
    desktop: string(),
  },
  'breakpoint'
);

const buttonBaseSchema = objectShape(
  {
    minWidth: string(),
    minHeight: string(),
    padding: string(),
    border: string(),
    borderRadius: string(),
    backgroundColor: string(),
    fontWeight: string(),
    fontSize: string(),
    color: string(),

    disabled: objectShape(
      {
        opacity: string(),
        backgroundColor: string(),
        color: string(),
        border: string(),
      },
      'buttons.base.disabled'
    ),

    hover: objectShape(
      {
        color: string(),
        backgroundColor: string(),
      },
      'buttons.base.hover'
    ),

    small: objectShape(
      {
        minWidth: string(),
        padding: string(),
        fontSize: string(),
      },
      'buttons.base.small'
    ),
  },
  'buttons.base'
);

const buttonPrimarySchema = objectShape(
  {
    backgroundColor: string(),
    border: string(),
    color: string(),
    borderRadius: string(),
    hover: objectShape(
      {
        backgroundColor: string(),
        color: string(),
      },
      'button.primary.hover'
    ),
  },
  'button.primary'
);

const buttonResubmitSchema = objectShape(
  {
    triggerColor: string(),
    color: string(),
    borderColor: string(),
    itemBorderColor: string(),
  },
  'button.resubmit'
);

const baseLinkSchema = objectShape(
  {
    color: string(),
    fontWeight: number(),
    textDecoration: string(),
    danger: objectShape(
      {
        color: string(),
      },
      'links.base.danger'
    ),
    action: objectShape(
      {
        color: string(),
      },
      'links.base.action'
    ),
    hover: objectShape(
      {
        color: string(),
        textDecoration: string(),
      },
      'links.base.hover'
    ),
  },
  'links.base'
);

const exitLinkSchema = objectShape(
  {
    backgroundColor: string(),
    color: string(),
    hover: objectShape(
      {
        color: string(),
        backgroundColor: string(),
      },
      'links.exit.hover'
    ),
  },
  'links.exit'
);

const navLinkSchema = objectShape(
  {
    borderColor: string(),
    backgroundColor: string(),
    borderRadius: string(),
    borderWidth: string(),
    color: string(),
    padding: string(),
    hover: objectShape(
      {
        color: string(),
        backgroundColor: string(),
      },
      'links.nav.hover'
    ),
  },
  'links.nav'
);

const dropdownSchema = objectShape(
  {
    ...extendedInputSchema('forms.dropdown'),
    placeholderColor: string(),
    hover: objectShape(
      {
        backgroundColor: string(),
      },
      'forms.dropdown.hover'
    ),
    active: objectShape(
      {
        backgroundColor: string(),
      },
      'forms.dropdown.active'
    ),
  },
  'forms.dropdown'
);

const inputSchema = objectShape(
  {
    ...extendedInputSchema('forms.input'),
    fontSize: string(),
  },
  'forms.input'
);

const radioSchema = objectShape(
  {
    ...baseInputSchema('forms.radio'),
    borderWidth: string(),
    checked: objectShape(
      {
        backgroundColor: string(),
        backgroundColorAfter: string(),
        borderColor: string(),
      },
      'forms.radio.checked'
    ),
  },
  'forms.radio'
);

const checkBoxSchema = objectShape(
  {
    ...baseInputSchema('forms.radio'),
    borderWidth: string(),
    checked: objectShape(
      {
        backgroundColor: string(),
        backgroundColorAfter: string(),
        borderColor: string(),
      },
      'forms.radio.checked'
    ),
  },
  'forms.radio'
);

const textareaSchema = objectShape(
  {
    ...extendedInputSchema('forms.input'),
    resize: string(),
  },
  'forms.textarea'
);

const tumblerSchema = objectShape(
  {
    ...baseInputSchema('forms.tumbler'),
    borderRadius: string(),
    boxShadow: string(),
    borderWidth: string(),
    checked: objectShape(
      {
        borderColor: string(),
        backgroundColor: string(),
      },
      'forms.tumbler.checked'
    ),
  },
  'forms.tumbler'
);

const descriptionSchema = objectShape(
  {
    color: string(),
  },
  'text.description'
);

const errorSchema = objectShape(
  {
    color: string(),
    paddingTop: string(),
    paddingLeft: string(),
  },
  'text.error'
);

const colorSchema = (name: string) => objectShape({ color: string() }, name);

const breadcrumbsSchema = objectShape(
  {
    color: string(),
    backgroundColor: string(),
    darkArrow: boolean(),
    mobile: objectShape(
      {
        color: string(),
        backgroundColor: string(),
      },
      'components.breadcrumbs.mobile'
    ),
  },
  'components.breadcrumbs'
);

const dividerSchema = objectShape(
  {
    backgroundColor: string(),
    lineColor: string(),
  },
  'components.divider'
);

const feedbackSchema = objectShape(
  {
    starActiveColor: string(),
    textColor: string(),
  },
  'components.feedback'
);

const hamburgerSchema = objectShape(
  {
    borderColor: string(),
    backgroundColor: string(),
  },
  'components.hamburger'
);

const modalSchema = (name: string) =>
  objectShape(
    {
      backgroundColorCloseButton: string(),
      colorCloseButton: string(),
    },
    name
  );

const navigationSchema = objectShape(
  {
    backgroundColor: string(),
    boxShadow: string(),
  },
  'components.navigation'
);

const phoneNumberSchema = objectShape(
  {
    buttonColor: string(),
  },
  'components.phoneNumber'
);

const progressSchema = objectShape(
  {
    backgroundColor: string(),
    backgroundColorAfter: string(),
    tagColor: string(),
    tagFontWeight: string(),
  },
  'components.progress'
);

const qrcodeSchema = objectShape(
  {
    width: string(),
    height: string(),
  },
  'components.qrcode'
);

const sliderSchema = objectShape(
  {
    backgroundColor: string(),
    backgroundColorActive: string(),
    arrow: objectShape(
      {
        backgroundColor: string(),
      },
      'components.slider.arrow'
    ),
  },
  'components.slider'
);

const stagePreviewSchema = objectShape(
  {
    textColor: string(),
    titleColor: string(),
    contentBackground: string(),
    borderRadius: string(),
    full: objectShape(
      {
        correction: objectShape(
          {
            divider: string(),
          },
          'components.stagePreview.full.correction'
        ),
      },
      'components.stagePreview.full'
    ),
  },
  'components.stagePreview'
);

const statusBoxSchema = objectShape(
  {
    background: objectShape(
      {
        primary: string(),
        danger: string(),
        warning: string(),
        success: string(),
      },
      'components.statusBox.background'
    ),
  },
  'components.statusBox'
);

const tooltipSchema = objectShape(
  {
    background: string(),
    border: string(),
    borderRadius: string(),
    fontSize: string(),
    maxWidth: string(),
    padding: string(),
  },
  'components.tooltip'
);

const uploaderSchema = objectShape(
  {
    width: string(),
    height: string(),
    backgroundColor: string(),
    borderColor: string(),
    borderColorLabel: string(),
    borderStyle: string(),
    borderWidth: string(),
    borderRadius: string(),
    borderRadiusLabel: string(),
    boxShadow: string(),
    active: objectShape(
      { borderColor: string() },
      'components.uploader.active'
    ),
    error: objectShape({ borderColor: string() }, 'components.uploader.error'),
  },
  'components.uploader'
);

const iconSchema = (name: string) =>
  objectShape(
    {
      fill: string(),
      fill2: string(),
      fill3: string(),
    },
    name
  );

export const themeSchema = object().shape({
  breakpoint: breakpointSchema,
  layout: objectShape({ backgroundColor: string() }, 'layout'),
  buttons: objectShape(
    {
      base: buttonBaseSchema,
      primary: buttonPrimarySchema,
      resubmit: buttonResubmitSchema,
    },
    'buttons'
  ),
  links: objectShape(
    {
      base: baseLinkSchema,
      exit: exitLinkSchema,
      nav: navLinkSchema,
    },
    'links'
  ),
  forms: objectShape(
    {
      dropdown: dropdownSchema,
      input: inputSchema,
      radio: radioSchema,
      checkBox: checkBoxSchema,
      textarea: textareaSchema,
      tumbler: tumblerSchema,
    },
    'forms'
  ),
  text: objectShape(
    {
      badge: badgeSchema,
      description: descriptionSchema,
      error: errorSchema,
      h1: colorSchema('text.h1'),
      h3: colorSchema('text.h3'),
      h4: colorSchema('text.h4'),
    },
    'text'
  ),
  components: objectShape(
    {
      appendControl: colorSchema('components.appendControl'),
      breadcrumbs: breadcrumbsSchema,
      correction: objectShape({ accent: string() }, 'components.correction'),
      divider: dividerSchema,
      feedback: feedbackSchema,
      geoHighlightTip: colorSchema('components.geoHighlightTip'),
      hamburger: hamburgerSchema,
      modal: modalSchema('components.modal'),
      modalPreview: modalSchema('components.modalPreview'),
      navigation: navigationSchema,
      previewArea: objectShape(
        { borderRadius: string() },
        'components.previewArea'
      ),
      phoneNumber: phoneNumberSchema,
      progress: progressSchema,
      qrcode: qrcodeSchema,
      slider: sliderSchema,
      stagePreview: stagePreviewSchema,
      statusBox: statusBoxSchema,
      tooltip: tooltipSchema,
      uploader: uploaderSchema,
    },
    'components'
  ),
  icons: objectShape(
    {
      docs: objectShape(
        {
          backSide: iconSchema('icons.docs.backSide'),
          blankStatement: iconSchema('icons.docs.blankStatement'),
          doc: iconSchema('icons.docs.doc'),
          electricityBill: iconSchema('icons.docs.electricityBill'),
          frontSide: iconSchema('icons.docs.frontSide'),
          miningActivity: iconSchema('icons.docs.miningActivity'),
          selfie: iconSchema('icons.docs.selfie'),
          selfieWithNote: iconSchema('icons.docs.selfieWithNote'),
          taxes: iconSchema('icons.docs.taxes'),
          tradingActivity: iconSchema('icons.docs.tradingActivity'),
          utilityBill: iconSchema('icons.docs.utilityBill'),
        },
        'icons.docs'
      ),
      markup: objectShape(
        {
          angle: iconSchema('icons.markup.angle'),
          arrow: iconSchema('icons.markup.arrow'),
          check: iconSchema('icons.markup.check'),
          clip: iconSchema('icons.markup.clip'),
          clock: iconSchema('icons.markup.clock'),
          close: iconSchema('icons.markup.close'),
          discuss: iconSchema('icons.markup.discuss'),
          exclamation: iconSchema('icons.markup.exclamation'),
          exit: iconSchema('icons.markup.exit'),
          eye: iconSchema('icons.markup.eye'),
          info: iconSchema('icons.markup.info'),
          lock: iconSchema('icons.markup.lock'),
          ok: iconSchema('icons.markup.ok'),
          refresh: iconSchema('icons.markup.refresh'),
          star: objectShape(
            { active: string(), def: string() },
            'icons.markup.star'
          ),
          status: objectShape(
            { pending: string(), draft: string(), correction: string(), accepted: string() },
            'icons.markup.status'
          ),
          stop: iconSchema('icons.markup.stop'),
        },
        'icons.markup'
      ),
      stubs: objectShape(
        {
          apiError: iconSchema('icons.stubs.apiError'),
          card: iconSchema('icons.stubs.card'),
          checkEmail: iconSchema('icons.stubs.checkEmail'),
          clock: iconSchema('icons.stubs.clock'),
          corporate: iconSchema('icons.stubs.corporate'),
          error: iconSchema('icons.stubs.error'),
          ok: iconSchema('icons.stubs.ok'),
          notice: iconSchema('icons.stubs.notice'),
          qrConnected: iconSchema('icons.stubs.qrConnected'),
          qrSubmitted: iconSchema('icons.stubs.qrSubmitted'),
          rejected: iconSchema('icons.stubs.rejected'),
          settings: iconSchema('icons.stubs.settings'),
          stop: iconSchema('icons.stubs.stop'),
        },
        'icons.stubs'
      ),
    },
    'icons'
  ),
});
