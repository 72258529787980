import { IconProps } from 'components/UI/Icon/types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export const Selfie: React.FC<IconProps> = ({ width = 114, height = 124 }) => {
  const {
    icons: {
      docs: {
        selfie: { fill, fill2 },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} version="1.1">
      <g id="Template-verification" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Template-4_e" transform="translate(-901.000000, -356.000000)">
          <g id="Man-ico" transform="translate(892.000000, 356.000000)">
            <g id="User-selfy">
              <g id="Group-15">
                <g id="Group-9">
                  <g id="Group-5">
                    <polygon id="Rectangle-16" points="0 0 114 0 114 116 0 116" />
                    <path d="M95.6384792,93.6382642 L74.8429792,83.1851321 C72.1564583,81.834717 70.4583333,79.0726038 70.4583333,76.0522264 L70.4583333,67.8490566 C71.0417917,67.1815094 71.7123333,66.3213585 72.4264167,65.3058113 C75.2609792,61.2830189 77.4032292,56.8553208 78.8901771,52.2087547 C81.5571042,51.3836226 83.5208333,48.9060377 83.5208333,45.9622642 L83.5208333,37.2075472 C83.5208333,35.2815094 82.6695937,33.5590189 81.34375,32.3552453 L81.34375,19.6981132 C81.34375,19.6981132 83.9279479,0 57.3958333,0 C30.8615417,0 33.4479167,19.6981132 33.4479167,19.6981132 L33.4479167,32.3552453 C32.1220729,33.5590189 31.2708333,35.2815094 31.2708333,37.2075472 L31.2708333,45.9622642 C31.2708333,48.2691321 32.4769375,50.2980377 34.2817396,51.4711698 C36.4566458,60.9963019 42.15625,67.8490566 42.15625,67.8490566 L42.15625,75.8486792 C42.15625,78.7661887 40.5713333,81.4516981 38.0219687,82.8480755 L18.6002083,93.5003774 C12.9898646,96.5754717 9.5,102.487094 9.5,108.910868 C9.5,112.826087 12.6739125,116 16.5891321,116 L105.291667,116 L105.291667,116 L105.291667,109.339849 C105.291667,102.690642 101.553615,96.6126792 95.6384792,93.6382642 Z" id="Shape" fill={fill} fillRule="nonzero" />
                  </g>
                </g>
              </g>
            </g>
            <g id="pic/pic_22-copy-16" transform="translate(69.337867, 95.335808)">
              <path d="M4,0.5 C2.06700338,0.5 0.5,2.06700338 0.5,4 L0.5,23.6087129 C0.5,25.5417095 2.06700338,27.1087129 4,27.1087129 L35.0407986,27.1087129 C36.9737952,27.1087129 38.5407986,25.5417095 38.5407986,23.6087129 L38.5407986,4 C38.5407986,2.06700338 36.9737952,0.5 35.0407986,0.5 L4,0.5 Z" id="Rectangle-12-Copy-5" stroke={fill} fill="#FFFFFF" />
              <path d="M4.25339988,7.63645251 L15.2669994,7.63645251 C15.8192842,7.63645251 16.2669994,8.08416776 16.2669994,8.63645251 L16.2669994,20.7345187 C16.2669994,21.2868034 15.8192842,21.7345187 15.2669994,21.7345187 L4.25339988,21.7345187 C3.70111513,21.7345187 3.25339988,21.2868034 3.25339988,20.7345187 L3.25339988,8.63645251 C3.25339988,8.08416776 3.70111513,7.63645251 4.25339988,7.63645251 Z" id="Rectangle-12-Copy-10" fill={fill2} />
              <rect id="Rectangle-12-Copy-12" fill={fill2} x="18.4359327" y="7.63645251" width="17.351466" height="1.17483885" rx="0.587419424" />
              <path d="M19.0233521,10.5735496 L35.1999793,10.5735496 C35.5244021,10.5735496 35.7873987,10.8365463 35.7873987,11.160969 C35.7873987,11.4853918 35.5244021,11.7483885 35.1999793,11.7483885 L19.0233521,11.7483885 C18.6989293,11.7483885 18.4359327,11.4853918 18.4359327,11.160969 C18.4359327,10.8365463 18.6989293,10.5735496 19.0233521,10.5735496 Z" id="Rectangle-12-Copy-13" fill={fill2} />
              <path d="M19.0233521,13.5106467 L28.6931795,13.5106467 C29.0176023,13.5106467 29.280599,13.7736434 29.280599,14.0980662 C29.280599,14.422489 29.0176023,14.6854856 28.6931795,14.6854856 L19.0233521,14.6854856 C18.6989293,14.6854856 18.4359327,14.422489 18.4359327,14.0980662 C18.4359327,13.7736434 18.6989293,13.5106467 19.0233521,13.5106467 Z" id="Rectangle-12-Copy-14" fill={fill2} />
            </g>
            <g id="Group-14" transform="translate(112.000000, 112.500000) rotate(266.000000) translate(-112.000000, -112.500000) translate(100.000000, 101.000000)" fill={fill}>
              <g id="Group-13">
                <path d="M5.25916046,5.30261733 C6.40029031,5.30261733 7.32535882,6.22768584 7.32535882,7.36881569 L7.32535882,19.3668471 C7.32535882,20.507977 6.40029031,21.4330455 5.25916046,21.4330455 C4.11803062,21.4330455 3.19296211,20.507977 3.19296211,19.3668471 L3.19296211,7.36881569 C3.19296211,6.22768584 4.11803062,5.30261733 5.25916046,5.30261733 Z" id="Rectangle-8" transform="translate(5.259160, 13.367831) rotate(-24.000000) translate(-5.259160, -13.367831) " />
                <path d="M8.62062028,0.663821324 C9.76175013,0.663821324 10.6868186,1.58888984 10.6868186,2.73001968 L10.6868186,15.2821321 C10.6868186,16.423262 9.76175013,17.3483305 8.62062028,17.3483305 C7.47949044,17.3483305 6.55442193,16.423262 6.55442193,15.2821321 L6.55442193,2.73001968 C6.55442193,1.58888984 7.47949044,0.663821324 8.62062028,0.663821324 Z" id="Rectangle-8-Copy" transform="translate(8.620620, 9.006076) rotate(-24.000000) translate(-8.620620, -9.006076) " />
                <path d="M14.2618834,2.06423028 C15.4030133,2.06423028 16.3280818,2.9892988 16.3280818,4.13042864 L16.3280818,16.6825411 C16.3280818,17.8236709 15.4030133,18.7487394 14.2618834,18.7487394 C13.1207536,18.7487394 12.1956851,17.8236709 12.1956851,16.6825411 L12.1956851,4.13042864 C12.1956851,2.9892988 13.1207536,2.06423028 14.2618834,2.06423028 Z" id="Rectangle-8-Copy-2" transform="translate(14.261883, 10.406485) rotate(-24.000000) translate(-14.261883, -10.406485) " />
                <path d="M19.4685772,5.0782916 C20.6097071,5.0782916 21.5347756,6.00336012 21.5347756,7.14448996 L21.5347756,14.5244173 C21.5347756,15.6655472 20.6097071,16.5906157 19.4685772,16.5906157 C18.3274474,16.5906157 17.4023789,15.6655472 17.4023789,14.5244173 L17.4023789,7.14448996 C17.4023789,6.00336012 18.3274474,5.0782916 19.4685772,5.0782916 Z" id="Rectangle-8-Copy-3" transform="translate(19.468577, 10.834454) rotate(-24.000000) translate(-19.468577, -10.834454) " />
              </g>
              <path d="M3.99531076,15.0255401 C4.65561465,20.5348228 7.49281814,22.983428 12.5069212,22.3713555 C14.5594771,22.1207996 17.60441,22.3364955 19.0431432,21.3490853 C23.5244044,18.2735721 24.2449706,14.4854752 21.2048419,9.98479462 L3.99531076,15.0255401 Z" id="Path-4" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
