import yup from 'yup';
import { localDebug } from 'utils/debug';

export const validate = <T>(schema: yup.Schema<T>, values: T) => {
  try {
    const result = schema.validateSync(values);
    localDebug('validate.result', result, values);
    return true;
  } catch(error) {
    localDebug('validate.error', error, values);
    return false;
  }
};
