import { IRecognizedResult } from 'utils/externalProfiles/types';
import { IPhoneNumber } from 'utils/types/IPhoneNumber';
import { IUploadResult } from '../../system/Dossier/Upload/sagas';

export enum Type {
  IDENTITY_STEP_INITIAL_SET = '@Identity/IDENTITY_STEP_INITIAL_SET',
  IDENTITY_STEP_SET = '@Identity/IDENTITY_STEP_SET',
  IDENTITY_BLOCK_FAILED = '@Identity/IDENTITY_BLOCK_FAILED',
  IDENTITY_BLOCK_RESET = '@Identity/IDENTITY_BLOCK_RESET',
  IDENTITY_UPLOAD_DOC_REQUEST = '@Identity/IDENTITY_UPLOAD_DOC_REQUEST',
  IDENTITY_UPLOAD_DOC_SUCCESS = '@Identity/IDENTITY_UPLOAD_DOC_SUCCESS',
  IDENTITY_UPLOAD_DOC_FAILED = '@Identity/IDENTITY_UPLOAD_DOC_FAILED',
  IDENTITY_DOC_SET = '@Identity/IDENTITY_DOC_SET',

  IDENTITY_META_SET = '@Identity/IDENTITY_META_SET',

  IDENTITY_SUBMIT_REQUEST = '@Identity/IDENTITY_SUBMIT_REQUEST',
  IDENTITY_SUBMIT_SUCCESS = '@Identity/IDENTITY_SUBMIT_SUCCESS',
  IDENTITY_SUBMIT_FAILED = '@Identity/IDENTITY_SUBMIT_FAILED',

  IDENTITY_PHONE_CODE_REQUEST = '@Identity/IDENTITY_PHONE_CODE_REQUEST',
  IDENTITY_PHONE_CODE_SUCCESS = '@Identity/IDENTITY_PHONE_CODE_SUCCESS',
  IDENTITY_PHONE_CODE_FAILED = '@Identity/IDENTITY_PHONE_CODE_FAILED',

  IDENTITY_CODE_VALIDATION_REQUEST = '@Identity/IDENTITY_CODE_VALIDATION_REQUEST',
  IDENTITY_CODE_VALIDATION_SUCCESS = '@Identity/IDENTITY_CODE_VALIDATION_SUCCESS',
  IDENTITY_CODE_VALIDATION_FAILED = '@Identity/IDENTITY_CODE_VALIDATION_FAILED',

  IDENTITY_RESET_PHONE_ERRORS = '@Identity/IDENTITY_RESET_PHONE_ERRORS',
  IDENTITY_RESET_PHONE_VALIDATION = '@Identity/IDENTITY_RESET_PHONE_VALIDATION',

  IDENTITY_SET_PHONE_COUNTRY = '@Identity/IDENTITY_SET_PHONE_COUNTRY',
  IDENTITY_RESET_PHONE_CODE = '@Identity/IDENTITY_RESET_PHONE_CODE',
}

export interface IdentityStepInitialSet {
  type: Type.IDENTITY_STEP_INITIAL_SET;
  payload: {
    stepId: string;
    fields: object;
  };
}

export interface IdentityStepSet {
  type: Type.IDENTITY_STEP_SET;
  payload: {
    stepId: string;
    fields: object;
  };
}

export interface IdentityBlockFailed {
  type: Type.IDENTITY_BLOCK_FAILED;
  payload: Error;
}

export interface IdentityBlockReset {
  type: Type.IDENTITY_BLOCK_RESET;
  payload: { stepId: string };
}
export interface IRequestUploadIdentityDocuments {
  type: Type.IDENTITY_UPLOAD_DOC_REQUEST;
  payload: { files: File[]; onAfterUpload?: any };
}

export interface IUploadIdentityDocumentsSuccess {
  type: Type.IDENTITY_UPLOAD_DOC_SUCCESS;
  payload: {};
}

export interface IUploadIdentityDocumentsFailed {
  type: Type.IDENTITY_UPLOAD_DOC_FAILED;
  payload: Error[];
}

export interface ISetIdentityDocuments {
  type: Type.IDENTITY_DOC_SET;
  payload: IUploadResult[];
}

export interface IMetaPayload {
  payload: {
    stepId: string;
    fields: IRecognizedResult;
  };
}

export interface IdentitySetMeta extends IMetaPayload {
  type: Type.IDENTITY_META_SET;
}

export interface IdentitySubmitRequest {
  type: Type.IDENTITY_SUBMIT_REQUEST;
  payload: {};
}
export interface IdentitySubmitSuccess {
  type: Type.IDENTITY_SUBMIT_SUCCESS;
  payload: {};
}

export interface IdentitySubmitFailed {
  type: Type.IDENTITY_SUBMIT_FAILED;
  payload: Error[];
}

export interface IPhoneCodeRequest {
  type: Type.IDENTITY_PHONE_CODE_REQUEST;
  payload: { phone: IPhoneNumber };
}
export interface IPhoneCodeSuccess {
  type: Type.IDENTITY_PHONE_CODE_SUCCESS;
  payload: { timestamp: string };
}

export interface IPhoneCodeFailed {
  type: Type.IDENTITY_PHONE_CODE_FAILED;
  payload: Error[];
}

export interface ICodeValidationRequest {
  type: Type.IDENTITY_CODE_VALIDATION_REQUEST;
  payload: { phone: IPhoneNumber};
}
export interface ICodeValidationSuccess {
  type: Type.IDENTITY_CODE_VALIDATION_SUCCESS;
  payload: { valid: boolean; skip: boolean };
}

export interface ICodeValidationFailed {
  type: Type.IDENTITY_CODE_VALIDATION_FAILED;
  payload: Error | { code: number; name: string };
}

export interface IResetPhoneErrors {
  type: Type.IDENTITY_RESET_PHONE_ERRORS;
  payload: {};
}

export interface IResetPhoneValidation {
  type: Type.IDENTITY_RESET_PHONE_VALIDATION;
  payload: {};
}

export interface ISetPhoneCountry {
  type: Type.IDENTITY_SET_PHONE_COUNTRY;
  payload: string;
}

export interface IResetPhoneCode {
  type: Type.IDENTITY_RESET_PHONE_CODE;
}

export type ActionType =
  | IdentityStepInitialSet
  | IdentityStepSet
  | IdentityBlockFailed
  | IdentityBlockReset
  | IRequestUploadIdentityDocuments
  | IUploadIdentityDocumentsSuccess
  | IUploadIdentityDocumentsFailed
  | ISetIdentityDocuments
  | IdentitySubmitRequest
  | IdentitySubmitSuccess
  | IdentitySubmitFailed
  | IPhoneCodeRequest
  | IPhoneCodeSuccess
  | IPhoneCodeFailed
  | ICodeValidationRequest
  | ICodeValidationSuccess
  | ICodeValidationFailed
  | IResetPhoneErrors
  | IResetPhoneValidation
  | IdentitySetMeta
  | ISetPhoneCountry
  | IResetPhoneCode;
