import { fromJS } from 'immutable';

import { Type, ActionType } from './actionTypes';

const defaultState = fromJS({
  loading: false,
  errors: [],
  data: {},
});

export function reducer( state = defaultState, { type, payload }: ActionType) {
  switch (type) {
    case Type.DICTS_SET:
      return state
        .set('loading', false)
        .set('errors', fromJS([]))
        .set('data', fromJS(payload));
    case Type.DICTS_FAILED: {
      return state.set('loading', false).set('errors', fromJS(payload));
    }
    case Type.UNSHIFT_COUNTRY_DICT_BY_GEO_LOCATION:
     return state.updateIn(['data', 'CountryDict'], () => payload);
    default:
      return state;
  }
}
