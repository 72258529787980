import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import transform from 'lodash/transform';
import composeFp from 'lodash/fp/compose';
import sizeFp from 'lodash/fp/size';
import compactFp from 'lodash/fp/compact';
import mapFp from 'lodash/fp/map';
import valuesFp from 'lodash/fp/values';
import * as uuid from 'uuid';
import { makeSelectExternalProfiles } from 'system/Profile/selectors';
import { Field } from 'utils/types/shared/enums';
import { Block } from 'system/Stages/types';
import { SubStep } from 'system/Routing/types';
import { joinByChar } from 'utils/helpers';
import { routerSelector } from 'system/Bootstrap/selectors';
import { url } from 'utils/url';
import { dateUtils } from 'utils/date';
import { makeSelectDocuments, makeSelectDictionary } from 'system/Dicts/selectors';
import { DocumentAddressSubtypeId } from 'utils/types/IDocument';
import { selectCorporateBlock, selectCorporateBlockStep, SubStore } from '../selectors';
import { omittedStepsByRole } from '../../utils';
import { makeSelectPhoneValidation } from '../generalInformation/generalInformation';
import { FileType } from '../../../Identity/selectors';
import { UserRole } from '../../../../utils/types/IPersonalInfo';

const makeSelectProfile = () =>
  createSelector(makeSelectExternalProfiles(), (profile) => {
    return profile.has(0) ? profile.get(0) : Map();
  });

export const makeSelectSelectPersonalBlockCacheRoles = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get('roles', List()) || List();
  });

export const makeSelectSelectPersonalBlockAllSubmittedRoles = () =>
  createSelector(makeSelectSelectPersonalBlockCacheRoles(), (state: Map<string, any>) => {
    return state
      .map((role: Map<string, any>) => role.get('userRole'))
      .flatten()
      .toSet()
      .toList();
  });

export const makeSelectSelectPersonalBlockHasCache = () =>
  createSelector(makeSelectSelectPersonalBlockCacheRoles(), (state: List<Map<string, any>>) => {
    return state && !state.isEmpty();
  });

export const makeSelectSelectRoleStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.SELECT_ROLE), Map()) || Map({});
  });

export const makeSelectUserRoles = () => {
  return createSelector(makeSelectSelectRoleStep(), (state) => {
    return state.get(Field.USER_ROLE, List());
  });
};

export const makeSelectEntryId = () => {
  return createSelector(makeSelectSelectRoleStep(), (state) => {
    return state.get(Field.ENTRY_ID, uuid.v4());
  });
};

export const makeSelectOmittedSteps = () => {
  return createSelector(makeSelectUserRoles(), (roles) => {
    const stepsByRole = omittedStepsByRole(roles);
    const selectPreview = stepsByRole && stepsByRole.length ? [SubStep.ROLE_SELECT_PREVIEW] : [];
    return [...selectPreview, ...stepsByRole];
  });
};

export const makeSelectSelectPersonalInfoMeta = (stepId: string) =>
  createSelector(
    selectCorporateBlockStep(Block.PERSONAL_INFO, SubStore.META, stepId),
    (value: any) => value
  );

export const makeSelectUserRolesMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.SELECT_ROLE)),
    (state) => {
      return state.get(Field.USER_ROLE, '');
    }
  );
};

export const makeSelectUBOStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.UBO_DOCUMENTS), Map()) || Map({});
  });

export const makeSelectUBOInterestShares = () => {
  return createSelector(makeSelectUBOStep(), (state) => {
    return state.get(Field.INTEREST_SHARES);
  });
};

export const makeSelectUBOInterestSharesMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.UBO_DOCUMENTS)),
    (state) => {
      return state.get(Field.INTEREST_SHARES, Map());
    }
  );
};

export const makeSelectUBOUploadStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.UBO_UPLOAD), Map()) || Map({});
  });

export const makeSelectProofOfInterestShares = () =>
  createSelector(makeSelectUBOUploadStep(), (state: Map<string, any>) => {
    return state.get(Field.PROOF_OF_INTEREST_SHARES, List()) || List([]);
  });

export const makeSelectFilesTokensInterestShares = () =>
  createSelector(makeSelectProofOfInterestShares(), (state: List<Map<string, any>>) => {
    return state.map((file) => file.get('fileToken', ''));
  });

export const makeSelectPositionStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.POSITION_DOCUMENTS), Map()) || Map({});
  });

export const makeSelectPosition = () => {
  return createSelector(makeSelectPositionStep(), (state) => {
    return state.get(Field.POSITION);
  });
};

export const makeSelectPositionMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.POSITION_DOCUMENTS)),
    (state) => {
      return state.get(Field.POSITION, Map());
    }
  );
};

export const makeSelectPositionDetailed = () => {
  return createSelector(makeSelectPositionStep(), (state) => {
    return state.get(Field.POSITION_OTHER_DETAILED);
  });
};

export const makeSelectPositionDetailedMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.POSITION_UPLOAD)),
    (state) => {
      return state.get(Field.POSITION_OTHER_DETAILED, Map());
    }
  );
};

export const makeSelectPositionUploadStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.POSITION_UPLOAD), Map()) || Map({});
  });

export const makeSelectProofOfPosition = () =>
  createSelector(makeSelectPositionUploadStep(), (state: Map<string, any>) => {
    return state.get(Field.PROOF_OF_POSITION, List()) || List([]);
  });
// PROOF_OF_INTEREST_SHARES

export const makeSelectFilesTokensPosition = () =>
  createSelector(makeSelectProofOfPosition(), (state: List<Map<string, any>>) => {
    return state.map((file) => file.get('fileToken', ''));
  });

export const makeSelectIdentityDocStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.IDENTITY_DOCUMENT), Map()) || Map({});
  });

export const makeSelectIdentityDocUploadStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.IDENTITY_UPLOAD), Map()) || Map({});
  });

export const makeSelectIdentityDoc = () =>
  createSelector(makeSelectIdentityDocUploadStep(), (state: Map<string, any>) => {
    return state.reduce((reduction, file, key) => {
      const newFile = file.get('0', Map()).set('id', key);
      return reduction.push(newFile);
    }, List());
  });

export const makeSelectIdentitySelfieDoc = () =>
  createSelector(makeSelectIdentityDocUploadStep(), (state: Map<string, any>) => {
    return state.get('selfie', List()) || List([]);
  });

export const makeSelectIdentitySelfieToken = () =>
  createSelector(makeSelectIdentitySelfieDoc(), (state: List<Map<string, any>>) => {
    return state.getIn(['0', 'fileToken']);
  });

export const makeSelectFilesTokensIdentity = () =>
  createSelector(makeSelectIdentityDoc(), (state: List<Map<string, any>>) => {
    return state.reduce((reduction, file) => {
      return file.get('id') !== FileType.selfie
        ? reduction.push(file.get('fileToken', ''))
        : reduction;
    }, List());
  });

export const makeSelectDocSubType = () => {
  return createSelector(routerSelector, makeSelectIdentityDocStep(), (router, state) => {
    const { source = '' } = url.parse(router.getIn(['location', 'search']));

    return state.get(Field.SUB_TYPE, source);
  });
};

export const makeSelectDocSubTypeMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.IDENTITY_DOCUMENT)),
    (state) => {
      return state.get(Field.SUB_TYPE, Map());
    }
  );
};

export const makeSelectIssuingCountry = () => {
  return createSelector([makeSelectIdentityDocStep(), routerSelector], (state, router) => {
    const { country = '' } = url.parse(router.getIn(['location', 'search']));
    return state.get(Field.ISSUING_COUNTRY, country);
  });
};

export const makeSelectIssuingCountryMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.IDENTITY_DOCUMENT)),
    (state) => {
      return state.get(Field.ISSUING_COUNTRY, Map());
    }
  );
};

export const makeSelectIdentityDocInfoStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.IDENTITY_DOC_INFO), Map()) || Map({});
  });

export const makeSelectSerialNumber = () => {
  return createSelector(makeSelectIdentityDocInfoStep(), makeSelectProfile(), (state, profile) => {
    const defaults = profile.get(Field.SERIAL_NUMBER, '');

    return state.get(Field.SERIAL_NUMBER, defaults);
  });
};

export const makeSelectSerialNumberMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.IDENTITY_DOC_INFO)),
    (state) => state.get(Field.SERIAL_NUMBER, Map())
  );
};

export const makeSelectExpireDate = () => {
  return createSelector(makeSelectIdentityDocInfoStep(), makeSelectProfile(), (state, profile) => {
    const defaults = profile.get(Field.EXPIRE_DATE, '');

    return state.get(Field.EXPIRE_DATE, defaults);
  });
};

export const makeSelectExpireDateObject = () => {
  return createSelector(makeSelectExpireDate(), (date) => dateUtils.dateStrToObj(date));
};

export const makeSelectExpireDateMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.IDENTITY_DOC_INFO)),
    (state) => state.get(Field.EXPIRE_DATE, Map())
  );
};

export const makeSelectHasExpireDate = () => {
  return createSelector(makeSelectIdentityDocInfoStep(), (state) =>
    state.get(Field.HAS_EXPIRE_DATE, true)
  );
};

export const makeSelectIssuingCountryInfo = () => {
  return createSelector(
    [makeSelectIdentityDocInfoStep(), makeSelectIssuingCountry(), routerSelector],
    (state, issuing, router) => {
      const { country = '' } = url.parse(router.getIn(['location', 'search']));
      return state.get(Field.ISSUING_COUNTRY, country || issuing);
    }
  );
};

export const makeSelectIssuingCountryInfoMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.IDENTITY_DOC_INFO)),
    (state) => {
      return state.get(Field.ISSUING_COUNTRY, Map());
    }
  );
};

export const makeSelectPersonalInformationStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.PERSONAL_INFORMATION), Map()) || Map({});
  });

export const makeSelectCitizenship = () => {
  return createSelector([makeSelectPersonalInformationStep()], (state) =>
    state.get(Field.CITIZENSHIP, '')
  );
};

export const makeSelectCitizenshipMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.PERSONAL_INFORMATION)),
    (state) => state.get(Field.CITIZENSHIP, Map())
  );
};

export const makeSelectPlaceOfBirth = () => {
  return createSelector([makeSelectPersonalInformationStep()], (state) =>
    state.get(Field.PLACE_OF_BIRTH, '')
  );
};

export const makeSelectPlaceOfBirthMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.PERSONAL_INFORMATION)),
    (state) => state.get(Field.PLACE_OF_BIRTH, Map())
  );
};

export const makeSelectSSN = () => {
  return createSelector([makeSelectPersonalInformationStep()], (state) => state.get(Field.SSN, ''));
};

export const makeSelectTINPersonal = () => {
  return createSelector([makeSelectPersonalInformationStep()], (state) => state.get(Field.TIN, ''));
};

export const makeSelectSSNMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.PERSONAL_INFORMATION)),
    (state) => state.get(Field.SSN, Map())
  );
};

export const makeSelectTINPersonalMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.PERSONAL_INFORMATION)),
    (state) => state.get(Field.TIN, Map())
  );
};

export const makeSelectFirstName = () => {
  return createSelector(
    makeSelectPersonalInformationStep(),
    makeSelectProfile(),
    (state, profile) => {
      const defaults = profile.get(Field.FIRST_NAME, '');

      return state.get(Field.FIRST_NAME, defaults);
    }
  );
};

export const makeSelectFirstNameMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.PERSONAL_INFORMATION)),
    (state) => state.get(Field.FIRST_NAME, Map())
  );
};

export const makeSelectLastName = () => {
  return createSelector(
    makeSelectPersonalInformationStep(),
    makeSelectProfile(),
    (state, profile) => {
      const defaults = profile.get(Field.LAST_NAME, '');
      return state.get(Field.LAST_NAME, defaults);
    }
  );
};

export const makeSelectLastNameMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.PERSONAL_INFORMATION)),
    (state) => state.get(Field.LAST_NAME, Map())
  );
};

export const makeSelectMiddleNameName = () => {
  return createSelector(
    makeSelectPersonalInformationStep(),
    makeSelectProfile(),
    (state, profile) => {
      const defaults = profile.get(Field.MIDDLE_NAME);

      return state.get(Field.MIDDLE_NAME, defaults);
    }
  );
};

export const makeSelectMiddleNameNameMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.PERSONAL_INFORMATION)),
    (state) => {
      return state.get(Field.MIDDLE_NAME, Map());
    }
  );
};

export const makeSelectGender = () => {
  return createSelector(
    makeSelectPersonalInformationStep(),
    makeSelectProfile(),
    (state, profile) => {
      const defaults = profile.get(Field.GENDER, '');
      return state.get(Field.GENDER, defaults);
    }
  );
};

export const makeSelectGenderMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.PERSONAL_INFORMATION)),
    (state) => state.get(Field.GENDER, Map())
  );
};

export const makeSelectDateOfBirth = () => {
  return createSelector(
    makeSelectPersonalInformationStep(),
    makeSelectProfile(),
    (state, profile) => {
      const defaults = profile.get(Field.DATE_OF_BIRTH, '');
      return state.get(Field.DATE_OF_BIRTH, defaults);
    }
  );
};

export const makeSelectDateOfBirthObject = () => {
  return createSelector(makeSelectDateOfBirth(), (date) => dateUtils.dateStrToObj(date));
};

export const makeSelectDateOfBirthMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.PERSONAL_INFORMATION)),
    (state) => state.get(Field.DATE_OF_BIRTH, '')
  );
};

export const makeSelectPersonalInformationPhone = () =>
  createSelector(makeSelectPersonalInformationStep(), (state) => state.get(Field.PHONE, Map()));

export const makeSelectPersonalInformationPhoneCode = () =>
  createSelector(makeSelectPersonalInformationPhone(), (state) => state.get(Field.PHONE_CODE, ''));

export const makeSelectPersonalInformationPhoneNumber = () =>
  createSelector(makeSelectPersonalInformationPhone(), (state) =>
    state.get(Field.PHONE_NUMBER, '')
  );

export const makeSelectPersonalInformationPhoneCountry = () =>
  createSelector(makeSelectPersonalInformationPhone(), (state) => state.get(Field.COUNTRY, ''));

// Address residence
export const makeSelectAddressResidenceStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.ADDRESS_RESIDENCE), Map()) || Map({});
  });

export const makeSelectAddressResidenceMeta = () =>
  createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.PERSONAL_INFORMATION)),
    (value: any) => value || Map({})
  );

export const makeSelectAddressResidenceCountry = () => {
  return createSelector(makeSelectAddressResidenceStep(), (state) => {
    return state.get(Field.COUNTRY, '');
  });
};

export const makeSelectAddressResidenceRegion = () => {
  return createSelector(makeSelectAddressResidenceStep(), (state) => state.get(Field.REGION, ''));
};

export const makeSelectAddressResidenceSubRegion = () => {
  return createSelector(makeSelectAddressResidenceStep(), (state) => state.get(Field.SUB_REGION));
};

export const makeSelectAddressResidenceZipCode = () => {
  return createSelector([makeSelectAddressResidenceStep()], (state) => state.get(Field.ZIP_CODE));
};

export const makeSelectAddressResidenceStreet = () => {
  return createSelector([makeSelectAddressResidenceStep()], (state) => {
    return state.get(Field.STREET);
  });
};

export const makeSelectAddressResidenceCity = () => {
  return createSelector([makeSelectAddressResidenceStep()], (state) => state.get(Field.CITY));
};

export const makeSelectAddressResidenceAptSuite = () => {
  return createSelector([makeSelectAddressResidenceStep()], (state) => state.get(Field.APT_SUITE));
};

export const makeSelectAddressDocStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.ADDRESS_DOCUMENTS), Map()) || Map({});
  });

// Meta
export const makeSelectAddressResidenceCountryMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.ADDRESS_RESIDENCE)),
    (state) => {
      return state.get(Field.COUNTRY, Map());
    }
  );
};

export const makeSelectAddressResidenceRegionMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.ADDRESS_RESIDENCE)),
    (state) => state.get(Field.REGION, Map())
  );
};

export const makeSelectAddressResidenceSubRegionMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.ADDRESS_RESIDENCE)),
    (state) => state.get(Field.SUB_REGION, Map())
  );
};

export const makeSelectAddressResidenceZipCodeMeta = () => {
  return createSelector(
    [makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.ADDRESS_RESIDENCE))],
    (state) => state.get(Field.ZIP_CODE, Map())
  );
};

export const makeSelectAddressResidenceStreetMeta = () => {
  return createSelector(
    [makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.ADDRESS_RESIDENCE))],
    (state) => {
      return state.get(Field.STREET, Map());
    }
  );
};

export const makeSelectAddressResidenceCityMeta = () => {
  return createSelector(
    [makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.ADDRESS_RESIDENCE))],
    (state) => state.get(Field.CITY, Map())
  );
};

export const makeSelectAddressResidenceAptSuiteMeta = () => {
  return createSelector(
    [makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.ADDRESS_RESIDENCE))],
    (state) => state.get(Field.APT_SUITE, Map())
  );
};

// Address Documents
export const makeSelectAddressDocumentsStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.ADDRESS_DOCUMENTS), Map()) || Map({});
  });

export const makeSelectAddressDocumentsUploadStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.ADDRESS_UPLOAD), Map()) || Map({});
  });

export const makeSelectAddressDoc = () =>
  createSelector(makeSelectAddressDocumentsUploadStep(), (state: Map<string, any>) => {
    return state.get('address', List()) || List();
  });

export const makeSelectFilesTokensAddress = () =>
  createSelector(makeSelectAddressDoc(), (state: List<Map<string, any>>) => {
    return state.map((file) => file.get('fileToken', ''));
  });

export const makeSelectAddressDocSubType = () => {
  return createSelector(routerSelector, makeSelectAddressDocumentsStep(), (router, state) => {
    const { source = '' } = url.parse(router.getIn(['location', 'search']));
    return state.get(Field.SUB_TYPE, source);
  });
};

export const makeSelectAddressDocSubTypeMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.ADDRESS_DOCUMENTS)),
    (state) => state.get(Field.SUB_TYPE, Map())
  );
};

export const makeSelectAddressDocumentDetails = () => {
  return createSelector(makeSelectAddressDocumentsStep(), (state) =>
    state.get(Field.SUB_TYPE_DETAILED, '')
  );
};
export const makeSelectAddressDocumentDetailsMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.ADDRESS_DOCUMENTS)),
    (state) => state.get(Field.SUB_TYPE_DETAILED, Map())
  );
};

export const makeSelectDocumentSubtypeName = () =>
  createSelector(
    makeSelectDocuments('DocumentAddressSubtypeDict'),
    (subtypeDict) => (id: DocumentAddressSubtypeId) => {
      return subtypeDict
        .find((document: any) => document.get('id') === id, null, List())
        .get('name', '');
    }
  );

export const makeSelectSocialProfilesValues = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.SOCIAL_PROFILES), List()) || List([]);
  });

// Questionnaire
export const makeSelectQuestionnaireStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.FINANCIAL_QUESTIONNAIRE), Map()) || Map({});
  });

export const makeSelectIsPep = () =>
  createSelector(makeSelectQuestionnaireStep(), (state) => state.get(Field.IS_PEP));

export const makeSelectIsPepMeta = () =>
  createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.FINANCIAL_QUESTIONNAIRE)),
    (state) => state.get(Field.IS_PEP, Map())
  );

export const makeSelectPepPosition = () =>
  createSelector(makeSelectQuestionnaireStep(), (state) => state.get(Field.PEP_POSITION));

export const makeSelectPepPositionMeta = () =>
  createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.FINANCIAL_QUESTIONNAIRE)),
    (state) => state.get(Field.PEP_POSITION)
  );

export const makeSelectHasPepRelatives = () =>
  createSelector(makeSelectQuestionnaireStep(), (state) => state.get(Field.HAS_PEP_RELATIVES));

export const makeSelectHasPepRelativesMeta = () =>
  createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.FINANCIAL_QUESTIONNAIRE)),
    (state) => state.get(Field.HAS_PEP_RELATIVES, Map())
  );

export const makeSelectPepRelativesDetailed = () =>
  createSelector(makeSelectQuestionnaireStep(), (state) => state.get(Field.PEP_RELATIVES_DETAILED));

export const makeSelectPepRelativesDetailedMeta = () =>
  createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.FINANCIAL_QUESTIONNAIRE)),
    (state) => state.get(Field.PEP_RELATIVES_DETAILED, Map())
  );

export const makeSelectHasPepCloseAssociate = () =>
  createSelector(makeSelectQuestionnaireStep(), (state) => state.get(Field.PEP_CLOSE_ASSOCIATE));

export const makeSelectHasPepCloseAssociateMeta = () =>
  createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.FINANCIAL_QUESTIONNAIRE)),
    (state) => state.get(Field.PEP_CLOSE_ASSOCIATE, Map())
  );

export const makeSelectPepCloseAssociateDetailed = () =>
  createSelector(makeSelectQuestionnaireStep(), (state) =>
    state.get(Field.PEP_CLOSE_ASSOCIATE_DETAILED)
  );

export const makeSelectPepCloseAssociateDetailedMeta = () =>
  createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.FINANCIAL_QUESTIONNAIRE)),
    (state) => state.get(Field.PEP_CLOSE_ASSOCIATE_DETAILED, Map())
  );

export const makeSelectIsUsCitizen = () =>
  createSelector(makeSelectQuestionnaireStep(), (state) => state.get(Field.US_CITIZEN));

export const makeSelectIsUsCitizenMeta = () =>
  createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.FINANCIAL_QUESTIONNAIRE)),
    (state) => state.get(Field.US_CITIZEN, Map())
  );

export const makeSelectIsUsTaxpayer = () =>
  createSelector(makeSelectQuestionnaireStep(), (state) => state.get(Field.US_TAXPAYER));

export const makeSelectIsUsTaxpayerMeta = () =>
  createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.FINANCIAL_QUESTIONNAIRE)),
    (state) => state.get(Field.US_TAXPAYER, Map())
  );

export const makeSelectIsUsResidentAlien = () =>
  createSelector(makeSelectQuestionnaireStep(), (state) => state.get(Field.US_RESIDENT_ALIEN));

export const makeSelectIsUsResidentAlienMeta = () =>
  createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.FINANCIAL_QUESTIONNAIRE)),
    (state) => state.get(Field.US_RESIDENT_ALIEN, Map())
  );

// Phone
export const makeSelectPhoneStep = () =>
  createSelector(selectCorporateBlock(Block.PERSONAL_INFO), (state: Map<string, any>) => {
    return state.get(joinByChar(SubStep.PHONE_NUMBER), Map()) || Map({});
  });

export const makeSelectPersonalPhoneMeta = () => {
  return createSelector(
    makeSelectSelectPersonalInfoMeta(joinByChar(SubStep.PHONE_NUMBER)),
    (state) => state.get(Field.PHONE, Map()) || Map({})
  );
};

export const makeSelectPersonalInfoPhone = () => {
  return createSelector(makeSelectPhoneStep(), (state) => state.get(Field.PHONE, Map()));
};

export const makeSelectPersonalInfoPhoneCode = () => {
  return createSelector(makeSelectPersonalInfoPhone(), (state) => state.get(Field.PHONE_CODE, ''));
};

export const makeSelectPersonalInfoPhoneNumber = () => {
  return createSelector(makeSelectPersonalInfoPhone(), (state) =>
    state.get(Field.PHONE_NUMBER, '')
  );
};

export const makeSelectPersonalInfoPhoneCountry = () => {
  return createSelector(makeSelectPersonalInfoPhone(), (state) => state.get(Field.COUNTRY, ''));
};

export const makeSelectPersonalInfoPhoneIsValidated = () =>
  createSelector(makeSelectPhoneValidation(), (state) => state.get(Field.PHONE_IS_VALIDATED));

export const makeSelectValidationPersonalPhoneCode = () =>
  createSelector(
    [makeSelectPhoneValidation(), makeSelectPersonalInfoPhoneCode()],
    (phoneState, code) => phoneState.getIn(['phone', 'code'], code)
  );

export const makeSelectValidationPersonalPhoneNumber = () =>
  createSelector(
    [makeSelectPhoneValidation(), makeSelectPersonalInfoPhoneNumber()],
    (phoneState, number) => phoneState.getIn(['phone', 'number'], number)
  );

export const makeSelectValidationPersonalPhoneCountry = () =>
  createSelector(
    [makeSelectPhoneValidation(), makeSelectPersonalInfoPhoneCountry()],
    (phoneState, country) => phoneState.getIn(['phone', 'country'], country)
  );

export const makeSelectPhoneValidationCountry = () =>
  createSelector(makeSelectPhoneValidation(), (state: any) =>
    state.getIn(['phone', 'country'], '')
  );

export const makeSelectValidationMaxQuantity = () =>
  createSelector(makeSelectSelectPersonalBlockCacheRoles(), (roles: List<Map<string, any>>) => {
    const transformValue = (
      result: { [key: string]: boolean },
      value: Array<number>,
      key: string
    ) => {
      if (key === UserRole.AUTHORIZED_PERSON) {
        result[key] = value.length === 1;
      } else {
        result[key] = value.length === 5;
      }
    };

    return transform(groupBy(map(roles.toJS(), 'userRole').flat()), transformValue, {});
  });

export const makeSelectNotSelectedMaxQuantityRoles = () =>
  createSelector(makeSelectValidationMaxQuantity(), (state: { [key: string]: boolean }) => {
    const newState = {
      0: false,
      1: false,
      2: false,
      ...state,
    };

    return composeFp(
      Boolean,
      sizeFp,
      compactFp,
      mapFp((v) => !v),
      valuesFp
    )(newState);
  });

export const makeSelectSourceOfFundsData = () => {
  return createSelector(selectCorporateBlock('personalInfo'), (state: Map<string, any>) => {
    return state.get('uboSourceOfFunds', Map());
  });
};

export const makeSelectAllMonthlyIncome = () => {
  return createSelector(makeSelectDictionary('MonthlyIncomeDict'), (dictionary) => {
    return dictionary;
  });
};

export const makeSelectAllSourceOfFunds = () => {
  return createSelector(makeSelectDictionary('SourceOfFiatFundsDict'), (dictionary) => {
    return dictionary;
  });
};

export const makeSelectMonthlyIncome = () => {
  return createSelector(makeSelectSourceOfFundsData(), (state) => {
    return state.get('monthlyIncome', '');
  });
};

export const makeSelectSourceOfFunds = () => {
  return createSelector(makeSelectSourceOfFundsData(), (state) => {
    return state.getIn(['detailedSources', 'source'], '');
  });
};

export const makeSelectSourceOfFundsDetailed = () => {
  return createSelector(makeSelectSourceOfFundsData(), (state) => {
    return state.getIn(['detailedSources', 'detailed'], '');
  });
};

export const makeSelectImages = () => {
  return createSelector(makeSelectSourceOfFundsData(), (state) => {
    return state.get('images', List())?.toJS?.();
  });
};

export const makeSelectSourceOfFiatFunds = () => {
  return createSelector(
    makeSelectSourceOfFundsData(),
    makeSelectMonthlyIncome(),
    makeSelectSourceOfFunds(),
    makeSelectSourceOfFundsDetailed(),
    makeSelectImages(),
    (data, monthlyIncome, source, detailed, images) => {
      if (data.isEmpty()) return null;

      return {
        monthlyIncome,
        detailedSources: [
          {
            source,
            ...(detailed && { detailed }),
            images: images.map(({ fileToken }: any) => fileToken),
          },
        ],
      };
    }
  );
};
