import { List, Map } from 'immutable';
import React, { ComponentType, useContext } from 'react';
import Select, {
  components as Reselect,
  Styles,
} from 'react-select';

import { Flag } from 'components/common/Flag';
import { withLocalization, WithLocalizationProps } from 'system/Localization';
import { ThemeContext } from 'styled-components';
import { coordinates } from 'components/common/Flag/coordinates';
import { toJS } from 'utils/helpers';
import { ConfigContext } from 'merchants';
import { IndicatorsContainer } from './Dropdown';
import { GeoHighlightTip } from './GeoHighlightTip';
import { adaptPhoneCodeOptions, getPhoneDropdownStyles } from './utils/helpers';
import { OnChangeType } from './index';

const Option: ComponentType<any> = (
  props
) => {
  const {
    value,
    data: {
      data: { countryId, countryName, cdn },
    },
  } = props;
  return (
    <Reselect.Option {...props}>
      <Flag countryCode={countryId} cdn={cdn} />
      {countryName}
      <GeoHighlightTip> +{value}</GeoHighlightTip>
    </Reselect.Option>
  );
};

interface DropdownWithPhoneProps {
  dictionary?: List<Map<string, any>>;
  onChange: OnChangeType<any>;
  code: string;
  country: string;
  disabled: boolean;
  valid: boolean;
  error?: boolean;
  maxMenuHeight?: number;
}

export const DropdownWithPhone = withLocalization<
  WithLocalizationProps<DropdownWithPhoneProps>
>(
  ({
    dictionary = List([]),
    valid,
    code,
    country,
    disabled,
    translate,
    onChange,
    error = false,
    maxMenuHeight = 300,
  }) => {
    const {
      appConfig: { cdn },
    } = useContext(ConfigContext);

    const {
      forms: { dropdown },
    } = useContext(ThemeContext);

    const options = adaptPhoneCodeOptions(toJS(dictionary), cdn);

    const backgroundPosition = country
      ? coordinates[country.toLowerCase()]
      : '';
    const styles: Styles = getPhoneDropdownStyles(
      { ...dropdown, backgroundPosition, cdn },
      { error, valid }
    );
    return (
      <Select
        onChange={onChange}
        className="dropdown-select"
        isDisabled={disabled}
        noOptionsMessage={() => `${translate('no_results')}`}
        options={options}
        styles={styles}
        value={{
          label: code,
          value: code,
        }}
        placeholder=""
        cdn={cdn}
        components={{
          Option,
          IndicatorsContainer,
        }}
        maxMenuHeight={maxMenuHeight}
        menuPlacement="auto"
      />
    );
  }
);
