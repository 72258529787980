import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { Routes } from 'system/Routing/Routes';
import { Stub } from 'system/Routing/types';

import {
  ApproveStub,
  Correction,
  ErrorMessage,
  PendingStub,
  QrConnectedStub,
  QrSubmittedStub,
  QuestionnaireNotice,
  RedStateStub,
  RejectedDossier,
  SubmittedStub,
  TryAgainStub,
  StatusStub,
} from 'containers/Stubs';
import { NotFoundStub } from 'containers/Routes/NotFoundRoute';
import { RedirectToIndex } from 'containers/RedirectToIndex';

function StubsSwitch() {
  return (
    <Switch>
      <Route
        path={Routes.stub.one({ stubId: Stub.CORRECTION })}
        render={(props: any) => <Correction {...props} />}
      />
      <Route
        path={Routes.stub.one({ stubId: Stub.SUBMITTED })}
        render={(props: any) => <SubmittedStub {...props} />}
      />
      <Route
        path={Routes.stub.one({ stubId: Stub.REJECTED })}
        render={(props: any) => <RejectedDossier {...props} />}
      />
      <Route
        path={Routes.stub.one({ stubId: Stub.PENDING })}
        render={(props: any) => <PendingStub {...props} />}
      />
      <Route
        path={Routes.stub.one({ stubId: Stub.APPROVE })}
        render={(props: any) => <ApproveStub {...props} />}
      />
      <Route
        path={Routes.stub.one({ stubId: Stub.RETRY })}
        render={(props: any) => <TryAgainStub {...props} />}
      />

      <Route
        path={Routes.stub.one({ stubId: Stub.REDSTATE })}
        render={(props: any) => <RedStateStub {...props} />}
      />
      <Route
        path={Routes.stub.one({ stubId: Stub.ERROR })}
        render={(props: any) => <ErrorMessage {...props} />}
      />
      <Route
        path={Routes.stub.one({ stubId: Stub.QR_CONNECTED })}
        render={(props: any) => <QrConnectedStub {...props} />}
      />
      <Route
        path={Routes.stub.one({ stubId: Stub.QR_SUBMITTED })}
        render={(props: any) => <QrSubmittedStub {...props} />}
      />
      <Route
        path={Routes.stub.one({ stubId: Stub.NOTICE })}
        render={(props: any) => <QuestionnaireNotice {...props} />}
      />
      <Route
        path={Routes.status('accepted|locked')}
        render={(props: any) => <StatusStub {...props} />}
      />
      <Route
        path={Routes.stub.one({ stubId: Stub.NEXT })}
        render={RedirectToIndex}
      />
      <Route path="*" component={NotFoundStub} />
    </Switch>
  );
}

StubsSwitch.displayName = 'Stubs';

export const Stubs = connect(null, null)(StubsSwitch);
