import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ILocalizationProps, withLocalization } from 'system/Localization';
import { navigateIndex } from 'system/Routing/actions';

import { CommonStub } from 'containers/Stubs/CommonStub';
import {
  BUTTON_TYPE,
  ICommonStubProps,
} from 'containers/Stubs/CommonStub/types';

import {
  getCountdown,
  getTimeCountMessage,
} from 'components/common/StagePreview/utils/helpers';
import { Stub } from 'components/UI/Icon';

interface IRejectedDossier extends ILocalizationProps {
  dateOfNextVerification: number;
  handleExit: () => void;
  softRefuseReason: string | null;
}

const RejectedDossierContainer = (
  withLocalization<IRejectedDossier>(
    ({
      translate,
      dateOfNextVerification: nextDate,
      handleExit,
      softRefuseReason,
    }: IRejectedDossier) => {
      const timeParam: string | React.ReactNode = getTimeCountMessage(
        translate,
        getCountdown(nextDate)
      );

      const defaultMessage = timeParam
        ? translate('different_verification_message', '', timeParam)
        : translate('different_verification_message_without_time');

      const softRefuseTime = timeParam
        ? translate('verification_message_time', '', timeParam)
        : '';

      const message = softRefuseReason
        ? [translate(softRefuseReason, ''), `\n ${softRefuseTime}`]
        : defaultMessage;

      const contentProps: ICommonStubProps = {
        stageTitle: translate('screen_title_stages'),
        buttons: [
          {
            clickHandler: handleExit,
            text: translate('exit_verification'),
            mods: { type: BUTTON_TYPE.primary },
          },
        ],
        body: {
          Icon: Stub.Rejected,
          title: translate('screen_title_verification_error'),
          message,
        },
      };

      return <CommonStub {...contentProps} />;
    }
  )
);


const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  handleExit: () => {
    dispatch(navigateIndex({ exit: true }));
  },
});

export const RejectedDossier = connect(
  null,
  mapDispatchToProps
)(RejectedDossierContainer);
