export enum Type {
  DOSSIER_STEP_SUBMIT = '@Dossier/DOSSIER_STEP_SUBMIT',
  FILL_USER_DOSSIER_FINISHED = '@Dossier/FILL_USER_DOSSIER_FINISHED',
}

export interface ISetDossierStepSubmit {
  type: Type.DOSSIER_STEP_SUBMIT;
  payload: { fields: any };
}

export interface IFillFinished {
  type: Type.FILL_USER_DOSSIER_FINISHED;
  payload: {};
}
