import React, { FC } from 'react';
import styled from 'styled-components';

import { RemoveButton } from 'components/common/RemoveButton';
import { Input } from './Input';

interface InputWithRemoveProps {
  onClick: () => void;
}

export const InputWithRemove: FC<InputWithRemoveProps> = ({
  onClick,
  ...props
}) => {
  return (
    <InputContainer>
      <InputRemoved {...props} />
      <RemoveButton onClick={onClick} />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  position: relative;

  > button {
    position: absolute;
    right: 13px;
    bottom: 0;
    top: 1px;
  }
`;

const InputRemoved = styled(Input)`
  padding-right: 30px;
`;
