import { List, Map } from 'immutable';
import { IRecognizedResult } from 'utils/externalProfiles/types';
import { ResultField } from 'utils/externalProfiles/enums';
import { MetaSource } from 'utils/types/shared/enums';
import { IMeta } from 'utils/types/shared/types';

export enum MetaField {
  SOURCE = 'source',
  IS_DISABLED = 'isDisabled'
}

const getSourceMeta = (field: Map<any, any>) => {
  const recognizeType = field.get(ResultField.TYPE, MetaSource.MANUAL);
  const trusted = field.get(ResultField.TRUSTED, false);

  return Map<IMeta>({ [MetaField.SOURCE]: recognizeType, [MetaField.IS_DISABLED]: trusted });
};

export const updateSourceMeta = ({
  externalProfiles = List(),
  correctionMistakes = List(),
}) => (
  meta: Map<string, IMeta>,
  value: string,
  key: string,
  fields: Map<keyof IRecognizedResult, any>
) => {
  const source = meta.getIn([key, 'source']);
  const valueInProfile = externalProfiles
    .filter((profile: Map<string, any>) => profile.get('type') === source)
    .first();

  // If pre filled field has been edited and after that user revert changes
  // then set source type from Profile[microblink].
  if (source === MetaSource.MANUAL) {
    const profileMeta = externalProfiles.findLast(
      (side: any) => side.get(key) === value,
      null,
      Map()
    );

    if (profileMeta.get(key) === value) {
      return meta.merge(Map({ [key]: getSourceMeta(profileMeta) }));
    }
  }

  if (valueInProfile && valueInProfile.get(key) === value) {
    return meta.merge(Map({ [key]: getSourceMeta(valueInProfile) }));
  }

  if (correctionMistakes.size) {
    const withoutMistakes = fields
      .filter((_: any, name: string) => {
        return !correctionMistakes.includes(name);
      })
      .merge(
        Map({
          [ResultField.TRUSTED]: true,
          [ResultField.TYPE]: '',
        })
      );

    if (withoutMistakes.has(key)) {
      return meta.merge(Map({ [key]: getSourceMeta(withoutMistakes) }));
    }
  }

  return meta.merge(Map({ [key]: getSourceMeta(fields) }));
};

export const toFlatIdentityDocs = (docs: File[]) => {
  return Object.entries(docs).reduce((acc: any[], [id, file]) => {
    return file ? [...acc, { file, id }] : acc;
  }, []);
};

export const base64ToBlob = async (base64: string) => {
  const image = `data:image/jpeg;base64,${base64}`;

  const response = await fetch(image);

  const blob = await response.blob();

  return blob;
};
