import React, { FC, useContext } from 'react';
import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Eye: FC<IconProps> = ({
  width = 23,
  height = 12,
}) => {
  const {
    icons: {
      markup: {
        eye: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g fill={fill} fillRule="nonzero">
        <path d="M23.143 5.735C22.935 5.5 17.929 0 11.658 0 5.388 0 .382 5.5.174 5.735a.692.692 0 0 0 0 .918c.208.233 5.214 5.734 11.484 5.734s11.277-5.5 11.485-5.734a.692.692 0 0 0 0-.918zM11.658 11.01c-2.636 0-4.781-2.162-4.781-4.817 0-2.656 2.145-4.818 4.781-4.818 2.637 0 4.782 2.162 4.782 4.818 0 2.655-2.145 4.817-4.782 4.817z" />
        <path d="M11.984 4.882c0-.66.33-1.24.833-1.597a3.287 3.287 0 0 0-1.495-.37c-1.823 0-3.307 1.47-3.307 3.279C8.015 8 9.5 9.472 11.322 9.472a3.298 3.298 0 0 0 3.251-2.726 1.97 1.97 0 0 1-2.59-1.864z" />
      </g>
    </svg>

  )
};

