import { ITrackedEvent, ITrackedEventCB } from './evenTypes';

export class TrackEventsEmitter {
  private static instance: TrackEventsEmitter;

  private static userCallBack: ITrackedEventCB;

  private constructor(cb?: ITrackedEventCB) {
    if (cb) {
      TrackEventsEmitter.userCallBack = cb;
    }
  }

  static getInstance(cb?: ITrackedEventCB): TrackEventsEmitter {
    if (!TrackEventsEmitter.instance) {
      TrackEventsEmitter.instance = new TrackEventsEmitter(cb);
    }

    return TrackEventsEmitter.instance;
  }

  trackEvents = (type: ITrackedEvent, payload: any) => {
    TrackEventsEmitter.userCallBack(type, payload);
  };

  hasUserCallback = () => {
    return typeof TrackEventsEmitter.userCallBack === 'function';
  };
}
