import {
  ChangeEvent,
  DragEvent,
  SyntheticEvent,
  createRef,
  useState,
  useEffect,
} from 'react';
import shortid from 'shortid';

import {
  IFilesHandlerChildrenProps,
  IFilesHandlerProps,
  DroppedFile,
} from './types';
import {
  fileListToArray,
  fileValidate as defaultFileValidate,
  stopEvent,
} from './utils';

export const defaultMaxFileSize = 31457280; // 30mb

export const FilesHandler = ({
  files: initialFiles = [],
  accept = [],
  children,
  maxFiles = 1,
  maxFileSize = defaultMaxFileSize,
  minFileSize = 1,
  multiple: allowMultipleFiles = false,
  fileValidate = defaultFileValidate,
  onChange,
}: IFilesHandlerProps) => {
  const multiple = allowMultipleFiles || maxFiles > 1;
  const inputFileRef = createRef<HTMLInputElement>();

  const [dragging, setDragging] = useState<boolean>(false);
  const [files, setFiles] = useState<DroppedFile[]>([]);

  const limit = (fileList: File[]): File[] => {
    return fileList.slice(0, maxFiles);
  };

  const validate = async (fileList: File[]) => {
    return fileList.reduce(async (acc, file: File | any) => {
      const prev = await acc;
      const isUploaded = !!file.fileToken;
      const currentFile = isUploaded ? file.file : file;
      const error = await fileValidate(currentFile, {
        accept,
        maxFileSize,
        minFileSize,
      });
      return [
        ...prev,
        {
          file: currentFile,
          error,
          ...(isUploaded ? { fileToken: file.fileToken } : {}),
        },
      ];
    }, [] as any);
  };

  const upload = async (fileList: any[] | FileList | null) => {
    const limitFiles = fileListToArray(fileList).slice(0, maxFiles - files.length)
    const uploadedFiles = await validate(limit(limitFiles));

    const allFilesWithId = uploadedFiles.map((uploadFile: File) => ({
      id: shortid.generate(),
      ...uploadFile,
    }));

    setFiles([...files, ...allFilesWithId]);
  };

  const handleUploadFiles = async (evt: ChangeEvent<HTMLInputElement>) => {
    stopEvent(evt);

    const {
      target: { files: allFiles },
    } = evt;

    await upload(allFiles);

    setDragging(false);
  };

  const handleRemoveFiles = (position?: number): void => {
    if (typeof position === 'number') {
      setFiles(files.filter((_: DroppedFile, index) => position !== index));
    } else {
      setFiles([]);
    }
  };

  const handleOpenDialog = (evt: SyntheticEvent<Element>): void => {
    stopEvent(evt);

    if (inputFileRef && inputFileRef.current) {
      inputFileRef.current.value = '';
      inputFileRef.current.click();
    }
  };

  const handleDragOver = (evt: DragEvent<Element>): void => {
    stopEvent(evt);

    evt.persist();

    setDragging(true);
  };

  const handleDragLeave = (evt: DragEvent<Element>): void => {
    stopEvent(evt);

    setDragging(false);
  };

  const handleDrop = async (evt: DragEvent<Element>) => {
    stopEvent(evt);

    const {
      dataTransfer: { files: allFiles },
    } = evt;

    await upload(allFiles);

    setDragging(false);
  };

  useEffect(() => {
    if (Array.isArray(initialFiles)) {
      upload(initialFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onChange) onChange(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files.length]);

  const props: IFilesHandlerChildrenProps = {
    accept: accept.join(','),
    dragging,
    // errors,
    inputFileRef,
    files,
    multiple,
    handleUploadFiles,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleOpenDialog,
    handleRemoveFiles,
  };

  return children(props);
};
