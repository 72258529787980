import { call, put, select, takeLatest } from 'redux-saga/effects';
import { List } from 'immutable';

import flatten from 'lodash/flatten';
import camelCase from 'lodash/camelCase';
import pick from 'lodash/pick';
import isFunction from 'lodash/isFunction';

import {
  baseUploadFileRequest,
  IUploadResult,
  requestWltfsToken,
} from 'system/Dossier/Upload/sagas';
import {
  qrCodeCompleteFileUpload,
} from 'system/Dossier/Upload/sagas/qr';
import { makeSelectExternalProfile } from 'system/Profile/selectors';
import { setRecognizerUserProfile } from 'system/Profile/sagas/externalProfiles';
import { makeSelectRouterSearchUploadId } from 'system/Bootstrap/selectors';
import { navigateToErrorLayout } from 'system/Routing/sagas';
import { Stage } from 'system/Stages/types';
import { Step } from 'system/Routing/types';

import {
  makeSelectDateOfBirth,
  makeSelectDocSubType,
  makeSelectFilesTokens,
  makeSelectFirstName,
  makeSelectGender,
  makeSelectIssuingCountry,
  makeSelectLastName,
  makeSelectNationality,
  makeSelectSelfieToken,
  makeSelectStepMeta,
  makeSelectUploadMistakes,
} from 'stages/Identity/selectors';
import { makeSelectApiUrl } from 'system/Settings/selectors';
import { IdentityStepInitialSet, IdentityStepSet, IRequestUploadIdentityDocuments, Type } from 'stages/Identity/actionTypes';

import { generateFileId, joinByChar } from 'utils/helpers';
import { ExternalSource, ResultField } from 'utils/externalProfiles/enums';
import { Field } from 'utils/types/shared/enums';
import { makeErrorMessage } from 'utils/error';

import { jsonRPC } from 'utils/request';
import { getFileNamesArray } from 'system/Dossier/Upload/utils';
import { PHOTO_MODE } from 'utils/types/IDocument';
import {
  setIdentityDocuments,
  setIdentityInitialStep,
  setIdentityMeta,
  successUploadIdentityDocuments,
  failedUploadIdentityDocuments,
} from '../actions';

import { watchForSubmit } from './submit';
import {
  watchForPhoneCodeRequest,
} from './phone';
import { toFlatIdentityDocs } from './utils';

function* handleSetIdentityDocuments(result: IUploadResult[]) {
  yield put(setIdentityDocuments(result));
}

function* handleClearDocumentsInfo({ source }: { source: string }) {
  const issuingCountry = yield select(makeSelectIssuingCountry());
  const nationality = yield select(makeSelectNationality());
  const firstName = yield select(makeSelectFirstName());
  const lastName = yield select(makeSelectLastName());
  const dateOfBirth = yield select(makeSelectDateOfBirth());
  const gender = yield select(makeSelectGender());

  const defaultPersonalInfo = {
    [Field.NATIONALITY]: nationality,
    [Field.FIRST_NAME]: firstName,
    [Field.LAST_NAME]: lastName,
    [Field.MIDDLE_NAME]: '',
    [Field.GENDER]: gender,
    [Field.DATE_OF_BIRTH]: dateOfBirth,
    [ResultField.TYPE]: '',
    [ResultField.TRUSTED]: false,
  };

  const defaultDocInfo = {
    [Field.SERIAL_NUMBER]: '',
    [Field.ISSUING_COUNTRY]: issuingCountry,
    [Field.EXPIRE_DATE]: '',
    [ResultField.TYPE]: '',
    [ResultField.TRUSTED]: false,
  };

  yield put(
    setIdentityInitialStep({
      stepId: joinByChar('personal-information'),
      fields: defaultPersonalInfo,
    })
  );

  yield put(
    setIdentityInitialStep({
      stepId: joinByChar(`${source}-information`),
      fields: defaultDocInfo,
    })
  );
}

function* recognizeDocuments({
  result,
  subType,
  country,
  stageId,
  blockId
}: {
  result: IUploadResult[];
  stageId?: string;
  subType: string;
  country: string;
  blockId?: string;
}) {
  const api = yield select(makeSelectApiUrl());
  const images = result.reduce((acc: string[], { id, fileToken = '' }: IUploadResult) => {
    if (id === PHOTO_MODE.SELFIE) {
      return acc;
    }
    return acc.concat(fileToken);
  }, []);

  return yield call(jsonRPC, {
    namespace: 'va',
    method: 'recognizeDocuments',
    params: {
      stageId,
      blockId,
      identityDocument: {
        subType,
        issuingCountry: country || undefined,
        images,
      },
    },
    api,
  });
}

function* updateResultWithMistakes(result: IUploadResult[], mistakes: List<string>) {
  const filesTokens = yield select(makeSelectFilesTokens());
  const selfieToken = yield select(makeSelectSelfieToken());

  if (mistakes.includes(Field.SELFIE_IMAGE)) {
    return [
      ...result,
      ...filesTokens.toJS().map((token: string) => ({ fileToken: token })),
    ];
  }
  if (mistakes.includes(Field.IMAGES)) {
    return [...result, { id: 'selfie', fileToken: selfieToken }];
  }

  return result;
}

export function* handleUploadResult({ result, source, next }: {  result: IUploadResult[]; source: string; next?: () => void; }) {
  try {
    const uploadMistakes = yield select(makeSelectUploadMistakes());
    const country = yield select(makeSelectIssuingCountry());
    const fileNames = getFileNamesArray(result);

    if (!uploadMistakes.size || uploadMistakes.includes(Field.IMAGES)) {
      const { data } = yield call(recognizeDocuments, {
        result,
        subType: source,
        country,
        stageId: Stage.IDENTITY,
        blockId: camelCase(Step.IDENTITY_DOCUMENT),
      });

      if (data) {
        yield call(setRecognizerUserProfile, { data, fileNames });
      }

      yield call(handleClearDocumentsInfo, { source });
    }

    const externalProfiles = yield select(
      makeSelectExternalProfile(ExternalSource.BACKEND)
    );

    const profiles = Object.values(externalProfiles.toJS());

    for (let i = 0; i < profiles.length; i += 1) {
      const profile = profiles[i];

      yield put(
        setIdentityInitialStep({
          stepId: joinByChar('personal-information'),
          fields: pick(profile, [
            Field.NATIONALITY,
            Field.FIRST_NAME,
            Field.LAST_NAME,
            Field.MIDDLE_NAME,
            Field.GENDER,
            Field.DATE_OF_BIRTH,
            ResultField.TYPE,
            ResultField.TRUSTED,
          ]),
        })
      );

      yield put(
        setIdentityInitialStep({
          stepId: joinByChar(`${source}-information`),
          fields: pick(profile, [
            Field.SERIAL_NUMBER,
            Field.ISSUING_COUNTRY,
            Field.EXPIRE_DATE,
            ResultField.TYPE,
            ResultField.TRUSTED,
          ]),
        })
      );
    }

    const uploadResult = uploadMistakes.size
      ? yield call(updateResultWithMistakes, result, uploadMistakes)
      : result;

    yield call(handleSetIdentityDocuments, uploadResult);
    
    if (isFunction(next)) {
      yield call(next)
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchForUploadIdentityDocumentsRequest(action: IRequestUploadIdentityDocuments) {
  try {
    const {
      payload: { files: identityDocs, onAfterUpload },
    } = action;

    yield call(requestWltfsToken);

    const uploadId = yield select(makeSelectRouterSearchUploadId());

    const source = yield select(makeSelectDocSubType());

    const files = toFlatIdentityDocs(identityDocs);

    const uploadedData = yield call(baseUploadFileRequest, files);

    const result = flatten<IUploadResult>(uploadedData);

    if (uploadId) {
      const fileTokens = result.map(({ ...rest }) => ({
        source,
        fileId: generateFileId(),
        ...rest,
      }));

      yield call(qrCodeCompleteFileUpload, { uploadId, fileTokens });
    }

    if (isFunction(onAfterUpload)) {
      yield call(onAfterUpload, { result, source });
    } else {
      yield call(handleUploadResult, { result, source });
    }

    yield put(successUploadIdentityDocuments());
  } catch (err) {
    yield put(failedUploadIdentityDocuments([makeErrorMessage(err)]));
    yield call(navigateToErrorLayout);
  }
}

function* watchForIdentityStepSet(action: IdentityStepInitialSet | IdentityStepSet) {
  try {
    const {
      payload: { stepId, fields },
    } = action;

    const stepMeta = yield select(makeSelectStepMeta(fields));
    yield put(setIdentityMeta({ stepId, fields: stepMeta }));
  } catch (err) {
    console.log(err);
  }
}

export function* rootSaga() {
  yield takeLatest(
    Type.IDENTITY_UPLOAD_DOC_REQUEST,
    watchForUploadIdentityDocumentsRequest
  );
  yield takeLatest(Type.IDENTITY_SUBMIT_REQUEST, watchForSubmit);

  yield takeLatest(
    [Type.IDENTITY_STEP_INITIAL_SET, Type.IDENTITY_STEP_SET],
    watchForIdentityStepSet
  );

  yield takeLatest(Type.IDENTITY_PHONE_CODE_REQUEST, watchForPhoneCodeRequest);
}
