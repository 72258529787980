import React, { useEffect, useState } from 'react';
import Select, { Props as SelectProps, Styles, ValueType } from 'react-select';
import get from 'lodash/get';
import styled, { withTheme } from 'styled-components';

import { getDropdownStyles } from 'components/UI/Dropdown/utils/helpers';
import { ILocalizationProps } from 'system/Localization';
import { IndicatorsContainer } from 'components/UI/Dropdown';
import { ErrorMessage } from 'components/UI';
import { IThemeProps } from 'merchants/types/IThemeStyles';
import { getDatePickerOptions, getStateDate } from './utils/helper';
import { DatePickerOption, ICalendarFormat, IDateValue, IYearsInterval } from './utils/types';

interface DatePickerSelectProps {
  onBlur: (key: string) => () => void;
  error: { [key: string]: string };
  yearsInterval: IYearsInterval;
  touched: { [key: string]: boolean };
  date: ICalendarFormat<string>;
  handleChange: (date: IDateValue) => void;
  disabled: boolean;
  locale: string;
}

type IDatePickerProps = ILocalizationProps & IThemeProps & SelectProps & DatePickerSelectProps;

const DatePickerSelect = ({
  onBlur,
  error,
  yearsInterval,
  theme,
  translate,
  touched,
  date,
  handleChange,
  disabled,
  locale,
}: IDatePickerProps) => {
  const [stateDate, setDate] = useState(getStateDate(date));

  useEffect(() => {
    const {
      year: { value: yearValue },
      month: { value: monthValue },
      day: { value: dayValue },
    } = stateDate;

    const fullDate =
      yearValue && monthValue && dayValue
        ? {
            all: `${yearValue}-${
              monthValue.length > 1 ? monthValue : `0${monthValue}`
            }-${dayValue.length > 1 ? dayValue : `0${dayValue}`}`,
          }
        : {};

    handleChange({
      year: yearValue,
      month: monthValue,
      day: dayValue,
      ...fullDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateDate]);

  const onChange = (name: string) => (query: ValueType<any>) => {
    const { year, month, day } = { ...stateDate, [name]: query } as ICalendarFormat<DatePickerOption>;

    setDate(
      getStateDate({
        year: year.value,
        month: month.value,
        day: day.value,
      })
    );
  };

  const themeDropdown = get(theme, 'forms.dropdown', {});

  const dateOptions = getDatePickerOptions(
    {
      yearsInterval,
      year: stateDate.year.value,
      month: stateDate.month.value,
    },
    locale
  );

  return (
    <Container>
      {Object.keys(dateOptions).map((select) => {
        const value = stateDate[select].value
          ? stateDate[select]
          : undefined;

        const selectError = get(error, select);
        const ifTouched = Boolean(touched && touched[select]);
        const ifError = Boolean(ifTouched && (selectError || error?.all));
        const ifValid = Boolean(ifTouched && !ifError);

        const styles: Styles = getDropdownStyles(themeDropdown, {
          error: ifError,
          valid: ifValid,
        });

        return (
          <Wrapper key={select} data-aqaid={`datepicker-${select}`}>
            <Select
              name={select}
              onBlur={onBlur(select)}
              onChange={onChange(select)}
              options={dateOptions[select]}
              styles={styles}
              value={value}
              placeholder={translate(`datepicker_${select}`)}
              components={{
                IndicatorsContainer,
              }}
              isDisabled={disabled}
              maxMenuHeight={300}
              menuPlacement="auto"
            />
            {ifError && (
              <ErrorMessage data-aqaid="error-message">
                {translate(selectError)}
              </ErrorMessage>
            )}
          </Wrapper>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    flex-grow: 1;
  }

  > div:not(:first-child):not(:last-child) {
    margin-left: 8px;
    margin-right: 8px;

    @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

export const DateSelect = withTheme(DatePickerSelect);
