import { List, Map } from 'immutable';
import { isLocalEnv } from 'utils/env';
import { ConfigureStore } from 'store';
import { emitLoggerError } from 'utils/error/logger';

const getConfig = () => {
  const state = ConfigureStore.getStore().getState();
  const locale = state.getIn(['settings', 'i18n', 'locale'], 'en');
  const requestToken = state.getIn(['profile', 'requestToken'], '');

  return {
    headers: {
      'Content-Type': 'application/json',
      'X-WLTVA-Client-Platform': 'web',
      'X-WLTVA-Client-Version': '0.0.1',
      'Accept-Language': locale,
      ...(requestToken ? { Authorization: `Bearer ${requestToken}` } : {}),
    },
  };
};

const parseJSON = (response: any) => response.json();

const trimObj = (obj: { [k: string]: any } | Array<any>): any => {
  if (!obj || (!Array.isArray(obj) && typeof obj !== 'object')) return obj;

  if (List.isList(obj)) {
    return trimObj(obj.toArray());
  }

  if (Map.isMap(obj)) {
    return trimObj(obj.toObject());
  }

  if (Array.isArray(obj)) {
    return obj.map((item) =>
      typeof item === 'string' ? item.trim().replace(/ +|\n|\v|\t/gm, ' ') : trimObj(item)
    );
  }

  return Object.keys(obj).reduce((acc: { [k: string]: any }, key) => {
    acc[key] =
      typeof obj[key] !== 'string'
        ? trimObj(obj[key])
        : obj[key].trim().replace(/ +|\n|\v|\t/gm, ' ');
    return acc;
  }, {});
};

interface IJsonRPCOptions {
  namespace: string;
  method: string;
  api: string;
  [prop: string]: any;
}

export const jsonRPC = async ({ namespace, api, method, params = {} }: IJsonRPCOptions) => {
  const url = isLocalEnv() ? `/rpc/${namespace}/` : `${api}/rpc/${namespace}/`;

  const init: any = {
    method: 'POST',
    headers: {
      ...getConfig().headers,
    },
    body: JSON.stringify({
      jsonrpc: '2.0',
      id: Date.now(),
      method,
      params: trimObj(params),
    }),
  };

  return fetch(url, init)
    .then(parseJSON)
    .then((response) => {
      const { result, error } = response;
      // TODO: Change error behavior
      // It shouldn't throw an exception
      // when http response 200 with an error payload

      if (error) {
        emitLoggerError(error, { key: url, data: init });
        throw error;
      }

      return result;
    });
};
