import { createSelector } from 'reselect';
import { fromJS } from 'immutable';

import { getFileToken } from 'utils/file';

import {
  makeSelectCompanyAnnualProfit,
  makeSelectCompanyBusinessModel,
  makeSelectCompanyPlanningDepositCrypto,
  makeSelectCompanySourceOfFiatFunds,
  makeSelectCompanySourceOfCryptoFunds,
  makeSelectCompanyBusinessModelFiles,
} from './businessActivity';

export const makeSelectBusinessActivityBlockData = () =>
  createSelector(
    makeSelectCompanyAnnualProfit(),
    makeSelectCompanyBusinessModel(),
    makeSelectCompanyBusinessModelFiles(),
    makeSelectCompanyPlanningDepositCrypto(),
    makeSelectCompanySourceOfFiatFunds(),
    makeSelectCompanySourceOfCryptoFunds(),
    (
      annualNetProfitLastYear,
      bussinessModel,
      bussinessModelFiles,
      planToDepositCryptoFunds,
      corporateSourceOfFiatFunds,
      corporateSourceOfCryptoFunds,
    ) => ({
      businessActivity: {
        annualNetProfitLastYear,
        bussinessModel,
        bussinessModelFiles: getFileToken(fromJS(bussinessModelFiles)),
        corporateSourceOfFiatFunds,
        planToDepositCryptoFunds,
        ...(planToDepositCryptoFunds ? { corporateSourceOfCryptoFunds } : {}),
      },
    })
  );
