import { createGlobalStyle, withTheme } from 'styled-components';
import { ITheme } from 'merchants/types/IThemeStyles';

interface IInjectGlobalStylesProps {
  theme: ITheme;
}
export const InjectGlobalStyles = withTheme(createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700');

  *,
  *::after,
  *::before {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }
  
   html, body {
      height: 100vh;
      margin: 0;
  }
  
  .root-websdk {
    min-height: 100vh;
  }
  
   .root-websdk, #root-websdk, #modal-root-websdk, #resubmit, #preview, .websdk-modal {
     font-family: Open Sans,sans-serif;
     line-height: 1.2;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      -ms-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      -webkit-text-size-adjust: 100%;
   }

  .root-websdk, #root-websdk {
      display: flex;
      flex-flow: column nowrap;
      position: relative;
      // fix when content height === mobile device viewport
      height: auto;
      height: 100vh;
      overflow: auto;
     
      background: ${({ theme }: IInjectGlobalStylesProps) =>
        theme.layout.backgroundColor};
      color: #39414d;
      text-rendering: optimizeLegibility;
      min-width: 320px;
      font-size: 15px;
      
      ul, li :not(#preview) {
         margin:0;
         padding: 0;
         text-indent: 0;
         list-style-type: none;
      }
      
      .app {
          text-align: center;
          font-size: 3.2rem;
          font-weight: 600;
      }
  }
`);
