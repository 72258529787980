export const coordinates: { [key: string]: string } = {
  ad: ' -16px 0',
  ae: ' -32px 0',
  af: ' -48px 0',
  ag: ' -64px 0',
  ai: ' -80px 0',
  al: ' -96px 0',
  am: ' -112px 0',
  an: ' -128px 0',
  ao: ' -144px 0',
  ar: ' -160px 0',
  as: ' -176px 0',
  at: ' -192px 0',
  au: ' -208px 0',
  aw: ' -224px 0',
  ax: ' -240px 0',
  az: ' 0 -11px',
  ba: ' -16px -11px',
  bb: ' -32px -11px',
  bd: ' -48px -11px',
  be: ' -64px -11px',
  bf: ' -80px -11px',
  bg: ' -96px -11px',
  bh: ' -112px -11px',
  bi: ' -128px -11px',
  bj: ' -144px -11px',
  bm: ' -160px -11px',
  bn: ' -176px -11px',
  bo: ' -192px -11px',
  br: ' -208px -11px',
  bs: ' -224px -11px',
  bt: ' -240px -11px',
  bv: ' 0 -22px',
  bw: ' -16px -22px',
  by: ' -32px -22px',
  bz: ' -48px -22px',
  ca: ' -64px -22px',
  catalonia: ' -80px -22px',
  cd: ' -96px -22px',
  cf: ' -112px -22px',
  cg: ' -128px -22px',
  ch: ' -144px -22px',
  ci: ' -160px -22px',
  ck: ' -176px -22px',
  cl: ' -192px -22px',
  cm: ' -208px -22px',
  cn: ' -224px -22px',
  co: ' -240px -22px',
  cr: ' 0 -33px',
  cu: ' -16px -33px',
  cv: ' -32px -33px',
  cw: ' -48px -33px',
  cy: ' -64px -33px',
  cz: ' -80px -33px',
  de: ' -96px -33px',
  dj: ' -112px -33px',
  dk: ' -128px -33px',
  dm: ' -144px -33px',
  do: ' -160px -33px',
  dz: ' -176px -33px',
  ec: ' -192px -33px',
  ee: ' -208px -33px',
  eg: ' -224px -33px',
  eh: ' -240px -33px',
  england: ' 0 -44px',
  er: ' -16px -44px',
  es: ' -32px -44px',
  et: ' -48px -44px',
  eu: ' -64px -44px',
  fi: ' -80px -44px',
  fj: ' -96px -44px',
  fk: ' -112px -44px',
  fm: ' -128px -44px',
  fo: ' -144px -44px',
  fr: ' -160px -44px',
  ga: ' -176px -44px',
  gb: ' -192px -44px',
  gd: ' -208px -44px',
  ge: ' -224px -44px',
  gf: ' -240px -44px',
  gg: ' 0 -55px',
  gh: ' -16px -55px',
  gi: ' -32px -55px',
  gl: ' -48px -55px',
  gm: ' -64px -55px',
  gn: ' -80px -55px',
  gp: ' -96px -55px',
  gq: ' -112px -55px',
  gr: ' -128px -55px',
  gs: ' -144px -55px',
  gt: ' -160px -55px',
  gu: ' -176px -55px',
  gw: ' -192px -55px',
  gy: ' -208px -55px',
  hk: ' -224px -55px',
  hm: ' -240px -55px',
  hn: ' 0 -66px',
  hr: ' -16px -66px',
  ht: ' -32px -66px',
  hu: ' -48px -66px',
  ic: ' -64px -66px',
  id: ' -80px -66px',
  ie: ' -96px -66px',
  il: ' -112px -66px',
  im: ' -128px -66px',
  in: ' -144px -66px',
  io: ' -160px -66px',
  iq: ' -176px -66px',
  ir: ' -192px -66px',
  is: ' -208px -66px',
  it: ' -224px -66px',
  je: ' -240px -66px',
  jm: ' 0 -77px',
  jo: ' -16px -77px',
  jp: ' -32px -77px',
  ke: ' -48px -77px',
  kg: ' -64px -77px',
  kh: ' -80px -77px',
  ki: ' -96px -77px',
  km: ' -112px -77px',
  kn: ' -128px -77px',
  kp: ' -144px -77px',
  kr: ' -160px -77px',
  kurdistan: ' -176px -77px',
  kw: ' -192px -77px',
  ky: ' -208px -77px',
  kz: ' -224px -77px',
  la: ' -240px -77px',
  lb: ' 0 -88px',
  lc: ' -16px -88px',
  li: ' -32px -88px',
  lk: ' -48px -88px',
  lr: ' -64px -88px',
  ls: ' -80px -88px',
  lt: ' -96px -88px',
  lu: ' -112px -88px',
  lv: ' -128px -88px',
  ly: ' -144px -88px',
  ma: ' -160px -88px',
  mc: ' -176px -88px',
  md: ' -192px -88px',
  me: ' -208px -88px',
  mg: ' -224px -88px',
  mh: ' -240px -88px',
  mk: ' 0 -99px',
  ml: ' -16px -99px',
  mm: ' -32px -99px',
  mn: ' -48px -99px',
  mo: ' -64px -99px',
  mp: ' -80px -99px',
  mq: ' -96px -99px',
  mr: ' -112px -99px',
  ms: ' -128px -99px',
  mt: ' -144px -99px',
  mu: ' -160px -99px',
  mv: ' -176px -99px',
  mw: ' -192px -99px',
  mx: ' -208px -99px',
  my: ' -224px -99px',
  mz: ' -240px -99px',
  na: ' 0 -110px',
  nc: ' -16px -110px',
  ne: ' -32px -110px',
  nf: ' -48px -110px',
  ng: ' -64px -110px',
  ni: ' -80px -110px',
  nl: ' -96px -110px',
  no: ' -112px -110px',
  np: ' -128px -110px',
  nr: ' -144px -110px',
  nu: ' -160px -110px',
  nz: ' -176px -110px',
  om: ' -192px -110px',
  pa: ' -208px -110px',
  pe: ' -224px -110px',
  pf: ' -240px -110px',
  pg: ' 0 -121px',
  ph: ' -16px -121px',
  pk: ' -32px -121px',
  pl: ' -48px -121px',
  pm: ' -64px -121px',
  pn: ' -80px -121px',
  pr: ' -96px -121px',
  ps: ' -112px -121px',
  pt: ' -128px -121px',
  pw: ' -144px -121px',
  py: ' -160px -121px',
  qa: ' -176px -121px',
  re: ' -192px -121px',
  ro: ' -208px -121px',
  rs: ' -224px -121px',
  ru: ' -240px -121px',
  rw: ' 0 -132px',
  sa: ' -16px -132px',
  sb: ' -32px -132px',
  sc: ' -48px -132px',
  scotland: ' -64px -132px',
  sd: ' -80px -132px',
  se: ' -96px -132px',
  sg: ' -112px -132px',
  sh: ' -128px -132px',
  si: ' -144px -132px',
  sj: ' -160px -132px',
  sk: ' -176px -132px',
  sl: ' -192px -132px',
  sm: ' -208px -132px',
  sn: ' -224px -132px',
  so: ' -240px -132px',
  somaliland: ' 0 -143px',
  sr: ' -16px -143px',
  ss: ' -32px -143px',
  st: ' -48px -143px',
  sv: ' -64px -143px',
  sx: ' -80px -143px',
  sy: ' -96px -143px',
  sz: ' -112px -143px',
  tc: ' -128px -143px',
  td: ' -144px -143px',
  tf: ' -160px -143px',
  tg: ' -176px -143px',
  th: ' -192px -143px',
  tibet: ' -208px -143px',
  tj: ' -224px -143px',
  tk: ' -240px -143px',
  tl: ' 0 -154px',
  tm: ' -16px -154px',
  tn: ' -32px -154px',
  to: ' -48px -154px',
  tr: ' -64px -154px',
  tt: ' -80px -154px',
  tv: ' -96px -154px',
  tw: ' -112px -154px',
  tz: ' -128px -154px',
  ua: ' -144px -154px',
  ug: ' -160px -154px',
  um: ' -176px -154px',
  us: ' -192px -154px',
  uy: ' -208px -154px',
  uz: ' -224px -154px',
  va: ' -240px -154px',
  vc: ' 0 -165px',
  ve: ' -16px -165px',
  vg: ' -32px -165px',
  vi: ' -48px -165px',
  vn: ' -64px -165px',
  vu: ' -80px -165px',
  wales: ' -96px -165px',
  wf: ' -112px -165px',
  ws: ' -128px -165px',
  xk: ' -144px -165px',
  ye: ' -160px -165px',
  yt: ' -176px -165px',
  za: ' -192px -165px',
  zanzibar: ' -208px -165px',
  zm: ' -224px -165px',
  zw: ' -240px -165px',
};
