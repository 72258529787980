import styled from 'styled-components';

import { Primary } from 'components/UI/Button';
import { Badge } from 'components/UI/Badge';

import {
  DURATION,
  TIMING_FUNCTION,
  SAGE_PREVIEW_FULL_WIDTH_DESKTOP,
  STAGE_PREVIEW_SHORT_CONTENT_HEIGHT_DESKTOP,
} from './utils/constants';

import {
  Content as ContentPreviewShort,
  ModalOpenButton,
  PreviewImage,
  StageBoxShort,
  Subtitle,
  Title,
} from './StagePreviewShort';
import {
  Content as ContentPreviewFull,
  ModalCloseButton,
  StageBoxFull,
} from './StagePreviewFull';

enum ANIMATION_STATE {
  exited = 'exited',
  exiting = 'exiting',
  entered = 'entered',
}

interface PreviewAnimationProps {
  directionX: string;
  directionY: string;
  shiftX: number;
  shiftY: number;
  startWidth: string;
  startX: number;
  startY: number;
  state: string;
  status: string;
}

export const PreviewAnimation = styled.div.attrs(
  ({
    directionX,
    directionY,
    shiftX,
    shiftY,
    startWidth,
    startX,
    startY,
    state,
    status,
  }: PreviewAnimationProps) => ({
    directionX,
    directionY,
    shiftX,
    shiftY,
    startWidth,
    startX,
    startY,
    state,
    status,
  })
)`
  ${StageBoxShort} {
    transition: box-shadow ${DURATION}ms ${TIMING_FUNCTION},
      transform ${DURATION}ms ${TIMING_FUNCTION};
    box-shadow: ${({ state }) => {
      return state === ANIMATION_STATE.exited
        ? '0 2px 17px 0 rgba(147, 147, 147, 0.5)'
        : '0 0 0 0 rgba(147, 147, 147, 0.5)';
    }};
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    ${ModalOpenButton} {
      transition: transform ${DURATION}ms ${TIMING_FUNCTION};
      transform: ${({ state }) => {
        return state === ANIMATION_STATE.exited
          ? 'translate(-50%, 0)'
          : 'translate(-50%, -30px)';
      }};
    }

    ${Subtitle},
    ${Title},
     ${Badge}{
      transition: opacity ${DURATION - 100}ms ${TIMING_FUNCTION},
        transform ${DURATION - 100}ms ${TIMING_FUNCTION};
      opacity: ${({ state }) => (state === ANIMATION_STATE.exited ? '1' : '0')};
    }

    ${Subtitle},
    ${Title} {
      transform: ${({ state }) => {
        return state === ANIMATION_STATE.exited
          ? 'translateY(0)'
          : 'translateY(-10px)';
      }};
    }

    ${Badge} {
      transform: ${({ state }) => {
        return state === ANIMATION_STATE.exited
          ? 'translateY(0)'
          : 'translateY(4px)';
      }};
    }

    ${PreviewImage} {
      height: 224px;
      width: 100%;
      opacity: ${({ state }) =>
        state === ANIMATION_STATE.entered || state === ANIMATION_STATE.exiting
          ? '0'
          : '1'};
    }

    ${ContentPreviewShort} {
      overflow: hidden;
      transition: padding ${DURATION}ms ${TIMING_FUNCTION},
        height ${DURATION}ms ${TIMING_FUNCTION};
      width: 100%;
      padding: ${({ state }) =>
        state === ANIMATION_STATE.exited ? '' : '0 12px'};
      opacity: ${({ state }) => (state === ANIMATION_STATE.exited ? '1' : '0')};
      max-height: ${({ state }) =>
        state === ANIMATION_STATE.exited
          ? `${STAGE_PREVIEW_SHORT_CONTENT_HEIGHT_DESKTOP}px`
          : '0px'};
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }

  ${StageBoxFull} {
    background: none;
    box-shadow: none;
    position: absolute;
    transition: transform ${DURATION}ms ${TIMING_FUNCTION},
      width ${DURATION}ms ${TIMING_FUNCTION},
      border-radius ${DURATION}ms ${TIMING_FUNCTION};
    border-radius: ${({ state, theme }) =>
      state === ANIMATION_STATE.entered
        ? '0'
        : theme.components.stagePreview.borderRadius};

    transform: ${({ state, startY, startX }) => {
      return state === ANIMATION_STATE.entered
        ? `translate(${startX * -1}px, ${startY * -1}px);`
        : 'translate(0, 0);';
    }};
    width: ${({ state, startWidth }) =>
      state === ANIMATION_STATE.entered ? '100%' : `${startWidth}px`};

    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
      border-radius: ${({ theme }) =>
        theme.components.stagePreview.borderRadius};
      box-shadow: none;
      height: auto;
      width: ${({ state, startWidth }) => {
        return state === ANIMATION_STATE.entered
          ? `${SAGE_PREVIEW_FULL_WIDTH_DESKTOP}px`
          : `${startWidth}px`;
      }};
      transform: ${({
        directionX = 'left',
        directionY = 'up',
        shiftX,
        shiftY,
        state,
      }) => {
        const x = directionX === 'right' ? `${shiftX * -1}px` : '0';
        const y = directionY === 'down' ? '0' : `${shiftY * -1}px`;

        return state === ANIMATION_STATE.entered
          ? `translate(${x}, ${y});`
          : 'translate(0, 0);';
      }};
    }

    ${ModalCloseButton} {
      transition: transform ${DURATION}ms ${TIMING_FUNCTION};
      transform: ${({ state }) => {
        return state === ANIMATION_STATE.entered
          ? 'translateY(0)'
          : 'translateY(-50px)';
      }};
    }

    ${Primary} {
      transition: transform ${DURATION}ms ${TIMING_FUNCTION};
      transform: ${({ state }) => {
        return state === ANIMATION_STATE.entered
          ? 'translateY(0)'
          : 'translateY(-60px)';
      }};
    }

    ${PreviewImage} {
      max-height: inherit;
      transition: height ${DURATION}ms ${TIMING_FUNCTION};
      height: ${({ state, status }) => {
        if (state === ANIMATION_STATE.entered) {
          return '332px';
        }
        return status === 'locked' ? '138px' : '224px';
      }};

      @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
        max-height: inherit;
        width: 100%;
        opacity: ${({ state }) => {
          return state === ANIMATION_STATE.entered ||
            state === ANIMATION_STATE.exiting
            ? '1'
            : '0';
        }};

        height: ${({ state }) =>
          state === ANIMATION_STATE.entered ? '260px' : '224px'};
      }
    }

    ${ContentPreviewFull} {
      overflow-y: auto;
      transition: height ${DURATION}ms ${TIMING_FUNCTION},
        padding ${DURATION}ms ${TIMING_FUNCTION};
      width: 100%;
      padding: 0 12px;
      opacity: ${({ state }) =>
        state === ANIMATION_STATE.entered ? '1' : '0'};

      @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
        height: ${({ state }) =>
          state === ANIMATION_STATE.entered ? '332px' : '0'};
        padding: ${({ state }) =>
          state === ANIMATION_STATE.entered ? '12px' : '0 12px'};
      }
      
      ::-webkit-scrollbar {
          width: 6px;
      }
       
      ::-webkit-scrollbar-track {
          border-radius: 10px;
      }
       
      ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.4); 
      }
      
      ::-webkit-scrollbar {
          width: 6px;
      }
       
      ::-webkit-scrollbar-track {
          border-radius: 10px;
      }
       
      ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.4); 
      }
    }

  }
`;
