import {
  IDossierStatusSet,
  IFeedbackHistorySet,
  IRecognizerProfileSet,
  IProfileFailed,
  IProfileGet,
  IProfileSet,
  IMerchantFeaturesSet,
  IStagesSet,
  IUserDataSet,
  Type,
  IRegistrationDataSet,
} from './actionTypes';
import { ISettings } from '../Settings/types';

export const getProfile = (): IProfileGet => ({
  type: Type.PROFILE_GET,
  payload: null,
});

export const setProfile = (payload: ISettings): IProfileSet => ({
  type: Type.PROFILE_SET,
  payload,
});

export const setDossierStatus = (status: string): IDossierStatusSet => ({
  type: Type.DOSSIER_STATUS_SET,
  payload: status,
});

export const setRegistrationData = (registrationData: any): IRegistrationDataSet => ({
  type: Type.REGISTRATION_DATA_SET,
  payload: registrationData,
});

export const setUserData = (userData: any): IUserDataSet => ({
  type: Type.USER_DATA_SET,
  payload: userData,
});

export const setStages = (stages: any[]): IStagesSet => ({
  type: Type.STAGES_SET,
  payload: stages,
});

export const setMerchantFeatures = (
  merchantFeatures: IMerchantFeaturesSet['payload']
): IMerchantFeaturesSet => ({
  type: Type.MERCHANT_FEATURES_SET,
  payload: merchantFeatures,
});

export const setRecognizerProfile = (payload: any): IRecognizerProfileSet => ({
  type: Type.RECOGNIZER_PROFILE_SET,
  payload,
});

export const setFeedbackHistory = (payload: any): IFeedbackHistorySet => ({
  type: Type.FEEDBACK_HISTORY_SET,
  payload,
});

export const failedProfile = (payload: Error[]): IProfileFailed => ({
  type: Type.PROFILE_FAILED,
  payload,
});
