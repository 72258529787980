import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Info: React.FunctionComponent<IconProps> = ({
  width,
  height,
  isFilled,
}) => {
  const {
    icons: {
      markup: {
        info: { fill, fill2 },
      }
    },
  } = useContext(ThemeContext);

  return isFilled
    ? (
      <svg width="16" height="16" viewBox="0 0 16 16">
        <path
fill={fill2} fillRule="evenodd"
              d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm.8 12H7.2v-1.6h1.6V12zm0-3.2H7.2V4h1.6v4.8z"
        />
      </svg>
    )
    : (
      <svg
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        width={width}
      >
        <path
          d="M5.85 9.75h1.3v-3.9h-1.3v3.9zM6.5 0A6.502 6.502 0 0 0 0 6.5C0 10.088 2.912 13 6.5 13S13 10.088 13 6.5 10.088 0 6.5 0zm0 11.7a5.207 5.207 0 0 1-5.2-5.2c0-2.866 2.333-5.2 5.2-5.2s5.2 2.334 5.2 5.2-2.333 5.2-5.2 5.2zm-.65-7.15h1.3v-1.3h-1.3v1.3z"
          fill={fill}
          fillRule="evenodd"
        />
      </svg>
    )
};

Info.displayName = 'IconInfo';

Info.defaultProps = { height: '13', width: '13' };
