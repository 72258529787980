import React, { FC } from 'react';
import styled from 'styled-components';
import { IThemeProps } from 'merchants/types/IThemeStyles';

export interface IProgressProps {
  size?: number;
}

export const Progress: FC<IProgressProps> = ({ size = undefined }) => (
  <Box>
    <Tag>{size}%</Tag>
    <Bar size={size} />
    <Tag>100%</Tag>
  </Box>
);

const Box = styled.span`
  flex: auto;
  display: flex;
  align-items: center;
  max-width: 503px;
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;
  color: #ffffff;
  
  @media (min-width: ${({ theme }: IThemeProps) => theme.breakpoint.desktop}) {
    margin-left: 13%;
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const Tag = styled.span`
  font-weight: ${({ theme }: IThemeProps) => theme.components.progress.tagFontWeight};
  color: ${({ theme }: IThemeProps) => theme.components.progress.tagColor}
`;

const Bar = styled.span.attrs(({ size }: IProgressProps & IThemeProps) => ({ size }))`
  flex: auto;
  height: 4px;
  margin-left: 12px;
  margin-right: 12px;
  background-color: ${({ theme }: IThemeProps) => theme.components.progress.backgroundColor};
  background-repeat: repeat-y;
  border-radius: 2px;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    width: ${({ size }) => size || 0}%;
    height: 100%;
    background-color: ${({ theme }: IThemeProps) => theme.components.progress.backgroundColorAfter};
    border-radius: 2px;
  }
`;
