import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { IThemeProps, WithThemeProps } from 'merchants/types/IThemeStyles';
import { Label, DummyInput } from '../shared';

interface ICheckboxProps {
  name?: string;
  disabled?: boolean;
  checked?: boolean;
  value?: string | number | string[] | undefined;
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  defaultChecked?: boolean;
  label?: any;
}

export function CheckBox({ disabled = false, label, ...innerProps }: ICheckboxProps) {
  return (
    <Label data-aqaid={`checkbox-${innerProps.name}`}>
      <Input disabled={disabled} {...innerProps} />
      <Mark disabled={disabled} />
      {label}
    </Label>
  );
}

CheckBox.displayName = 'CheckBox';

const Mark = styled.span.attrs(
  ({
    checked,
    disabled,
  }: WithThemeProps<{ checked: boolean; disabled: boolean }>) => ({
    checked,
    disabled,
  })
)`
  cursor: pointer;
  position: relative;
  padding: 0;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    background: #fff;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    border-width: ${({ theme }: IThemeProps) =>
      theme.forms.checkBox.borderWidth};
    border-style: solid;
    border-width: ${({ theme }: IThemeProps) =>
      theme.forms.checkBox.borderWidth};
    border-color: ${({
      theme,
      disabled,
    }: { disabled: boolean } & IThemeProps) =>
      (disabled && theme.forms.checkBox.disabled.borderColor) ||
      theme.forms.checkBox.borderColor};
  }
`;

const Input = styled(DummyInput).attrs({ type: 'checkbox' })`
  width: 0;
  height: 0;
  opacity: 0;

  &:checked + ${Mark} {
    &::before {
      border-color: ${({ theme }: IThemeProps) =>
        theme.forms.checkBox.checked.borderColor};
      background-color: ${({ theme }: IThemeProps) =>
        theme.forms.checkBox.checked.backgroundColor};
    }

    &::after {
      content: '';
      position: absolute;
      left: 3px;
      top: 7px;
      background: #fff;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff,
        4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
      transform: rotate(45deg);
    }
  }

  &:disabled + ${Mark} {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + ${Mark} {
    :before {
      box-shadow: none;
      background: #ddd;
    }
  }
`;
