import React, { FC, useContext } from 'react';
import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';


export const Exit: FC<IconProps> = ({
  width = 16,
  height = 16,
}) => {
  const {
    icons: {
      markup: {
        exit: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
    >
      <g fillRule="nonzero">
        <path d="M7.973 14.645h-5.98a.665.665 0 0 1-.664-.665V2.02c0-.367.298-.665.664-.665h5.98a.664.664 0 1 0 0-1.328h-5.98A1.996 1.996 0 0 0 0 2.02v11.96c0 1.1.894 1.993 1.993 1.993h5.98a.664.664 0 1 0 0-1.328z" />
        <path d="M15.802 7.527l-4.04-3.987a.664.664 0 1 0-.933.946l2.887 2.85H5.98a.664.664 0 1 0 0 1.328h7.736l-2.887 2.85a.664.664 0 1 0 .933.946l4.04-3.987a.666.666 0 0 0 0-.946z" />
      </g>
    </svg>
  )
};

