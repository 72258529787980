import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const MiningActivity: React.FunctionComponent<IconProps> = ({ width = 104, height = 126 }) => {
  const {
    icons: {
      docs: {
        miningActivity: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg height={height} viewBox={`0 0 ${width} ${height}`} width={width} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect height="124" rx="4" stroke={fill} strokeWidth="2" width="102" x="1" y="1" />
        <path d="M17 79h70v30H17z" stroke={fill} strokeWidth="2" />
        <path d="M18 90h68v-2H18zM18 100h68v-2H18z" fill={fill} />
        <path d="M56 78h2v32h-2zM74 78h2v32h-2z" fill={fill} />
        <path d="M62.286 32.788c-.746-.961-1.871-1.622-3.377-1.98 1.917-.975 2.76-2.629 2.528-4.963-.077-.846-.312-1.58-.704-2.2a4.414 4.414 0 0 0-1.592-1.5 8.78 8.78 0 0 0-2.18-.866 17.256 17.256 0 0 0-2.702-.433V16h-2.971v4.712c-.515 0-1.3.012-2.354.038V16h-2.972v4.846c-.425.013-1.048.02-1.872.02l-4.09-.02V24h2.142c.977 0 1.55.436 1.717 1.308v5.52c.129 0 .231.006.309.019h-.31v7.73c-.102.654-.475.981-1.118.981h-2.142L40 43.078h3.86c.244 0 .597.003 1.06.008.463.007.81.01 1.042.01V48h2.972v-4.846a105 105 0 0 0 2.354.02V48h2.971v-4.904a23.175 23.175 0 0 0 2.808-.288 11.454 11.454 0 0 0 2.344-.664c.746-.301 1.37-.676 1.871-1.125.502-.448.917-1.02 1.245-1.712.328-.692.537-1.493.627-2.404.168-1.781-.122-3.153-.868-4.115zM48.991 24.21a143.12 143.12 0 0 0 1.804-.03c.277-.005.65.01 1.12.05.47.038.865.09 1.187.153.321.064.675.17 1.06.317.387.148.695.327.927.539.231.211.427.48.588.808.161.326.241.701.241 1.124 0 .36-.057.686-.173.981-.115.295-.29.542-.52.74-.232.2-.47.373-.715.52a3.276 3.276 0 0 1-.936.356c-.379.09-.71.16-.993.211a8.16 8.16 0 0 1-1.061.106c-.425.02-.747.032-.965.039-.219.006-.518.006-.898 0-.38-.007-.6-.01-.665-.01v-5.904zm8.114 13.096c-.122.295-.28.552-.472.77-.193.218-.448.41-.763.576-.315.167-.62.302-.916.404a7.397 7.397 0 0 1-1.07.27 14.11 14.11 0 0 1-1.101.163 20.14 20.14 0 0 1-1.129.077 25.407 25.407 0 0 1-1.949.02c-.373-.007-.61-.01-.714-.01v-6.5c.103 0 .409-.007.917-.02a50.52 50.52 0 0 1 1.244-.019c.322 0 .766.02 1.332.058.566.038 1.042.096 1.428.173.386.077.807.195 1.264.355.456.16.826.356 1.11.587.282.231.52.525.713.885.193.358.29.769.29 1.23 0 .36-.061.686-.184.981z" fill={fill} fillRule="nonzero" />
        <g fill={fill}>
          <path d="M16 58h22v-2H16zM16 64h22v-2H16zM16 70h22v-2H16z" />
        </g>
        <path d="M41 58h22v-2H41zM41 64h22v-2H41zM41 70h22v-2H41zM66 58h22v-2H66zM66 64h22v-2H66zM66 70h22v-2H66z" fill={fill} />
      </g>
    </svg>
  );
}

MiningActivity.displayName = 'MiningActivityIcon';
