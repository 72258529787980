/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';

import { makeSelectStages } from 'system/Profile/selectors';

import { ErrorView } from 'containers/ErrorBoundary/errorViews';
import { RedirectToIndex } from 'containers/RedirectToIndex';

export function NotFoundRoute() {
  return <Route path="*" component={RedirectToIndex} />;
}

export function NotFoundStub() {
  return <ErrorView error={fromJS({})} />;
}

const mapStateToProps = createStructuredSelector({
  stages: makeSelectStages(),
});
const mapDispatchToProps = null;

export const RouteExitVerification = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFoundRoute);
