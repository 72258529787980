import { isLocalEnv, isDevEnv } from 'utils/env';
import { storage } from 'utils/storage';

const setExperiment = (name: string, value = '') => {
  const { location } = window;

  storage.set(name, value);

  location.reload();
};

export const register = () => {
  (window as any).setExperiment = setExperiment;
};

export const getDesiredDecision = () => {
  return isLocalEnv() || isDevEnv() ? storage.get('desiredDecision', '') : '';
};
