/*  eslint-disable */
import React, { FC } from 'react';
import styled from 'styled-components';
import { components } from 'react-select';
import get from 'lodash/get';

import { withCustomOption } from 'components/UI/Dropdown/HOC/withCustomOption';
import { ToolTip } from 'components/common/ToolTip';
import { Markup } from 'components/UI/Icon';
import { Flex } from 'components/UI';
import { Box } from 'components/UI/FlexBox'

import { Dropdown } from './Dropdown';

export const Label = styled(Box)<{ flex: string }>`
  padding-right: 0;
  margin-right: 0;
  align-self: center;
  display: inline-flex;
`;

export const Hint = styled.span`
  padding-top: 5px;
`;

const OptionTip: FC<any> = (props: any) => {
  const { label } = props;
  const hint = get(props, ['data', 'data', 'hint']);

  const tooltip = hint ? (
    <ToolTip trigger={<Markup.Info />}>
      {hint}
    </ToolTip>
  ) : null;

  return (
    <components.Option {...props}>
      <Flex>
        <Label breakpoint={{ mobile: { size: 'auto' } }} flex='none'>{label}</Label>
        {tooltip}
      </Flex>
    </components.Option>
  );
};



OptionTip.displayName = 'OptionTip';

export const DropdownWithTip = withCustomOption(OptionTip)(Dropdown);
