import { DepGraph } from 'dependency-graph';

import findLast from 'lodash/findLast';
import pick from 'lodash/pick';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

type NodesSequenceIdWithOptions = {
  id: string;
  required: {
    [k: string]: string | string[] | boolean;
  };
};

type NodesSequence = (
  | NodesSequenceIdWithOptions
  | NodesSequenceIdWithOptions[]
)[];

type GraphData = {};

const validateNodeData = (
  node: NodesSequenceIdWithOptions,
  data: GraphData
): boolean => {
  const requiredData = pick(data, Object.keys(node.required));
  return Object.entries(node.required).every(
    ([requiredNode, requiredValue]) => {
      const requiredValueForNode = get(requiredData, requiredNode, null);
      if (isArray(requiredValue)) {
        return requiredValue.includes(requiredValueForNode);
      }

      return isEqual(requiredValue, requiredValueForNode);
    }
  );
};

export const buildGraph = (nodesSequence: NodesSequence, data: GraphData) => {
  const graph: any = new DepGraph();

  nodesSequence.forEach((node) => {
    if (Array.isArray(node)) {
      const filtered = node.filter((n) => validateNodeData(n, data));

      if (isEmpty(filtered)) {
        graph.addNode(node[0].id);
      } else {
        filtered.forEach((n) => graph.addNode(n.id));
      }
    } else {
      if (has(node, 'required')) {
        const isValidNodeData = validateNodeData(node, data);

        if (!isValidNodeData) {
          return;
        }
      }

      graph.addNode(node.id);
    }

    const nodes = Object.keys(graph.nodes);
    const lastNode = findLast(nodes);
    const prevLastNode = nodes.findIndex((it) => it === lastNode) - 1;

    nodes[prevLastNode] && graph.addDependency(nodes[prevLastNode], lastNode);
  });

  return graph;
};

const omitPrevStep = (omitStepId: string, stepId: string, graph: any) => {
  const predictedLastStepId = findLast(graph.dependantsOf(stepId));

  return predictedLastStepId === omitStepId
    ? findLast(graph.dependantsOf(omitStepId))
    : predictedLastStepId;
};

export const getCurrentStep = ({ routeSchema, data, stepId }: any): any => {
  const graph = buildGraph(routeSchema, data);

  if (!graph.hasNode(stepId)) {
    return {};
  }

  const index = Object.keys(graph.nodes).findIndex((it) => it === stepId);

  const prevStepId = omitPrevStep('upload', stepId, graph);

  return {
    index,
    amount: graph.size(),
    prevStepId,
    currentStepId: stepId,
    nextStepId: findLast(graph.dependenciesOf(stepId)),
  };
};

const getOmittedSteps = ({
  isForward = true,
  omitSteps,
  stepId,
  graph,
}: any) => {
  const graphPart = isForward
    ? graph.dependenciesOf(stepId)
    : graph.dependantsOf(stepId);

  return graphPart.filter((dependencies: any) => {
    return !omitSteps.includes(dependencies);
  });
};

export const getStageRouteProgress = ({
  graph,
  stepId,
  omitPrevSteps = [''],
  omitNextSteps = [''],
}: any) => {
  if (!graph.hasNode(stepId)) {
    console.warn(stepId, 'graph node is absent');
    return {};
  }

  const index = Object.keys(graph.nodes).findIndex((it) => it === stepId);
  const prevSteps = !isEmpty(omitPrevSteps)
    ? getOmittedSteps({
        isForward: false,
        omitSteps: omitPrevSteps,
        stepId,
        graph,
      })
    : graph.dependantsOf(stepId);

  const nextSteps = !isEmpty(omitNextSteps)
    ? getOmittedSteps({
        omitSteps: omitNextSteps,
        stepId,
        graph,
      })
    : graph.dependenciesOf(stepId);

  return {
    index,
    amount: graph.size(),
    prevStepId: findLast(prevSteps),
    currentStepId: stepId,
    nextStepId: findLast(nextSteps),
  };
};

export const getCorporateRouteProgress = ({
  stepsList,
  stepId,
  blockId,
  omitPrevSteps = [''],
  omitNextSteps = [''],
}: any) => {
  const steps =
    omitPrevSteps.length || omitNextSteps.length
      ? stepsList.filter(
          (item: string) => {
            return !omitPrevSteps.includes(item) && !omitNextSteps.includes(item)
          }

        )
      : stepsList;
  const index = steps.findIndex((it: string) => it === stepId);
  const lastIdx = steps.size - 1;
  const prevIdx = index - 1;
  const prevStep = lastIdx >= prevIdx && prevIdx >= 0 ? prevIdx : null;

  const nextIdx = index + 1;
  const nextStep = lastIdx >= nextIdx && nextIdx >= 0 ? nextIdx : null;

  return {
    index,
    blockId,
    amount: steps.size,
    prevStepId: steps.get(prevStep, null),
    currentStepId: stepId,
    nextStepId: steps.get(nextStep, null),
  };
};
