import ReactHtmlParser from 'react-html-parser';
import { Map } from 'immutable';

export function format(term: string) {
  return term.replace(/\\n/g, '\n');
}

export function pull(locales: Map<string, any>, locale: string, term: string, defaults: any = '') {
  return format(locales.getIn([locale, term], defaults));
}

export function translate(locales: Map<string, any>, locale: string, term: string, ...placeholders: string[]) {
  try {
    let phrase = pull(locales, locale, term);

    if (!phrase) return placeholders.pop();

    if (phrase.startsWith('{')) return phrase;

    while (placeholders.length) {
      phrase = phrase.replace(/\[\w+\]/, placeholders.shift() as string);
    }

    return ReactHtmlParser(phrase);
  } catch (err) {
    return placeholders.pop();
  }
}
