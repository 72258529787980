/* eslint-disable */
import React, { FC, useContext } from 'react';
import styled from 'styled-components';

import { Logo } from 'components/common/Logo';
import { Nav } from 'components/common/Nav';
import { Progress } from 'components/common/Progress';
import { Resubmit } from '../../Resubmit';
import { Box, ExitLink, Flex, Link } from 'components/UI';
import * as Icon from 'components/UI/Icon/Markup';
import { CrumbProps } from '../Navbar';
import { ITranslate } from 'system/Localization';
import { ConfigContext } from 'merchants';
import { NavBreadCrumbs } from './NavBreadCrumbs';

interface IDesktopProps {
  showResubmit: boolean;
  showCrumbs: boolean;
  showProgress: boolean;
  progress: number;
  crumbs: CrumbProps[];
  indexLink: string;
  fallbackLink: string;
  iss: string;
  translate: ITranslate;
}
export const Desktop: FC<IDesktopProps> = ({
  crumbs,
  showCrumbs,
  showResubmit,
  showProgress,
  progress,
  fallbackLink,
  translate,
  iss,
}) => {
  const {
    features: { breadcrumbsInHeader, breadcrumbs, exitBtn },
  } = useContext(ConfigContext);

  return (
    <>
      <Nav>
        <Flex align="center" justify="space-between">
          <Box breakpoint={{ mobile: { size: 'auto' } }}>
            <Flex align="center">
              <Box breakpoint={{ mobile: { size: 'auto' } }}>
                <Link to={fallbackLink}>
                  <Logo iss={iss} />
                </Link>
              </Box>
              {breadcrumbsInHeader && showCrumbs && (
                <Box breakpoint={{ mobile: { size: 'auto' } }}>
                  <NavBreadCrumbs crumbs={crumbs} translate={translate} />
                </Box>
              )}
            </Flex>
          </Box>
          {showProgress && (
            <Box breakpoint={{ mobile: { size: 'auto', flexGrow: 1 } }}>
              <Progress size={progress} />
            </Box>
          )}
          <Box breakpoint={{ mobile: { size: 'auto' } }}>
            <Flex align="center" justify="space-between">
              {showResubmit && (
                <Box breakpoint={{ mobile: { size: 'auto' } }}>
                  <Resubmit />
                </Box>
              )}
              {exitBtn ? (
                <Box breakpoint={{ mobile: { size: 'auto' } }}>
                  <ExitLink to={fallbackLink}>
                    <Icon.Exit />
                    <span>{translate('exit_verification')}</span>
                  </ExitLink>
                </Box>
              ) : null}
            </Flex>
          </Box>
        </Flex>
      </Nav>
      {breadcrumbs && showCrumbs && crumbs.length > 1 && (
        <Content>
          <NavWrapper>
            <NavBreadCrumbs crumbs={crumbs} translate={translate} />
          </NavWrapper>
        </Content>
      )}
    </>
  );
};

export const Content = styled.nav`
  background-color: #e1e7f2;
`;

export const NavWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 11px 16px;
`;

export const ProgressWrapper = styled.div`
  //width: 100%;
  //position: absolute;
  //z-index: 0;
`;
