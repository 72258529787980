import { all, call, put, select, putResolve, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import kebabCase from 'lodash/kebabCase';

import { SubStep } from 'system/Routing/types';
import { makeSelectRouterParams, makeSelectRouterSearchUploadId } from 'system/Bootstrap/selectors';
import { generateFileId, joinByChar } from 'utils/helpers';
import { navigateCorporateNext } from 'system/Routing/actions';
import { saveBlock, saveStage, submitEsignature, jsonRPCApiCall } from 'system/Stages/sagas';
import { Block, Stage } from 'system/Stages/types';
import { navigateToErrorLayout, navigateToIndex } from 'system/Routing/sagas';
import { storeUserProfile, fetchUserProfile } from 'system/Profile/sagas';
import { makeErrorMessage } from 'utils/error';
import { Routes } from 'system/Routing/Routes';
import { qrCodeCompleteFileUpload } from 'system/Dossier/Upload/sagas/qr';
import { recognizeDocuments } from 'system/Documents/sagas/recognizeDocuments';
import { setRecognizerUserProfile } from 'system/Profile/sagas/externalProfiles';
import { PHOTO_MODE } from 'utils/types/IDocument';
import {
  ICorporateBlockCacheRequest,
  ICorporateBlockSubmitRequest,
  ICorporateEsignatureSubmit,
  ICorporateStepSubmit,
} from '../actionTypes';
import {
  failedBlockCache,
  failedSubmitCorporate,
  requestUploadFiles,
  resetBlock,
  setCorporateMeta,
  setCorporateStep,
  successSubmitCorporate,
  successUploadFiles,
  successEsignature,
  failedEsignature,
} from '../actions';
import {
  makeSelectAccountActivityBlockData,
  makeSelectAccountActivityBlockMeta,
  makeSelectGeneralInfoBlockData,
  makeSelectGeneralInfoBlockMeta,
  makeSelectLicensesBlockData,
  makeSelectLicensesBlockMeta,
  makeSelectStepMeta,
  makeSelectCompanyDocumentsBlockData,
  makeSelectCompanyDocumentsBlockMeta,
  makeSelectPersonalInfoBlockData,
  makeSelectBusinessActivityBlockData,
  makeSelectSignatureBlockData,
  makeSelectDocSubType,
  makeSelectIssuingCountry,
} from '../selectors';
import { uploadFiles } from './upload';
import { makeSelectPersonalInfoBlockSaveData } from '../selectors/personalInfo/save';
import {
  makeSelectPaymentDetailsBlockCache,
  makeSelectPaymentDetailsBlockData,
} from '../selectors/paymentDetails';
import { requestCache } from './cache';

export function* watchForStepSubmit(action: ICorporateStepSubmit): any {
  const {
    payload: { fields, upload, replace = false, nextStepId, cachePath, entryId },
  } = action;
  const {
    params: { stepId: block, stubId: step },
  } = yield select(makeSelectRouterParams());

  const stepId = joinByChar(step);
  const blockId = joinByChar(block);
  const uploadId = yield select(makeSelectRouterSearchUploadId());

  const stepMeta = yield select(makeSelectStepMeta({ ...fields, ...upload }, blockId, stepId));

  yield put(
    setCorporateStep({
      stepId,
      blockId,
      fields,
      replace,
    })
  );

  yield put(
    setCorporateMeta({
      stepId,
      blockId,
      fields: stepMeta,
    })
  );

  if (upload) {
    yield put(requestUploadFiles({}));

    const result = yield all(
      Object.keys(upload).map((source) => {
        if (upload[source]?.length) {
          return uploadFiles({ files: upload[source], source, replace });
        }
      })
    );

    if (uploadId) {
      const fileTokens = result.reduce((acc: any[], item: any) => {
        if (item) {
          acc.push({
            fileId: generateFileId(),
            ...item,
          });
        }
        return acc;
      }, []);

      yield call(qrCodeCompleteFileUpload, { uploadId, fileTokens });
    }

    if (step === SubStep.IDENTITY_UPLOAD) {
      const fileTypes = [PHOTO_MODE.FRONT, PHOTO_MODE.BACK];
      const imageTokens = result.reduce((reduction: any, current: any) => {
        if (current && fileTypes.includes(current.source) && current?.files) {
          const fileTokens = current.files.map(({ fileToken }: any) => fileToken);
          return reduction.concat(fileTokens);
        }
        return reduction;
      }, []);

      const subType = yield select(makeSelectDocSubType());

      const issuingCountry = yield select(makeSelectIssuingCountry());

      const { data } = yield call(recognizeDocuments, {
        imageTokens,
        subType,
        issuingCountry: issuingCountry || undefined,
        stageId: Stage.CORPORATE,
        entryId,
        blockId,
      });

      if (data) {
        yield call(setRecognizerUserProfile, {
          data,
          fileNames: fileTypes,
        });
      }
    }

    yield put(successUploadFiles());
  }

  yield put(navigateCorporateNext({ nextStepId, cachePath }));
}

export function* watchBlockSubmit(action: ICorporateBlockSubmitRequest) {
  try {
    const {
      payload: { blockId },
    } = action;

    let stageData = {};
    let fieldsInputSource = {};

    switch (blockId) {
      case Block.GENERAL_INFORMATION:
        stageData = yield select(makeSelectGeneralInfoBlockData());
        fieldsInputSource = yield select(makeSelectGeneralInfoBlockMeta());
        break;
      case Block.BUSINESS_ACTIVITY:
        stageData = yield select(makeSelectBusinessActivityBlockData());
        break;
      case Block.ACCOUNT_ACTIVITY:
        stageData = yield select(makeSelectAccountActivityBlockData());
        fieldsInputSource = yield select(makeSelectAccountActivityBlockMeta());
        break;
      case Block.LICENSES:
        stageData = yield select(makeSelectLicensesBlockData());
        fieldsInputSource = yield select(makeSelectLicensesBlockMeta());
        break;
      case Block.PAYMENT_DETAILS:
        stageData = yield select(makeSelectPaymentDetailsBlockData());
        break;
      case Block.PERSONAL_INFO:
        stageData = yield select(makeSelectPersonalInfoBlockData());
        break;
      case Block.COMPANY_DOCUMENTS:
        stageData = yield select(makeSelectCompanyDocumentsBlockData());
        fieldsInputSource = yield select(makeSelectCompanyDocumentsBlockMeta());
        break;
      case Block.SIGNATURE:
        stageData = yield select(makeSelectSignatureBlockData());
        break;
      default:
        break;
    }

    const response = yield call(saveBlock, {
      stageId: Stage.CORPORATE,
      blockId,
      stageData,
      metaData: {
        fieldsInputSource,
      },
    });

    const { userDossier, error } = response;

    if (error) {
      yield call(navigateToErrorLayout);
    }

    if (userDossier) {
      yield call(storeUserProfile, userDossier);
      yield put(push(Routes.url({ stageId: Stage.CORPORATE })));
      yield put(successSubmitCorporate());
    }
  } catch (err) {
    yield put(failedSubmitCorporate([makeErrorMessage(err)]));
    yield call(navigateToErrorLayout);
  }
}

export function* watchBlockCache(action: ICorporateBlockCacheRequest) {
  try {
    const {
      payload: { blockId, nextStepId, cachePath, data },
    } = action;

    let userData = data ?? {};

    switch (blockId) {
      case Block.GENERAL_INFORMATION:
        break;
      case Block.BUSINESS_ACTIVITY:
        break;
      case Block.ACCOUNT_ACTIVITY:
        break;
      case Block.LICENSES:
        break;
      case Block.PAYMENT_DETAILS:
        userData = yield select(makeSelectPaymentDetailsBlockCache());
        break;
      case Block.PERSONAL_INFO:
        userData = yield select(makeSelectPersonalInfoBlockSaveData());
        break;
      case Block.COMPANY_DOCUMENTS:
        break;
      case Block.SIGNATURE:
        break;
      default:
        break;
    }

    yield call(requestCache, { blockId, userData });

    yield put(resetBlock({ blockId, cachePath }));

    yield put(
      push(
        Routes.url({
          blockId: kebabCase(blockId),
          stageId: Stage.CORPORATE,
          stepId: nextStepId,
        })
      )
    );
  } catch (err) {
    yield put(failedBlockCache([makeErrorMessage(err)]));
    yield call(navigateToErrorLayout);
  }
}

export function* watchFormSubmit() {
  try {
    const response = yield call(saveStage, {
      stageId: Stage.CORPORATE,
      stageData: { corporate: {} },
    });

    const { userDossier, error } = response;

    if (error) {
      yield call(navigateToErrorLayout);
    }

    if (userDossier) {
      yield call(storeUserProfile, userDossier);
      yield put(successSubmitCorporate());
    }
  } catch (err) {
    yield put(failedSubmitCorporate([makeErrorMessage(err)]));
    yield call(navigateToErrorLayout);
  }
}

export function* watchForEsignatureSubmit(action: ICorporateEsignatureSubmit) {
  try {
    const { payload } = action;
    yield call(submitEsignature, payload);

    yield all([call(fetchUserProfile), put(push(Routes.url({ stageId: Stage.CORPORATE })))]);

    yield put(successSubmitCorporate());
  } catch (err) {
    yield put(failedEsignature([makeErrorMessage(err)]));
    yield call(navigateToErrorLayout);
  }
}

export function* watchForEsignatureResend() {
  try {
    yield call(jsonRPCApiCall, { method: 'resendEsignature' });

    yield call(fetchUserProfile);

    yield put(successSubmitCorporate());
  } catch (err) {
    yield put(failedEsignature([makeErrorMessage(err)]));
    yield call(navigateToErrorLayout);
  }
}

export function* watchForEsignatureCancel() {
  try {
    yield call(jsonRPCApiCall, { method: 'cancelEsignature' });

    yield call(fetchUserProfile);

    yield put(successSubmitCorporate());
  } catch (err) {
    yield put(failedEsignature([makeErrorMessage(err)]));
    yield call(navigateToErrorLayout);
  }
}
