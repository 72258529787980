/* eslint-disable no-console */
import { all, call, select, put } from 'redux-saga/effects';
import axios from 'axios';

import { jsonRPC } from 'utils/request';
import { convertToBufferArray } from 'utils/file';

import {
  makeSelectQrUploadId,
  makeSelectWltfsToken,
} from 'system/Dossier/Upload/selectors';

import { makeSelectApiUrl } from 'system/Settings/selectors';
import { qrSetWltfsToken } from '../actions';
import { qrCodeIsConnected } from './qr';

export interface IUploadParams {
  file: File;
  token: string;
  id?: string;
  source?: string;
}

export interface IUploadResult {
  file: File;
  id: string;
  fileToken?: string;
  fileType?: string;
}

export function* requestWltfsToken() {
  const isQrCodeConnected = yield qrCodeIsConnected();
  const uploadId = yield select(makeSelectQrUploadId());
  const namespace = isQrCodeConnected ? 'mobile' : 'tuc';
  const api = yield select(makeSelectApiUrl());

  const { wltfsToken } = yield call(jsonRPC, {
    namespace,
    api,
    method: 'requestWltfsToken',
    params: uploadId ? { uploadId } : {},
  });

  yield put(qrSetWltfsToken(wltfsToken));
}

function* upload({ id, file, token }: IUploadParams) {
  const body = yield call(convertToBufferArray, file);
  const api = yield select(makeSelectApiUrl());

  const result = yield call(() =>
    axios.post(
      `${api}/upload`,
      body,
      {
        headers: {
          Authorization: `Bearer ${encodeURIComponent(token)}`,
          filename: encodeURIComponent(file.name),
        },
      }
    )
  );

  return { id, file, result: result?.data };
}

export function* baseUploadFileRequest(uploadFiles: IUploadResult[]) {
  const token = yield select(makeSelectWltfsToken());

  const batch: (IUploadResult & {
    result: { fileToken: string };
  })[] = yield all(
    uploadFiles.map(({ id, file }) => {
      return upload({ id, file, token });
    })
  );

  return batch.map(({ id, file, result: { fileToken } }) => ({
    id,
    file,
    fileToken,
  }));
}
