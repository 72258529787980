import { createSelector } from 'reselect';
import { isEmpty, omitBy } from 'lodash';
import { fromJS } from 'immutable';
import { Stage } from 'system/Stages/types';
import {
  makeSelectPhoneBlock,
  makeSelectPrefilledPhoneBlock,
} from 'stages/Identity/blocks/Phone/selector';
import { MetaSource } from 'utils/types/shared/enums';

import { makeSelectOccupationBlock } from 'stages/Identity/blocks/Occupation/selectors';
import { makeSelectQuestionnaireBlock } from 'stages/Identity/blocks/Questionnaire/selectors';

import {
  makeSelectResidence,
  makeSelectDocSubType,
  makeSelectExpireDate,
  makeSelectFilesTokens,
  makeSelectIssuingCountry,
  makeSelectPersonalData,
  makeSelectSelfieToken,
  makeSelectSerialNumber,
  makeSelectWithMetaSource,
  makeSelectCountryMeta,
  makeSelectRegionMeta,
  makeSelectSubRegionMeta,
  makeSelectSSNMeta,
  makeSelectPhoneMeta,
  makeSelectIssuingCountryMeta,
  makeSelectDocSubTypeMeta,
  makeSelectDateOfBirthMeta,
  makeSelectFirstNameMeta,
  makeSelectGenderMeta,
  makeSelectMiddleNameNameMeta,
  makeSelectLastNameMeta,
  makeSelectSerialNumberMeta,
  makeSelectPersonalCodeMeta,
  makeSelectExpireDateMeta,
  makeSelectFullResidence,
  makeSelectFullResidenceCountryMeta,
  makeSelectFullResidenceRegionMeta,
  makeSelectFullResidenceSubRegionMeta,
  makeSelectFullResidenceSSNMeta,
  makeSelectAptSuiteMeta,
  makeSelectCityMeta,
  makeSelectStreetMeta,
  makeSelectZipCodeMeta,
  makeSelectJurisdictionCode,
  makeSelectSourceOfFundsBlock,
  makeSelectAddressDocumentBlock,
  makeSelectPersonalCode,
  makeSelectLivenessCheckStats,
  makeSelectCountry
} from './selectors';

const makeSelectIdentityDocument = createSelector(
  makeSelectPersonalData(),
  makeSelectDocSubType(),
  makeSelectSerialNumber(),
  makeSelectFilesTokens(),
  makeSelectSelfieToken(),
  makeSelectIssuingCountry(),
  makeSelectExpireDate(),
  makeSelectJurisdictionCode(),
  makeSelectPersonalCode(),
  makeSelectLivenessCheckStats(),
  (
    personalData,
    subType,
    serialNumber,
    images,
    selfieImage,
    issuingCountry,
    expireDate,
    jurisdictionCode,
    personalCode,
    livenessCheckRegula,
  ) => {
    return {
      type: Stage.IDENTITY,
      personalData,
      subType,
      serialNumber,
      images,
      issuingCountry,
      personalCode,
      hasExpireDate: !!expireDate,
      ...(jurisdictionCode ? { jurisdictionCode } : {}),
      ...(expireDate ? { expireDate } : {}),
      ...(selfieImage ? { selfieImage } : {}),
      ...(livenessCheckRegula?.length ? { livenessCheckRegula }: {}),
    };
  }
);

export const makeSelectAllStageData = () =>
  createSelector(
    makeSelectResidence(),
    makeSelectFullResidence(),
    makeSelectIdentityDocument,
    makeSelectOccupationBlock(),
    makeSelectQuestionnaireBlock(),
    makeSelectSourceOfFundsBlock(),
    makeSelectAddressDocumentBlock(),
    makeSelectPhoneBlock(),
    (
      residence,
      fullResidence,
      identityDocument,
      occupation,
      questionnairePage,
      sourceOfFunds,
      addressDocument,
      phone,
    ) => {
      return fromJS(
        omitBy(
          {
            ...(residence && !residence.isEmpty() && fullResidence.isEmpty() ? { residence } : {}),
            ...(fullResidence && !fullResidence.isEmpty()
              ? { fullResidence: fullResidence.toJS() }
              : {}),
            phone,
            identityDocument,
            occupation,
            questionnairePage,
            ...(sourceOfFunds.isEmpty() ? {} : { sof: sourceOfFunds.toJS() }),
            ...(addressDocument.isEmpty() ? {} : { addressDocument: {...addressDocument.toJS(), type: 'address'} }),
          },
          isEmpty
        )
      ).toJS();
    }
  );

const makeSelectResidenceMeta = () =>
  createSelector(
    makeSelectWithMetaSource(makeSelectCountryMeta),
    makeSelectWithMetaSource(makeSelectRegionMeta),
    makeSelectWithMetaSource(makeSelectSubRegionMeta),
    makeSelectWithMetaSource(makeSelectSSNMeta),
    (country, region, subRegion, SSN) => {
      return omitBy({ country, region, subRegion, SSN }, isEmpty);
    }
  );

const makeSelectFullResidenceMeta = () =>
  createSelector(
    makeSelectWithMetaSource(makeSelectFullResidenceCountryMeta),
    makeSelectWithMetaSource(makeSelectFullResidenceRegionMeta),
    makeSelectWithMetaSource(makeSelectFullResidenceSubRegionMeta),
    makeSelectWithMetaSource(makeSelectFullResidenceSSNMeta),
    makeSelectWithMetaSource(makeSelectStreetMeta),
    makeSelectWithMetaSource(makeSelectCityMeta),
    makeSelectWithMetaSource(makeSelectZipCodeMeta),
    makeSelectWithMetaSource(makeSelectAptSuiteMeta),
    (country, region, subRegion, SSN, street, city, zipCode, aptSuite) => {
      return omitBy({ country, region, subRegion, SSN, street, city, zipCode, aptSuite }, isEmpty);
    }
  );

const makeSelectPhoneNumberMeta = () =>
  createSelector(makeSelectWithMetaSource(makeSelectPhoneMeta), (phone) => {
    return omitBy({ phone }, isEmpty);
  });

const makeSelectPersonalDataMeta = () =>
  createSelector(
    makeSelectWithMetaSource(makeSelectDateOfBirthMeta),
    makeSelectWithMetaSource(makeSelectFirstNameMeta),
    makeSelectWithMetaSource(makeSelectGenderMeta),
    makeSelectWithMetaSource(makeSelectMiddleNameNameMeta),
    makeSelectWithMetaSource(makeSelectLastNameMeta),
    (dateOfBirth, firstName, gender, middleName, lastName) => {
      return omitBy({ dateOfBirth, firstName, gender, middleName, lastName }, isEmpty);
    }
  );

const makeSelectIdentityDocumentMeta = () =>
  createSelector(
    makeSelectWithMetaSource(makeSelectIssuingCountryMeta),
    makeSelectWithMetaSource(makeSelectDocSubTypeMeta),
    makeSelectPersonalDataMeta(),
    makeSelectWithMetaSource(makeSelectSerialNumberMeta),
    makeSelectWithMetaSource(makeSelectPersonalCodeMeta),
    makeSelectWithMetaSource(makeSelectExpireDateMeta),
    (issuingCountry, subType, personalData, serialNumber, expireDate, personalCode) => {
      return {
        issuingCountry,
        subType,
        selfieImage: MetaSource.MANUAL,
        images: MetaSource.MANUAL,
        personalData,
        serialNumber,
        personalCode,
        expireDate,
        hasExpireDate: expireDate,
      };
    }
  );

export const makeSelectAllStageMetaData = () =>
  createSelector(
    makeSelectResidenceMeta(),
    makeSelectFullResidenceMeta(),
    makeSelectPhoneNumberMeta(),
    makeSelectIdentityDocumentMeta(),
    (residence, fullResidence, phoneNumber, identityDocument) => {
      return omitBy(
        {
          ...(residence && isEmpty(fullResidence) ? { residence } : {}),
          ...(fullResidence ? { fullResidence } : {}),
          phoneNumber,
          identityDocument,
        },
        isEmpty
      );
    }
  );

export const makeSelectPrefilledStageData = () => {
  return createSelector(makeSelectPrefilledPhoneBlock(), makeSelectCountry(), (phone, country) => {
    return {
      phone, country
    };
  });
};
