import { Styles } from 'react-select';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';

import { setColorByState } from 'utils/styledComponents/helpers';
import { joinIfNotEmpty } from 'utils/helpers';

import flags from 'components/common/Flag/images/flags.png';
import globalIcon from 'components/common/Flag/images/global_icon.svg';
import { IDropdownProps } from '../Dropdown';

interface IOptionTypeBase {
  label: string;
  value: string;
  data?: object;
}

interface IOptionItem {
  id: string;
  name: string;
  alpha2: string;
  alpha3: string;
  regions: any[];
  hint: string;
  geoLocation: string;
  phoneCode: string;
}

interface IReactSelectStates {
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
}

interface IDropdownThemeProps {
  backgroundColor: string;
  borderColor: string;
  borderRadius: string;
  borderWidth: string;
  boxShadow: string;
  color: string;
  focus: { [k: string]: string };
  disabled: { [k: string]: string };
  error: { [k: string]: string };
  zIndex?: string;
  hover: any;
  active: any;
  backgroundPosition?: string;
  placeholderColor: string;
  cdn?: string;
  valid: { [k: string]: string };
}

interface IGetDropdownStyles {
  (theme: IDropdownThemeProps, states: { error: boolean; valid: boolean }): Styles;
}

export const getDropdownStyles: IGetDropdownStyles = (
  {
    backgroundColor,
    borderColor,
    borderRadius,
    borderWidth,
    boxShadow,
    color,
    focus,
    disabled,
    error,
    hover,
    active,
    valid,
    placeholderColor,
  },
  { error: dropdownError, valid: dropdownValid }
) => {
  return {
    control: (provided, { isDisabled, isFocused }: IReactSelectStates) => {
      return {
        ...provided,
        boxShadow,
        backgroundColor: setColorByState(
          {
            default: backgroundColor,
            disabled: disabled.backgroundColor,
            error: error.backgroundColor,
          },
          { disabled: isDisabled, error: dropdownError }
        ),
        borderColor: setColorByState(
          {
            default: borderColor,
            disabled: disabled.borderColor,
            focused: focus.borderColor,
            error: error.borderColor,
            valid: valid.borderColor
          },
          {
            disabled: isDisabled,
            focused: isFocused,
            error: dropdownError,
            valid: dropdownValid
          }
        ),
        borderWidth,
        borderRadius,
        '&:hover': {
          borderColor: setColorByState(
            {
              default: borderColor,
              focused: focus.borderColor,
              error: error.borderColor,
            },
            {
              focused: isFocused,
              error: dropdownError,
            }
          ),
        },
        color,
      };
    },
    dropdownIndicator: (
      provided,
      { isDisabled, isFocused }: IReactSelectStates
    ) => ({
      ...provided,
      color: setColorByState(
        {
          default: color,
          disabled: disabled.color,
        },
        {
          disabled: isDisabled,
          focused: isFocused,
        }
      ),
    }),
    input: (provided) => {
      return {
        ...provided,

        input: {
          fontFamily: 'inherit',
          opacity: '1 !important',
        },
      };
    },
    indicatorSeparator: () => ({
      width: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 4,
      zIndex: 100,
    }),
    option: (
      provided,
      { isDisabled, isFocused, isSelected }: IReactSelectStates
    ) => ({
      ...provided,
      backgroundColor: setColorByState(
        {
          default: 'none',
          focused: hover.backgroundColor,
          selected: active.backgroundColor,
        },
        { focused: isFocused, selected: isSelected }
      ),
      color: 'inherit',
      ':active': {
        backgroundColor: active.backgroundColor,
      },
      ':focus': {
        backgroundColor: active.backgroundColor,
      },
      padding: '11px 14px',
      borderBottom: `1px solid ${borderColor}`,
    }),
    placeholder: (
      provided,
      { isDisabled, isFocused, isSelected }: IReactSelectStates
    ) => ({
      ...provided,
      color: setColorByState(
        { default: placeholderColor, disabled: disabled.color },
        { disabled: isDisabled }
      ),
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: 12,
    }),
    multiValue: (provided) => ({
      ...provided,
      background: '#F5F6F7',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      background: 'transparent',
      color: '#263850',
      ':hover': {
        color: '#263850',
        background: 'transparent',
      }
    })
  };
};

export const getPhoneDropdownStyles: IGetDropdownStyles = ({
  borderColor,
  color,
  disabled,
  hover,
  active,
  backgroundPosition,
  placeholderColor,
  cdn,
}) => {
  return {
    control: (provided) => {
      return {
        ...provided,
        position: 'absolute',
        width: '125px',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        outline: 'none',
        '&:active': {
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
        },
        ':after': {
          content: '""',
          position: 'absolute',
          right: 0,
          display: 'inline-block',
          lineHeight: 1,
          height: 16,
          width: 1,
          backgroundColor: borderColor,
        },
        color,
      };
    },
    dropdownIndicator: (
      provided,
      { isDisabled, isFocused }: IReactSelectStates
    ) => ({
      ...provided,
      color: setColorByState(
        {
          default: color,
          disabled: disabled.color,
        },
        {
          disabled: isDisabled,
          focused: isFocused,
        }
      ),
    }),
    input: (provided) => {
      return {
        ...provided,
        position: 'relative',
        width: '100%',
        paddingLeft: '35px',
        fontFamily: 'inherit',
        opacity: '1 !important',
        ':before': {
          backgroundColor: color,
          content: '" "',
          position: 'absolute',
          top: backgroundPosition ? 6 : 3,
          left: 0,
          display: 'inline-block',
          width: '16px',
          height: backgroundPosition ? '11px' : '17px',
          marginRight: 8,
          background: `url(${joinIfNotEmpty(cdn, backgroundPosition ? flags : globalIcon)}) no-repeat`,
          backgroundPosition,
        },
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        paddingLeft: '37px',
        ':before': {
          content: '"+"',
          position: 'absolute',
          left: 25,
          display: 'inline-block',
          width: '10px',
          height: '100%',
          lineHeight: 1,
        },
      };
    },
    indicatorSeparator: () => ({
      width: 0,
    }),
    menu: (provided) => ({
      ...provided,
      top: '35px',
      bottom: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 4,
      zIndex: 100,
    }),
    option: (provided, { isFocused, isSelected }: IReactSelectStates) => ({
      ...provided,
      width: '100%',
      backgroundColor: setColorByState(
        {
          default: 'none',
          focused: hover.backgroundColor,
          selected: active.backgroundColor,
        },
        { focused: isFocused, selected: isSelected }
      ),
      color: 'inherit',
      ':active': {
        backgroundColor: active.backgroundColor,
      },
      ':focus': {
        backgroundColor: active.backgroundColor,
      },
      padding: '11px 14px',
      borderBottom: `1px solid ${borderColor}`,
    }),
    placeholder: (provided, { isDisabled }: IReactSelectStates) => ({
      ...provided,
      color: setColorByState(
        { default: placeholderColor, disabled: disabled.color },
        { disabled: isDisabled }
      ),
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: 12,
    }),
  };
};

export const adaptOptions = (dictionary: IDropdownProps['dictionary'] = [], cdn: string) => {
  return dictionary.reduce(
    (dict: IOptionTypeBase[], item: any): IOptionTypeBase[] => {
      const {
        id,
        name,
        alpha2,
        alpha3,
        regions,
        hint,
        geoLocation,
        phoneCode,
      } = item;
      if (!id || !name) {
        return [...dict, item];
      }

      return [
        ...dict,
        {
          label: name,
          value: id,
          data: omitBy(
            {
              id,
              name,
              alpha2,
              alpha3,
              regions,
              hint,
              geoLocation,
              phoneCode,
              cdn,
            },
            (val) => !isBoolean(val) && isEmpty(val)
          ),
        },
      ];
    },
    []
  );
};

export const adaptPhoneCodeOptions = (
  dictionary: IDropdownProps['dictionary'] = [],
  cdn: string
) => {
  return dictionary.reduce(
    (dict: IOptionTypeBase[], item: any): IOptionTypeBase[] => {
      const { countryName, countryId, alpha2, alpha3, code, phoneMask } = item;
      if (!countryId || !code) {
        return [...dict, item];
      }

      return [
        ...dict,
        {
          label: code,
          value: code,
          data: { countryId, countryName, alpha2, alpha3, cdn, phoneMask },
        },
      ];
    },
    []
  );
};

export const adaptZipOptions = (dictionary: any) => {
  return dictionary.reduce((dict: any, { id, text, isContainer }: any): any => {
    if (!id || !text) {
      return dict;
    }

    return [
      ...dict,
      { value: id, label: text, data: { id, text, isContainer } },
    ];
  }, []);
};
