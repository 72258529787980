import { isImmutable } from 'immutable';
import capitalize from 'lodash/capitalize';

export { default as capitalize } from 'lodash/capitalize'

export const toJS = (value: any): any => {
  return isImmutable(value) ? value.toJS() : value;
};

export const joinByChar = (input: string, char = '-') =>
  input
    .split(char)
    .map((part: string, index: number) => (!index ? part : capitalize(part)))
    .join('');

export const generateFileId = () => Number(`${Math.random()}`.slice(2, 8));


export const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)


export function joinIfNotEmpty(...args: any[]) {
  return args.join('');
}

export const extractPayloadFromJWT = (token: string) => (
  token ? JSON.parse(atob(token.split('.')[1])) : null)
