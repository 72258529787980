/* eslint-disable */
import React, { FC } from 'react';
import styled from 'styled-components';

import { ITranslate } from 'system/Localization';
import { Resubmit } from 'containers/Resubmit';

import { Hamburger } from 'components/common/Hamburger';
import { Nav } from 'components/common/Nav';
import { ExitLink } from 'components/UI';
import * as Icon from 'components/UI/Icon/Markup';
import { Overlay } from 'components/UI/Overlay';
import { useOverlay } from 'Hooks/useOverlay';


export interface IMobileMenuProps {
  fallbackLink:string;
  handleClose?: () => void;
  translate: ITranslate;
}

export const MobileMenu: FC<IMobileMenuProps> = ({ fallbackLink, handleClose, translate }) => {
  useOverlay();
  return  (
    <Overlay>
      <Area>
        <Nav>
          <Right>
            <Hamburger active handleClick={handleClose} />
          </Right>
        </Nav>
        <Resubmit isMobile/>
        <Footer>
          <ExitLink block to={fallbackLink}>
            <Icon.Exit />
            {translate('exit_verification')}
          </ExitLink>
        </Footer>
      </Area>
    </Overlay>
  );
}

const Area = styled.div`
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  margin-left: auto;
  width: 270px;
`;

const Right = styled.span`
  display: block;
  margin-left: auto;
  width: 40px;
`;

const Footer = styled.div`
  margin-top: auto;
  padding: 16px;
`;
