import { List, Map } from 'immutable';
import { Stage, Block } from 'system/Stages/types';

export enum Stub {
  CORRECTION = 'correction',
  REDSTATE = 'redstate',
  SUBMITTED = 'submitted',
  NOTICE = 'notice',
  ERROR = 'error',
  REJECTED = 'rejected',
  QR_CONNECTED = 'qr-connected',
  QR_SUBMITTED = 'qr-submitted',
  PENDING = 'pending',
  APPROVE = 'accepted',
  NEXT = 'next',
  RETRY = 'retry',
  LIVENESS = 'liveness',
}

export enum Step {
  RESIDENCE = 'residence',
  FULL_RESIDENCE = 'full-residence',
  PHONE_NUMBER = 'phone',
  LIVENESS_CHECK = 'liveness-check',
  IDENTITY_DOCUMENT = 'identity-document',
  UPLOAD = 'upload',
  PERSONAL_INFORMATION = 'personal-information',
  INTERNATIONAL_PASSPORT_INFORMATION = 'internationalPassport-information',
  NATIONAL_ID_CARD_INFORMATION = 'nationalIdCard-information',
  DRIVER_LICENSE_INFORMATION = 'driverLicense-information',
  SOURCE_OF_FUNDS = 'sourceOfFunds',
  SOURCE_OF_FUNDS_DOCUMENTS = 'sourceOfFundsDocuments',

  ADDRESS_RESIDENCE = 'address-residence',
  ADDRESS_DOCUMENTS = 'address-documents',
  SOCIAL_PROFILES = 'social-profiles',

  PURPOSE_QUESTIONNAIRE = 'purpose-questionnaire',
  EMPLOYMENT = 'employment',
  EMPLOYMENT_DETAILS = 'employment-details',
  SOURCE_OF_FIAT_FUNDS = 'source-of-fiat-funds',
  SOURCE_OF_CRYPTO_FUNDS = 'source-of-crypto-funds',
  WITHDRAWAL_DESTINATION = 'withdrawal-destination',
  BANKING_JURISDICTION = 'banking-jurisdiction',
  FINANCIAL_QUESTIONNAIRE = 'financial-questionnaire',

  REQUEST = 'request',
  MENU = 'menu',
  GENERAL_INFO = 'general-information',
  BUSINESS_ACTIVITY = 'business-activity',
  ACCOUNT_ACTIVITY = 'account-activity',
  LICENSES = 'licenses',
  PAYMENT_DETAILS = 'payment-details',
  PERSONAL_INFO = 'personal-info',
  COMPANY_DOCUMENTS = 'company-documents',

  SIGNATURE = 'esignature',
}

export enum SubStep {
  // General information
  GENERAL = 'general',
  REGISTERED_ADDRESS = 'registered-address',
  PREMISES_ADDRESS = 'premises-address',
  GENERAL_VAT = 'general-vat',
  GENERAL_PHONE = 'phone',
  SOCIAL_PROFILES = 'social-profiles',

  // Business activity
  BUSINESS_ACTIVITY_INDUSTRY = 'industry',
  BUSINESS_ACTIVITY_FIAT = 'fiat',
  BUSINESS_ACTIVITY_FIAT_UPLOAD = 'upload-fiat-sources',
  BUSINESS_ACTIVITY_CRYPTO = 'crypto',
  BUSINESS_ACTIVITY_CRYPTO_UPLOAD = 'upload-crypto-sources',

  // Activity
  ACTIVITY = 'activity',

  // Licenses, permissions and AML regulation
  LICENSES_PERMISSIONS = 'licenses-permissions',
  AML_REGULATION = 'aml-regulation',

  // Company Documents
  UPLOAD = 'documents-upload',

  // Payment details
  PAYMENT_DETAILS_MENU = 'menu',
  PAYMENT_DETAILS_BANK_INFO = 'information',
  PAYMENT_DETAILS_BANK_STATEMENTS = 'statements',
  PAYMENT_DETAILS_BANK_INTERNATIONAL = 'international',
  PAYMENT_DETAILS_BANK_CORRESPONDENT = 'correspondent',
  PAYMENT_DETAILS_WALLET_ADDRESS = 'wallet-address',

  // Personal information
  ROLE_SELECT_PREVIEW = 'preview',
  REPRESENTATIVES = 'representatives',
  SELECT_ROLE = 'select-role',
  UBO_DOCUMENTS = 'ubo-documents',
  UBO_SOURCE_OF_FUNDS = 'ubo-source-of-funds',
  UBO_UPLOAD = 'ubo-upload',
  POSITION_DOCUMENTS = 'position-documents',
  POSITION_UPLOAD = 'position-upload',
  AUTHORIZED_DOCUMENTS = 'authorized-documents',
  AUTHORIZED_UPLOAD = 'authorized-upload',
  IDENTITY_DOCUMENT = 'identity-document',
  IDENTITY_UPLOAD = 'identity-upload',
  IDENTITY_SCAN = 'identity-scan',
  IDENTITY_LIVENESS_CHECK = 'identity-liveness-check',
  PERSONAL_INFORMATION = 'information',
  IDENTITY_DOC_INFO = 'identity-document-info',
  PHONE_NUMBER = 'phone',
  ADDRESS_RESIDENCE = 'address-residence',
  ADDRESS_DOCUMENTS = 'address-documents',
  ADDRESS_UPLOAD = 'address-upload',
  FINANCIAL_QUESTIONNAIRE = 'financial-questionnaire',

  // signature
  SIGNATURE = 'signature',
}

export type StepSchema = {
  id: Step | SubStep;
  fields: string[];
  required?: { [k: string]: string };
};

export type StepSchemaImmutable = Map<
  keyof StepSchema,
  string | List<string> | Map<string, string>
>;

export type RoutingSchema = {
  [k in Stage]?: {
    [k in Block]?: (StepSchema | StepSchema[])[];
  };
};

export type RouteId = Step;

export type RouteStage = { [k in Stage]?: RouteId[] };
