import React, { ComponentType, ReactNode } from 'react';
import { Map } from 'immutable';
import { connect, Matching } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector, Selector } from 'reselect';
import ReactHtmlParser from 'react-html-parser';
import isObject from 'lodash/isObject';

import { setLocale } from 'system/Settings/actions';
import { unserialize } from 'utils/serialization';
import { Diff } from 'utility-types';

import { makeSelectLocale, makeSelectLocales } from './selectors';

export type ITranslate = (
  term: string,
  defaultValue?: string | ReactNode,
  ...placeholders: any[]
) => ReactNode;

export interface ILocalizationProps {
  translate: ITranslate;
  locale?: string;
  locales?: Map<string, string>;
}

interface Internal {
  translate: ITranslate;
}

export type WithLocalizationProps<P> = P & ILocalizationProps;

export const withLocalization = <BaseProps extends ILocalizationProps>(
  Component: React.ComponentType<BaseProps>
) => {
  const mapStateToProps: Selector<
    any,
    { locale: string; locales: Map<string, string> }
  > = createStructuredSelector({
    locale: makeSelectLocale(),
    locales: makeSelectLocales(),
  });

  const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    setLocale: (locale: string) => dispatch(setLocale(locale)),
  });

  type PropsFromRedux = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

  const LocalizationWrapper: ComponentType<Matching<
    Internal,
    PropsFromRedux
  >> = (props) => {
    const { locale, locales, ...rest } = props;
    const withFormattedText = (term: string) => term.replace(/\\n/g, '\n');

    const pull = (term: string, defaultValue = '') => {
      return withFormattedText(locales.getIn([locale, term], defaultValue));
    };

    const translate = (
      term: string,
      defaultValue = '',
      ...placeholders: any[]
    ): ReactNode => {
      try {
        const result = unserialize(term);

        let translation = defaultValue;
        if (isObject(result)) {
          const { key = '', args = [] } = result as {
            key: string;
            args: any[];
          };
          translation = args.reduce((reduction: string, argument = '') => {
            return reduction.replace(/\[\w+\]/, argument);
          }, pull(key));

          return ReactHtmlParser(translation);
        }

        translation = placeholders.reduce(
          (reduction: string, placeholder = '') => {
            return reduction.replace(/\[\w+\]/, placeholder);
          },
          pull(term, defaultValue)
        );

        if (translation.startsWith('{')) {
          return translation;
        }

        return ReactHtmlParser(translation);
      } catch (err) {
        return defaultValue;
      }
    };

    return (
      <Component
        {...(rest as BaseProps)}
        translate={translate}
        locale={locale}
      />
    );
  };

  return connect<
    ReturnType<typeof mapStateToProps>,
    typeof mapDispatchToProps,
    Diff<BaseProps, Internal>,
    any
  >(
    mapStateToProps,
    mapDispatchToProps
  )(LocalizationWrapper);
};
