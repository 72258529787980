import React, { Children, FC, ReactNode, useContext } from 'react';
import styled from 'styled-components';
import { WithThemeProps } from 'merchants/types/IThemeStyles';

import { Link } from 'components/UI';
import { ConfigContext } from 'merchants';
import { IConfig } from 'merchants/types/IConfig';
import { joinIfNotEmpty  } from 'utils/helpers';
import arrowDark from './arrow-dark.svg';
import arrow from './arrow.svg';

interface IBreadcrumbsProps {
  children: ReactNode;
  mobile?: boolean;
  cdn?: string;
}

interface IBox {
  mobile: boolean;
}

interface IBreadcrumb {
  mobile: boolean;
  cdn: string;
}

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({
  children,
  mobile,
}) => {
  const {
    appConfig: { cdn }
  } = useContext<IConfig>(ConfigContext);
  return (
    <Box mobile={mobile}>
      {Children.map(children, (child, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <Breadcrumb key={`breadcrumb-${key}`} mobile={mobile} cdn={cdn}>
          {child}
        </Breadcrumb>
      ))}
    </Box>
  );
};

const Box = styled.ul.attrs(({ mobile }: WithThemeProps<IBox>) => ({ mobile }))`
  display: flex;
  
  ${({ mobile }) =>
    !mobile
      ? `
        &:not(:first-child) {
          margin-left: 25px;
        }
      `
      : ''}

  ${({ mobile, theme }) =>
    mobile
      ? `
          background-color: ${theme.components.breadcrumbs.mobile.backgroundColor};
          padding: 10px 16px 11px;
        `
      : ''}
  
  ${Link} {
    color: inherit;
  }
`;


const Breadcrumb = styled.li.attrs((props: IBreadcrumbsProps) => props)`
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;

  ${({ mobile, theme }: WithThemeProps<IBreadcrumb>) =>
    mobile
      ? `color: ${theme.components.breadcrumbs.mobile.color};`
      : `color:${theme.components.breadcrumbs.color};`}

  + li {
    &::before {
      content: '';
      padding-left: 15px;
      padding-right: 15px;
      background: no-repeat
        url(${({
          mobile,
          cdn,
          theme: {
            components: {
              breadcrumbs: { darkArrow },
            },
          },
        }: WithThemeProps<IBreadcrumb>) => joinIfNotEmpty(cdn, `${mobile || darkArrow ? arrowDark : arrow}`)})
        center;
    }
  }
`;
