import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const AngleDown: React.FunctionComponent<IconProps> = ({ width, height, className }) => {
  const {
    icons: {
      markup: {
        angle: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg className={className} height={height} viewBox={`0 0 ${width} ${height}`} width={width} xmlns="http://www.w3.org/2000/svg">
      <path d="M5.406 7.174l-5.16-5.16A.84.84 0 1 1 1.435.826L6 5.39 10.565.826a.84.84 0 0 1 1.189 1.188l-5.16 5.16a.837.837 0 0 1-1.188 0z" fill={fill} fillRule="nonzero" />
    </svg>
  );
};

AngleDown.displayName = 'AngleDownIcon';

AngleDown.defaultProps = {  height: '8', width: '12' };
