import { connectRouter } from 'connected-react-router/immutable';
import { combineReducers } from 'redux-immutable';
import { History } from 'history';

import * as Bootstrap from 'system/Bootstrap/reducer';
import * as Routing from 'system/Routing/reducer';
import * as Settings from 'system/Settings/reducers';
import * as Profile from 'system/Profile/reducer';
import * as QR from 'system/Dossier/Upload/reducer';
import * as Dicts from 'system/Dicts/reducer';
import * as UI from 'system/UI/reducer';
import * as Analytics from 'system/Analytics/reducer';

import * as Identity from 'stages/Identity/reducer';
import * as Address from 'stages/Address/reducer';
import * as Enhanced from 'stages/Enhanced/reducer';
import * as Questionnaire from 'stages/Questionnaire/reducer';
import * as Corporate from 'stages/Corporate/reducer';
import * as LivenessCheck from 'stages/Identity/blocks/IdentityDocuments/LivenessCheck/reducer';

const dossierReducers = combineReducers({
  routing: Routing.reducer,
  qr: QR.reducer,
  identity: Identity.reducer as any,
  address: Address.reducer as any,
  enhanced: Enhanced.reducer as any,
  cfd: Questionnaire.reducer,
  corporate: Corporate.reducer as any,
  livenessCheck: LivenessCheck.reducer as any,
});

export const createRootReducer = (history: History) =>
  combineReducers({
    bootstrap: Bootstrap.reducer,
    analytics: Analytics.reducer,
    settings: Settings.reducer,
    profile: Profile.reducer as any,
    dossier: dossierReducers,
    dicts: Dicts.reducer,
    router: connectRouter(history),
    ui: UI.reducer,
  });
