import { createContext, useContext } from 'react';

export interface IGoogleTagManagerContext {
  enable: boolean;
  uid: string;
  googleTagManagerId: string;
}

export const Context = createContext<IGoogleTagManagerContext>({
  uid: '',
  enable: false,
  googleTagManagerId: '',
});

export const useGoogleTagManagerConfig = () => {
  return useContext(Context);
};
