import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { identitySelector, SubStore } from 'stages/Identity/selectors';
import { Field } from 'utils/types/shared/enums';

export const occupationSelector = (subStore = SubStore.DATA) => {
  return createSelector(identitySelector, (state) => state.getIn([subStore, Field.OCCUPATION], Map()));
};

export const makeSelectOccupationStatus = () => {
  return createSelector(occupationSelector(), (state) => state.get(Field.OCCUPATION_STATUS, ''));
};

export const makeSelectOccupationStatusMeta = () => {
  return createSelector(occupationSelector(SubStore.META), (state) =>
    state.get(Field.OCCUPATION_STATUS, Map())
  );
};

export const makeSelectOccupationPosition = () => {
  return createSelector(occupationSelector(), (state) => state.get(Field.OCCUPATION_POSITION, ''));
};

export const makeSelectOccupationPositionMeta = () => {
  return createSelector(occupationSelector(SubStore.META), (state) =>
    state.get(Field.OCCUPATION_POSITION, Map())
  );
};


export const makeSelectOccupationEmployedDetails = () => {
  return createSelector(occupationSelector(), (state) => state.get('employedDetails', Map()));
};

export const makeSelectOccupationSelfEmployedDetails = () => {
  return createSelector(occupationSelector(), (state) => state.get('selfEmployedDetails', Map()));
};

export const makeSelectAccountCreateReason = () => {
  return createSelector(occupationSelector(), (state) =>
    state.get(Field.ACCOUNT_CREATE_REASON, '')
  );
};

export const makeSelectAccountCreateOtherReason = () => {
  return createSelector(occupationSelector(), (state) =>
    state.get(Field.ACCOUNT_CREATE_REASON_OTHER, '')
  );
};


export const makeSelectOccupationBlock = () => {
  return createSelector(
    [
      makeSelectOccupationStatus(),
      makeSelectAccountCreateReason(),
      makeSelectAccountCreateOtherReason(),
      makeSelectOccupationEmployedDetails(),
      makeSelectOccupationSelfEmployedDetails(),
    ],
    (status, reason, reasonOther, employedDetails, selfEmployedDetails) => {
      return {
        ...(status && { [Field.OCCUPATION_STATUS]: status }),
        ...(reason && { [Field.ACCOUNT_CREATE_REASON]: reason }),
        ...(reasonOther && { [Field.ACCOUNT_CREATE_REASON_OTHER]: reasonOther }),
        ...(!employedDetails.isEmpty() && { employedDetails }),
        ...(!selfEmployedDetails.isEmpty() && { selfEmployedDetails }),
      };
    }
  );
};
