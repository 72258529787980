/* eslint-disable */
import { createSelector } from 'reselect';
import {isEmpty, omitBy, cond, pick, matchesProperty, constant } from 'lodash';

import {
  makeSelectQuestionnaire,
  cryptoFundsSelector,
  withdrawalSelector,
  makeSelectCompany,
  makeSelectEmploymentPeriod,
  makeSelectEmploymentPosition,
  makeSelectEmploymentPositionDetails,
  makeSelectEmploymentStatus,
  makeSelectMonthlyIncome,
  makeSelectSelfEmploymentCompanyName,
  makeSelectSelfEmploymentIndustry,
  makeSelectSelfEmploymentIndustryDetailed,
  makeSelectSelfEmploymentType,
  makeSelectSelfEmploymentTypeDetailed,
  makeSelectTaxResidence,
  makeSelectTIN,
  makeSelectDetailedSources,
  makeSelectPurposeQuestionnaireReason,
  makeSelectOtherDetailed,
} from './selectors';
import { Field } from 'utils/types/shared/enums';

const makeSelectEmployment = createSelector(
  makeSelectEmploymentStatus(),
  makeSelectCompany(),
  makeSelectSelfEmploymentType(),
  makeSelectSelfEmploymentTypeDetailed(),
  makeSelectSelfEmploymentCompanyName(),
  makeSelectSelfEmploymentIndustry(),
  makeSelectSelfEmploymentIndustryDetailed(),
  makeSelectEmploymentPosition(),
  makeSelectEmploymentPositionDetails(),
  makeSelectEmploymentPeriod(),
  makeSelectTIN(),
  makeSelectTaxResidence(),
  (
    status: any,
    company: any,
    selfEmploymentType: any,
    selfEmploymentTypeDetailed: any,
    selfEmploymentCompanyName: any,
    selfEmploymentIndustry: any,
    selfEmploymentIndustryDetailed: any,
    position: any,
    positionDetailed: any,
    employmentPeriod: any,
    TIN: any,
    taxResidence: any
  ) => {
    return {
      status,
      ...(isEmpty(company) ? {} : { company }),
      ...(selfEmploymentType ? { selfEmploymentType } : {}),
      ...(selfEmploymentTypeDetailed ? { selfEmploymentTypeDetailed } : {}),
      ...(selfEmploymentCompanyName ? { selfEmploymentCompanyName } : {}),
      ...(selfEmploymentIndustry ? { selfEmploymentIndustry } : {}),
      ...(selfEmploymentIndustryDetailed
        ? { selfEmploymentIndustryDetailed }
        : {}),
      ...(position ? { position } : {}),
      ...(positionDetailed ? { positionDetailed } : {}),
      ...(employmentPeriod ? { employmentPeriod } : {}),
      ...(TIN ? { TIN } : {}),
      ...(taxResidence ? { taxResidence } : {}),
    };
  }
);

const makeSelectSourceOfFiatFunds = createSelector(
  makeSelectMonthlyIncome(),
  makeSelectDetailedSources,
  (monthlyIncome, detailedSources) => {
    return {
      ...(monthlyIncome ? { monthlyIncome } : {}),
      ...(detailedSources.size
        ? { detailedSources: detailedSources.toJS() }
        : {}),
    };
  }
);

const makeSelectSourceOfFunds = createSelector(
  makeSelectSourceOfFiatFunds,
  cryptoFundsSelector(),
  withdrawalSelector,
  (sourceOfFiatFunds, sourceOfCryptoFunds, withdrawalDestination) => {
    return {
      ...(isEmpty(sourceOfFiatFunds) ? {} : { sourceOfFiatFunds }),
      ...(sourceOfCryptoFunds.size
        ? {
            sourceOfCryptoFunds: omitBy(
              sourceOfCryptoFunds.toJS(),
              (value, key) => key === Field.CRYPTO_FUNDS_DEPOSIT
            ),
          }
        : {}),
      ...(withdrawalDestination.size
        ? {
            withdrawalDestination: omitBy(
              withdrawalDestination.toJS(),
              (value, key) => key === Field.CRYPTO_FUNDS_WITHDRAW
            ),
          }
        : {}),
    };
  }
);

const makeSelectPurposeQuestionnaire = createSelector(
  makeSelectPurposeQuestionnaireReason(),
  makeSelectOtherDetailed(),
  (reason, otherDetailed) => {
    const values = {
      [Field.PURPOSE_QUESTIONNAIRE_REASON]: reason,
      [Field.OTHER_DETAILED]: otherDetailed,
    }

    return cond([
      [
        matchesProperty(Field.PURPOSE_QUESTIONNAIRE_REASON, 
        Field.DEPOSIT_REASON), constant({[Field.DEPOSIT_REASON]: true})],
      [
        matchesProperty(Field.PURPOSE_QUESTIONNAIRE_REASON, 
        Field.INCREASE_TRADING_VOLUME_REASON), constant({[Field.INCREASE_TRADING_VOLUME_REASON]: true})],
      [
        matchesProperty(Field.PURPOSE_QUESTIONNAIRE_REASON, 
        Field.OTHER_DETAILED), (values) => pick(values, Field.OTHER_DETAILED)]
    ])(values)
  }
)

export const makeSelectAllStageData = () =>
  createSelector(
    makeSelectEmployment,
    makeSelectQuestionnaire,
    makeSelectSourceOfFunds,
    makeSelectPurposeQuestionnaire,
    (employment, questionnaire, sourceOfFunds, purposeQuestionnaire) => ({
      employment,
      ...(questionnaire.size ? { questionnaire: questionnaire.toJS() } : {}),
      ...(isEmpty(sourceOfFunds) ? {} : { sourceOfFunds }),
      purposeQuestionnaire
    })
  );
