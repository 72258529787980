import { IConfig } from 'merchants/types/IConfig';
import { Type, IStart, ILoaded, IError } from './actionTypes';

export const start = (config: IConfig): IStart => ({
  type: Type.APPLICATION_LOADING,
  payload: { config },
});

export const end = (): ILoaded => ({
  type: Type.APPLICATION_LOADED,
  payload: {}
});

export const failed = (error: Error[]): IError => ({
  type: Type.APPLICATION_FAILED,
  payload: error,
});
