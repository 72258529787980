import { fromJS, List, Map } from 'immutable';
import { Type as UIType, ActionType as UIActionType } from 'system/UI/actionTypes';
import { Stage } from 'system/Stages/types';
import { Type, ActionType } from './actionTypes';

interface IState {
  loading: boolean;
  errors: object;
  data: object;
}

export const defaultState = fromJS({
  loading: false,
  errors: [],
  data: {},
} as IState);

export const reducer = (state = defaultState, { type, payload }: ActionType & UIActionType) => {
  switch (type) {
    case Type.ENHANCED_STEP_INITIAL_SET: {
      const { stepId, fields = {} } = payload;

      const update = state.getIn(['data', stepId], fromJS({})).merge(fromJS(fields));

      return state.setIn(['data', stepId], fromJS(update));
    }
    case Type.ENHANCED_STEP_SET: {
      const { stepId, fields = {} } = payload;

      return state.setIn(['data', stepId], fromJS(fields));
    }
    case Type.ENHANCED_META_SET: {
      const { stepId, fields } = payload;

      return state.mergeIn(['meta', stepId], fields);
    }
    case Type.ENHANCED_SOURCES_ADD_FILES: {
      const { source, files: images } = payload;

      const path = ['data', 'sourceOfFiatFunds', 'detailedSources'];

     const find =  state.getIn(path, List()).find((value: Map<string, any>) => {
        return value.get('source') === source ;
      });

      if (!find) {
        const data = fromJS([{ source, images }]);
        return state.mergeIn(['data', 'sourceOfFiatFunds', 'detailedSources'], data)
      }

      const sources = state
        .getIn(path, List())
        .reduce(
          (reduction: List<Map<string, any>>, value: Map<string, any>) => {
            return value.get('source') === source
              ? reduction.push(value.setIn(['images'], fromJS(images)))
              : reduction.push(value);
          },
          List()
        );

      return state.setIn(path, sources);
    }
    case Type.ENHANCED_UPLOAD_FILES_REQUEST:
    case Type.ENHANCED_SUBMIT_REQUEST: {
      return state.set('loading', true);
    }

    case Type.ENHANCED_UPLOAD_FILES_SUCCESS:
    case Type.ENHANCED_SUBMIT_SUCCESS: {
      return state.set('loading', false);
    }

    case Type.ENHANCED_UPLOAD_FILES_ERROR:
    case Type.ENHANCED_SUBMIT_FAILED: {
      return state.set('loading', false).set('errors', fromJS(payload));
    }
    case UIType.UI_RESET_ERRORS:
      return state.set('errors', fromJS([]));
    case UIType.UI_RESET_STAGE_DATA: {
      const { stageId } = payload;
      return (stageId === Stage.ENHANCED || !stageId) ? defaultState : state;
    }
    default:
      return state;
  }
};

