import { Transition } from 'react-transition-group';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { withTheme } from 'styled-components';

import { Markup, IOverlayProps } from 'components/UI';
import { useOnClickOutside } from 'Hooks';
import { IThemeProps, WithThemeProps } from 'merchants/types/IThemeStyles';
import { DynamicPortal } from 'components/Portal';
import { ENTER_TIMEOUT, EXIT_TIMEOUT } from '../StagePreview/utils/constants';
import { Modal } from '../StagePreview/Modal';

interface BaseModalProps extends IThemeProps {
  isOpen: boolean;
  onRequestClose?: (state: boolean) => void;
  children: ReactNode;
}

interface IClose {
  onClick: (state: boolean) => void;
  role: string;
}

function BaseModalComponent({
  isOpen,
  onRequestClose,
  children,
  theme,
}: BaseModalProps) {
  const modalContent = useRef<HTMLDivElement>(null);

  const [visible, toggleVisible] = useState<boolean>(Boolean(isOpen));

  useEffect(() => {
    toggleVisible(Boolean(isOpen));
  }, [isOpen]);

  const handleCloseModal = () => {
    toggleVisible(false);
    if (onRequestClose) {
      onRequestClose(false);
    }
  };

  useOnClickOutside(modalContent, handleCloseModal);
  return (
    <Transition
      in={visible}
      timeout={{
        enter: ENTER_TIMEOUT,
        exit: EXIT_TIMEOUT,
      }}
    >
      {(state) => {
        return visible && (
          <DynamicPortal id="resubmit">
            <Modal state={state}>
              <ModalContent state={state} ref={modalContent as any}>
                <Close
                  onClick={handleCloseModal}
                  role="button"
                  data-aqaid="close-modal-btn"
                >
                  <Markup.Close
                    fill={theme.components.modal.backgroundColorCloseButton}
                    fill2={theme.components.modal.colorCloseButton}
                  />
                </Close>
                {children}
              </ModalContent>
            </Modal>
          </DynamicPortal>
        );
      }}
    </Transition>
  );
}

const Close = styled.span.attrs((props: WithThemeProps<IClose>) => props)`
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  @media (min-width: ${({ theme }: WithThemeProps<{}>) =>
      theme.breakpoint.desktop}) {
    top: 16px;
    right: 16px;
  }
`;

const ModalContent = styled.div.attrs(
  ({
    state = 'entered',
    ...props
  }: IOverlayProps & WithThemeProps<{ ref: any }>) => ({
    state,
    ...props,
  })
)`
  text-align: center;
  position: relative;
  padding: 56px 32px 32px;
  max-width: 95%;
  max-height: 95%;
  overflow: auto;
  background-color: #fff;
  display: ${({ state }: WithThemeProps<{ state: string }>) => {
    return state === 'entered' ? 'block' : 'none';
  }};
  z-index: 1;

  @media (min-width: ${({ theme }: WithThemeProps<{}>) =>
      theme.breakpoint.desktop}) {
    width: auto;
    height: auto;
    padding: 56px;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  line-height: 1.33;
  font-weight: 600;
  margin: 0 0 24px;
`;

export const Content = styled.p`
  font-size: 16px;
  line-height: 1.5;
  max-width: 370px;
  margin: 0 auto 24px;
`;

export const Buttons = styled.div`
  > button {
    margin-right: 8px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (min-width: ${({ theme }: WithThemeProps<{}>) =>
      theme.breakpoint.desktop}) {
    margin-right: 24px;
  }
`;

export const BaseModal = withTheme(BaseModalComponent);
