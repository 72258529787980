import cookie, { CookieAttributes } from 'js-cookie';

export const get = (name: string): string | undefined => {
  return cookie.get(name);
};

export const set = (
  name: string,
  value: string,
  options?: CookieAttributes
) => {
  return cookie.set(name, value, options);
};

export const remove = (
  alias: string | string[],
  options?: CookieAttributes
) => {
  const rm = (name: string) => cookie.remove(name, options);

  return Array.isArray(alias) ? alias.forEach(rm) : rm(alias);
};

export const cookieH = { get, set, remove };
