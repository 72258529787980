import { createSelector } from 'reselect';

import { StageId } from 'system/Stages/types';
import { StoreFields } from './reducer';

export const analyticsSelector = (state: any) => state.get('analytics');

export const makeSelectVerificationDateByStageStart = (stageId: StageId) => createSelector(
  analyticsSelector,
  (state) => {
    return state.getIn([StoreFields.VERIFICATION_DATE_START, stageId], 0)
  }
);
