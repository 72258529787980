import { lazy } from 'react';
import { IStageRouteProps } from './interfaces';
import { Routes } from './Routes';
import { Stubs } from '../../containers/Stubs';
import { Stage } from '../Stages/types';

const lazyIdentity = lazy(() => import('stages/Identity/Identity'));
const lazyAddress = lazy(() => import('stages/Address/Address'));
const lazyEnhanced = lazy(() => import('stages/Enhanced/Enhanced'));
const lazyCorporate = lazy(() => import('stages/Corporate/Corporate'));
const lazyQuestionnaire = lazy(() => import('stages/Questionnaire/Questionnaire'));

export const stageRoutes: IStageRouteProps = {
  stub: {
    key: 'stub',
    path: Routes.stub.all(),
    component: Stubs,
  },
  [Stage.IDENTITY]: {
    key: Stage.IDENTITY,
    path: Routes.stage.one(Stage.IDENTITY),
    component: lazyIdentity,
  },
  [Stage.ADDRESS]: {
    key: Stage.ADDRESS,
    path: Routes.stage.one(Stage.ADDRESS),
    component: lazyAddress,
  },
  [Stage.ENHANCED]: {
    key: Stage.ENHANCED,
    path: Routes.stage.one(Stage.ENHANCED),
    component: lazyEnhanced,
  },
  [Stage.CORPORATE]: {
    key: Stage.CORPORATE,
    path: Routes.stage.one(Stage.CORPORATE, '', true),
    component: lazyCorporate,
  },
  [Stage.CFD]: {
    key: Stage.CFD,
    path: Routes.stage.one(Stage.CFD),
    component: lazyQuestionnaire,
  },
};
