import { call, put, select } from 'redux-saga/effects';

import { Stage } from 'system/Stages/types';
import { storeUserProfile } from 'system/Profile/sagas';
import { saveStage } from 'system/Stages/sagas';
import {
  navigateAfterSubmit,
  navigateToErrorLayout,
} from 'system/Routing/sagas';

import { makeErrorMessage } from 'utils/error';

import { makeSelectVerificationDateByStageStart } from 'system/Analytics/selectors';
import { successSubmitEnhanced, failedSubmitEnhanced } from '../actions';
import { makeSelectAllStageData } from '../selectors/submit';

export function* watchFormSubmit() {
  try {
    const stageData = yield select(makeSelectAllStageData());
    const verificationDateStart = yield select(makeSelectVerificationDateByStageStart(Stage.ENHANCED));

    const response = yield call(saveStage, {
      stageId: Stage.ENHANCED,
      stageData,
      metaData: {
        verificationDateStart,
        verificationDateEnd: Date.now()
      }
    });

    const { userDossier, error } = response;

    if (error) {
      yield call(navigateToErrorLayout);
    }

    if (userDossier) {
      const { stages } = userDossier;
      yield call(storeUserProfile, userDossier);
      yield call(navigateAfterSubmit, stages, { refetchUserProfile: false });
      yield put(successSubmitEnhanced());
    }

  } catch (err) {
    yield put(failedSubmitEnhanced([makeErrorMessage(err)]));

    yield call(navigateToErrorLayout);
  }
}
