import { MutableRefObject, useEffect } from 'react';

// reference: https://usehooks.com/useOnClickOutside/
export const useOnClickOutside = (
  ref: MutableRefObject<HTMLElement | null>,
  handler: (e: any) => void
) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref || (ref.current && ref.current.contains(event.target))) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
