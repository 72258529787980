import React, { FC } from 'react';
import styled from 'styled-components';

export interface IHamburgerProps {
  active?: boolean;
  handleClick?: () => void;
}

export const Hamburger: FC<IHamburgerProps> = ({ active, handleClick }) => (
  <Default onClick={handleClick}>
    <Laver active={active} />
  </Default>
);

const Default = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.components.hamburger.borderColor};
`;

const Laver = styled.span.attrs(({ active }: IHamburgerProps) => ({ active }))`
  display: block;
  height: 2px;
  width: 20px;
  background: ${({ active, theme }) => (active ? 'transparent' : theme.components.hamburger.backgroundColor )};

  ::before, ::after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: 20px;
    background: ${({ theme }) => theme.components.hamburger.backgroundColor};
  }
  
  ::before {
    ${({ active }) => (active ? 'transform: rotate(45deg);' : 'top: 10px;')}
  }

  ::after {
    ${({ active }) => (active ? 'transform: rotate(-45deg);' : 'bottom: 10px;')}
  }
`;
