import { createSelector } from 'reselect';
import { makeSelectRouterSearchUploadId } from 'system/Bootstrap/selectors';
import { dossierSelector } from '../selectors';

export const makeSelectQrReadyState = () => {
  return createSelector(
    dossierSelector,
    (state) => state.getIn(['qr', 'readyState'], 3)
  )
};

export const makeSelectQrUploadId = () => {
  return createSelector(
    dossierSelector,
    makeSelectRouterSearchUploadId(),
    (dossier, routerUploadId) => {
      return dossier.getIn(['qr', 'uploadId'], '') || routerUploadId;
    }
  )
};

export const makeSelectWltfsToken = () => createSelector(
  dossierSelector,
  (state) => state.getIn(['qr', 'wltfsToken'], '')
);
