import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import { jsonRPC } from 'utils/request';
import { IRequestUniqueId, Type } from './actionTypes';
import { requestUniqueIdError, requestUniqueIdSuccess } from './actions';
import { makeSelectApiUrl } from '../Settings/selectors';

function* requestUniqueId(action: IRequestUniqueId) {
  try {
    const api = yield select(makeSelectApiUrl());

    const response = yield call(jsonRPC, {
      namespace: 'va',
      method: 'setUniqueId',
      params: { ...action.payload },
      api
    });

    if (response?.success) {
      yield put(requestUniqueIdSuccess(response))
    }
  } catch (e) {
    console.log('requestUniqueId', e);
    yield put(requestUniqueIdError(e))
  }
}

export function* rootSaga() {
  yield all([
    takeLatest(Type.FINGERPRINT_SET_UNIQUE_ID_REQUEST, requestUniqueId)
  ])
}


