import { stageRoutes } from 'system/Routing/stageRoutes.lazy';
import { config } from 'merchants/cex';
import { App } from '../App';

// eslint-disable-next-line no-undef
__webpack_public_path__ = `${(window as any).appConfig.cdn}/`;

(async () => {
  await App(config, stageRoutes);
})();


