import React, { useEffect } from 'react';
import { match } from 'react-router';
import { withRouter } from 'react-router-dom';
import { History, Location } from 'history';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { CommonStub } from 'containers/Stubs/CommonStub';
import { Stub } from 'components/UI/Icon';
import {
  BUTTON_TYPE,
  ICommonStubProps,
} from 'containers/Stubs/CommonStub/types';
import { ILocalizationProps, withLocalization } from 'system/Localization';
import { types, withMixpanel } from 'system/Analytics';
import { resetStageData } from 'system/UI/actions';
import { StageId } from 'system/Stages/types';

interface IRedStateStub extends ILocalizationProps {
  location: Location<{ region: string; ifCountry: boolean }>;
  match: match<{ stageId: StageId }>;
  history: History;
  processMixpanel: (event: string | null) => void;
  handleResetCountry: (stageId: StageId) => void;
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  handleResetCountry: (stageId: StageId) => {
    dispatch(resetStageData(stageId));
  },
});

export const RedStateStub: any = connect(
  null,
  mapDispatchToProps
)(
  withMixpanel(
    withRouter(
      withLocalization(
        ({
          translate,
          location: { state },
          match: {
            params: { stageId },
          },
          handleResetCountry,
          history,
          processMixpanel,
        }: IRedStateStub) => {
          const ifRegion = state && state.region;
          const trackId = ifRegion
            ? types.TRACK.RED_STATE_SELECTED
            : types.TRACK.RED_COUNTRY_SELECTED;

          useEffect(() => {
            processMixpanel(trackId);
          }, [processMixpanel, trackId]);

          const contentProps: ICommonStubProps = {
            stageTitle: translate(`stage_title_${stageId}`),
            body: {
              Icon: Stub.Stop,
              title:
                state && state.ifCountry
                  ? translate('screen_title_country_unsupported')
                  : translate('screen_title_state_unsupported'),
              message:
                state && state.ifCountry
                  ? translate('screen_unsupported_country_note')
                  : translate('screen_unsupported_state_note'),
              dataAqaid: 'red-state-error',
            },
            buttons: [
              {
                clickHandler: () => {
                  handleResetCountry(stageId);
                  history.goBack();
                },
                text: translate('back'),
                mods: { type: BUTTON_TYPE.primary },
              },
            ],
          };

          return <CommonStub {...contentProps} />;
        }
      )
    )
  )
);
