import { IConfig } from 'merchants/types/IConfig';

export enum Type {
  APPLICATION_LOADING = '@Bootstrap/APPLICATION_LOADING',
  APPLICATION_LOADED = '@Bootstrap/APPLICATION_LOADED',
  APPLICATION_FAILED = '@Bootstrap/APPLICATION_FAILED',
}

export interface IStart {
  type: Type.APPLICATION_LOADING;
  payload: { config: IConfig };
}

export interface ILoaded {
  type: Type.APPLICATION_LOADED;
  payload: {};
}

export interface IError {
  type: Type.APPLICATION_FAILED;
  payload: Error[];
}

export type Action = IStart | ILoaded | IError;
