/* eslint-disable */

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { List, Map } from 'immutable';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { createStructuredSelector } from 'reselect';

import { IMediaMatches, Media } from 'system/Media';
import { makeSelectRelativeProgress } from 'system/Dossier/selectors';
import {
  makeSelectIssuerIndexLink,
  makeSelectIssuerFallbackLink,
} from 'system/Settings/selectors';
import {
  makeSelectISS,
  makeSelectResetStageIds,
  makeSelectMerchantFeatures
} from 'system/Profile/selectors';
import { ILocalizationProps, withLocalization } from 'system/Localization';
import { Stub } from 'system/Routing/types'

import { getAppCrumb } from './utils/helpers';

import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

export type CrumbProps = { to?: string | null; label: string };

interface IBaseNavbarProps extends ILocalizationProps {
  fallbackLink: string;
  indexLink: string;
  iss: string;
  progress: number;
  resubmitStageIds: Map<string, List<string[]>>;
  merchantFeatures: Map<string, any>;
}

export const BaseNavbar = withLocalization((props: IBaseNavbarProps) => {
  const { indexLink, fallbackLink, resubmitStageIds, merchantFeatures, iss, translate } = props;
  const progress = get(props, 'progress');
  const location = useLocation();
  const pathname = get(location, 'pathname', '');

  const isIndexPage = pathname === '/' || pathname.includes('/status');
  const crumbs = getAppCrumb(pathname, { to: '/', label: 'screen_title_stages' });
  const isStub = useMemo(() => Object.values(Stub).some((stub) => pathname.endsWith(stub)), [pathname]);
  const isCorp = useMemo(() => pathname.startsWith('/corporate'), [pathname]);
  const showCrumbs = !isEmpty(crumbs) && !isIndexPage;
  const showProgress = !isIndexPage && !isCorp && !isStub;
  const showResubmit = merchantFeatures.get('resubmit') && isIndexPage && !resubmitStageIds.isEmpty();

  return (
    <Media>
      {({ tablet }: IMediaMatches) =>
        tablet ? (
          <Mobile
            crumbs={crumbs}
            progress={progress}
            showCrumbs={showCrumbs}
            showProgress={showProgress}
            showResubmit={showResubmit}
            indexLink={indexLink}
            fallbackLink={fallbackLink}
            translate={translate}
            iss={iss}
          />
        ) : (
          <Desktop
            crumbs={crumbs}
            progress={progress}
            showCrumbs={showCrumbs}
            showProgress={showProgress}
            showResubmit={showResubmit}
            indexLink={indexLink}
            fallbackLink={fallbackLink}
            translate={translate}
            iss={iss}
          />
        )
      }
    </Media>
  );
});

const mapStateToProps = createStructuredSelector({
  progress: makeSelectRelativeProgress(),
  indexLink: makeSelectIssuerIndexLink,
  fallbackLink: makeSelectIssuerFallbackLink,
  resubmitStageIds: makeSelectResetStageIds(),
  iss: makeSelectISS(),
  merchantFeatures: makeSelectMerchantFeatures(),
});

export const Navbar = connect<ReturnType<typeof mapStateToProps>, null, any>(
  mapStateToProps,
  null
)(BaseNavbar);
