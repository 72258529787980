import isEmpty from 'lodash/isEmpty';
import { url as Url } from 'utils/url';

import { Stage } from 'system/Stages/types';

import { Stub, SubStep } from './types';

import { getCombinedBlockPattern, getCombinedPattern } from './helpers';

export const Routes = {
  /*
   * usage example src/system/Routing/__test__/Routes.test.ts:6
   * */
  root: (url?: string) => {
    if (!url) return '/';
    return url;
  },
  /*
   * src/system/Routing/__test__/Routes.test.ts:12
   * */
  url: ({
    stageId,
    stepId,
    stubId,
    blockId,
    query,
  }: {
    stageId?: Stage;
    stepId?: string;
    blockId?: string;
    stubId?: Stub | string;
    query?: object;
  }) => {
    let url = '';

    if (stageId) {
      url = url.concat(`/${stageId}`);
    }

    if (blockId) {
      url = url.concat(`/${blockId}`);
    }

    if (stepId) {
      url = url.concat(`/${stepId}`);
    }

    if (stepId && stubId) {
      url = url.concat(`/${stubId}`);
    }

    if (query && !isEmpty(query) && (stepId || stubId || blockId)) {
      url = url.concat(`?${Url.stringify(query)}`);
    }

    return url;
  },
  stage: {
    /*
     * src/system/Routing/__test__/Routes.test.ts:46
     * */
    all: () => getCombinedPattern({ stubs: false, singleStage: true }),
    /*
     * src/system/Routing/__test__/Routes.test.ts:52
     * */
    one: (stageId: Stage, stepId = '', singleStage = false) =>
      getCombinedPattern({
        stages: [stageId],
        steps: [stepId],
        stubs: false,
        singleStage,
      }),
    /*
     * src/system/Routing/__test__/Routes.test.ts:52
     * */
    block: (stageId: Stage, stepIds: string, subStepIds?: SubStep) =>
      getCombinedBlockPattern({
        stages: [stageId],
        steps: [stepIds],
        subStepIds: subStepIds ? [subStepIds] : undefined,
      }),
    /*
     * src/system/Routing/__test__/Routes.test.ts:67
     * */
    oneOf: (stageIds: Stage[], stepIds = [''], singleStage = false) =>
      getCombinedPattern({
        stages: stageIds,
        steps: stepIds,
        stubs: false,
        singleStage,
      }),
  },
  stub: {
    /*
     * src/system/Routing/__test__/Routes.test.ts:83
     * */

    all: () => getCombinedPattern({ stages: [], steps: [] }),
    /*
     * src/system/Routing/__test__/Routes.test.ts:87
     * */
    allStrict: () => getCombinedPattern(),
    /*
     * src/system/Routing/__test__/Routes.test.ts:93
     * */
    one: ({
      stageIds = Object.values(Stage),
      stepIds = [],
      stubId,
      subStepIds,
    }: {
      stageIds?: Stage[];
      stepIds?: string[];
      stubId: Stub;
      subStepIds?: SubStep[];
    }) =>
      getCombinedPattern({
        stages: stageIds,
        steps: stepIds,
        stubs: [stubId],
        subStepIds,
      }),

    /*
     * src/system/Routing/__test__/Routes.test.ts:109
     * */
    oneOf: ({
      stageIds = Object.values(Stage),
      stepIds = [],
      stubIds,
      subStepIds,
    }: {
      stageIds?: Stage[];
      stepIds?: string[];
      stubIds: Stub[];
      subStepIds?: SubStep;
    }) =>
      getCombinedPattern({
        stages: stageIds,
        steps: stepIds,
        stubs: stubIds as Stub[],
        subStepIds: subStepIds ? [subStepIds] : undefined,
      }),
  },
  /*
   * src/system/Routing/__test__/Routes.test.ts:135
   * */
  status: (group?: string) => {
    const pattern = `${getCombinedPattern({
      stages: [],
      steps: [],
      stubs: false,
    })}/:statusId`;
    if (group !== undefined) {
      return pattern.replace(':statusId', `:statusId(${group})`);
    }
    return getCombinedPattern({ stages: [], steps: [], stubs: [] });
  },
};
