export enum Type {
  LIVENESS_CHECK_INIT = '@LivenessCheck/LIVENESS_CHECK_INIT',
  LIVENESS_CHECK_REFRESH = '@LivenessCheck/LIVENESS_CHECK_REFRESH',
  LIVENESS_CHECK_TOKEN_REQUEST = '@LivenessCheck/LIVENESS_CHECK_TOKEN_REQUEST',
  LIVENESS_CHECK_TOKEN_SUCCESS = '@LivenessCheck/LIVENESS_CHECK_TOKEN_SUCCESS',
  LIVENESS_CHECK_TOKEN_ERROR = '@LivenessCheck/LIVENESS_CHECK_TOKEN_ERROR',
  LIVENESS_CHECK_CLEANUP = '@LivenessCheck/LIVENESS_CHECK_CLEANUP',
  
  LIVENESS_CHECK_APP_CONNECT = '@LivenessCheck/LIVENESS_CHECK_APP_CONNECT',
  LIVENESS_CHECK_APP_DISCONNECT = '@LivenessCheck/LIVENESS_CHECK_APP_DISCONNECT',

  LIVENESS_CHECK_WEBSOCKET_CONNECT = '@LivenessCheck/LIVENESS_CHECK_WEBSOCKET_CONNECT',
  LIVENESS_CHECK_WEBSOCKET_MESSAGE = '@LivenessCheck/LIVENESS_CHECK_WEBSOCKET_MESSAGE',
  LIVENESS_CHECK_WEBSOCKET_ERROR = '@LivenessCheck/LIVENESS_CHECK_WEBSOCKET_ERROR',
  LIVENESS_CHECK_WEBSOCKET_DISCONNECT = '@LivenessCheck/LIVENESS_CHECK_WEBSOCKET_DISCONNECT',

  LIVENESS_CHECK_MOBILE_TOKEN_REQUEST = '@LivenessCheck/LIVENESS_CHECK_MOBILE_TOKEN_REQUEST',
  LIVENESS_CHECK_MOBILE_TOKEN_SUCCESS = '@LivenessCheck/LIVENESS_CHECK_MOBILE_TOKEN_SUCCESS',
  LIVENESS_CHECK_MOBILE_TOKEN_ERROR = '@LivenessCheck/LIVENESS_CHECK_MOBILE_TOKEN_ERROR',
}

export type LivenessCheckAppConnect = {
  type: Type.LIVENESS_CHECK_APP_CONNECT;
};

export type LivenessCheckAppDisconnect= {
  type: Type.LIVENESS_CHECK_APP_DISCONNECT;
};

export type LivenessCheckInit = {
  type: Type.LIVENESS_CHECK_INIT;
};

export type LivenessCheckRefresh = {
  type: Type.LIVENESS_CHECK_REFRESH;
};

export type LivenessCheckCleanup = {
  type: Type.LIVENESS_CHECK_CLEANUP;
};

export type LivenessCheckTokenRequest = {
  type: Type.LIVENESS_CHECK_TOKEN_REQUEST;
};

export type LivenessCheckTokenSuccess = {
  type: Type.LIVENESS_CHECK_TOKEN_SUCCESS;
  payload: any;
};

export type LivenessCheckTokenError = {
  type: Type.LIVENESS_CHECK_TOKEN_ERROR;
  payload: any;
};

export type LivenessCheckWebsocketConnect = {
  type: Type.LIVENESS_CHECK_WEBSOCKET_CONNECT;
};

export type LivenessCheckWebsocketMessage = {
  type: Type.LIVENESS_CHECK_WEBSOCKET_MESSAGE;
  payload: any;
};

export type LivenessCheckWebsocketError = {
  type: Type.LIVENESS_CHECK_WEBSOCKET_ERROR;
  payload: any;
};

export type LivenessCheckWebsocketDisconnect = {
  type: Type.LIVENESS_CHECK_WEBSOCKET_DISCONNECT;
};

export type LivenessCheckMobileTokenRequest = {
  type: Type.LIVENESS_CHECK_MOBILE_TOKEN_REQUEST;
};

export type LivenessCheckMobileTokenSuccess = {
  type: Type.LIVENESS_CHECK_MOBILE_TOKEN_SUCCESS;
  payload: any;
};

export type LivenessCheckMobileTokenError = {
  type: Type.LIVENESS_CHECK_MOBILE_TOKEN_ERROR;
  payload: any;
};

export type ActionType =
  | LivenessCheckInit
  | LivenessCheckCleanup
  | LivenessCheckWebsocketConnect
  | LivenessCheckWebsocketMessage
  | LivenessCheckWebsocketError
  | LivenessCheckTokenRequest
  | LivenessCheckTokenSuccess
  | LivenessCheckTokenError
  | LivenessCheckWebsocketDisconnect
  | LivenessCheckAppConnect
  | LivenessCheckAppDisconnect
  | LivenessCheckMobileTokenRequest
  | LivenessCheckMobileTokenSuccess
  | LivenessCheckMobileTokenError
  ;
