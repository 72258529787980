import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Clock: React.FunctionComponent<IconProps> = ({ width, height }) => {
  const {
    icons: {
      markup: {
        clock: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 16c0 8.837-7.164 16-16.001 16C7.163 32 0 24.837 0 16 0 7.163 7.163 0 15.999 0 24.836 0 32 7.164 32 16zM15.999 4C9.373 4 4 9.37 4 15.998 4 22.626 9.373 28 15.999 28 22.627 28 28 22.626 28 15.998 28 9.37 22.627 4 15.999 4zm6.903 14h-8.276c-.337-.032-.605-.366-.626-.777V9.44c.024-.802.565-1.44 1.237-1.44.682 0 1.235.667 1.235 1.49v4.707l-.003.042c0 .015.003.029.003.039.017.415.279.73.609.77l5.772.007c.64.053 1.147.696 1.147 1.483 0 .763-.48 1.38-1.098 1.462z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

Clock.displayName = 'ClockIcon';

Clock.defaultProps = { height: '32', width: '32' };
