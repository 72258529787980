import { IPhoneNumber } from 'utils/types/IPhoneNumber';
import { IUploadParams } from 'system/Dossier/Upload/sagas';
import { IMetaPayload } from '../Identity/actionTypes';

export enum Type {
  CORPORATE_STEP_INITIAL_SET = '@Corporate/CORPORATE_STEP_INITIAL_SET',
  CORPORATE_STEP_SET = '@Corporate/CORPORATE_STEP_SET',
  CORPORATE_STEP_SUBMIT = '@Corporate/CORPORATE_STEP_SUBMIT',
  CORPORATE_BLOCK_FAILED = '@Corporate/CORPORATE_BLOCK_FAILED',
  CORPORATE_BLOCK_RESET = '@Corporate/CORPORATE_BLOCK_RESET',
  CORPORATE_UPLOAD_DOC_REQUEST = '@Corporate/CORPORATE_UPLOAD_DOC_REQUEST',
  CORPORATE_UPLOAD_DOC_SUCCESS = '@Corporate/CORPORATE_UPLOAD_DOC_SUCCESS',
  CORPORATE_UPLOAD_DOC_FAILED = '@Corporate/CORPORATE_UPLOAD_DOC_FAILED',

  CORPORATE_CACHE_REQUEST = '@Corporate/CORPORATE_CACHE_REQUEST',
  CORPORATE_CACHE_REMOVE = '@Corporate/CORPORATE_CACHE_REMOVE',
  CORPORATE_CACHE_FAILED = '@Corporate/CORPORATE_CACHE_FAILED',
  CORPORATE_CACHE_SUCCESS = '@Corporate/CORPORATE_CACHE_SUCCESS',

  CORPORATE_SUBMIT_REQUEST = '@Corporate/CORPORATE_SUBMIT_REQUEST',
  CORPORATE_SUBMIT_SUCCESS = '@Corporate/CORPORATE_SUBMIT_SUCCESS',
  CORPORATE_SUBMIT_FAILED = '@Corporate/CORPORATE_SUBMIT_FAILED',
  CORPORATE_META_SET = '@Corporate/CORPORATE_META_SET',

  CORPORATE_UPLOAD_FILES_REQUEST = '@Corporate/CORPORATE_UPLOAD_FILES_REQUEST',
  CORPORATE_UPLOAD_FILES_ERROR = '@Corporate/CORPORATE_UPLOAD_FILES_ERROR',
  CORPORATE_UPLOAD_FILES_SUCCESS = '@Corporate/CORPORATE_UPLOAD_FILES_SUCCESS',

  CORPORATE_ADD_FILES = '@Corporate/CORPORATE_ADD_FILES',
  CORPORATE_REMOVE_FILES = '@Corporate/CORPORATE_REMOVE_FILES',

  CORPORATE_BLOCK_SUBMIT_REQUEST = '@Corpoorate/CORPORATE_BLOCK_SUBMIT_REQUEST',
  CORPORATE_BLOCK_SUBMIT_SUCCESS = '@Corpoorate/CORPORATE_BLOCK_SUBMIT_SUCCESS',
  CORPORATE_BLOCK_SUBMIT_FAILED = '@Corpoorate/CORPORATE_BLOCK_SUBMIT_FAILED',

  CORPORATE_PHONE_CODE_REQUEST = '@Corporate/CORPORATE_PHONE_CODE_REQUEST',
  CORPORATE_PHONE_CODE_SUCCESS = '@Corporate/CORPORATE_PHONE_CODE_SUCCESS',
  CORPORATE_PHONE_CODE_FAILED = '@Corporate/CORPORATE_PHONE_CODE_FAILED',
  CORPORATE_CODE_VALIDATION_REQUEST = '@Corporate/CORPORATE_CODE_VALIDATION_REQUEST',
  CORPORATE_CODE_VALIDATION_SUCCESS = '@Corporate/CORPORATE_CODE_VALIDATION_SUCCESS',
  CORPORATE_CODE_VALIDATION_FAILED = '@Corporate/CORPORATE_CODE_VALIDATION_FAILED',
  CORPORATE_RESET_PHONE_ERRORS = '@Corporate/CORPORATE_RESET_PHONE_ERRORS',
  CORPORATE_RESET_PHONE_VALIDATION = '@Corporate/CORPORATE_RESET_PHONE_VALIDATION',
  CORPORATE_PHONE_WITHOUT_CODE_REQUEST = '@Corporate/CORPORATE_PHONE_WITHOUT_CODE_REQUEST',

  CORPORATE_ESIGNATURE_SUBMIT = '@Corporate/CORPORATE_ESIGNATURE_SUBMIT',
  CORPORATE_ESIGNATURE_SUCCESS = '@Corporate/CORPORATE_ESIGNATURE_SUCCESS',
  CORPORATE_ESIGNATURE_FAILED = '@Corporate/CORPORATE_ESIGNATURE_FAILED',
  CORPORATE_ESIGNATURE_RESEND = '@Corporate/CORPORATE_ESIGNATURE_RESEND',
  CORPORATE_ESIGNATURE_CANCEL = '@Corporate/CORPORATE_ESIGNATURE_CANCEL',
  CORPORATE_CLEAN_PHONE_VALIDATION = '@Corporate/CORPORATE_CLEAN_PHONE_VALIDATION',

  CORPORATE_SET_PHONE_COUNTRY = '@Corporate/CORPORATE_SET_PHONE_COUNTRY',
}

export interface CorporateStepSet {
  type: Type.CORPORATE_STEP_SET;
  payload: {
    stepId: string;
    fields: object;
  };
}

export interface IRequestUploadCorporateDocuments {
  type: Type.CORPORATE_UPLOAD_DOC_REQUEST;
  payload: { files: File[]; onAfterUpload?: any };
}

export interface IUploadCorporateDocumentsSuccess {
  type: Type.CORPORATE_UPLOAD_DOC_SUCCESS;
  payload: {};
}

export interface IUploadCorporateDocumentsFailed {
  type: Type.CORPORATE_UPLOAD_DOC_FAILED;
  payload: Error[];
}

export interface ICorporateSubmitRequest {
  type: Type.CORPORATE_SUBMIT_REQUEST;
  payload: any;
}

export interface ICorporateSubmitSuccess {
  type: Type.CORPORATE_SUBMIT_SUCCESS;
  payload: any;
}

export interface ICorporateSubmitFailed {
  type: Type.CORPORATE_SUBMIT_FAILED;
  payload: any;
}

export interface ICorporateStepSet {
  type: Type.CORPORATE_STEP_SET;
  payload: {
    stepId: string;
    blockId: string;
    fields: object;
    replace?: boolean;
  };
}

export interface ICorporateStepSubmit {
  type: Type.CORPORATE_STEP_SUBMIT;
  payload: {
    fields?: any;
    upload?: any;
    replace?: boolean;
    nextStepId?: string;
    cachePath?: string;
    entryId?: string;
  };
}

export interface CorporateSetMeta extends IMetaPayload {
  type: Type.CORPORATE_META_SET;
  payload: {
    stepId: string;
    blockId: string;
    fields: object;
  };
}

export interface IPhoneCodeRequest {
  type: Type.CORPORATE_PHONE_CODE_REQUEST;
  payload: { phone: IPhoneNumber };
}
export interface IPhoneWithoutCode {
  type: Type.CORPORATE_PHONE_WITHOUT_CODE_REQUEST;
  payload: { phone: IPhoneNumber };
}
export interface IPhoneCodeSuccess {
  type: Type.CORPORATE_PHONE_CODE_SUCCESS;
  payload: { timestamp: string };
}

export interface IPhoneCodeFailed {
  type: Type.CORPORATE_PHONE_CODE_FAILED;
  payload: Error[];
}

export interface ICodeValidationRequest {
  type: Type.CORPORATE_CODE_VALIDATION_REQUEST;
  payload: { phone: IPhoneNumber; code: string };
}
export interface ICodeValidationSuccess {
  type: Type.CORPORATE_CODE_VALIDATION_SUCCESS;
  payload: { valid: boolean; skip: boolean };
}

export interface ICodeValidationFailed {
  type: Type.CORPORATE_CODE_VALIDATION_FAILED;
  payload: Error | { code: number; name: string };
}

export interface IResetPhoneErrors {
  type: Type.CORPORATE_RESET_PHONE_ERRORS;
  payload: {};
}

export interface IResetPhoneValidation {
  type: Type.CORPORATE_RESET_PHONE_VALIDATION;
  payload: {};
}
export interface ICleanPhoneValidation {
  type: Type.CORPORATE_CLEAN_PHONE_VALIDATION;
  payload: {};
}

export interface ICorporateBlockSubmitRequest {
  type: Type.CORPORATE_BLOCK_SUBMIT_REQUEST;
  payload: { blockId: string };
}

export interface ICorporateBlockSubmitSuccess {
  type: Type.CORPORATE_BLOCK_SUBMIT_SUCCESS;
  payload: {};
}

export interface ICorporateBlockSubmitFailed {
  type: Type.CORPORATE_BLOCK_SUBMIT_FAILED;
  payload: Error[];
}

export interface CorporateBlockReset {
  type: Type.CORPORATE_BLOCK_RESET;
  payload: {
    blockId: string;
    stepId?: string;
    cachePath?: string;
  };
}

export interface ICorporateUploadFileRequest {
  type: Type.CORPORATE_UPLOAD_FILES_REQUEST;
  payload: { files?: File[]; source?: string };
}

export interface ICorporateUploadFileSuccess {
  type: Type.CORPORATE_UPLOAD_FILES_SUCCESS;
  payload: {};
}

export interface ICorporateUploadFileError {
  type: Type.CORPORATE_UPLOAD_FILES_ERROR;
  payload: Error[];
}

export interface ICorporateSourceAddFile {
  type: Type.CORPORATE_ADD_FILES;
  payload: {
    source?: string;
    files: IUploadParams[];
    blockId: string;
    stepId: string;
    replace?: boolean;
  };
}

export interface ICorporateSourceRemoveFile {
  type: Type.CORPORATE_REMOVE_FILES;
  payload: {
    source: string;
    id: string | number;
    blockId: string;
    stepId: string;
  };
}

export interface ICorporateBlockCacheRequest {
  type: Type.CORPORATE_CACHE_REQUEST;
  payload: {
    blockId: string;
    nextStepId?: string;
    cachePath?: string;
    data?: any;
  };
}

export interface ICorporateBlockCacheRemove {
  type: Type.CORPORATE_CACHE_REMOVE;
  payload: { blockId?: string; idx: number; path: any };
}

export interface ICorporateBlockCacheSuccess {
  type: Type.CORPORATE_CACHE_SUCCESS;
  payload: { data: any; blockId: string };
}

export interface ICorporateBlockCacheFailed {
  type: Type.CORPORATE_CACHE_FAILED;
  payload: Error[];
}

export interface ICorporateEsignatureSubmit {
  type: Type.CORPORATE_ESIGNATURE_SUBMIT;
  payload: { data: any };
}

export interface ICorporateEsignatureSuccess {
  type: Type.CORPORATE_ESIGNATURE_SUCCESS;
  payload: any;
}

export interface ICorporateEsignatureFailed {
  type: Type.CORPORATE_ESIGNATURE_FAILED;
  payload: Error[];
}

export interface ISetPhoneCountry {
  type: Type.CORPORATE_SET_PHONE_COUNTRY;
  payload: string;
}

export type ActionType =
  | ICorporateSubmitRequest
  | ICorporateSubmitSuccess
  | ICorporateSubmitFailed
  | ICorporateStepSet
  | ICorporateStepSubmit
  | IPhoneCodeRequest
  | IPhoneCodeSuccess
  | IPhoneCodeFailed
  | ICodeValidationRequest
  | ICodeValidationSuccess
  | ICodeValidationFailed
  | IResetPhoneErrors
  | IResetPhoneValidation
  | CorporateSetMeta
  | ICorporateBlockSubmitRequest
  | ICorporateBlockSubmitSuccess
  | ICorporateBlockSubmitFailed
  | CorporateBlockReset
  | ICorporateUploadFileRequest
  | ICorporateUploadFileSuccess
  | ICorporateUploadFileError
  | ICorporateSourceAddFile
  | ICorporateSourceRemoveFile
  | ICorporateBlockCacheRequest
  | ICorporateBlockCacheSuccess
  | ICorporateBlockCacheFailed
  | ICorporateBlockCacheRemove
  | ICorporateEsignatureSubmit
  | ICorporateEsignatureSuccess
  | ICorporateEsignatureFailed
  | ISetPhoneCountry;
