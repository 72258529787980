import styled from 'styled-components';
import { FieldBox } from 'containers/Field';
import { Textarea } from 'components/UI/Textarea';
import { Button, Link } from 'components/UI';

export const SubmittedContent = styled.div`
  display: flex;
  align-items: center;

  > span {
    padding-left: 8px;
  }
`;

export const Box = styled.div`
  margin: 0 auto;
  max-width: 535px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${FieldBox} {
    max-width: 470px;
    width: 100%;

    ${Textarea} {
      max-width: 470px;
      height: 176px;
      width: 100%;
      border-radius: ${({ theme }) => theme.forms.textarea.borderRadius};
      box-shadow: ${({ theme }) => theme.forms.textarea.boxShadow};  
    }

    margin-bottom: 16px;
  }

  ${Button} {
    margin-bottom: 16px;
  }
`;

export const Stars = styled.ul`
  margin: 0 auto 8px;
  list-style-type: none;
  display: flex;
  max-width: 200px;
  width: 100%;
  justify-content: space-between;
`;

export const Star = styled.li`
  cursor: pointer;
`;

export const P = styled.p`
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color:${({ theme }) => theme.components.feedback.textColor};
  margin-bottom: 24px;

  strong {
    font-weight: 600;
  }

  > svg {
    vertical-align: middle;
    margin-right: 8px;
  }

  ${Link} {
    font-size: inherit;
  }
`;
