import { MutableRefObject } from 'react';

export const ENTER_TIMEOUT = 500;
export const EXIT_TIMEOUT = 500;
export const DURATION = 450;
export const TIMING_FUNCTION = 'cubic-bezier(0.645, 0.045, 0.355, 1)';

export const STAGE_PREVIEW_SHORT_WIDTH_DESKTOP = 274;
export const SAGE_PREVIEW_FULL_WIDTH_DESKTOP = 470;

export const STAGE_PREVIEW_SHORT_CONTENT_HEIGHT_DESKTOP = 84;

type Container = { containerTop: number; containerLeft: number };

type WindowSize = { width: number; height: number };

type Rect = {
  top: string;
  left: string;
  width: string;
};

export type ClientRectResize = {
  rect: Rect;
  windowSize: WindowSize;
  container: Container;
};

export type Hook = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  activeElement: number | undefined,
  container: HTMLElement | null,
) => ClientRectResize | {};
