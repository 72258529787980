/* eslint-disable */
import React, { FC } from 'react';

import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { Link } from 'components/UI';
import { CrumbProps } from '../Navbar';
import { ITranslate } from 'system/Localization';

interface IDesktopProps {
  translate: ITranslate;
  crumbs: CrumbProps[];
}

export const NavBreadCrumbs: FC<IDesktopProps> = ({
  crumbs,
  translate,
}) => {
 
  return (
    <Breadcrumbs>
      {crumbs.map(({ to, label }: CrumbProps) => {
        return to ? (
          <Link key={label} to={to}>
            {translate(label)}
          </Link>
        ) : (
          <span key={label}>{translate(label)}</span>
        );
      })}
    </Breadcrumbs>
  );
};
