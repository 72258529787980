import { createSelector } from 'reselect';

import {
  makeSelectAddressResidenceAptSuite,
  makeSelectAddressResidenceCity,
  makeSelectAddressResidenceCountry,
  makeSelectAddressResidenceRegion,
  makeSelectAddressResidenceStreet,
  makeSelectAddressResidenceSubRegion,
  makeSelectAddressResidenceZipCode,
  makeSelectCitizenship,
  makeSelectDateOfBirth,
  makeSelectDocSubType,
  makeSelectExpireDate,
  makeSelectFirstName,
  makeSelectGender,
  makeSelectHasExpireDate,
  makeSelectHasPepCloseAssociate,
  makeSelectHasPepRelatives,
  makeSelectIsPep,
  makeSelectIssuingCountryInfo,
  makeSelectIsUsCitizen,
  makeSelectIsUsResidentAlien,
  makeSelectIsUsTaxpayer,
  makeSelectLastName,
  makeSelectMiddleNameName,
  makeSelectPepCloseAssociateDetailed,
  makeSelectPepPosition,
  makeSelectPepRelativesDetailed,
  makeSelectPlaceOfBirth,
  makeSelectPosition,
  makeSelectSerialNumber,
  makeSelectSocialProfilesValues,
  makeSelectSSN,
  makeSelectTINPersonal,
  makeSelectUserRoles,
  makeSelectFilesTokensIdentity,
  makeSelectPositionDetailed,
  makeSelectUBOInterestShares,
  makeSelectFilesTokensPosition,
  makeSelectFilesTokensInterestShares,
  makeSelectFilesTokensAddress,
  makeSelectSelectPersonalBlockCacheRoles,
  makeSelectIdentitySelfieToken,
  makeSelectEntryId,
  makeSelectPersonalInfoPhoneIsValidated,
  makeSelectValidationPersonalPhoneCountry,
  makeSelectValidationPersonalPhoneNumber,
  makeSelectValidationPersonalPhoneCode,
  makeSelectSourceOfFiatFunds,
} from './personalInfo';

export const makeSelectPersonalData = () =>
  createSelector(
    makeSelectFirstName(),
    makeSelectLastName(),
    makeSelectDateOfBirth(),
    makeSelectMiddleNameName(),
    makeSelectPlaceOfBirth(),
    makeSelectGender(),
    makeSelectCitizenship(),
    (
      firstName,
      lastName,
      dateOfBirth,
      middleName,
      placeOfBirth,
      gender,
      citizenship
    ) => {
      return {
        firstName,
        lastName,
        displayName: `${firstName} ${lastName}`,
        dateOfBirth,
        placeOfBirth,
        gender,
        citizenship,
        middleName
      };
    }
  );

export const makeSelectIdentityDocument = () =>
  createSelector(
    [
      makeSelectDocSubType(),
      makeSelectFilesTokensIdentity(),
      makeSelectHasExpireDate(),
      makeSelectSerialNumber(),
      makeSelectExpireDate(),
      makeSelectIssuingCountryInfo(),
      makeSelectIdentitySelfieToken(),
      makeSelectPersonalData(),
    ],
    (
      subType,
      images,
      hasExpireDate,
      serialNumber,
      expireDate,
      issuingCountry,
      selfieImage,
      personalData
    ) => ({
      type: 'identity',
      subType,
      images: images.toJS(),
      hasExpireDate,
      serialNumber,
      ...(expireDate ? { expireDate } : {}),
      issuingCountry,
      selfieImage,
      personalData,
    })
  );

export const makeSelectPhone = () =>
  createSelector(
    makeSelectValidationPersonalPhoneCountry(),
    makeSelectValidationPersonalPhoneCode(),
    makeSelectValidationPersonalPhoneNumber(),
    makeSelectPersonalInfoPhoneIsValidated(),
    (country, code, number, isValidated) => ({
      country,
      code,
      number,
      isValidated
    })
  );

export const makeSelectAddressDocuments = () =>
  createSelector(
    [
      makeSelectAddressResidenceStreet(),
      makeSelectAddressResidenceAptSuite(),
      makeSelectAddressResidenceCity(),
      makeSelectAddressResidenceCountry(),
      makeSelectAddressResidenceRegion(),
      makeSelectAddressResidenceSubRegion(),
      makeSelectAddressResidenceZipCode(),
      makeSelectFilesTokensAddress(),
    ],
    (street, aptSuite, city, country, region, subregion, zipCode, images) => ({
      address: {
        street,
        aptSuite,
        city,
        country,
        region,
        zipCode,
        subregion,
      },
      images: images.toJS(),
    })
  );

export const makeSelectPEPStatus = () =>
  createSelector(
    [
      makeSelectIsPep(),
      makeSelectHasPepRelatives(),
      makeSelectPepRelativesDetailed(),
      makeSelectHasPepCloseAssociate(),
      makeSelectPepCloseAssociateDetailed(),
      makeSelectIsUsCitizen(),
      makeSelectIsUsTaxpayer(),
      makeSelectIsUsResidentAlien(),
      makeSelectPepPosition(),
    ],
    (
      isPep,
      hasPepRelatives,
      pepRelativesDetailed,
      pepCloseAssociate,
      pepCloseAssociateDetailed,
      usCitizen,
      usTaxpayer,
      usResidentAlien,
      pepPosition
    ) => ({
      isPep,
      hasPepRelatives,
      pepCloseAssociate,
      usCitizen,
      usTaxpayer,
      usResidentAlien,
      ...(pepRelativesDetailed ? { pepRelativesDetailed } : {}),
      ...(pepCloseAssociateDetailed ? { pepCloseAssociateDetailed } : {}),
      ...(pepPosition ? { pepPosition } : {}),
    })
  );

export const makeSelectPersonalInfoBlockData = () =>
  createSelector(
    [
      makeSelectEntryId(),
      makeSelectUserRoles(),
      makeSelectPosition(),
      makeSelectPositionDetailed(),
      makeSelectPhone(),
      makeSelectFilesTokensPosition(),
      makeSelectTINPersonal(),
      makeSelectSSN(),
      makeSelectIdentityDocument(),
      makeSelectAddressDocuments(),
      makeSelectPEPStatus(),
      makeSelectSocialProfilesValues(),
      makeSelectUBOInterestShares(),
      makeSelectFilesTokensInterestShares(),
      makeSelectSourceOfFiatFunds(),
    ],
    (
      entryId,
      userRole,
      position,
      otherDetailed,
      phone,
      proofOfPosition,
      TIN,
      SSN,
      identityDocument,
      addressDocument,
      pepStatus,
      socialNetworkProfiles,
      interestShares,
      proofOfInterestShares,
      sourceOfFiatFunds,
    ) => {
      const isProofOfPosition = !proofOfPosition.isEmpty() ? proofOfPosition : undefined;
      const isProofOfInterestShares = !proofOfInterestShares.isEmpty() ? proofOfInterestShares : undefined;
      const isInterestShares = interestShares ? parseFloat(interestShares) : undefined;

      return {
        entryId,
        userRole,
        position,
        otherDetailed,
        phone,
        proofOfPosition: isProofOfPosition,
        ...(TIN && { TIN }),
        ...(SSN && { SSN }),
        identityDocument,
        addressDocument,
        pepStatus,
        socialNetworkProfiles,
        interestShares: isInterestShares,
        proofOfInterestShares: isProofOfInterestShares,
        ...(sourceOfFiatFunds && { sourceOfFiatFunds }),
      }
    }
  );

export const makeSelectPersonalInfoBlockSaveData = () =>
  createSelector(
    [
      makeSelectSelectPersonalBlockCacheRoles(),
      makeSelectPersonalInfoBlockData(),
    ],
    (cashedData, filledData) => ({
      roles: [...cashedData.toJS(), filledData],
    })
  );
