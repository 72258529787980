import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { identitySelector, SubStore } from 'stages/Identity/selectors';
import { Field } from 'utils/types/shared/enums';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

export const questionnaireSelector = (subStore = SubStore.DATA) => {
  return createSelector(identitySelector, (state) =>
    state.getIn([subStore, Field.QUESTIONNAIRE_PAGE], Map())
  );
};

export const makeSelectAccountCreateReason = () => {
  return createSelector(questionnaireSelector(), (state) => state.get(Field.ACCOUNT_CREATE_REASON));
};

export const makeSelectAccountCreateOtherReason = () => {
  return createSelector(questionnaireSelector(), (state) =>
    state.get(Field.ACCOUNT_CREATE_REASON_OTHER)
  );
};

export const makeSelectExchange = () => {
  return createSelector(questionnaireSelector(), (state) => state.get(Field.HOW_FIND_OUR_EXCHANGE));
};

export const makeSelectExchangeOther = () => {
  return createSelector(questionnaireSelector(), (state) =>
    state.get(Field.HOW_FIND_OUR_EXCHANGE_OTHER)
  );
};

export const makeSelectVerificationBySelf = () => {
  return createSelector(questionnaireSelector(), (state) =>
    state.get(Field.PASS_VERIFICATION_BY_SELF)
  );
};

export const makeSelectCryptoWithDraw = () => {
  return createSelector(questionnaireSelector(), (state) =>
    state.get(Field.CRYPTO_FOUND_WITH_DRAW)
  );
};

export const makeSelectCryptoWithDrawDestination = () => {
  return createSelector(questionnaireSelector(), (state) =>
    state.get(Field.CRYPTO_FOUND_WITH_DRAW_DESTINATION)
  );
};

export const makeSelectCryptoWithDrawDestinationOther = () => {
  return createSelector(questionnaireSelector(), (state) =>
    state.get(Field.CRYPTO_FOUND_WITH_DRAW_DESTINATION_OTHER)
  );
};

export const makeSelectWorkWithTraders = () => {
  return createSelector(questionnaireSelector(), (state) => state.get(Field.WORK_WITH_TRADERS));
};

export const makeSelectQuestionnaireBlock = () => {
  return createSelector(
    [
      makeSelectAccountCreateReason(),
      makeSelectAccountCreateOtherReason(),
      makeSelectExchange(),
      makeSelectExchangeOther(),
      makeSelectVerificationBySelf(),
      makeSelectCryptoWithDraw(),
      makeSelectCryptoWithDrawDestination(),
      makeSelectCryptoWithDrawDestinationOther(),
      makeSelectWorkWithTraders(),
    ],
    (
      reason,
      reasonOther,
      exchange,
      exchangeOther,
      verificationBySelf,
      cryptoWithDraw,
      cryptoWithDrawDestination,
      cryptoWithDrawDestinationOther,
      workWithTraders
    ) => {
      const values = {
        [Field.ACCOUNT_CREATE_REASON]: reason,
        [Field.ACCOUNT_CREATE_REASON_OTHER]: reasonOther,
        [Field.HOW_FIND_OUR_EXCHANGE]: exchange,
        [Field.HOW_FIND_OUR_EXCHANGE_OTHER]: exchangeOther,
        [Field.PASS_VERIFICATION_BY_SELF]: verificationBySelf,
        [Field.CRYPTO_FOUND_WITH_DRAW]: cryptoWithDraw,
        [Field.CRYPTO_FOUND_WITH_DRAW_DESTINATION]: cryptoWithDrawDestination,
        [Field.CRYPTO_FOUND_WITH_DRAW_DESTINATION_OTHER]: cryptoWithDrawDestinationOther,
        [Field.WORK_WITH_TRADERS]: workWithTraders,
      };

      const result = omitBy(values, isUndefined);

      return result;
    }
  );
};
