import { createSelector } from 'reselect';
import { fromJS } from 'immutable';

import { getFileToken } from 'utils/file';
import { Field } from 'utils/types/shared/enums';

import {
  makeSelectExtractFromRegisterFiles,
  makeSelectStatutoryDocumentsFilesValues,
  makeSelectCertificateOfRegistrationFilesValues,
  makeSelectCorporateFundsConfirmationFiles,
  makeSelectAllDocumentsFilesValues,
  makeSelectExtractFromRegisterMeta,
  makeSelectStatutoryDocumentsMeta,
  makeSelectCertificateOfRegistrationMeta,
  makeSelectCorporateFundsConfirmationMeta,
  makeSelectAllDocumentsMeta,
} from './companyDocuments';

export const makeSelectCompanyDocumentsBlockData = () =>
  createSelector(
    [
      makeSelectCertificateOfRegistrationFilesValues(),
      makeSelectStatutoryDocumentsFilesValues(),
      makeSelectExtractFromRegisterFiles(),
      makeSelectCorporateFundsConfirmationFiles(),
      makeSelectAllDocumentsFilesValues(),
    ],
    (
      certificateOfRegistration,
      statutoryDocuments,
      extractFromRegister,
      corporateFundsConfirmation,
      allDocuments
    ) => ({
      companyDocuments: {
        [Field.CERTIFICATE_OF_REGISTRATION]: getFileToken(certificateOfRegistration),
        [Field.STATUTORY_DOCUMENTS]: getFileToken(statutoryDocuments),
        [Field.EXTRACT_FROM_REGISTER]: getFileToken(fromJS(extractFromRegister)),
        [Field.CORPORATE_FOUNDS_CONFIRMATION]: getFileToken(fromJS(corporateFundsConfirmation)),
        [Field.ALL_DOCUMENTS]: getFileToken(allDocuments),
      },
    })
  );

export const makeSelectCompanyDocumentsBlockMeta = () =>
  createSelector(
    [
      makeSelectCertificateOfRegistrationMeta(),
      makeSelectStatutoryDocumentsMeta(),
      makeSelectExtractFromRegisterMeta(),
      makeSelectCorporateFundsConfirmationMeta(),
      makeSelectAllDocumentsMeta(),
    ],
    (
      certificateOfRefistrationMeta,
      statutoryDocumentsMeta,
      extractFromRegisterMeta,
      corporateFundsConfirmationMeta,
      allDocumentsMeta
    ) => {
      const certificateOfRegistration = certificateOfRefistrationMeta.get(
        'source'
      );
      const statutoryDocuments = statutoryDocumentsMeta.get('source');
      const extractFromRegister = extractFromRegisterMeta.get(
        'source'
      );
      const corporateFundsConfirmation = corporateFundsConfirmationMeta.get('source');
      const allDocuments = allDocumentsMeta.get('source');
      return {
        companyDocuments: {
          [Field.CERTIFICATE_OF_REGISTRATION]: certificateOfRegistration,
          [Field.STATUTORY_DOCUMENTS]: statutoryDocuments,
          [Field.EXTRACT_FROM_REGISTER]: extractFromRegister,
          [Field.CORPORATE_FOUNDS_CONFIRMATION]: corporateFundsConfirmation,
          [Field.ALL_DOCUMENTS]: allDocuments,
        },
      };
    }
  );
