import { all, fork } from 'redux-saga/effects';
import * as Bootstrap from 'system/Bootstrap/sagas';
import * as Routing from 'system/Routing/sagas';
import * as Profile from 'system/Profile/sagas';
import * as UI from 'system/UI/sagas';

import * as Identity from 'stages/Identity/sagas/rootSaga';
import * as Address from 'stages/Address/sagas/rootSaga';
import * as EnhancedStage from 'stages/Enhanced/sagas/rootSaga';
import * as Dossier from 'system/Dossier/sagas';
import * as Questionnaire from 'stages/Questionnaire/sagas';
import * as Corporate from 'stages/Corporate/sagas/sagas';
import * as Fingerprint from 'system/Fingerprint/sagas';
import * as LivenessCheck from 'stages/Identity/blocks/IdentityDocuments/LivenessCheck/sagas';

const sagas = [
  Bootstrap.rootSaga,
  Routing.rootSaga,
  Profile.rootSaga,
  UI.rootSaga,
  EnhancedStage.rootSaga,
  Questionnaire.rootSaga,
  Corporate.rootSaga,
  Identity.rootSaga,
  Address.rootSaga,
  Dossier.rootSaga,
  Fingerprint.rootSaga,
  LivenessCheck.rootSaga,
];

export function* rootSaga() {
  yield all(sagas.map((saga) => fork(saga)));
}
