import { createSelector } from 'reselect';
import { Map } from 'immutable';

import { Field } from 'utils/types/shared/enums';
import { dateUtils } from 'utils/date';
import { PhoneErrors } from 'utils/error';
import { joinByChar } from 'utils/helpers';
import { Step } from 'system/Routing/types';
import { Block } from 'system/Stages/types';
import {
  makeSelectGeneralInfoCountry,
  makeSelectRegistrationCountry,
} from 'system/Profile/selectors';
import {
  selectCorporateBlock,
  selectCorporateBlockStep,
  selectorCorporate,
  selectorSubStoreParam,
  SubStore,
} from '../selectors';

export const makeSelectGeneralInfoStepMeta = (stepId: string) =>
  createSelector(
    selectCorporateBlockStep(Block.GENERAL_INFORMATION, SubStore.META, stepId),
    (value: any) => value
  );

// RegisteredAddress
export const makeSelectRegisteredAddress = () =>
  createSelector(selectCorporateBlock(Block.GENERAL_INFORMATION), (state: Map<string, any>) => {
    return state.get('registeredAddress', Map()) || Map({});
  });

export const makeSelectRegisteredAddressMeta = () =>
  createSelector(
    makeSelectGeneralInfoStepMeta('registeredAddress'),
    (value: any) => value || Map({})
  );

export const makeSelectCountry = () => {
  return createSelector(makeSelectRegisteredAddress(), (state) => {
    return state.get(Field.COUNTRY, '');
  });
};

export const makeCompanyCountry = () => {
  return createSelector(
    makeSelectRegisteredAddress(),
    makeSelectRegistrationCountry(),
    makeSelectGeneralInfoCountry(),
    (registeredAddress, registrationCountry, generalInfoCountry) => {
      return registeredAddress.get(Field.COUNTRY, '') || generalInfoCountry || registrationCountry;
    }
  );
};

export const makeSelectRegion = () => {
  return createSelector(makeSelectRegisteredAddress(), (state) => state.get(Field.REGION, ''));
};

export const makeSelectSubRegion = () => {
  return createSelector(makeSelectRegisteredAddress(), (state) => state.get(Field.SUB_REGION, ''));
};

export const makeSelectZipCode = () => {
  return createSelector([makeSelectRegisteredAddress()], (state) => state.get(Field.ZIP_CODE));
};

export const makeSelectStreet = () => {
  return createSelector([makeSelectRegisteredAddress()], (state) => {
    return state.get(Field.STREET);
  });
};

export const makeSelectCity = () => {
  return createSelector([makeSelectRegisteredAddress()], (state) => state.get(Field.CITY));
};

export const makeSelectAptSuite = () => {
  return createSelector([makeSelectRegisteredAddress()], (state) => state.get(Field.APT_SUITE));
};
// Meta
export const makeSelectCountryMeta = () => {
  return createSelector(makeSelectRegisteredAddressMeta(), (state) => {
    return state.get(Field.COUNTRY, Map());
  });
};

export const makeSelectRegionMeta = () => {
  return createSelector(makeSelectRegisteredAddressMeta(), (state) =>
    state.get(Field.REGION, Map())
  );
};

export const makeSelectSubRegionMeta = () => {
  return createSelector(makeSelectRegisteredAddressMeta(), (state) =>
    state.get(Field.SUB_REGION, Map())
  );
};

export const makeSelectZipCodeMeta = () => {
  return createSelector([makeSelectRegisteredAddressMeta()], (state) =>
    state.get(Field.ZIP_CODE, Map())
  );
};

export const makeSelectStreetMeta = () => {
  return createSelector([makeSelectRegisteredAddressMeta()], (state) => {
    return state.get(Field.STREET, Map());
  });
};

export const makeSelectCityMeta = () => {
  return createSelector([makeSelectRegisteredAddressMeta()], (state) =>
    state.get(Field.CITY, Map())
  );
};

export const makeSelectAptSuiteMeta = () => {
  return createSelector([makeSelectRegisteredAddressMeta()], (state) =>
    state.get(Field.APT_SUITE, Map())
  );
};

// Premises Address
export const makeSelectPremisesAddress = () =>
  createSelector(selectCorporateBlock(Block.GENERAL_INFORMATION), (state: Map<string, any>) => {
    return state.get('premisesAddress', Map()) || Map({});
  });

export const makeSelectPremisesAddressMeta = () =>
  createSelector(
    makeSelectGeneralInfoStepMeta('premisesAddress'),
    (value: any) => value || Map({})
  );

export const makeSelectPremisesAddressCountry = () =>
  createSelector(
    [makeSelectPremisesAddress(), makeSelectCountry()],
    (state: Map<string, any>, regCountry: string) => {
      return state.get(Field.COUNTRY, regCountry);
    }
  );

export const makeSelectPremisesAddressValue = () =>
  createSelector(makeSelectPremisesAddress(), (state: Map<string, any>) => {
    return state.get(Field.ADDRESS, '');
  });

export const makeSelectPremisesAddressSameAsRegistered = () =>
  createSelector(makeSelectPremisesAddress(), (state: Map<string, any>) => {
    return state.get(Field.SAME_AS_REGISTERED, false);
  });

export const makeSelectPremisesAddressCountryMeta = () =>
  createSelector([makeSelectPremisesAddressMeta()], (state: Map<string, any>) => {
    return state.get(Field.COUNTRY, Map());
  });

export const makeSelectPremisesAddressValueMeta = () =>
  createSelector(makeSelectPremisesAddressMeta(), (state: Map<string, any>) => {
    return state.get(Field.ADDRESS, Map());
  });

export const makeSelectPremisesAddressSameAsRegisteredMeta = () =>
  createSelector(makeSelectPremisesAddressMeta(), (state: Map<string, any>) => {
    return state.get(Field.SAME_AS_REGISTERED, Map());
  });

// General
export const makeSelectGeneral = () =>
  createSelector(selectCorporateBlock(Block.GENERAL_INFORMATION), (state: Map<string, any>) => {
    return state.get('general', Map()) || Map({});
  });

export const makeSelectGeneralMeta = () =>
  createSelector(makeSelectGeneralInfoStepMeta('general'), (value: any) => value || Map({}));

export const makeSelectFullLegalName = () =>
  createSelector(makeSelectGeneral(), (state: Map<string, any>) => {
    return state.get(Field.FULL_LEGAL_NAME, '');
  });

export const makeSelectTradeName = () =>
  createSelector(makeSelectGeneral(), (state: Map<string, any>) => {
    return state.get(Field.TRADE_NAME, '');
  });

export const makeSelectTypeOfCompany = () =>
  createSelector(makeSelectGeneral(), (state: Map<string, any>) => {
    return state.get(Field.TYPE_OF_COMPANY, '');
  });

export const makeSelectCompanyRegistrationNumber = () =>
  createSelector(makeSelectGeneral(), (state: Map<string, any>) => {
    return state.get(Field.COMPANY_REGISTRATION_NUMBER, '');
  });

export const makeSelectCompanyIndustry = () =>
  createSelector(makeSelectGeneral(), (state: Map<string, any>) => {
    return state.get(Field.INDUSTRY_CORP, '');
  });

export const makeSelectCompanyRegionOfBusiness = () =>
  createSelector(makeSelectGeneral(), (state: Map<string, any>) => {
    return state.get(Field.REGION_OF_BUSINESS, '');
  });

export const makeSelectCompanyOtherIndustry = () =>
  createSelector(makeSelectGeneral(), (state: Map<string, any>) => {
    return state.get(Field.OTHER_INDUSTRY_CORP, '');
  });

export const makeSelectFullLegalNameMeta = () =>
  createSelector(makeSelectGeneralMeta(), (state: Map<string, any>) => {
    return state.get(Field.FULL_LEGAL_NAME, Map());
  });

export const makeSelectTradeNameMeta = () =>
  createSelector(makeSelectGeneralMeta(), (state: Map<string, any>) => {
    return state.get(Field.TRADE_NAME, Map());
  });

export const makeSelectTypeOfCompanyMeta = () =>
  createSelector(makeSelectGeneralMeta(), (state: Map<string, any>) => {
    return state.get(Field.TYPE_OF_COMPANY, Map());
  });

export const makeSelectCompanyRegistrationNumberMeta = () =>
  createSelector(makeSelectGeneralMeta(), (state: Map<string, any>) => {
    return state.get(Field.COMPANY_REGISTRATION_NUMBER, Map());
  });

export const makeSelectCompanyIndustryMeta = () =>
  createSelector(makeSelectGeneralMeta(), (state: Map<string, any>) => {
    return state.get(Field.INDUSTRY_CORP, Map());
  });

export const makeSelectCompanyOtherIndustryMeta = () =>
  createSelector(makeSelectGeneralMeta(), (state: Map<string, any>) => {
    return state.get(Field.OTHER_INDUSTRY_CORP, Map());
  });

export const makeSelectCompanyRegionOfBusinessMeta = () =>
  createSelector(makeSelectGeneralMeta(), (state: Map<string, any>) => {
    return state.get(Field.REGION_OF_BUSINESS, Map());
  });

// GeneralVat
export const makeSelectGeneralVat = () =>
  createSelector(selectCorporateBlock(Block.GENERAL_INFORMATION), (state: Map<string, any>) => {
    return state.get('generalVat', Map()) || Map({});
  });

export const makeSelectGeneralVatMeta = () =>
  createSelector(makeSelectGeneralInfoStepMeta('generalVat'), (value: any) => value || Map({}));

export const makeSelectEmployerIdentificationNumber = () =>
  createSelector(makeSelectGeneralVat(), (state: Map<string, any>) => {
    return state.get(Field.EMPLOYER_IDENTIFICATION_NUMBER, '');
  });

export const makeSelectTIN = () =>
  createSelector(makeSelectGeneralVat(), (state: Map<string, any>) => {
    return state.get(Field.TIN, '');
  });

export const makeSelectVAT = () =>
  createSelector(makeSelectGeneralVat(), (state: Map<string, any>) => {
    return state.get(Field.VAT, '');
  });

export const makeSelectDateOfIncorporation = () =>
  createSelector(makeSelectGeneralVat(), (state: Map<string, any>) => {
    return state.get(Field.DATE_OF_INCORPORATION, '');
  });

export const makeSelectEmployerIdentificationNumberMeta = () =>
  createSelector(makeSelectGeneralVatMeta(), (state: Map<string, any>) => {
    return state.get(Field.EMPLOYER_IDENTIFICATION_NUMBER, Map());
  });

export const makeSelectTINMeta = () =>
  createSelector(makeSelectGeneralVatMeta(), (state: Map<string, any>) => {
    return state.get(Field.TIN, Map());
  });

export const makeSelectVATMeta = () =>
  createSelector(makeSelectGeneralVatMeta(), (state: Map<string, any>) => {
    return state.get(Field.VAT, Map());
  });

export const makeSelectDateOfIncorporationMeta = () =>
  createSelector(makeSelectGeneralVatMeta(), (state: Map<string, any>) => {
    return state.get(Field.DATE_OF_INCORPORATION, Map());
  });

export const makeSelectDateOfIncorporationObject = () => {
  return createSelector(makeSelectDateOfIncorporation(), (date) => dateUtils.dateStrToObj(date));
};

export const makeSelectSocialProfiles = () =>
  createSelector(selectCorporateBlock(Block.GENERAL_INFORMATION), (state: Map<string, any>) => {
    return state.get('socialProfiles', Map()) || Map({});
  });

// Phone
export const makeSelectPhoneBlock = () =>
  createSelector(selectCorporateBlock(Block.GENERAL_INFORMATION), (state: Map<string, any>) => {
    return state.get('phone', Map()) || Map({});
  });

export const makeSelectPhone = () =>
  createSelector(makeSelectPhoneBlock(), (state: Map<string, any>) => {
    return state.get('phone', Map()) || Map({});
  });

// Phone validation
const phoneNumberSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(selectorCorporate, (state) => {
    return state.getIn([subStore, joinByChar(Step.PHONE_NUMBER)], Map());
  });

export const makeSelectPhoneMeta = () => {
  return createSelector(phoneNumberSelector(SubStore.META), (state) =>
    state.get(Field.PHONE, Map())
  );
};

export const makeSelectPhoneCode = () => {
  return createSelector(makeSelectGeneralVat(), (state) =>
    state.getIn([Field.PHONE, Field.PHONE_CODE], '')
  );
};

export const makeSelectPhoneNumber = () => {
  return createSelector(makeSelectGeneralVat(), (state) =>
    state.getIn([Field.PHONE, Field.PHONE_NUMBER], '')
  );
};

export const makeSelectPhoneCountry = () => {
  return createSelector(makeSelectGeneralVat(), (state) =>
    state.getIn([Field.PHONE, Field.COUNTRY], '')
  );
};

export const makeSelectPhoneValidation = () =>
  createSelector(selectorCorporate, (state: any) => state.get('phoneValidation', Map()));

export const makeSelectPhoneValidationErrors = () =>
  createSelector(makeSelectPhoneValidation(), (state: any) => state.get('error', Map()));

export const makeSelectPhoneValidationSkip = () =>
  createSelector(makeSelectPhoneValidation(), (state: any) => state.get('skip', false));

export const makeSelectPhoneValidationValid = () =>
  createSelector(
    [makeSelectPhoneValidation(), makeSelectPhoneValidationErrors()],
    (state: any, error: any) => {
      const errorCode = error.get('code', 0);
      return state.get('valid', false) || errorCode === PhoneErrors.VALIDATED;
    }
  );

export const makeSelectPhoneValidationTimestamp = () =>
  createSelector(
    [makeSelectPhoneValidation(), makeSelectPhoneValidationErrors()],
    (state: any, errors: any) => state.get('timestamp', 0) || errors.getIn(['data', 'timestamp'], 0)
  );

export const makeSelectValidationPhoneCode = () =>
  createSelector([makeSelectPhoneValidation(), makeSelectPhoneCode()], (phoneState, code) =>
    phoneState.getIn(['phone', 'code'], code)
  );

export const makeSelectValidationPhoneNumber = () =>
  createSelector([makeSelectPhoneValidation(), makeSelectPhoneNumber()], (phoneState, number) =>
    phoneState.getIn(['phone', 'number'], number)
  );

export const makeSelectValidationPhoneCountry = () =>
  createSelector([makeSelectPhoneValidation(), makeSelectPhoneCountry()], (phoneState, country) =>
    phoneState.getIn(['phone', 'country'], country)
  );

export const makeSelectValidationPhoneMask = () =>
  createSelector([makeSelectPhoneValidation()], (phoneState) =>
    phoneState.getIn(['phone', 'phoneMask'], '')
  );

export const makeSelectValidationCode = () =>
  createSelector(makeSelectPhoneValidation(), (phoneState) => phoneState.get('code', ''));

export const makeSelectValidationIsValidated = () =>
  createSelector(makeSelectPhoneValidation(), (state) => state.get(Field.PHONE_IS_VALIDATED));
