import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'utils/helpers';
import { Visible } from 'components/common/Visible';

import { withLocalization, WithLocalizationProps } from 'system/Localization';

import { QRCode } from 'components/common/QRCode/QRCode';
import { Divider, Link } from 'components/UI';

interface UploadingMethodProps {
  qrCodeUrl?: string;
  webUrl: string;
  isDisabledUrl?: boolean;
  handleSubmit?: (evt: any) => void;
}

export const UploadingMethod = withLocalization<
  WithLocalizationProps<UploadingMethodProps>
>(({ qrCodeUrl = '', webUrl = '', isDisabledUrl, handleSubmit, translate }) => {
  // TODO:Temporary disable QR code ZNYCH-8321
  // const showQRCode = !isMobile() && qrCodeUrl;
  return null;
  // return (
  //   <UploadingMethodArea>
  //     <Visible if={false}>
  //       <>
  //         <QRCodeArea>
  //           <QRCode value={qrCodeUrl} />
  //           <QRCodeCaption>{translate('upload_qr_code')}</QRCodeCaption>
  //         </QRCodeArea>
  //         <Divider>{translate('or')}</Divider>
  //       </>
  //     </Visible>
  //     <LinkArea>
  //       <Link
  //         to={webUrl}
  //         onClick={handleSubmit}
  //         data-aqaid="upload-from-web-btn"
  //         disabled={isDisabledUrl}
  //       >
  //         {translate('upload_from_web')}
  //       </Link>
  //     </LinkArea>
  //   </UploadingMethodArea>
  // );
});

const UploadingMethodArea = styled.div`
  text-align: center;
`;

const QRCodeArea = styled.div`
  padding: 6px 0;
`;

const QRCodeCaption = styled.div`
  margin-top: 16px;
`;

const LinkArea = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;
`;
