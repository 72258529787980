import {
  IRetryStage,
  IRetryStageFinished,
  IRetryStageStart,
  IResetStageData,
  Type,
  IResubmit,
  IFeedbackSave,
  IZipGet,
  IZipLocation,
} from './actionTypes';
import { IValues } from '../../components/common/Feedback';

export const submitting = () => {
  return {
    type: Type.UI_SUBMITTING,
  };
};

export const submitted = () => {
  return {
    type: Type.UI_SUBMITTED,
  };
};

export const failedSubmitting = (error: Error | Error[]) => ({
  type: Type.UI_SUBMITTING_FAILED,
  payload: error,
});

export const saveFeedback = (payload: IValues): IFeedbackSave => {
  return {
    type: Type.UI_FEEDBACK_SAVE,
    payload,
  };
};

export const getZipSuggestions = (payload: IZipGet['payload']): IZipGet => ({
  type: Type.UI_ZIP_SUGGESTIONS_GET,
  payload,
});

export const getZipLocation = (
  payload: IZipLocation['payload']
): IZipLocation => ({
  type: Type.UI_ZIP_RETRIEVE_LOCATION,
  payload,
});

export const resubmitStagesRequest = (
  payload: IResubmit['payload']
): IResubmit => ({
  type: Type.UI_RESUBMIT_STAGES,
  payload,
});

export const retryStage = (stageId: string): IRetryStage => ({
  type: Type.UI_RETRY_STAGE,
  payload: { stageId },
});

export const retryStageStart = (): IRetryStageStart => ({
  type: Type.UI_RETRY_STAGE_START,
});

export const retryStageFinished = (): IRetryStageFinished => ({
  type: Type.UI_RETRY_STAGE_FINISHED,
});

export const resetStageData = (stageId: string): IResetStageData => ({
  type: Type.UI_RESET_STAGE_DATA,
  payload: { stageId },
});

export const resetErrors = () => ({
  type: Type.UI_RESET_ERRORS,
});
