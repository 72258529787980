import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Lock: React.FunctionComponent<IconProps> = ({ width, height }) => {
  const {
    icons: {
      markup: {
        lock: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg height={height} viewBox={`0 0 ${width} ${height}`} width={width} xmlns="http://www.w3.org/2000/svg">
      <path d="M23.134 12H21.15V9.333C21.151 4.187 17 0 11.897 0 6.795 0 2.644 4.187 2.644 9.333V12H.66a.663.663 0 0 0-.661.667v16.666C0 30.804 1.186 32 2.644 32H21.15c1.458 0 2.644-1.196 2.644-2.667V12.667a.663.663 0 0 0-.661-.667zm-9.258 13.926a.672.672 0 0 1-.164.519.659.659 0 0 1-.493.222h-2.643a.659.659 0 0 1-.493-.222.672.672 0 0 1-.165-.519l.417-3.781A2.645 2.645 0 0 1 9.254 20c0-1.47 1.185-2.667 2.643-2.667 1.459 0 2.644 1.196 2.644 2.667 0 .862-.404 1.648-1.081 2.145l.416 3.781zM17.186 12H6.61V9.333C6.61 6.393 8.982 4 11.897 4c2.916 0 5.288 2.393 5.288 5.333V12z" fill={fill} fillRule="nonzero" />
    </svg>
  );
};

Lock.displayName = 'LockIcon';

Lock.defaultProps = { height: '32', width: '24' };
