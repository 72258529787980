export const palette: { [key: string]: string } = {
  c1: '#263850',
  c2: '#0c87f2',
  c3: '#fff',
  c4: '#2094fa',
  c5: '#e65069',
  c6: '#e0e6e8',
  c7: '#3e4a5c',
  c8: '#cacaca',
  c9: '#c96866',
  c10: '#e3e7ee',
  c11: '#232a34',
  c12: '#0abf64',
  c13: '#f58e23',
  c14: '#fcfcfc',
  c15: '#efefef',
  c16: '#41b3db',
  c17: '#DBE1EA',
  c18: '#DBE1EA',
  c19: '#D6DCE1',
  c20: '#344563',
  c21: '#f8fafe',
  c22: '#E5E9ED',
  c23: '#D8D8D8',
  c24: '#528FCC',
  c25: '#4ec0e8',
  c26: '#E6F3FD',
  c27: '#D7EBFD',
  c28: '#667C99',
  c29: '#04BCC6',
  c30: '#22C4FF',
};
