import { CountryId, FileInfo, IDate, IMeta, IsoDate } from './shared/types';

import { IPersonalInfo } from './IPersonalInfo';
import { IAddressResidential } from './IAddress';
import { ILivenessResult } from './ILivenessCheck';

type DocumentTypeId = 'identity' | 'address' | 'finance';

export enum Document {
  DRIVER_LICENCE = 'driverLicense',
  NATIONAL_ID = 'nationalIdCard',
  PASSPORT = 'internationalPassport',
}

export type DocumentIdentitySubtypeId = Document;
export type DocumentAddressSubtypeId = '0' | '1' | '2' | '3' | 'other';
type DocumentFinanceSubtypeId = '0' | '1' | '2' | '3' | '4' | 'other';

type DocumentSubtypeId =
  | DocumentIdentitySubtypeId
  | DocumentAddressSubtypeId
  | DocumentFinanceSubtypeId;

interface IDocument {
  type: DocumentTypeId;
  subType: DocumentSubtypeId;
  images: FileInfo[];
}

export interface IIdentityDocument extends IDocument {
  type: 'identity';
  subType: DocumentIdentitySubtypeId;
  images: FileInfo[];
  issuingCountry: CountryId;
  personalData: IPersonalInfo;
  hasExpireDate: boolean;
  selfieImage: FileInfo; // uploaded selfie with doc or screenshot on livenessCheck
  serialNumber: string;
  expireDate?: IsoDate;
  livenessCheck?: {
    action: ILivenessResult[];
    time: number;
  }[];
}

export interface IAddressDocument extends IDocument {
  type: 'address';
  subType: DocumentAddressSubtypeId;
  subTypeDetailed?: string;
  images: FileInfo[];
  address: IAddressResidential;
}

export interface IFinanceDocument extends IDocument {
  type: 'finance';
  subType: DocumentFinanceSubtypeId;
  subTypeDetailed?: string; // required for subType = Other
  images: FileInfo[];
}

export enum PHOTO_MODE {
  BACK = 'backSide',
  FRONT = 'frontSide',
  SELFIE = 'selfie',
  SELFIE_WITH_NOTE = 'selfieWithNote',
  SCAN_FRONT= 'scanFrontSide',
  SCAN_BACK = 'scanBackSide',
}

export interface DocumentValues {
  subType: string;
  subTypeDetailed: string;
}

export type DocumentMeta = Iterable<['subType' | 'subTypeDetailed', IMeta]>;

export interface DocumentInfoValues {
  serialNumber: string;
  issuingCountry: string;
  hasExpireDate: boolean;
  expireDate: IDate;
}

export type DocumentInfoMeta = Iterable<
  ['serialNumber' | 'issuingCountry' | 'expireDate', IMeta]
>;

export interface DocumentsValues {
  subType: string;
  issuingCountry: string;
}

export type DocumentsMeta = Iterable<['subType' | 'issuingCountry', IMeta]>;
