import { fromJS } from 'immutable';
import isArray from 'lodash/isArray';

import { ActionType as UIActionType, Type as UIType } from 'system/UI/actionTypes';
import { Stage } from 'system/Stages/types';
import { PhoneErrorsType } from 'utils/error';
import { ActionType, Type } from './actionTypes';

export const defaultState = fromJS({
  loading: false,
  errors: [],
  data: {},
  meta: {},
  phoneValidation: {
    timestamp: 0,
    error: {},
    valid: false,
    skip: false,
    phone: {
      number: '',
      code: '',
      country: '',
    },
    code: '',
  },
});

export const reducer = (
  state: any = defaultState,
  { type, payload }: ActionType & UIActionType
) => {
  switch (type) {
    case Type.IDENTITY_STEP_INITIAL_SET: {
      const { stepId, fields = {} } = payload;

      const update = state
        .getIn(['data', stepId], fromJS(isArray(fields) ? [] : {}))
        .merge(fromJS(fields));

      return state.setIn(['data', stepId], fromJS(update));
    }
    case Type.IDENTITY_STEP_SET: {
      const { stepId, fields = {} } = payload;

      return state.setIn(['data', stepId], fromJS(fields));
    }
    case Type.IDENTITY_BLOCK_RESET: {
      const { stepId } = payload;
      return state.setIn(['data', stepId], fromJS({}));
    }
    case Type.IDENTITY_DOC_SET: {
      return state.setIn(['data', 'upload'], fromJS(payload));
    }
    case Type.IDENTITY_META_SET: {
      const { stepId, fields } = payload;

      return state.mergeIn(['meta', stepId], fields);
    }
    case Type.IDENTITY_SUBMIT_REQUEST:
    case Type.IDENTITY_UPLOAD_DOC_REQUEST: {
      return state.set('loading', true);
    }
    case Type.IDENTITY_SUBMIT_SUCCESS:
    case Type.IDENTITY_UPLOAD_DOC_SUCCESS: {
      return state.set('loading', false);
    }
    case Type.IDENTITY_SUBMIT_FAILED:
    case Type.IDENTITY_UPLOAD_DOC_FAILED: {
      return state.set('loading', false).set('errors', fromJS(payload));
    }
    case UIType.UI_RESET_ERRORS:
      return state.set('errors', fromJS([]));
    case UIType.UI_RESET_STAGE_DATA: {
      const { stageId } = payload;
      return stageId === Stage.IDENTITY || !stageId ? defaultState : state;
    }
    case Type.IDENTITY_PHONE_CODE_REQUEST: {
      const { phone } = payload;
      const prevPhone = state.getIn(['phoneValidation', 'phone'], fromJS({}));
      const prevValid = state.getIn(['phoneValidation', 'valid'], false);

      return state
        .set('loading', true)
        .setIn(['phoneValidation', 'valid'], prevPhone.equals(fromJS(phone)) ? prevValid : false)
        .setIn(['phoneValidation', 'code'], '')
        .setIn(['phoneValidation', 'timestamp'], 0)
        .setIn(['phoneValidation', 'phone'], fromJS(phone));
    }
    case Type.IDENTITY_PHONE_CODE_FAILED: {
      const { data } = payload as any;
      return state
        .set('loading', false)
        .setIn(['phoneValidation', 'timestamp'], data?.timestamp ? data?.timestamp : 0)
        .setIn(['phoneValidation', 'error'], fromJS(payload))
        .setIn(['phoneValidation', 'error', 'type'], PhoneErrorsType.PHONE);
    }
    case Type.IDENTITY_PHONE_CODE_SUCCESS: {
      const { timestamp } = payload;
      return state.setIn(['phoneValidation', 'timestamp'], timestamp).set('loading', false);
    }

    case Type.IDENTITY_RESET_PHONE_ERRORS: {
      return state.setIn(['phoneValidation', 'error'], fromJS({}));
    }
    case Type.IDENTITY_RESET_PHONE_VALIDATION: {
      return state
        .setIn(['phoneValidation', 'timestamp'], 0)
        .setIn(['phoneValidation', 'valid'], false)
        .setIn(['phoneValidation', 'skip'], false);
    }

    case Type.IDENTITY_SET_PHONE_COUNTRY: {
      return state.setIn(['phoneValidation', 'phone', 'country'], payload);
    }

    case Type.IDENTITY_RESET_PHONE_CODE: {
      return state.setIn(['phoneValidation', 'phone', 'code'], '');
    }

    default:
      return state;
  }
};
