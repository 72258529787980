export const options: any = (iss: string) => ({
  excludes: {
    audio: true,
    canvas: true,
    cpuClass: true,
    deviceMemory: true,
    enumerateDevices: true,
    hardwareConcurrency: true,
    hasLiedBrowser: true,
    hasLiedLanguages: true,
    hasLiedOs: true,
    hasLiedResolution: true,
    indexedDb: true,
    openDatabase: true,
    touchSupport: true,
    webdriver: true,
  },
  fonts: { extendedJsFonts: true },
  extraComponents: [
    {
      key: 'cookies',
      getData(done: (value: any) => void) {
        done(navigator.cookieEnabled);
      },
    },
    {
      key: 'languages',
      getData(done: (value: any) => void) {
        done(navigator.languages);
      },
    },
    {
      key: 'buildID',
      getData(done: (value: any) => void) {
        const { buildID } = navigator as any;
        done(buildID);
      },
    },
    {
      key: 'merchant',
      getData(done: (value: any) => void) {
        done(iss);
      },
    },
  ]
});
