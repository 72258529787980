import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';

import {
  recognizerValuesMiddleware,
  countriesAdapter,
} from './utils/valueAdapters';
import { MetaSource } from '../enums';

export class Backend {
  private input: any;

  private readonly keys: string[];

  private settings: any = {
    valueAdapters: {
      nationality: countriesAdapter,
      issuingCountry: countriesAdapter,
    },
  };

  constructor(input: any, keys: string[]) {
    this.input = input;
    this.keys = keys;
  }

  private adaptedFields() {
    const fields = this.input.reduce((acc: any, item: any, idx: number) => {
      const name = this.keys[idx];
      if (!isEmpty(item) && name) {
        acc[name] = mapValues(
          {
            ...item,
            type: MetaSource.BACKEND,
          },
          (value: any, key: string) => {
            return recognizerValuesMiddleware(value, key, {
              settings: this.settings.valueAdapters,
            });
          }
        );
      }
      return acc;
    }, {});

    return omitBy(fields, (val) => !isBoolean(val) && isEmpty(val));
  }

  getSourceData() {
    return this.adaptedFields();
  }
}
