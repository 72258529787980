import { call, select } from 'redux-saga/effects';

import { makeSelectApiUrl } from 'system/Settings/selectors';

import { jsonRPC } from 'utils/request';

export interface IRecognizePayload {
  imageTokens: string[];
  subType: string;
  issuingCountry: string | undefined;
  stageId?: any;
  entryId?: string;
  blockId?: string;
}

export function* recognizeDocuments(payload: IRecognizePayload): any {
  const { stageId, entryId, imageTokens: images, subType, issuingCountry, blockId } = payload;

  const api = yield select(makeSelectApiUrl());

  return yield call(jsonRPC, {
    namespace: 'va',
    method: 'recognizeDocuments',
    api,
    params: {
      ...(stageId && { stageId }),
      ...(entryId && { entryId }),
      ...(blockId && { blockId }),
      identityDocument: {
        subType,
        issuingCountry,
        images,
      },
    },
  });
}
