import React, { FunctionComponent } from 'react';
import get from 'lodash/get';

import { StageStatusName } from 'system/Stages/types';

import { Badge } from 'components/UI/Badge';
import { ITranslate } from 'system/Localization';

export type BadgeStatuses = Extract<
  StageStatusName,
  | 'accepted'
  | 'correction'
  | 'refused'
  | 'pending'
  | 'next_stage_needed'
  | 'current_stage_needed'
  | 'inprogress'
>;

export type IStatusMessages = {
  [k in BadgeStatuses]: any;
};

interface IStageStatusBadgeProps {
  status: BadgeStatuses;
  translate: ITranslate;
}

const StageStatusBadge: FunctionComponent<IStageStatusBadgeProps> = ({
  status,
  translate,
}) => {
  const badgeByStatus: IStatusMessages = {
    accepted: (
      <Badge isSuccess data-aqaid="status-accepted">
        {translate('stage_status_accepted')}
      </Badge>
    ),
    correction: (
      <Badge isWarning data-aqaid="status-correction">
        {translate('stage_status_correction')}
      </Badge>
    ),
    next_stage_needed: (
      <Badge isPrimary data-aqaid="status-next_stage_needed">
        {translate('stage_status_next_required')}
      </Badge>
    ),
    current_stage_needed: (
      <Badge isWarning data-aqaid="status-current_stage_needed">
        {translate('stage_status_current_required')}
      </Badge>
    ),
    pending: (
      <Badge isPrimary data-aqaid="status-pending">
        {translate('stage_status_pending')}
      </Badge>
    ),
    inprogress: (
      <Badge isPrimary data-aqaid="status-inprogress">
        {translate('stage_status_pending')}
      </Badge>
    ),
    refused: (
      <Badge isDanger data-aqaid="status-refused">
        {translate('stage_status_rejected')}
      </Badge>
    ),
  };

  return get(badgeByStatus, status, null);
};

StageStatusBadge.displayName = 'StageStatusBadge';

export { StageStatusBadge };

