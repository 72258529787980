import { fromJS, Map } from 'immutable';
import get from 'lodash/get';

import { ExternalSource } from 'utils/externalProfiles/enums';
import {
  Type as UIType,
  ActionType as UIActionType,
} from 'system/UI/actionTypes';
import { IProfile } from './types';

import { Type, ActionType } from './actionTypes';

const defaultState = fromJS({
  loading: false,
  errors: [],
  uid: '',
  dossierStatus: '',
  email: '',
  iss: '',
  requestToken: '',
  stages: [],
  userData: {},
  backend: {},
  feedback: {},
  merchantFeatures: {},
  registrationData: {},
} as IProfile);

export function reducer(
  state: any = defaultState,
  { type, payload }: ActionType & UIActionType
) {
  switch (type) {
    case Type.PROFILE_SET: {
      const {
        uid = '',
        email = '',
        iss = '',
        product = '',
        requestToken = '',
        selfieWithIdentity = null,
        selfieWithNote = null,
        features = {},
      } = payload;
      return state
        .set('uid', uid)
        .set('email', email)
        .set('iss', iss)
        .set('product', product)
        .set('requestToken', requestToken)
        .setIn(
          ['merchantFeatures'],
          Map({ selfieWithIdentity, selfieWithNote, ...features })
        );
    }
    case Type.STAGES_SET: {
      return state.set('stages', fromJS(payload));
    }
    case Type.USER_DATA_SET: {
      const signers = get(payload , 'esignature.signers');

      if(signers) {
        const path = ['userData', 'esignature', 'signers'];
      
        const transformSigners = Object.values(signers).reduce((acc: any, val: any) => {
          acc[val.entryId] = val;
          return acc
        }, {})

        return state.setIn(path, fromJS(transformSigners))
      }
      
      return state.set('userData', fromJS(payload));
    }
    case Type.DOSSIER_STATUS_SET: {
      return state.set('dossierStatus', payload);
    }
    case Type.REGISTRATION_DATA_SET: {
      return state.set('registrationData', payload);
    }
    case Type.RECOGNIZER_PROFILE_SET: {
      return state.setIn([ExternalSource.BACKEND], fromJS(payload));
    }
    case Type.FEEDBACK_HISTORY_SET:
      return state.set('feedback', fromJS(payload));
    case Type.MERCHANT_FEATURES_SET:
      return state.set(
        'merchantFeatures',
        state.get('merchantFeatures').merge(fromJS(payload))
      );
    case Type.PROFILE_FAILED: {
      return state.set('errors', fromJS(payload));
    }
    case UIType.UI_RESET_ERRORS:
      return state.set('errors', fromJS([]));
    default:
      return state;
  }
}
