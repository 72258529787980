import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';

import { resubmitStagesRequest } from 'system/UI/actions';
import { makeSelectSubmitted } from 'system/UI/selectors';
import {
  ITranslate,
  withLocalization,
  WithLocalizationProps,
} from 'system/Localization';
import { makeSelectResetStageIds } from 'system/Profile/selectors';

import { serialize } from 'utils/serialization';

import { NavLink } from 'components/UI/Link/NavLink';
import { ToolTip } from 'components/common/ToolTip';
import {
  BaseModal,
  Buttons,
  Content,
  Title,
} from 'components/common/BaseModal';
import { TriggerBox } from 'components/common/ToolTip/Tip';
import { Button, Primary } from 'components/UI/Button';
import { getStageTitle } from 'components/common/StagePreview/utils/helpers';
import { StageId } from 'system/Stages/types';
import { ResubmitItems, Item } from './ResubmitItems';

interface ResubmitContainerProps {
  isMobile?: boolean;
  resubmitStageIds: Map<StageId, any> | Map<string, any>;
  translate: ITranslate;
  handleResubmitStages: (stageId: StageId | string) => void;
  submittedStatus: boolean;
}

function ResubmitContainer({
  isMobile,
  resubmitStageIds,
  translate,
  handleResubmitStages,
  submittedStatus,
}: ResubmitContainerProps) {
  const resubmitStages: { [key: string]: any } = resubmitStageIds.toJS();
  const resubmitList = Object.keys(resubmitStages) as StageId[];

  const [modalIsOpen, triggerOpenModal] = useState(false);
  const [resubmitId, setResubmitId] = useState<StageId | string>('');

  const handleSelectResubmitStage = (stageId: StageId | string) => () => {
    setResubmitId(stageId);
    triggerOpenModal(true);
  };

  const handleCloseModal = () => {
    triggerOpenModal(false);
  };

  const handleConfirmResubmitStage = (stageId: StageId | string) => () =>
    handleResubmitStages(stageId);

  const stages = resubmitId ? resubmitStages[resubmitId] : [];

  useEffect(() => {
    if (submittedStatus) {
      handleCloseModal();
    }
  }, [submittedStatus]);

  const stageTitles = stages?.map((stage: StageId) =>
    translate(getStageTitle(stage))
  );

  return (
    <>
      {isMobile ? (
        <List>
          <ResubmitItems
            resubmitList={resubmitList}
            handleSelectResubmitStage={handleSelectResubmitStage}
            translate={translate}
          />
          <Item>{translate('mobile_menu_info')}</Item>
        </List>
      ) : (
        <ResubmitButton
          placement="bottom"
          trigger={
            <ResubmitLink to="#" data-aqaid="resubmit-btn">
              {translate('button_resubmit')}
            </ResubmitLink>
          }
        >
          <List>
            <ResubmitItems
              resubmitList={resubmitList}
              handleSelectResubmitStage={handleSelectResubmitStage}
              translate={translate}
            />
          </List>
        </ResubmitButton>
      )}
      <BaseModal isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
        <Title>{translate('dialog_title_sure')}</Title>
        <Content>
          {translate(
            serialize({
              key: 'dialog_message_resubmit',
              args: [stageTitles?.join(', ')],
            })
          )}
        </Content>
        <Buttons>
          <Primary
            onClick={handleConfirmResubmitStage(resubmitId)}
            data-aqaid={`resubmit-${resubmitId}-ok`}
          >
            {translate('dialog_button_resubmit')}
          </Primary>
          <Button
            onClick={handleCloseModal}
            data-aqaid={`resubmit-${resubmitId}-cancel`}
          >
            {translate('dialog_button_cancel')}
          </Button>
        </Buttons>
      </BaseModal>
    </>
  );
}

const ResubmitButton = styled(ToolTip)`
  ${TriggerBox} {
    padding: 10px 0;
    margin: -10px 0;
  }
`;

const ResubmitLink = styled(NavLink)`
  && {
    color: ${({ theme }) => theme.buttons.resubmit.triggerColor};
    border-color: ${({ theme }) => theme.buttons.resubmit.borderColor};
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
`;

const mapStateToProps = createStructuredSelector({
  resubmitStageIds: makeSelectResetStageIds(),
  submittedStatus: makeSelectSubmitted(),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  handleResubmitStages: (stageId: StageId | string) => {
    dispatch(resubmitStagesRequest({ stageId }));
  },
});

export const Resubmit = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withLocalization<WithLocalizationProps<ResubmitContainerProps>>(
    ResubmitContainer
  )
);
