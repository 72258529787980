import React from 'react';
import { Dispatch } from 'redux';
import { withRouter, match } from 'react-router-dom';
import { History, Location } from 'history';

import { connect } from 'react-redux';
import { ILocalizationProps, withLocalization } from 'system/Localization';

import { navigateIndex } from 'system/Routing/actions';
import {
  BUTTON_TYPE,
  ICommonStubProps,
} from 'containers/Stubs/CommonStub/types';

import { CommonStub } from 'containers/Stubs/CommonStub';
import { Stub } from 'components/UI/Icon';

interface ISubmittedStub extends ILocalizationProps {
  location: Location;
  history: History;
  match: match<{ statusId: string }>;
  fallback: string;
  handleLocation: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  handleLocation: () => {
    dispatch(navigateIndex({ exit: true }));
  },
});

export const StatusStub = connect(
  null,
  mapDispatchToProps
)(
  withRouter(
    withLocalization(
      ({
        translate,
        match: {
          params: { statusId: id },
        },
        handleLocation,
      }: ISubmittedStub) => {
        const contentProps: ICommonStubProps = {
          stageTitle: '',
          buttons: [
            {
              clickHandler: handleLocation,
              text: translate('button_finish'),
              mods: { type: BUTTON_TYPE.primary },
            },
          ],
          body: {
            Icon: Stub.Notice,
            title: translate(`stage_status_${id}`),
          },
        };

        return <CommonStub {...contentProps} />;
      }
    )
  )
);
