import React, { FunctionComponent } from 'react';
import { IDropdownProps } from 'components/UI/Dropdown';

export const withCustomOption = (Option: FunctionComponent) => <P extends {}>(
  WrappedComponent: FunctionComponent<P>
) => {
  const WithCustomOption: FunctionComponent<P & IDropdownProps> = (props) => {
    return <WrappedComponent components={{ Option }} {...props} />;
  };

  WithCustomOption.displayName = `WithCustomOption(${WrappedComponent.displayName})`;

  return WithCustomOption;
};
