import { PHOTO_MODE } from 'utils/types/IDocument';
import { parseToken } from 'utils/merchant';
import { IUploadResult } from './sagas';

export const getFileNamesArray = (fileTokens: IUploadResult[]) => {
  return fileTokens
    ? fileTokens.reduce((acc: string[], { id }: IUploadResult) => {
        if (id === PHOTO_MODE.SELFIE) {
          return acc;
        }
        return acc.concat(id);
      }, [])
    : [];
};

export const mapTokenToFile = (fileToken: string) => {
  const { data } = parseToken(fileToken);
  return {
    file: {
      type: data.fileMime,
      name: decodeURIComponent(data.fileName || ''),
      lastModified: data.date,
      size: data.fileLength,
      uploaded: true,
    },
    fileToken,
    id: data.id,
  };
};
