import { SubStep } from 'system/Routing/types';

export const shouldAskId = (country: string) => {
  return country && country === 'US';
};

export const shouldAskTIN = (country: string) => {
  return country && country !== 'US';
};

export const omittedStepsByRole = (roles: any) => {
  const ifUBO = roles.includes('0');
  const ifPosition = roles.includes('1') || roles.includes('2');
  const ifNotAuthorized = roles.includes('2');
  const result = [
    ...(ifUBO ? [] : [SubStep.UBO_DOCUMENTS, SubStep.UBO_UPLOAD, SubStep.UBO_SOURCE_OF_FUNDS]),
    ...(ifPosition ? [] : [SubStep.POSITION_DOCUMENTS, SubStep.POSITION_UPLOAD]),
    ...(ifNotAuthorized ? [] : [SubStep.IDENTITY_LIVENESS_CHECK]),
  ];
  return result;
};

export const shouldAskAdditional = (value: string) => {
  return value === 'other';
};
