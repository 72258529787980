import { IUploadParams } from '../../system/Dossier/Upload/sagas';

export enum Type {
  ENHANCED_STEP_INITIAL_SET = '@Enhanced/ENHANCED_STEP_INITIAL_SET',
  ENHANCED_STEP_SET = '@Enhanced/ENHANCED_STEP_SET',
  ENHANCED_BLOCK_FAILED = '@Enhanced/ENHANCED_BLOCK_FAILED',

  ENHANCED_UPLOAD_FILES_REQUEST = '@Enhanced/ENHANCED_UPLOAD_FILES_REQUEST',
  ENHANCED_UPLOAD_FILES_ERROR = '@Enhanced/ENHANCED_UPLOAD_FILES_ERROR',
  ENHANCED_UPLOAD_FILES_SUCCESS = '@Enhanced/ENHANCED_UPLOAD_FILES_SUCCESS',

  ENHANCED_SOURCES_ADD_FILES = '@Enhanced/ENHANCED_SOURCES_ADD_FILES',
  ENHANCED_SOURCES_REMOVE_FILES = '@Enhanced/ENHANCED_SOURCES_REMOVE_FILES',

  ENHANCED_SUBMIT_REQUEST = '@Enhanced/ENHANCED_SUBMIT_REQUEST',
  ENHANCED_SUBMIT_SUCCESS = '@Enhanced/ENHANCED_SUBMIT_SUCCESS',
  ENHANCED_SUBMIT_FAILED = '@Enhanced/ENHANCED_SUBMIT_FAILED',

  ENHANCED_META_SET = '@Enhanced/ENHANCED_META_SET',
}

export interface IEnhancedStepInitialSet {
  type: Type.ENHANCED_STEP_INITIAL_SET;
  payload: {
    stepId: string;
    fields: object;
  };
}

export interface IEnhancedStepSet {
  type: Type.ENHANCED_STEP_SET;
  payload: {
    stepId: string;
    fields: object;
  };
}

export interface IEnhancedBlockFailed {
  type: Type.ENHANCED_BLOCK_FAILED;
  payload: Error;
}

export interface IEnhancedUploadFileRequest {
  type: Type.ENHANCED_UPLOAD_FILES_REQUEST;
  payload: { files: File[] };
}

export interface IEnhancedUploadFileSuccess {
  type: Type.ENHANCED_UPLOAD_FILES_SUCCESS;
  payload: {};
}

export interface IEnhancedUploadFileError {
  type: Type.ENHANCED_UPLOAD_FILES_ERROR;
  payload: Error[];
}

export interface IEnhancedSourceAddFile {
  type: Type.ENHANCED_SOURCES_ADD_FILES;
  payload: {
    source: string;
    files: IUploadParams[];
  };
}

export interface IEnhancedSourceRemoveFile {
  type: Type.ENHANCED_SOURCES_REMOVE_FILES;
  payload: {
    source: string;
    index: number;
  };
}

export interface IEnhancedSubmitRequest {
  type: Type.ENHANCED_SUBMIT_REQUEST;
  payload: {};
}

export interface IEnhancedSubmitSuccess {
  type: Type.ENHANCED_SUBMIT_SUCCESS;
  payload: {};
}

export interface IEnhancedSubmitFailed {
  type: Type.ENHANCED_SUBMIT_FAILED;
  payload: Error[];
}

export interface IEnhancedSetMeta {
  type: Type.ENHANCED_META_SET;
  payload: {
    stepId: string;
    fields: object;
  };
}

export type ActionType =
  | IEnhancedStepInitialSet
  | IEnhancedStepSet
  | IEnhancedBlockFailed
  | IEnhancedUploadFileRequest
  | IEnhancedUploadFileSuccess
  | IEnhancedUploadFileError
  | IEnhancedSourceAddFile
  | IEnhancedSourceRemoveFile
  | IEnhancedSubmitRequest
  | IEnhancedSubmitSuccess
  | IEnhancedSubmitFailed
  | IEnhancedSetMeta;
