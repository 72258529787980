import styled from 'styled-components';
import { Link } from 'components/UI/Link';

export const ContentLink = styled(Link)`
  display: inline-block;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.links.base.fontWeight};
  text-decoration: ${({ theme }) => theme.links.base.textDecoration};
  color: ${({ theme }) => theme.links.base.color};

  :hover {
    opacity: 0.9;
    text-decoration: ${({ theme }) => theme.links.base.hover.textDecoration};
    color: ${({ theme }) => theme.links.base.hover.color};
  }
`;
