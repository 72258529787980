import { IGeoLocation } from 'utils/types/IGeo';
import { ISettings } from './types';

export enum Type {
  SETTINGS_GET = '@Settings/SETTINGS_GET',
  SETTINGS_SET = '@Settings/SETTINGS_SET',
  SETTINGS_FAILED = '@Settings/SETTINGS_FAILED',
  LOCALES_SET = '@Settings/LOCALES_SET',
  LOCALE_SET = '@Settings/LOCALE_SET',
  AB_TESTING_SET = '@Settings/AB_TESTING_SET',
  GEO_LOCATION_SET = '@Settings/GEO_LOCATION_SET',
  
  SET_TAB_INDEX = '@Settings/SET_TAB_INDEX',
  GET_TAB_INDEX = '@Settings/GET_TAB_INDEX',
}

export interface ISettingsGet {
  type: Type.SETTINGS_GET;
  payload: null;
}

export interface ISettingsSet {
  type: Type.SETTINGS_SET;
  payload: ISettings;
}

export interface ISettingsFailed {
  type: Type.SETTINGS_FAILED;
  payload: Error[];
}

export interface ILocaleSet {
  type: Type.LOCALE_SET;
  payload: string;
}

export interface ILocalesSet {
  type: Type.LOCALES_SET;
  payload: object;
}

export interface IABTestingSet {
  type: Type.AB_TESTING_SET;
  payload: object;
}

export interface IGeoLocationSet {
  type: Type.GEO_LOCATION_SET;
  payload: IGeoLocation;
}

export interface ITabIndexGet {
  type: Type.GET_TAB_INDEX;
  payload: { name: string };
}
export interface ITabIndexSet {
  type: Type.SET_TAB_INDEX;
  payload: { name: string; value: number};
}


export type ActionType =
  | ISettingsGet
  | ISettingsSet
  | ISettingsFailed
  | ILocaleSet
  | ILocalesSet
  | IABTestingSet
  | IGeoLocationSet
  | ITabIndexGet
  | ITabIndexSet;
