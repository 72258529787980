import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects';

import { Type as DictsType } from 'system/Dicts/actionTypes';
import { Type as ProfileType } from 'system/Profile/actionTypes';
import { Type as DossierType } from 'system/Dossier/actionTypes';
import { fetchDicts } from 'system/Dicts/sagas';
import {
  fetchUserLocation,
  fetchUserProfile,
  loadProfileSettings,
} from 'system/Profile/sagas';
import {
  applyLocale,
  loadAppLocales,
  loadAppSettings,
} from 'system/Settings/sagas';
import {
  qrCodeConnectedSettings,
  qrCodeIsConnected,
} from 'system/Dossier/Upload/sagas/qr';
import { loadFeedbackHistory } from 'system/UI/sagas';

import { makeErrorMessage } from 'utils/error';
import { IConfig } from 'merchants/types/IConfig';
import { routingBootstrap } from 'system/Routing/sagas';
import { routingSetStageProgress } from 'system/Routing/actions';
import { parseJwt, getToken } from 'utils/merchant';
import { setLoggerUserData } from 'utils/error/logger';
import { getAppEnv } from 'utils/env';
import { IStart, Type } from './actionTypes';
import { end, failed } from './actions';

function* bootstrap(action: IStart) {
  try {
    const {
      payload: { config },
    } = action;
    const { requestToken, i18n, product, features, stages, appConfig } = config as IConfig;
    const { iss, email, uid, locale, ...jwt } = parseJwt(requestToken || getToken());

    yield call(applyLocale, { locale: i18n?.locale || locale });
    yield fork(loadAppLocales, { translations: i18n?.translations });

    const isQrCodeConnected = yield qrCodeIsConnected();

    if (isQrCodeConnected) {
      yield call(loadAppSettings, { appConfig });
      const qrCodePageSettings = yield qrCodeConnectedSettings();
      const { index, amount } = qrCodePageSettings;
      yield put(routingSetStageProgress({ index, amount }));
      yield fork(loadAppSettings, qrCodePageSettings);
      yield fork(loadProfileSettings, qrCodePageSettings);

      yield put(end());
      return;
    }

    yield fork(loadFeedbackHistory);

    if (Object.keys(config).length) {
      yield call(loadAppSettings, { ...config });
      yield call(setLoggerUserData, { iss, uid, env: getAppEnv() });
      yield call(loadProfileSettings, {
        iss,
        ...(product ? { product }: { product: jwt.product }),
        requestToken,
        features,
        email,
        uid
      });
      yield fork(fetchUserProfile);
      yield fork(fetchDicts);

      yield all([
        take(ProfileType.USER_DATA_SET),
        take(DictsType.DICTS_SET),
        take(DossierType.FILL_USER_DOSSIER_FINISHED)
      ]);

      yield call(routingBootstrap, { stages });

      yield fork(fetchUserLocation);

      yield put(end());
    } else {
      yield put(failed([makeErrorMessage(new Error('Settings is empty!'))]));
    }
  } catch (err) {
    yield put(failed([makeErrorMessage(err)]));
  }
}

function* handleAppLoadingErrors() {
  yield put(end());
}

export function* rootSaga() {
  yield takeLatest(Type.APPLICATION_LOADING, bootstrap);
  yield takeLatest(
    [ProfileType.PROFILE_FAILED, DictsType.DICTS_FAILED],
    handleAppLoadingErrors
  );
}
