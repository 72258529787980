/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSelector } from 'reselect';
import { fromJS, List, Map } from 'immutable';
import isString from 'lodash/isString';
import trim from 'lodash/trim';

import { routerSelector } from 'system/Bootstrap/selectors';
import { makeSelectSourceOfFiatFounds } from 'system/Dicts/selectors';
import { makeSelectCountry as makeSelectCountryAddress } from 'stages/Address/selectors';
import {
  selectedStepSelector,
  SubStore,
  makeSelectCountry as makeSelectCountryIdentity,
} from 'stages/Identity/selectors';
import { makeSelectStageCorrectionMistakes } from 'system/Profile/selectors';

import { updateSourceMeta } from 'stages/Identity/sagas/utils';

import { url } from 'utils/url';
import { Field } from 'utils/types/shared/enums';
import { filter, isEmpty } from 'utils/collection';

const selector = (state: any) => state.getIn(['dossier', 'enhanced'], Map());

const fiatFundsSelector = (subStore = SubStore.DATA) =>
  createSelector(selector, (state) => state.getIn([subStore, 'sourceOfFiatFunds'], Map()));

export const cryptoFundsSelector = (subStore = SubStore.DATA) =>
  createSelector(selector, (state) => state.getIn([subStore, 'sourceOfCryptoFunds'], Map()));

export const withdrawalSelector = createSelector(selector, (state) =>
  state.getIn(['data', 'withdrawalDestination'], Map())
);

export const employmentSelector = (subStore = SubStore.DATA) =>
  createSelector(selector, (state) => state.getIn([subStore, 'employment'], Map()));

export const employmentDetailsSelector = (subStore = SubStore.DATA) =>
  createSelector(selector, (state) => state.getIn([subStore, 'employmentDetails'], Map()));

export const questionnaireSelector = (subStore = SubStore.DATA) =>
  createSelector(selector, (state) => {
    const questionnaire = state.getIn([subStore, 'financialQuestionnaire']);

    return Map.isMap(questionnaire)
      ? questionnaire.map((value: any) => (isString(value) ? trim(value) : value))
      : Map<string, any>();
  });

export const bankingJurisdictionSelector = (subStore = SubStore.DATA) =>
  createSelector(selector, (state) => state.getIn([subStore, 'bankingJurisdiction'], Map()));

export const makeSelectLoading = () =>
  createSelector(selector, (state) => state.get('loading', false));

export const makeSelectErrors = () =>
  createSelector(selector, (state) => state.get('errors', List()));

export const makeSelectMonthlyIncome = () =>
  createSelector(fiatFundsSelector(), (state) => state.get(Field.MONTHLY_INCOME, ''));

export const makeSelectMonthlyIncomeMeta = () =>
  createSelector(fiatFundsSelector(SubStore.META), (state) =>
    state.get(Field.MONTHLY_INCOME, Map())
  );

export const makeSelectSources = () =>
  createSelector(fiatFundsSelector(), (state: Map<string, any>) => {
    return state.get('detailedSources', List());
  });

export const makeSelectSourceImagesByType = () =>
  createSelector(makeSelectSources(), routerSelector, (detailedSources, router) => {
    const { source = '' } = url.parse(router.getIn(['location', 'search']));
    return detailedSources
      .filter((_source: any) => _source.get('source') === source)
      .first(List())
      .getIn(['images'], List());
  });

export const makeSelectAllAvailableSources = () =>
  createSelector(
    [makeSelectSourceOfFiatFounds(), makeSelectEmploymentStatus()],
    (allSources: List<any>, employment: string) => {
      return allSources.filter((source: Map<string, any>) => {
        return source.get('employmentStatuses', List()).includes(employment);
      });
    }
  );

export const makeSelectAvailableSourceByType = () =>
  createSelector(makeSelectSourceOfFiatFounds(), routerSelector, (sources, router) => {
    const { source = '' } = url.parse(router.getIn(['location', 'search']));
    const defaultValue = Map(fromJS({ id: source }));

    return sources.find(
      (avaliableSource: any) => {
        return avaliableSource.get('id') === source;
      },
      null,
      defaultValue
    );
  });

export const makeSelectCryptoFundsDeposit = () =>
  createSelector(cryptoFundsSelector(), (state) => state.get(Field.CRYPTO_FUNDS_DEPOSIT, false));

export const makeSelectCryptoSources = () =>
  createSelector(cryptoFundsSelector(), (state) => state.get(Field.DETAILED_SOURCES, List()));

// Withdrawal
export const makeSelectCryptoWithdrawal = () =>
  createSelector(withdrawalSelector, (state) => state.get('cryptoFundsWithdraw', false));

export const makeSelectCryptoWithdrawalDestinations = () =>
  createSelector(withdrawalSelector, (state) => state.get('detailedDestinations', List()));

export const makeSelectTaxResidence = () =>
  createSelector(employmentSelector(), makeSelectCountryAddress(), (state, country) =>
    state.get(Field.TAX_RESIDENCE, country)
  );

export const makeSelectTaxResidenceMeta = () =>
  createSelector(employmentSelector(SubStore.META), (state) =>
    state.get(Field.TAX_RESIDENCE, Map())
  );

export const makeSelectEmploymentStatus = () =>
  createSelector(employmentSelector(), (state) => state.get(Field.STATUS, ''));

export const makeSelectEmploymentStatusMeta = () =>
  createSelector(employmentSelector(SubStore.META), (state) => state.get(Field.STATUS, Map()));

export const makeSelectEmploymentPosition = () =>
  createSelector(employmentSelector(), (state) => state.get(Field.POSITION, ''));

export const makeSelectEmploymentPositionMeta = () =>
  createSelector(employmentSelector(SubStore.META), (state) => state.get(Field.POSITION, Map()));

export const makeSelectEmploymentPeriod = () =>
  createSelector(employmentSelector(), (state) => state.get(Field.EMPLOYMENT_PERIOD, ''));

export const makeSelectEmploymentPeriodMeta = () =>
  createSelector(employmentSelector(SubStore.META), (state) =>
    state.get(Field.EMPLOYMENT_PERIOD, Map())
  );

export const makeSelectCompanyCountry = () =>
  createSelector(employmentDetailsSelector(), makeSelectCountryAddress(), (state, country) => {
    return state.get(Field.COUNTRY, country);
  });

export const makeSelectCompanyName = () =>
  createSelector(employmentDetailsSelector(), (state) => state.get(Field.NAME, ''));

export const makeSelectCompanyNameMeta = () =>
  createSelector(employmentDetailsSelector(SubStore.META), (state) => state.get(Field.NAME, Map()));

export const makeSelectCompanyCountryMeta = () =>
  createSelector(employmentDetailsSelector(SubStore.META), (state) =>
    state.get(Field.COUNTRY, Map())
  );

export const makeSelectCompanyIndustry = () =>
  createSelector(employmentDetailsSelector(), (state) => state.get(Field.INDUSTRY, ''));

export const makeSelectCompanyIndustryMeta = () =>
  createSelector(employmentDetailsSelector(SubStore.META), (state) =>
    state.get(Field.INDUSTRY, Map())
  );

export const makeSelectCompanyIndustryDetailed = () =>
  createSelector(employmentDetailsSelector(), (state) => state.get(Field.INDUSTRY_DETAILED, ''));

export const makeSelectCompanyIndustryDetailedMeta = () =>
  createSelector(employmentDetailsSelector(SubStore.META), (state) =>
    state.get(Field.INDUSTRY_DETAILED, Map())
  );

export const makeSelectCompany = () =>
  createSelector(
    makeSelectCompanyName(),
    makeSelectCompanyCountry(),
    makeSelectCompanyIndustry(),
    makeSelectCompanyIndustryDetailed(),
    (name, country, industry, industryDetailed) => {
      return filter({ name, country, industry, industryDetailed }, isEmpty);
    }
  );

export const makeSelectTIN = () =>
  createSelector(employmentSelector(), (state) => state.get('TIN', ''));

export const makeSelectTINMeta = () =>
  createSelector(employmentSelector(SubStore.META), (state) => state.get(Field.TIN, Map()));

export const makeSelectEmploymentPositionDetails = () =>
  createSelector(employmentSelector(), (state) => state.get(Field.POSITION_DETAILED, ''));

export const makeSelectEmploymentPositionDetailsMeta = () =>
  createSelector(employmentSelector(SubStore.META), (state) =>
    state.get(Field.POSITION_DETAILED, Map())
  );

export const makeSelectSelfEmploymentType = () =>
  createSelector(employmentSelector(), (state) => state.get(Field.SELF_EMPLOYMENT_TYPE, ''));

export const makeSelectSelfEmploymentTypeMeta = () =>
  createSelector(employmentSelector(SubStore.META), (state) =>
    state.get(Field.SELF_EMPLOYMENT_TYPE, Map())
  );

export const makeSelectSelfEmploymentTypeDetailed = () =>
  createSelector(employmentSelector(), (state) => state.get('selfEmploymentTypeDetailed', ''));

export const makeSelectSelfEmploymentTypeDetailedMeta = () =>
  createSelector(employmentSelector(SubStore.META), (state) =>
    state.get(Field.SELF_EMPLOYMENT_TYPE_DETAILED, Map())
  );

export const makeSelectSelfEmploymentCompanyName = () =>
  createSelector(employmentSelector(), (state) =>
    state.get(Field.SELF_EMPLOYMENT_COMPANY_NAME, '')
  );

export const makeSelectSelfEmploymentCompanyNameMeta = () =>
  createSelector(employmentSelector(SubStore.META), (state) =>
    state.get(Field.SELF_EMPLOYMENT_COMPANY_NAME, Map())
  );

export const makeSelectSelfEmploymentIndustry = () =>
  createSelector(employmentSelector(), (state) => state.get(Field.SELF_EMPLOYMENT_INDUSTRY, ''));

export const makeSelectSelfEmploymentIndustryMeta = () =>
  createSelector(employmentSelector(SubStore.META), (state) =>
    state.get(Field.SELF_EMPLOYMENT_INDUSTRY, Map())
  );

export const makeSelectSelfEmploymentIndustryDetailed = () =>
  createSelector(employmentSelector(), (state) =>
    state.get(Field.SELF_EMPLOYMENT_INDUSTRY_DETAILED, '')
  );

export const makeSelectSelfEmploymentIndustryDetailedMeta = () =>
  createSelector(employmentSelector(SubStore.META), (state) =>
    state.get(Field.SELF_EMPLOYMENT_INDUSTRY_DETAILED, Map())
  );

export const makeSelectIsPep = () =>
  createSelector(questionnaireSelector(), (state) => state.get(Field.IS_PEP));

export const makeSelectIsPepMeta = () =>
  createSelector(questionnaireSelector(SubStore.META), (state) => state.get(Field.IS_PEP, Map()));

export const makeSelectPepPosition = () =>
  createSelector(questionnaireSelector(), (state) => {
    const position = state.get(Field.PEP_POSITION);

    return isString(position) ? trim(position) : position;
  });

export const makeSelectPepPositionMeta = () =>
  createSelector(questionnaireSelector(SubStore.META), (state) => state.get(Field.PEP_POSITION));

export const makeSelectHasPepRelatives = () =>
  createSelector(questionnaireSelector(), (state) => state.get(Field.HAS_PEP_RELATIVES));

export const makeSelectHasPepRelativesMeta = () =>
  createSelector(questionnaireSelector(SubStore.META), (state) =>
    state.get(Field.HAS_PEP_RELATIVES, Map())
  );

export const makeSelectPepRelativesDetailed = () =>
  createSelector(questionnaireSelector(), (state) => {
    const relatives = state.get(Field.PEP_RELATIVES_DETAILED);

    return isString(relatives) ? trim(relatives) : relatives;
  });

export const makeSelectPepRelativesDetailedMeta = () =>
  createSelector(questionnaireSelector(SubStore.META), (state) =>
    state.get(Field.PEP_RELATIVES_DETAILED, Map())
  );

export const makeSelectHasPepCloseAssociate = () =>
  createSelector(questionnaireSelector(), (state) => state.get(Field.PEP_CLOSE_ASSOCIATE));

export const makeSelectHasPepCloseAssociateMeta = () =>
  createSelector(questionnaireSelector(SubStore.META), (state) =>
    state.get(Field.PEP_CLOSE_ASSOCIATE, Map())
  );

export const makeSelectPepCloseAssociateDetailed = () =>
  createSelector(questionnaireSelector(), (state) => {
    const associated = state.get(Field.PEP_CLOSE_ASSOCIATE_DETAILED);

    return isString(associated) ? trim(associated) : associated;
  });

export const makeSelectPepCloseAssociateDetailedMeta = () =>
  createSelector(questionnaireSelector(SubStore.META), (state) =>
    state.get(Field.PEP_CLOSE_ASSOCIATE_DETAILED, Map())
  );

export const makeSelectIsUsCitizen = () =>
  createSelector(questionnaireSelector(), (state) => state.get(Field.US_CITIZEN));

export const makeSelectIsUsCitizenMeta = () =>
  createSelector(questionnaireSelector(SubStore.META), (state) =>
    state.get(Field.US_CITIZEN, Map())
  );

export const makeSelectIsUsTaxpayer = () =>
  createSelector(questionnaireSelector(), (state) => state.get(Field.US_TAXPAYER));

export const makeSelectIsUsTaxpayerMeta = () =>
  createSelector(questionnaireSelector(SubStore.META), (state) =>
    state.get(Field.US_TAXPAYER, Map())
  );

export const makeSelectIsUsResidentAlien = () =>
  createSelector(questionnaireSelector(), (state) => state.get(Field.US_RESIDENT_ALIEN));

export const makeSelectIsUsResidentAlienMeta = () =>
  createSelector(questionnaireSelector(SubStore.META), (state) =>
    state.get(Field.US_RESIDENT_ALIEN, Map())
  );

export const makeSelectBankJurisdiction = () =>
  createSelector(
    bankingJurisdictionSelector(),
    makeSelectCountryIdentity(),
    (state: Map<string, any>, country: string) => state.get(Field.BANK_JURISDICTION, country)
  );

export const makeSelectBankName = () =>
  createSelector(bankingJurisdictionSelector(), (state: Map<string, any>) =>
    state.get(Field.BANK_NAME, '')
  );

export const makeSelectDifferentBankForWithdrawal = () =>
  createSelector(bankingJurisdictionSelector(), (state: Map<string, any>) =>
    state.get(Field.DIFFERENT_BANK_FOR_WITHDRAWAL, false)
  );

export const makeSelectWithdrawalBankJurisdiction = () =>
  createSelector(bankingJurisdictionSelector(), (state: Map<string, any>) =>
    state.get(Field.WITHDRAWAL_BANK_JURISDICTION, '')
  );

export const makeSelectWithdrawalBankName = () =>
  createSelector(bankingJurisdictionSelector(), (state: Map<string, any>) =>
    state.get(Field.WITHDRAWAL_BANK_NAME, '')
  );

export const makeSelectBankJurisdictionMeta = () =>
  createSelector(bankingJurisdictionSelector(SubStore.META), (state: Map<string, any>) =>
    state.get(Field.BANK_JURISDICTION, Map())
  );

export const makeSelectPrimaryPurpose = () =>
  createSelector(bankingJurisdictionSelector(), (state: Map<string, any>) =>
    state.get('primaryPurpose', '')
  );

export const makeSelectPrimaryPurposeMeta = () =>
  createSelector(bankingJurisdictionSelector(SubStore.META), (state: Map<string, any>) =>
    state.get(Field.PRIMARY_PURPOSE, Map())
  );

export const makeSelectPrimaryPurposeDetailed = () =>
  createSelector(bankingJurisdictionSelector(), (state: Map<string, any>) =>
    state.get('primaryPurposeDetailed', '')
  );

export const makeSelectPrimaryPurposeDetailedMeta = () =>
  createSelector(bankingJurisdictionSelector(SubStore.META), (state: Map<string, any>) =>
    state.get(Field.PEP_RELATIVES_DETAILED, Map())
  );

export const makeSelectPlannedTurnover = () =>
  createSelector(bankingJurisdictionSelector(), (state: Map<string, any>) =>
    state.get('plannedTurnover', '')
  );

export const makeSelectPlannedTurnoverMeta = () =>
  createSelector(bankingJurisdictionSelector(SubStore.META), (state: Map<string, any>) =>
    state.get(Field.PLANNED_TURNOVER, Map())
  );

// CAUTION: uses only for stage submit
export const makeSelectQuestionnaire = createSelector(
  questionnaireSelector(),
  bankingJurisdictionSelector(),
  (financial: Map<string, any>, banking: Map<string, any>) => {
    return financial.mergeDeep(banking);
  }
);

export const makeSelectDetailedSources = createSelector(
  makeSelectSources(),
  (sources: List<Map<string, any>>) => {
    return sources.map((source: Map<string, any>) => {
      const images = source
        .get('images', List())
        .map((img: Map<string, any>) => img.get('fileToken'));

      return source.set('images', images);
    });
  }
);

export const makeSelectStepMeta = (fields: any) =>
  createSelector(
    selectedStepSelector(SubStore.META),
    makeSelectStageCorrectionMistakes('enhanced'),
    (stepMeta, correctionMistakes) => {
      return fromJS(fields).reduce(updateSourceMeta({ correctionMistakes }), stepMeta);
    }
  );

const purposeQuestionnaireSelector = (subStore = SubStore.DATA) =>
  createSelector(selector, (state) => state.getIn([subStore, 'purposeQuestionnaire'], Map()));

export const makeSelectPurposeQuestionnaireReason = () =>
  createSelector(purposeQuestionnaireSelector(), (state) =>
    state.get(Field.PURPOSE_QUESTIONNAIRE_REASON)
  );

export const makeSelectOtherDetailed = () =>
  createSelector(purposeQuestionnaireSelector(), (state) => state.get(Field.OTHER_DETAILED));
