import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

export const recognizerValuesMiddleware = (
  value: any,
  key: string,
  { settings }: any
) => {
  const cb = get(settings, key);

  if (isFunction(cb)) {
    return cb(value);
  }

  return value;
};

export const countriesAdapter = (code: string) => {
  const schema = {
    UAE: 'AE',
    ESP: 'ES',
    GBR: 'GB',
    IRL: 'IE',
    AUT: 'AT',
    COL: 'CO',
    DEU: 'DE',
    ITA: 'IT',
    NZL: 'NZ',
    CYP: 'CY',
    CRO: 'HR',
    KWT: 'KW',
    INDONESIA: 'ID',
    SGP: 'SG',
    HKG: 'HK',
    BRN: 'BN',
    MEX: 'MX',
  };

  return get(schema, code, code);
};
