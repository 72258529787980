import {
  IABTestingSet,
  IGeoLocationSet,
  ILocaleSet,
  ILocalesSet,
  ISettingsFailed,
  ISettingsGet,
  ISettingsSet,
  ITabIndexGet,
  ITabIndexSet,
  Type,
} from './actionTypes';
import { ISettings } from './types';

export const getSettings = (): ISettingsGet => ({
  type: Type.SETTINGS_GET,
  payload: null,
});

export const setSettings = (attrs: ISettings): ISettingsSet => ({
  type: Type.SETTINGS_SET,
  payload: attrs,
});

export const failedSettings = (error: Error[]): ISettingsFailed => ({
  type: Type.SETTINGS_FAILED,
  payload: error,
});

export const setLocale = (locale: string): ILocaleSet => ({
  type: Type.LOCALE_SET,
  payload: locale,
});

export const setLocales = (payload: {}): ILocalesSet => ({
  type: Type.LOCALES_SET,
  payload,
});

export const setABTesting = (payload: {}): IABTestingSet => ({
  type: Type.AB_TESTING_SET,
  payload,
});

export const setGeoLocation = (
  payload: IGeoLocationSet['payload']
): IGeoLocationSet => ({
  type: Type.GEO_LOCATION_SET,
  payload,
});

export const getTabIndex = (payload: ITabIndexGet['payload']) => {
  return {
    type: Type.GET_TAB_INDEX,
    payload,
  };
};

export const setTabIndex = (payload: ITabIndexSet['payload']) => {
  return {
    type: Type.SET_TAB_INDEX,
    payload,
  };
};
