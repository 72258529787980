import { call, put, select, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { push } from 'connected-react-router';

import { jsonRPC } from 'utils/request';
import { url } from 'utils/url';

import { Step, Stub } from 'system/Routing/types';
import {
  routingCorporateStepProgress,
  routingToProcessStepProgress,
} from 'system/Routing/actions';
import {
  makeSelectRouter,
  makeSelectRouterParams,
  makeSelectRouterSearch,
} from 'system/Bootstrap/selectors';
import { navigateToErrorLayout } from 'system/Routing/sagas';
import { Routes } from 'system/Routing/Routes';

import { makeSelectApiUrl } from 'system/Settings/selectors';
import { makeSelectQrReadyState } from '../selectors';
import { qrSetReadyState, qrSetUploadId } from '../actions';

import { requestWltfsToken } from './index';
import { Stage } from '../../../Stages/types';

function* createSocketChannel(clientUploadId: any) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const api = yield select(makeSelectApiUrl());
  const wssEndPoint = `${api.replace('https', 'wss')}/mobile/init`;

  const socket = new WebSocket(wssEndPoint, [
    window.btoa(clientUploadId).replace(/=/g, ''),
  ]);

  yield put(qrSetReadyState(socket.readyState));

  return eventChannel((emit: any) => {
    const messageHandler = ({ data = '{}' }: any) => {
      const { status, uploadId, fileTokens } = JSON.parse(data);

      switch (status) {
        case 'init':
          emit({ uploadId });
          break;
        case 'connect':
          emit({ readyState: socket.readyState });
          break;
        case 'complete':
          socket.close();
          emit({ fileTokens, readyState: socket.readyState });
          break;
        default:
          break;
      }
    };

    socket.addEventListener('message', messageHandler);

    const unsubscribe = () => {
      socket.removeEventListener('message', messageHandler);
    };

    return unsubscribe;
  });
}

export function* qrCodeInitConnect({
  payload: { onComplete, stageId, stepId, blockId },
}: any) {
  try {
    const prevReadyState = yield select(makeSelectQrReadyState());

    if (prevReadyState <= 1) {
      return;
    }
    const api = yield select(makeSelectApiUrl());

    const result = yield call(jsonRPC, {
      namespace: 'va',
      method: 'getUploadId',
      api,
    });

    const socketChannel = yield call(createSocketChannel, result.uploadId);

    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        const { readyState, uploadId, fileTokens } = yield take(socketChannel);

        if (uploadId) {
          yield put(qrSetUploadId(uploadId));
        }

        if (readyState === 1) {
          const router = yield select(makeSelectRouter());
          const isExact = router
            .getIn(['location', 'pathname'])
            .includes('qr-connected');

          if (!isExact) {
            yield put(
              push(
                Routes.url({
                  stageId,
                  blockId,
                  stepId: stepId || Step.UPLOAD,
                  stubId: Stub.QR_CONNECTED,
                })
              )
            );
            if (stageId === Stage.CORPORATE) {
              yield put(routingCorporateStepProgress());
            } else {
              yield put(routingToProcessStepProgress());
            }
          }
        }

        if (readyState > 1) {
          yield call(onComplete, {
            result: fileTokens,
            source: fileTokens[0]?.source,
          });

          yield put(qrSetUploadId(''));
          yield put(qrSetReadyState(3));
        }
      } catch (error) {
        console.log(error);
        socketChannel.close();
      }
    }
  } catch (err) {
    console.log(err);
    yield call(navigateToErrorLayout);
  }
}

export function* qrCodeIsConnected() {
  const routePath = yield select(makeSelectRouterParams());
  const search = yield select(makeSelectRouterSearch());
  const { uploadId } = url.parse(search);

  return Boolean(uploadId) || routePath?.params.stubId === Stub.QR_SUBMITTED;
}

export function* qrCodeConnectedSettings() {
  yield call(requestWltfsToken);

  const router = yield select(makeSelectRouter());

  const { uploadId, ...settings } = url.parse(
    router.getIn(['location', 'search'])
  );

  return {
    uploadId,
    ...settings,
  };
}

export function* qrCodeCompleteFileUpload(params: {
  uploadId: string;
  fileTokens: any[];
}) {
  const {
    params: { stageId, stepId, blockId },
  } = yield select(makeSelectRouterParams());
  const api = yield select(makeSelectApiUrl());

  yield call(jsonRPC, {
    namespace: 'mobile',
    method: 'completeFileUpload',
    params,
    api,
  });
  yield put(
    push(
      Routes.url({
        stageId,
        blockId,
        stepId: stepId || Step.UPLOAD,
        stubId: Stub.QR_SUBMITTED,
      })
    )
  );
}
