import { call, put, select } from 'redux-saga/effects';
import { makeSelectApiUrl } from 'system/Settings/selectors';
import { logLocalDev } from 'utils/logger';
import { jsonRPC } from 'utils/request';

import { livenessCheckTokenError, livenessCheckTokenSuccess } from '../actions';

export function* watchForTokenRequest() {
  const api = yield select(makeSelectApiUrl());

  try {
    const payload = yield call(jsonRPC, {
      namespace: 'livenessToken',
      method: 'getLivenessToken',
      api,
    });

    yield put(livenessCheckTokenSuccess(payload));
  } catch (err) {
    logLocalDev('LC request error', err);

    yield put(livenessCheckTokenError(err));
  }
}
