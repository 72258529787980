import { useEffect, useRef, ReactNode, FC, useContext } from 'react';
import { createPortal } from 'react-dom';
import { ConfigContext } from 'merchants';
import { IGeneralConfig } from '../../merchants/types/IConfig';

interface IPortalProps {
  children: ReactNode;
  id?: string;
}

export const Portal: FC<IPortalProps> = ({
  id = 'modal-root-websdk',
  children,
}) => {
  const { containerId = '' }: IGeneralConfig = useContext(ConfigContext);

  const node = document.getElementById(id) || document.createElement('div');
  const root = document.getElementById(containerId);

  const el = useRef(node);
  const {
    current,
    current: { parentElement },
  } = el;
  const dynamic = !parentElement;

  useEffect(() => {
    if (dynamic && root) {
      current.id = id;
      root.appendChild(current);
    }
    return () => {
      if (dynamic && parentElement) {
        parentElement.removeChild(current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return createPortal(children, current);
};


export const DynamicPortal: FC<IPortalProps> = ({ children }) => {
  const root =  useRef(document.createElement('div'));
  const node = useRef(document.createElement('div'));

  useEffect(() => {
    root.current.setAttribute('class', 'websdk-modal');
    node.current.setAttribute('class', 'websdk-portal');
    root.current.appendChild(node.current);
    
    document.body.appendChild(root.current);
    
    return () => {
      document.body.removeChild(root.current);
    }
  }, []);

  return createPortal(children, node.current);
};
