import React from 'react';
import styled from 'styled-components';

import { Markup as Icon } from 'components/UI';

export const RemoveButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button onClick={onClick} data-aqaid="remove-btn" type="button">
      <Icon.Close fill="#e65069" />
    </Button>
  );
};

const Button = styled.button`
  outline: none;
  height: 100%;
  width: 16px;
  border: none;
  background: none;
  cursor: pointer;

  > svg {
    width: 100%;
  }
`;
