import React from 'react';

import { IconProps } from 'components/UI/Icon/types';

export const Facebook: React.FunctionComponent<IconProps> = ({
  fill,
  height,
  width,
}) => (
  <svg
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="16" cy="16" fill={fill} r="16" />
      <path
        d="M17.333 13.333v-1.587c0-.717.159-1.08 1.271-1.08H20V8h-2.133c-2.667 0-3.734 1.78-3.734 3.733v1.6H12V16h2.133v8h3.2v-8h2.35L20 13.333h-2.667"
        fill="#FFF"
      />
    </g>
  </svg>
);

Facebook.displayName = 'FacebookIcon';

Facebook.defaultProps = { fill: '#263850', height: 32, width: 32 };
