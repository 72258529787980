import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const ElectricityBill: React.FunctionComponent<IconProps> = ({ width = 104, height = 126 }) => {
  const {
    icons: {
      docs: {
        electricityBill: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg height={height} viewBox={`0 0 ${width} ${height}`} width={width} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect height="124" rx="4" stroke={fill} strokeWidth="2" width="102" x="1" y="1" />
        <path d="M16 86h72v-2H16zM16 92h72v-2H16zM16 66h72v-2H16zM16 72h72v-2H16zM16 98h72v-2H16zM16 104h72v-2H16zM16 110h34v-2H16z" fill={fill} />
        <path d="M61.147 26.975a.568.568 0 0 0-.511-.308h-6.692l6.604-9.848a.508.508 0 0 0 .017-.543.57.57 0 0 0-.494-.276h-9.035a.57.57 0 0 0-.506.295l-8.47 16a.507.507 0 0 0 .024.518.574.574 0 0 0 .48.254h5.808L42.044 47.26a.52.52 0 0 0 .226.662.587.587 0 0 0 .727-.111l18.07-20.267a.51.51 0 0 0 .08-.569z" fill={fill} fillRule="nonzero" />
      </g>
    </svg>
  );
}

ElectricityBill.displayName = 'ElectricityBillIcon';
