import moment from 'moment';

export enum Format {
  DATE = 'YYYY-MM-DD',
  TIME = 'HH:mm',
  DATETIME = 'YYYY-MM-DD HH:mm',
  FILE = 'DD MMM, YYYY - HH:mm',
  TIMESTAMP = 'X',
}

export const format = (date: Date | number | string, formatStr: Format = Format.DATE): string => {
  return moment(date).format(formatStr);
};

export const parse = (date: string): Date => {
  return moment(date).toDate();
};

export const add = (obj: object): Date => {
  return moment()
    .add(obj)
    .toDate();
};

export const ifValid = (date: string) => {
  return moment(date).isValid();
};

export const dateStrToObj = (date: string) => {
  if (!date) return {};
  const [year = '', month = '', day = ''] = date.split('-');
  if (ifValid(date)) {
    return {
      year,
      month,
      day,
      all: date,
    };
  }

  return {};
};

export const dateToAge = (date: string) => {
  return moment().diff(moment(date), 'years');
};

export const dateIsPast = (date: Date) => {
  const todayDate = moment().startOf('day');
  const compareDate = moment(date);
  return todayDate.isAfter(compareDate);
};

export const getDiffTimeFromNow = (date: number) => {
  return moment(date).diff(moment());
};

export const getTimeDuration = (diffTime: number): moment.Duration => {
  return moment.duration(diffTime, 'milliseconds');
};

export const doubleDigit = (value: number) => {
  return value < 10 ? `0${value}` : `${value}`;
};

export const getSplitDate = (duration: any) => {
  const month = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return {
    month: month ? doubleDigit(month) : '',
    days: days ? doubleDigit(days) : '',
    hours: hours ? doubleDigit(hours) : '',
    minutes: minutes ? doubleDigit(minutes) : '',
    seconds: seconds ? doubleDigit(seconds) : '',
  };
};

export const dateUtils = {
  add,
  format,
  parse,
  dateStrToObj,
  dateToAge,
  getDateDiffFromNow: getDiffTimeFromNow,
  getSplitDate,
  getTimeDuration,
  dateIsPast,
};
