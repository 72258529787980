import { fromJS, List, Map, isImmutable } from 'immutable';

import {
  Type as UIType,
  ActionType as UIActionType,
} from 'system/UI/actionTypes';
import { Stage } from 'system/Stages/types';
import { PhoneErrorsType } from 'utils/error';
import { Type, ActionType } from './actionTypes';
import { isArray } from '../../utils/collection';

export const defaultState = fromJS({
  loading: false,
  errors: [],
  data: {},
  phoneValidation: {
    timestamp: 0,
    error: {},
    valid: false,
    skip: false,
    phone: {
      number: '',
      code: '',
      country: '',
    },
    code: '',
    isValidated: false,
  },
});

export const reducer = (
  state = defaultState,
  { type, payload }: ActionType & UIActionType
) => {
  switch (type) {
    case Type.CORPORATE_STEP_SET: {
      const { stepId, blockId, fields = {} } = payload;
      const path = ['data', blockId, stepId];
      if (isArray(fields) || isImmutable(fields)) {
        return state.setIn(path, fromJS(fields));
      }
      return state.mergeIn(path, fromJS(fields));
    }
    case Type.CORPORATE_META_SET: {
      const { stepId, blockId, fields = {} } = payload;
      return state.setIn(['meta', blockId, stepId], fromJS(fields));
    }
    case Type.CORPORATE_BLOCK_RESET: {
      const { stepId, blockId, cachePath } = payload;
      let path = ['data', blockId];
      const data = fromJS({});
      const cache = state.getIn(['data', blockId, cachePath], Map());
      const newData = !cache.isEmpty() && !stepId ? data.set(cachePath, cache) : data;

      if (stepId) {
        path = ['data', blockId, stepId];
      }

      return state.setIn(path, newData);
    }
    case Type.CORPORATE_CACHE_REQUEST:
    case Type.CORPORATE_UPLOAD_FILES_REQUEST:
    case Type.CORPORATE_BLOCK_SUBMIT_REQUEST:
    case Type.CORPORATE_SUBMIT_REQUEST:
    case Type.CORPORATE_ESIGNATURE_SUBMIT:
    case Type.CORPORATE_ESIGNATURE_RESEND:
    case Type.CORPORATE_ESIGNATURE_CANCEL:
       {
      return state.set('loading', true);
    }
    case Type.CORPORATE_CACHE_FAILED:
    case Type.CORPORATE_UPLOAD_FILES_SUCCESS:
    case Type.CORPORATE_SUBMIT_SUCCESS:
    case Type.CORPORATE_ESIGNATURE_SUCCESS: {
      return state.set('loading', false);
    }
    case Type.CORPORATE_UPLOAD_FILES_ERROR:
    case Type.CORPORATE_SUBMIT_FAILED:
    case Type.CORPORATE_ESIGNATURE_FAILED: {
      return state.set('loading', false).set('errors', fromJS(payload));
    }
    case Type.CORPORATE_CACHE_SUCCESS: {
      const { data = {}, blockId } = payload;
      const path = ['data', blockId];

      return state.set('loading', false).setIn(path, fromJS(data));
    }
    case Type.CORPORATE_CACHE_REMOVE: {
      return state.set('loading', true);
    }
    case UIType.UI_RESET_ERRORS:
      return state.set('errors', fromJS([]));
    case UIType.UI_RESET_STAGE_DATA: {
      const { stageId } = payload;
      return stageId === Stage.CORPORATE || !stageId ? defaultState : state;
    }
    case Type.CORPORATE_ADD_FILES: {
      const {
        source,
        files: images = [],
        stepId,
        blockId,
        replace = false,
      } = payload;

      const path = ['data', blockId, stepId, source];
      const data = fromJS(images);
      const prevData = state.getIn(path, List()) || List();
      const newData =
        !replace && prevData && !prevData.isEmpty()
          ? prevData.concat(data)
          : data;

      return state.setIn(path, newData);
    }

    case Type.CORPORATE_REMOVE_FILES: {
      const { source, stepId, blockId, id } = payload;

      const path = ['data', blockId, stepId, source];
      const data = state
        .getIn(path, List())
        .filter((_: Map<string, any>, _index: number) => _index !== id);
      return state.setIn(path, data);
    }

    case Type.CORPORATE_PHONE_CODE_REQUEST: {
      const { phone } = payload;
      const prevPhone = state.getIn(['phoneValidation', 'phone'], fromJS({}));
      const prevValid = state.getIn(['phoneValidation', 'valid'], false);

      return state
        .set('loading', true)
        .setIn(
          ['phoneValidation', 'valid'],
          prevPhone.equals(fromJS(phone)) ? prevValid : false
        )
        .setIn(['phoneValidation', 'code'], '')
        .setIn(['phoneValidation', 'timestamp'], 0)
        .setIn(['phoneValidation', 'phone'], fromJS(phone));
    }
    case Type.CORPORATE_CODE_VALIDATION_REQUEST: {
      const { phone, code } = payload;
      return state
        .set('loading', true)
        .setIn(['phoneValidation', 'phone'], fromJS(phone))
        .setIn(['phoneValidation', 'code'], code);
    }

    case Type.CORPORATE_PHONE_WITHOUT_CODE_REQUEST: {
      const { phone } = payload;
      return state
        .setIn(['phoneValidation', 'phone'], fromJS(phone))
        .setIn(['phoneValidation', 'isValidated'], false)
    }

    case Type.CORPORATE_PHONE_CODE_FAILED: {
      const { data } = payload as any;
      return state
        .set('loading', false)
        .setIn(
          ['phoneValidation', 'timestamp'],
          data?.timestamp ? data?.timestamp : 0
        )
        .setIn(['phoneValidation', 'error'], fromJS(payload))
        .setIn(['phoneValidation', 'error', 'type'], PhoneErrorsType.PHONE);
    }
    case Type.CORPORATE_CODE_VALIDATION_FAILED:
      return state
        .set('loading', false)
        .setIn(['phoneValidation', 'error'], fromJS(payload))
        .setIn(['phoneValidation', 'error', 'type'], PhoneErrorsType.CODE);
    case Type.CORPORATE_PHONE_CODE_SUCCESS: {
      const { timestamp } = payload;
      return state
        .setIn(['phoneValidation', 'timestamp'], timestamp)
        .setIn(['phoneValidation', 'isValidated'], true)
        .set('loading', false);
    }
    case Type.CORPORATE_CODE_VALIDATION_SUCCESS: {
      const { valid, skip } = payload;
      return state
        .setIn(['phoneValidation', 'valid'], valid)
        .setIn(['phoneValidation', 'skip'], skip)
        .setIn(['phoneValidation', 'error'], fromJS({}))
        .set('loading', false);
    }
    case Type.CORPORATE_RESET_PHONE_ERRORS: {
      return state.setIn(['phoneValidation', 'error'], fromJS({}));
    }
    case Type.CORPORATE_RESET_PHONE_VALIDATION: {
      return state
        .setIn(['phoneValidation', 'timestamp'], 0)
        .setIn(['phoneValidation', 'valid'], false)
        .setIn(['phoneValidation', 'skip'], false);
    }
    case Type.CORPORATE_CLEAN_PHONE_VALIDATION: {
      return state
        .setIn(['phoneValidation', 'timestamp'], 0)
        .setIn(['phoneValidation', 'error'], fromJS({}))
        .setIn(['phoneValidation', 'valid'], false)
        .setIn(['phoneValidation', 'skip'], false)
        .setIn(['phoneValidation', 'phone'], fromJS({}))
        .setIn(['phoneValidation', 'phone', 'number'], '')
        .setIn(['phoneValidation', 'phone', 'code'], '')
        .setIn(['phoneValidation', 'phone', 'country'], '')
        .setIn(['phoneValidation', 'isValidated'], false)
        .setIn(['phoneValidation', 'code'], '')
    }
    case Type.CORPORATE_SET_PHONE_COUNTRY: {
      return state.setIn(['phoneValidation', 'phone', 'country'], payload);
    }
    default:
      return state;
  }
};
