import * as merchant from 'utils/merchant';
import { IFeatures, IConfig } from 'merchants/types/IConfig';
import { isLocalEnv } from 'utils/env';

import { images } from './images';
import { i18n } from './locales'
import { theme } from './theme';

const { backUrl, workingMode, product = '' } = merchant.getSettings();

export const config: IConfig = {
  appConfig: { api: '', cdn: '', env: '', zendeskId: '' },
  i18n,
  backUrl,
  workingMode,
  product,
  requestToken: merchant.getToken(),
  features: {
    breadcrumbsInHeader: true,
    breadcrumbs: false,
    header: true,
    fingerprint: true,
    exitBtn: true,
    // ...((isLocalEnv()) && { googleTagManagerId: 'GTM-PZXQFNR' }),
  },
  images,
  theme: {
    name: 'identity',
    config: theme
  }
};

export const defaultFeatures: IFeatures = {
  selfieWithIdentity: false,
  selfieWithNote: false,
  retryDossier: false,
  phoneFieldOnResidence: false,
  breadcrumbsInHeader: false,
  breadcrumbs: false,
  header: false,
  fingerprint: false,
  exitBtn: false
};
