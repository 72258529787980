import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Doc: React.FunctionComponent<IconProps>= ({ width = 104, height = 126 }) => {
  const {
    icons: {
      docs: {
        doc: { fill, fill2 },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg height={height} viewBox={`0 0 ${width} ${height}`} width={width} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect height="124" rx="4" stroke={fill} strokeWidth="2" width="102" x="1" y="1" />
        <path d="M16 38h72v-2H16zM16 44h72v-2H16zM16 50h35.234v-2H16zM16 62h72v-2H16zM16 68h72v-2H16z" fill={fill} />
        <g fill={fill}>
          <path d="M16 86h72v-2H16zM16 92h72v-2H16z" />
        </g>
        <path d="M16 98h35.234v-2H16zM16 110h72v-2H16z" fill={fill} />
        <path d="M24.884 21.42c0 1.763-.501 3.113-1.505 4.05-1.003.938-2.452 1.406-4.347 1.406H16V16.168h3.362c1.748 0 3.105.462 4.072 1.385.967.923 1.45 2.212 1.45 3.867zm-2.358.059c0-2.3-1.016-3.45-3.047-3.45h-1.208V25h.974c2.187 0 3.28-1.174 3.28-3.522zm14.297.029c0 1.772-.44 3.135-1.319 4.087s-2.138 1.428-3.779 1.428c-1.64 0-2.9-.476-3.78-1.428-.878-.952-1.318-2.32-1.318-4.102 0-1.782.441-3.143 1.322-4.083.882-.94 2.145-1.41 3.79-1.41 1.646 0 2.905.474 3.776 1.42.872.948 1.308 2.31 1.308 4.088zm-7.815 0c0 1.196.227 2.097.681 2.702.454.606 1.133.909 2.036.909 1.812 0 2.717-1.204 2.717-3.611 0-2.412-.9-3.618-2.702-3.618-.904 0-1.585.304-2.044.912-.459.607-.688 1.51-.688 2.706zm14.582-3.604c-.854 0-1.516.321-1.985.963-.468.643-.703 1.537-.703 2.685 0 2.387.896 3.581 2.688 3.581.752 0 1.663-.188 2.732-.564v1.905c-.879.366-1.86.549-2.944.549-1.558 0-2.75-.472-3.574-1.417-.825-.945-1.238-2.301-1.238-4.069 0-1.113.203-2.089.608-2.926.405-.837.987-1.48 1.747-1.926.759-.447 1.649-.67 2.67-.67 1.04 0 2.084.251 3.134.754l-.732 1.846c-.4-.19-.804-.357-1.209-.498a3.605 3.605 0 0 0-1.194-.213z" fill={fill2} />
      </g>
    </svg>
  );
};

Doc.displayName = 'DocIcon';
