import { createSelector } from 'reselect';
import { makeSelectUserData } from 'system/Profile/selectors';
import { makeSelectPhoneCode, makeSelectPhoneNumber, makeSelectPhoneCountry } from 'stages/Identity/selectors/selectors';

const filterEmptyValues = (obj: object) => {
  return Object.entries(obj).reduce((result: any, [key, value]: [string, any]) => {
    if (value !== '' && value !== null && value !== undefined) {
      return {
        ...result,
        [key]: value,
      };
    }
    return result;
  }, {});
};

const makeSelectPrefilledCode = () => {
  return createSelector(makeSelectUserData(), (data) => {
    return data.getIn(['phone', 'code']);
  });
};

const makeSelectPrefilledNumber = () => {
  return createSelector(makeSelectUserData(), (data) => {
    return data.getIn(['phone', 'number']);
  });
};

const makeSelectPrefilledCountry = () => {
  return createSelector(makeSelectUserData(), (data) => {
    return data.getIn(['phone', 'country']);
  });
};

const makeSelectCode = () => {
  return createSelector(makeSelectPhoneCode(), makeSelectPrefilledCode(), (code, prefilledCode) => {
    return code || prefilledCode;
  });
};

const makeSelectNumber = () => {
  return createSelector(
    makeSelectPhoneNumber(),
    makeSelectPrefilledNumber(),
    (number, prefilledNumber) => {
      return number || prefilledNumber;
    }
  );
};

const makeSelectCountry = () => {
  return createSelector(
    makeSelectPhoneCountry(),
    makeSelectPrefilledCountry(),
    (country, prefilledCountry) => {
      return country || prefilledCountry;
    }
  );
};

export const makeSelectPrefilledPhoneBlock = () => {
  return createSelector(
    makeSelectPrefilledCode(),
    makeSelectPrefilledNumber(),
    makeSelectPrefilledCountry(),
    (code, number, country) => {
      return filterEmptyValues({ code, number, country });
    }
  );
};

export const makeSelectPhoneBlock = () => {
  return createSelector(
    makeSelectCode(),
    makeSelectNumber(),
    makeSelectCountry(),
    (code, number, country) => {
      return filterEmptyValues({ code, number, country });
    }
  );
};
