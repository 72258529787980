import { IsoDate, Gender, CountryId, IMeta, IDate } from './shared/types';

export interface IPersonalInfo {
  gender: Gender;
  firstName: string;
  middleName: string;
  lastName: string;
  nationality: CountryId;
  dateOfBirth: IsoDate;
}

export interface PersonalValues {
  gender: Gender;
  firstName: string;
  middleName: string;
  lastName: string;
  nationality: CountryId;
  dateOfBirth: IDate;
}

export type PersonalInfoMeta = Iterable<
  [
    (
      | 'gender'
      | 'firstName'
      | 'middleName'
      | 'lastName'
      | 'nationality'
      | 'dateOfBirth'
    ),
    IMeta
  ]
>;

export enum UserRole {
  UBO = '0',
  OFFICER_SIGNATURE = '1',
  AUTHORIZED_PERSON = '2'
}
