import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

import { saveFeedback } from 'system/UI/actions';
import { FormikProps, withFormik } from 'formik';

import {
  makeSelectStagesWithoutFeedback,
  makeSelectTicketSystem
} from 'system/Profile/selectors';
import { makeSelectSubmitted, makeSelectSubmitting } from 'system/UI/selectors';

import { BaseField } from 'containers/Field';
import { ButtonPrimary, Markup, Textarea } from 'components/UI';
import { Box, P, Star, Stars, SubmittedContent } from 'components/common/Feedback/styles';

import { Loader } from 'components/common';
import { ILocalizationProps, withLocalization } from 'system/Localization';

import { useToggleWithTimeout } from 'Hooks/useToggle';
import { List } from 'immutable';
import { feedbackSchema } from './schema';

export interface IValues {
  score: number;
  feedback: string;
  stages: string[];
}

interface FeedbackProps extends ILocalizationProps {
  handleSubmit?: (
    values: IValues,
    props: {
      props: {
        handleSaveFeedback: (values: IValues) => void;
        stagesWithoutFeedback: List<string>;
      };
    }
  ) => void;
  stagesWithoutFeedback: List<string>;
  submitted: boolean;
  submitting: boolean;
  handleSaveFeedback: (values: IValues) => void;
  ticketSystem: boolean;
}

type Props = FormikProps<IValues> & FeedbackProps;

function BaseFeedback({
  handleSubmit,
  setFieldValue,
  stagesWithoutFeedback,
  values,
  submitted,
  submitting,
  translate,
  ticketSystem,
  errors
}: Props) {
  const maxRate = 5;
  const [isHideFeedback, handleStartToggleTimeout] = useToggleWithTimeout({
    defaultValue: stagesWithoutFeedback.isEmpty() || submitted,
  });

  useEffect(() => {
    if (!isHideFeedback && submitted) {
      handleStartToggleTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  if (isHideFeedback) {
    return null;
  }

  if (submitting) {
    return <Loader />;
  }

  if (submitted) {
    return (
      <Box>
        <SubmittedContent>
          <Markup.Check fill="#2094FA" />
          <span>{translate('rating_max_popup_message')}</span>
        </SubmittedContent>
      </Box>
    );
  }

  const handleSetScore = (index: number) => () => {
    return setFieldValue('score', index + 1);
  };

  const onSubmit = () => {
    handleSubmit();
  };

  return (
    <Box>
      <Stars>
        {[...new Array(maxRate)].map((_s, index) => {
          const mode = index < values.score ? 'active' : 'default';
          return (
            <Star
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={handleSetScore(index)}
              data-aqaid={`star-${index}-${mode}`}
            >
              <Markup.Star mode={mode} />
            </Star>
          );
        })}
      </Stars>
      <P>{translate('rating_message')}</P>
      {values.score ? (
        <>
          <BaseField
            as={Textarea}
            disabled={false}
            name="feedback"
            type="textarea"
            placeholder={translate('feedback_textarea_placeholder')}
            translate={translate}
          />
          <ButtonPrimary type="submit" onClick={onSubmit} disabled={!isEmpty(errors)} data-aqaid="send_feedback">
            {translate('button_submit_feedback')}
          </ButtonPrimary>
          {ticketSystem && <P>
            <Markup.Discuss />
            <strong>{translate('feedback_support', '')}</strong>
            {/* TODO: remove after ZNYCH-7082 dev passed
              <strong>
                {translate('feedback_support_text')}{' '}
                <Link to="https://identance.com">
                  {translate('feedback_support_link')}
                </Link>
              </strong> */}
          </P>}
        </>
      ) : null}
    </Box>
  );
}

const FeedbackContainer = withFormik<FeedbackProps, IValues>({
  mapPropsToValues: () => ({ feedback: '', score: 0, stages: [] }),
  validationSchema: feedbackSchema,
  handleSubmit: (values, { props: { handleSaveFeedback, stagesWithoutFeedback } }) => {
    return handleSaveFeedback({
      ...values,
      stages: stagesWithoutFeedback.toJS(),
    });
  },
})(BaseFeedback);

const mapStateToProps = createStructuredSelector({
  stagesWithoutFeedback: makeSelectStagesWithoutFeedback(),
  submitting: makeSelectSubmitting(),
  submitted: makeSelectSubmitted(),
  ticketSystem: makeSelectTicketSystem()
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    handleSaveFeedback: (values: IValues) => {
      return dispatch(saveFeedback(values));
    },
  };
};

export const Feedback = connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalization(FeedbackContainer));
