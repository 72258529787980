import { fromJS } from 'immutable';

import { Type, Action } from './actionTypes';

export enum Status {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED',
}

export const defaultState = fromJS({
  errors: [],
  status: Status.IDLE,
});

export function reducer(state = defaultState, action: Action) {
  switch (action.type) {
    case Type.APPLICATION_LOADING:
      return state.set('errors', fromJS([])).set('status', Status.LOADING);
    case Type.APPLICATION_LOADED:
      return state.set('errors', fromJS([])).set('status', Status.LOADED);
    case Type.APPLICATION_FAILED:
      return state
        .set('errors', fromJS(action.payload))
        .set('status', Status.FAILED);
    default:
      return state;
  }
}
