import { fromJS } from 'immutable';

import { Type } from './actionTypes';

export enum Status {
  IDLE = 'IDLE',
  SUBMITTING = 'SUBMITTING',
  SUBMITTED = 'SUBMITTED',
  FAILED = 'FAILED',
}

const defaultState = fromJS({
  errors: [],
  status: Status.IDLE,
  loading: false
});

export function reducer(
  state: any = defaultState,
  { type, payload }: any
 ) {
  switch (type) {
    case Type.UI_SUBMITTING: {
      return state.set('status', Status.SUBMITTING)
    }
    case Type.UI_SUBMITTED: {
      return state.set('status', Status.SUBMITTED)
    }
    case Type.UI_RETRY_STAGE_START: {
      return state.set('loading', true)
    }
    case Type.UI_RETRY_STAGE_FINISHED: {
      return state.set('loading', false)
    }
    case Type.UI_SUBMITTING_FAILED: {
      const errors = state.get('errors').push(fromJS(payload));
      return state.set('status', Status.FAILED).set('errors', fromJS(errors))
    }

    default:
      return state;
  }
}
