import { all, call, put, select } from 'redux-saga/effects';
import { Map } from 'immutable';

import merge from 'lodash/merge';
import set from 'lodash/set';

import { Stage, StageId } from 'system/Stages/types';

import { makeSelectRouterParams } from 'system/Bootstrap/selectors';
import {
  makeSelectMerchantFeatures,
  makeSelectStages,
  makeSelectStageById,
} from 'system/Profile/selectors';
import {
  makeSelectDossierByStage,
  makeSelectStageProgress,
} from 'system/Dossier/selectors';
import { makeSelectGroupedQuestionsIdsByBlock } from 'system/Dicts/selectors';

import { qrCodeIsConnected } from 'system/Dossier/Upload/sagas/qr';

import { requestSubmitIdentity } from 'stages/Identity/actions';
import { requestSubmitAddress } from 'stages/Address/actions';
import { requestSubmitEnhanced } from 'stages/Enhanced/actions';
import { requestSubmitQuestionnaire } from 'stages/Questionnaire/actions';
import { getStagesRouteSchema } from 'system/Routing/routing';

import {
  buildGraph,
  getStageRouteProgress,
} from 'containers/Stage/utils/helpers';

import { RouteId, RouteStage } from '../types';

import {
  routingSetStageProgress,
  setSchema,
  setStageRouteIds,
  setStageRouteIdsGrouped,
  setStagesEntryUrls,
} from '../actions';

import {
  makeSelectSchema,
  makeSelectStagesRouteIds,
  makeSelectSchemaFlattenedFilteredByStatus,
} from '../selectors';

import { getStagesEntryUrls } from '../utils';

import { navigateToEntryUrlByStageStatus } from './navigate';
import { IRoutingSetStepProgress } from '../actionTypes';

function* getStageRouteIds(stageId: StageId) {
  const stage = yield select(makeSelectStageById(stageId));
  const dossierByStage = yield select(makeSelectDossierByStage(stageId));
  let schemaFlattenedFilteredByStatus = yield select(
    makeSelectSchemaFlattenedFilteredByStatus(stage.get('status'), stageId)
  );

  if (stageId === Stage.CFD) {
    const cfdQuestionsDict = yield select(
      makeSelectGroupedQuestionsIdsByBlock()
    );
    const cfdQuestions = cfdQuestionsDict
      .valueSeq()
      .flatten()
      .map((q: any) => Map({ id: q }));

    schemaFlattenedFilteredByStatus = schemaFlattenedFilteredByStatus
      .toList()
      .merge(cfdQuestions);
  }

  return buildGraph(
    schemaFlattenedFilteredByStatus.toJS(),
    dossierByStage.toJS()
  );
}

function* getStageRoutesIdsGroupBy(stageId: StageId) {
  const graph = yield call(getStageRouteIds, stageId);
  return {
    id: stageId,
    routeIds: graph.overallOrder().reverse() as RouteId[],
  };
}

function* mapSchemaToStageRoutesIds() {
  const schema = yield select(makeSelectSchema());
  const cfdQuestions = yield select(makeSelectGroupedQuestionsIdsByBlock());

  const stageRoutesIdsGrouped = yield all(
    schema
      .keySeq()
      .toJS()
      .map((stageId: StageId) => {
        return call(getStageRoutesIdsGroupBy, stageId);
      })
  );

  let payload = stageRoutesIdsGrouped.reduce(
    (
      stageRoutesIds: RouteStage,
      { id, routeIds }: { id: StageId; routeIds: RouteId[] }
    ) => {
      return { ...stageRoutesIds, [id]: routeIds };
    },
    {}
  );

  if (cfdQuestions.size) {
    payload = set(
      payload,
      'cfd',
      cfdQuestions
        .valueSeq()
        .flatten()
        .toJS()
    );
  }

  yield put(setStageRouteIdsGrouped(payload));
}

function* mapRouteIdsToStagesEntryUrls() {
  const schema = yield select(makeSelectStagesRouteIds());
  const stages = yield select(makeSelectStages());
  const features = yield select(makeSelectMerchantFeatures());

  const entryUrls = getStagesEntryUrls({ schema, stages, features });
  yield put(setStagesEntryUrls(entryUrls));
}

export function* handleStepProgress(action: IRoutingSetStepProgress) {
  const {
    payload: { nextStepId, omitPrevSteps, omitNextSteps } = {},
  } = action;

  const routerParams = yield select(makeSelectRouterParams());
  if (!routerParams) {
    return;
  }

  const {
    params: { stageId, stepId },
  } = routerParams;

  const graph = yield call(getStageRouteIds, stageId);

  yield put(
    setStageRouteIds({
      stageId: stageId as Stage,
      routeIds: graph.overallOrder().reverse(),
    })
  );

  const stageRouteProgress = getStageRouteProgress({
    graph,
    stepId,
    omitPrevSteps,
    omitNextSteps,
  });

  // mutation of stageRouteProgress for path with query (f.e. /upload?source=)
  yield put(routingSetStageProgress(merge(stageRouteProgress, { nextStepId })));
}

export function* handleStageSubmit(stageId: Stage) {
  switch (stageId) {
    case Stage.IDENTITY:
      yield put(requestSubmitIdentity());
      break;
    case Stage.ADDRESS:
      yield put(requestSubmitAddress());
      break;
    case Stage.ENHANCED:
      yield put(requestSubmitEnhanced());
      break;
    case Stage.CFD:
      yield put(requestSubmitQuestionnaire({}));
      break;

    default:
      console.warn(`Passed wrong stageId: ${stageId}`);
      break;
  }
}

export function* routingInitialize() {
  yield call(mapSchemaToStageRoutesIds);
  yield call(mapRouteIdsToStagesEntryUrls);
}

export function* routingBootstrap({ stages }: { stages?: Stage[] }) {
  const isQrCodeConnected = yield qrCodeIsConnected();

  if (isQrCodeConnected) {
    return;
  }
  yield put(setSchema(getStagesRouteSchema(stages)));

  yield call(routingInitialize);

  const stageProgress = yield select(makeSelectStageProgress());
  if (
    !stageProgress.get('steps').size &&
    process.env.REACT_APP_ENV !== 'mock'
  ) {
    yield call(navigateToEntryUrlByStageStatus);
  }
}
