import { takeEvery } from 'redux-saga/effects';
import { Type } from 'stages/Identity/blocks/IdentityDocuments/LivenessCheck/actionTypes';

import { watchForTokenRequest } from './token';
import { watchForSocketConnect } from './socket';

export function* rootSaga() {
  yield takeEvery([
    Type.LIVENESS_CHECK_TOKEN_REQUEST,
    Type.LIVENESS_CHECK_MOBILE_TOKEN_REQUEST
  ], watchForTokenRequest);

  yield takeEvery(Type.LIVENESS_CHECK_TOKEN_SUCCESS, watchForSocketConnect);
}
