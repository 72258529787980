import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { settingsSelector } from 'system/Settings/selectors';

export const makeSelectLocales = () =>
  createSelector(
    settingsSelector,
    (state: any) => state.getIn(['i18n', 'locales'], Map())
  );

export const makeSelectLocale = () =>
  createSelector(
    settingsSelector,
    (state: any) => state.getIn(['i18n', 'locale'], '')
  );

export const makeSelectLocaleCode = () =>
  createSelector(makeSelectLocale(), (locale: any) => {
    return typeof locale === 'string' ? locale.split('-')[0] : locale;
  });
