import React, { FC } from 'react';
import { components as Reselect } from 'react-select';
import { withLocalization } from 'system/Localization';
import { withCustomOption } from './HOC/withCustomOption';
import { Dropdown } from './Dropdown';
import { GeoHighlightTip } from './GeoHighlightTip';

export const OptionWithGeoLocation: FC = withLocalization((props: any) => {
  const {
    label,
    data: {
      data: { geoLocation },
    },
    translate
  } = props;
  return (
    <Reselect.Option {...props}>
      {label} {geoLocation && <GeoHighlightTip>({translate('suggest_region_by_ip')})</GeoHighlightTip>}
    </Reselect.Option>
  );
});

export const DropdownWithRegionGeoHighlight = withCustomOption(OptionWithGeoLocation)(Dropdown);
