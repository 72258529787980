import {
  AddressBlockFailed,
  AddressBlockReset,
  AddressStepSet,
  IAddressSubmitFailed,
  IAddressSubmitRequest,
  IAddressSubmitSuccess,
  IRequestUploadAddressDocument,
  ISetAddressDocuments,
  IUploadAddressDocumentFailed,
  IUploadAddressDocumentSuccess,
  Type,
  IAddressSetMeta,
  AddressStepInitialSet,
} from './actionTypes';

export const setAddressInitialStep = ({
  stepId,
  fields,
}: AddressStepInitialSet['payload']): AddressStepInitialSet => ({
  type: Type.ADDRESS_STEP_INITIAL_SET,
  payload: { stepId, fields },
});

export const setAddressStep = ({
  stepId,
  fields,
}: AddressStepSet['payload']): AddressStepSet => ({
  type: Type.ADDRESS_STEP_SET,
  payload: { stepId, fields },
});

export const resetAddressBlock = (): AddressBlockReset => ({
  type: Type.ADDRESS_BLOCK_RESET,
  payload: null,
});

export const failedAddressBlock = (err: Error): AddressBlockFailed => ({
  type: Type.ADDRESS_BLOCK_FAILED,
  payload: err,
});

export const requestUploadAddressDocument = (
  payload: IRequestUploadAddressDocument['payload']
): IRequestUploadAddressDocument => ({
  type: Type.ADDRESS_UPLOAD_DOC_REQUEST,
  payload,
});

export const successUploadAddressDocument = (): IUploadAddressDocumentSuccess => ({
  type: Type.ADDRESS_UPLOAD_DOC_SUCCESS,
  payload: null,
});

export const failedUploadAddressDocument = (
  err: Error[]
): IUploadAddressDocumentFailed => ({
  type: Type.ADDRESS_UPLOAD_DOC_FAILED,
  payload: err,
});

export const setAddressDocuments = (
  payload: ISetAddressDocuments['payload']
): ISetAddressDocuments => ({
  type: Type.ADDRESS_DOC_SET,
  payload,
});

export const setAddressMeta = (
  payload: IAddressSetMeta['payload']
): IAddressSetMeta => ({
  type: Type.ADDRESS_META_SET,
  payload,
});

export const requestSubmitAddress = (): IAddressSubmitRequest => ({
  type: Type.ADDRESS_SUBMIT_REQUEST,
  payload: {},
});

export const successSubmitAddress = (
): IAddressSubmitSuccess => ({
  type: Type.ADDRESS_SUBMIT_SUCCESS,
  payload: {},
});

export const failedSubmitAddress = (err: Error[]): IAddressSubmitFailed => ({
  type: Type.ADDRESS_SUBMIT_FAILED,
  payload: err,
});
