import { createFilter } from 'react-select';
import React, { FunctionComponent } from 'react';

type Stringify = (data: {
  label: string;
  value: string;
  data: { data: { alpha2: string; alpha3: string } };
}) => string;

export const withCountryFilter = <P extends {}>(
  WrappedComponent: FunctionComponent<P>
) => {
  const WithCountryFilter = (props: P) => {
    const filterConfig: { stringify: Stringify } = {
      stringify: ({ label, value, data: { data } }) => {
        return `${label} ${value} ${data.alpha2} ${data.alpha3}`;
      },
    };

    return (
      <WrappedComponent {...props} filterOption={createFilter(filterConfig)} />
    );
  };

  WithCountryFilter.displayName = `WithCountryFilter.${WrappedComponent.displayName}`;

  return WithCountryFilter;
};
