import {
  Type,
  LivenessCheckInit,
  LivenessCheckCleanup,
  LivenessCheckWebsocketConnect,
  LivenessCheckWebsocketMessage,
  LivenessCheckWebsocketError,
  LivenessCheckWebsocketDisconnect,
  LivenessCheckRefresh,
  LivenessCheckAppConnect,
  LivenessCheckAppDisconnect,
} from './actionTypes';

export const livenessCheckInit = (): LivenessCheckInit => ({
  type: Type.LIVENESS_CHECK_INIT,
});

export const livenessCheckRefresh = (): LivenessCheckRefresh => ({
  type: Type.LIVENESS_CHECK_REFRESH,
});

export const livenessCheckCleanup = (): LivenessCheckCleanup => ({
  type: Type.LIVENESS_CHECK_CLEANUP,
});

export const livenessCheckAppConnect = (): LivenessCheckAppConnect => ({
  type: Type.LIVENESS_CHECK_APP_CONNECT,
});

export const livenessCheckAppDisconnect = (): LivenessCheckAppDisconnect => ({
  type: Type.LIVENESS_CHECK_APP_DISCONNECT,
});

export const livenessCheckWebsocketConnect = (): LivenessCheckWebsocketConnect => ({
  type: Type.LIVENESS_CHECK_WEBSOCKET_CONNECT,
});

export const livenessCheckWebsocketMessage = (message: any): LivenessCheckWebsocketMessage => ({
  type: Type.LIVENESS_CHECK_WEBSOCKET_MESSAGE,
  payload: message,
});

export const livenessCheckWebsocketError = (error: any): LivenessCheckWebsocketError => ({
  type: Type.LIVENESS_CHECK_WEBSOCKET_ERROR,
  payload: error,
});

export const livenessCheckWebsocketDisconnect = (): LivenessCheckWebsocketDisconnect => ({
  type: Type.LIVENESS_CHECK_WEBSOCKET_DISCONNECT,
});

export const livenessCheckTokenRequest = () => ({
  type: Type.LIVENESS_CHECK_TOKEN_REQUEST,
});

export const livenessCheckTokenSuccess = (payload: any) => ({
  type: Type.LIVENESS_CHECK_TOKEN_SUCCESS,
  payload,
});

export const livenessCheckTokenError = (error: any) => ({
  type: Type.LIVENESS_CHECK_TOKEN_ERROR,
  payload: error,
});

export const livenessCheckMobileTokenRequest = () => ({
  type: Type.LIVENESS_CHECK_MOBILE_TOKEN_REQUEST,
});

export const livenessCheckMobileTokenSuccess = (payload: any) => ({
  type: Type.LIVENESS_CHECK_MOBILE_TOKEN_SUCCESS,
  payload,
});

export const livenessCheckMobileTokenError = (error: any) => ({
  type: Type.LIVENESS_CHECK_MOBILE_TOKEN_ERROR,
  payload: error,
});
