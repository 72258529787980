import {
  IQrInitConnect,
  IQrSetReadyState,
  IQrSetUploadId,
  IQRSetWltfsToken,
  Type,
} from './actionTypes';

export const qrInitConnect = ({
  onComplete,
  stageId,
  stepId,
  blockId,
}: IQrInitConnect['payload']): IQrInitConnect => ({
  type: Type.QR_INIT_CONNECT,
  payload: {
    stageId,
    stepId,
    onComplete,
    blockId,
  },
});

export const qrSetReadyState = (readyState: number): IQrSetReadyState => ({
  type: Type.QR_SET_READY_STATE,
  payload: readyState,
});

export const qrSetUploadId = (uploadId: string): IQrSetUploadId => ({
  type: Type.QR_SET_UPLOAD_ID,
  payload: uploadId,
});

export const qrSetWltfsToken = (payload: string): IQRSetWltfsToken => ({
  type: Type.QR_SET_WLTFS_TOKEN,
  payload,
});
