import { createSelector } from 'reselect';
import { Map, List, fromJS } from 'immutable';
import { matchPath } from 'react-router-dom';
import get from 'lodash/get';

import { Stage, StepAlias } from 'system/Stages/types';
import { Step } from 'system/Routing/types';
import { routerSelector } from 'system/Bootstrap/selectors';
import {
  makeSelectUserData,
  makeSelectExternalProfiles,
  makeSelectStageCorrectionMistakes,
} from 'system/Profile/selectors';

import { Field } from 'utils/types/shared/enums';
import { url } from 'utils/url';
import { dateUtils } from 'utils/date';
import { pickImmutable } from 'utils/collection';
import { joinByChar } from 'utils/helpers';
import { PhoneErrors } from 'utils/error';

import { updateSourceMeta } from '../sagas/utils';

export enum FileType {
  selfie = 'selfie',
}

export enum SubStore {
  DATA = 'data',
  META = 'meta',
}

export type subStore = 'data' | 'meta';
export type selectorSubStoreParam = subStore | undefined;

export const identitySelector = (state: any) => {
  return state.getIn(['dossier', Stage.IDENTITY], Map());
};

const residenceSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(identitySelector, (state) => {
    return state.getIn([subStore, joinByChar(Step.RESIDENCE)], Map());
  });

const fullResidenceSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(identitySelector, (state) => {
    return state.getIn([subStore, joinByChar(Step.FULL_RESIDENCE)], Map());
  });

const sourceOfFundsSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(identitySelector, (state) => {
    return state.getIn([subStore, joinByChar(Step.SOURCE_OF_FUNDS)], Map());
  });

const sourceOfFundsDocumentsSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(identitySelector, (state) => {
    return state.getIn([subStore, joinByChar(Step.SOURCE_OF_FUNDS_DOCUMENTS)], Map());
  });

const phoneNumberSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(identitySelector, (state) => {
    return state.getIn([subStore, joinByChar(Step.PHONE_NUMBER)], Map());
  });

const documentSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(identitySelector, (state) =>
    state.getIn([subStore, joinByChar(Step.IDENTITY_DOCUMENT)], Map())
  );

const personalDataSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(identitySelector, (state) =>
    state.getIn([subStore, joinByChar(Step.PERSONAL_INFORMATION)], Map())
  );

const livenessCheckSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(identitySelector, (state) =>
    state.getIn([subStore, StepAlias.LIVENESS_CHECK], Map())
  );

const filesSelector = createSelector(identitySelector, (state) =>
  state.getIn([SubStore.DATA, Step.UPLOAD], List())
);

export const selectedStepSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(routerSelector, identitySelector, (router, state) => {
    const matched = matchPath(router.getIn(['location', 'pathname']), {
      path: '/:stageId/:stepId',
    });
    const stepId = get(matched, 'params.stepId', '');
    return state.getIn([subStore, joinByChar(stepId)], Map());
  });

export const makeSelectErrors = () =>
  createSelector(identitySelector, (state) => state.get('errors', List()));

export const makeSelectLoading = () =>
  createSelector(identitySelector, (state) => {
    return state.get('loading', false);
  });

// residence
export const makeSelectCountry = () => {
  return createSelector(
    [residenceSelector(), fullResidenceSelector()],
    (residenceState, fullResidenceState) => {
      return residenceState.get(Field.COUNTRY, '') || fullResidenceState.get(Field.COUNTRY, '');
    }
  );
};

const addressDocumentSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(identitySelector, (state) => {
    return state.getIn([subStore, 'addressDocument'], Map());
  });

const addressDocumentUploadSelector = (subStore: selectorSubStoreParam = SubStore.DATA) =>
  createSelector(identitySelector, (state) => {
    return state.getIn([subStore, 'addressDocumentUpload'], Map());
  });

export const makeSelectCountryMeta = () => {
  return createSelector(residenceSelector(SubStore.META), (state) => {
    return state.get(Field.COUNTRY, Map());
  });
};

export const makeSelectRegion = () => {
  return createSelector(
    [residenceSelector(), fullResidenceSelector()],
    (residenceState, fullResidenceState) => {
      return fullResidenceState.get(Field.REGION, '') || residenceState.get(Field.REGION, '');
    }
  );
};

export const makeSelectRegionMeta = () => {
  return createSelector(residenceSelector(SubStore.META), (state) =>
    state.get(Field.REGION, Map())
  );
};

export const makeSelectSubRegion = () => {
  return createSelector(
    [residenceSelector(), fullResidenceSelector()],
    (residence, fullResidence) => {
      return fullResidence.get(Field.SUB_REGION, '') || residence.get(Field.SUB_REGION, '');
    }
  );
};

export const makeSelectSubRegionMeta = () => {
  return createSelector(residenceSelector(SubStore.META), (state) =>
    state.get(Field.SUB_REGION, Map())
  );
};
// full residence

export const makeSelectFullResidenceCountryMeta = () => {
  return createSelector(fullResidenceSelector(SubStore.META), (state) => {
    return state.get(Field.COUNTRY, Map());
  });
};

export const makeSelectFullResidenceRegionMeta = () => {
  return createSelector(fullResidenceSelector(SubStore.META), (state) =>
    state.get(Field.REGION, Map())
  );
};

export const makeSelectFullResidenceSubRegionMeta = () => {
  return createSelector(fullResidenceSelector(SubStore.META), (state) =>
    state.get(Field.SUB_REGION, Map())
  );
};

export const makeSelectZipCode = () => {
  return createSelector(
    residenceSelector(),
    fullResidenceSelector(),
    (residence, fullResidence) => {
      return fullResidence.get(Field.ZIP_CODE, '') || residence.get(Field.ZIP_CODE, '');
    }
  );
};

export const makeSelectZipCodeMeta = () => {
  return createSelector(fullResidenceSelector(SubStore.META), (state) =>
    state.get(Field.ZIP_CODE, Map())
  );
};

export const makeSelectStreet = () => {
  return createSelector(
    residenceSelector(),
    fullResidenceSelector(),
    (residence, fullResidence) => {
      return fullResidence.get(Field.STREET, '') || residence.get(Field.STREET, '');
    }
  );
};

export const makeSelectStreetMeta = () => {
  return createSelector(fullResidenceSelector(SubStore.META), (state) => {
    return state.get(Field.STREET, Map());
  });
};

export const makeSelectCity = () => {
  return createSelector(
    residenceSelector(),
    fullResidenceSelector(),
    (residence, fullResidence) => {
      return fullResidence.get(Field.CITY, '') || residence.get(Field.CITY, '');
    }
  );
};

export const makeSelectCityMeta = () => {
  return createSelector(fullResidenceSelector(SubStore.META), (state) =>
    state.get(Field.CITY, Map())
  );
};

export const makeSelectAptSuite = () => {
  return createSelector(
    residenceSelector(),
    fullResidenceSelector(),
    (residence, fullResidence) => {
      return fullResidence.get(Field.APT_SUITE, '') || residence.get(Field.APT_SUITE, '');
    }
  );
};

export const makeSelectAptSuiteMeta = () => {
  return createSelector(fullResidenceSelector(SubStore.META), (state) =>
    state.get(Field.APT_SUITE, Map())
  );
};

export const makeSelectPhone = () => {
  return createSelector(phoneNumberSelector(), (state) => state.get(Field.PHONE, Map()));
};

export const makeSelectPhoneMeta = () => {
  return createSelector(phoneNumberSelector(SubStore.META), (state) =>
    state.get(Field.PHONE, Map())
  );
};

export const makeSelectPhoneCode = () => {
  return createSelector(makeSelectPhone(), (state) => state.get(Field.PHONE_CODE, ''));
};

export const makeSelectPhoneNumber = () => {
  return createSelector(makeSelectPhone(), (state) => state.get(Field.PHONE_NUMBER, ''));
};

export const makeSelectPhoneCountry = () => {
  return createSelector(makeSelectPhone(), (state) => state.get(Field.COUNTRY, ''));
};

export const makeSelectSSN = () => {
  return createSelector(residenceSelector(), (state) => state.get(Field.SSN, ''));
};

export const makeSelectSSNMeta = () => {
  return createSelector(residenceSelector(SubStore.META), (state) => state.get(Field.SSN, Map()));
};

export const makeSelectFullResidenceSSN = () => {
  return createSelector(
    residenceSelector(),
    fullResidenceSelector(),
    (residence, fullResidence) => {
      return residence.get(Field.SSN, '') || fullResidence.get(Field.SSN, '');
    }
  );
};

export const makeSelectFullResidenceSSNMeta = () => {
  return createSelector(fullResidenceSelector(SubStore.META), (state) =>
    state.get(Field.SSN, Map())
  );
};

export const makeSelectDocSubType = () => {
  return createSelector(routerSelector, documentSelector(), (router, state) => {
    const { source = '' } = url.parse(router.getIn(['location', 'search']));

    return state.get(Field.SUB_TYPE, source);
  });
};

export const makeSelectDocSubTypeMeta = () => {
  return createSelector(documentSelector(SubStore.META), (state) => {
    return state.get(Field.SUB_TYPE, Map());
  });
};

// export const makeSelectIssuingCountry = () => {
//   return createSelector(
//     [selectedStepSelector(), makeSelectCountry(), routerSelector],
//     (state, selectedCountry, router) => {
//       const { country = '' } = url.parse(router.getIn(['location', 'search']));
//       return state.get(Field.ISSUING_COUNTRY, country || selectedCountry);
//     }
//   );
// };

export const makeSelectIssuingCountry = () => {
  return createSelector(identitySelector, makeSelectDocSubType(), (state, docSubtype) => {
    const defaults = state.getIn([SubStore.DATA, 'identityDocument', Field.ISSUING_COUNTRY], '');

    return state.getIn(
      [SubStore.DATA, `${docSubtype}Information`, Field.ISSUING_COUNTRY],
      defaults
    );
  });
};

export const makeSelectIssuingCountryMeta = () => {
  return createSelector(identitySelector, makeSelectDocSubType(), (state, docSubtype) => {
    return state.getIn([SubStore.META, `${docSubtype}Information`, Field.ISSUING_COUNTRY], Map());
  });
};

export const makeSelectSerialNumber = () => {
  return createSelector(identitySelector, makeSelectDocSubType(), (state, docSubtype) =>
    state.getIn([SubStore.DATA, `${docSubtype}Information`, Field.SERIAL_NUMBER], '')
  );
};

export const makeSelectSerialNumberMeta = () => {
  return createSelector(identitySelector, makeSelectDocSubType(), (state, docSubtype) =>
    state.getIn([SubStore.META, `${docSubtype}Information`, Field.SERIAL_NUMBER], Map())
  );
};

export const makeSelectPersonalCode = () => {
  return createSelector(identitySelector, makeSelectDocSubType(), (state, docSubtype) =>
    state.getIn([SubStore.DATA, `${docSubtype}Information`, Field.PERSONAL_CODE], undefined)
  );
};

export const makeSelectPersonalCodeMeta = () => {
  return createSelector(identitySelector, makeSelectDocSubType(), (state, docSubtype) =>
    state.getIn([SubStore.META, `${docSubtype}Information`, Field.PERSONAL_CODE], Map())
  );
};

export const makeSelectExpireDate = () => {
  return createSelector(identitySelector, makeSelectDocSubType(), (state, docSubtype) =>
    state.getIn([SubStore.DATA, `${docSubtype}Information`, Field.EXPIRE_DATE], '')
  );
};

export const makeSelectExpireDateMeta = () => {
  return createSelector(identitySelector, makeSelectDocSubType(), (state, docSubtype) =>
    state.getIn([SubStore.META, `${docSubtype}Information`, Field.EXPIRE_DATE], Map())
  );
};

export const makeSelectHasExpireDate = () => {
  return createSelector(identitySelector, makeSelectDocSubType(), (state, docSubtype) =>
    state.getIn([SubStore.DATA, `${docSubtype}Information`, Field.HAS_EXPIRE_DATE], true)
  );
};

export const makeSelectNationality = () => {
  return createSelector([personalDataSelector(), makeSelectCountry()], (state, country) =>
    state.get(Field.NATIONALITY, country)
  );
};

export const makeSelectNationalityMeta = () => {
  return createSelector([personalDataSelector(SubStore.META)], (state) =>
    state.get(Field.NATIONALITY, Map())
  );
};

export const makeSelectFirstName = () => {
  return createSelector(personalDataSelector(), (state) => state.get(Field.FIRST_NAME, ''));
};

export const makeSelectFirstNameMeta = () => {
  return createSelector(personalDataSelector(SubStore.META), (state) =>
    state.get(Field.FIRST_NAME, Map())
  );
};

export const makeSelectLastName = () => {
  return createSelector(personalDataSelector(), (state) => state.get(Field.LAST_NAME, ''));
};

export const makeSelectLastNameMeta = () => {
  return createSelector(personalDataSelector(SubStore.META), (state) =>
    state.get(Field.LAST_NAME, Map())
  );
};

export const makeSelectMiddleNameName = () => {
  return createSelector(personalDataSelector(), (state) => state.get(Field.MIDDLE_NAME, ''));
};

export const makeSelectMiddleNameNameMeta = () => {
  return createSelector(personalDataSelector(SubStore.META), (state) => {
    return state.get(Field.MIDDLE_NAME, Map());
  });
};

export const makeSelectGender = () => {
  return createSelector(personalDataSelector(), (state) => state.get(Field.GENDER, ''));
};

export const makeSelectGenderMeta = () => {
  return createSelector(personalDataSelector(SubStore.META), (state) =>
    state.get(Field.GENDER, Map())
  );
};

export const makeSelectDateOfBirth = () => {
  return createSelector(personalDataSelector(), (state) => state.get(Field.DATE_OF_BIRTH, ''));
};

export const makeSelectDateOfBirthMeta = () => {
  return createSelector(personalDataSelector(SubStore.META), (state) =>
    state.get(Field.DATE_OF_BIRTH, '')
  );
};

export const makeSelectResidence = () =>
  createSelector(residenceSelector(), (state: Map<string, any>) => {
    return state.filter(Boolean);
  });

export const makeSelectFullResidence = () =>
  createSelector(fullResidenceSelector(), (state: Map<string, any>) => {
    return state.filter(Boolean);
  });

export const makeSelectPersonalData = (subStore?: selectorSubStoreParam) =>
  createSelector(personalDataSelector(subStore), (state: Map<string, any>) => {
    return state.filter(Boolean);
  });

export const makeSelectIdentityDocument = createSelector(
  residenceSelector(),
  (state: Map<string, any>) => {
    return state.filter(Boolean);
  }
);

export const makeSelectLivenessCheckSelfie = () => {
  return createSelector(livenessCheckSelector(), (state) => {
    const images = state.get('images', List());

    return images.get(0, Map());
  });
};

export const makeSelectSelfieToken = () =>
  createSelector(
    filesSelector,
    makeSelectLivenessCheckSelfie(),
    (state: Map<string, any>, livenessCheckSelfie) => {
      const livenessCheckFileToken = livenessCheckSelfie.get('fileToken', '');

      const selfie = state.find(
        (file: Map<string, any>) => file.get('id') === FileType.selfie,
        null,
        Map()
      );

      const selfieFileToken = selfie.get('fileToken', '');

      return selfieFileToken || livenessCheckFileToken;
    }
  );

export const makeSelectFilesTokens = () =>
  createSelector(filesSelector, (state: List<Map<string, any>>) => {
    return state.reduce((reduction, file) => {
      return file.get('id') !== FileType.selfie
        ? reduction.push(file.get('fileToken', ''))
        : reduction;
    }, List());
  });

export const makeSelectExpireDateObject = () => {
  return createSelector(makeSelectExpireDate(), (date) => dateUtils.dateStrToObj(date));
};

export const makeSelectDateOfBirthObject = () => {
  return createSelector(makeSelectDateOfBirth(), (date) => dateUtils.dateStrToObj(date));
};

export const makeSelectWithMetaSource = (select: any) => {
  return createSelector(select(), (state: Map<any, any> = Map()) => {
    return pickImmutable(state, ['source']).get('source', '');
  });
};

export const makeSelectStepMeta = (fields: any) =>
  createSelector(
    selectedStepSelector(SubStore.META),
    makeSelectExternalProfiles(),
    makeSelectStageCorrectionMistakes('identity'),
    (stepMeta, externalProfiles, correctionMistakes) => {
      return fromJS(fields).reduce(
        updateSourceMeta({ externalProfiles, correctionMistakes }),
        stepMeta
      );
    }
  );

export const makeSelectUploadMistakes = () =>
  createSelector(makeSelectStageCorrectionMistakes('identity'), (mistakes) => {
    return mistakes.filter((mistake: Field) =>
      [Field.IMAGES, Field.SELFIE_IMAGE].includes(mistake)
    );
  });

export const makeSelectPhoneValidation = () =>
  createSelector(identitySelector, (state: any) => state.get('phoneValidation', Map()));

export const makeSelectPhoneValidationErrors = () =>
  createSelector(makeSelectPhoneValidation(), (state: any) => state.get('error', Map()));

export const makeSelectPhoneValidationSkip = () =>
  createSelector(makeSelectPhoneValidation(), (state: any) => state.get('skip', false));

export const makeSelectPhoneValidationValid = () =>
  createSelector(
    [makeSelectPhoneValidation(), makeSelectPhoneValidationErrors()],
    (state: any, error: any) => {
      const errorCode = error.get('code', 0);
      return state.get('valid', false) || errorCode === PhoneErrors.VALIDATED;
    }
  );

export const makeSelectPhoneValidationTimestamp = () =>
  createSelector(
    [makeSelectPhoneValidation(), makeSelectPhoneValidationErrors()],
    (state: any, errors: any) => state.get('timestamp', 0) || errors.getIn(['data', 'timestamp'], 0)
  );

export const makeSelectValidationPhoneCode = () =>
  createSelector([makeSelectPhoneValidation(), makeSelectPhoneCode()], (phoneState, code) =>
    phoneState.getIn(['phone', 'code'], code)
  );

export const makeSelectValidationPhoneNumber = () =>
  createSelector([makeSelectPhoneValidation(), makeSelectPhoneNumber()], (phoneState, number) =>
    phoneState.getIn(['phone', 'number'], number)
  );

export const makeSelectValidationPhoneCountry = () =>
  createSelector([makeSelectPhoneValidation(), makeSelectPhoneCountry()], (phoneState, country) =>
    phoneState.getIn(['phone', 'country'], country)
  );

export const makeSelectValidationPhoneMask = () =>
  createSelector([makeSelectPhoneValidation()], (phoneState) =>
    phoneState.getIn(['phone', 'phoneMask'], '')
  );

export const makeSelectValidationCode = () =>
  createSelector(makeSelectPhoneValidation(), (phoneState) => phoneState.get('code', ''));

export const makeSelectJurisdictionCode = () => {
  return createSelector(
    identitySelector,
    makeSelectDocSubType(),
    makeSelectUserData(),
    (state, docSubtype, userData) => {
      const jurisdictionCode = state.getIn(
        [SubStore.DATA, `${docSubtype}Information`, Field.JURISDICTION_CODE],
        ''
      );

      return jurisdictionCode || userData.getIn(['identityDocument', 'jurisdictionCode'], '');
    }
  );
};

export const makeSelectJurisdictionCodeMeta = () => {
  return createSelector(identitySelector, makeSelectDocSubType(), (state, docSubtype) =>
    state.getIn([SubStore.META, `${docSubtype}Information`, Field.JURISDICTION_CODE], Map())
  );
};

// source of funds
export const makeSelectAccountCreateReason = () => {
  return createSelector(sourceOfFundsSelector(), (state) => {
    return state.get(Field.ACCOUNT_CREATE_REASON, '');
  });
};

export const makeSelectAccountCreateReasonOther = () => {
  return createSelector(sourceOfFundsSelector(), (state) => {
    return state.get(Field.ACCOUNT_CREATE_REASON_OTHER, '');
  });
};

export const makeSelectSourceOfFunds = () => {
  return createSelector(sourceOfFundsSelector(), (state) => {
    return state.get(Field.SOURCE_OF_FUNDS, '');
  });
};

export const makeSelectSourceOfFundsOther = () => {
  return createSelector(sourceOfFundsSelector(), (state) => {
    return state.get(Field.SOURCE_OF_FUNDS_OTHER, '');
  });
};

export const makeSelectAnnualFundsAmount = () => {
  return createSelector(sourceOfFundsSelector(), (state) => {
    return state.get(Field.ANNUAL_FUNDS_AMOUNT, '');
  });
};

export const makeSelectStatusIdentity = () => {
  return createSelector(sourceOfFundsSelector(), (state) => {
    return state.get(Field.STATUS_IDENTITY, '');
  });
};

export const makeSelectEmploymentPositionIdentity = () => {
  return createSelector(sourceOfFundsSelector(), (state) => {
    return state.get(Field.EMPLOYMENT_POSITION_IDENTITY, '');
  });
};

export const makeSelectWhenReceiveProfitFrom = () => {
  return createSelector(sourceOfFundsSelector(), (state) => {
    return state.get(Field.WHEN_RECEIVE_PROFIT, '');
  });
};

export const makeSelectWhenReceiveProfitFromObject = () => {
  return createSelector(makeSelectWhenReceiveProfitFrom(), (date) => dateUtils.dateStrToObj(date));
};

export const makeSelectWhenReceiveFunds = () => {
  return createSelector(sourceOfFundsSelector(), (state) => {
    return state.get(Field.WHEN_RECEIVE_FUNDS, '');
  });
};

export const makeSelectWhenReceiveFundsObject = () => {
  return createSelector(makeSelectWhenReceiveFunds(), (date) => dateUtils.dateStrToObj(date));
};

export const makeSelectMonthlyTurnover = () => {
  return createSelector(sourceOfFundsSelector(), (state) => {
    return state.get(Field.MONTHLY_TURNOVER, '');
  });
};

export const makeSelectSourceOfFundsDocuments = () => {
  return createSelector(sourceOfFundsDocumentsSelector(), (state) => {
    return state.get(Field.IMAGES, List());
  });
};

export const makeSelectAccountCreateReasonMeta = () => {
  return createSelector(sourceOfFundsDocumentsSelector(SubStore.META), (state) =>
    state.getIn([Field.ACCOUNT_CREATE_REASON], Map())
  );
};

export const makeSelectAccountCreateReasonOtherMeta = () => {
  return createSelector(sourceOfFundsDocumentsSelector(SubStore.META), (state) =>
    state.getIn([Field.ACCOUNT_CREATE_REASON_OTHER], Map())
  );
};

export const makeSelectSourceOfFundsMeta = () => {
  return createSelector(sourceOfFundsDocumentsSelector(SubStore.META), (state) =>
    state.getIn([Field.SOURCE_OF_FUNDS], Map())
  );
};

export const makeSelectSourceOfFundsOtherMeta = () => {
  return createSelector(sourceOfFundsDocumentsSelector(SubStore.META), (state) =>
    state.getIn([Field.SOURCE_OF_FUNDS_OTHER], Map())
  );
};

export const makeSelectAnnualFundsAmountMeta = () => {
  return createSelector(sourceOfFundsDocumentsSelector(SubStore.META), (state) =>
    state.getIn([Field.ANNUAL_FUNDS_AMOUNT], Map())
  );
};

export const makeSelectStatusIdentityMeta = () => {
  return createSelector(sourceOfFundsDocumentsSelector(SubStore.META), (state) =>
    state.getIn([Field.STATUS_IDENTITY], Map())
  );
};

export const makeSelectEmploymentPositionIdentityMeta = () => {
  return createSelector(sourceOfFundsDocumentsSelector(SubStore.META), (state) =>
    state.getIn([Field.EMPLOYMENT_POSITION_IDENTITY], Map())
  );
};

export const makeSelectWhenReceiveProfitFromMeta = () => {
  return createSelector(sourceOfFundsDocumentsSelector(SubStore.META), (state) =>
    state.getIn([Field.WHEN_RECEIVE_PROFIT], Map())
  );
};

export const makeSelectWhenReceiveFundsMeta = () => {
  return createSelector(sourceOfFundsDocumentsSelector(SubStore.META), (state) =>
    state.getIn([Field.WHEN_RECEIVE_FUNDS], Map())
  );
};

export const makeSelectMonthlyTurnoverMeta = () => {
  return createSelector(sourceOfFundsDocumentsSelector(SubStore.META), (state) =>
    state.getIn([Field.MONTHLY_TURNOVER], Map())
  );
};

export const makeSelectSourceOfFundsDocumentsMeta = () => {
  return createSelector(sourceOfFundsDocumentsSelector(SubStore.META), (state) =>
    state.getIn([Field.IMAGES], Map())
  );
};

export const makeSelectSourceOfFundsBlock = () => {
  return createSelector(
    sourceOfFundsSelector(),
    makeSelectSourceOfFundsDocuments(),
    (sourceOfFunds, documents) => {
      let images;

      if (sourceOfFunds.isEmpty()) return Map();

      if (!documents.isEmpty()) {
        images = documents.map((document: Map<string, any>) => document.get('fileToken'));
      }

      return sourceOfFunds.set('images', images);
    }
  );
};

export const makeSelectAddressDocumentSubtype = () => {
  return createSelector(addressDocumentSelector(), (document: any) => {
    return document.get('subType', '');
  });
};

export const makeSelectAddressDocumentSubtypeDetailed = () => {
  return createSelector(addressDocumentSelector(), (documents: any) => {
    return documents.get('subTypeDetailed', '');
  });
};

export const makeSelectAddressDocumentUploads = () => {
  return createSelector(addressDocumentUploadSelector(), (documents: any) => {
    return documents.get('files', List());
  });
};

export const makeSelectAddressDocumentBlock = () => {
  return createSelector(
    makeSelectAddressDocumentSubtype(),
    makeSelectAddressDocumentSubtypeDetailed(),
    makeSelectAddressDocumentUploads(),
    (subType: string, subTypeDetailed: string, images: any) => {
      if (subType) {
        return Map({
          subType,
          ...(subTypeDetailed && { subTypeDetailed }),
          images: images?.map((img: Map<string, any>) => img.get('fileToken')),
        });
      }
      return Map();
    }
  );
};

export const makeSelectLivenessCheckStats = () => {
  return createSelector(identitySelector, (state) => {
    const statistics = state.getIn([SubStore.DATA, StepAlias.LIVENESS_CHECK_STATS], List());
    return statistics.toJS();
  });
};
