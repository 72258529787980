import React, { useContext } from 'react';
import { withRouter, match } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { History, Location } from 'history';
import styled, { ThemeContext } from 'styled-components';

import { ILocalizationProps, withLocalization } from 'system/Localization';
import { makeSelectStageCorrection } from 'system/Profile/selectors';
import { Routes } from 'system/Routing/Routes';

import {
  BUTTON_TYPE,
  ICommonStubProps,
} from 'containers/Stubs/CommonStub/types';
import { CommonStub } from 'containers/Stubs/CommonStub';

import { Content } from 'components/common/Layout';
import { StageId } from 'system/Stages/types';

interface ISubmittedStub extends ILocalizationProps {
  location: Location;
  history: History;
  match: match<{ stageId: StageId; stepId: string }>;
  correctionList: Map<string, any>;
}

const mapStateToProps = createStructuredSelector({
  correctionList: makeSelectStageCorrection(),
});

export const Correction = connect(mapStateToProps)(
  withRouter(
    withLocalization(
      ({
        translate,
        match: {
          params: { stageId, stepId },
        },
        correctionList,
        history,
      }: ISubmittedStub) => {
        const correction = correctionList.get(stageId, Map()).toJS();

        const {
          list = [],
          paragraphs = [],
          correctionTextTitle = '',
          correctionComment = '',
          correctionCommentTitle = '',
        } = correction;

        const {
          components: {
            correction: { accent },
          },
        } = useContext(ThemeContext);

        const contentProps: ICommonStubProps = {
          stageTitle: translate(`stage_title_${stageId}`),
          stageSubtitle: translate('stage_correction_label'),
          titleColor: accent,
          buttons: [
            {
              clickHandler: () =>
                history.push(Routes.url({ stageId, stepId } as any)),
              text: translate('button_update'),
              mods: { type: BUTTON_TYPE.primary },
            },
          ],
        };

        return (
          <CommonStub {...contentProps}>
            <Wrapper>
              <MainWrapper>
                <Section>
                  {correctionTextTitle && (
                    <Title>{translate(correctionTextTitle)}</Title>
                  )}
                  <List>
                    {list.map((item: string, idx: number) => {
                      return <Item key={`${item}-${idx}`}>{item}</Item>;
                    })}
                  </List>
                </Section>
                {paragraphs.map(({ label, mistake }: any, idx: number) => {
                  return (
                    <Section key={`${label}-${idx}`}>
                      <Title>{label}</Title>
                      <List>
                        <Item>{mistake}</Item>
                      </List>
                    </Section>
                  );
                })}
              </MainWrapper>
              <CommentWrapper>
                <Section>
                  {correctionCommentTitle && (
                    <Title>{translate(correctionCommentTitle)}</Title>
                  )}
                  {correctionComment && (
                    <Message>&quot;{correctionComment}&quot;</Message>
                  )}
                </Section>
              </CommentWrapper>
            </Wrapper>
          </CommonStub>
        );
      }
    )
  )
);

const Wrapper = styled(Content)`
  width: auto;
  justify-content: center;
`;

const MainWrapper = styled.div`
  font-size: 16px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    display: block;
    left: -32px;
    width: 2px;
    height: calc(100% - 16px);
    background-color: ${({
      theme: {
        components: {
          correction: { accent },
        },
      },
    }) => accent};
  }
`;
const CommentWrapper = styled.div`
  font-size: 16px;
`;

const Section = styled.section`
  text-align: left;
  margin-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
`;

const Title = styled.h4`
  margin-bottom: 6px;
`;

const Message = styled.p`
  word-break: break-all;
`;

const List = styled.ul`
  padding-left: 20px;
`;

const Item = styled.li`
  list-style: disc;
`;
