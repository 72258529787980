import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

import { dossierSelector } from 'system/Dossier/selectors';
import { StageId, StageStatusName, StageStatuses } from '../../Stages/types';
import { makeSelectStageById } from '../../Profile/selectors';

export const makeSelectDossierRouting = createSelector(
  dossierSelector,
  (state) => state.get('routing', List())
);

export const makeSelectSchema = () =>
  createSelector(makeSelectDossierRouting, (state) => {
    return state.get('schema', List());
  });

export const makeSelectSchemaByStage = (stageId: StageId) =>
  createSelector(makeSelectSchema(), (schema) => {
    return schema.get(stageId, Map());
  });

export const makeSelectStagesRouteIds = () =>
  createSelector(makeSelectDossierRouting, (state) => {
    return state.get('stagesRouteIds', Map());
  });

export const makeSelectEntryUrls = () =>
  createSelector(makeSelectDossierRouting, (state) => {
    return state.get('stagesEntryUrls', Map());
  });

export const makeSelectEntryUrl = () =>
  createSelector(makeSelectEntryUrls(), (entryUrls) => {
    return entryUrls.valueSeq().first();
  });

export const makeSelectStageProgress = () =>
  createSelector(makeSelectDossierRouting, (state) =>
    state.get('stageProgress', Map())
  );

const makeSelectStageBlocksIds = (stage: StageId) =>
  createSelector(makeSelectStageById(stage), (selectedStage) => {
    return selectedStage
      .get('blocks', List())
      .map((block: any) => block.get('id', ''));
  });

export const makeSelectSchemaOfStageFilteredByDossierBlocks = (stage: StageId) =>
  createSelector(
    makeSelectStageBlocksIds(stage),
    makeSelectSchemaByStage(stage),
    (blocks, selectedSchemaOfStage) => {
      return selectedSchemaOfStage.filter(
        (schemaStepItem: any, schemaBlockId: string) => {
          return blocks.includes(schemaBlockId);
        }
      );
    }
  );

const makeSelectSchemaOfStageFilteredByDossier = (
  stage: StageId
) =>
  createSelector(
    makeSelectStageBlocksIds(stage),
    makeSelectSchemaOfStageFilteredByDossierBlocks(stage),
    (blocks, schema) => {
      return blocks.reduce((acc: any, it: any) => {
        return acc.merge(Map({[it]: schema.get(it)}))
      }, Map());
    }
  );

const makeSelectSchemaFlattened = (stageId: StageId) =>
  createSelector(
    makeSelectSchemaOfStageFilteredByDossier(stageId),
    (schema) => schema.valueSeq().flatten(true).filter(Boolean)
  );

const makeSelectSchemaFlattenedWithCorrection = (stageId: StageId) =>
  createSelector(
    makeSelectStageById(stageId),
    makeSelectSchemaOfStageFilteredByDossier(stageId),
    (stage, schema) => {
      const mistakes = stage
        .getIn(['smartCorrection', 'mistakes'], Map())
        .map((mistake: any) => mistake.get('name'));

      return schema.entrySeq().reduce((acc: any, schemaBlockItem: any) => {
        const [schemaBlockId, schemaBlockSteps = []] = schemaBlockItem;

        const blockMistakes = mistakes
          .filter((it: any) => it.includes(schemaBlockId))
          .map((it: any) => {
            return it.slice(it.indexOf('.') + 1);
          });

        const stepsWithMistake = schemaBlockSteps.filter((schemaStep: any) => {
          if (List.isList(schemaStep)) {
            const subSchema = schemaStep.filter((it: any) => {
              return it.get('fields').find((i: any) => {
                return blockMistakes.includes(i);
              });
            });

            return subSchema.size;
          }

          return schemaStep.get('fields').find((schemaField: any) => {
            return blockMistakes.includes(schemaField);
          });
        }, List());

        return acc.merge(stepsWithMistake);
      }, List());
    }
  );

export const makeSelectSchemaFlattenedFilteredByStatus = (
  status: StageStatusName | string = '',
  stageId: StageId
) =>
  createSelector(
    makeSelectSchemaFlattened(stageId),
    makeSelectSchemaFlattenedWithCorrection(stageId),
    (schemaFlattenedBase, schemaFlattenedWithCorrection) => {
      if (status === StageStatuses.CORRECTION) {
        return schemaFlattenedWithCorrection;
      }

      return schemaFlattenedBase;
    }
  );
