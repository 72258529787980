import * as Sentry from '@sentry/react';
import { getAppEnv } from '../env';

export const initLogger = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_LOGGER_URL,
    attachStacktrace: true,
    environment: getAppEnv()
  });
};

export const emitLoggerError = (error: any, { key, data }: { key: string; data: any } ) => {
  Sentry.withScope((scope) => {
    scope.setExtra(key, data);
    Sentry.captureException(error);
  });
};

export const setLoggerUserData = (data: any) => {
  Sentry.setUser(data);
};
