import styled from 'styled-components';

import { ITipProps } from '../types';

interface TipProps {
  isFlexible?: boolean;
  tipPadding?: string;
  ref: any;
}

export const Tip = styled.div<TipProps>`
  position: absolute;
  z-index: 10;
  padding: ${({ theme, tipPadding }: ITipProps) => tipPadding || theme.components.tooltip.padding};
  border: ${({ theme }: ITipProps) => theme.components.tooltip.border};
  border-radius: ${({ theme }: ITipProps) => theme.components.tooltip.borderRadius};
  background: ${({ theme }: ITipProps) => theme.components.tooltip.background};
  font-size: ${({ theme }: ITipProps) => theme.components.tooltip.fontSize};
  max-width: ${({ isFlexible }: ITipProps) => isFlexible ? 'none' : '312px'};
`;

export const TriggerBox = styled.span`
  padding: 6px 6px 10px;
  margin-bottom: -5px;
  display: inline-flex;
  align-items: center;
`;
