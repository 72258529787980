import { createSelector } from 'reselect';
import { Map, List } from 'immutable';

import { makeSelectSelectPersonalBlockCacheRoles } from 'stages/Corporate/selectors';
import { makeSelectUserData, makeSelectStageBlocks } from 'system/Profile/selectors';
import { Stage, Block, StageStatuses } from 'system/Stages/types';

export const makeSelectCompanyRepresentatives = () =>
  createSelector(makeSelectSelectPersonalBlockCacheRoles(), (state: Map<string, any>) => {
    return state.map((role: Map<string, any>) => {
      const displayName = role.getIn(['identityDocument', 'personalData', 'displayName']);

      return {
        label: displayName,
        value: role.get('entryId'),
      };
    });
  });

export const makeSelectIsAllSigned = () =>
  createSelector(makeSelectUserData(), (state: Map<string, any>) => {
    return state.getIn([Block.SIGNATURE, 'allSigned'], false);
  });

export const makeSelectIsLockSigners = () =>
  createSelector(makeSelectUserData(), (state: Map<string, any>) => {
    return state.getIn([Block.SIGNATURE, 'lock'], false);
  });

export const makeSelectSavedSigners = () =>
  createSelector(makeSelectUserData(), (state: Map<string, any>) =>
    state.getIn([Block.SIGNATURE, 'signers'], Map()).toList()
  );

export const makeSelectCompanySignerEmails = () =>
  createSelector(makeSelectSavedSigners(), (savedSigners: List<Map<string, any>>) =>
    savedSigners.toJS()
  );

export const makeSelectEsignatureStatus = () =>
  createSelector(makeSelectStageBlocks(Stage.CORPORATE), (blocks: List<Map<string, any>>) => {
    const esignature = blocks.find(
      (block: Map<string, any>) => block.get('id') === Block.SIGNATURE
    );

    return esignature?.get?.('status');
  });

export const makeSelectEsignatureAwaiting = () =>
  createSelector(makeSelectEsignatureStatus(), (status: string | undefined) => {
    return status === StageStatuses.AWAITING;
  });
