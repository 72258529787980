import React from 'react';
import { match, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { History } from 'history';

import { Stub } from 'components/UI/Icon';
import { CommonStub } from 'containers/Stubs/CommonStub';
import {
  BUTTON_TYPE,
  ICommonStubProps,
} from 'containers/Stubs/CommonStub/types';
import { ILocalizationProps, withLocalization } from 'system/Localization';
import { types, withMixpanel } from 'system/Analytics';
import { requestStageAnswer } from 'stages/Questionnaire/actions';
import { Routes } from 'system/Routing/Routes'
import { StageId } from 'system/Stages/types';

interface IQuestionnaireNotice extends ILocalizationProps {
  handleSubmitStageAnswer: (stageId: string, answer: string) => void;
  history: History;
  processMixpanel: (event: string | null) => void;
  match: match<{ stageId: StageId }>;
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  handleSubmitStageAnswer: (stageId: StageId, answer: string) => {
    dispatch(requestStageAnswer({ stageId, answer }));
  },
});

const QuestionnaireNoticeComponent = withLocalization<IQuestionnaireNotice>(
  ({
     translate,
     history: {
       push,
     },
     match: {
       params: { stageId },
     },
     handleSubmitStageAnswer,
     processMixpanel,
   }) => {

    const noticeHandler = (type: string) => {
      let answer = '';
      let event = null;

      if (type === 'cancel') {
        answer = 'refused';
        event = 'QUESTIONNAIRE_WARNING_QUESTION_CANCEL_EVENT';
      }
      if (type === 'proceed') {
        answer = 'accepted';
        event = 'QUESTIONNAIRE_WARNING_QUESTION_PROCEED_EVENT';
      }

      handleSubmitStageAnswer(stageId, answer);
      processMixpanel(event)
      push(Routes.root());
    };

    const contentProps: ICommonStubProps = {
      stageTitle: translate('block_title_cfd'),
      buttons: [
        {
          mods: { type: BUTTON_TYPE.regular },
          clickHandler: () => noticeHandler('cancel'),
          text: translate('stub_cancel'),
          event: types.TRACK.QUESTIONNAIRE_WARNING_QUESTION_CANCEL_EVENT,
        },
        {
          mods: { type: BUTTON_TYPE.primary },
          clickHandler: () => {
            // @see ZNYCH-3774
            noticeHandler('cancel');
          },
          text: translate('stub_proceed'),
          event: types.TRACK.QUESTIONNAIRE_WARNING_QUESTION_PROCEED_EVENT,
        },
      ],
      body: {
        Icon: Stub.Notice,
        message: translate('screen_notice_note'),
      },
    };

    return <CommonStub {...contentProps} />;
  }
)

export const QuestionnaireNotice = connect(
  null,
  mapDispatchToProps
)(withRouter(withMixpanel(QuestionnaireNoticeComponent))
);
