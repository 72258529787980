import React from 'react';

import { IconProps } from 'components/UI/Icon/types';

export const Share: React.FunctionComponent<IconProps> = ({
  fill,
  height,
  width,
}) => (
  <svg
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="16" cy="16" fill={fill} r="16" />
      <path
        d="M19.978 19.31a2.46 2.46 0 0 0-1.626.618l-5.916-3.334a2.55 2.55 0 0 0 .075-.562 2.55 2.55 0 0 0-.075-.562l5.85-3.301c.447.401 1.036.65 1.692.65 1.377 0 2.489-1.076 2.489-2.41 0-1.333-1.112-2.409-2.49-2.409-1.376 0-2.488 1.076-2.488 2.41 0 .192.033.377.075.562l-5.85 3.301a2.522 2.522 0 0 0-1.692-.65c-1.377 0-2.489 1.076-2.489 2.41 0 1.332 1.112 2.409 2.49 2.409.655 0 1.244-.25 1.692-.65l5.907 3.34a2.198 2.198 0 0 0-.067.523c0 1.293 1.087 2.345 2.423 2.345 1.335 0 2.422-1.052 2.422-2.345 0-1.294-1.087-2.346-2.422-2.346z"
        fill="#FFF"
      />
    </g>
  </svg>
);

Share.displayName = 'ShareIcon';

Share.defaultProps = { fill: '#263850', height: 32, width: 32 };
