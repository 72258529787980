import { Portal } from 'components/Portal';
import React, { ReactNode, useState, useRef, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { usePopper } from 'react-popper';

import { Arrow } from './Arrow';
import { Tip, TriggerBox } from './Tip';

interface IToolTipPros {
  children: ReactNode;
  className?: string;
  defaultVisible?: boolean;
  isFlexible?: boolean;
  placement?: 'top' | 'left' | 'bottom' | 'right' | 'auto';
  tipPadding?: string;
  trigger: ReactNode;
}

export function ToolTip({
  trigger,
  placement = 'right',
  children,
  defaultVisible = false,
  isFlexible,
  tipPadding,
}: IToolTipPros) {
  const {
    breakpoint: { tablet },
  } = useContext(ThemeContext);

  const [visible, setVisible] = useState<boolean>(defaultVisible);
  const [referenceElement, setReferenceElement] = React.useState<any>(useRef());
  const [popperElement, setPopperElement] = React.useState(null);
  const [arrowElement, setArrowElement] = React.useState(null);
  const mediaQuery: MediaQueryList | undefined = window
    ? window.matchMedia(`(max-width: ${tablet})`)
    : undefined;

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: mediaQuery?.matches ? 'auto' : placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 16,
        },
      },
    ],
  });

  const onClick = (evt: React.SyntheticEvent<HTMLSpanElement>) => {
    evt.preventDefault();
    evt.stopPropagation();

    setVisible(true);
  };

  const onMouseEnter = () => {
    setVisible(true);
  };

  const onMouseLeave = () => {
    setVisible(false);
  };

  return (
    <TriggerBox
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={setReferenceElement}
    >
      {trigger}

      {visible ? (
        <Portal id="resubmit-tip">
          <Tip
            ref={setPopperElement}
            style={styles.popper}
            isFlexible={isFlexible}
            tipPadding={tipPadding}
            {...attributes.popper}
          >
            {children}
            <Arrow
              ref={setArrowElement}
              style={styles.arrow}
              placement={
                attributes?.popper && attributes.popper['data-popper-placement']
              }
            />
          </Tip>
        </Portal>
      ) : null}
    </TriggerBox>
  );
}
