import { Type, ISetDossierStepSubmit, IFillFinished } from './actionTypes';

export const setDossierStepSubmit = (
  payload: ISetDossierStepSubmit['payload']
): ISetDossierStepSubmit => ({
  type: Type.DOSSIER_STEP_SUBMIT,
  payload,
});

export const fillUserDossierFinished = (): IFillFinished => ({
  type: Type.FILL_USER_DOSSIER_FINISHED,
  payload: {},
});
