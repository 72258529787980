import { createSelector } from 'reselect';
import { makeSelectSelectPersonalBlockCacheRoles } from './personalInfo';

export const makeSelectPersonalInfoBlockData = () =>
  createSelector(
    [
      makeSelectSelectPersonalBlockCacheRoles()
    ],
    (
      roles
    ) => ({
      personalInfo: {
        roles
      },
    }),
  );
