import React, { FC, useEffect, useLayoutEffect, useRef } from 'react';

import { useGoogleTagManagerConfig } from './context';
import { useGoogleTagManagerInit, userLoginFlow } from './events';

export const GoogleTagManagerInitialize: FC = ({ children }) => {
  const shouldTrigger: any = useRef(true);
  const triggerUseGoogleTagManagerInit = useGoogleTagManagerInit();
  const { googleTagManagerId } = useGoogleTagManagerConfig();

  useLayoutEffect(() => {
    if (shouldTrigger) {
      shouldTrigger.current = false;
      const timeout = setTimeout(() => {
        triggerUseGoogleTagManagerInit({ gtmId: googleTagManagerId });
        clearTimeout(timeout);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export const GoogleTagManagerUserLoginFlow: FC = ({ children }) => {
  const shouldTrigger: any = useRef(true);
  const triggerUserLoginFlow = userLoginFlow();

  useEffect(() => {
    if (shouldTrigger) {
      shouldTrigger.current = false;
      triggerUserLoginFlow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};
