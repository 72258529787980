import styled from 'styled-components';

export const H4 = styled.h4`
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  text-align: center;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.text.h4.color};
`;
