import React from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { FileType } from 'components/common/FilesHandler';

const FilePdf: React.FunctionComponent<IconProps> = ({
  width,
  height,
  isFilled,
}) => {
  return (
    <svg
      width="27"
      height="31"
      viewBox="0 0 27 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.86992 0.294922H17.7578L26.1791 8.68347V28.344C26.1791 29.3771 25.3414 30.2139 24.3092 30.2139H1.86992C0.837686 30.2139 0 29.3771 0 28.344V2.16484C0 1.13174 0.837783 0.294922 1.86992 0.294922Z"
        fill="#E65069"
      />
      <path
        opacity="0.182254"
        d="M26.4462 8.84319H19.8037C18.7517 8.84319 17.8979 7.99031 17.8979 6.93936V0.294922L26.4462 8.84319Z"
        fill="black"
      />
      <path
        d="M19.3009 14.4116C19.6144 14.4116 19.7679 14.1511 19.7679 13.8986C19.7679 13.6372 19.6078 13.3848 19.3009 13.3848H17.5153C17.1662 13.3848 16.9716 13.6604 16.9716 13.9646V18.1476C16.9716 18.5205 17.1943 18.7274 17.4956 18.7274C17.7951 18.7274 18.0188 18.5205 18.0188 18.1476V16.9995H19.0988C19.4338 16.9995 19.6013 16.7381 19.6013 16.4785C19.6013 16.2243 19.4338 15.9718 19.0988 15.9718H18.0188V14.4116H19.3009ZM13.2657 13.3848H11.9592C11.6045 13.3848 11.3528 13.6167 11.3528 13.961V18.1512C11.3528 18.5785 11.7149 18.7123 11.9741 18.7123H13.3452C14.9679 18.7123 16.0394 17.6945 16.0394 16.1234C16.0385 14.4624 15.0297 13.3848 13.2657 13.3848ZM13.3284 17.6793H12.532V14.4178H13.2498C14.3363 14.4178 14.8089 15.1128 14.8089 16.0717C14.8089 16.9692 14.3446 17.6793 13.3284 17.6793ZM8.54252 13.3848H7.24828C6.88235 13.3848 6.67833 13.6149 6.67833 13.9646V18.1476C6.67833 18.5205 6.91227 18.7274 7.22668 18.7274C7.5411 18.7274 7.77504 18.5205 7.77504 18.1476V16.9263H8.58639C9.58772 16.9263 10.4141 16.2501 10.4141 15.1626C10.4142 14.0985 9.61686 13.3848 8.54252 13.3848ZM8.52103 15.9451H7.77514V14.367H8.52103C8.98146 14.367 9.27437 14.7095 9.27437 15.1565C9.2734 15.6026 8.98146 15.9451 8.52103 15.9451Z"
        fill="white"
      />
    </svg>
  );
};

const FilePng: React.FunctionComponent<IconProps> = ({
  width,
  height,
  isFilled,
}) => {
  return (
    <svg
      width="27"
      height="31"
      viewBox="0 0 27 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.86992 0.614258H17.7578L26.1791 9.00281V28.6633C26.1791 29.6964 25.3414 30.5332 24.3092 30.5332H1.86992C0.837686 30.5332 0 29.6964 0 28.6633V2.48417C0 1.45107 0.837783 0.614258 1.86992 0.614258Z"
        fill="#7FCC45"
      />
      <path
        d="M6.08923 15.1079C6.08923 14.882 6.26635 14.6348 6.55384 14.6348H8.13503C9.02403 14.6348 9.82489 15.2303 9.82489 16.3725C9.82489 17.454 9.02403 18.0556 8.13503 18.0556H6.99106V18.9591C6.99106 19.2611 6.80111 19.4314 6.55384 19.4314C6.32795 19.4314 6.08923 19.2611 6.08923 18.9591V15.1079ZM6.99106 15.4972V17.2008H8.13418C8.59194 17.2008 8.95472 16.7969 8.95472 16.3725C8.95472 15.8934 8.59194 15.4972 8.13418 15.4972H6.99106Z"
        fill="white"
      />
      <path
        d="M10.6968 15.1754C10.6968 14.9281 10.7516 14.6894 11.1075 14.6894C11.3531 14.6894 11.4087 14.751 11.5927 14.9281L13.8566 17.7611V15.107C13.8566 14.8811 14.0543 14.6338 14.2879 14.6338C14.5343 14.6338 14.773 14.8811 14.773 15.107V18.9581C14.773 19.2601 14.5882 19.3748 14.4102 19.4304C14.1715 19.4304 14.0543 19.3757 13.8566 19.1917L11.5927 16.3177V18.959C11.5927 19.261 11.4078 19.4313 11.1614 19.4313C10.915 19.4313 10.696 19.261 10.696 18.959V15.1754H10.6968Z"
        fill="white"
      />
      <path
        d="M19.9196 18.9045C19.4336 19.3075 18.8381 19.4795 18.2366 19.4795C16.7992 19.4795 15.7801 18.6581 15.7801 17.0299C15.7801 15.648 16.8548 14.5742 18.2974 14.5742C18.8381 14.5742 19.4328 14.759 19.8572 15.1766C20.2747 15.5864 19.6723 16.1956 19.2625 15.8337C19.0092 15.5864 18.6533 15.4025 18.2974 15.4025C17.47 15.4025 16.67 16.0656 16.67 17.0299C16.67 18.0429 17.3399 18.6581 18.2366 18.6581C18.6533 18.6581 19.0092 18.5349 19.2625 18.3501V17.5099H18.2366C17.6274 17.5099 17.689 16.6739 18.2366 16.6739H19.6047C19.858 16.6739 20.0899 16.8664 20.0899 17.0786V18.5358C20.0899 18.6581 20.036 18.7736 19.9196 18.9045Z"
        fill="white"
      />
      <path
        opacity="0.182254"
        d="M26.4462 9.16253H19.8037C18.7517 9.16253 17.8979 8.30965 17.8979 7.2587V0.614258L26.4462 9.16253Z"
        fill="black"
      />
    </svg>
  );
};

const FileJpg: React.FunctionComponent<IconProps> = ({
  width,
  height,
  isFilled,
}) => {
  return (
    <svg
      width="27"
      height="31"
      viewBox="0 0 27 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.86992 0.595703H17.7578L26.1791 8.98425V28.6447C26.1791 29.6778 25.3414 30.5146 24.3092 30.5146H1.86992C0.837686 30.5146 0 29.6778 0 28.6447V2.46562C0 1.43252 0.837783 0.595703 1.86992 0.595703Z"
        fill="#50BEE8"
      />
      <path
        d="M8.12279 15.3732C8.12279 14.8079 9.01119 14.8079 9.01119 15.3732V18.03C9.01119 19.0867 8.50641 19.7261 7.34291 19.7261C6.77083 19.7261 6.29381 19.5704 5.87653 19.0329C5.53329 18.6223 6.1794 18.0309 6.52938 18.4608C6.81205 18.8108 7.11492 18.8915 7.41021 18.8781C7.78627 18.8646 8.1169 18.6955 8.12363 18.03V15.3732H8.12279Z"
        fill="white"
      />
      <path
        d="M10.1933 15.3736C10.1933 15.1515 10.3682 14.9092 10.6509 14.9092H12.2048C13.0797 14.9092 13.8672 15.4947 13.8672 16.617C13.8672 17.6804 13.0797 18.2727 12.2048 18.2727H11.0817V19.1611C11.0817 19.4572 10.8932 19.6246 10.6509 19.6246C10.4288 19.6246 10.1933 19.4572 10.1933 19.1611V15.3736ZM11.0817 15.7564V17.4322H12.2048C12.6557 17.4322 13.0124 17.0343 13.0124 16.617C13.0124 16.1467 12.6557 15.7564 12.2048 15.7564H11.0817Z"
        fill="white"
      />
      <path
        d="M18.6709 19.1065C18.1931 19.5036 17.6075 19.6719 17.0161 19.6719C15.6027 19.6719 14.6008 18.8651 14.6008 17.2633C14.6008 15.9054 15.6574 14.8496 17.0758 14.8496C17.6075 14.8496 18.1922 15.0305 18.6095 15.441C19.0192 15.844 18.4278 16.443 18.0248 16.0871C17.7758 15.8449 17.4258 15.6631 17.0758 15.6631C16.2623 15.6631 15.4757 16.3151 15.4757 17.2633C15.4757 18.2594 16.1344 18.8651 17.0161 18.8651C17.4258 18.8651 17.7758 18.7431 18.0248 18.5622V17.7352H17.0161C16.4171 17.7352 16.4777 16.9141 17.0161 16.9141H18.3613C18.6104 16.9141 18.8383 17.1026 18.8383 17.3112V18.744C18.8383 18.8651 18.7853 18.9778 18.6709 19.1065Z"
        fill="white"
      />
      <path
        opacity="0.182254"
        d="M26.4462 9.14397H19.8037C18.7517 9.14397 17.8979 8.2911 17.8979 7.24014V0.595703L26.4462 9.14397Z"
        fill="black"
      />
    </svg>
  );
};

FileJpg.displayName = 'FileJpg';

FileJpg.defaultProps = { height: '33', width: '27' };

export const File = ({ type }: { type: string }) => {
  switch (type) {
    case FileType.JPG:
    case FileType.JPEG:
      return <FileJpg />;
    case FileType.PNG:
      return <FilePng />;
    case FileType.PDF:
      return <FilePdf />;
    default:
      return <FileJpg />;
  }
};
