import { isImmutable } from 'immutable';
import isString from 'lodash/isString';

export const fromImmutable = (_: any, originalValue: any) => {
  return isImmutable(originalValue) ? originalValue.toJS() : originalValue;
};

export const removeAtFromString = (str: string | null | undefined = '') => {
  return str ? str.replace(/@/g, '') : '';
};

export const removeLineBreaks = (value?: string) => {
  return isString(value) ? value.replaceAll(/[\n]+/g, ' ') : value;
};
