import {  Middleware } from 'redux';
import { TrackEventsEmitter } from './TrackEventsEmitter';
import { events } from './evenTypes';

export const trackingMiddleware: Middleware = () => next => action => {
  const { type, payload } = action as any;
  const userEvent = events[type];
  const emitter = TrackEventsEmitter.getInstance();

  if (emitter.hasUserCallback() && userEvent) {
    emitter.trackEvents(userEvent, payload);
  }

  return next(action);
};
