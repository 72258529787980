import React, { FC } from 'react';
import styled from 'styled-components';

import { Content, Footer, Header, Layout } from 'components/common/Layout';
import { H1, H3, H4 } from 'components/UI';
import { renderButtons } from './renderButtons';
import { ICommonStubProps } from './types';

export const CommonStub: FC<ICommonStubProps> = ({
  stageSubtitle,
  stageTitle,
  titleColor,
  body = {},
  buttons = [],
  links = [],
  children,
}) => {
  const { Icon, title, message, dataAqaid } = body;
  return (
    <Layout>
      <Header center>
        <Title>{stageTitle}</Title>
        <SubTitle color={titleColor}>{stageSubtitle}</SubTitle>
      </Header>
      {children || (
        <Main>
          {Icon && <Icon />}
          {title && <MessageTitle data-aqaid={dataAqaid}>{title}</MessageTitle>}
          {message && <Message>{message}</Message>}
        </Main>
      )}
      <Footer>{renderButtons(buttons, links)}</Footer>
    </Layout>
  );
};

const Main = styled(Content)`
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 26px;
  
  > svg {
    margin-bottom: 20px;
  }
`;

const Title = styled(H1)`
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    text-align: center;
  }
`;

const SubTitle = styled(H3)`
  ${({ color }) => color && `color: ${color}`}
`;

const MessageTitle = styled(H4)`
  margin-bottom: 16px;
`;

const Message = styled.p`
  max-width: 450px;
  font-size: 16px;
  line-height: 1.5;
  color: #949aa1;
  white-space: pre-wrap;
`;
