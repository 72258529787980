import { call, select } from 'redux-saga/effects';

import { Stage } from 'system/Stages/types';
import { jsonRPC } from 'utils/request';
import { getDesiredDecision } from 'utils/devtools';
import { makeSelectApiUrl, makeSelectWorkingMode } from 'system/Settings/selectors';

export function* saveStage(params: any = {}) {
  const { stageId: stage } = params;
  const desiredDecision = getDesiredDecision();
  const workingMode = yield select(makeSelectWorkingMode());
  const api = yield select(makeSelectApiUrl());

  const response = yield call(jsonRPC, {
    namespace: 'va',
    method: 'saveUserDossierStage',
    params: {
      ...(stage !== Stage.CFD && desiredDecision ? { desiredDecision } : {}),
      ...params,
      workingMode,
    },
    api,
  });

  return response;
}

export function* saveBlock(params: any = {}) {
  const { stageId: stage } = params;
  const desiredDecision = getDesiredDecision();
  const workingMode = yield select(makeSelectWorkingMode());
  const api = yield select(makeSelectApiUrl());

  const response = yield call(jsonRPC, {
    namespace: 'va',
    method: 'saveVerificationBlock',
    params: {
      ...(stage !== Stage.CFD && desiredDecision ? { desiredDecision } : {}),
      ...params,
      workingMode,
    },
    api,
  });

  return response;
}

export function* blockCacheRequest(params: any = {}) {
  const api = yield select(makeSelectApiUrl());

  const response = yield call(jsonRPC, {
    namespace: 'va',
    method: 'blockCache',
    params,
    api,
  });

  return response;
}

export function* jsonRPCApiCall({ namespace = 'va', method, params = {} }: any) {
  const api = yield select(makeSelectApiUrl());

  const response = yield call(jsonRPC, {
    namespace,
    method,
    params,
    api,
  });

  return response;
}

export type SubmitEsignature = {
  email: string;
  signerEmails: { email: string; name: string }[];
};

export function* submitEsignature(params: any = {}) {
  const response = yield jsonRPCApiCall({ method: 'submitEsignature', params });

  return response;
}
