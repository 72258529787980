import React, { ReactNode } from 'react';
import { TRACK } from 'system/Analytics/types';

export enum BUTTON_TYPE {
  primary = 'primary',
  regular = 'regular',
}
export interface IStubButtonMods {
  [key: string]: string;
  type: BUTTON_TYPE;
}

export interface IStubLinksMods {
  [key: string]: string;
}

export interface IStubButton {
  mods: IStubButtonMods;
  clickHandler?: () => void;
  text: ReactNode;
  event?: TRACK;
}

export interface IStubLink {
  mods?: IStubLinksMods;
  fallback: string;
  text: ReactNode;
}

export interface ICommonStubProps {
  stageSubtitle?: ReactNode;
  stageTitle: ReactNode;
  titleColor?: string;
  body?: {
    Icon?: React.FC;
    title?: ReactNode;
    message?: ReactNode;
    dataAqaid?: string;
    clickHandler?: () => void;
  };
  stageId?: string;
  stubMods?: string;
  buttons?: IStubButton[];
  links?: IStubLink[];
}
