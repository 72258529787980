import { createSelector } from 'reselect';
import { Map } from 'immutable';

import { Field } from 'utils/types/shared/enums';
import { Block } from 'system/Stages/types';
import {
  selectCorporateBlock,
  selectCorporateBlockStep,
  SubStore,
} from '../selectors';


export const makeSelectAccountActivity = () =>
  createSelector(
    selectCorporateBlock(Block.ACCOUNT_ACTIVITY),
    (state: Map<string, any>) => {
      return state.get('activity', Map()) || Map({});
    }
  );

export const makeSelectPrimaryPurpose = () => {
  return createSelector(makeSelectAccountActivity(), (state) => {
    return state.get(Field.PRIMARY_PURPOSE_CORPORATE, '');
  });
};

export const makeSelectPrimaryPurposeDetailed = () => {
  return createSelector(makeSelectAccountActivity(), (state) =>
    state.get(Field.PRIMARY_PURPOSE_DETAILED_CORPORATE, '')
  );
};

export const makeSelectPlannedMonthlyDepositVolume = () => {
  return createSelector(makeSelectAccountActivity(), (state) =>
    state.get(Field.PLANNED_MONTHLY_DEPOSIT_VOLUME, '')
  );
};

export const makeSelectExpectedMonthlyDepositOperations = () => {
  return createSelector([makeSelectAccountActivity()], (state) =>
    state.get(Field.EXPECTED_MONTHLY_DEPOSIT_OPERATIONS, '')
  );
};

export const makeSelectPlannedMonthlyWithdrawalVolume = () => {
  return createSelector([makeSelectAccountActivity()], (state) =>
    state.get(Field.PLANNED_MONTHLY_WITHDRAWAL_VOLUME, '')
  );
};

export const makeSelectAccountActivityStepMeta = (stepId: string) =>
  createSelector(
    selectCorporateBlockStep(Block.ACCOUNT_ACTIVITY, SubStore.META, stepId),
    (value: any) => value
  );

export const makeSelectAccountActivityMeta = () =>
  createSelector(
    makeSelectAccountActivityStepMeta('activity', ),
    (value: any) => value || Map({})
  );

export const makeSelectPrimaryPurposeMeta = () => {
  return createSelector(makeSelectAccountActivityMeta(), (state) => {
    return state.get(Field.PRIMARY_PURPOSE_CORPORATE, Map());
  });
};

export const makeSelectPrimaryPurposeDetailedMeta = () => {
  return createSelector(makeSelectAccountActivityMeta(), (state) =>
    state.get(Field.PRIMARY_PURPOSE_DETAILED_CORPORATE, Map())
  );
};

export const makeSelectPlannedMonthlyDepositVolumeMeta = () => {
  return createSelector(makeSelectAccountActivityMeta(), (state) =>
    state.get(Field.PLANNED_MONTHLY_DEPOSIT_VOLUME, Map())
  );
};

export const makeSelectExpectedMonthlyDepositOperationsMeta = () => {
  return createSelector([makeSelectAccountActivityMeta()], (state) =>
    state.get(Field.EXPECTED_MONTHLY_DEPOSIT_OPERATIONS, Map())
  );
};

export const makeSelectPlannedMonthlyWithdrawalVolumeMeta = () => {
  return createSelector([makeSelectAccountActivityMeta()], (state) =>
    state.get(Field.PLANNED_MONTHLY_WITHDRAWAL_VOLUME, Map())
  );
};
