import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import MediaQueries from 'react-media';

export interface IMediaProps {
  children?: React.ReactNode;
}

export interface IMediaMatches {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
}

export function Media({ children }: IMediaProps) {
  const { breakpoint: { mobile, tablet, desktop }} = useContext(ThemeContext);
  return (
    <MediaQueries
      queries={{
        mobile: { maxWidth: mobile },
        tablet: { maxWidth: tablet },
        desktop: { minWidth: desktop },
      }}
    >
      {children}
    </MediaQueries>
  );
}

export function Mobile({ children }: IMediaProps) {
  return <Media>{({ mobile }: IMediaMatches) => (mobile ? children : null)}</Media>
}

export function Tablet({ children }: IMediaProps) {
  return <Media>{({ tablet }: IMediaMatches) => (tablet ? children : null)}</Media>
}

export function Desktop({ children }: IMediaProps) {
  return <Media>{({ desktop }: IMediaMatches) => (desktop ? children : null)}</Media>
}
