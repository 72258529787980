import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';

import { MerchantConfigProvider } from 'merchants';

import { RoutesBuilder } from 'containers/Routes';
import { InjectGlobalStyles } from 'system/InjectGlobalStyles';
import { withMixpanel, types } from 'system/Analytics';

import { withLog } from 'HOC/withLog';

import { Navbar } from 'containers/Navbar';
import { ErrorBoundary } from 'containers/ErrorBoundary/ErrorBoundarySentry';

import { Loader } from 'components/common';
import { FingerprintInit } from 'system/Fingerprint';
import { getRoutes } from 'system/Routing/routing';
import {
  GoogleTagManagerProvider,
  GoogleTagManagerInitialize,
  GoogleTagManagerUserLoginFlow,
} from 'system/GoogleTagManager';

const Main = withMixpanel(({ processMixpanel, hasHeader, children }: any) => {
  useEffect(() => {
    processMixpanel(types.TRACK.START_SESSION);
    return () => {
      processMixpanel(types.TRACK.END_SESSION);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary>
      {hasHeader && <Navbar />}
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </ErrorBoundary>
  );
});

export function Root({ store, history, config, routes }: any) {
  const Application = withLog(() => {
    return (
      <>
        <Main hasHeader={config?.features?.header}>
          <RoutesBuilder routes={getRoutes(routes, config?.stages)} />
        </Main>
        <InjectGlobalStyles />
      </>
    );
  });
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MerchantConfigProvider config={config}>
          <GoogleTagManagerProvider>
            <GoogleTagManagerInitialize>
              <GoogleTagManagerUserLoginFlow>
                {config?.features?.fingerprint ? (
                  <FingerprintInit>
                    <Application />
                  </FingerprintInit>
                ) : (
                  <Application />
                )}
              </GoogleTagManagerUserLoginFlow>
            </GoogleTagManagerInitialize>
          </GoogleTagManagerProvider>
        </MerchantConfigProvider>
      </ConnectedRouter>
    </Provider>
  );
}

