import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const UtilityBill: React.FunctionComponent<IconProps> = ({ width = 104, height = 126 }) => {
  const {
    icons: {
      docs: {
        utilityBill: { fill, fill2, fill3 },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M16 84h36v-2H16zM16 90h36v-2H16zM16 96h17.053v-2H16z"
          fill={fill}
        />
        <path d="M16 110h72v-2H16z" fill={fill2} />
        <path d="M68 82h20v14H68z" fill={fill} />
        <rect
          height="124"
          rx="4"
          stroke={fill}
          strokeWidth="2"
          width="102"
          x="1"
          y="1"
        />
        <path
          d="M16 38h72v-2H16zM16 44h72v-2H16zM16 50h34.56v-2H16zM16 64h72v-2H16zM16 70h72v-2H16z"
          fill={fill}
        />
        <path
          d="M16 16h3.333c1.518 0 2.62.216 3.306.648.686.432 1.03 1.12 1.03 2.062 0 .64-.15 1.165-.451 1.575-.3.41-.7.656-1.197.74v.073c.678.151 1.168.434 1.468.85.3.414.45.966.45 1.655 0 .976-.352 1.738-1.058 2.285-.705.547-1.664.82-2.875.82H16V16zm2.27 4.24h1.319c.615 0 1.06-.094 1.337-.285.275-.19.413-.505.413-.945 0-.41-.15-.704-.45-.882-.3-.179-.775-.268-1.425-.268h-1.193v2.38zm0 1.802v2.791h1.48c.625 0 1.086-.12 1.384-.359.298-.24.447-.605.447-1.099 0-.888-.635-1.333-1.904-1.333H18.27zm7.808 4.666V16h2.27v10.708h-2.27zm4.966 0V16h2.27v8.833h4.344v1.875h-6.614zm8.474 0V16h2.27v8.833h4.344v1.875h-6.614z"
          fill={fill3}
        />
      </g>
    </svg>
  );
}
