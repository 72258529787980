import { IUploadParams } from 'system/Dossier/Upload/sagas';
import {
  IEnhancedStepInitialSet,
  IEnhancedBlockFailed,
  IEnhancedStepSet,
  IEnhancedUploadFileError,
  IEnhancedUploadFileSuccess,
  IEnhancedSubmitRequest,
  IEnhancedSubmitSuccess,
  IEnhancedSubmitFailed,
  IEnhancedSetMeta,
  Type,
  IEnhancedUploadFileRequest,
  IEnhancedSourceAddFile,
  IEnhancedSourceRemoveFile,
} from './actionTypes';

export const setEnhancedInitialStep = ({
  stepId,
  fields,
}: IEnhancedStepInitialSet['payload']): IEnhancedStepInitialSet => ({
  type: Type.ENHANCED_STEP_INITIAL_SET,
  payload: { stepId, fields },
});

export const setEnhancedStep = ({
  stepId,
  fields,
}: IEnhancedStepSet['payload']): IEnhancedStepSet => ({
  type: Type.ENHANCED_STEP_SET,
  payload: { stepId, fields },
});

export const setEnhancedMeta = (
  payload: IEnhancedSetMeta['payload']
): IEnhancedSetMeta => ({
  type: Type.ENHANCED_META_SET,
  payload,
});

export const failedEnhanceBlock = (err: Error): IEnhancedBlockFailed => ({
  type: Type.ENHANCED_BLOCK_FAILED,
  payload: err,
});

export const requestUploadFiles = ({
  files,
}: IEnhancedUploadFileRequest['payload']): IEnhancedUploadFileRequest => {
  return {
    type: Type.ENHANCED_UPLOAD_FILES_REQUEST,
    payload: { files },
  };
};

export const successUploadFiles = (): IEnhancedUploadFileSuccess => ({
  type: Type.ENHANCED_UPLOAD_FILES_SUCCESS,
  payload: {},
});

export const errorUploadFiles = (error: Error[]): IEnhancedUploadFileError => ({
  type: Type.ENHANCED_UPLOAD_FILES_ERROR,
  payload: error,
});

export const addFiles = (
  source = '',
  files: IUploadParams[]
): IEnhancedSourceAddFile => ({
  type: Type.ENHANCED_SOURCES_ADD_FILES,
  payload: { source, files },
});

export const removeFile = (
  source: string,
  index: number
): IEnhancedSourceRemoveFile => ({
  type: Type.ENHANCED_SOURCES_REMOVE_FILES,
  payload: { source, index },
});

export const requestSubmitEnhanced = (): IEnhancedSubmitRequest => ({
  type: Type.ENHANCED_SUBMIT_REQUEST,
  payload: {},
});

export const successSubmitEnhanced = (): IEnhancedSubmitSuccess => ({
  type: Type.ENHANCED_SUBMIT_SUCCESS,
  payload: {},
});

export const failedSubmitEnhanced = (err: Error[]): IEnhancedSubmitFailed => ({
  type: Type.ENHANCED_SUBMIT_FAILED,
  payload: err,
});
