export const palette: { [key: string]: string } = {
    c1: '#26374c',
    c2: '#344563',
    c3: '#fff',
    c4: '#0c87f2',
    c5: '#e65069',
    c6: '#0abf64',
    c7: '#667c99',
    c8: '#bdc8d9',
    c9: '#e5e9ed',
    c10: '#e3e7ee',
    c11: '#c96866',
    c12: '#f5f7fa',
    c13: '#fcfcfc',
    c14: '#dbe1ea',
    c15: '#ffffff33',
    c16: '#E2E7EE',
    c17: '#afb7c5',
    c18: '#04BCC6',
};
