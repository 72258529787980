import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { ITheme } from 'merchants/types/IThemeStyles';
import get from 'lodash/get';
import { setColorByState } from 'utils/styledComponents/helpers';

export interface ILinkProps {
  children: React.ReactNode;
  to: string;
  danger?: boolean;
  action?: boolean;
  disabled?: boolean;
  type?: string;
  className?: string;
  onClick?: (evt: React.SyntheticEvent<HTMLElement>) => void;
  theme: ITheme;
}

export const Link = styled(
  ({ className, children, to = '', onClick, disabled}: ILinkProps) => {
    const isExternalUrl = /^https?:\/\//.test(to);

    if (disabled) {
      return <span className={className}>{children}</span>
    }

    return isExternalUrl ? (
      <a className={className} href={to} onClick={onClick} >
        {children}
      </a>
    ) : (
      <RouterLink className={className} onClick={onClick} to={to}>
        {children}
      </RouterLink>
    );
  }
)`
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.links.base.fontWeight};
  text-decoration: ${({ theme }) => theme.links.base.textDecoration};
  color: ${({ theme: { links: { base } }, danger, action }) => setColorByState(
    {
      default: base.color,
      danger:  get(base, 'danger.color'),
      action:  get(base, 'action.color'),
    },
    { danger, action }
  )};
  line-height: 1;
  transition: opacity 0.25s;
  opacity: ${({ disabled }) => disabled ? '0.4' : '1'};
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};

  > {
    :not(:last-child) {
      margin-right: 8px;
    }
  }

  :hover {
    opacity: 0.9;
    text-decoration: ${({ theme }) => theme.links.base.hover.textDecoration};
    color: ${({ theme: { links: { base } }, danger }) => setColorByState(
      {
        default: get(base, 'hover.color'),
        danger:  get(base, 'hover.danger.color'),
      },
      { danger }
    )};
  }
`;
