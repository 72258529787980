/* eslint-disable */
import {
  digits,
  cyrillic,
  letters,
  quotes,
  slash,
  slashes,
  space,
  punctuation,
  dot,
  dash,
  star,
  verticalSlash,
  brackets,
  latin,
  plus,
  latinRegular,
} from '../definitions';
import { create } from '../helpers';

export const SSN = /^(?!123456789)(?!1{9}|2{9}|3{9}|4{9}|5{9}|6{9}|7{9}|8{9}|9{9})((?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4})$/;
export const TIN_DIGITS = /^\d+$/;
export const TIN = create(latin, digits, slashes, dot, dash);
export const userName = create(latin, space, quotes, dash);
export const otherNames = create(latin, digits, space, quotes, dash, dot);
export const serialNumber = create(latin, digits, space, slashes, dot, dash, star);
export const zipCode = create(latin, digits, space, slashes, dot, dash);
export const city = create(latinRegular, digits, space, slash, quotes, punctuation);
export const street = create(
  latinRegular,
  digits,
  space,
  slashes,
  quotes,
  punctuation,
  verticalSlash
);
export const apartment = create(latin, digits, space, slashes, quotes, punctuation, verticalSlash);
export const companyName = create(latin, digits, space, quotes, dot, dash);
export const detailed = create(latin, digits, space, slashes, quotes, punctuation, verticalSlash);
export const detailedLatinDigitNumber = create(
  latin,
  digits,
  space,
  slashes,
  quotes,
  punctuation,
  verticalSlash
);
export const bankName = create(latin, digits, space, slashes, quotes, punctuation, verticalSlash);
export const address = create(latin, digits, space, slashes, quotes, punctuation, verticalSlash);
export const detailedNoDigits = create(letters, space, slashes, quotes, punctuation, verticalSlash);
export const regNumber = create(latin, digits, slashes, dash, dot);
export const VAT = create(latin, digits, slashes, dash, dot);
export const phoneNumber = create(digits);
//prettier-ignore
export const phoneCode = create(digits, '\+', dash);
export const code = create(digits);
export const digitsAndDot = create(digits, dot);
export const twitter = /^@?([a-zA-Z\d_]+)?$/;
export const url = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[^\s]+([\-\.]{1}[^\s]+)*\.[^\s]{1,10}(:[0-9]{1,5})?(\/.*)?$/;
export const swift = /[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?/;
export const email = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
export const percent = /^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)$/;
export const jurisdictionCode = create(latin);
export const regionOfBusiness = create(latin, slashes, dot, quotes, punctuation, space);

// aliases
export const bankAddress = address;
export const other = address;

export const matches = {
  address,
  SSN,
  TIN_DIGITS,
  TIN,
  city,
  street,
  userName,
  zipCode,
  detailed,
  apartment,
  companyName,
  serialNumber,
  detailedNoDigits,
  phoneCode,
  phoneNumber,
  bankName,
  bankAddress,
  twitter,
  code,
  url,
  regNumber,
  VAT,
  otherNames,
  other,
  swift,
  detailedLatinDigitNumber,
  email,
  percent,
  digitsAndDot,
  jurisdictionCode,
  regionOfBusiness,
};
