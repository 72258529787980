import React from 'react';
import { Route } from 'react-router-dom';
import { Path } from 'history';
import { IRouteRoutes } from 'system/Routing/interfaces';

interface RouteItemProps {
  routes?: IRouteRoutes;
  path: Path;
  exact?: boolean;
  component: any;
  handlePrevStep?: () => void;
  exitOnSubmit?: () => void;
}

export function RouteItem({
  routes: childRoutes,
  path,
  exact,
  component: Component,
  handlePrevStep,
  exitOnSubmit,
}: RouteItemProps) {
  return (
    <Route
      path={path}
      exact={exact}
      render={() => (
        <Component
          routes={childRoutes}
          handlePrevStep={handlePrevStep}
          exitOnSubmit={exitOnSubmit}
        />
      )}
    />
  );
}
