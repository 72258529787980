import { fromJS } from 'immutable';

import { Type, ActionType } from './actionTypes';

export const defaultState = fromJS({
  readyState: 3,
  uploadId: null,
  wltfsToken: null
});

export const reducer = (state = defaultState, { type, payload }: ActionType) => {
  switch (type) {
    case Type.QR_SET_READY_STATE: {
      return state.set('readyState', payload)
    }
    case Type.QR_SET_UPLOAD_ID: {
      return state.set('uploadId', payload)
    }
    case Type.QR_SET_WLTFS_TOKEN: {
      return state.set('wltfsToken', payload)
    }
    default:
      return state;
  }
};
