import React from 'react';
import { withRouter, match } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { History, Location } from 'history';
import compose from 'lodash/fp/compose';

import { ILocalizationProps, withLocalization } from 'system/Localization';
import { navigateIndex } from 'system/Routing/actions';

import {
  BUTTON_TYPE,
  ICommonStubProps,
} from 'containers/Stubs/CommonStub/types';
import { CommonStub } from 'containers/Stubs/CommonStub';

import { Stub } from 'components/UI/Icon';
import { StageId } from 'system/Stages/types';

interface ISubmittedStub extends ILocalizationProps {
  location: Location;
  history: History;
  match: match<{ stageId: StageId; stubId: string }>;
  fallback?: string;
}

export const PendingStub = compose(withRouter, withLocalization)(({
  translate,
  match: {
    params: { stageId, stubId },
  },
}: ISubmittedStub) => {
  const dispatch = useDispatch();

  const handleLocation = () => {
    dispatch(navigateIndex( { exit: true }));
  };

  const contentProps: ICommonStubProps = {
    stageTitle: translate(`stage_title_${stageId}`),
    stageSubtitle: translate('information_notice'),
    buttons: [
      {
        clickHandler: handleLocation,
        text: translate('button_back_to_platform'),
        mods: { type: BUTTON_TYPE.primary },
      },
    ],
    body: {
      Icon: Stub.Clock,
      title: translate('stage_queue_message_corporate'),
      message: translate(`stub_message_${stubId}`),
    },
  };

  return <CommonStub {...contentProps} />;
})
