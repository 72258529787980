import { fromJS } from 'immutable';

import { ActionType, Type } from './actionTypes';

export const defaultState = fromJS({
  schema: {}, // read-only

  stageProgress: {
    stageId: null,
    index: 0,
    amount: 0,
    steps: [],
    prevStepId: null,
    currentStepId: null,
    nextStepId: null,
    blockId: null,
  },

  stagesRouteIds: {}, // editable
  stagesEntryUrls: {}, // read-only
  identityProgress: 0,
});

export function reducer(state = defaultState, action: ActionType) {
  const { type, payload } = action;

  switch (type) {
    case Type.SET_SCHEMA: {
      return state.set('schema', fromJS(payload));
    }

    case Type.SET_STAGE_ROUTE_IDS_GROUPED: {
      return state.set('stagesRouteIds', fromJS(payload));
    }

    case Type.SET_STAGE_ROUTE_IDS: {
      const { stageId, routeIds } = payload;
      return state.setIn(['stagesRouteIds', stageId], fromJS(routeIds));
    }

    case Type.SET_STAGES_ENTRY_URLS: {
      return state.set('stagesEntryUrls', fromJS(payload));
    }

    case Type.SET_STAGE_PROGRESS: {
      const stageProgress = state.get('stageProgress').merge(fromJS(payload));

      return state.set('stageProgress', stageProgress);
    }

    case Type.SET_IDENTITY_PROGRESS: {
      return state.set('identityProgress', payload);
    }
    default: {
      return state;
    }
  }
}
