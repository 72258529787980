import { put, select } from 'redux-saga/effects';
import merge from 'lodash/merge';
import { push } from 'connected-react-router';

import { getCorporateRouteProgress } from 'containers/Stage/utils/helpers';
import { Stage } from 'system/Stages/types';
import { makeSelectRouterParams } from 'system/Bootstrap/selectors';
import { qrCodeIsConnected } from 'system/Dossier/Upload/sagas/qr';
import { makeSelectStageProgress } from 'system/Dossier/selectors';
import { requestBlockCache, requestBlockSubmitCorporate } from 'stages/Corporate/actions';
import { joinByChar } from 'utils/helpers';
import { makeSelectOmittedSteps } from 'stages/Corporate/selectors';
import { IRoutingSetStepProgress, Type } from '../actionTypes';
import { routingSetStageProgress, setStageRouteIds } from '../actions';
import {
  makeSelectCorporateBlocksList,
  makeSelectCorporateBlockSteps,
} from '../selectors/corporate';
import { Routes } from '../Routes';

export function* handleCorporateStepProgress(action: IRoutingSetStepProgress) {
  const { payload: { nextStepId, omitPrevSteps = [], omitNextSteps = [] } = {} } = action;
  const isQrCodeConnected = yield qrCodeIsConnected();

  if (isQrCodeConnected) {
    return;
  }

  const routerParams = yield select(makeSelectRouterParams());
  if (!routerParams) {
    return;
  }

  const {
    params: { stageId, stepId: blockId, stubId: stepId },
  } = routerParams;

  const stepsList = yield select(makeSelectCorporateBlockSteps(blockId));
  const routeIds = yield select(makeSelectCorporateBlocksList());
  const omittedSteps = yield select(makeSelectOmittedSteps());

  yield put(
    setStageRouteIds({
      stageId: stageId as Stage,
      routeIds,
    })
  );

  const stageRouteProgress = getCorporateRouteProgress({
    stepsList,
    stepId,
    blockId,
    omitPrevSteps: [...omitPrevSteps],
    omitNextSteps: [...omitNextSteps, ...omittedSteps],
  });

  // mutation of stageRouteProgress for path with query (f.e. /upload?source=)
  yield put(routingSetStageProgress(merge(stageRouteProgress, { nextStepId })));
}

export function* handleCorporateNavigation(action: {
  type: Type;
  payload: { nextStepId?: string; cachePath?: string };
}) {
  const { nextStepId: nextId, cachePath } = action?.payload || {};
  const isQrCodeConnected = yield qrCodeIsConnected();

  if (isQrCodeConnected) {
    return;
  }
  const stageProgress = yield select(makeSelectStageProgress());

  const { prevStepId, nextStepId, blockId } = stageProgress.toJS();

  switch (action.type) {
    case Type.CORPORATE_NAVIGATE_NEXT:
      if (cachePath) {
        yield put(
          requestBlockCache({
            blockId: joinByChar(blockId),
            nextStepId: nextId,
            cachePath,
          })
        );
        return;
      }

      if (!nextStepId) {
        yield put(requestBlockSubmitCorporate({ blockId: joinByChar(blockId) }));
        return;
      }

      yield put(
        push(
          Routes.url({
            blockId,
            stageId: Stage.CORPORATE,
            stepId: nextId || nextStepId,
          })
        )
      );

      break;

    case Type.CORPORATE_NAVIGATE_BACK:
      if (!prevStepId) {
        yield put(push(Routes.url({ stageId: Stage.CORPORATE })));
        return;
      }
      yield put(push(Routes.url({ blockId, stageId: Stage.CORPORATE, stepId: prevStepId })));
      break;

    default:
      console.warn(
        `Unexpected action type in processingStageNavigation. Action type: ${action.type}`
      );
      yield put(push(Routes.root()));
      break;
  }
}

export function* navigateCorporateStep(action: any) {
  const { step, subStep } = action.payload;

  yield put(
    push(
      Routes.url({
        stageId: Stage.CORPORATE,
        blockId: step,
        stepId: subStep,
      })
    )
  );
}
