import React from 'react';
import styled from 'styled-components';

import { withLocalization, ILocalizationProps } from 'system/Localization';
import { StagesPreview } from 'containers/StagesPreview';
import { Layout, Content, Header, Footer } from 'components/common/Layout';
import { Feedback } from 'components/common/Feedback/Feedback';

import { Wrap as Slider } from 'components/common/Slider';

type IndexProps = ILocalizationProps;

function Index({ translate }: IndexProps) {
  return (
    <Page>
      <Layout>
        <Header>
          <Title>{translate('verify_your_identity', '')}</Title>
          <SubTitle>{translate('verification_sub_title')}</SubTitle>
        </Header>
      </Layout>
      <StagesPreview />
      <Layout>
        <Feedback />
        <Footer />
      </Layout>
    </Page>
  );
}

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.text.h1.color};
  margin-bottom: 20px;
`;

const SubTitle = styled.h4`
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.text.h3.color};
  font-weight: 500;
`;

const Page = styled.div`
  height: 100%;

  ${Slider} {
    margin-bottom: 56px;
  }

  ${Header} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 56px;
  }

  ${Content} {
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
  }
`;

export const IndexPage =  withLocalization(Index);
