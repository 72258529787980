import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const BankStatement: React.FunctionComponent<IconProps>= ({ width = 104, height = 126 }) => {
  const {
    icons: {
      docs: {
        blankStatement: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg height={height} viewBox={`0 0 ${width} ${height}`} width={width} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect height="124" rx="4" stroke={fill} strokeWidth="2" width="102" x="1" y="1" />
        <path d="M16 66h72v-2H16z" fill={fill} />
        <path d="M17 79h70v30H17z" stroke={fill} strokeWidth="2" />
        <path d="M18 90h68v-2H18zM18 100h68v-2H18z" fill={fill} />
        <path d="M56 78h2v32h-2zM74 78h2v32h-2zM16 72h72v-2H16z" fill={fill} />
        <g fill={fill}>
          <path d="M35.2 36.985c0 2.027-.733 3.656-2.2 4.886-1.465 1.23-3.518 1.966-6.157 2.209V48h-2.62v-3.844c-3.11-.064-5.839-.612-8.185-1.644v-5.05c1.11.55 2.445 1.034 4.007 1.454 1.561.421 2.954.67 4.178.746v-5.928l-1.281-.497c-2.524-.995-4.313-2.075-5.364-3.242C16.526 28.83 16 27.385 16 25.664c0-1.849.723-3.37 2.17-4.561 1.448-1.192 3.465-1.916 6.053-2.17v-2.927h2.62v2.85c2.92.127 5.559.714 7.917 1.76l-1.797 4.474a20.672 20.672 0 0 0-6.12-1.492v5.642c2.486.956 4.255 1.785 5.307 2.486 1.052.701 1.823 1.472 2.314 2.314.49.841.736 1.823.736 2.945zm-5.756.287c0-.536-.217-.988-.65-1.358-.434-.37-1.084-.752-1.951-1.147v4.761c1.734-.293 2.6-1.045 2.6-2.256zm-7.688-11.608c0 .56.195 1.023.583 1.386.39.364 1.017.736 1.884 1.119v-4.494c-1.645.242-2.467.905-2.467 1.989z" />
        </g>
        <g fill={fill}>
          <path d="M44 27h44v-2H44zM44 33h44v-2H44zM44 39h21.488v-2H44z" />
        </g>
      </g>
    </svg>
  );
};

BankStatement.displayName = 'BankStatementIcon';
