export enum Type {
  DICTS_GET = '@Dictionaries/DICTS_GET',
  DICTS_SET = '@Dictionaries/DICTS_SET',
  DICTS_FAILED = '@Dictionaries/DICTS_FAILED',
  UNSHIFT_COUNTRY_DICT_BY_GEO_LOCATION = '@Dictionaries/UNSHIFT_COUNTRY_DICT_BY_GEO_LOCATION'
}

export interface IDictsGet {
  type: Type.DICTS_GET;
  payload: null;
}

export interface IDictsSet {
  type: Type.DICTS_SET;
  payload: any;
}

export interface IDictsFailed {
  type: Type.DICTS_FAILED;
  payload: Error[];
}

export interface IUnshiftCountryDictByGeoLocation {
  type: Type.UNSHIFT_COUNTRY_DICT_BY_GEO_LOCATION;
  payload: any;
}

export type ActionType = IDictsGet | IDictsSet | IDictsFailed | IUnshiftCountryDictByGeoLocation;
