import React, { FunctionComponent, useContext, useMemo } from 'react';
import Select, { Props as SelectProps, Styles, components } from 'react-select';
import { IndicatorContainerProps } from 'react-select/src/components/containers';
import styled, { ThemeContext } from 'styled-components';
import { toJS } from 'utils/helpers';
import { isArray } from 'utils/collection';
import { Markup } from 'components/UI';

import { withLocalization, WithLocalizationProps } from 'system/Localization';
import { ConfigContext } from 'merchants';
import { IConfig } from 'merchants/types/IConfig';
import { adaptOptions, getDropdownStyles } from './utils/helpers';

export interface IDictionary {
  id: string;
  name: string;
  [key: string]: any;
}

export interface IDropdownProps extends SelectProps {
  dictionary: IDictionary[];
  error?: boolean;
}

export const IndicatorsContainer: FunctionComponent<IndicatorContainerProps<{
  label: string;
  value: string;
}>> = (props) => {
  return (
    components.IndicatorsContainer && (
      <components.IndicatorsContainer {...props}>
        <ArrowWrapper>
          <Markup.Arrow />
        </ArrowWrapper>
      </components.IndicatorsContainer>
    )
  );
};

export const Dropdown = withLocalization<WithLocalizationProps<IDropdownProps>>(
  ({
    dictionary,
    error = false,
    value,
    valid,
    disabled,
    translate,
    components: externalComponents,
    multipleClear,
    ...props
  }) => {
    const {
      appConfig: { cdn },
    }: IConfig = useContext(ConfigContext);

    const { forms: { dropdown } } = useContext(ThemeContext);

    const options = adaptOptions(toJS(dictionary), cdn);
    const isSearchable = options.length > 7;

    const styles: Styles = getDropdownStyles(dropdown, {
      error,
      valid,
    });

    const memoizeValue = useMemo(() => {
      if (isArray(value)) {
        return adaptOptions(toJS(value), cdn);
      }
      return value ? adaptOptions([toJS(value)], cdn)[0] : value;
    }, [value, cdn]);

    return (
      <Select
        {...props}
        className="dropdown-select"
        isDisabled={disabled}
        isSearchable={isSearchable}
        noOptionsMessage={() => `${translate('no_results')}`}
        options={options}
        styles={styles}
        value={memoizeValue}
        components={{
          ...externalComponents,
          ...(multipleClear ? {} : { MultiValueRemove: () => null }),
          IndicatorsContainer,
        }}
        blurInputOnSelect={false}
        maxMenuHeight={300}
        menuPlacement="auto"
      />
    );
  }
);

const ArrowWrapper = styled.div`
  margin-right: 10px;
  line-height: 1;
`;

Dropdown.displayName = 'Dropdown';
