import styled from 'styled-components';
import { WithThemeProps } from 'merchants/types/IThemeStyles';

const columns = 12;
const gutter = '8px';

interface IFlexProps {
  align?: string;
  justify?: string;
  [l: string]: any;
}

interface IBoxProps {
  checked?: boolean;
  offset?: number;
  breakpoint?: {
    mobile?: { size?: number | string; flexGrow?: number };
    tablet?: { size?: number | string; flexGrow?: number };
  };
}

export const Frame = styled.div<WithThemeProps<{  fullWidth?: boolean }>>`
  margin: auto;

  ${({ fullWidth }) => `max-width: ${fullWidth ? '100%' :
          '470px'}`};
  width: 100%;
`;

export const Box = styled.div<IBoxProps>`
  padding-right: ${gutter};
  padding-left: ${gutter};
  box-sizing: border-box;

  ${({ offset }: IBoxProps) =>
    offset && `margin-left: ${(100 * offset) / columns}%;`};

  ${({ breakpoint: { mobile: { size = 12, flexGrow = 0 } = {} } = {} }: IBoxProps) => {
    const width = typeof size === 'number' ? `${(100 * size) / columns}%` : size;
    return `
      flex: ${flexGrow} 1 ${width};
      width: ${width};
    `;
  }};

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    ${({ breakpoint: { tablet: { size = 0 } = {} } = {} }: IBoxProps) => {
      if (size) {
        return `
          flex-basis: ${(100 * Number(size)) / columns}%;
          width: ${(100 * Number(size)) / columns}%;
      `;
      }
      return ''
    }};
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-right: -${gutter};
  margin-left: -${gutter};
  box-sizing: border-box;
  ${({ align }: IFlexProps) => (align ? `align-items: ${align};` : '')}
  ${({ justify }: IFlexProps) =>
    justify ? `justify-content: ${justify};` : ''}
`;

export const FlexWithColumn = styled(Flex)`
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: row;
  }
`;
