import styled from 'styled-components';

import { Button, IButtonProps } from '../Button';

export type IButtonPrimary = IButtonProps;

export const Link = styled(Button).attrs({ type: 'button' })`
  min-width: auto;
  min-height: auto;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  color: ${({ theme }) => theme?.links?.base?.color};

  :not(:disabled) {
    :hover {
      background-color: transparent;
      color: ${({ theme }) => theme?.links?.base?.hover?.color};
    }
  }

  :disabled {
    :hover {
      cursor: auto;
    }
  }
`;

export const LinkDanger = styled(Link)`
  color: ${({ theme }) => theme?.links?.base?.danger?.color};

  :not(:disabled) {
    :hover {
      color: ${({ theme }) => theme?.links?.base?.danger?.color};
    }
  }
`;
