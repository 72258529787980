export enum Stage {
  IDENTITY = 'identity',
  ADDRESS = 'address',
  ENHANCED = 'enhanced',
  CORPORATE = 'corporate',
  CFD = 'cfd',
}

export type StageId = 'identity' | 'address' | 'enhanced' | 'corporate' | 'cfd';

export type StepId = string;

export enum StepAlias {
  RESIDENCE = 'residence',
  FULL_RESIDENCE = 'fullResidence',
  IDENTITY_DOCUMENT = 'identityDocument',
  IDENTITY_DOCUMENT_UPLOAD = 'identityDocumentUpload',
  LIVENESS_CHECK = 'livenessCheck',
  PERSONAL_INFORMATION = 'personalInformation',
  PHONE_NUMBER = 'phone',
  OCCUPATION = 'occupation',
  QUESTIONNAIRE_PAGE = 'questionnairePage',
  SOURCE_OF_FUNDS = 'sourceOfFunds',
  SOURCE_OF_FUNDS_DOCUMENTS = 'sourceOfFundsDocuments',
  LIVENESS_CHECK_STATS = 'livenessCheckStats',
};

export type BlockId =
  | 'addressDocument'
  | 'appropriatenessTest'
  | 'clientCategorization'
  | 'corporate'
  | 'declaration'
  | 'employment'
  | 'financialDocuments'
  | 'identityDocument'
  | 'questionnaire'
  | 'residence'
  | 'fullResidence'
  | 'socialNetworkProfiles'
  | 'sourceOfFunds'
  | 'generalInformation'
  | 'businessActivity'
  | 'accountActivity'
  | 'licenses'
  | 'beneficiaryBankDetails'
  | 'personalInfo'
  | 'companyDocuments'
  | 'signature';

export enum Block {
  RESIDENCE = 'residence',
  FULL_RESIDENCE = 'fullResidence',
  PHONE = 'phone',
  IDENTITY_DOCUMENT = 'identityDocument',

  ADDRESS_DOCUMENT = 'addressDocument',
  ADDRESS_DOCUMENT_ONLY_IMAGE = 'addressDocumentOnlyImage',
  SOCIAL_NETWORK_PROFILES = 'socialNetworkProfiles',

  EMPLOYMENT = 'employment',
  SOURCE_OF_FUNDS = 'sourceOfFunds',
  QUESTIONNAIRE = 'questionnaire',

  CORPORATE = 'corporate',

  APPROPRIATENESS_TEST = 'appropriatenessTest',
  CLIENT_CATEGORIZATION = 'clientCategorization',

  GENERAL_INFORMATION = 'generalInformation',
  BUSINESS_ACTIVITY = 'businessActivity',
  ACCOUNT_ACTIVITY = 'accountActivity',
  LICENSES = 'licenses',
  PAYMENT_DETAILS = 'paymentDetails',
  PERSONAL_INFO = 'personalInfo',
  COMPANY_DOCUMENTS = 'companyDocuments',
  SIGNATURE = 'esignature',
}

export type FieldId = string;

type BlockStatusName =
  | 'accepted'
  | 'correction'
  | 'draft'
  | 'inprogress'
  | 'locked'
  | 'pending'
  | 'awaiting';

export type StageStatusName =
  | BlockStatusName
  | 'next_stage_needed'
  | 'current_stage_needed'
  | 'refused'
  | 'tobe_asked';

export enum StageStatuses {
  ACCEPTED = 'accepted',
  CORRECTION = 'correction',
  DRAFT = 'draft',
  INPROGRESS = 'inprogress',
  LOCKED = 'locked',
  PENDING = 'pending',
  NEXT_STAGE_NEEDED = 'next_stage_needed',
  CURRENT_STAGE_NEEDED = 'current_stage_needed',
  REFUSED = 'refused',
  TOBE_ASKED = 'tobe_asked',
  EDITABLE = 'editable',
  AWAITING = 'awaiting',
}

export type Product = 'cex' | 'cex-broker';

export enum ProductType {
  BROKER = 'cex-broker',
}

interface IBlockStatus {
  id: BlockId;
  status: BlockStatusName;
}

export interface ICorrection {
  list?: string[];
  paragraphs?: {
    label: string;
    mistake: string;
  }[];
  correctionComment?: string;
  correctionCommentTitle?: string;
  correctionTextTitle?: string;
}

export interface IStageStatus {
  blocks: IBlockStatus[];
  correctionJson?: ICorrection;
  dateOfNextVerification?: number;
  id: StageId;
  notInQueue?: boolean;
  queueNumber?: number;
  resubmit?: StageId[];
  status: StageStatusName;
}

export interface IStages {
  stages: IStageStatus[];
}
