import React, { FC } from 'react';

import { IconProps } from 'components/UI/Icon/types';

export const Success: FC<IconProps> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="8.49976" cy="8.55664" r="8" fill="#0ABF64" />
        <path
          d="M7.55177 9.8931L11.0091 6.43573C11.1943 6.25059 11.4944 6.25059 11.6796 6.43573C11.8647 6.62087 11.8647 6.92103 11.6796 7.10617L7.88152 10.9042C7.6994 11.0863 7.40413 11.0863 7.22201 10.9042L5.32025 9.00247C5.13511 8.81733 5.13511 8.51716 5.32025 8.33202C5.50539 8.14689 5.80555 8.14689 5.99069 8.33202L7.55177 9.8931Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
