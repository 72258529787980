import { call, put, select, takeLatest } from 'redux-saga/effects';

import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';

import { setDossierStepSubmit } from 'system/Dossier/actions';
import {
  baseUploadFileRequest,
  IUploadParams,
  IUploadResult,
  requestWltfsToken,
} from 'system/Dossier/Upload/sagas';
import {
  qrCodeCompleteFileUpload,
  qrCodeIsConnected,
} from 'system/Dossier/Upload/sagas/qr';
import { navigateToErrorLayout } from 'system/Routing/sagas';
import { makeSelectRouterSearchUploadId } from 'system/Bootstrap/selectors';

import {
  failedUploadAddressDocument,
  setAddressDocuments,
  setAddressMeta,
  successUploadAddressDocument,
} from 'stages/Address/actions';

import { generateFileId } from 'utils/helpers';
import { makeErrorMessage } from 'utils/error';

import {
  Type,
  AddressStepInitialSet,
  AddressStepSet,
  IRequestUploadAddressDocument,
} from '../actionTypes';
import { makeSelectDocSubType, makeSelectStepMeta } from '../selectors';
import { watchForSubmit } from './submit';

export function* handleResult({ result }: { result: IUploadParams[] }) {
  try {
    yield put(setAddressDocuments({ source: result[0]?.id, images: result }));
    const isQrCodeConnected = yield qrCodeIsConnected();

    if (isQrCodeConnected) {
      return;
    }
    yield put(setDossierStepSubmit({ fields: result }));
  } catch (error) {
    yield call(navigateToErrorLayout);
  }
}

function* watchForUploadAddressDocumentsRequest(
  action: IRequestUploadAddressDocument
) {
  try {
    const {
      payload: { files },
    } = action;

    yield call(requestWltfsToken);

    const uploadId = yield select(makeSelectRouterSearchUploadId());
    const source = yield select(makeSelectDocSubType());

    const uploadFiles = files.reduce(
      (acc: [] | IUploadResult[], file: File) => {
        return [...acc, { file, id: source }];
      },
      []
    );

    const result = yield call(baseUploadFileRequest, uploadFiles);

    if (uploadId) {
      const fileTokens = result.map(({ id, ...rest }: IUploadParams) => ({
        id,
        fileType: 'multi',
        fileId: generateFileId(),
        ...rest,
      }));

      yield call(qrCodeCompleteFileUpload, { uploadId, fileTokens });
    }

    yield call(handleResult, { result });
    yield put(successUploadAddressDocument());
  } catch (err) {
    yield put(failedUploadAddressDocument([makeErrorMessage(err)]));
    yield call(navigateToErrorLayout);
  }
}

function* watchForAddressStepSet(
  action: AddressStepInitialSet | AddressStepSet
) {
  try {
    const {
      payload: { stepId, fields },
    } = action;

    const stepMeta = yield select(makeSelectStepMeta(omitBy(fields, isEmpty)));

    yield put(setAddressMeta({ stepId, fields: stepMeta }));
  } catch (err) {
    console.log(err);
  }
}

export function* rootSaga() {
  yield takeLatest(
    Type.ADDRESS_UPLOAD_DOC_REQUEST,
    watchForUploadAddressDocumentsRequest
  );
  yield takeLatest(Type.ADDRESS_SUBMIT_REQUEST, watchForSubmit);

  yield takeLatest(
    [Type.ADDRESS_STEP_INITIAL_SET, Type.ADDRESS_STEP_SET],
    watchForAddressStepSet
  );
}
