import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

export const dossierSelector = (state: any) => state.get('dossier');

export const makeSelectDossierByStage = (stage: any) => {
  return createSelector(dossierSelector, (state) => {
    return state.getIn([stage, 'data']);
  });
};

export const makeSelectStageRouting = () =>
  createSelector(dossierSelector, (state) => state.get('routing', Map()));

export const makeSelectStageProgress = () => {
  return createSelector(makeSelectStageRouting(), (state) => state.get('stageProgress', List()));
};

export const makeSelectStageProgressDetailed = () => {
  return createSelector(makeSelectStageProgress(), (state) => {
    const index = state.get('index', 0);
    const amount = state.get('amount', 0);
    return { index, amount };
  });
};

export const makeSelectIdentityProgress = () => {
  return createSelector(makeSelectStageRouting(), (routing) => routing.get('identityProgress'));
};

export const makeSelectRelativeProgress = () => {
  return createSelector(
    makeSelectStageProgressDetailed(),
    makeSelectIdentityProgress(),
    (progressDetailed, identityProgress) => {
      const { index, amount }: any = progressDetailed;

      if (!amount && identityProgress) {
        return identityProgress;
      }

      try {
        const progress = Math.floor(amount ? (100 * index) / amount : 0);
        return Math.max(0, progress);
      } catch (err) {
        return 0;
      }
    }
  );
};
