import React, {
  FC,
  ChangeEventHandler,
  MouseEventHandler,
  MouseEvent,
  FocusEvent,
} from 'react';
import InputMask from 'react-input-mask';
import { useField } from 'formik';
import isFunction from 'lodash/isFunction';

import { Input } from 'components/UI/Input/Input';

export interface InputMaskNextProps {
  name: string;
  value: string;
  mask: string;
  disabled?: boolean;
  placeholder?: string;
  error?: string | boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClick: MouseEventHandler<HTMLInputElement>;
  onBlur: FocusEvent<HTMLInputElement>;
}

export const InputMaskNext: FC<InputMaskNextProps> = (props) => {
  const { name, placeholder, error, value, mask, disabled, onChange } = props;
  const [field, meta, helpers] = useField(name);

  const valid = meta.touched && meta.value && !meta.error;

  const { setTouched } = helpers;

  const handleClick = (evt: MouseEvent<HTMLInputElement>) => {
    if (isFunction(props.onClick)) props.onClick(evt);
    setTouched(false);
  };

  const handleBlur = (evt: FocusEvent<HTMLInputElement>) => {
    if (isFunction(props.onBlur)) props.onBlur(evt);
    setTouched(true);
  };

  return (
    <InputMask
      name={name}
      value={value}
      mask={mask}
      alwaysShowMask={false}
      maskChar={' '}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      onClick={handleClick}
      onBlur={handleBlur}
    >
      {(inputProps: any) => (
        <Input error={error} valid={valid} {...inputProps} />
      )}
    </InputMask>
  );
};
