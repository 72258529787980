import styled from 'styled-components';
import { TransitionStatus } from 'react-transition-group/Transition';

export interface IOverlayProps {
  state?: TransitionStatus;
}

export const Overlay = styled.div.attrs(
  ({ state = 'entered' }: IOverlayProps) => ({
    state,
  })
)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  transition: all 500ms;
  display: ${({ state }) => {
    return state === 'exited' ? 'none' : 'block';
  }};
  background: ${({ state }) => {
    return state === 'entered' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)';
  }};
  z-index: 100;
`;
