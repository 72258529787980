import React, { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isFunction from 'lodash/isFunction';

import { VerificationLifeTimeParams } from './actionTypes';
import { setVerificationDateStart } from './actions';

import { makeSelectVerificationDateByStageStart } from './selectors';

export const withVerificationLifetimeTimingAnalytic = (
  Component: ComponentType<{}>,
  { stageId }: Pick<VerificationLifeTimeParams, 'stageId'>
) => {
  const VerificationLifetimeTimingAnalytic = ({
    startTime,
    startVerificationHandler,
    ...props
  }: any) => {
    
    const handlers = startTime ? {} : {
      onClick: (evt: any) => {
        startVerificationHandler();
        if (isFunction(props.onClick)) props.onClick(evt);
      },
    };
    return <Component {...props} {...handlers} />;
  };

  const mapStateToProps = createStructuredSelector({
    startTime: makeSelectVerificationDateByStageStart(stageId)
  });

  const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    startVerificationHandler: () => {
      dispatch(setVerificationDateStart({ stageId, time: Date.now() }));
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(VerificationLifetimeTimingAnalytic);
};
