import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

import {
  makeSelectAllQuestions,
  makeSelectAllAnswers,
  makeSelectAllSubAnswers,
} from 'system/Dicts/selectors';
import { makeSelectRouterParams } from 'system/Bootstrap/selectors';

export const selector = (state: any) => state.getIn(['dossier', 'cfd'], Map());

export const makeSelectLoading = () =>
  createSelector(selector, (state: any) => state.get('loading', false));

export const makeSelectErrors = () =>
  createSelector(selector, (state: any) => state.get('errors', List()));

export const makeSelectQuestionName = (): any =>
  createSelector(makeSelectRouterParams(), (state) => {
    return state?.params?.stepId || '';
  });

export const makeSelectQuestion = () =>
  createSelector(
    [makeSelectAllQuestions(), makeSelectQuestionName()],
    (questions: List<Map<string, any>>, id: any = '') => {
      return (
        questions.find(
          (q: Map<string, any>) => q.get('id') === id,
          null,
          Map()
        ) || Map()
      );
    }
  );

export const makeSelectQuestionValue = (): any =>
  createSelector([selector, makeSelectQuestion()], (state, question) => {
    const blockId = question.get('blockId', '');
    const stepId = question.get('id', '');
    return state.getIn(['data', blockId, stepId]);
  });

export const makeSelectAnswers = () =>
  createSelector(
    [makeSelectAllAnswers(), makeSelectQuestionName()],
    (answers: List<Map<string, any>>, question: any = '') => {
      return answers.get(question, Map()) || Map();
    }
  );

export const makeSelectSubAnswers = () =>
  createSelector(
    [makeSelectAnswers(), makeSelectAllSubAnswers()],
    (answers: any, subAnswers: Map<string, any>) => {
      const values = answers
        .map((answer: Map<string, any>) => answer.get('values'), null, List())
        .toSet();

      return subAnswers.get(values.first(), List());
    }
  );

export const makeSelectData = createSelector(selector, (state) =>
  state.get('data', Map())
);
