import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const TradingActivity: React.FunctionComponent<IconProps> = ({ width = 104, height = 126 }) => {
  const {
    icons: {
      docs: {
        tradingActivity: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g fill="none" fillRule="evenodd">
        <rect width="102" height="124" x="1" y="1" stroke={fill} strokeWidth="2" rx="4" />
        <path stroke={fill} strokeWidth="2" d="M17 33h70v30H17z" />
        <path fill={fill} d="M18 44h68v-2H18zM18 54h68v-2H18zM18 64h68v-2H18z" />
        <path fill={fill} d="M56 32h2v32h-2zM74 32h2v32h-2z" />
        <path stroke={fill} strokeWidth="2" d="M17 79h70v30H17z" />
        <path fill={fill} d="M18 90h68v-2H18zM18 100h68v-2H18z" />
        <path fill={fill} d="M56 78h2v32h-2zM74 78h2v32h-2zM16 18v-2h72v2zM16 24v-2h72v2z" />
      </g>
    </svg>
  );
};

TradingActivity.displayName = 'TradingActivityIcon';
