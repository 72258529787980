import React, { FC } from 'react';

import styled from 'styled-components';
import { StageId } from 'system/Stages/types';
import { getStageTitle } from 'components/common/StagePreview/utils/helpers';
import { IMediaMatches, Media } from 'system/Media';
import { Markup as Icon } from 'components/UI';
import { ITranslate } from 'system/Localization';

interface ResubmitItemsProps {
  resubmitList: StageId[];
  handleSelectResubmitStage: (stage: StageId) => () => void;
  translate: ITranslate;
}

export const ResubmitItems: FC<ResubmitItemsProps> = ({
  resubmitList,
  handleSelectResubmitStage,
  translate,
}) => (
  <>
    {resubmitList.map((stageId: StageId) => (
      <Item key={stageId}>
        <Action
          data-aqaid={`resubmit-${stageId}`}
          type="button"
          onClick={handleSelectResubmitStage(stageId)}
        >
          {translate(getStageTitle(stageId))}
          <Media>
            {({ tablet }: IMediaMatches) => (tablet ? <Icon.Refresh /> : null)}
          </Media>
        </Action>
      </Item>
    ))}
  </>
);

const Action = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.buttons.resubmit.color};
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  justify-content: space-between;
  line-height: 1.6;
  padding: 0;
  text-transform: capitalize;
  transition: background-color 0.3s ease-in;
  width: 100%;
`;

export const Item = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ theme }) => theme.buttons.resubmit.itemBorderColor};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: 5px 0;
  }
`;
