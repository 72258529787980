import React from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectProduct } from 'system/Profile/selectors';
import { withLoader } from 'HOC/withLoader';
import { IRouteRoutes } from 'system/Routing/interfaces';
import { RouteExitVerification } from './NotFoundRoute';
import { RouteItem } from './RouteItem';

interface RoutesBuilderProps {
  routes?: IRouteRoutes;
  handlePrevStep?: () => void;
  exitOnSubmit?: () => void;
  product?: string;
}

const RoutesBuilderContainer = ({
  routes: configRoutes,
  handlePrevStep,
  exitOnSubmit,
  product,
}: RoutesBuilderProps) => {
  if (!configRoutes) {
    return null;
  }

  const routes = configRoutes({ product });

  return (
    <Switch>
      {routes.map(({ key, ...route }) => {
        return (
          <RouteItem
            key={key}
            {...route}
            handlePrevStep={handlePrevStep}
            exitOnSubmit={exitOnSubmit}
          />
        );
      })}
      <RouteExitVerification />
    </Switch>
  );
};

const mapSteToProps = createStructuredSelector({
  product: makeSelectProduct(),
});

const WithLoader = withLoader<RoutesBuilderProps>(RoutesBuilderContainer);

export const RoutesBuilder = connect<
  ReturnType<typeof mapSteToProps>,
  null,
  RoutesBuilderProps
>(mapSteToProps)(WithLoader);
