import {
  ICorporateSubmitFailed,
  ICorporateSubmitRequest,
  ICorporateSubmitSuccess,
  Type,
  ICodeValidationFailed,
  ICodeValidationRequest,
  ICodeValidationSuccess,
  IPhoneCodeFailed,
  IPhoneCodeRequest,
  IPhoneCodeSuccess,
  IResetPhoneErrors,
  IResetPhoneValidation,
  ICorporateStepSet,
  ICorporateStepSubmit,
  ICorporateBlockSubmitRequest,
  ICorporateBlockSubmitSuccess,
  ICorporateBlockSubmitFailed,
  CorporateBlockReset,
  CorporateSetMeta,
  ICorporateUploadFileRequest,
  ICorporateUploadFileSuccess,
  ICorporateUploadFileError,
  ICorporateSourceAddFile,
  ICorporateSourceRemoveFile,
  ICorporateBlockCacheRequest,
  ICorporateBlockCacheSuccess,
  ICorporateBlockCacheFailed,
  ICorporateBlockCacheRemove,
  ICorporateEsignatureSubmit,
  ICorporateEsignatureSuccess,
  ICorporateEsignatureFailed,
  ICleanPhoneValidation,
  IPhoneWithoutCode,
  ISetPhoneCountry,
  CorporateStepSet,
  IRequestUploadCorporateDocuments,
  IUploadCorporateDocumentsSuccess,
  IUploadCorporateDocumentsFailed,
} from './actionTypes';

export const requestSubmitCorporate = (): ICorporateSubmitRequest => ({
  type: Type.CORPORATE_SUBMIT_REQUEST,
  payload: {},
});

export const successSubmitCorporate = (payload: any = {}): ICorporateSubmitSuccess => ({
  type: Type.CORPORATE_SUBMIT_SUCCESS,
  payload,
});

export const failedSubmitCorporate = (err: any): ICorporateSubmitFailed => ({
  type: Type.CORPORATE_SUBMIT_FAILED,
  payload: err,
});

export const setCorporateStep = ({
  stepId,
  blockId,
  fields,
  replace,
}: ICorporateStepSet['payload']): ICorporateStepSet => ({
  type: Type.CORPORATE_STEP_SET,
  payload: { stepId, blockId, fields, replace },
});

export const setCorporateMeta = (payload: CorporateSetMeta['payload']): CorporateSetMeta => ({
  type: Type.CORPORATE_META_SET,
  payload,
});

export const setCorporateSubmit = ({
  fields,
  upload,
  replace,
  nextStepId,
  cachePath,
  entryId,
}: ICorporateStepSubmit['payload']): ICorporateStepSubmit => ({
  type: Type.CORPORATE_STEP_SUBMIT,
  payload: { fields, upload, replace, nextStepId, cachePath, entryId },
});

export const requestPhoneCode = (payload: IPhoneCodeRequest['payload']): IPhoneCodeRequest => ({
  type: Type.CORPORATE_PHONE_CODE_REQUEST,
  payload,
});

export const requestPhoneWithoutCode = (
  payload: IPhoneWithoutCode['payload']
): IPhoneWithoutCode => ({
  type: Type.CORPORATE_PHONE_WITHOUT_CODE_REQUEST,
  payload,
});

export const successPhoneCode = (payload: IPhoneCodeSuccess['payload']): IPhoneCodeSuccess => ({
  type: Type.CORPORATE_PHONE_CODE_SUCCESS,
  payload,
});

export const failedPhoneCode = (err: Error[]): IPhoneCodeFailed => ({
  type: Type.CORPORATE_PHONE_CODE_FAILED,
  payload: err,
});

export const resetPhoneErrors = (): IResetPhoneErrors => ({
  type: Type.CORPORATE_RESET_PHONE_ERRORS,
  payload: {},
});

export const resetPhoneValidation = (): IResetPhoneValidation => ({
  type: Type.CORPORATE_RESET_PHONE_VALIDATION,
  payload: {},
});

export const cleanPhoneValidation = (): ICleanPhoneValidation => ({
  type: Type.CORPORATE_CLEAN_PHONE_VALIDATION,
  payload: {},
});

export const requestBlockSubmitCorporate = ({
  blockId,
}: ICorporateBlockSubmitRequest['payload']): ICorporateBlockSubmitRequest => ({
  type: Type.CORPORATE_BLOCK_SUBMIT_REQUEST,
  payload: { blockId },
});

export const successBlockSubmitCorporate = (): ICorporateBlockSubmitSuccess => ({
  type: Type.CORPORATE_BLOCK_SUBMIT_SUCCESS,
  payload: {},
});

export const failedBlockSubmitCorporate = (err: Error[]): ICorporateBlockSubmitFailed => ({
  type: Type.CORPORATE_BLOCK_SUBMIT_FAILED,
  payload: err,
});

export const requestBlockCache = ({
  blockId,
  cachePath,
  nextStepId,
  data,
}: ICorporateBlockCacheRequest['payload']): ICorporateBlockCacheRequest => ({
  type: Type.CORPORATE_CACHE_REQUEST,
  payload: { blockId, cachePath, nextStepId, data },
});

export const removeBlockCache = ({
  blockId,
  idx,
  path,
}: ICorporateBlockCacheRemove['payload']): ICorporateBlockCacheRemove => ({
  type: Type.CORPORATE_CACHE_REMOVE,
  payload: { blockId, idx, path },
});

export const successCorporateBlockSet = (
  payload: ICorporateBlockCacheSuccess['payload']
): ICorporateBlockCacheSuccess => ({
  type: Type.CORPORATE_CACHE_SUCCESS,
  payload,
});

export const failedBlockCache = (err: Error[]): ICorporateBlockCacheFailed => ({
  type: Type.CORPORATE_CACHE_FAILED,
  payload: err,
});

export const resetBlock = ({
  blockId,
  stepId,
  cachePath,
}: CorporateBlockReset['payload']): CorporateBlockReset => ({
  type: Type.CORPORATE_BLOCK_RESET,
  payload: { blockId, stepId, cachePath },
});

export const requestUploadFiles = ({
  files,
  source,
}: ICorporateUploadFileRequest['payload']): ICorporateUploadFileRequest => {
  return {
    type: Type.CORPORATE_UPLOAD_FILES_REQUEST,
    payload: { files, source },
  };
};

export const successUploadFiles = (): ICorporateUploadFileSuccess => ({
  type: Type.CORPORATE_UPLOAD_FILES_SUCCESS,
  payload: {},
});

export const errorUploadFiles = (error: Error[]): ICorporateUploadFileError => ({
  type: Type.CORPORATE_UPLOAD_FILES_ERROR,
  payload: error,
});

export const addFiles = ({
  source = '',
  files,
  blockId,
  stepId,
  replace,
}: ICorporateSourceAddFile['payload']): ICorporateSourceAddFile => ({
  type: Type.CORPORATE_ADD_FILES,
  payload: { source, files, blockId, stepId, replace },
});

export const removeFile = ({
  source,
  id,
  blockId,
  stepId,
}: {
  source: string;
  id: string | number;
  blockId: string;
  stepId: string;
}): ICorporateSourceRemoveFile => ({
  type: Type.CORPORATE_REMOVE_FILES,
  payload: { source, id, blockId, stepId },
});

export const submitEsignature = (data: any): ICorporateEsignatureSubmit => ({
  type: Type.CORPORATE_ESIGNATURE_SUBMIT,
  payload: data,
});

export const successEsignature = (data: any): ICorporateEsignatureSuccess => ({
  type: Type.CORPORATE_ESIGNATURE_SUCCESS,
  payload: data,
});

export const failedEsignature = (errors: Error[]): ICorporateEsignatureFailed => ({
  type: Type.CORPORATE_ESIGNATURE_FAILED,
  payload: errors,
});

export const setPhoneCountry = (payload: string): ISetPhoneCountry => ({
  type: Type.CORPORATE_SET_PHONE_COUNTRY,
  payload,
});

export const resendEsignature = (payload: any) => ({
  type: Type.CORPORATE_ESIGNATURE_RESEND,
  payload,
});

export const cancelEsignature = (payload: any) => ({
  type: Type.CORPORATE_ESIGNATURE_CANCEL,
  payload,
});

export const requestUploadIdentityDocuments = (
  payload: IRequestUploadCorporateDocuments['payload']
): IRequestUploadCorporateDocuments => ({
  type: Type.CORPORATE_UPLOAD_DOC_REQUEST,
  payload,
});

export const successUploadCorporateDocuments = (): IUploadCorporateDocumentsSuccess => ({
  type: Type.CORPORATE_UPLOAD_DOC_SUCCESS,
  payload: {},
});

export const failedUploadCorporateDocuments = (err: Error[]): IUploadCorporateDocumentsFailed => ({
  type: Type.CORPORATE_UPLOAD_DOC_FAILED,
  payload: err,
});
