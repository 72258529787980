import { Dispatch } from 'redux';
import React from 'react';
import { RouteComponentProps, withRouter, match } from 'react-router-dom';
import { History, Location } from 'history';
import { connect } from 'react-redux';

import { ITranslate, withLocalization } from 'system/Localization';

import { CommonStub } from 'containers/Stubs/CommonStub';
import {
  BUTTON_TYPE,
  ICommonStubProps,
} from 'containers/Stubs/CommonStub/types';

import { Stub } from 'components/UI/Icon';
import { navigateIndex } from 'system/Routing/actions';
import { StageId } from 'system/Stages/types';

interface ISubmittedStub extends RouteComponentProps {
  location: Location;
  history: History;
  match: match<{ stageId: StageId}>;
  nextStep?: string;
  handleLocation: () => void;
  translate: ITranslate;
  message?: string;
}

const ApproveStubContainer = withLocalization<ISubmittedStub>(({
  translate,
  match: {
    params: { stageId },
  },
  handleLocation,
  message= 'stub_message_approve'
}) => {
  const contentProps: ICommonStubProps = {
    stageTitle: translate(`stage_title_${stageId}`),
    stageSubtitle: translate('information_notice'),
    buttons: [
      {
        clickHandler: () => handleLocation(),
        text: translate('button_continue'),
        mods: { type: BUTTON_TYPE.primary },
      },
    ],
    body: {
      Icon: Stub.Ok,
      title: translate('stub_title_approve'),
      message: translate(message),
    },
  };

  return <CommonStub {...contentProps} />;
  }
);

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  handleLocation: () => {
    dispatch(navigateIndex({ exit: true }));
  },
});

export const ApproveStub = connect(
  null,
  mapDispatchToProps
)(withRouter(ApproveStubContainer));
