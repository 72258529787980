import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import { Markup } from 'components/UI';
import { IButtonProps } from 'components/UI/Button/Button';

interface IContent {
  type: string;
  text: string;
  list: string[];
}

export const InfoMessage = ({ message = '' }: { message?: string }) => {
  const string = message.replace(/\n/g, '\\n');
  let parsed: { content?: IContent[] } = {};
  try {
    parsed = JSON.parse(string);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('JSON.parse error');
  }
  const { content = [] } = parsed;

  return (
    <>
      {content.map(({ type, text, list }: IContent, index: number) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            {type === 'text' && <p>{ReactHtmlParser(text)}</p>}
            {type === 'list' && (
              <List>
                {list.map((item: string, _i: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem key={_i}>
                    <ListIcon fill="#4ec0e8" />
                    {ReactHtmlParser(item)}
                  </ListItem>
                ))}
              </List>
            )}
            {type === 'text-add' && <p>{ReactHtmlParser(text)}</p>}
          </div>
        );
      })}
    </>
  );
};

const List = styled.ul`
  margin: 8px 0;
  list-style: none;
`;

const ListItem = styled.li`
  position: relative;
  padding-left: 32px;
  padding-bottom: 8px;
`;

export const ListIcon = styled(Markup.Check).attrs(
  (props: IButtonProps) => props
)`
  position: absolute;
  top: 0;
  left: 0;
`;
