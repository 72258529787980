import React from 'react';
import styled from 'styled-components';
import { ILocalizationProps, withLocalization } from 'system/Localization';

export const Loader = withLocalization(({ translate }: ILocalizationProps) => {
  return (
    <Layout data-aqaid='loader'>
      <Wrapper>
        <Spinner>
          <Leaf />
          <Leaf />
          <Leaf />
          <Leaf />
          <Leaf />
          <Leaf />
          <Leaf />
          <Leaf />
          <Leaf />
          <Leaf />
          <Leaf />
          <Leaf />
        </Spinner>
        <Label>{translate('dialog_message_processing')}</Label>
      </Wrapper>
    </Layout>
  );
});

const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
`;

const Leaf = styled.div`
  transform-origin: 20px 20px;
  animation: lds-spinner 1.2s linear infinite;
  
    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 28px;
      left: 19px;
      width: 2px;
      height: 8px;
      border-radius: 20%;
      background: #667c99;
    }
    
    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
    
    @keyframes lds-spinner {
      0% {
        opacity: 1;
      }
        100% {
          opacity: 0;
      }
`;

const Layout = styled.div`
  animation: delayed linear 2s forwards;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  @keyframes delayed {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  
      display: flex;
    align-items: center;
`;

const Label = styled.h5`
  color: #667c99;
  padding-left: 5px;
`;
