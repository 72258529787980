import React from 'react';

import { IconProps } from 'components/UI/Icon/types';

export const Linkedin: React.FunctionComponent<IconProps> = ({
  fill,
  height,
  width,
}: IconProps) => (
  <svg
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="16" cy="16" fill={fill} r="16" />
      <path
        d="M24 22.429h-3.536v-5.223c0-1.367-.553-2.3-1.77-2.3-.93 0-1.448.63-1.688 1.236-.09.218-.077.521-.077.825v5.462h-3.503s.045-9.252 0-10.093h3.503v1.584c.207-.693 1.327-1.681 3.113-1.681 2.217 0 3.958 1.451 3.958 4.578v5.612zM9.883 11.073h-.022C8.73 11.073 8 10.302 8 9.323c0-.996.754-1.752 1.905-1.752 1.15 0 1.858.754 1.88 1.75 0 .978-.73 1.752-1.902 1.752zm-1.48 1.263h3.12v10.093h-3.12V12.336z"
        fill="#FFF"
      />
    </g>
  </svg>
);

Linkedin.displayName = 'LinkedinIcon';

Linkedin.defaultProps = { fill: '#263850', height: 32, width: 32 };
