import React, { Children, useEffect, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { Map } from 'immutable';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectMerchantFeatures } from 'system/Profile/selectors';
import { IConfig } from './types/IConfig';

import { baseConfig } from './identance';
import { mergeMerchantFeatures } from './helpers';

interface IConfigProvider {
  children: React.ReactChild;
  config: IConfig;
  merchantFeatures?: Map<string, any>;
}

export const ConfigContext = React.createContext(baseConfig);

const mapStateToProps = createStructuredSelector({
  merchantFeatures: makeSelectMerchantFeatures(),
});

export const MerchantConfigProvider = connect(
  mapStateToProps,
  null
)(
  ({
    config,
    children,
    merchantFeatures = Map(),
  }: IConfigProvider) => {
    const { theme, ...defaultConfig } = config;

    const [
      currentTheme,
      // setTheme
    ] = useState(theme?.config);

    const [
      currentConfig,
      setConfig
    ] = useState<Omit<IConfig, 'theme'>>(defaultConfig);

    useEffect(() => {
      if(!merchantFeatures.isEmpty()) {
        const mergedConfig = mergeMerchantFeatures(currentConfig, merchantFeatures.toJS());
        setConfig(mergedConfig);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantFeatures]);

    return (
      <ConfigContext.Provider value={currentConfig}>
        <StyledThemeProvider theme={currentTheme}>
          {Children.only(children)}
        </StyledThemeProvider>
      </ConfigContext.Provider>
    );
  }
);
