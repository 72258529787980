import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Type, IRequestUploadCorporateDocuments } from '../actionTypes';
import { watchForPhoneCodeRequest } from './phone';
import {
  watchBlockCache,
  watchBlockSubmit,
  watchFormSubmit,
  watchForStepSubmit,
  watchForEsignatureSubmit,
  watchForEsignatureResend,
  watchForEsignatureCancel,
} from './submit';
import { watchBlockCacheRemove } from './cache';
import {
  baseUploadFileRequest,
  IUploadResult,
  requestWltfsToken,
} from 'system/Dossier/Upload/sagas';
import { makeSelectRouterSearchUploadId } from 'system/Bootstrap/selectors';
import { makeSelectDocSubType } from '../selectors';
import { toFlatIdentityDocs } from './utils';
import flatten from 'lodash/flatten';
import { generateFileId } from 'utils/helpers';
import { qrCodeCompleteFileUpload } from 'system/Dossier/Upload/sagas/qr';
import isFunction from 'lodash/isFunction';
import { failedUploadCorporateDocuments, successUploadCorporateDocuments } from '../actions';
import { makeErrorMessage } from 'utils/error';
import { navigateToErrorLayout } from 'system/Routing/sagas';
import { handleUploadResult } from '../../Identity/sagas/rootSaga';

function* watchForUploadCorporateDocumentsRequest(action: IRequestUploadCorporateDocuments) {
  try {
    const {
      payload: { files: identityDocs, onAfterUpload },
    } = action;

    yield call(requestWltfsToken);

    const uploadId = yield select(makeSelectRouterSearchUploadId());

    const source = yield select(makeSelectDocSubType());

    const files = toFlatIdentityDocs(identityDocs);

    const uploadedData = yield call(baseUploadFileRequest, files);

    const result = flatten<IUploadResult>(uploadedData);

    if (uploadId) {
      const fileTokens = result.map(({ ...rest }) => ({
        source,
        fileId: generateFileId(),
        ...rest,
      }));

      yield call(qrCodeCompleteFileUpload, { uploadId, fileTokens });
    }

    if (isFunction(onAfterUpload)) {
      yield call(onAfterUpload, { result, source });
    } else {
      yield call(handleUploadResult, { result, source });
    }

    yield put(successUploadCorporateDocuments());
  } catch (err) {
    yield put(failedUploadCorporateDocuments([makeErrorMessage(err)]));
    yield call(navigateToErrorLayout);
  }
}

export function* rootSaga() {
  yield takeLatest(Type.CORPORATE_STEP_SUBMIT, watchForStepSubmit);
  yield takeLatest(Type.CORPORATE_BLOCK_SUBMIT_REQUEST, watchBlockSubmit);
  yield takeLatest(Type.CORPORATE_CACHE_REQUEST, watchBlockCache);
  yield takeLatest(Type.CORPORATE_CACHE_REMOVE, watchBlockCacheRemove);
  yield takeLatest(Type.CORPORATE_SUBMIT_REQUEST, watchFormSubmit);
  yield takeLatest(Type.CORPORATE_PHONE_CODE_REQUEST, watchForPhoneCodeRequest);
  yield takeLatest(Type.CORPORATE_ESIGNATURE_SUBMIT, watchForEsignatureSubmit);
  yield takeLatest(Type.CORPORATE_ESIGNATURE_RESEND, watchForEsignatureResend);
  yield takeLatest(Type.CORPORATE_ESIGNATURE_CANCEL, watchForEsignatureCancel);
  yield takeLatest(Type.CORPORATE_UPLOAD_DOC_REQUEST, watchForUploadCorporateDocumentsRequest);
}
