import Fingerprint from 'fingerprintjs2';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Dispatch } from 'redux';
import { makeSelectISS } from 'system/Profile/selectors';
import { useEffect } from 'react';
import { requestUniqueId } from './actions';
import { options } from './utils/options';
import { schema } from './utils/schema';

const mapStateToProps = createStructuredSelector({
  iss: makeSelectISS(),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  handleSubmit: (result: any) => {
    dispatch(requestUniqueId({ bulkId: { ...result } }));
  },
});

export const FingerprintInit: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ children, iss, handleSubmit }) => {
  const error = (e: Error) => {
    console.log('FingerprintInit', e);
  };

  const process = (components: any) =>
    components.reduce((res: any, current: any) => {
      const { key, value } = current;

      if (schema[key]) {
        res[schema[key]] = value;
      }

      return res;
    }, {});

  const init = () => {
    Fingerprint.getPromise(options(iss))
      .then(process, error)
      .then(handleSubmit, error);
  };

  const fingerprintInit = () => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(init);
    } else {
      setTimeout(init, 1000);
    }
  };

  useEffect(() => {
    fingerprintInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
});
