import { fromJS, List, Map } from 'immutable';
import { IRegionDictFields } from 'utils/types/shared/interfaces';

type regionDictFields = Map<keyof IRegionDictFields, string>
export type unshiftByGeoLocationDictProp = List<regionDictFields>

export const unshiftByGeoLocation = (dict: unshiftByGeoLocationDictProp, id: string) => {
  return dict.reduce((reduction: unshiftByGeoLocationDictProp, value: regionDictFields) => {
    if (value.get('id') === id) {
      return reduction.unshift(value.merge(fromJS({ geoLocation: true })));
    }
    return reduction.push(value);
  }, List())
};
