import { RouteStage, RoutingSchema } from './types';
import {
  ICorporateRoutingStepProgress,
  IRoutingProcessStepProgress,
  IRoutingSetSchema,
  IRoutingSetStageRouteIds,
  IRoutingSetStageRouteIdsGrouped,
  IRoutingSetStagesEntryUrls,
  Type,
} from './actionTypes';

export const setSchema = (payload: RoutingSchema): IRoutingSetSchema => ({
  type: Type.SET_SCHEMA,
  payload,
});

export const setStageRouteIdsGrouped = (
  payload: RouteStage
): IRoutingSetStageRouteIdsGrouped => ({
  type: Type.SET_STAGE_ROUTE_IDS_GROUPED,
  payload,
});

export const setStageRouteIds = (
  payload: IRoutingSetStageRouteIds['payload']
): IRoutingSetStageRouteIds => ({
  type: Type.SET_STAGE_ROUTE_IDS,
  payload,
});

export const setStagesEntryUrls = (
  payload: string[]
): IRoutingSetStagesEntryUrls => ({
  type: Type.SET_STAGES_ENTRY_URLS,
  payload,
});

export const routingToProcessStepProgress = (
  payload?: IRoutingProcessStepProgress['payload']
): IRoutingProcessStepProgress => ({
  type: Type.ROUTING_TO_PROCESS_STEP_PROGRESS,
  payload,
});

export const routingCorporateStepProgress = (
  payload?: IRoutingProcessStepProgress['payload']
): ICorporateRoutingStepProgress => ({
  type: Type.CORPORATE_ROUTING_STEP_PROGRESS,
  payload,
});

export const routingSetStageProgress = (payload: any) => ({
  type: Type.SET_STAGE_PROGRESS,
  payload,
});

export const navigateNext = () => ({
  type: Type.ROUTING_NAVIGATE_NEXT,
});

export const navigateBack = () => ({
  type: Type.ROUTING_NAVIGATE_BACK,
});

export const navigateCorporateNext = ({
  nextStepId,
  cachePath,
}: { nextStepId?: string; cachePath?: string } = {}) => ({
  type: Type.CORPORATE_NAVIGATE_NEXT,
  payload: { nextStepId, cachePath },
});

export const navigateCorporateBack = () => ({
  type: Type.CORPORATE_NAVIGATE_BACK,
});

export const navigateIndex = (payload?: {
  exit?: boolean;
  refetchUserProfile?: boolean;
}) => ({
  type: Type.ROUTING_NAVIGATE_INDEX,
  payload,
});

export const navigateCorporateStep = (step: string, subStep: string) => ({
  type: Type.CORPORATE_NAVIGATE_STEP,
  payload: { step, subStep },
});

export const setIdentityProgress = (progress: number) => ({
  type: Type.SET_IDENTITY_PROGRESS,
  payload: progress,
});
