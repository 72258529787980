import { ChangeEvent, DragEvent, SyntheticEvent } from 'react';

export type DroppedFile = { id?: string; file: File | null; error: string }

export interface IFilesHandlerChildrenProps {
  accept: string;
  dragging: boolean;
  inputFileRef: any;
  files: DroppedFile[];
  multiple: boolean;
  handleUploadFiles: (evt: ChangeEvent<HTMLInputElement>) => void;
  handleDragLeave: (evt: DragEvent<Element>) => void;
  handleDragOver: (evt: DragEvent<Element>) => void;
  handleDrop: (evt: DragEvent<Element>) => void;
  handleOpenDialog: (evt: SyntheticEvent<Element>) => void;
  handleRemoveFiles: (position?: number) => void;
}

export interface IFilesHandlerProps {
  accept?: string[];
  multiple?: boolean;
  minFileSize?: number;
  maxFileSize?: number;
  maxFiles?: number;
  files?: any[];
  children: (props: IFilesHandlerChildrenProps) => JSX.Element;
  fileValidate?: (file: File, options: any) => any;
  onChange?: (files: any) => void; //   File[]
  handleUpload?: (files: any) => void; //   File[]
}

export type FileValidateOptions = {
  maxFileSize: number;
  minFileSize: number;
  accept: string[];
};

export enum FileType {
  AUDIO = 'audio/*',
  IMAGE = 'image/*',
  VIDEO = 'video/*',

  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  PDF = 'application/pdf',
}

