import { cookieH as cookie } from 'utils/cookie';
import { ISettings } from 'system/Settings/types';
import { unserialize } from '../serialization';

export const getSettings = (): ISettings => {
  const settings = cookie.get('merchantParams');

  return settings ? JSON.parse(settings) : {};
};

export const getToken = (): string => {
  const requestToken = cookie.get('requestToken');

  return requestToken || '';
};

export const parseJwt = (token = '') => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return `%${  (`00${  c.charCodeAt(0).toString(16)}`).slice(-2)}`;
        })
        .join('')
    );

    const payload = unserialize(jsonPayload);
    const profilePayload = atob(payload?.profile);
    const profile = unserialize(profilePayload);

    return {
      ...payload,
      profile
    };
  } catch (e) {
    return {}
  }
};


export const parseToken = (token = '') => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return `%${  (`00${  c.charCodeAt(0).toString(16)}`).slice(-2)}`;
        })
        .join('')
    );

    return unserialize(jsonPayload);
  } catch (e) {
    return {}
  }
};

