import { IconProps } from 'components/UI/Icon/types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export const BackSide: React.FC<IconProps> = ({ width = 372, height = 110 }) => {
  const {
    icons: {
      docs: {
        backSide: { fill, fill2 },
      },
    }
  } = useContext(ThemeContext);
  return (

    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g fill="none" fillRule="evenodd">
        <path fill={fill} d="M0 0h372v40H0z" />
        <g fill={fill2}>
          <path d="M39 56h2.164v53.689H39zM44.049 56h1v53.689h-1zM121.23 56h1v53.689h-1zM47.656 56h2.885v53.689h-2.885zM106.803 56h2.885v53.689h-2.885zM111.852 56h2.885v53.689h-2.885zM116.18 56h2.885v53.689h-2.885zM71.459 56h2.885v53.689h-2.885zM77.23 56h2.885v53.689H77.23zM82.279 56h2.885v53.689h-2.885zM87.328 56h2.885v53.689h-2.885zM52.705 56h2.885v53.689h-2.885zM58.475 56h1.443v53.689h-1.443zM62.803 56h1.443v53.689h-1.443zM125.557 56H127v53.689h-1.443zM67.131 56h1.443v53.689h-1.443zM94.541 56h1.443v53.689h-1.443zM98.869 56h1.443v53.689h-1.443zM102.475 56h1.443v53.689h-1.443z" />
        </g>
        <g fill={fill2}>
          <path d="M159 56h173v2H159zM159 84h143.661v-2H159zM159 110h111.287v-2H159z" />
        </g>
      </g>
    </svg>
  );
};

