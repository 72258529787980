import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Discuss: React.FunctionComponent<IconProps> = ({ width, height, className }) => {
  const {
    icons: {
      markup: {
        discuss: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg className={className} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`}>
      <path fill={fill} d="M18.362 5.062c0 .714-.529 1.149-.916 1.468-.146.12-.418.34-.418.423a.58.58 0 0 1-.59.58h-.005a.583.583 0 0 1-.595-.57c-.005-.634.465-1.013.843-1.324.297-.245.491-.417.491-.575a.584.584 0 0 0-.588-.578.584.584 0 0 0-.59.578.59.59 0 0 1-.594.585.59.59 0 0 1-.595-.585c0-.963.798-1.747 1.778-1.747.981 0 1.78.782 1.78 1.745zm-1.912 3.01a.579.579 0 0 0-.582.585v.005c0 .323.253.582.582.582a.592.592 0 0 0 .595-.587.59.59 0 0 0-.595-.584zM4.456 12.145a.59.59 0 0 0-.595.585.59.59 0 0 0 .595.584h.05a.59.59 0 0 0 .594-.584.59.59 0 0 0-.595-.585h-.05zm2.068 0a.59.59 0 0 0-.595.585.59.59 0 0 0 .595.584h.05a.59.59 0 0 0 .594-.584.59.59 0 0 0-.595-.585h-.05zM24 2.81v6.579c0 1.557-1.272 2.821-2.857 2.821h-7.717c-.151 0-.275 0-.38-.003a3.216 3.216 0 0 0-.278.003c-.05.032-.172.142-.29.248l-.156.14-2.183 1.954a.614.614 0 0 1-.648.104.596.596 0 0 1-.367-.535V7.923H2.891c-.93 0-1.7.748-1.7 1.662v6.578c0 .914.77 1.632 1.7 1.632h8.62c.148 0 .287.066.396.165l1.779 1.61v-5.555a.59.59 0 0 1 .595-.584.59.59 0 0 1 .595.584v6.88a.576.576 0 0 1-.586.579.607.607 0 0 1-.402-.16l-2.608-2.35H2.89c-1.585 0-2.89-1.243-2.89-2.8v-6.58c0-1.557 1.305-2.83 2.89-2.83h6.234V2.81C9.124 1.25 10.439 0 12.024 0h9.119C22.728 0 24 1.251 24 2.809zm-1.19 0c0-.913-.738-1.64-1.667-1.64h-9.118c-.93 0-1.711.727-1.711 1.64v9.986l1.196-1.062c.056-.05.118-.095.163-.136.547-.495.686-.574 1.399-.56.097.003.213.003.354.003h7.717c.93 0 1.667-.74 1.667-1.652v-6.58z" />
    </svg>
  );
};

Discuss.displayName = 'Chat';

Discuss.defaultProps = { height: '22', width: '24' };
