import { StageId } from 'system/Stages/types';

export enum Type {
  VERIFICATION_DATE_START = '@Analytics/VERIFICATION_DATE_START',
}

export type VerificationLifeTimeParams = {
  stageId: StageId;
  time: number;
};

export interface IVerificationDateStart {
  type: Type.VERIFICATION_DATE_START;
  payload: VerificationLifeTimeParams;
}

export type ActionType = IVerificationDateStart;
