import styled from 'styled-components';

import { ITheme } from 'merchants/types/IThemeStyles';

import { setColorByState } from 'utils/styledComponents/helpers';

export interface IBadgeProps {
  theme: ITheme;
  isDanger?: boolean;
  isPrimary?: boolean;
  isWarning?: boolean;
  isSuccess?: boolean;
  isPending?: boolean;
  isAwaiting?: boolean;
}

export const Badge = styled.span.attrs((props: IBadgeProps) => props)`
  display: inline-block;
  line-height: 1;
  padding: 6px 16px 5px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;

  color: ${({ theme }) => theme.text.badge.color};
  background-color: ${({ theme, isDanger, isPrimary, isWarning, isSuccess }) =>
    setColorByState(
      {
        default: theme.text.badge.backgroundColor,
        danger: theme.text.badge.danger.backgroundColor,
        primary: theme.text.badge.primary.backgroundColor,
        warning: theme.text.badge.warning.backgroundColor,
        success: theme.text.badge.success.backgroundColor,
      },
      {
        danger: isDanger,
        primary: isPrimary,
        warning: isWarning,
        success: isSuccess,
      }
    )};
`;

export const BlockBadge = styled.div<IBadgeProps>`
  border-radius: 2px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 1px 5px;
  margin-left: auto;
  white-space: nowrap;

  color: ${({ theme }) => theme.text.badge.color};
  background-color: ${({ theme, isDanger, isPrimary, isWarning, isPending, isSuccess, isAwaiting }) =>
    setColorByState(
      {
        default: theme.text.badge.backgroundColor,
        danger: theme.text.badge.danger.backgroundColor,
        primary: theme.text.badge.primary.backgroundColor,
        warning: theme.text.badge.warning.backgroundColor,
        success: theme.text.badge.success.backgroundColor,
        pending: theme.text.badge.pending.backgroundColor,
        awaiting: theme.text.badge.awaiting.backgroundColor,
      },
      {
        danger: isDanger,
        primary: isPrimary,
        warning: isWarning,
        success: isSuccess,
        pending: isPending,
        awaiting: isAwaiting,
      }
    )};
`;
