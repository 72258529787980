/* eslint-disable */
import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectUID } from 'system/Profile/selectors';
import { default as packageJson } from '../../package.json';

export const withLog = <BaseProps extends object>(
  WrappedComponent: ComponentType<BaseProps>
) => {
  const mapStateToProps = createStructuredSelector({
    UID: makeSelectUID(),
  });

  const WithLog = ({ UID, ...props }: ReturnType<typeof mapStateToProps>) => {
    console.log(`APP_VERSION: ${packageJson.version}`);
    console.log(`UID: ${UID}`);
    return <WrappedComponent {...props as BaseProps} />;
  };

  return connect(mapStateToProps)(WithLog);
};

