import React from 'react';
import { IconProps } from 'components/UI/Icon/types';

export const Completed: React.FunctionComponent<IconProps> = () => {
  return (
    <svg
      id="Layer_1"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill='#62a90a' d="m32 2c-16.5419922 0-30 13.4580078-30 30s13.4580078 30 30 30 30-13.4580078 30-30-13.4580078-30-30-30zm0 58c-15.4394531 0-28-12.5605469-28-28s12.5605469-28 28-28 28 12.5605469 28 28-12.5605469 28-28 28z" />
      <path fill='#62a90a' d="m47.1943359 20.5302734-20.8178711 20.8183594-9.5708008-9.5708008c-.390625-.390625-1.0234375-.390625-1.4140625 0s-.390625 1.0234375 0 1.4140625l10.277832 10.277832c.1875.1875.4418945.2929688.7070313.2929688s.5195313-.1054688.7070313-.2929688l21.5249023-21.5253906c.390625-.390625.390625-1.0234375 0-1.4140625s-1.0234375-.390625-1.4140625 0z" />
    </svg>
  );
};

Completed.displayName = 'CompletedIcon';
