import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

import { Field } from 'utils/types/shared/enums';
import { Block } from 'system/Stages/types';
import {
  selectCorporateBlock,
  selectCorporateBlockStep,
  SubStore,
} from '../selectors';

// Documents Upload step
export const makeSelectDocumentsUploadStep = () =>
  createSelector(
    selectCorporateBlock(Block.COMPANY_DOCUMENTS),
    (state: Map<string, any>) => {
      return state.get('documentsUpload', Map()) || Map({});
    }
  );

export const makeSelectCertificateOfRegistrationFilesValues = () => {
  return createSelector(makeSelectDocumentsUploadStep(), (state) =>
    state.get(Field.CERTIFICATE_OF_REGISTRATION, List())
  );
};

export const makeSelectCertificateOfRegistrationFiles = () => {
  return createSelector(makeSelectCertificateOfRegistrationFilesValues(), (state) =>
    state.map((item: Map<string, any>) => item).toJS()
  );
};

export const makeSelectStatutoryDocumentsFilesValues = () => {
  return createSelector(makeSelectDocumentsUploadStep(), (state) =>
    state.get(Field.STATUTORY_DOCUMENTS, List())
  );
};

export const makeSelectStatutoryDocumentsFiles = () => {
  return createSelector(makeSelectStatutoryDocumentsFilesValues(), (state) =>
    state.map((item: Map<string, any>) => item).toJS()
  );
};


export const makeSelectExtractFromRegisterFilesValues = () => {
  return createSelector(makeSelectDocumentsUploadStep(), (state) =>
    state.get(Field.EXTRACT_FROM_REGISTER, List())
  );
};

export const makeSelectExtractFromRegisterFiles = () => {
  return createSelector(makeSelectExtractFromRegisterFilesValues(), (state) =>
    state.map((item: Map<string, any>) => item).toJS()
  );
};


export const makeSelectCorporateFundsConfirmationFilesValues = () => {
  return createSelector(makeSelectDocumentsUploadStep(), (state) =>
    state.get(Field.CORPORATE_FOUNDS_CONFIRMATION, List())
  );
};

export const makeSelectCorporateFundsConfirmationFiles = () => {
  return createSelector(makeSelectCorporateFundsConfirmationFilesValues(), (state) =>
    state.map((item: Map<string, any>) => item).toJS()
  );
};

export const makeSelectAllDocumentsFilesValues = () => {
  return createSelector(makeSelectDocumentsUploadStep(), (state) =>
    state.get(Field.ALL_DOCUMENTS, List())
  );
};

export const makeSelectAllDocumentsFiles = () => {
  return createSelector(makeSelectAllDocumentsFilesValues(), (state) =>
    state.map((item: Map<string, any>) => item).toJS()
  );
};
// Meta
export const makeSelectDocumentsUploadStepMeta = (stepId: string) =>
  createSelector(
    selectCorporateBlockStep(Block.COMPANY_DOCUMENTS, SubStore.META, stepId),
    (value: any) => value
  );

export const makeSelectCertificateOfRegistrationMeta = () => {
  return createSelector(makeSelectDocumentsUploadStepMeta('documentsUpload'), (state) =>
    state.get(Field.CERTIFICATE_OF_REGISTRATION,  Map())
  );
};

export const makeSelectStatutoryDocumentsMeta = () => {
  return createSelector(makeSelectDocumentsUploadStepMeta('documentsUpload'), (state) =>
    state.get(Field.STATUTORY_DOCUMENTS,  Map())
  );
};

export const makeSelectExtractFromRegisterMeta = () => {
  return createSelector(makeSelectDocumentsUploadStepMeta('documentsUpload'), (state) =>
    state.get(Field.EXTRACT_FROM_REGISTER,  Map())
  );
};

export const makeSelectCorporateFundsConfirmationMeta = () => {
  return createSelector(makeSelectDocumentsUploadStepMeta('documentsUpload'), (state) =>
    state.get(Field.CORPORATE_FOUNDS_CONFIRMATION,  Map())
  );
};

export const makeSelectAllDocumentsMeta = () => {
  return createSelector(makeSelectDocumentsUploadStepMeta('documentsUpload'), (state) =>
    state.get(Field.ALL_DOCUMENTS,  Map())
  );
};
