import React from 'react';
import styled from 'styled-components';
import { ITheme } from 'merchants/types/IThemeStyles';

import { INavLinkProps, NavLink } from '../NavLink';

export interface IExitLinkProps extends INavLinkProps {
  block?: boolean;
  theme: ITheme;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ExitLink = styled(({ children, block, ...props }: IExitLinkProps) => (
  <NavLink {...props} data-aqaid="exit-link">
    {children}
  </NavLink>
))`
  && {
    background-color: ${({ theme }: IExitLinkProps) => theme.links.exit.backgroundColor};
    color: ${({ theme }: IExitLinkProps) => theme.links.exit.color};
    min-width: auto;
    padding: 11px;
    width: ${( { block }) => block ? '100%' : 'auto' };

    > :first-child {
      margin-right: 0;
    }

    span {
      padding-bottom: 1px;
    }

    :hover {
      color: ${({ theme }: IExitLinkProps) => theme.links.exit.hover.color};
      background-color: ${({ theme }: IExitLinkProps) => theme.links.exit.hover.backgroundColor};
    }

    :hover > svg {
      fill: ${({ theme }: IExitLinkProps) => theme.links.exit.hover.color};
    }

    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
      min-width: 102px;
      padding: 9px 11px;

      > :first-child {
        margin-right: 4px;
      }
    }
  }
`;
