import {
  call,
  put,
  all,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';

import {
  submitting,
  submitted,
  failedSubmitting,
  retryStageStart,
  retryStageFinished,
  resetErrors, resetStageData,
} from 'system/UI/actions';
import { setFeedbackHistory } from 'system/Profile/actions';

import { IFeedbackSave, IResubmit, IRetryStage, IZipGet, IZipLocation, Type } from 'system/UI/actionTypes';

import { storeUserProfile } from 'system/Profile/sagas';
import {
  navigateToErrorLayout,
  navigateToIndex,
  routingInitialize,
} from 'system/Routing/sagas';
import { makeSelectFeedbackHistory } from 'system/Profile/selectors';

import { jsonRPC } from 'utils/request';
import { makeErrorMessage } from 'utils/error';
import { storage } from 'utils/storage';
import { unserialize, serialize } from 'utils/serialization';

import { adaptZipOptions } from 'components/UI/Dropdown/utils/helpers';
import { push } from 'connected-react-router';
import { makeSelectApiUrl, makeSelectWorkingMode } from '../Settings/selectors';
import { makeSelectEntryUrls } from '../Routing/selectors';

export function* loadFeedbackHistory() {
  try {
    const feedback = yield call([storage, 'get'], 'feedback');
    yield put(setFeedbackHistory(unserialize(feedback)));
  } catch (err) {
    console.log(err);
  }
}

export function* saveFeedback({ payload }: IFeedbackSave) {
  try {
    yield put(submitting());
    const api = yield select(makeSelectApiUrl());

    const { feedback: comment, score: rate, stages } = payload;

    yield call(jsonRPC, {
      namespace: 'va',
      method: 'rateUserDossierStages',
      params: {
        stages,
        rate,
        comment,
      },
      api
    });

    const feedbackHistory = yield select(makeSelectFeedbackHistory());
    yield call(
      [storage, 'set'],
      'feedback',
      serialize({ received: [...feedbackHistory.toJS(), ...stages] })
    );

    yield put(submitted());
  } catch (err) {
    yield put(failedSubmitting([makeErrorMessage(err)]));
  }
}

function* fetchZipSuggestions({ payload }: IZipGet) {
  const { query: text, containerId = '', resolve, reject } = payload;
  try {
    const api = yield select(makeSelectApiUrl());
    const suggestions = yield call(jsonRPC, {
      namespace: 'geo',
      method: 'getLocationSuggestions',
      params: {
        text,
        containerId,
      },
      api
    });
    resolve(adaptZipOptions(suggestions));
  } catch (error) {
    reject(error);
  }
}

function* fetchZipRetrieveLocation({ payload }: IZipLocation) {
  const { containerId: id, resolve, reject } = payload;
  try {
    const api = yield select(makeSelectApiUrl());
    const suggestion = yield call(jsonRPC, {
      namespace: 'geo',
      method: 'retrieveLocation',
      params: { id },
      api
    });
    resolve(suggestion);
  } catch (error) {
    reject(error);
  }
}

function* resubmitStagesRequest({ payload: { stageId } }: IResubmit) {
  try {
    yield put(submitting());
    yield put(resetStageData(stageId));
    const api = yield select(makeSelectApiUrl());
    const response = yield call(jsonRPC, {
      namespace: 'va',
      method: 'cancelStage',
      params: { stageId },
      api
    });
    const { userDossier, error } = response;

    if (userDossier) {
      yield call(storeUserProfile, userDossier);
      yield call(routingInitialize);
      yield put(submitted());
    } else {
      yield put(failedSubmitting(makeErrorMessage(error)));
      yield call(navigateToErrorLayout);
    }

    yield call(navigateToIndex);
  } catch (err) {
    yield put(failedSubmitting(makeErrorMessage(err)));
  }
}

function* retryStageSubmit({ payload }: IRetryStage) {
  try {
    const { stageId } = payload;
    const workingMode = yield select(makeSelectWorkingMode());

    yield put(resetErrors());
    yield put(retryStageStart());
    yield put(resetStageData(stageId));
    const api = yield select(makeSelectApiUrl());

    const response = yield call(jsonRPC, {
      namespace: 'va',
      method: 'retryDossier',
      params: { stageId, workingMode },
      api
    });
    const { userDossier, error } = response;

    if (userDossier) {
      yield call(storeUserProfile, userDossier);
      yield call(routingInitialize);
      const urls  = yield select(makeSelectEntryUrls());
      const url = urls.get(stageId, '');

      if (url) {
        yield put(push(url));
      }

    } else {
      yield put(failedSubmitting(makeErrorMessage(error)));
      yield call(navigateToErrorLayout);
    }
    yield put(retryStageFinished());

  } catch (err) {
    yield put(failedSubmitting(makeErrorMessage(err)));
    yield put(retryStageFinished());
  }
}

export function* rootSaga() {
  yield all([
    yield takeEvery(Type.UI_FEEDBACK_SAVE, saveFeedback),
    yield debounce(300, Type.UI_ZIP_SUGGESTIONS_GET, fetchZipSuggestions),
    yield takeEvery(Type.UI_ZIP_RETRIEVE_LOCATION, fetchZipRetrieveLocation),
    yield takeEvery(Type.UI_RESUBMIT_STAGES, resubmitStagesRequest),
    yield takeEvery(Type.UI_RETRY_STAGE, retryStageSubmit)
  ]);
}
