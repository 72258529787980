import { createSelector } from 'reselect';
import {
  makeSelectAptSuite,
  makeSelectAptSuiteMeta,
  makeSelectCity,
  makeSelectCityMeta,
  makeSelectCompanyRegistrationNumber,
  makeSelectCompanyRegistrationNumberMeta,
  makeSelectCountry,
  makeSelectCountryMeta,
  makeSelectDateOfIncorporation,
  makeSelectDateOfIncorporationMeta,
  makeSelectEmployerIdentificationNumber,
  makeSelectEmployerIdentificationNumberMeta,
  makeSelectFullLegalName,
  makeSelectFullLegalNameMeta,
  makeSelectPremisesAddressCountry,
  makeSelectPremisesAddressCountryMeta,
  makeSelectPremisesAddressSameAsRegistered,
  makeSelectPremisesAddressSameAsRegisteredMeta,
  makeSelectPremisesAddressValue,
  makeSelectPremisesAddressValueMeta,
  makeSelectRegion,
  makeSelectRegionMeta,
  makeSelectSocialProfiles,
  makeSelectStreet,
  makeSelectStreetMeta,
  makeSelectSubRegion,
  makeSelectSubRegionMeta,
  makeSelectTIN,
  makeSelectTINMeta,
  makeSelectTradeName,
  makeSelectTradeNameMeta,
  makeSelectTypeOfCompany,
  makeSelectTypeOfCompanyMeta,
  makeSelectValidationPhoneCode,
  makeSelectValidationIsValidated,
  makeSelectValidationPhoneCountry,
  makeSelectValidationPhoneNumber,
  makeSelectVAT,
  makeSelectVATMeta,
  makeSelectZipCode,
  makeSelectZipCodeMeta,
  makeSelectCompanyIndustry,
  makeSelectCompanyOtherIndustry,
  makeSelectCompanyIndustryMeta,
  makeSelectCompanyOtherIndustryMeta,
  makeSelectCompanyRegionOfBusiness,
} from './generalInformation';

export const makeSelectGeneralInfoPhone = () =>
  createSelector(
    makeSelectValidationPhoneCountry(),
    makeSelectValidationPhoneCode(),
    makeSelectValidationPhoneNumber(),
    makeSelectValidationIsValidated(),
    (country, code, number, isValidated) => ({
      country,
      code,
      number,
      isValidated
    })
  );

export const makeSelectGeneralInfoBlockData = () =>
  createSelector(
    [
      makeSelectFullLegalName(),
      makeSelectTradeName(),
      makeSelectTypeOfCompany(),
      makeSelectCompanyRegistrationNumber(),
      makeSelectTIN(),
      makeSelectCountry(),
      makeSelectRegion(),
      makeSelectSubRegion(),
      makeSelectZipCode(),
      makeSelectStreet(),
      makeSelectCity(),
      makeSelectAptSuite(),
      makeSelectPremisesAddressSameAsRegistered(),
      makeSelectPremisesAddressCountry(),
      makeSelectPremisesAddressValue(),
      makeSelectEmployerIdentificationNumber(),
      makeSelectVAT(),
      makeSelectDateOfIncorporation(),
      makeSelectSocialProfiles(),
      makeSelectGeneralInfoPhone(),
      makeSelectCompanyIndustry(),
      makeSelectCompanyOtherIndustry(),
      makeSelectCompanyRegionOfBusiness()
    ],
    (
      fullLegalName,
      tradeName,
      typeOfCompany,
      companyRegistrationNumber,
      TIN,
      country,
      region,
      subregion,
      zipCode,
      street,
      city,
      aptSuite,
      premisesAddressSameAsRegistered,
      premCountry,
      premAddress,
      employerIdentificationNumber,
      VAT,
      dateOfIncorporation,
      socialNetworkProfiles,
      phone,
      industry,
      otherIndustry,
      regionOfBusiness,
    ) => {
      const isSelectedCountryUS = country === 'US';

      return {
        generalInformation: {
          industry,
          regionOfBusiness,
          ...(otherIndustry ? { otherIndustry } : {}),
          phone,
          fullLegalName,
          tradeName,
          typeOfCompany,
          companyRegistrationNumber,
          TIN: !isSelectedCountryUS ? TIN : undefined,
          registeredAddress: {
            street,
            city,
            country,
            region,
            zipCode,
            subregion: isSelectedCountryUS ? subregion : undefined,
            ...(aptSuite ? { aptSuite } : {}),
          },
          premisesAddressSameAsRegistered,
          ...(!premisesAddressSameAsRegistered && (premCountry && premAddress)
            ? {
                premisesAddress: {
                  country: premCountry,
                  address: premAddress,
                },
              }
            : {}),
          employerIdentificationNumber: isSelectedCountryUS ? employerIdentificationNumber : undefined,
          ...(VAT && { VAT }),
          dateOfIncorporation,
          socialNetworkProfiles,
        },
      };
    }
  );

export const makeSelectGeneralInfoBlockMeta = () =>
  createSelector(
    [
      makeSelectFullLegalNameMeta(),
      makeSelectTradeNameMeta(),
      makeSelectTypeOfCompanyMeta(),
      makeSelectCompanyRegistrationNumberMeta(),
      makeSelectTINMeta(),
      makeSelectCountryMeta(),
      makeSelectRegionMeta(),
      makeSelectSubRegionMeta(),
      makeSelectZipCodeMeta(),
      makeSelectStreetMeta(),
      makeSelectCityMeta(),
      makeSelectAptSuiteMeta(),
      makeSelectPremisesAddressSameAsRegisteredMeta(),
      makeSelectPremisesAddressCountryMeta(),
      makeSelectPremisesAddressValueMeta(),
      makeSelectEmployerIdentificationNumberMeta(),
      makeSelectVATMeta(),
      makeSelectDateOfIncorporationMeta(),
      makeSelectCompanyIndustryMeta(),
      makeSelectCompanyOtherIndustryMeta(),
    ],
    (
      fullLegalNameMeta,
      tradeNameMeta,
      typeOfCompanyMeta,
      companyRegistrationNumberMeta,
      TINMeta,
      countryMeta,
      regionMeta,
      subregionMeta,
      zipCodeMeta,
      streetMeta,
      cityMeta,
      aptSuiteMeta,
      premisesAddressSameAsRegisteredMeta,
      premCountryMeta,
      premAddressMeta,
      employerIdentificationNumberMeta,
      VATMeta,
      dateOfIncorporationMeta,
      industryMeta,
      otherIndustryMeta,
    ) => {
      const fullLegalName = fullLegalNameMeta.get('source');
      const tradeName = tradeNameMeta.get('source');
      const typeOfCompany = typeOfCompanyMeta.get('source');
      const companyRegistrationNumber = companyRegistrationNumberMeta.get(
        'source'
      );
      const TIN = TINMeta.get('source');
      const country = countryMeta.get('source');
      const region = regionMeta.get('source');
      const subregion = subregionMeta.get('source');
      const zipCode = zipCodeMeta.get('source');
      const street = streetMeta.get('source');
      const city = cityMeta.get('source');
      const aptSuite = aptSuiteMeta.get('source');
      const premisesAddressSameAsRegistered = premisesAddressSameAsRegisteredMeta.get(
        'source'
      );
      const premCountry = premCountryMeta.get('source');
      const premAddress = premAddressMeta.get('source');
      const employerIdentificationNumber = employerIdentificationNumberMeta.get(
        'source'
      );
      const VAT = VATMeta.get('source');
      const dateOfIncorporation = dateOfIncorporationMeta.get('source');

      const industry = industryMeta.get('source');
      const otherIndustry = otherIndustryMeta.get('source');
      return {
        generalInformation: {
          fullLegalName,
          tradeName,
          typeOfCompany,
          industry,
          otherIndustry,
          companyRegistrationNumber,
          ...(TIN ? { TIN } : {}),
          registeredAddress: {
            street,
            city,
            country,
            region,
            zipCode,
            ...(subregion ? { subregion } : {}),
            ...(aptSuite ? { aptSuite } : {}),
          },
          premisesAddressSameAsRegistered,
          ...(premCountry && premAddress
            ? {
                premisesAddress: {
                  country: premCountry,
                  address: premAddress,
                },
              }
            : {}),
          ...(employerIdentificationNumber
            ? { employerIdentificationNumber }
            : {}),
          VAT,
          dateOfIncorporation,
        },
      };
    }
  );

