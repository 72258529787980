import React, { FC } from 'react';

import { IconProps } from 'components/UI/Icon/types';

export const Waiting: FC<IconProps> = ({ width, height }) => {

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.49976" cy="8.61523" r="8" fill="#04BCC6" />
      <path
        d="M6.98455 9.20114L8.03988 8.40964L7.01251 9.75469C6.90962 9.61803 6.93751 9.42377 7.07446 9.3212L6.98446 9.2012L6.98455 9.20114ZM7.26069 10.0289C7.12149 10.0289 6.98294 9.9662 6.89238 9.84452L8.18988 6.78055C8.18988 6.6092 8.32838 6.4707 8.49973 6.4707C8.67107 6.4707 8.80957 6.6092 8.80957 6.78055V8.63958C8.80957 8.73717 8.76371 8.8292 8.68563 8.88745L8.77563 9.00745L8.77548 9.00757C8.77543 9.0076 8.77538 9.00764 8.77533 9.00768L7.53629 9.93697C7.53629 9.93697 7.53629 9.93697 7.53629 9.93697C7.45368 9.99893 7.35679 10.0289 7.26069 10.0289Z"
        fill="white"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M8.49976 4.46523C10.7883 4.46523 12.6498 6.32666 12.6498 8.61523C12.6498 10.9038 10.7883 12.7652 8.49976 12.7652C6.21118 12.7652 4.34976 10.9038 4.34976 8.61523C4.34976 6.32666 6.21118 4.46523 8.49976 4.46523ZM8.49976 11.8456C10.2806 11.8456 11.7301 10.3961 11.7301 8.61523C11.7301 6.83441 10.2806 5.38491 8.49976 5.38491C6.71862 5.38491 5.26943 6.8344 5.26943 8.61523C5.26943 10.3961 6.71893 11.8456 8.49976 11.8456Z"
        fill="white"
        stroke="white"
        strokeWidth="0.3"
      />
    </svg>
  );
};
