import React, { FC } from 'react';
import { isProdEnv } from 'utils/env';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectISS, makeSelectUID } from 'system/Profile/selectors';

export interface IMixPanelWrapperProps {
  uid: string;
  iss: string;
  render: any;
}

export interface IProcessMixpanel {
  processMixpanel: (
    trackId: string,
    params?: { [key: string]: string }
  ) => void;
}

const mapStateToProps = createStructuredSelector({
  uid: makeSelectUID(),
  iss: makeSelectISS(),
});

export const withMixpanel: any = (Component: any) => {
  const MixPanel: FC<IMixPanelWrapperProps> = (props: any) => {
    const { uid, iss } = props;
    let _identified = false;

    const identify = () => {
      window.mixpanel.identify(uid);
      window.mixpanel.people.set({
        USER_ID: uid,
        MERCHANT: iss,
        LOCALE: window.navigator.language,
      });

      _identified = true;
    };

    const process = (trackId: string, params: { [key: string]: string }) => {
      const { mixpanel } = window;
      // console.log('Mixpanel Event --', trackId, { ...params });
      // TODO do we need this check|| this.isDemo() || (!enable && !Env.isProd)
      if (!mixpanel || !isProdEnv()) {
        return;
      }

      if (!_identified) {
        identify();
      }
      mixpanel.track(trackId, params);
    };

    return <Component {...props} processMixpanel={process} />;
  };

  return connect(
    mapStateToProps,
    null
  )(MixPanel);
};


