import React, { useState } from 'react';
import styled from 'styled-components';
import isFunction from 'lodash/isFunction';
import { IThemeProps } from 'merchants/types/IThemeStyles';
import { setColorByState } from 'utils/styledComponents/helpers';

interface ITumblerProps {
  name?: string;
  dataAqaid?: string;
  disabled?: boolean;
  checked?: boolean;
  handleChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ILaverProps extends IThemeProps {
  checked: boolean;
  disabled: boolean;
}

export function Tumbler({
  name,
  checked = false,
  disabled = false,
  handleChange,
}: ITumblerProps) {
  const [state, setState] = useState<boolean>(checked);

  const handleToggle = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.persist();

    if (disabled) {
      return;
    }

    if (isFunction(handleChange)) {
      handleChange(evt);
    }

    setState(evt.target.checked);
  };

  return (
    <Laver checked={state} disabled={disabled}>
      <input
        type="checkbox"
        name={name}
        checked={state}
        onChange={handleToggle}
      />
    </Laver>
  );
}

const Laver = styled.span`
  display: flex;
  align-items: center;
  width: 40px;
  height: 24px;
  padding: 0 3px;
  border-radius: ${({ theme }) => theme.forms.tumbler.borderRadius};
  border: solid ${({ theme }) => theme.forms.tumbler.borderWidth};
  user-select: none;
  box-shadow: ${({ theme }) => theme.forms.tumbler.boxShadow};

  border-color: ${({ checked, theme: { forms:{ tumbler } } }: ILaverProps) =>
    setColorByState(
      {
        default: tumbler.borderColor,
        checked: tumbler.checked.borderColor,
      },
      {
        checked,
      }
    )};
  justify-content: ${({ checked }: ILaverProps) =>
    checked ? 'flex-end' : 'flex-start'};
  opacity: ${({ disabled }: ILaverProps) => (disabled ? '.4' : 'initial')};

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: ${({ checked, theme: { forms: { tumbler } } }: ILaverProps) =>
      setColorByState(
        {
          default: tumbler.backgroundColor,
          checked: tumbler.checked.backgroundColor,
        },
        {
          checked,
        }
      )};
  }

  input {
    display: none;
  }
`;
