import React from 'react';

import { IconProps } from 'components/UI/Icon/types';

export const Twitter: React.FunctionComponent<IconProps> = ({
  fill,
  height,
  width,
}) => (
  <svg
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="16" cy="16" fill={fill} r="16" />
      <path
        d="M24 11.539a6.539 6.539 0 0 1-1.885.516 3.288 3.288 0 0 0 1.444-1.815 6.564 6.564 0 0 1-2.086.796 3.282 3.282 0 0 0-5.593 2.993 9.317 9.317 0 0 1-6.766-3.43 3.277 3.277 0 0 0-.444 1.652 3.28 3.28 0 0 0 1.46 2.731 3.285 3.285 0 0 1-1.488-.41v.041a3.283 3.283 0 0 0 2.635 3.218 3.268 3.268 0 0 1-1.483.057 3.283 3.283 0 0 0 3.066 2.278 6.585 6.585 0 0 1-4.077 1.406c-.265 0-.527-.015-.783-.045A9.308 9.308 0 0 0 13.032 23c6.038 0 9.34-5 9.34-9.337 0-.143-.003-.286-.009-.425A6.64 6.64 0 0 0 24 11.539"
        fill="#FFF"
      />
    </g>
  </svg>
);

Twitter.displayName = 'TwitterIcon';

Twitter.defaultProps = { fill: '#263850', height: 32, width: 32 };
