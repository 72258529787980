import { RefObject, useEffect, useRef } from 'react';
import { generate } from 'shortid';

const ATTR = 'data-overlay';

export const useOverlay = (name: string = generate()) => {
  let attrValue = document.body.getAttribute(ATTR);
  const { current: locked }: RefObject<string> = useRef(name);

  useEffect(() => {
    if (!attrValue) {
      attrValue = locked;
      document.body.setAttribute(ATTR, locked);
     
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    }

    return () => {
      if (attrValue === locked) {
        document.body.removeAttribute(ATTR);
       
        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.width = '';
      }
    };
  });
};
