import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

import { Field } from 'utils/types/shared/enums';
import { Block } from 'system/Stages/types';
import {
  selectCorporateBlock,
  selectCorporateBlockStep,
  SubStore,
} from '../selectors';

// Licenses permissions step
export const makeSelectLicensesPermissions = () =>
  createSelector(
    selectCorporateBlock(Block.LICENSES),
    (state: Map<string, any>) => {
      return state.get('licensesPermissions', Map()) || Map({});
    }
  );

export const makeSelectLicensing = () => {
  return createSelector(makeSelectLicensesPermissions(), (state) => {
    return state.get(Field.LICENSING, '');
  });
};

export const makeSelectLicenseLink = () => {
  return createSelector(makeSelectLicensesPermissions(), (state) =>
    (state.get(Field.LICENSE_LINK, List()) || List()).toJS()
  );
};

export const makeSelectLicenseFilesValues = () => {
  return createSelector(makeSelectLicensesPermissions(), (state) =>
    state.get(Field.LICENSE_FILES, List())
  );
};

export const makeSelectLicenseFiles = () => {
  return createSelector(makeSelectLicenseFilesValues(), (state) =>
    state.map((item: Map<string, any>) => item).toJS()
  );
};

export const makeSelectPermissionsAndAuthorizations = () => {
  return createSelector(makeSelectLicensesPermissions(), (state) =>
    state.get(Field.PERMISSIONS_AND_AUTHORIZATIONS, '')
  );
};

export const makeSelectPermissionsAndAuthorizationsLink = () => {
  return createSelector(makeSelectLicensesPermissions(), (state) =>
    (state.get(Field.PERMISSIONS_AND_AUTHORIZATIONS_LINK, List()) || List()).toJS()
  );
};

export const makeSelectPermissionsAndAuthorizationsFilesValues = () => {
  return createSelector(makeSelectLicensesPermissions(), (state) =>
    state.get(Field.PERMISSIONS_AND_AUTHORIZATIONS_FILE, List())
  );
};

export const makeSelectPermissionsAndAuthorizationsFiles = () => {
  return createSelector(makeSelectPermissionsAndAuthorizationsFilesValues(), (state) =>
    state.map((item: Map<string, any>) => item).toJS()
  );
};

export const makeSelectAmlReportingEntity = () => {
  return createSelector(makeSelectLicensesPermissions(), (state) =>
    state.get(Field.AML_REPORTING_ENTITY, '')
  );
};

export const makeSelectLicensesStepMeta = (stepId: string) =>
  createSelector(
    selectCorporateBlockStep(Block.LICENSES, SubStore.META, stepId),
    (value: any) => value
  );

export const makeSelectLicensesPermissionsMeta = () =>
  createSelector(
    makeSelectLicensesStepMeta('licensesPermissions'),
    (value: any) => value || Map({})
  );


export const makeSelectLicensingMeta = () => {
  return createSelector(makeSelectLicensesPermissionsMeta(), (state) => {
    return state.get(Field.LICENSING, Map());
  });
};

export const makeSelectLicenseLinkMeta = () => {
  return createSelector(makeSelectLicensesPermissionsMeta(), (state) =>
    state.get(Field.LICENSE_LINK, Map())
  );
};

export const makeSelectLicenseFilesMeta = () => {
  return createSelector(makeSelectLicensesPermissionsMeta(), (state) =>
    state.get(Field.LICENSE_FILES, Map())
  );
};

export const makeSelectPermissionsAndAuthorizationsMeta = () => {
  return createSelector(makeSelectLicensesPermissionsMeta(), (state) =>
    state.get(Field.PERMISSIONS_AND_AUTHORIZATIONS, Map())
  );
};

export const makeSelectPermissionsAndAuthorizationsLinkMeta = () => {
  return createSelector(makeSelectLicensesPermissionsMeta(), (state) =>
    state.get(Field.PERMISSIONS_AND_AUTHORIZATIONS_LINK, Map())
  );
};
export const makeSelectPermissionsAndAuthorizationsFilesMeta = () => {
  return createSelector(makeSelectLicensesPermissionsMeta(), (state) =>
    state.get(Field.PERMISSIONS_AND_AUTHORIZATIONS_FILE, Map())
  );
};

export const makeSelectAmlReportingEntityMeta = () => {
  return createSelector(makeSelectLicensesPermissionsMeta(), (state) =>
    state.get(Field.AML_REPORTING_ENTITY, Map())
  );
};



// AML regulations step
export const makeSelectLicensesAmlRegulations = () =>
  createSelector(
    selectCorporateBlock(Block.LICENSES),
    (state: Map<string, any>) => {
      return state.get('amlRegulation', Map()) || Map({});
    }
  );

export const makeSelectAmlProgram = () => {
  return createSelector(makeSelectLicensesAmlRegulations(), (state) => {
    return state.get(Field.AML_PROGRAM, '');
  });
};

export const makeSelectAmlProgramLink = () => {
  return createSelector(makeSelectLicensesAmlRegulations(), (state) =>
    (state.get(Field.AML_PROGRAM_LINK,  List()) || List()).toJS()
  );
};

export const makeSelectAmlProgramFileValue = () => {
  return createSelector(makeSelectLicensesAmlRegulations(), (state) =>
    state.get(Field.AML_PROGRAM_FILES,  List())
  );
};

export const makeSelectAmlProgramFile = () => {
  return createSelector(makeSelectAmlProgramFileValue(), (state) =>
    state.map((item: Map<string, any>) => item).toJS()
  );
};

export const makeSelectSharesTradedOnStockExchange = () => {
  return createSelector(makeSelectLicensesAmlRegulations(), (state) =>
    state.get(Field.SHARES_TRADED_ON_STOCK_EXCHANGE, '')
  );
};

export const makeSelectStockExchangeLink = () => {
  return createSelector(makeSelectLicensesAmlRegulations(), (state) =>
    (state.get(Field.STOCK_EXCHANGE_LINK, List()) || List()).toJS()
  );
};

export const makeSelectStockExchangeFileValue = () => {
  return createSelector(makeSelectLicensesAmlRegulations(), (state) =>
    state.get(Field.STOCK_EXCHANGE_FILE, List())
  );
};

export const makeSelectStockExchangeFile = () => {
  return createSelector(makeSelectStockExchangeFileValue(), (state) =>
    state.map((item: Map<string, any>) => item).toJS()
  );
};

export const makeSelectAuditedEntity = () => {
  return createSelector(makeSelectLicensesAmlRegulations(), (state) =>
    state.get(Field.AUDITED_ENTITY, '')
  );
};

export const makeSelectAuditorLink = () => {
  return createSelector(makeSelectLicensesAmlRegulations(), (state) =>
    (state.get(Field.AUDITOR_LINK, List()) || List()).toJS()
  );
};

export const makeSelectAuditorFileValue = () => {
  return createSelector(makeSelectLicensesAmlRegulations(), (state) =>
    state.get(Field.AUDITOR_FILE, List())
  );
};

export const makeSelectAuditorFile = () => {
  return createSelector(makeSelectAuditorFileValue(), (state) =>
    state.map((item: Map<string, any>) => item).toJS()
  );
};

export const makeSelectAmlRegulationsMeta = () =>
  createSelector(
    makeSelectLicensesStepMeta('amlRegulation'),
    (value: any) => value || Map({})
  );

export const makeSelectAmlProgramMeta = () => {
  return createSelector(makeSelectAmlRegulationsMeta(), (state) => {
    return state.get(Field.AML_PROGRAM, Map());
  });
};

export const makeSelectAmlProgramLinkMeta = () => {
  return createSelector(makeSelectAmlRegulationsMeta(), (state) =>
    state.get(Field.AML_PROGRAM_LINK, Map())
  );
};

export const makeSelectAmlProgramFilesMeta = () => {
  return createSelector(makeSelectAmlRegulationsMeta(), (state) =>
    state.get(Field.AML_PROGRAM_FILES, Map())
  );
};

export const makeSelectSharesTradedOnStockExchangeMeta = () => {
  return createSelector(makeSelectAmlRegulationsMeta(), (state) =>
    state.get(Field.SHARES_TRADED_ON_STOCK_EXCHANGE, Map())
  );
};

export const makeSelectStockExchangeLinkMeta = () => {
  return createSelector(makeSelectAmlRegulationsMeta(), (state) =>
    state.get(Field.STOCK_EXCHANGE_LINK, Map())
  );
};

export const makeSelectStockExchangeFilesMeta = () => {
  return createSelector(makeSelectAmlRegulationsMeta(), (state) =>
    state.get(Field.STOCK_EXCHANGE_FILE, Map())
  );
};

export const makeSelectAuditedEntityMeta = () => {
  return createSelector(makeSelectAmlRegulationsMeta(), (state) =>
    state.get(Field.AUDITED_ENTITY, Map())
  );
};

export const makeSelectAuditorLinkMeta = () => {
  return createSelector(makeSelectAmlRegulationsMeta(), (state) =>
    state.get(Field.AUDITOR_LINK, Map())
  );
};

export const makeSelectAuditorFilesMeta = () => {
  return createSelector(makeSelectAmlRegulationsMeta(), (state) =>
    state.get(Field.AUDITOR_FILE, Map())
  );
};
