import styled from 'styled-components';
import { Link } from 'components/UI/Link';
import { IButtonPrimary } from 'components/UI/Button/Primary/Primary';

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const StyledPrimaryLink = styled(Link)`
  background-color: ${({ theme }: IButtonPrimary) => theme.buttons.primary.backgroundColor};
  color: ${({ theme }) => theme.buttons.primary.color};
  border-radius: ${({ theme }) => theme.buttons.primary.borderRadius};

  :not(:disabled) {
    :hover {
      background-color: ${({ theme }) => theme.buttons.primary.hover.backgroundColor};
      color: ${({ theme }) => theme.buttons.primary.hover.color};
    }
  }
`;
