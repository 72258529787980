import React from 'react';
import { withLocalization, ILocalizationProps } from 'system/Localization';
import { Button, H1 } from 'components/UI';
import { Stub } from 'components/UI/Icon';
import styled from 'styled-components';

interface IErrorMessageProps extends ILocalizationProps {
  type: string;
  message: string;
}

export const ErrorMessage = withLocalization<IErrorMessageProps>(
  ({ type, message, translate }) => {
    return (
      <Layout>
        {type === 'api' ? <Stub.ApiError /> : <Stub.Error />}
        <Title>
          {type === 'api'
            ? translate('error_server_error_header')
            : translate('error_unknown')}
        </Title>
        <Message>
          {type !== 'api'
            ? translate('error_server_error')
            : translate('error_api_error')}
        </Message>
        {message && <Message>{message}</Message>}
        <Button mods="success" onClick={() => window.location.replace('/')}>
          {translate('restart')}
        </Button>
      </Layout>
    );
  }
);

const Layout = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
`;

const Title = styled(H1)`
  @media (min-width: ${({ theme }: any) => theme.breakpoint.desktop}) {
    text-align: center;
  }
`;

const Message = styled.p`
  padding: 32px;
`;
