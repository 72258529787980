import { call, put, select } from 'redux-saga/effects';

import { makeSelectApiUrl } from 'system/Settings/selectors';
import { jsonRPC } from 'utils/request';
import {  IPhoneCodeRequest } from '../actionTypes';
import {
  failedPhoneCode,
  successPhoneCode,
} from '../actions';

export function* watchForPhoneCodeRequest(action: IPhoneCodeRequest) {
  try {
    const { payload: { phone: { code, number } } } = action;
    const api = yield select(makeSelectApiUrl());

    const response = yield call(jsonRPC, {
      namespace: 'va',
      method: 'sendCode',
      params: {
        phone: { code, number }
      },
      api,
    });

    if (response) {
      yield put(successPhoneCode({ ...response }));
    }

    if (response.error) {
      yield put(failedPhoneCode(response.error));
    }
  } catch (e) {
    yield put(failedPhoneCode(e));
  }
}
