import styled from 'styled-components';

export const H1 = styled.h1`
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.h1.color};
  white-space: pre-wrap;
  line-height: 1.3;
`;
