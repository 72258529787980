import {
  Type,
  IRequestUniqueId,
  IRequestUniqueIdSuccess,
  IRequestUniqueIdError,
} from './actionTypes';

export const requestUniqueId = (payload: IRequestUniqueId['payload']): IRequestUniqueId => ({
  type: Type.FINGERPRINT_SET_UNIQUE_ID_REQUEST,
  payload
});

export const requestUniqueIdSuccess = (payload: any): IRequestUniqueIdSuccess => ({
  type: Type.FINGERPRINT_SET_UNIQUE_ID_SUCCESS,
  payload
});

export const requestUniqueIdError = (payload: Error): IRequestUniqueIdError => ({
  type: Type.FINGERPRINT_SET_UNIQUE_ID_ERROR,
  payload
});
