import get from 'lodash/get';

import { StageStatusName } from 'system/Stages/types';

type ComponentStates = StageStatusName | 'disabled' | 'focused' | 'selected' | 'error' | 'danger' | 'primary' | 'warning' | 'success' | 'locked' | 'checked' | 'active' | 'action' | 'valid';

interface ISetColorByState <P = {}> {
  (config: Partial<P> & { default: string }, state: Partial<Record<keyof P, boolean>>): string;
}

export const setColorByState: ISetColorByState<Record<ComponentStates, string>> = (config, states) => {
  const [colorForState] = Object.entries(states).find((state) => {
    return state[1];
  }) || ['default'];

  return get(config, colorForState, '');
};
