import React from 'react';

export enum TRACK {
  END_SESSION = 'END_SESSION',
  START_SESSION = 'START_SESSION',
  STAGE_BUTTON = 'STAGE_BUTTON',
  NEXT_BUTTON = 'NEXT_BUTTON',
  BACK_BUTTON = 'BACK_BUTTON',
  TAKE_SNAPSHOT_BUTTON = 'TAKE_SNAPSHOT_BUTTON',
  RE_TAKE_PHOTO_BUTTON = 'RE_TAKE_PHOTO_BUTTON',
  ITS_READABLE_BUTTON = 'ITS_READABLE_BUTTON',
  OPEN_CAMERA_BUTTON = 'OPEN_CAMERA_BUTTON',
  LINK_ACCOUNT = 'LINK_ACCOUNT',
  BLOCK_DURATION = 'BLOCK_DURATION',
  RED_STATE_SELECTED = 'RED_STATE_SELECTED',
  SUBMIT_STAGE = 'SUBMIT_STAGE',
  SUBMIT_REQUEST = 'SUBMIT_REQUEST',
  RED_COUNTRY_SELECTED = 'RED_COUNTRY_SELECTED',
  IMPORT_PHOTO_LIBRARY_EVENT = 'IMPORT_PHOTO_LIBRARY_EVENT',
  IMPORT_FILES_EVENT = 'IMPORT_FILES_EVENT',
  SHOW_RESUBMIT_STAGE_LIST = 'SHOW_RESUBMIT_STAGE_LIST',
  RESUBMIT_STAGES = 'RESUBMIT_STAGES',
  QR_CODE_CONNECTED = 'QR_CODE_CONNECTED',
  QR_CODE_PASSED = 'QR_CODE_PASSED',
  SHOW_ADDRESS_SEARCH_SCREEN = 'SHOW_ADDRESS_SEARCH_SCREEN',
  LIVENESS_CHECK_FAIL_SKIPPED_EVENT = 'LIVENESS_CHECK_FAIL_SKIPPED_EVENT',
  LIVENESS_CHECK_FAIL_TRY_AGAIN_EVENT = 'LIVENESS_CHECK_FAIL_TRY_AGAIN_EVENT',
  QUESTIONNAIRE_WARNING_QUESTION_CANCEL_EVENT = 'QUESTIONNAIRE_WARNING_QUESTION_CANCEL_EVENT',
  QUESTIONNAIRE_WARNING_QUESTION_PROCEED_EVENT = 'QUESTIONNAIRE_WARNING_QUESTION_PROCEED_EVENT',
}

export interface IEventHandlers {
  onClick?: (evt: React.SyntheticEvent<any>) => void;
  onChange?: (evt: React.SyntheticEvent<any>) => void;
  onBlur?: (evt: React.SyntheticEvent<any>) => void;
  onFocus?: (evt: React.SyntheticEvent<any>) => void;
}

export interface IParam {
  trackId: TRACK;
  [key: string]: any;
}

export interface EventHandlersParams {
  onClick?: IParam;
  onChange?: IParam;
  onBlur?: IParam;
  onFocus?: IParam;
  onMount?: IParam;
  onUnmount?: IParam;
  [key: string]: any;
}
