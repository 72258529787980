import React from 'react';
import styled from 'styled-components';
import omit from 'lodash/omit';

import { ILinkProps, StyledLink } from 'components/UI';
import { ITheme } from 'merchants/types/IThemeStyles';

export interface INavLinkProps extends ILinkProps {
  theme: ITheme;
  block?: boolean;
}

export const NavLink = styled(({ children, ...props }: INavLinkProps) => (
  <StyledLink {...omit(props, 'block')}>{children}</StyledLink>
))`
  && {
    width: ${({ block }) => block ? '100%' : 'auto'};
    min-width: 102px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: ${({ theme }: INavLinkProps) => theme.links.nav.padding};
    color: ${({ theme }: INavLinkProps) => theme.links.nav.color};
    border-radius: ${({ theme }: INavLinkProps) => theme.links.nav.borderRadius};
    border:
      ${({ theme }: INavLinkProps) => theme.links.nav.borderWidth}
      solid
      ${({ theme }: INavLinkProps) => theme.links.nav.borderColor};
    background-color: ${({ theme }: INavLinkProps) => theme.links.nav.backgroundColor};
    transition: background-color 0.25s;

    & + * {
      margin-left: 8px;
    }

    > {
      :first-child {
        margin-right: 4px;
      }

      :not(:last-child) {
        margin-right: 4px;
      }
    }

    :hover {
      color: ${({ theme }: INavLinkProps) => theme.links.nav.hover.color};
      background-color: ${({ theme }: INavLinkProps) => theme.links.nav.hover.backgroundColor};
    }
  }
`;
