import { createSelector } from 'reselect';
import {
  makeSelectExpectedMonthlyDepositOperations,
  makeSelectExpectedMonthlyDepositOperationsMeta,
  makeSelectPlannedMonthlyDepositVolume,
  makeSelectPlannedMonthlyDepositVolumeMeta,
  makeSelectPlannedMonthlyWithdrawalVolume,
  makeSelectPlannedMonthlyWithdrawalVolumeMeta,
  makeSelectPrimaryPurpose,
  makeSelectPrimaryPurposeDetailed,
  makeSelectPrimaryPurposeDetailedMeta,
  makeSelectPrimaryPurposeMeta,
} from './accountActivity';

export const makeSelectAccountActivityBlockData = () =>
  createSelector(
    [
      makeSelectPrimaryPurpose(),
      makeSelectPrimaryPurposeDetailed(),
      makeSelectPlannedMonthlyDepositVolume(),
      makeSelectExpectedMonthlyDepositOperations(),
      makeSelectPlannedMonthlyWithdrawalVolume(),
    ],
    (
      primaryPurpose,
      primaryPurposeDetailed,
      plannedMonthlyDepositVolume,
      expectedMonthlyDepositOperations,
      plannedMonthlyWithdrawalVolume,
    ) => ({
      accountActivity: {
        primaryPurpose,
        ...(primaryPurposeDetailed ? { primaryPurposeDetailed } : {}),
        plannedMonthlyDepositVolume,
        expectedMonthlyDepositOperations,
        plannedMonthlyWithdrawalVolume,
      },
    }),
  );
export const makeSelectAccountActivityBlockMeta = () =>
  createSelector(
    [
      makeSelectPrimaryPurposeMeta(),
      makeSelectPrimaryPurposeDetailedMeta(),
      makeSelectPlannedMonthlyDepositVolumeMeta(),
      makeSelectExpectedMonthlyDepositOperationsMeta(),
      makeSelectPlannedMonthlyWithdrawalVolumeMeta(),
    ],
    (
      primaryPurposeMeta,
      primaryPurposeDetailedMeta,
      plannedMonthlyDepositVolumeMeta,
      expectedMonthlyDepositOperationsMeta,
      plannedMonthlyWithdrawalVolumeMeta,
    ) => {
      const primaryPurpose = primaryPurposeMeta.get('source');
      const primaryPurposeDetailed = primaryPurposeDetailedMeta.get('source');
      const plannedMonthlyDepositVolume = plannedMonthlyDepositVolumeMeta.get('source');
      const expectedMonthlyDepositOperations = expectedMonthlyDepositOperationsMeta.get('source');
      const plannedMonthlyWithdrawalVolume = plannedMonthlyWithdrawalVolumeMeta.get('source');
      return {
        accountActivity: {
          primaryPurpose,
          primaryPurposeDetailed,
          plannedMonthlyDepositVolume,
          expectedMonthlyDepositOperations,
          plannedMonthlyWithdrawalVolume,
        },
      };
    },
  );
