export const get = (key: string, defVal: any = '') => {
  const value = localStorage.getItem(key);

  return value !== null ? value : defVal;
};

export const set = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const storage = { get, set };
