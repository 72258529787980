import React, { FC, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { components, Styles } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useField } from 'formik';

import { getZipLocation, getZipSuggestions } from 'system/UI/actions';
import { getDropdownStyles } from './utils/helpers';

function Menu(props: any) {
  const { options } = props;
  return !options.length ? null : <components.Menu {...props} />;
}

const DropdownWithZipComponent: FC<any> = ({
  _handleFetchZip,
  _handleFetchZipLocation,
  value,
  name,
  onChange,
  onBlur,
  theme,
  placeholder,
  disabled,
  handleSetByZip,
  handleResetBlock,
}: any) => {
  const [, meta] = useField(name);
  const ifValidSearch = !meta?.error;
  const ifError = Boolean(meta.error && meta.touched);
  const ifValid = Boolean(meta.touched && !ifError);

  const [inputValue, _updateInputValue] = useState(value);
  // This state is used for triggering loadOptions during selecting item with isContainer prop
  // due to code from AsyncSelect:
  //      const options = passEmptyOptions
  //         ? []
  //         : inputValue && loadedInputValue
  //         ? loadedOptions
  //         : defaultOptions || [];
  const [fetchedOptions, _setOptions] = useState([]);

  useEffect(() => {
    _updateInputValue(value);
  }, [value]);

  const loadPromiseOptions = (val: string) => {
    return new Promise((resolve) => {
      if (ifValidSearch && val.length > 2) {
        _handleFetchZip(val).then((opts: any) => {
          resolve(opts);
        });
      }
    });
  };

  const handleInputChange = (newValue: string, { action }: any) => {
    if (action === 'input-change') {
      _updateInputValue(newValue);
      onChange(newValue);
    }
  };

  const handleSelectChange: any = (newValue: any, { action }: any) => {
    if (action === 'clear') {
      handleResetBlock();
      return _updateInputValue('');
    }

    if (action === 'select-option') {
      const {
        data: { id, isContainer },
      } = newValue;

      if (isContainer && ifValidSearch) {
        return _handleFetchZip(inputValue, id).then((opts: any) => {
          _setOptions(opts); // helps open select dropdown with new list
        });
      }

      return _handleFetchZipLocation(id).then((opts: any) => {
        _updateInputValue('');
        _setOptions([]);
        handleSetByZip(opts)
      });
    }
  };

  const styles: Styles = getDropdownStyles(theme.forms.dropdown, {
    error: ifError,
    valid: ifValid,
  });

  return (
    <AsyncSelect
      isDisabled={disabled}
      isClearable
      className="select-zip"
      placeholder={placeholder}
      defaultOptions={fetchedOptions}
      closeOnSelect={false}
      closeMenuOnSelect={false}
      onSelectResetsInput={false}
      inputValue={inputValue}
      loadOptions={loadPromiseOptions}
      onInputChange={handleInputChange}
      onChange={handleSelectChange}
      onBlur={onBlur}
      styles={styles}
      components={{
        Menu,
        IndicatorSeparator() {
          return null;
        },
        DropdownIndicator() {
          return null;
        },
      }}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  _handleFetchZip: (query: string, containerId: string): any => {
    return new Promise((resolve, reject) => {
      dispatch(getZipSuggestions({ query, containerId, resolve, reject }));
    });
  },
  _handleFetchZipLocation: (containerId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      dispatch(getZipLocation({ containerId, resolve, reject }));
    });
  }
});

export const DropdownWithZip = connect(
  null,
  mapDispatchToProps
)(withTheme(DropdownWithZipComponent));
