import { fromJS } from 'immutable';

import { Type, ActionType } from './actionTypes';

export enum StoreFields {
  VERIFICATION_DATE_START = 'verificationDateStart',
}

export const defaultState = fromJS({
  [StoreFields.VERIFICATION_DATE_START]: {},
});

export const reducer = (
  state = defaultState,
  { type, payload }: ActionType
) => {
  switch (type) {
    case Type.VERIFICATION_DATE_START: {
      const { stageId, time } = payload;

      return state.mergeIn(
        [StoreFields.VERIFICATION_DATE_START],
        fromJS({ [stageId]: time })
      );
    }
    default:
      return state;
  }
};
