import React, { useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Exclamation: React.FunctionComponent<IconProps> = ({
  width,
  height,
}) => {
  const {
    icons: {
      markup: {
        exclamation: { fill },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M8 1C3.584 1 0 4.584 0 9s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm.8 12H7.2v-1.6h1.6V13zm0-3.2H7.2V5h1.6v4.8z"
          id="b"
        />
        <filter
          filterUnits="objectBoundingBox"
          height="125%"
          id="a"
          width="125%"
          x="-12.5%"
          y="-6.2%"
        >
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation=".5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(1 -.5)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill={fill} xlinkHref="#b" />
      </g>
    </svg>
  );
};

Exclamation.displayName = 'ExclamationIcon';

Exclamation.defaultProps = { height: '19', width: '18' };
