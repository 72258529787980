import {
  IdentityBlockFailed,
  IdentitySetMeta,
  IdentityStepInitialSet,
  IdentityStepSet,
  IdentitySubmitFailed,
  IdentitySubmitRequest,
  IdentitySubmitSuccess,
  IMetaPayload,
  IRequestUploadIdentityDocuments,
  ISetIdentityDocuments,
  IUploadIdentityDocumentsFailed,
  IUploadIdentityDocumentsSuccess,
  IPhoneCodeRequest,
  IPhoneCodeSuccess,
  IPhoneCodeFailed,
  ICodeValidationRequest,
  ICodeValidationSuccess,
  ICodeValidationFailed,
  Type,
  IResetPhoneErrors,
  IResetPhoneValidation,
  IdentityBlockReset,
  ISetPhoneCountry,
  IResetPhoneCode,
} from './actionTypes';
import { IUploadResult } from '../../system/Dossier/Upload/sagas';

export const setIdentityInitialStep = ({
  stepId,
  fields,
}: IdentityStepInitialSet['payload']): IdentityStepInitialSet => {
  return {
    type: Type.IDENTITY_STEP_INITIAL_SET,
    payload: { stepId, fields },
  };
};

export const setIdentityStep = ({
  stepId,
  fields,
}: IdentityStepSet['payload']): IdentityStepSet => ({
  type: Type.IDENTITY_STEP_SET,
  payload: { stepId, fields },
});

export const failedIdentityBlock = (err: Error): IdentityBlockFailed => ({
  type: Type.IDENTITY_BLOCK_FAILED,
  payload: err,
});

export const resetIdentityBlock = (stepId: string): IdentityBlockReset => ({
  type: Type.IDENTITY_BLOCK_RESET,
  payload: { stepId },
});

export const requestUploadIdentityDocuments = (
  payload: IRequestUploadIdentityDocuments['payload']
): IRequestUploadIdentityDocuments => ({
  type: Type.IDENTITY_UPLOAD_DOC_REQUEST,
  payload,
});

export const successUploadIdentityDocuments = (): IUploadIdentityDocumentsSuccess => ({
  type: Type.IDENTITY_UPLOAD_DOC_SUCCESS,
  payload: {},
});

export const failedUploadIdentityDocuments = (err: Error[]): IUploadIdentityDocumentsFailed => ({
  type: Type.IDENTITY_UPLOAD_DOC_FAILED,
  payload: err,
});

export const setIdentityDocuments = (payload: IUploadResult[]): ISetIdentityDocuments => ({
  type: Type.IDENTITY_DOC_SET,
  payload,
});

export const setIdentityMeta = (payload: IMetaPayload['payload']): IdentitySetMeta => ({
  type: Type.IDENTITY_META_SET,
  payload,
});

export const requestSubmitIdentity = (): IdentitySubmitRequest => ({
  type: Type.IDENTITY_SUBMIT_REQUEST,
  payload: {},
});

export const successSubmitIdentity = (): IdentitySubmitSuccess => ({
  type: Type.IDENTITY_SUBMIT_SUCCESS,
  payload: {},
});

export const failedSubmitIdentity = (err: Error[]): IdentitySubmitFailed => ({
  type: Type.IDENTITY_SUBMIT_FAILED,
  payload: err,
});

export const requestPhoneCode = (payload: IPhoneCodeRequest['payload']): IPhoneCodeRequest => ({
  type: Type.IDENTITY_PHONE_CODE_REQUEST,
  payload,
});

export const successPhoneCode = (payload: IPhoneCodeSuccess['payload']): IPhoneCodeSuccess => ({
  type: Type.IDENTITY_PHONE_CODE_SUCCESS,
  payload,
});

export const failedPhoneCode = (err: Error[]): IPhoneCodeFailed => ({
  type: Type.IDENTITY_PHONE_CODE_FAILED,
  payload: err,
});

export const resetPhoneErrors = (): IResetPhoneErrors => ({
  type: Type.IDENTITY_RESET_PHONE_ERRORS,
  payload: {},
});

export const resetPhoneValidation = (): IResetPhoneValidation => ({
  type: Type.IDENTITY_RESET_PHONE_VALIDATION,
  payload: {},
});

export const setPhoneCountry = (payload: string): ISetPhoneCountry => ({
  type: Type.IDENTITY_SET_PHONE_COUNTRY,
  payload,
});

export const resetPhoneCode = (): IResetPhoneCode => ({
  type: Type.IDENTITY_RESET_PHONE_CODE,
});
