import { isLocalEnv, isDevEnv } from 'utils/env';

export const makeErrorMessage = (
  err: Error & { componentStack?: string; eventId?: string }
): Error => {
  const { stack, ...rest } = err;
  const isDevelopment = isLocalEnv() || isDevEnv();

  if (isDevelopment) {
    console.error('Error: ', err);
  }

  return {
    ...rest,
    ...(isDevelopment ? { stack } : { stack }),
  };
};

export enum PhoneErrors {
  TIMEOUT = 1075,
  VALIDATED = 1076,
  BAD_NUM = 1077,
  SMS_ERROR = 1078,
  EXPIRED = 1079,
}

export enum PhoneErrorsType {
  CODE = 'code',
  PHONE = 'phone',
}

export const PhoneErrorMessages = {
  1075: 'error_phone_timeout',
  1076: 'error_number_is_validated',
  1077: 'error_phone_invalid',
  1078: 'error_sms_sending',
  1079: 'error_code_expired',
  1111: 'error_code_invalid',
};

export const getPhoneErrorMessage = (
  code: keyof typeof PhoneErrorMessages | undefined
) => {
  return code ? PhoneErrorMessages[code] : '';
};
