export const serialize = (value: any): string => {
  return JSON.stringify(value);
};

export const unserialize = (value: string): any => {
  try {
    return JSON.parse(value);
  } catch (err) {
    return undefined;
  }
};

export const serialization = { serialize, unserialize };
