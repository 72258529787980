/* eslint-disable */
import React, { FC, useState } from 'react';

import { ITranslate } from 'system/Localization/withLocalization';

import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { Hamburger } from 'components/common/Hamburger';
import { Logo } from 'components/common/Logo';
import { MobileMenu } from 'components/common/MobileMenu';
import { Nav } from 'components/common/Nav';
import { Progress } from 'components/common/Progress';
import { Portal, DynamicPortal } from 'components/Portal';
import { CrumbProps } from 'containers/Navbar';
import { Box, ExitLink, Flex, Link } from 'components/UI';
import * as Icon from 'components/UI/Icon/Markup';


interface IMobile {
  showCrumbs: boolean;
  showProgress: boolean;
  showResubmit: boolean;
  progress: number;
  crumbs: CrumbProps[];
  indexLink: string;
  fallbackLink: string;
  iss: string;
  translate: ITranslate;
}

export const Mobile: FC<IMobile> = ({
  crumbs,
  showCrumbs,
  showProgress,
  showResubmit,
  progress,
  fallbackLink,
  translate,
  iss,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleToggle = () => {
    setVisible((prevState) => !prevState);
  };

  const menu = visible ? (
    <DynamicPortal id="menu">
      <MobileMenu
        fallbackLink={fallbackLink}
        handleClose={handleToggle}
        translate={translate}
      />
    </DynamicPortal>
  ) : null;

  return (
    <>
      <Nav>
        <Flex
          align='center'
          justify='space-between'
        >
          <Box breakpoint={{ mobile: { size: 'auto' }}}>
            <Link to={fallbackLink}>
              <Logo iss={iss} />
            </Link>
          </Box>
          {showProgress && (
            <Box breakpoint={{ mobile: { size: 'auto', flexGrow: 1 }}}>
              <Progress size={progress} />
            </Box>
          )}
          <Box breakpoint={{ mobile: { size: 'auto' }}}>
            {showResubmit ? (
              <Hamburger handleClick={handleToggle} />
            ) : (
              <ExitLink block to={fallbackLink}>
                <Icon.Exit />
              </ExitLink>
            )}
          </Box>
        </Flex>
      </Nav>
      {showCrumbs && (
        <Breadcrumbs mobile>
          {crumbs.map(({ to, label }: CrumbProps) => {
            return to ? (
              <Link key={label} to={to}>
                {translate(label)}
              </Link>
            ) : (
              <span key={label}>{translate(label)}</span>
            );
          })}
        </Breadcrumbs>
      )}
      {menu}
    </>
  );
};
