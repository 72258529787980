import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { IThemeProps, WithThemeProps } from 'merchants/types/IThemeStyles';
import { Label, DummyInput } from '../shared';

interface IRadioProps {
  name?: string;
  disabled?: boolean;
  checked?: boolean;
  value?: string | number | string[] | undefined | 'true' | 'false' | any;
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  defaultChecked?: boolean;
}

export function Radio({ disabled = false, ...innerProps }: IRadioProps) {
  return (
    <Label data-aqaid={`radio-${innerProps.name}`}>
      <Input disabled={disabled} {...innerProps} />
      <Mark disabled={disabled} />
    </Label>
  );
}

Radio.displayName = 'Radio';

const Mark = styled.span.attrs(({ checked, disabled }: WithThemeProps<{ checked: boolean; disabled: boolean }>) => ({
  checked,
  disabled,
}))`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  position: relative;
  border-style: solid;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.forms.input.boxShadow};
  border-width: ${({ theme }: IThemeProps) => theme.forms.radio.borderWidth};
  border-color: ${({ theme, disabled }: { disabled: boolean } & IThemeProps) =>
    (disabled && theme.forms.radio.disabled.borderColor) || theme.forms.radio.borderColor};
  background-color: ${({
    theme,
    disabled,
  }: { disabled: boolean } & IThemeProps) =>
    (disabled && theme.forms.radio.disabled.backgroundColor) ||
    theme.forms.radio.backgroundColor};

  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
  }
`;

const Input = styled(DummyInput).attrs({ type: 'radio' })`
  &:checked + ${Mark} {
    border-color: ${({ theme }: IThemeProps) =>
      theme.forms.radio.checked.borderColor};
    background-color: ${({ theme }: IThemeProps) =>
      theme.forms.radio.checked.backgroundColor};

    &::after {
      display: block;
      background-color: ${({ theme }: IThemeProps) =>
  theme.forms.radio.checked.backgroundColorAfter};
    }
  }
`;
