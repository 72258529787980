import React, { FC } from 'react';
import { History, Location } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/fp/compose';

import { Stage, StageId } from 'system/Stages/types';
import { retryStage } from 'system/UI/actions';
import { navigateIndex } from 'system/Routing/actions';
import { ILocalizationProps, withLocalization } from 'system/Localization';
import { makeSelectVerificationType } from 'system/Profile/selectors';

import { BUTTON_TYPE, ICommonStubProps } from 'containers/Stubs/CommonStub/types';
import { CommonStub } from 'containers/Stubs/CommonStub';

import { Stub } from 'components/UI/Icon';

interface ISubmittedStub extends ILocalizationProps {
  location: Location | any;
  history: History;
  nextStep: string;
  softRefuseReason: string | null;
}

export const TryAgainStub: FC<any> = compose(withRouter,withLocalization)(({
  translate,
  softRefuseReason,
}: ISubmittedStub) => {
  const dispatch = useDispatch();
  const verificationType = useSelector(makeSelectVerificationType())

  const isCorporate = verificationType === Stage.CORPORATE
  const stageId = isCorporate ? Stage.CORPORATE : Stage.IDENTITY

  const handleRetryStage = (id: StageId) => {
    dispatch(retryStage(id));

    window.location.replace(`${window.location.origin}/${id}`);
  };
  const handleExit = () => {
    if (isCorporate) {
      window.location.replace('https://cex.io')
    } else {
      dispatch(navigateIndex({ exit: true }))
    }
  };

  let buttons = [{
      clickHandler: handleExit,
      text: translate('exit_verification'),
      mods: { type: BUTTON_TYPE.regular },
    },
    {
      clickHandler: () =>  handleRetryStage(stageId),
      text: translate('button_try_again'),
      mods: { type: BUTTON_TYPE.primary },
    }]

  if (isCorporate)  {
    buttons = [
      {
        clickHandler: handleExit,
        text: translate('button_back_to_platform'),
        mods: { type: BUTTON_TYPE.primary },
      },
    ];
  }

  const contentProps: ICommonStubProps = {
    stageTitle: translate(`stage_title_${stageId}`),
    stageSubtitle: translate('information_notice'),
    buttons,
    body: {
      Icon: Stub.Error,
      title: translate('stub_title_retry'),
      message: softRefuseReason
        ? translate(softRefuseReason, '')
        : translate(`stub_message_refuse_${stageId}`)
    },
  };

  return <CommonStub {...contentProps} />;
})
