import React, { ComponentType, useEffect } from 'react';
import { matchPath, withRouter } from 'react-router-dom';
import reduce from 'lodash/reduce';
import { EventHandlersParams } from './types';
import { IProcessMixpanel, withMixpanel } from './withMixpanel';

type IAnalyticHandler = IProcessMixpanel

export const withAnalytic = <P extends object>(
  Component: ComponentType<P>,
  options: EventHandlersParams
) => {
  const AnalyticHandler = ({
    location: { pathname: defPathname },
    processMixpanel,
    ...filteredProps
  }: any) => {
    const matched = matchPath(defPathname, { path: '/:STAGE/:STEP?' });

    const { onMount, onUnmount } = options;
    const mountTrack = onMount?.trackId;
    const unmountTrack = onUnmount?.trackId;

    // eslint-disable-next-line consistent-return
    useEffect(() => {
      if (mountTrack) {
        processMixpanel(mountTrack);
      }
      if (unmountTrack) {
        return () => {
          processMixpanel(unmountTrack);
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlers = () =>
      reduce(
        Object.keys(options),
        (result: any, value: any) => {
          const ownPropsHandler = filteredProps[value];

          const { trackId, ...otherOpts } = options[value];
          const params = { ...otherOpts, ...matched?.params };

          // eslint-disable-next-line no-param-reassign
          result[value] = () => {
            processMixpanel(trackId, params);
            if (ownPropsHandler) {
              ownPropsHandler();
            }
          };

          return result;
        },
        {}
      );

    return <Component {...filteredProps} {...handlers()} />;
  };

  return withMixpanel(withRouter(AnalyticHandler));
};
