/* eslint-disable @typescript-eslint/no-use-before-define */

export enum Locale {
  en = 'en-GB',
  ru = 'ru',
}

const allLocales: Locale[] = [Locale.en, Locale.ru];

const findAvailableLocale = (search = ''): Locale | void => {
  if (!search) {
    return undefined;
  }

  const shortNotationSearch = shortNotationLocale(search);

  return allLocales.find((locale: Locale) => {
    return shortNotationSearch.startsWith(shortNotationLocale(locale));
  });
};

const getUserAgentLocale = (): string => {
  try {
    return shortNotationLocale(window.navigator.language);
  } catch (err) {
    return '';
  }
};

export const shortNotationLocale = (locale = ''): string => {
  return locale.slice(0, 2);
};

export const getAppLocale = (language = ''): Locale => {
  const [ firstLocale ] = language.split(',');
  return (
    findAvailableLocale(shortNotationLocale(firstLocale)) ||
    findAvailableLocale(getUserAgentLocale()) ||
    Locale.en
  );
};
