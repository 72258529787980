import React, { FC, useContext } from 'react';

import { IconProps } from 'components/UI/Icon/types';
import { ThemeContext } from 'styled-components';

export const Close: React.FC<IconProps> = ({
  width,
  height,
  fill: customFill,
  fill2:  customFill2,
  className,
  onClick,
}: any) => {
  const {
    icons: {
      markup: {
        close: { fill, fill2 },
      }
    },
  } = useContext(ThemeContext);
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect width='24' height='24' rx="12" fill={customFill || fill} opacity={customFill || fill ? '' : '.198'}/>
      <rect 
        x="16.126" 
        y="6.93358" 
        width="1.33" 
        height='13' 
        rx="0.6" 
        transform="rotate(45 16.126 6.93358)" 
        fill={customFill2 || fill2}
      />
      <rect 
        x="17.0664" 
        y="16.126" 
        width="1.33" 
        height='13' 
        rx="0.6" 
        transform="rotate(135 17.0664 16.126)" 
        fill={customFill2 || fill2}
      />
    </svg>
  );
};

Close.displayName = 'CloseIcon';

Close.defaultProps = { height: '32', width: '32' };
