import { ENVIRONMENTS } from '../types/shared/enums';

export function getAppEnv(): ENVIRONMENTS {
  return ((window as any).appConfig?.env as ENVIRONMENTS) || {};
}

export function isLocalEnv(): boolean {
  return [ENVIRONMENTS.LOCAL, ENVIRONMENTS.MOCK].includes(getAppEnv());
}

export function isDevEnv(): boolean {
  return getAppEnv() === ENVIRONMENTS.DEV;
}

export function isUatEnv(): boolean {
  return getAppEnv() === ENVIRONMENTS.UAT;
}

export function isProdEnv(): boolean {
  return !isLocalEnv() && !isDevEnv() && !isUatEnv();
}

export function debug(...args: any) {
  return isLocalEnv() && console.log(...args);
}
