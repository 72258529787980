import { routerMiddleware } from 'connected-react-router/immutable';
import { createBrowserHistory, History } from 'history';
import { fromJS } from 'immutable';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware, { Task } from 'redux-saga';

import { isDevEnv, isLocalEnv } from 'utils/env';

import { createRootReducer } from './reducers';
import { rootSaga } from './sagas';
import { trackingMiddleware } from './middleware';

const composeEnhancers =
  isLocalEnv() || isDevEnv()
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const sagaMiddleware = createSagaMiddleware();

export class ConfigureStore {
  private static instance: ConfigureStore;

  private static store: Store<any>;

  private static history: History;

  private static saga: Task;

  private constructor(baseUrl = '/') {
    ConfigureStore.createStore(baseUrl);
  }

  static getStore() {
    return ConfigureStore.store;
  }

  static getHistory() {
    return ConfigureStore.history;
  }

  static createStore(baseUrl = '/') {
    if (this.saga) {
      this.saga.cancel()
    }

    ConfigureStore.history = createBrowserHistory({ basename: baseUrl });

    ConfigureStore.store = createStore(
      createRootReducer(ConfigureStore.history),
      fromJS({}),
      composeEnhancers(
        applyMiddleware(
          sagaMiddleware,
          routerMiddleware(ConfigureStore.history),
          trackingMiddleware
        )
      )
    );

    this.saga = sagaMiddleware.run(rootSaga);
  }

  static getInstance(baseUrl = '/') {
    if (!ConfigureStore.instance) {
      ConfigureStore.instance = new ConfigureStore(baseUrl);
    }

    return ConfigureStore.instance;
  }
}
