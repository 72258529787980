/* eslint-disable */
import React, { ReactNode } from 'react';
import { fromJS, List } from 'immutable';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import { createStructuredSelector } from 'reselect';
import { Dispatch } from 'redux';
import { failed } from 'system/Bootstrap/actions';
import { makeErrorMessage } from 'utils/error';

import { withLocalization, WithLocalizationProps } from 'system/Localization';
import { ErrorView } from './errorViews';
import { makeSelectAllErrors } from 'system/Bootstrap/selectors';
import { makeSelectIssuerFallbackLink } from 'system/Settings/selectors';
import { from } from 'immutable/contrib/cursor';

interface ErrorBoundarySentryProps {
  handleSetErrorInfo: (error: Array<Error>) => void;
  errors: List<any>;
  children: ReactNode;
}

const ErrorBoundaryComponent = ({
  handleSetErrorInfo,
  children,
  errors = List(),
}: ErrorBoundarySentryProps) => {
  const onError = (error: Error, componentStack: string, eventId: string) => {
    console.error(error);
    handleSetErrorInfo([makeErrorMessage({ ...error, componentStack, eventId })]);
  };

  if (!errors.isEmpty()) {
    const firstError = errors.first();

    return <ErrorView error={firstError} />;
  }

  return (
    <Sentry.ErrorBoundary
      showDialog={false}
      onError={onError}
      fallback={({ error }) => {
        return <ErrorView error={fromJS(error)} />;
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

const mapStateToProps = createStructuredSelector({
  errors: makeSelectAllErrors(),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  handleSetErrorInfo: (error: Array<Error>) => dispatch(failed(error)),
});

export const ErrorBoundary = connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalization<WithLocalizationProps<ErrorBoundarySentryProps>>(ErrorBoundaryComponent));
