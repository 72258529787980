import React, { FC, SyntheticEvent, useState } from 'react';

interface Image {
  src: string;
  fallbackSrc?: string;
  [key: string]: any;
}

export const Image: FC<Image> = ({ src, fallbackSrc = '', alt,  ...props }) => {
  const [ imgError, setImgError ] = useState(0);
  const onError = (e: SyntheticEvent<HTMLImageElement>) => {
    if (imgError >= 1) {
      return;
    }
    (e.target as HTMLImageElement).src = fallbackSrc;
    setImgError((prevState) => prevState + 1);
  };

  return <img src={src} onError={onError} alt={alt} {...props} />;
};
